import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuService } from '@traas/boldor/common/features/menu/services/menu.service';

const TRAVYS_PLANS_URL = 'https://www.travys.ch/plans/';
const TRAVYS_TRAFFIC_INFORMATION_URL = 'https://m.travys.ch/disruptions';
const TRAVYS_FAQ_URL = 'https://faq.traas.ch/travys';
const TRAVYS_CUSTOMER_SERVICE_URL = 'https://faq.traas.ch/travys/service-clients';

@Component({
    selector: 'traas-travys-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    isHoursMenuCollapsed = true;
    isBuyMenuCollapsed = true;
    isAccountMenuCollapsed = true;
    isHelpMenuCollapsed = true;

    constructor(private menuService: MenuService) {}

    toggleHoursMenu(): void {
        this.collapseMenuToggles();
        this.isHoursMenuCollapsed = !this.isHoursMenuCollapsed;
    }

    toggleMapMenu(): void {
        this.collapseMenuToggles();
    }

    toggleAccountMenu(): void {
        this.collapseMenuToggles();
        this.isAccountMenuCollapsed = !this.isAccountMenuCollapsed;
    }

    toggleBuyMenu(): void {
        this.collapseMenuToggles();
        this.isBuyMenuCollapsed = !this.isBuyMenuCollapsed;
    }

    toggleHelpMenu(): void {
        this.collapseMenuToggles();
        this.isHelpMenuCollapsed = !this.isHelpMenuCollapsed;
    }

    async navigateToHome(): Promise<boolean> {
        return this.menuService.navigateToHome();
    }

    async navigateToItinerary(): Promise<boolean> {
        return this.menuService.navigateToItinerary();
    }

    async navigateToFavorites(): Promise<boolean> {
        return this.menuService.navigateToFavorites();
    }

    async openPlans(): Promise<void> {
        await this.menuService.openWebPageInBrowser(TRAVYS_PLANS_URL);
    }

    async openTrafficInformation(): Promise<void> {
        await this.menuService.openWebPageInBrowser(TRAVYS_TRAFFIC_INFORMATION_URL);
    }

    async openFaq(): Promise<void> {
        await this.menuService.openWebPageInBrowser(TRAVYS_FAQ_URL);
    }

    async openCustomerService(): Promise<void> {
        await this.menuService.openWebPageInBrowser(TRAVYS_CUSTOMER_SERVICE_URL);
    }

    private collapseMenuToggles(): void {
        this.isHoursMenuCollapsed = true;
        this.isBuyMenuCollapsed = true;
        this.isAccountMenuCollapsed = true;
        this.isHelpMenuCollapsed = true;
    }
}
