import { Injectable } from '@angular/core';
import { environment } from '@traas/boldor/environments';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Platform } from '@ionic/angular';
import { LoggingService } from '@traas/common/logging';
import { PlatformUtilsService } from '@traas/common/utils';
import { EMPTY, firstValueFrom, interval, merge, NEVER, Observable, of, Subscriber, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, takeWhile, tap } from 'rxjs/operators';
import { CallbackID, Geolocation, PermissionStatus, Position } from '@capacitor/geolocation';
import { GeopositionAdapter } from '@traas/boldor/all-models';
import { Capacitor } from '@capacitor/core';

const PERMISSION_DENIED = 1;
const POSITION_UNAVAILABLE = 2;
const TIMEOUT = 3;
const throttleOfGpsEventsInMS = 5000;
const BUFFER_SIZE = 1;

@Injectable({ providedIn: 'root' })
export class GeolocationService {
    private readonly $isGeolocationServiceAvailableValue: Observable<boolean>;
    private readonly $watchGeopositionValue: Observable<GeopositionAdapter>;
    protected readonly locationOptions: PositionOptions = {
        // https://stackoverflow.com/questions/46327491/how-do-i-force-minimum-accuracy-for-phonegap-gps-geolocation
        enableHighAccuracy: true,
        // There is no guarantee that the gps-hardware-chip is really used,
        // it's only a "wish" that you tell this plugin to use (if possible) gps-satellites and
        // strongly depends on environmental conditions

        maximumAge: 0,

        // Maximum waiting time to get geolocation set to 5 seconds
        timeout: 5000,
    };
    private isLocationAvailable: boolean;

    private $geopositionListener: Observable<GeopositionAdapter>;

    constructor(
        protected platformUtilsService: PlatformUtilsService,
        protected diagnosticService: Diagnostic,
        protected logger: LoggingService,
        protected platform: Platform,
    ) {
        this.$isGeolocationServiceAvailableValue = this.$getGeolocationServiceAvailabilityDependingOnPlatform().pipe(
            shareReplay(BUFFER_SIZE),
            distinctUntilChanged(),
        );
        this.$watchGeopositionValue = this.$isGeolocationServiceAvailableValue.pipe(
            filter((value) => value === true),
            switchMap(() => this.$buildWatchGeoposition()),
            shareReplay({ refCount: true, bufferSize: BUFFER_SIZE }),
        );
    }

    /**
     * Return true if GPS is enabled in settings of the device AND if there is authorization
     */
    $isGeolocationServiceAvailable(): Observable<boolean> {
        return this.$isGeolocationServiceAvailableValue;
    }

    $watchGeoposition(): Observable<GeopositionAdapter> {
        return this.$watchGeopositionValue;
    }

    requestGeolocationPermission(): Promise<PermissionStatus> {
        return Geolocation.requestPermissions();
    }

    checkLocationPermissions(): void {
        this.$isGeolocationServiceAvailable()
            .pipe(takeWhile((isAvailable) => !isAvailable))
            .subscribe((isLocationAvailable) => {
                if (!isLocationAvailable) {
                    this.requestGeolocationPermission().then(
                        () => {},
                        (error) => console.warn(error),
                    );
                }
            });
    }

    $availableGpsPosition(): Observable<GeopositionAdapter> {
        return this.$isGeolocationServiceAvailable().pipe(switchMap((isAvailable) => (isAvailable ? this.$watchGeoposition() : NEVER)));
    }

    getHere(): Promise<GeopositionAdapter> {
        return firstValueFrom(this.$watchGeoposition());
    }

    private $getGeolocationServiceAvailabilityDependingOnPlatform(): Observable<boolean> {
        if (Capacitor.getPlatform() === 'web') {
            return this.$isLocationAvailableOnWeb();
        }

        if (this.platformUtilsService.isApp()) {
            return this.$isLocationAvailableOnNativePlatform();
        }

        this.isLocationAvailable = false;
        return of(false);
    }

    private $isLocationAvailableOnNativePlatform(): Observable<boolean> {
        const $availabilityChange = merge(this.$locationStateChanged(), this.platform.resume.asObservable()).pipe(
            switchMap(() => this.getLocationAvailability()),
        );
        return merge(this.getLocationAvailability(), $availabilityChange).pipe(debounceTime(100));
    }

    private $isLocationAvailableOnWeb(): Observable<boolean> {
        console.debug("Check location's permission ...");
        return new Observable<boolean>((subscriber) => {
            // Vérification des permissions
            this.checkGeolocationPermission().then((permissionState) => {
                if (permissionState === 'prompt' || permissionState === 'granted') {
                    navigator.geolocation.getCurrentPosition(
                        () => this.handlePositionGranted(subscriber),
                        (error) => this.handlePositionError(error, subscriber),
                        { timeout: 1000 },
                    );
                } else {
                    this.handlePermissionDenied(subscriber);
                }
            });
        });
    }

    private checkGeolocationPermission(): Promise<PermissionState> {
        if (navigator.permissions) {
            return navigator.permissions
                .query({ name: 'geolocation' })
                .then((result) => result.state)
                .catch((error) => {
                    this.logger.logLocalError(error);
                    return 'denied'; // Fallback state
                });
        } else {
            return Promise.resolve('prompt'); // Fallback si l'API Permissions n'est pas supportée
        }
    }

    private handlePositionGranted(subscriber: Subscriber<boolean>): void {
        console.debug('Location authorized !');
        this.isLocationAvailable = true;
        subscriber.next(true);
    }

    private handlePositionError(error: GeolocationPositionError, subscriber: Subscriber<boolean>): void {
        console.error(`Location error: ${error.message}`);
        this.isLocationAvailable = false;
        subscriber.next(false);
    }

    private handlePermissionDenied(subscriber: Subscriber<boolean>): void {
        console.error('Location permission denied');
        this.isLocationAvailable = false;
        subscriber.next(false);
    }

    private async getLocationAvailability(): Promise<boolean> {
        let hasPermissions = false;
        let isLocationAvailable = false;
        try {
            hasPermissions = (await Geolocation.checkPermissions()).location === 'granted';
        } catch (error) {
            console.warn('Error during checking geolocation permissions :', error);
        }
        try {
            if (this.platformUtilsService.isAndroid()) {
                isLocationAvailable = await this.diagnosticService.isGpsLocationAvailable();
            } else {
                isLocationAvailable = await this.diagnosticService.isLocationAvailable();
            }
        } catch (error) {
            console.warn('Error during checking geolocation availability :', error);
        }

        this.isLocationAvailable = hasPermissions && isLocationAvailable;
        if (environment.isDebugMode) {
            console.log(`::: isLocationAvailable = ${isLocationAvailable}`);
        }
        return isLocationAvailable;
    }

    protected $buildWatchGeoposition(): Observable<GeopositionAdapter> {
        if (this.$geopositionListener) {
            return this.$geopositionListener;
        }
        this.$geopositionListener = timer(0, throttleOfGpsEventsInMS).pipe(
            filter(() => this.isLocationAvailable),
            switchMap(async () => {
                try {
                    return await this.getPositionByWatching(this.locationOptions);
                } catch (error) {
                    console.warn('Fails to getCurrentPosition: ', error);
                    return null;
                }
            }),
            filter((position) => !!position),
            map((position) => new GeopositionAdapter(position)),
            distinctUntilChanged((old, current) => {
                if (!old || !current) {
                    return false; // pass to next operator
                }
                // pass to next operator if returns false
                return current.hasSameLatAndLng(old) || old.getData().timestamp === current.getData().timestamp;
            }),
            catchError(() => {
                // This error is not important to be logged
                return EMPTY;
            }),
        );
        return this.$geopositionListener;
    }

    private createMockWatchPosition(subscriber: Subscriber<GeopositionAdapter>): string {
        const belAir = {
            timestamp: 1111111111,
            coords: {
                accuracy: 160,
                latitude: 46.204296,
                longitude: 6.142796,
            },
        };

        const aigleGare = {
            timestamp: 1111111111,
            coords: {
                accuracy: 160,
                latitude: 46.316845,
                longitude: 6.96368,
            },
        };

        const rueLangallerie9 = {
            timestamp: 1111111111,
            coords: {
                accuracy: 160,
                latitude: 46.5201144,
                longitude: 6.6384173,
            },
        };

        const placeDeNeuve = {
            timestamp: 1111111111,
            coords: {
                latitude: 46.204226,
                longitude: 6.141,
                accuracy: 160,
            },
        };

        const chezLaure = {
            timestamp: 1111111111,
            coords: {
                latitude: 46.535058,
                longitude: 6.597941,
                accuracy: 160,
            },
        };

        const presqueChezLaure = {
            timestamp: 1111111111,
            coords: {
                latitude: 46.5280244,
                longitude: 6.6011479,
                accuracy: 160,
            },
        };

        const chezVirginie = {
            timestamp: 1111111111,
            coords: {
                latitude: 46.16365650139868,
                longitude: 6.113525498888491,
                accuracy: 160,
            },
        };

        interval(10000)
            .pipe(
                tap(() => {
                    const randomIntFromInterval: (min, max) => number = (min, max) => {
                        // min and max included
                        return Math.floor(Math.random() * (max - min + 1) + min);
                    };

                    const rndInt = randomIntFromInterval(1, 2);
                    const randomLocation = aigleGare;
                    console.log('randomLocation', rndInt);
                    subscriber.next(new GeopositionAdapter(randomLocation as Position));
                }),
            )
            .subscribe();

        return 'yolo';
    }

    private $locationStateChanged(): Observable<void> {
        return new Observable((subscriber) => {
            this.diagnosticService.registerLocationStateChangeHandler(() => {
                subscriber.next();
                return !subscriber.closed;
            });
        });
    }

    private getPositionByWatching(options: PositionOptions = {}): Promise<Position> {
        return new Promise<Position>((resolve, reject) => {
            const watchIdPromise = Geolocation.watchPosition(options, (position, error) => {
                if (error) {
                    reject(error);
                } else if (!position) {
                    reject(`Position is null`);
                } else {
                    resolve(position);
                }
                watchIdPromise.then((watchId: CallbackID) => {
                    Geolocation.clearWatch({ id: watchId });
                });
            }).catch((initializationError) => {
                reject(initializationError);
            });
        });
    }
}
