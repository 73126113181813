import { Injectable } from '@angular/core';
import { Price as PriceGql, ReductionType as ReductionTypeGql } from '@traas/boldor/graphql-generated/graphql';
import { GqlQuickArticle } from '../../../features/ticket/services/quick-article.service';
import { Article, Currency, Price, Reduction, ReductionType, Ticket, TicketAdapter, TicketState } from '@traas/boldor/all-models';
import { CategoryTicketsAdapter } from '../../../models/ticket/category-tickets';

@Injectable({
    providedIn: 'root',
})
export class ArticleService {
    static formatAmountInCentsInHumanReadableValue(amountInCents: number): string {
        return (amountInCents / 100).toFixed(2);
    }

    static formatPrices(unitPrices: PriceGql[] = []): Price[] {
        return unitPrices.map((currentUnitPrice) => {
            return ArticleService.formatPrice(currentUnitPrice);
        });
    }

    private static formatPrice(currentUnitPrice: PriceGql): Price {
        const unitPrice = ArticleService.formatAmountInCentsInHumanReadableValue(currentUnitPrice.amountInCents);
        return { currency: currentUnitPrice.currency as unknown as Currency, value: unitPrice };
    }

    adaptQuickArticles(quickQuickArticles: GqlQuickArticle[]): TicketAdapter[] {
        return quickQuickArticles.map((ticket) => {
            return this.adaptQuickArticle(ticket);
        });
    }

    castTicketsIntoCategoryTickets(tickets: TicketAdapter[]): CategoryTicketsAdapter[] {
        const distinctCategories = Array.from(new Set(tickets.map((ticket) => ticket.getCategoryId())));
        return distinctCategories.map((category) => {
            return this.createCategoryTicketsAdapter(category, tickets);
        });
    }

    private convertReductionType(reductionType: ReductionTypeGql): ReductionType {
        switch (reductionType) {
            case ReductionTypeGql.Bike:
                return ReductionType.BIKE;
            case ReductionTypeGql.HalfFare:
                return ReductionType.HALF_FARE;
            case ReductionTypeGql.NoReduction:
                return ReductionType.NO_REDUCTION;
        }
    }

    private convertReduction(reduction: GqlQuickArticle['reduction']): Reduction {
        return {
            description: reduction.description,
            id: this.convertReductionType(reduction.id),
        };
    }

    private adaptQuickArticle(ticket: GqlQuickArticle): TicketAdapter {
        const article: Article = {
            category: { description: ticket.category.description, id: ticket.category.description },
            categoryDescription: ticket.categoryDescription,
            travelType: ticket.travelType,
            smsCode: ticket.smsCode,
            description: ticket.description,
            id: ticket.articleId,
            reduction: this.convertReduction(ticket.reduction),
            title: ticket.title,
            isSupersaver: false,
            travelClass: undefined,
            validity: undefined,
            zones: ticket.zones,
            prices: ArticleService.formatPrices(ticket.prices),
            locationsValidity: undefined,
            locationsChoice: undefined,
            tarifOwner: undefined,
            paymentMeans: ticket.paymentMeans,
            tripClass: ticket.tripClass,
            ticketPriceLabel: undefined,
            conditions: undefined,
            includes: undefined,
            roundTrip: undefined,
        };

        const data: Ticket = {
            article,
            state: TicketState.Unknown,
            id: undefined,
            departureStopName: undefined,
            qrCodeData: undefined,
            purchaseDate: undefined,
            duration: undefined,
            prices: ArticleService.formatPrices(ticket.prices),
            fqCode: undefined,
            passenger: undefined,
            orderNumber: undefined,
            referenceNumber: undefined,
            ticketNumber: undefined,
            type: undefined,
            articleNumber: undefined,
            isCancelled: undefined,
        };

        return new TicketAdapter(data);
    }

    private createCategoryTicketsAdapter(category: string, tickets: TicketAdapter[]): CategoryTicketsAdapter {
        const filteredTicketAdapters = tickets.filter((ticket) => {
            return ticket.getCategoryId() === category;
        });

        const [firstTicket] = filteredTicketAdapters;
        const firstArticle = firstTicket.getArticle();
        const categoryDescription = firstArticle.categoryDescription || null;
        return new CategoryTicketsAdapter({
            category: {
                title: category,
                description: categoryDescription,
            },
            tickets: filteredTicketAdapters,
        });
    }
}
