import { ItineraryState } from './itinerary.state';
import { createFeatureSelector, createSelector, createSelectorFactory } from '@ngrx/store';
import { ItineraryAdapter } from '../../../models/itinerary/itinerary';
import { Itinerary } from '@traas/boldor/all-models';
import { isEqualMemoized } from './isEqualMemoized';

export const selectItinerary = createFeatureSelector<ItineraryState>('itinerary');

export const getAllItineraries = createSelector(selectItinerary, (state: ItineraryState) => state.itineraries);

export const getRefresher = createSelector(selectItinerary, (itineraryState: ItineraryState) => itineraryState.refresher);

export const getItinerariesInScrollViewport = createSelector(
    selectItinerary,
    (itineraryState: ItineraryState) => itineraryState.inScrollViewport,
);

export const getQueryKind = createSelector(selectItinerary, (itineraryState: ItineraryState) => itineraryState.queryKind);

export const getSelectedItinerary = createSelector(selectItinerary, (itineraryState: ItineraryState) => itineraryState?.selected.itinerary);

export const getSelectedDatePickerBarSearchOption = createSelector(
    selectItinerary,
    (itineraryState: ItineraryState) => itineraryState?.selected.datePickerBarSearchOption,
);

export const getSelectedItineraryConditionsMessages = createSelector(
    selectItinerary,
    (itineraryState: ItineraryState) => itineraryState?.selected.conditionsMessages,
);

export const getEnabledTransportModes = createSelectorFactory(isEqualMemoized)(
    selectItinerary,
    ({ enabledTransportModes }: ItineraryState) => enabledTransportModes,
);

export const getEnableOnlyFastestRoutes = createSelector(selectItinerary, ({ enableOnlyFastestRoutes }) => enableOnlyFastestRoutes);

export const hasActiveTransportModeFilters = createSelector(
    getEnabledTransportModes,
    (enabledTransportModes) => enabledTransportModes.length > 0,
);

export function findSameItineraryById(itinerary: ItineraryAdapter, itineraries: Itinerary[]): Itinerary | undefined {
    return itineraries?.find((current) => new ItineraryAdapter(current).getId() === itinerary.getId());
}
