import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
    getCompanyFromNetworkId,
    MAX_LINES_ICON_LIST_LIMIT,
    THRESHOLD_IN_MINUTES_TO_SHOW_FIRST_WALKING_LEG,
} from '../../../business-rules.utils';
import { LegAdapter } from '../../../models/itinerary/leg';
import * as moment from 'moment';
import { Line } from '@traas/common/models';

const BY_FOOT_ICON_OFFSET = 2;

@Component({
    selector: 'app-lines-icon-horizontal-list',
    templateUrl: './lines-icon-horizontal-list.component.html',
    styleUrls: ['./lines-icon-horizontal-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinesIconHorizontalListComponent implements OnInit {
    private _lines: Line[] = [];
    private _legs: LegAdapter[] = [];

    @Input()
    set lines(value: Line[]) {
        const oldLinesIds = this._lines.map(({ id }) => id).join('-');
        const newLinesIds = value.map(({ id }) => id).join('-');
        if (oldLinesIds === newLinesIds) {
            return;
        }
        this._lines = value;
        this.initDenormalizedLines();
    }

    get lines(): Line[] {
        return this._lines;
    }

    @Input()
    set legs(value: LegAdapter[]) {
        const oldLegs = this._legs
            .map((leg) => leg.getServiceNumber())
            .filter((serviceNumber) => serviceNumber !== null)
            .join('-');
        const newLegs = value
            .map((leg) => leg.getServiceNumber())
            .filter((serviceNumber) => serviceNumber !== null)
            .join('-');
        if (oldLegs === newLegs) {
            return;
        }
        this._legs = value;
        this.initDenormalizedLines();
    }

    get legs(): LegAdapter[] {
        return this._legs;
    }

    delayOfWalkingLegInMinutes: number;
    linesToShow: Line[] = [];
    hasTooManyLines: boolean;
    isStartByFoot: boolean;

    ngOnInit(): void {
        this.linesToShow = [];
        this.initDenormalizedLines();
    }

    trackById(index: number, { id }: Line): string | number {
        return id ? id : index;
    }

    getCompanyFromNetworkId(networkId: string): string {
        return getCompanyFromNetworkId(networkId);
    }

    hasRightJoin(index: number, isLastItem: boolean): boolean {
        return this.hasTooManyLines ? (this.isStartByFoot ? false : index < 1) : !isLastItem;
    }

    hasRightDashedJoin(index: number): boolean {
        return this.hasTooManyLines ? (this.isStartByFoot ? index < 1 : index === 1) : false;
    }

    private initDenormalizedLines(): void {
        this.hasTooManyLines = this.getHasTooManyLines();
        this.isStartByFoot = this.isDisplayStartByFoot();
        this.linesToShow = [];

        if (this.hasTooManyLines) {
            if (this.isStartByFoot) {
                const [firstLine] = this.lines;
                this.linesToShow.push(firstLine);
            } else {
                for (let i = 0; i <= 1; i++) {
                    this.linesToShow.push(this.lines[i]);
                }
            }
            const lastLine = this.lines[this.lines.length - 1];
            this.linesToShow.push(lastLine);
        } else {
            this.lines.forEach((line) => {
                this.linesToShow.push(line);
            });
        }
    }

    private getHasTooManyLines(): boolean {
        return (
            this.lines.length > MAX_LINES_ICON_LIST_LIMIT ||
            (this.isStartByFoot && this.lines.length + BY_FOOT_ICON_OFFSET > MAX_LINES_ICON_LIST_LIMIT)
        );
    }

    private isDisplayStartByFoot(): boolean {
        const [firstLeg] = this.legs;
        if (firstLeg?.isByFoot()) {
            this.delayOfWalkingLegInMinutes = this.computeDelayOfWalkingLeg(firstLeg);
            return this.delayOfWalkingLegInMinutes >= THRESHOLD_IN_MINUTES_TO_SHOW_FIRST_WALKING_LEG;
        }
        return false;
    }

    private computeDelayOfWalkingLeg(firstLeg: LegAdapter): number {
        const delayOfLeg = moment.duration(firstLeg.getWalkDuration(), 'minutes');
        return delayOfLeg.seconds() ? delayOfLeg.minutes() + 1 : delayOfLeg.minutes();
    }
}
