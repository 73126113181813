import { DivIcon, DivIconOptions } from 'leaflet';
import { ChangePlaceEventSourceEnum, MyGpsPositionPlace, Place } from '@traas/boldor/all-models';
import { DynamicPlaceAdapter } from './dynamic-place.adapter';

export class MyGpsPositionDynamicPlaceAdapter extends DynamicPlaceAdapter<Place<MyGpsPositionPlace>> {
    private readonly changePlaceSourceEvent: ChangePlaceEventSourceEnum = ChangePlaceEventSourceEnum.MyGpsPositionSelection;

    constructor(data: Place<MyGpsPositionPlace>) {
        super(data);
    }

    getChangePlaceEvent(): ChangePlaceEventSourceEnum {
        return this.changePlaceSourceEvent;
    }

    getDivIcon(iconOptions?: DivIconOptions): DivIcon {
        return null;
    }

    isDrawOnEndpointRequired(): boolean {
        return true;
    }

    isDrawOnMapRequired(): boolean {
        return false;
    }

    isNeededToFitMap(): boolean {
        return true;
    }

    transformToCoordinates(): GeolocationCoordinates {
        return this.getData().transformToCoordinates();
    }
}
