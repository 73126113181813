import { v4 as uuid } from 'uuid';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

const CRYPTO_SIZE = 32;
const LOCAL_STORAGE_VERIFIER_KEY = 'codeVerifierMap';

@Injectable({ providedIn: 'root' })
export class PkceService {
    getCodeVerifier(state: string): string {
        const codeVerifierMap = this.getCodeVerifierMapFromLocalStorage();
        return codeVerifierMap[state];
    }

    private generateCodeChallenge(codeVerifier: string): string {
        const hash = CryptoJS.SHA256(codeVerifier);
        const hashedVerifier = hash.toString(CryptoJS.enc.Hex);
        const words = CryptoJS.enc.Hex.parse(hashedVerifier);
        const base64Digest = CryptoJS.enc.Base64.stringify(words);

        return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }

    getVerifierAndChallenge(): { verifierKey: string; codeChallenge: string } {
        const codeVerifier = this.generateCodeVerifier();
        const codeChallenge = this.generateCodeChallenge(codeVerifier);
        const verifierKey = uuid();

        this.saveCodeVerifierToLocalStorage(verifierKey, codeVerifier);

        return { verifierKey, codeChallenge };
    }

    private generateCodeVerifier(): string {
        const randomWords = CryptoJS.lib.WordArray.random(CRYPTO_SIZE);
        return randomWords.toString(CryptoJS.enc.Hex);
    }

    private getCodeVerifierMapFromLocalStorage(): { [key: string]: any } {
        const codeVerifierMap = localStorage.getItem(LOCAL_STORAGE_VERIFIER_KEY);
        return codeVerifierMap ? JSON.parse(codeVerifierMap) : {};
    }

    private saveCodeVerifierToLocalStorage(key: string, value: string): void {
        const codeVerifierMap = this.getCodeVerifierMapFromLocalStorage();
        codeVerifierMap[key] = value;
        localStorage.setItem(LOCAL_STORAGE_VERIFIER_KEY, JSON.stringify(codeVerifierMap));
    }
}
