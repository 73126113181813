import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getPassengerTypeIconName } from '../../../../business-rules.utils';
import { PassengerType } from '@traas/boldor/graphql-generated/graphql';
import { ExpandableComponent } from '../../../../components/expandable/expandable.component';
import { getFormattedPriceFromPrice } from '@traas/boldor/all-helpers';
import { Price, Ticket } from '@traas/boldor/all-models';

@Component({
    selector: 'app-ticket-resume',
    templateUrl: './ticket-resume.component.html',
    styleUrls: ['./ticket-resume.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketResumeComponent extends ExpandableComponent {
    @Input() expanded = false;
    @Input() ticket: Ticket;

    constructor() {
        super(null);
    }

    getFormattedPrice(price: Price): string {
        return getFormattedPriceFromPrice(price, false, false);
    }

    getPassengerTypeIconName(passengerType: PassengerType): string {
        return getPassengerTypeIconName(passengerType);
    }
}
