import { AbstractAdapter, Place, PlaceAddress, PlacesTypeInterface } from '@traas/boldor/all-models';
import * as hash from 'object-hash';

export class AddressAdapter extends AbstractAdapter<PlaceAddress> implements Place<PlaceAddress> {
    isFromHistory: boolean;

    constructor(data: PlaceAddress) {
        super(data);
    }

    createAddressPlacesHistory(): this {
        this.isFromHistory = true;
        this.getNameOrPath = (): string => 'time';
        return this;
    }

    transformToCoordinates(): GeolocationCoordinates {
        return {
            accuracy: null,
            latitude: this.getData().latLon[0],
            longitude: this.getData().latLon[1],
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            speed: null,
        };
    }

    getName(): string {
        return `${this.getAddress()}${this.getCity() ? ', ' + this.getCity() : ''}`;
    }

    getNameOrPath(): string {
        return 'search';
    }

    isGpsPosition(): boolean {
        return false;
    }

    getPlaceType(): keyof PlacesTypeInterface {
        return 'address';
    }

    getId(): string {
        return hash.sha1(this.getData());
    }

    getAddress(): string {
        return this.getData().address;
    }

    getCity(): string {
        return this.getData().city;
    }

    getLatitude(): number {
        return this.getData().latitude;
    }

    getLongitude(): number {
        return this.getData().longitude;
    }
}
