import { PaymentAction, PaymentActionTypes } from './payment.action';
import { initialState, PaymentState, PaymentStatus, TicketStatus } from './payment.state';

export function paymentReducer(state: PaymentState = initialState, action: PaymentAction): PaymentState {
    switch (action.type) {
        case PaymentActionTypes.BuyArticles:
            return {
                ...state,
                paymentStatus: PaymentStatus.INITIAL,
                ticketStatus: TicketStatus.INITIAL,
                articleType: action.payload.articleType,
                selectedCreditCard: action.payload.selectedCreditCard,
                createCreditCard: action.payload.createCreditCard,
            };

        case PaymentActionTypes.BuyArticlesSuccess: {
            return {
                ...state,
                paymentStatus: PaymentStatus.PREPARATION,
                orderId: action.payload.orderId,
            };
        }

        case PaymentActionTypes.BuyArticlesFail: {
            return {
                ...state,
                paymentStatus: PaymentStatus.BUY_FAIL,
            };
        }

        case PaymentActionTypes.ProcessPayment:
            return {
                ...state,
                paymentStatus: PaymentStatus.VERIFICATION,
                ticketStatus: TicketStatus.CREATION,
                isCanceledByUser: false,
            };

        case PaymentActionTypes.ProcessPaymentSuccess:
            return {
                ...state,
                paymentStatus: PaymentStatus.VERIFIED,
            };

        case PaymentActionTypes.ProcessPaymentFail:
            return {
                ...state,
                paymentStatus: PaymentStatus.FAIL,
            };

        case PaymentActionTypes.CheckTicketGeneration:
            return {
                ...state,
                paymentStatus: PaymentStatus.VALIDATION,
                ticketStatus: TicketStatus.CREATION,
            };

        case PaymentActionTypes.CancelPayment:
            return {
                ...state,
                paymentStatus: PaymentStatus.ABORT,
                ticketStatus: TicketStatus.INITIAL,
                isCanceledByUser: true,
            };

        case PaymentActionTypes.CancelPaymentSuccess:
            return {
                ...state,
                ticketStatus: TicketStatus.INITIAL,
            };

        case PaymentActionTypes.PaymentTimedOut:
            return {
                ...state,
                paymentStatus: PaymentStatus.TIMED_OUT,
            };

        case PaymentActionTypes.RecoveryFailed:
            return {
                ...state,
                ticketStatus: TicketStatus.RECOVERY_FAILED,
            };

        case PaymentActionTypes.GenerateTicketSuccess:
            return {
                ...state,
                paymentStatus: PaymentStatus.CAPTURED,
                ticketStatus: TicketStatus.CREATED,
            };

        case PaymentActionTypes.GenerateTicketFail:
            return {
                ...state,
                paymentStatus: PaymentStatus.FAIL,
            };

        case PaymentActionTypes.ResetPaymentState:
            return initialState;

        case PaymentActionTypes.SetCardPaymentMethodName:
            return {
                ...state,
                cardPaymentMethodName: action.cardPaymentMethodName,
            };
        case PaymentActionTypes.SetMobilePaymentMethodName:
            return {
                ...state,
                mobilePaymentMethodName: action.mobilePaymentMethodName,
            };
        case PaymentActionTypes.SetPaymentMethodName:
            return {
                ...state,
                paymentMethodName: action.paymentMethodName,
            };

        default:
            return state;
    }
}
