<ion-toolbar>
    <ion-buttons slot="start" *ngIf="canGoBack">
        <ion-back-button oitClickUsingBackButton [defaultHref]="defaultHref" text=""></ion-back-button>
    </ion-buttons>
    <ion-title>
        <div class="center">
            <ion-icon [name]="iconName" *ngIf="iconUrl || iconName" [src]="iconUrl" [ngStyle]="{ 'font-size': iconSize }"></ion-icon>
            <span class="e2e-page-title">{{ title }}</span>
        </div>
    </ion-title>
    <ion-buttons slot="end">
        <ion-button
            *ngIf="showHomeLogo"
            (click)="homeButtonClicked()"
            [disabled]="isHomeButtonDisabled"
            [style.opacity]="isHomeButtonDisabled ? '1' : ''"
        >
            <ion-icon src="assets/icon/headerlogo.svg"></ion-icon>
        </ion-button>
    </ion-buttons>
</ion-toolbar>
