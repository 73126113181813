import { initialState, TicketState } from './ticket.state';
import { TicketAction, TicketActionTypes } from './ticket.action';

export function ticketReducer(state: TicketState = initialState, action: TicketAction): TicketState {
    switch (action.type) {
        case TicketActionTypes.Load:
            return { ...state, isLoading: true };
        case TicketActionTypes.LoadError:
            return {
                ...state,
                isLoading: false,
                quickTickets: [],
            };
        case TicketActionTypes.LoadSuccess:
            return {
                ...state,
                isLoading: false,
                quickTickets: action.payload.quickArticles,
                isInitialized: true,
            };

        case TicketActionTypes.LoadQuickTicketsByZone:
            return { ...state, isLoading: true };
        case TicketActionTypes.LoadQuickTicketsByZoneError:
            return {
                ...state,
                isLoading: false,
                quickTicketsByZone: [],
            };
        case TicketActionTypes.LoadQuickTicketsByZoneSuccess:
            return {
                ...state,
                isLoading: false,
                quickTicketsByZone: action.payload.quickTicketsByZone,
                isInitialized: true,
            };

        case TicketActionTypes.LoadTicketList:
            return { ...state, isLoading: true };
        case TicketActionTypes.LoadTicketListError:
            return {
                ...state,
                isLoading: false,
                ticketList: [],
            };
        case TicketActionTypes.LoadTicketListSuccess:
            return {
                ...state,
                isLoading: false,
                ticketList: action.payload.ticketList,
                isInitialized: true,
            };
        default:
            return state;
    }
}
