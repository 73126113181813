<ng-container *ngIf="showSkip3DSecure">
    <ion-item lines="none">
        <ion-checkbox
            slot="start"
            [checked]="skip3DSecure"
            (ionChange)="onSkip3DSecureChange($event)"
            class="e2e-use-no3D-secure-under"
            mode="md"
        ></ion-checkbox>
        <ion-label class="ion-text-wrap">
            {{ 'credit-cards.skip3DSecure' | translate : { thresholdAmount: getSkip3DSecureMinimumAmountThreshold() } }}
        </ion-label>
    </ion-item>
</ng-container>
