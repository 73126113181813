import { NgModule } from '@angular/core';
import { TravysConditionsOfSaleComponent } from './travys-conditions-of-sale/travys-conditions-of-sale.component';
import { COMPANY_COMPONENTS, DynamicComponentService } from '@traas/boldor/common/services/dynamic-component.service';

@NgModule({
    declarations: [TravysConditionsOfSaleComponent],
    exports: [TravysConditionsOfSaleComponent],
})
export class TravysConditionsOfSaleModule {
    constructor(private dynamicComponentService: DynamicComponentService) {
        this.dynamicComponentService.setComponentType(COMPANY_COMPONENTS.Cgv, TravysConditionsOfSaleComponent);
    }
}
