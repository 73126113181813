import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SafeHtmlPipe } from './safeHtml/safe-html.pipe';
import { StopNamePipe } from './stop-name.pipe';
import { DayOfWeekPipe } from './day-of-week.pipe';

@NgModule({
    imports: [CommonModule, IonicModule],
    declarations: [StopNamePipe, SafeHtmlPipe, DayOfWeekPipe],
    exports: [StopNamePipe, SafeHtmlPipe, DayOfWeekPipe],
})
export class SharedPipeModule {}
