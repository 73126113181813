<div class="ticket">
    <ion-row class="ion-align-items-center">
        <ion-col class="title">
            {{ 'my-journeys.ticket-summary.quick-ticket' | translate }}
        </ion-col>
    </ion-row>
    <div class="ticket-container">
        <div class="ticket-infos">
            <ng-container *ngIf="$article() | async as article">
                <ion-row class="article">
                    <ion-col size="12">
                        {{ article.title }}
                    </ion-col>
                </ion-row>
                <ion-row class="zone">
                    <ion-col size="12">
                        {{ getZonesTextFromZones(article.zones) }}
                    </ion-col>
                </ion-row>
                <ion-row class="departure-datetime">
                    <ion-col size="12">
                        <app-human-readable-date [departureDatetime]="article.validity.from"></app-human-readable-date>
                    </ion-col>
                </ion-row>
            </ng-container>
            <ng-container *ngIf="quickTicket.isProcessing">
                <app-human-readable-date [departureDatetime]="quickTicket.tickets[0].purchaseDate"></app-human-readable-date>
            </ng-container>

            <div *ngIf="!this.showOperationsIcons && isRefundable" class="ticket-icons-price">
                <ion-icon (click)="confirmRefundOrder()" class="ticket-delete" src="assets/icons/grid/delete.svg"></ion-icon>
                <div class="ticket-price">
                    {{ totalPrice }}
                </div>
            </div>
            <div *ngIf="showOperationsIcons" class="ticket-icons-price">
                <ion-icon class="operation-icon separation-right" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
                <div class="ticket-price" [class.strikethrough]="areThirdPartyPaidTickets(order)">
                    {{ totalPrice }}
                </div>
            </div>
        </div>
        <div class="ticket-arrow">
            <ion-icon src="assets/icons/menu/chevron.svg"></ion-icon>
        </div>
    </div>
</div>
