import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';

import { DataVersionService } from './services/common/data-version/data-version.service';
import { DynamicPlaceService } from './services/common/dynamic-place/dynamic-place.service';
import { GeolocationService } from './services/common/geolocation/geolocation.service';
import { LineService } from './services/common/line/line.service';
import { PhysicalStopService } from './services/common/physical-stop/physical-stop.service';
import { FailAction, ObservableTypedStorage, OnlineService, PlatformUtilsService, StorageService, TypedStorage } from '@traas/common/utils';
import { TabsService } from './services/common/tabs/tabs.service';
import { TimerService } from './services/common/timer/timer.service';
import { ToasterService } from './services/common/toaster/toaster.service';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { HomeStoreModule } from './features/home/store/home-store.module';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { ConfigurationService } from './services/common/configuration/configuration.service';
import { CompanyService } from './services/common/company/company.service';
import { ReferencesService } from './services/common/references/references.service';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { RequestHeadersInterceptor } from '@traas/boldor/common/http-interceptors/request-headers-interceptor.service';
import { DeviceIdService } from '@traas/boldor/common/http-interceptors/device-id.service';
import { CorrelationIdService } from '@traas/boldor/common/http-interceptors/correlation-id.service';

function failActionLogger(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (action instanceof FailAction) {
            console.log('state', state);
            console.log('action {type, payload}', action.type, action.payload);
        }
        return reducer(state, action);
    };
}

const metaReducers: MetaReducer[] = [failActionLogger];

/*
BoldorFeatureShellCoreModule should have only providers (services), no declaration. Never import CodeModule in other module than AppModule.
 */
@NgModule({
    imports: [
        CommonModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        HttpClientModule,
        StoreModule.forRoot(
            {
                router: routerReducer,
            },
            {
                metaReducers,
                runtimeChecks: {
                    strictStateImmutability: false,
                    strictActionImmutability: false,
                    strictStateSerializability: false,
                    strictActionSerializability: false,
                    strictActionWithinNgZone: false,
                    strictActionTypeUniqueness: true,
                },
            },
        ),
        EffectsModule.forRoot([]),
        HomeStoreModule,
        StoreRouterConnectingModule.forRoot(),
        /*StoreDevtoolsModule.instrument({
            actionsBlocklist: [GridActionTypes.DragMove],
            maxAge: 60,
        }),*/
        IonicStorageModule.forRoot({
            name: 'boldor',
            // If you change driverOrder, think to update tools/scripts/protractor/protractor-start-env.sh
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
        }),
        OverlayModule,
    ],
    exports: [],
})
/**
 * Singleton service documentation : https://angular.io/guide/singleton-services
 */
export class BoldorFeatureShellCoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: BoldorFeatureShellCoreModule,
    ) {
        if (parentModule) {
            throw new Error('BoldorFeatureShellCoreModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<BoldorFeatureShellCoreModule> {
        return {
            ngModule: BoldorFeatureShellCoreModule,
            providers: [
                ConfigurationService,
                CompanyService,
                DataVersionService,
                Diagnostic,
                DynamicPlaceService,
                GeolocationService,
                LineService,
                OnlineService,
                PhysicalStopService,
                PlatformUtilsService,
                ReferencesService,
                StorageService,
                TypedStorage,
                ObservableTypedStorage,
                TabsService,
                TimerService,
                ToasterService,
                DeviceIdService,
                CorrelationIdService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: RequestHeadersInterceptor,
                    multi: true, // multi permets d'ajouter plusieurs intercepteurs avec ce même token
                },
            ],
        };
    }
}
