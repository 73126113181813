import { Injectable } from '@angular/core';
import { TimeDisplayMode } from '@traas/boldor/all-models';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsHelper {
    getEventForTimeDisplayModeSwitch(timeDisplayMode: TimeDisplayMode): string {
        return timeDisplayMode === TimeDisplayMode.Absolute ? 'display_format__hours' : 'display_format__minutes';
    }

    getUserPropertyValueForTimeDisplayMode(timeDisplayMode: TimeDisplayMode): string {
        return timeDisplayMode === TimeDisplayMode.Absolute ? 'hours' : 'minutes';
    }
}
