import { DynamicPlace, MyGpsPositionPlace, Place, PlaceStop, PlacesTypeInterface } from '@traas/boldor/all-models';
import { MyGpsPositionDynamicPlaceAdapter } from './my-gps-position-dynamic-place.adapter';
import { PoiDynamicPlaceAdapter } from './poi-dynamic-place.adapter';
import { StopDynamicPlaceAdapter } from './stop-dynamic-place.adapter';
import { CityDynamicPlaceAdapter } from './city-dynamic-place.adapter';
import { BookmarkDynamicPlaceAdapter } from './bookmark-dynamic-place.adapter';
import { AddressDynamicPlaceAdapter } from './address-dynamic-place.adapter';
import { BoldorLocalizationService } from '@traas/common/localization';

export function createDynamicPlace(place: Place, boldorLocalizationService?: BoldorLocalizationService): DynamicPlace {
    const placeType: keyof PlacesTypeInterface = place.getPlaceType();
    switch (placeType) {
        case 'stop':
            return new StopDynamicPlaceAdapter(place as Place<PlaceStop>);
        case 'myGpsPosition':
            return new MyGpsPositionDynamicPlaceAdapter(place as Place<MyGpsPositionPlace>);
        case 'city':
            return new CityDynamicPlaceAdapter(place, boldorLocalizationService);
        case 'bookmark':
            return new BookmarkDynamicPlaceAdapter(place);
        case 'address':
            return new AddressDynamicPlaceAdapter(place);
        case 'poi':
            return new PoiDynamicPlaceAdapter(place);
        default:
            throw new Error(`Unknown behavior to this place type ${placeType}`);
    }
}
