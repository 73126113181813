import { Component, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ColorModeOptions } from '@traas/boldor/all-models';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AnalyticsService } from '@traas/common/analytics';
import { AuthenticationApi, PreferencesService } from '@traas/common/feature-account';
import { BookmarkService } from '../../../../libs/boldor/common/src/lib/services/common/bookmark/bookmark.service';
import { ConfigurationService } from '../../../../libs/boldor/common/src/lib/services/common/configuration/configuration.service';
import { DataVersionService } from '../../../../libs/boldor/common/src/lib/services/common/data-version/data-version.service';
import { GeolocationService } from '../../../../libs/boldor/common/src/lib/services/common/geolocation/geolocation.service';
import { ItineraryFiltersService } from '../../../../libs/boldor/common/src/lib/features/itinerary/services/itinerary-filters.service';
import { LineStylesService } from '../../../../libs/boldor/common/src/lib/services/common/line-styles/line-styles.service';
import { PlatformUtilsService } from '@traas/common/utils';
import { ReferencesService } from '../../../../libs/boldor/common/src/lib/services/common/references/references.service';
import { RouteUrl, RoutingService } from '@traas/common/routing';
import { StatusBarService } from '../../../../libs/boldor/common/src/lib/services/common/status-bar/status-bar.service';
import { $backButtonPushed } from '../../../../libs/boldor/common/src/lib/listeners/app-listener-utils';
import { filter, withLatestFrom } from 'rxjs/operators';
import { CompanyService } from '../../../../libs/boldor/common/src/lib/services/common/company/company.service';
import { observeRouterUrlEvents } from '@traas/boldor/all-helpers';
import * as _ from 'lodash';
import { AndroidBackButtonLockService } from '../../../../libs/boldor/common/src/lib/services/common/home/android-back-button-lock.service';
import { Platform } from '@ionic/angular';
import { CurrentUserSession } from '@traas/common/logging';
import { CustomerProviderService } from '@traas/boldor/common/services/common/customer/customer-provider.service';

@Component({
    selector: 'traas-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    private $onDestroy: Subject<void>;
    $colorMode: Observable<ColorModeOptions> = new Subject<ColorModeOptions>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private analyticsService: AnalyticsService,
        private androidBackButtonLockService: AndroidBackButtonLockService,
        private bookmarkService: BookmarkService,
        private configurationService: ConfigurationService,
        private dataVersionService: DataVersionService,
        private geolocationService: GeolocationService,
        private itineraryFiltersService: ItineraryFiltersService,
        private lineStylesService: LineStylesService,
        private platform: Platform,
        private platformUtilsService: PlatformUtilsService,
        private preferencesService: PreferencesService,
        private referencesService: ReferencesService,
        private renderer: Renderer2,
        private router: Router,
        private routingService: RoutingService,
        private statusBarService: StatusBarService,
        private authenticationApi: AuthenticationApi,
        private customerProviderService: CustomerProviderService,
    ) {
        this.$colorMode = this.$buildColorMode();
    }

    ngOnInit(): void {
        this.$onDestroy = new Subject<void>();
        this.initUserSession();

        this.analyticsService.initializeWebTrackingIfEnabled();
        this.subscribeToRouterChanges();

        this.initApp().then(
            () => console.debug('App initialized!'),
            () => {},
        );
    }

    ngOnDestroy(): void {
        this.$onDestroy.next();
        this.$onDestroy.complete();
    }

    private async initApp(): Promise<void> {
        await this.platform.ready();

        this.enableBackButton();
        if (this.platformUtilsService.isApp()) {
            this.geolocationService.checkLocationPermissions();
        }

        await this.initializeStatusBar();
        this.addCustomCssVariable();
        this.addThemeCssTag();
        this.lineStylesService.initLineStyles();

        await this.dataVersionService.update();

        const initializationsPromises = this.initializationsPromises();
        await Promise.all(initializationsPromises);
    }

    private enableBackButton(): void {
        try {
            $backButtonPushed()
                .pipe(
                    withLatestFrom(this.activatedRoute.queryParams, (url, queryParams) => ({
                        url: this.routingService.getRouteUrl(),
                        queryParams,
                    })),
                    filter(({ url }) => this.isUrlAllowedToUseBackbutton(url)),
                    filter(({ queryParams }) => this.hasParameters(queryParams)),
                )
                .subscribe(() => {
                    if (this.androidBackButtonLockService.lock('back button pressed')) {
                        this.routingService.goBack();
                    }
                });
        } catch (error) {
            console.warn('Enable Back Button Error ', error);
        }
    }

    // noinspection JSMethodCanBeStatic
    private hasParameters(queryParams: Params): boolean {
        return !_.isEmpty(queryParams);
    }

    // noinspection JSMethodCanBeStatic
    private isUrlAllowedToUseBackbutton(url: string): boolean {
        return url.includes(RouteUrl.departureResultUrl) || url.includes(RouteUrl.departureDetailUrl);
    }

    private initializationsPromises(): Promise<void>[] {
        return [
            this.initializeBookmarks(),
            this.preferencesService.initializeDefaultPreferences(),
            this.configurationService.initConfigurationIfNeeded(),
            this.referencesService.initReferencesIfNeeded(),
            this.itineraryFiltersService.fetchAndStoreTransportModes(),
        ];
    }

    private addCustomCssVariable(): void {
        document.documentElement.style.setProperty('--device-zoom-ratio', this.getDeviceZoomRatio());
    }

    private getDeviceZoomRatio(): string {
        const defaultRemVariable = getComputedStyle(document.documentElement).getPropertyValue('--default-rem-size');
        const defaultRemSize = parseFloat(defaultRemVariable);
        const actualRemSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
        return `${actualRemSize / defaultRemSize}`;
    }

    private addThemeCssTag(): void {
        const theme = CompanyService.getClassNameForCurrentCompany();
        this.renderer.addClass(document.body, theme);
        if (this.platformUtilsService.isAndroid()) {
            this.renderer.addClass(document.body, 'android');
        } else if (this.platformUtilsService.isIos()) {
            this.renderer.addClass(document.body, 'ios');
        }
    }

    private async initializeBookmarks(): Promise<void> {
        await this.bookmarkService.initializeBookmarks();
    }

    private async initializeStatusBar(): Promise<void> {
        await this.statusBarService.initializeStatusBar();
    }

    private $buildColorMode(): Observable<ColorModeOptions> {
        return this.preferencesService.$getColorModeOption();
    }

    private subscribeToRouterChanges(): void {
        observeRouterUrlEvents(this.router, this.$onDestroy).subscribe((url) => this.analyticsService.reportPageView(url));
    }

    private async initUserSession(): Promise<void> {
        try {
            if (await this.authenticationApi.isAuthenticated()) {
                CurrentUserSession.activeUser = { id: (await this.customerProviderService.getCustomer())?.id };
            }
        } catch (error) {
            console.error('Error during initialization of current user session:', error);
        }
    }
}
