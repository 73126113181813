import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DepartureTimeViewModel } from '../../../features/departure/services/departure-time/departure-time.service';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'app-departure-time',
    templateUrl: './departure-time.component.html',
    styleUrls: ['./departure-time.component.scss'],
    standalone: true,
    imports: [CommonModule, IonicModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartureTimeComponent {
    @Input() departureTimeViewModel: DepartureTimeViewModel;
}
