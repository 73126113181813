/**
 * At more or less of this limit values, the search will not ran.
 */
const MAX_COMMERCIAL_STOPS_TO_FIRE_SEARCH_TPC = 3;
const MAX_COMMERCIAL_STOPS_TO_FIRE_SEARCH_TPG = 4;
const MAX_COMMERCIAL_STOPS_TO_FIRE_SEARCH_TRAVYS = 4;

/**
 * Use company from environment.company
 * @param company
 */
export function getMaxCommercialSopsToFireSearch(company: string): number {
    switch (company) {
        case 'travys':
            return MAX_COMMERCIAL_STOPS_TO_FIRE_SEARCH_TRAVYS;
        case 'tpg':
            return MAX_COMMERCIAL_STOPS_TO_FIRE_SEARCH_TPG;
        case 'tpc':
        default:
            return MAX_COMMERCIAL_STOPS_TO_FIRE_SEARCH_TPC;
    }
}
