<ion-row class="ion-align-items-center">
    <ion-col size="8" class="flex title ion-align-items-center">
        <ion-icon class="ticket-icon" src="assets/icons/stop-on-request.svg"></ion-icon>
        <span>
            <p class="ion-no-margin">Demande d'arrêt effectuée</p>
        </span>
    </ion-col>
    <ion-col size="4" class="cancel">
        <p class="ion-no-margin">
            <ion-icon class="delete-icon" src="assets/icons/grid/delete.svg" (click)="onCancelClicked()"></ion-icon>
        </p>
    </ion-col>
</ion-row>
