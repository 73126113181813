import { GridActions, GridActionTypes } from './grid.actions';
import { GridState, initialState } from './grid.state';

export function gridReducer(state: GridState = initialState, action: GridActions): GridState {
    switch (action.type) {
        case GridActionTypes.DragStart:
            return {
                ...state,
                dragStartPoint: action.payload.point,
            };

        case GridActionTypes.DragMove:
            return {
                ...state,
                dragEndPoint: action.payload.point,
            };

        case GridActionTypes.DragEnd:
            return {
                ...state,
                dragStartPoint: undefined,
                dragEndPoint: undefined,
            };

        case GridActionTypes.EditionIn:
            return {
                ...state,
                isInEdition: true,
                editAll: false,
            };

        case GridActionTypes.EditionOut:
            return {
                ...state,
                isInEdition: false,
                editAll: false,
            };

        case GridActionTypes.EditionAll:
            return {
                ...state,
                isInEdition: true,
                editAll: true,
            };

        case GridActionTypes.ChangeSearchMode:
            return {
                ...state,
                searchMode: action.payload.mode,
            };

        default:
            return state;
    }
}
