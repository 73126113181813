import { ComparableError } from './ComparableError';
import { I18nMessage } from '../i18n-message.model';

export class SyntheseInvalidResponseError extends ComparableError {
    constructor(
        message?: string,
        /* This typo comes from synthese */
        public requestParams?: any,
        public response?: any,
        url?: string,
        public messages?: I18nMessage,
    ) {
        super(message);
    }
}
