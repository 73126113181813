/* tslint:disable:max-classes-per-file */
// eslint-disable-next-line max-classes-per-file
import { Itinerary, ItineraryAreas, ItineraryStop, LeaveOrArriveEnum, TransportMode } from '@traas/boldor/all-models';
import { SelectedItineraryContext } from './itinerary.state';
import { Action } from '@ngrx/store';
import { DatePickerBarSearchOption } from '../../../components/date-picker-bar/date-picker-bar.component';
import { Placeholder } from '../../../components/placeholder-list-item/placeholder.model';

export enum ItineraryActionTypes {
    Load = '[Itinerary] Load check', // endless scroll call Load directly
    Loading = '[Itinerary] Loading...', // Loading http request is triggered
    LoadedMore = '[Itinerary] Loaded more',
    LoadedPrevious = '[Itinerary] Loaded previous',
    LoadFail = '[Itinerary] Load fail',
    Refresh = '[Itinerary] Refresh', // 60s timer call refresh
    Reload = '[Itinerary] Reload', // when itinerary is changed
    ReplaceItineraries = '[Itinerary] Set itineraries',
    ClearItineraries = '[Itinerary] Clear itineraries',
    //
    ChangeDatePickerSearchOption = '[Itinerary] Datepicker search option changed',
    ChangeQueryKind = '[Itinerary] Change query kind',
    ClickOnLegArrival = '[Itinerary] Click on leg arrival',
    ClickOnLegDeparture = '[Itinerary] Click on leg departure',
    OpenDetails = '[Itinerary] Open details page',
    SetDeparturesInScrollViewport = '[Itinerary] Set itineraries in scroll viewport',
    ResetLoadedItineraries = '[Itinerary] Reset loaded itineraries and scroll viewport',

    SetEnabledTransportModes = '[Itinerary] Sets the value of the currently used transport modes',
    ResetEnabledTransportModes = '[Itinerary] Resets the currently filtered transport modes',
}

interface LoadPayload {
    itineraryAreas: ItineraryAreas;
    date: Date;
    highestDepartureTime?: Date;
    itinerariesCount: number;
    way: LeaveOrArriveEnum;
    transportModes: string[];
    isRefresh?: boolean;
}

export class Load implements Action {
    readonly type: ItineraryActionTypes.Load = ItineraryActionTypes.Load;

    constructor(public payload: LoadPayload) {}
}

export class Loading implements Action {
    readonly type: ItineraryActionTypes.Loading = ItineraryActionTypes.Loading;
}

export class Reload implements Action {
    readonly type: ItineraryActionTypes.Reload = ItineraryActionTypes.Reload;

    constructor(public payload: LoadPayload) {}
}

export class Refresh implements Action {
    readonly type: ItineraryActionTypes.Refresh = ItineraryActionTypes.Refresh;

    constructor(public payload: LoadPayload) {}
}

export class LoadedMore implements Action {
    readonly type: ItineraryActionTypes.LoadedMore = ItineraryActionTypes.LoadedMore;

    constructor(public payload: { itineraries: Itinerary[] }) {}
}

export class ReplaceItineraries implements Action {
    readonly type: ItineraryActionTypes.ReplaceItineraries = ItineraryActionTypes.ReplaceItineraries;

    constructor(public payload: { itineraries: Itinerary[] }) {}
}

export class ClearItineraries implements Action {
    readonly type: ItineraryActionTypes.ClearItineraries = ItineraryActionTypes.ClearItineraries;
}

export class LoadedPrevious implements Action {
    readonly type: ItineraryActionTypes.LoadedPrevious = ItineraryActionTypes.LoadedPrevious;

    constructor(public payload: { itineraries: Itinerary[] }) {}
}

export class LoadFail implements Action {
    readonly type: ItineraryActionTypes.LoadFail = ItineraryActionTypes.LoadFail;

    constructor(public payload?: { message: string; showMessage: boolean }) {}
}

export class ClickOnLegArrival implements Action {
    readonly type: ItineraryActionTypes.ClickOnLegArrival = ItineraryActionTypes.ClickOnLegArrival;

    constructor(public payload: ItineraryStop) {}
}

export class ClickOnLegDeparture implements Action {
    readonly type: ItineraryActionTypes.ClickOnLegDeparture = ItineraryActionTypes.ClickOnLegDeparture;

    constructor(public payload: ItineraryStop) {}
}

export class SetDeparturesInScrollViewport implements Action {
    readonly type: ItineraryActionTypes.SetDeparturesInScrollViewport = ItineraryActionTypes.SetDeparturesInScrollViewport;

    constructor(public payload: { itineraries: (Itinerary | Placeholder)[] }) {}
}

export class ChangeQueryKind implements Action {
    readonly type: ItineraryActionTypes.ChangeQueryKind = ItineraryActionTypes.ChangeQueryKind;

    constructor(public payload: { queryKind: LeaveOrArriveEnum }) {}
}

export class OpenDetails implements Action {
    readonly type: ItineraryActionTypes.OpenDetails = ItineraryActionTypes.OpenDetails;

    constructor(public payload: SelectedItineraryContext) {}
}

export class ChangeDatePickerSearchOption implements Action {
    readonly type: ItineraryActionTypes.ChangeDatePickerSearchOption = ItineraryActionTypes.ChangeDatePickerSearchOption;

    constructor(public payload: DatePickerBarSearchOption) {}
}

export class SetEnabledTransportModes implements Action {
    readonly type: ItineraryActionTypes.SetEnabledTransportModes = ItineraryActionTypes.SetEnabledTransportModes;

    constructor(public payload: TransportMode[]) {}
}

export class ResetEnabledTransportModes implements Action {
    readonly type: ItineraryActionTypes.ResetEnabledTransportModes = ItineraryActionTypes.ResetEnabledTransportModes;
}

export class ResetLoadedItineraries implements Action {
    readonly type: ItineraryActionTypes.ResetLoadedItineraries = ItineraryActionTypes.ResetLoadedItineraries;
}

export type ItineraryAction =
    | ChangeDatePickerSearchOption
    | ChangeQueryKind
    | ClickOnLegArrival
    | ClickOnLegDeparture
    | Load
    | Loading
    | LoadedMore
    | LoadedPrevious
    | ReplaceItineraries
    | LoadFail
    | OpenDetails
    | Refresh
    | Reload
    | SetDeparturesInScrollViewport
    | SetEnabledTransportModes
    | ResetEnabledTransportModes
    | ResetLoadedItineraries
    | ClearItineraries;
