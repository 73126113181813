import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TosService } from '@traas/common/feature-tos';

export const acceptTosGuardFactory: () => CanActivateFn = (navCtrl = inject(NavController), acceptTosService = inject(TosService)) => {
    const canActivateFn: CanActivateFn = async () => {
        if (await acceptTosService.hasAcceptedTos()) {
            return true;
        }

        await navCtrl.navigateRoot('/accept-cgu');
        return false;
    };

    return canActivateFn;
};
