<app-credit-cards
    [createCreditCard]="($createCreditCard | async)!"
    [creditCards]="($creditCards | async)!"
    [paymentMethodsConfiguration]="(paymentMethodsConfiguration | async)!"
    [selectedCreditCard]="($selectedCreditCard | async)!"
    [selectedCardPaymentMethodName]="($selectedCardPaymentMethodName | async)!"
    [selectedMobilePaymentMethodName]="($selectedMobilePaymentMethodName | async)!"
    [showSkip3DSecure]="showSkip3DSecure"
    [skip3DSecure]="hasSkip3DSecure"
    [guestCustomer]="guestCustomer"
    (createCreditCardChange)="onCreateCreditCardChange($event)"
    (savedCreditCardSelectedChange)="onSavedCreditCardSelectedChange($event)"
    (skip3DSecureChange)="onSkip3DSecureChange($event)"
    (hasPaymentMethodSelected)="onPaymentMethodChange($event)"
    (hasPaymentMethodNameSelected)="onPaymentMethodNameSelected($event)"
    (hasCardPaymentMethodNameSelected)="onCardPaymentMethodNameSelected($event)"
    (hasMobilePaymentMethodNameSelected)="onMobilePaymentMethodNameSelected($event)"
>
</app-credit-cards>
