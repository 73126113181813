import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { WarningMessageComponent } from './warning-message.component';

@NgModule({
    imports: [CommonModule, IonicModule],
    declarations: [WarningMessageComponent],
    exports: [WarningMessageComponent],
})
export class WarningMessageModule {}
