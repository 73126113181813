import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../../services/common/alert.service';
import { CompanyService } from '../../services/common/company/company.service';
import { HumanReadableStop, JourneyMessage, LineStyle, TransitStop } from '@traas/boldor/all-models';
import { TransitStopAdapter } from '../../models/departure/transit-stop';
import { getLinePathBackgroundColor, getLinePathClass, getStopIconDetailName, getThermoStopIconName } from '../../business-rules.utils';
import { JourneyMessageService } from '../../services/common/journey-message.service';

export interface TransitStopViewModel {
    arrivalDelay: string;
    arrivalRealtimeTime: string;
    arrivalTime: string;
    hasDisruption: boolean;
    isCancelled: boolean;
    disruptionMessages: JourneyMessage[];
    stop: HumanReadableStop;
    hasBookingRequirements?: boolean;
    isOptional?: boolean;
    track: string;
    cancellationMessages: JourneyMessage[];
}

@Component({
    selector: 'app-transit-stop-item',
    templateUrl: './transit-stop-item.component.html',
    styleUrls: ['./transit-stop-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitStopItemComponent implements OnInit {
    private _transitStop!: TransitStop;
    @Input()
    set transitStop(value: TransitStop) {
        this._transitStop = value;
        if (value) {
            const transitStopAdapter = new TransitStopAdapter(value);
            const cancellationMessages = this.journeyMessageService.getFormattedCancellationMessagesOfTransitStop(
                transitStopAdapter.getData(),
            );
            this.transitStopViewModel = {
                arrivalTime: transitStopAdapter.getArrivalTime(),
                arrivalRealtimeTime: transitStopAdapter.getArrivalRealTime(),
                track: value.letter,
                isOptional: value.isOptional ?? false,
                hasBookingRequirements: value.hasBookingRequirements ?? false,
                isCancelled: value.isCancelled ?? false,
                stop: transitStopAdapter,
                disruptionMessages: this.journeyMessageService.getFormattedDisruptionMessagesOfTransitStop(transitStopAdapter.getData()),
                hasDisruption: this.journeyMessageService.containsDisruptionMessagesInTransitStop(transitStopAdapter.getData()),
                arrivalDelay: transitStopAdapter.getArrivalDelay(),
                cancellationMessages: cancellationMessages ? [cancellationMessages] : [],
            };
        }
    }

    @Input() isTrain = false;
    @Input() activateForwardArrow = false;
    @Input() isLast = false;
    @Input() lineStyle: LineStyle | null;
    @Output() goToDetailsClick = new EventEmitter<TransitStop>();
    @Output() stopInformationClick = new EventEmitter<TransitStop>();

    readonly isTPC = CompanyService.isTPC();
    readonly isTPG = CompanyService.isTPG();
    transitStopViewModel: TransitStopViewModel;
    linePathBackgroundColor: string;
    linePathStyle: string;
    thermoStopIconName: string;
    stopIconDetailName: string;
    private alertService = inject(AlertService);
    private journeyMessageService = inject(JourneyMessageService);

    ngOnInit(): void {
        const { hasBookingRequirements, isOptional } = this.transitStopViewModel;
        this.thermoStopIconName = getThermoStopIconName(hasBookingRequirements ?? false, isOptional ?? false, this.isTrain);
        this.stopIconDetailName = getStopIconDetailName(hasBookingRequirements ?? false, isOptional ?? false, this.isTrain);
        const isTraasAndCanceled = CompanyService.isTraas() && this.transitStopViewModel.isCancelled;
        this.linePathBackgroundColor = getLinePathBackgroundColor(
            this.lineStyle?.style,
            this.lineStyle?.backgroundColor,
            isTraasAndCanceled,
        );
        this.linePathStyle = getLinePathClass(this.lineStyle?.style, isTraasAndCanceled);
    }

    async openWarningModal(event: Event): Promise<void> {
        event.preventDefault();
        event.stopPropagation();
        return this.alertService.presentDisruptionMessagesModal(this.transitStopViewModel.disruptionMessages);
    }

    arrowClicked(): void {
        this.goToDetailsClick.emit(this._transitStop);
    }

    stopInformationClicked(): void {
        this.stopInformationClick.emit(this._transitStop);
    }
}
