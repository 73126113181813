import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AndroidBackButtonLockService {
    private _$isLocked = new BehaviorSubject<boolean>(false);
    $alreadyLocked = new Subject<void>();

    /**
     * Locks the back button.
     * Returns true if the button was locked, false if it was already locked.
     * @param message
     */
    lock(message?: string): boolean {
        if (this.isLocked()) {
            this.$alreadyLocked.next();
            return false;
        }
        this._$isLocked.next(true);
        return true;
    }

    unlock(message?: string): void {
        this._$isLocked.next(false);
    }

    $isLocked(): Observable<boolean> {
        return this._$isLocked.asObservable();
    }

    isLocked(): boolean {
        return this._$isLocked.getValue();
    }
}
