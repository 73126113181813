import * as Gql from '@traas/boldor/graphql-generated/graphql';
import { ItineraryBasketInput, JourneyMessageType as GqlJourneyMessageType, LineInput } from '@traas/boldor/graphql-generated/graphql';
import {
    Currency,
    JourneyMessageType,
    PriceInCents,
    Reduction,
    ReductionType,
    TravelClass,
    TravelClassType,
} from '@traas/boldor/all-models';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

/** uncomment this when we will not have webpack anymore
 // export itself
 export * as ServiceToGql from './service-to-gql.converter';
 */
// export * as ServiceToGql from './service-to-gql.converter';

export function currency(value: Currency): Gql.Currency {
    switch (value) {
        case Currency.CHF:
            return Gql.Currency.Chf;
        case Currency.EUR:
            return Gql.Currency.Eur;
    }
}

export function zoneInput(zones: Gql.Zone[]): Gql.ZoneInput[] {
    return (
        zones?.map(({ id }) => ({
            id,
        })) ?? []
    );
}

export function travelClassType(value: TravelClassType): Gql.TravelClassType {
    switch (value) {
        case TravelClassType.CLASS_1:
            return Gql.TravelClassType.Class_1;
        case TravelClassType.CLASS_2:
            return Gql.TravelClassType.Class_2;
        case TravelClassType.CLASS_CHANGE:
            return Gql.TravelClassType.ClassChange;
    }
}

export function toJourneyMessageType(journeyMessageType: JourneyMessageType): GqlJourneyMessageType {
    switch (journeyMessageType) {
        case JourneyMessageType.Cancellation:
            return GqlJourneyMessageType.Cancellation;
        case JourneyMessageType.Disruption:
            return GqlJourneyMessageType.Disruption;
        default:
            console.warn(`Unknown JourneyMessageType : ${journeyMessageType}`);
            return GqlJourneyMessageType.Disruption;
    }
}

export function travelClass(value: TravelClass): Gql.TravelClass {
    return {
        id: travelClassType(value.id),
        description: value.description,
    };
}

export function reductionType(value: ReductionType): Gql.ReductionType {
    switch (value) {
        case ReductionType.NO_REDUCTION:
            return Gql.ReductionType.NoReduction;
        case ReductionType.HALF_FARE:
            return Gql.ReductionType.HalfFare;
        case ReductionType.BIKE:
            return Gql.ReductionType.Bike;
        case ReductionType.DOG:
            return Gql.ReductionType.Dog;
        case ReductionType.UNKNOWN:
            return Gql.ReductionType.Unknown;
    }
}

export function reduction(value: Reduction): Gql.Reduction {
    return {
        id: reductionType(value.id),
        description: value.description,
    };
}

export function priceInCents(price: PriceInCents): Gql.Price {
    return {
        currency: currency(price.currency),
        amountInCents: price.amountInCents,
    };
}

export function coordinatesInput(coordinates: Gql.Coordinates): Gql.CoordinatesInput {
    return {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        accuracy: coordinates.accuracy,
    };
}

export function lineInput(line: Gql.Line): Gql.LineInput {
    return {
        color: line.color,
        destination: line.destination,
        lineOfficialCode: line.lineOfficialCode,
        id: line.id,
        fontColor: line.fontColor,
        style: line.style,
        number: line.number,
        networkId: line.networkId,
    };
}

export function linesToLineInputs(lines: Gql.Line[]): LineInput[] {
    return lines.map((line) => {
        return lineInput(line);
    });
}

export function associatedCommercialStopInput(
    associatedCommercialStop: Gql.AssociatedCommercialStop,
): Gql.AssociatedCommercialStopInput | undefined {
    if (!associatedCommercialStop.coordinates) {
        console.error('associatedCommercialStop.coordinates to get associatedCommercialStopInput is mandatory. Use fallback.');
        return undefined;
    }
    let associatedCommercialStopLines: Gql.Line[] = [];
    if (associatedCommercialStop.lines && associatedCommercialStop.lines.length > 0) {
        associatedCommercialStopLines = associatedCommercialStop.lines as Gql.Line[];
    }
    return {
        cityName: associatedCommercialStop.cityName,
        coordinates: coordinatesInput(associatedCommercialStop.coordinates),
        name: associatedCommercialStop.name,
        id: associatedCommercialStop.id,
        lines: linesToLineInputs(associatedCommercialStopLines),
    };
}

export function physicalStopAssociatedInput(physicalStop: Gql.PhysicalStop): Gql.PhysicalStopInput {
    if (!physicalStop.associatedCommercialStop) {
        throw new Error(`associatedCommercialStop to get physicalStopAssociatedInput is mandatory.`);
    }
    let physicalStopLines: Gql.Line[] = [];
    if (physicalStop.lines && physicalStop.lines.length > 0) {
        physicalStopLines = physicalStop.lines as Gql.Line[];
    }
    return {
        geometry: physicalStop.geometry,
        id: physicalStop.id,
        letter: physicalStop.letter,
        transportMode: physicalStop.transportMode,
        lines: linesToLineInputs(physicalStopLines),
        associatedCommercialStop: associatedCommercialStopInput(physicalStop.associatedCommercialStop),
    };
}

function messagesInput(messages: Gql.JourneyMessage[]): Gql.JourneyMessageInput[] {
    return messages.map((message) => {
        return {
            htmlContent: message.htmlContent,
            id: message.id,
            title: message.title,
            type: toJourneyMessageType(message.type),
        };
    });
}

export function commercialStopsInput(stops: Gql.CommercialStop[]): Gql.CommercialStopInput[] {
    return stops.map((stop, index) => {
        if (stop.didok === null || stop.didok === undefined) {
            throw new TechnicalError(
                `didok is mandatory but not present on stop ${index} ID ${stop.id}.`,
                ErrorCodes.Graphql.Mapping,
                undefined,
                { stop },
            );
        }
        if (!stop.physicalStopAssociated) {
            throw new TechnicalError(
                `physicalStopAssociated is mandatory but not present on stop ${index} ID ${stop.id}.`,
                ErrorCodes.Graphql.Mapping,
                undefined,
                { stop },
            );
        }
        return {
            arrivalTime: stop.arrivalTime,
            departureTime: stop.departureTime,
            cityName: stop.cityName,
            didok: stop.didok,
            hasBookingRequirements: stop.hasBookingRequirements,
            id: stop.id,
            isArrivalPlace: stop.isArrivalPlace,
            isCancelled: stop.isCancelled,
            isOptional: stop.isOptional,
            isDeparturePlace: stop.isDeparturePlace,
            latLon: stop.latLon,
            name: stop.name,
            scheduledArrivalTime: stop.scheduledArrivalTime,
            scheduledDepartureTime: stop.scheduledDepartureTime,
            messages: messagesInput(stop.messages),
            physicalStopAssociated: physicalStopAssociatedInput(stop.physicalStopAssociated),
        };
    });
}

export function vehicleInput(vehicle: Gql.Vehicle): Gql.VehicleInput {
    return {
        transport: vehicle.transport,
        code: vehicle.code,
    };
}

export function legInput(leg: Gql.Leg): Gql.LegInput {
    return {
        isFirstClassAuthorized: leg.isFirstClassAuthorized,
        bookingDeadline: leg.bookingDeadline,
        byFoot: leg.byFoot,
        duration: leg.duration,
        length: leg.length,
        isBookable: leg.isBookable,
        serviceId: leg.serviceId,
        wkt: leg.wkt,
        transportCompanyId: leg.transportCompanyId,
        line: lineInput(leg.line),
        stops: commercialStopsInput(leg.stops),
        vehicle: vehicleInput(leg.vehicle),
        messages: messagesInput(leg.messages),
    };
}

export function itineraryBasket(itinerary: ItineraryBasketInput): Gql.ItineraryBasketInput {
    return {
        ...itinerary,
        hasStopRequest: !!itinerary.hasStopRequest,
        legs: itinerary.legs.map((leg) => legInput(leg)),
    };
}
