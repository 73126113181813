import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LeafletMapCustomEventsDirective } from './leaflet-map-custom-events.directive';

@NgModule({
    imports: [CommonModule, IonicModule],
    declarations: [LeafletMapCustomEventsDirective],
    exports: [LeafletMapCustomEventsDirective],
})
export class LeafletMapCustomEventsModule {}
