<ion-content>
    <div class="container">
        <img src="assets/imgs/errors/generic-error.svg" />
        <h2>{{ 'error-page.title' | translate }}</h2>
        <p><br />{{ 'error-page.retry-message' | translate }}</p>
        <ion-button oitClickUsingBackButton elevation="1" size="large" (click)="goToApp()">
            {{ 'error-page.go-home-button' | translate }}
        </ion-button>
    </div>
</ion-content>
