import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    Departure,
    DepartureDetails,
    JourneyEnum,
    JourneyMessageType,
    LatLngRect,
    LeaveOrArriveEnum,
    LineFilter,
    MapMode,
    TicketDuration,
    toBounds,
    TransitStop,
    Traveler,
} from '@traas/boldor/all-models';
import {
    Departure as GqlDeparture,
    Direction,
    DirectionsFromStopGQL,
    DirectionsFromStopQueryVariables,
    GenerateDepartureArticlesGQL,
    GenerateDepartureArticlesMutation,
    GenerateDepartureArticlesMutationVariables,
    GetRouteGQL,
    GetRouteQuery,
    GetRouteQueryVariables,
    JourneyMessageType as JourneyMessageTypeGQL,
    SearchDeparturesGQL,
    SearchDeparturesQueryVariables,
    SearchDirection,
} from '@traas/boldor/graphql-generated/graphql';
import { LineStylesService } from '../../../services/common/line-styles/line-styles.service';
import { LineService } from '../../../services/common/line/line.service';
import { FOR_NEXT_HOURS, LOAD_MORE_DEPARTURE_COUNT } from '../../../business-rules.utils';
import { DepartureDetailsAdapter } from '../../../models/departure/departure-details';
import { convertToError, LoggingService } from '@traas/common/logging';
import { BoldorLocalizationService } from '@traas/common/localization';
import * as moment from 'moment';
import { combineLatest, firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InMemoryCache } from '../../cache/memoryCache.service';
import { ScrollActions } from '../../home/store/scroll';
import { initialState } from '../../home/store/scroll/scroll.reducer';
import { FrontToGqlDepartureConverter } from '../converters/front-to-gql';
import { DepartureStoreActions, DepartureStoreSelectors } from '../store';
import { DepartureState } from '../store/departure.state';
import { ErrorCodes, isSuccess, Line, Result, TechnicalError } from '@traas/common/models';

export type GqlDepartureArticle = GenerateDepartureArticlesMutation['article']['generateDepartureArticles'][number];

export enum UpdateLogic {
    MERGE,
    REPLACE,
}

@Injectable({ providedIn: 'root' })
export class DepartureService {
    private departuresCache = new InMemoryCache<Departure[]>();

    constructor(
        protected store: Store<DepartureState>,
        protected lineStylesService: LineStylesService,
        protected lineService: LineService,
        protected loggingService: LoggingService,
        protected generateDepartureArticlesGQL: GenerateDepartureArticlesGQL,
        protected searchDeparturesGQL: SearchDeparturesGQL,
        protected getRouteGQL: GetRouteGQL,
        protected directionsFromStopGQL: DirectionsFromStopGQL,
        protected boldorLocalizationService: BoldorLocalizationService,
    ) {}

    $getDeparturesByCommercialStopsIds(
        commercialStopsIds: string[],
        timestamp: string,
        departuresCount: number = LOAD_MORE_DEPARTURE_COUNT,
        way: LeaveOrArriveEnum,
        lineFilters?: LineFilter[],
    ): Observable<Departure[]> {
        return this.$fetchDepartures(commercialStopsIds, timestamp, departuresCount, way, lineFilters);
    }

    getLoadMoreDeparturesCountBy(mapMode: MapMode): number {
        switch (mapMode) {
            case MapMode.Small:
                return LOAD_MORE_DEPARTURE_COUNT * 2;
            case MapMode.Full:
            case MapMode.Half:
            default:
                return LOAD_MORE_DEPARTURE_COUNT;
        }
    }

    // route details
    $getDepartureDetails(
        serviceId: string,
        commercialStopId: string,
        rankOfStop: number,
        departureDatetime: Date,
    ): Observable<DepartureDetailsAdapter> {
        const getRouteQueryVariables = this.createGetRouteQueryVariable(serviceId, commercialStopId, rankOfStop, departureDatetime);
        const $route = this.getRouteGQL.fetch(getRouteQueryVariables);
        return $route.pipe(
            map(({ data }) => {
                const { stops, id, line } = data.route;
                let transitStops = this.convertGetRouteStopsToTransitStops(stops);
                transitStops = transitStops.filter(
                    (transitStop) => transitStop?.stopAreaName?.trim() !== '' && transitStop?.arrivalTime?.trim() !== '',
                );
                const details: DepartureDetails = {
                    stops: transitStops,
                    journeyId: id,
                    line,
                };
                return new DepartureDetailsAdapter(details);
            }),
        );
    }

    async generateDepartureTicketArticles(
        departure: Departure,
        traveler: Traveler,
        durationsFilter: TicketDuration[],
    ): Promise<GqlDepartureArticle[]> {
        const variables = this.createVariablesToCallGenerateDepartureArticles(departure, traveler, durationsFilter);

        const result = await firstValueFrom(this.generateDepartureArticlesGQL.mutate(variables));
        const generateDepartureArticles = result.data?.article.generateDepartureArticles;
        return generateDepartureArticles ?? [];
    }

    async updateLineFilters(stopAreaIds: string[], datetime: string, updateLogic: UpdateLogic, boundingBox: LatLngRect): Promise<void> {
        try {
            this.store.dispatch(new DepartureStoreActions.StartRefreshLinesOfDeparture());

            const currentLineFilters = await this.getLineFilters();
            const newLineFilters = await this.fetchLineFilters(stopAreaIds, datetime, boundingBox);

            if (!newLineFilters) {
                console.warn('New line filters are undefined');
                return;
            }
            const hasDifferentLines = this.lineService.hasDifferentLineFilters(currentLineFilters, newLineFilters);

            if (hasDifferentLines) {
                switch (updateLogic) {
                    case UpdateLogic.MERGE:
                        await this.mergeLineFilters(currentLineFilters, newLineFilters);
                        break;
                    case UpdateLogic.REPLACE:
                        this.store.dispatch(new DepartureStoreActions.SetLineFilters(newLineFilters));
                        this.store.dispatch(new DepartureStoreActions.SetActiveLineFilters([]));
                        break;
                    default:
                        throw new Error('Unknown update logic');
                }
            }

            this.store.dispatch(new DepartureStoreActions.StopRefreshLinesOfDeparture());
        } catch (error) {
            this.loggingService.logError(
                new TechnicalError('Error while updating line filters', ErrorCodes.Departure.UpdateLineFilters, convertToError(error), {
                    stopAreaIds,
                    datetime,
                    updateLogic,
                    boundingBox,
                }),
            );
        }
    }

    async fetchLineFilters(stopAreaIds: string[], datetime: string, boundingBox: LatLngRect): Promise<LineFilter[]> {
        const lines = await this.fetchLinesOnNextDeparturesFrom(stopAreaIds, datetime, boundingBox);
        if (!lines) {
            console.warn('No lines found for this stopAreaIds', stopAreaIds);
            return;
        }
        const lineFilters: LineFilter[] = lines.map((line) => ({
            ...line,
            isChecked: true,
        }));
        return this.lineService.sortLineFilters(lineFilters);
    }

    $buildIsValidResponse(): Observable<boolean> {
        return combineLatest([this.store.select(DepartureStoreSelectors.getError), this.$getDepartures()]).pipe(
            map(([error, allDepartures]) => {
                return !error && (!!allDepartures || allDepartures?.length > 0);
            }),
        );
    }

    $getDepartures(): Observable<Departure[]> {
        return this.store.select(DepartureStoreSelectors.getAllDepartures).pipe(
            map((departures: Departure[] | null) => {
                if (departures) {
                    this.departuresCache.set(departures.map((departure) => ({ ...departure, isFromCache: true })));
                    return departures;
                }
                return this.departuresCache.get();
            }),
        );
    }

    /**
     * 1: Clear cache
     * 2 and [...] : Clear state
     *
     * It is important to call clear cache in first. Because all selectors will be triggered when we modify
     * state, if we modify state firstly -> selector will be fired but cache is still filled, then clear cache -> selectors are
     * not fired again because cache is not observed by Ngrx selectors.
     */
    clearDepartures(): void {
        this.departuresCache.clear();
        this.store.dispatch(new DepartureStoreActions.ClearDepartures());
        this.store.dispatch(ScrollActions.setScrollMode({ mode: initialState.mode }));
    }

    private async fetchLinesOnNextDeparturesFrom(stopAreaIds: string[], datetime: string, bbox: LatLngRect): Promise<Line[]> {
        const bounds = toBounds(bbox);
        if (!bounds) {
            return [];
        }

        const variables: DirectionsFromStopQueryVariables = {
            directionsFromStopInput: {
                dateTime: datetime,
                stopAreaIds,
                boundingBox: {
                    northEast: { latitude: bounds.getNorth(), longitude: bounds.getEast() },
                    southWest: { latitude: bounds.getSouth(), longitude: bounds.getWest() },
                },
                timeDuration: FOR_NEXT_HOURS,
            },
        };
        try {
            const result = await firstValueFrom(this.directionsFromStopGQL.fetch(variables));
            const getDirectionsFromStop = result.data.departure.getDirectionsFromStop;
            return await this.transformDirectionsToLines(getDirectionsFromStop);
        } catch (error) {
            this.loggingService.logError(
                new TechnicalError('Error on fetching lines', ErrorCodes.Line.Fetch, convertToError(error), {
                    variables: JSON.stringify(variables),
                }),
            );
            return [];
        }
    }

    /**
     * If line filters has been touched, we merge the array of new lines and existing lines. Keeping all existing lines
     * in the same state (check/uncheck) and all new lines are by default unchecked, like asked by the client.
     * If the filter has not been touched, so we replace completely the line filters array.
     * @param oldLineFilters
     * @param newLineFilters
     * @private
     */
    private async mergeLineFilters(oldLineFilters: LineFilter[], newLineFilters: LineFilter[]): Promise<void> {
        const hasActiveLineFilters = await firstValueFrom(this.store.select(DepartureStoreSelectors.hasActiveLineFilters));
        if (this.isAllChecked(oldLineFilters) && !hasActiveLineFilters) {
            this.store.dispatch(new DepartureStoreActions.SetLineFilters(newLineFilters));
            return;
        }
        const merged = newLineFilters.map((lineFilter) => {
            const alreadyExisting = this.lineService.findLineFilter(lineFilter, oldLineFilters);
            if (alreadyExisting) {
                return alreadyExisting;
            }
            return {
                ...lineFilter,
                isChecked: false,
            };
        });
        this.store.dispatch(new DepartureStoreActions.SetLineFilters(merged));
    }

    private isAllChecked(lineFilters: LineFilter[]): boolean {
        return lineFilters.every(({ isChecked }) => isChecked);
    }

    /**
     * LineStyleId on direction is used only to find a mapping between direction and lineStyle to create a line
     * with the correct ID (from direction) and correct style (from lineStyle)
     * @param linesDirection
     * @private
     */
    private async transformDirectionsToLines(linesDirection: Direction[] = []): Promise<Line[]> {
        const linesPromises = linesDirection.map(async (direction) => {
            const lineStyle = await this.lineStylesService.getLineStyleById(direction.lineStyleId);
            if (lineStyle) {
                return this.lineStylesService.createLineFrom(lineStyle, direction.lineId, direction.name, direction.wayback);
                // Ce else if ne rentretra que dans le cas de l'impl. hafas, car seulement hafas remplis le lineName
            } else if (direction.lineName) {
                return {
                    id: direction.lineId,
                    number: direction.lineName,
                    lineOfficialCode: '',
                    style: '',
                };
            }
            return undefined;
        });
        const lines = await Promise.all(linesPromises);
        return lines.filter((line) => !!line);
    }

    private getLineFilters(): Promise<LineFilter[]> {
        return firstValueFrom(this.store.select(DepartureStoreSelectors.getLineFilters));
    }

    protected createSearchDeparturesVariable(
        commercialStopsIds: string[],
        timestamp: string,
        additionalDepartures: number,
        way: LeaveOrArriveEnum,
        lineFilters?: LineFilter[],
    ): SearchDeparturesQueryVariables {
        return {
            count: additionalDepartures,
            using: {
                stopIds: commercialStopsIds,
            },
            timing: {
                timestamp,
                kind: way === LeaveOrArriveEnum.ArriveBy ? SearchDirection.ArriveBeforeDate : SearchDirection.LeaveAfterDate,
            },
            filter: {
                directions: lineFilters?.map(({ id, wayback }) => ({
                    lineId: id,
                    wayback,
                })),
            },
            lang: this.boldorLocalizationService.languageCode,
        };
    }

    /**
     * For a search (HAFAS Impl) at 08:12:02, the Gateway will only return results starting at 08:13:00.
     *
     * This is because HAFAS returns results rounded to the nearest minute. For example,
     * for a request at 08:12:02, HAFAS will return 4 results at 08:12:00, then 8 results at 08:13:00.
     * However, the business rule validated by client is to exclude all results where the departure time is less than the requested time.
     * So trips that depart at 08:12:00 for a search at 08:12:03 will be excluded, and only those starting at 08:13:00 will be returned.
     * @param commercialStopsIds
     * @param timestamp
     * @param additionalDepartures
     * @param way
     * @param lineFilters
     * @protected
     */
    protected $fetchDepartures(
        commercialStopsIds: string[],
        timestamp: string,
        additionalDepartures: number,
        way: LeaveOrArriveEnum,
        lineFilters?: LineFilter[],
    ): Observable<Departure[]> {
        const variables = this.createSearchDeparturesVariable(commercialStopsIds, timestamp, additionalDepartures, way, lineFilters);
        const $searchDepartures = this.searchDeparturesGQL.fetch(variables);

        return $searchDepartures.pipe(
            map(({ data }) => {
                if (!data.departure?.search) {
                    return { loadFail: null, departures: [] };
                }

                return {
                    departures: data.departure.search
                        .filter(Boolean)
                        .map((gqlDeparture) => {
                            const result = this.mapGqlDepartureToDeparture(gqlDeparture as GqlDeparture);
                            if (isSuccess(result)) {
                                return result.value;
                            } else {
                                this.loggingService.logError(result.error);
                                return null;
                            }
                        })
                        .filter(Boolean),
                    loadFail: null,
                };
            }),
            map(({ departures }) => departures),
            catchError((error) => {
                return throwError(
                    () =>
                        new TechnicalError('Error on fetching departures', ErrorCodes.Departure.Fetch, error, {
                            variables: JSON.stringify(variables),
                        }),
                );
            }),
        );
    }

    private convertGetRouteStopsToTransitStops(data: GetRouteQuery['route']['stops'][0][]): TransitStop[] {
        return data.map((externalStop) => {
            return {
                coordinates: externalStop.coordinates,
                arrivalTime: externalStop.arrivalTime,
                arrivalDate: externalStop.arrivalDate,
                id: externalStop.id,
                arrivalRealTime: externalStop.arrivalRealTime,
                departureDate: externalStop.departureDate,
                arrivalRealTimeDate: externalStop.arrivalRealTimeDate,
                cityName: externalStop.cityName,
                departureRealTime: externalStop.departureRealTime,
                departureTime: externalStop.departureTime,
                departureRealTimeDate: externalStop.departureRealTimeDate,
                hasBookingRequirements: externalStop.hasBookingRequirements,
                isCancelled: externalStop.isCancelled,
                isOptional: externalStop.isOptional,
                letter: externalStop.letter,
                rank: externalStop.rank,
                messages: externalStop.messages?.map((externalMessage) => {
                    return {
                        htmlContent: externalMessage?.htmlContent,
                        id: externalMessage?.id,
                        title: externalMessage?.title,
                        type: this.convertJourneyMessageType(externalMessage.type),
                    };
                }),
                stopAreaName: externalStop.stopAreaName,
                stopPointName: externalStop.stopPointName,
                tadId: externalStop.tadId,
            } as TransitStop;
        });
    }

    private convertJourneyMessageType(type: JourneyMessageTypeGQL): JourneyMessageType {
        switch (type) {
            case JourneyMessageTypeGQL.Cancellation:
                return JourneyMessageType.Cancellation;
            case JourneyMessageTypeGQL.Disruption:
                return JourneyMessageType.Disruption;
            default:
                this.loggingService.logError(
                    new TechnicalError('Unexpected JourneyMessageType', ErrorCodes.Graphql.Mapping, undefined, { type }),
                );
                return JourneyMessageType.Disruption;
        }
    }

    private createGetRouteQueryVariable(
        serviceId: string,
        commercialStopId: string,
        rankOfStop: number,
        departureDatetime: Date,
    ): GetRouteQueryVariables {
        const momentDepartureDateTime = moment(departureDatetime).format('YYYY-MM-DD HH:mm');
        const lang = this.boldorLocalizationService.languageCode;
        return {
            routeInput: {
                stopId: commercialStopId,
                serviceId,
                startRank: rankOfStop,
                departureDatetime: momentDepartureDateTime,
                lang,
            },
        };
    }

    private createVariablesToCallGenerateDepartureArticles(
        departure: Departure,
        traveler: Traveler,
        durationsFilter: TicketDuration[],
    ): GenerateDepartureArticlesMutationVariables {
        const langId = this.boldorLocalizationService.languageCode;
        return {
            generateDepartureArticlesInput: FrontToGqlDepartureConverter.toGenerateDepartureArticlesInput(
                departure,
                traveler,
                durationsFilter,
                langId,
            ),
        };
    }

    private mapGqlDepartureToDeparture(gqlDeparture: GqlDeparture): Result<Departure, TechnicalError> {
        if (!gqlDeparture.stop) {
            return {
                success: false,
                error: new TechnicalError('Stop is undefined', ErrorCodes.Graphql.Mapping, undefined, {
                    gqlDeparture: JSON.stringify(gqlDeparture),
                }),
            };
        }
        try {
            const mappedDeparture: Departure = {
                ...gqlDeparture,
                __type__: JourneyEnum.Departure,
                directionCity: '',
                directionStopName: '',
                reservation: false,
                networkId: gqlDeparture.networkId ?? '',
                messages: gqlDeparture.messages.map((message) => {
                    return {
                        ...message,
                        type: this.convertJourneyMessageType(message.type),
                    };
                }),
                outdatedDate: gqlDeparture.outdatedDate ? new Date(gqlDeparture.outdatedDate) : undefined,
                stop: {
                    ...gqlDeparture.stop,
                    messages: gqlDeparture.stop.messages.map((message) => {
                        return {
                            ...message,
                            type: this.convertJourneyMessageType(message.type),
                        };
                    }),
                },
            };
            return { success: true, value: mappedDeparture };
        } catch (error) {
            return {
                success: false,
                error: new TechnicalError('Error on mapping GqlDeparture to Departure', ErrorCodes.Graphql.Mapping, convertToError(error), {
                    gqlDeparture: JSON.stringify(gqlDeparture),
                }),
            };
        }
    }
}
