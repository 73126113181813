import { wktToGeoJSON } from '@terraformer/wkt';
import {
    Feature,
    FeatureCollection,
    GeoJSON,
    GeoJsonProperties,
    GeoJsonTypes,
    Geometry,
    GeometryCollection,
    LineString,
    MultiLineString,
    Point,
    Position,
} from 'geojson';
import { flatten, isEqual } from 'lodash';

export function feature<P extends GeoJsonProperties>(geometry: Geometry, properties: P): Feature<Geometry, P> {
    return {
        type: 'Feature',
        geometry,
        properties,
    };
}

export function featureCollection(...features: Feature[]): FeatureCollection {
    return {
        type: 'FeatureCollection',
        features,
    };
}

export function geometryCollection(...geometries: Geometry[]): GeometryCollection {
    return {
        type: 'GeometryCollection',
        geometries,
    };
}

export function lineString(coordinates: Position[]): LineString {
    return {
        type: 'LineString',
        coordinates,
    };
}

export function isLineString(input: GeoJSON): input is LineString {
    return input.type === 'LineString';
}

export function point(coordinates: Position): Point {
    return {
        type: 'Point',
        coordinates,
    };
}

/**
 * Remove all elements from incoming array that are equal to the previous element.
 * @param arrays
 */
function dedupe<T>(arrays: T[][]): T[][] {
    return arrays.filter((array, i) => i === 0 || !isEqual(array, arrays[i - 1]));
}

/**
 * Map an incoming geoJson node to a new one by applying the visitor functions provided
 * @param node
 * @param visitor
 */
function visit(node: GeoJSON, visitor: Partial<{ [key in GeoJsonTypes]: (node: GeoJSON) => GeoJSON }>): GeoJSON {
    if (node.type === 'FeatureCollection') {
        return featureCollection(...(node as FeatureCollection).features.map((feat) => visit(feat, visitor) as Feature));
    }
    if (node.type === 'GeometryCollection') {
        return geometryCollection(...(node as GeometryCollection).geometries.map((geometry) => visit(geometry, visitor) as Geometry));
    }
    const nodeVisitor = visitor[node.type];
    return nodeVisitor ? nodeVisitor(node) : node;
}

export function toGeoJson(pathAsWkt: string): GeoJSON {
    const pathAsGeoJson = wktToGeoJSON(pathAsWkt);

    return visit(pathAsGeoJson, {
        MultiLineString: (node) => lineString(dedupe(flatten((node as MultiLineString).coordinates))),
    });
}

export function isDrawable(input: LineString): boolean {
    return input.coordinates.length >= 2;
}

const RAD_PER_DEGREE = Math.PI / 180;

/**
 * Calculate the haversine of the angle given in degree
 */
function haversine(angleInDeg: number): number {
    return Math.sin((angleInDeg * RAD_PER_DEGREE) / 2) ** 2;
}

/**
 * Calculate the cosine of an angle in degrees
 */
function cosine(angleInDeg: number): number {
    return Math.cos(angleInDeg * RAD_PER_DEGREE);
}
/**
 * Calculate the angle in radians between 2 points on a sphere.
 * Multiply by the radius to get the distance.
 *
 * The angle between 2 points is measured on the great circle going through those points.
 * @param param0 origin
 * @param param1 destination
 */
function angleInRad([fromLon, fromLat]: Position, [toLon, toLat]: Position): number {
    const angleHaversine = haversine(toLat - fromLat) + cosine(fromLat) * cosine(toLat) * haversine(toLon - fromLon);
    return 2 * Math.asin(Math.sqrt(angleHaversine));
}

/**
 * Given an array of number, returns a new array of the same size where
 * `output[n]` is equal to `Sum(input[0...n])` for every value of `n`
 * @param input
 *
 */
function partialSums(input: number[]): number[] {
    const result = [] as number[];
    let sum = 0;
    for (const value of input) {
        sum += value;
        result.push(sum);
    }
    return result;
}

/**
 * Given an array of coordinates representing a path,
 * return an array of the same size,
 * where the nth element of the array is the normalized length along the path from the origin of the path to the nth point of the path
 * @param coordinates An array of coordinates
 */
function getPathLengthsFromOrigin(coordinates: Position[]): number[] {
    const segmentLengths = coordinates.map((position, index, positions) => (index === 0 ? 0 : angleInRad(position, positions[index - 1])));
    return partialSums(segmentLengths);
}

/**
 * A very small value, but still safe to use as a divisor
 */
const EPSILON = 1e-10;

/**
 * Given a `LineString`, calculate a point located at the mid point along the path
 * @param input
 */
export function midPathPoint({ coordinates }: LineString): Point {
    if (coordinates.length === 0) {
        throw new Error('LineString is empty, cannot calculate midPath');
    }
    if (coordinates.length === 1) {
        return point(coordinates[0]);
    }

    const pathLengthsFromOrigin = getPathLengthsFromOrigin(coordinates);

    const halfPathLength = pathLengthsFromOrigin[pathLengthsFromOrigin.length - 1] / 2;

    if (halfPathLength < EPSILON) {
        return point(coordinates[0]);
    }

    /**
     * Search for the segment [A, B] surrounding the mid point M
     */
    let indexB = pathLengthsFromOrigin.findIndex((length) => length > halfPathLength);
    let indexA = indexB - 1;
    if (indexB <= 0) {
        indexB = coordinates.length - 1;
        indexA = 0;
    }
    const pathLengthFromOriginToB = pathLengthsFromOrigin[indexB];
    const pathLengthFromOriginToA = pathLengthsFromOrigin[indexA];
    const ratio = (halfPathLength - pathLengthFromOriginToA) / (pathLengthFromOriginToB - pathLengthFromOriginToA);

    const coordinatesA = coordinates[indexA];
    const coordinatesB = coordinates[indexB];

    return point([
        coordinatesA[0] + ratio * (coordinatesB[0] - coordinatesA[0]),
        coordinatesA[1] + ratio * (coordinatesB[1] - coordinatesA[1]),
    ]);
}

function toFeatureArray(input: GeoJSON): Feature[] {
    switch (input.type) {
        case 'FeatureCollection':
            return (input as FeatureCollection).features;
        case 'Feature':
            return [input as Feature];
        default:
            return [feature(input as Geometry, {})];
    }
}

export function mergeToFeatureCollection(...input: GeoJSON[]): FeatureCollection {
    const featureArrays = input.map((feat) => toFeatureArray(feat));
    return featureCollection(...flatten(featureArrays));
}
