import { AbstractAdapter, ActionItem, Place, PlacesTypeInterface } from '@traas/boldor/all-models';
import * as hash from 'object-hash';

export class ActionItemAdapter extends AbstractAdapter<ActionItem> implements Place<ActionItem> {
    isFromHistory: boolean;

    constructor(data: ActionItem) {
        super(data);
    }

    createActionItem(): this {
        this.isFromHistory = true; // do not change
        return this;
    }

    getName(): string {
        return this.getData().name;
    }

    getType(): string {
        return this.getData().type;
    }

    getNameOrPath(): string {
        return this.getData().icon;
    }

    isGpsPosition(): boolean {
        return false;
    }

    getPlaceType(): keyof PlacesTypeInterface {
        return 'actionItem';
    }

    getId(): string {
        return hash.sha1(this.getData());
    }

    transformToCoordinates(): GeolocationCoordinates {
        return null;
    }
}
