/* tslint:disable:max-classes-per-file */
import { Bookmark, Endpoint, Place } from '@traas/boldor/all-models';
import { Action } from '@ngrx/store';

export enum SearchPlaceActionTypes {
    AddBookmark = '[Home] Add bookmark',
    InitBookmarks = '[Home] Initialize bookmarks...',
    RemoveBookmark = '[Home] Ask to remove bookmark',
    ResetBookmarks = '[Home] Reset bookmark',
    BookmarkModification = '[Home] Bookmark modified',
    BookmarkRemoved = '[Home] Bookmark removed',
    PickPlace = '[SearchPlace] Pick place...',
    PickPlaceSuccess = '[SearchPlace] Pick place success',
    PickPlaceFail = '[SearchPlace] Pick place fail',
    PickPlaceCanceled = '[SearchPlace] Pick place canceled',
}

export class AddBookmark implements Action {
    readonly type = SearchPlaceActionTypes.AddBookmark;

    constructor(public payload: { newBookmark: Bookmark }) {}
}

export class InitBookmarks implements Action {
    readonly type = SearchPlaceActionTypes.InitBookmarks;

    constructor(public payload: { bookmarks: Bookmark[] }) {}
}

export class ResetBookmarks implements Action {
    readonly type = SearchPlaceActionTypes.ResetBookmarks;

    constructor() {}
}

export class RemoveBookmark implements Action {
    readonly type = SearchPlaceActionTypes.RemoveBookmark;

    constructor(public payload: { bookmark: Bookmark }) {}
}

export class BookmarkModification implements Action {
    readonly type = SearchPlaceActionTypes.BookmarkModification;

    constructor(public payload: { modified: boolean } = { modified: true }) {}
}

export class BookmarkRemoved implements Action {
    readonly type = SearchPlaceActionTypes.BookmarkRemoved;

    constructor(public payload: { bookmark: Bookmark } | null) {}
}

export class PickPlace implements Action {
    readonly type = SearchPlaceActionTypes.PickPlace;

    constructor(public payload: { place: Place<any> }) {}
}

export class PickPlaceCanceled implements Action {
    readonly type = SearchPlaceActionTypes.PickPlaceCanceled;

    constructor() {}
}

export class PickPlaceSuccess implements Action {
    readonly type = SearchPlaceActionTypes.PickPlaceSuccess;

    constructor(public endpoint: Endpoint) {}
}

export class PickPlaceFail implements Action {
    readonly type = SearchPlaceActionTypes.PickPlaceFail;

    constructor(public payload: string) {}
}

export type SearchPlaceAction =
    | AddBookmark
    | InitBookmarks
    | RemoveBookmark
    | ResetBookmarks
    | BookmarkModification
    | BookmarkRemoved
    | PickPlace
    | PickPlaceSuccess
    | PickPlaceCanceled
    | PickPlaceFail;
