<app-symbol-svg *ngIf="lineIconPath && isLegacyLineIcon" [symbolURL]="lineIconPath"></app-symbol-svg>
<img *ngIf="lineIconPath && !isLegacyLineIcon" [src]="lineIconPath" [ngStyle]="{ width: imgWidth }" alt="{{ lineNumber }}" />
<ng-container *ngIf="!lineIconPath">
    <div
        [ngClass]="lineStyle"
        [style.background-color]="lineStyle ? null : backgroundColor"
        [style.color]="lineStyle ? null : color"
        class="line-badge"
    >
        {{ lineNumber }}
    </div>
</ng-container>
