import { Injectable } from '@angular/core';
import {
    Departure,
    DepartureStopRequest,
    Itinerary,
    ItineraryStopRequest,
    JourneyData,
    OrderViewModel,
    StopRequest,
} from '@traas/boldor/all-models';
import {
    CancelStopRequestGQL,
    GetAllOrdersOfCurrentUserQuery,
    RequestDepartureStopGQL,
    RequestDepartureStopMutationVariables,
    RequestItineraryStopGQL,
    RequestItineraryStopMutationVariables,
} from '@traas/boldor/graphql-generated/graphql';
import { FrontToGqlCartConverter } from '../../../models/cart/front-to-gql-cart-converter';
import { isDepartureJourney } from '../../../models/departure/departure';
import { isItineraryJourney } from '../../../models/itinerary/itinerary';
import { GqlToFrontOrderConverter } from '../../../models/order';
import { GqlToFrontStopRequestConverter } from '../../../models/stop-request/gql-to-front';
import { firstValueFrom } from 'rxjs';
import { JourneyAdapterFactory } from '../../../features/booking/models/journey.factory';
import { ErrorCodes, Result, TechnicalError } from '@traas/common/models';
import { convertToError } from '@traas/common/logging';

interface ConvertibleGqlStopRequests {
    itineraryStopRequests: GetAllOrdersOfCurrentUserQuery['order']['getAllOrders']['itineraryStopRequests'];
    departureStopRequests: GetAllOrdersOfCurrentUserQuery['order']['getAllOrders']['departureStopRequests'];
}

@Injectable({ providedIn: 'root' })
export class StopRequestService {
    constructor(
        private requestDepartureStopGQL: RequestDepartureStopGQL,
        private requestItineraryStopGQL: RequestItineraryStopGQL,
        private cancelStopRequestGQL: CancelStopRequestGQL,
    ) {}

    async requestStop(journeyData: JourneyData): Promise<Result<StopRequest, { isOutdated: boolean; error: Error }>> {
        const now = new Date();
        const journeyAdapter = JourneyAdapterFactory.create(journeyData);
        const isBookingDeadlineOutdated = journeyAdapter.getBookingDeadline() < now;
        if (!journeyAdapter.isBookable() || isBookingDeadlineOutdated) {
            return {
                success: false,
                error: {
                    error: new TechnicalError(
                        'Journey is not bookable or booking deadline is outdated.',
                        ErrorCodes.Purchase.StopRequest,
                        undefined,
                        {
                            journey: {
                                isBookable: journeyData.isBookable,
                                isCancelled: journeyData.isCancelled,
                                hasBookingRequirements: journeyData.hasBookingRequirements,
                                hasStopRequest: journeyData.hasStopRequest,
                                remainingTimeBeforeBooking: journeyData.remainingTimeBeforeBooking,
                                bookingDeadline: journeyData.bookingDeadline,
                            },
                            isBookingDeadlineOutdated,
                        },
                    ),
                    isOutdated: isBookingDeadlineOutdated,
                },
            };
        }

        try {
            let response: StopRequest;
            if (isDepartureJourney(journeyData)) {
                response = await this.requestDepartureStop(journeyData);
                return { success: true, value: response };
            }
            if (isItineraryJourney(journeyData)) {
                response = await this.requestItineraryStop(journeyData);
                return { success: true, value: response };
            }
            return {
                success: false,
                error: {
                    error: new TechnicalError(`Unknown orderType of journeyData`, ErrorCodes.Purchase.StopRequest, undefined, {
                        journeyData,
                    }),
                    isOutdated: isBookingDeadlineOutdated,
                },
            };
        } catch (error) {
            return {
                success: false,
                error: {
                    error: new TechnicalError(`Error while requesting stop`, ErrorCodes.Purchase.StopRequest, convertToError(error), {
                        journey: {
                            isBookable: journeyData.isBookable,
                            isCancelled: journeyData.isCancelled,
                            hasBookingRequirements: journeyData.hasBookingRequirements,
                            hasStopRequest: journeyData.hasStopRequest,
                            remainingTimeBeforeBooking: journeyData.remainingTimeBeforeBooking,
                            bookingDeadline: journeyData.bookingDeadline,
                        },
                    }),
                    isOutdated: isBookingDeadlineOutdated,
                },
            };
        }
    }

    /**
     * Return succeed or not
     * @param orderId
     */
    async cancelStopRequest(orderId: string): Promise<boolean> {
        const result = await firstValueFrom(this.cancelStopRequestGQL.mutate({ orderId }));
        const cancelStopRequest = result.data?.order.cancelStopRequest;
        if (!cancelStopRequest) {
            throw new Error('Error: Gateway response for cancelStopRequest must not be empty.');
        }
        return cancelStopRequest.success;
    }

    gqlStopRequestsToStopRequests(convertibleStopRequests: ConvertibleGqlStopRequests): StopRequest[] {
        return [
            ...convertibleStopRequests.departureStopRequests.map(GqlToFrontStopRequestConverter.toDepartureStopRequest),
            ...convertibleStopRequests.itineraryStopRequests.map(GqlToFrontStopRequestConverter.toItineraryStopRequest),
        ].filter((element) => !!element);
    }

    gqlStopRequestsToOrdersViewModel(convertibleStopRequests: ConvertibleGqlStopRequests): OrderViewModel[] {
        return this.gqlStopRequestsToStopRequests(convertibleStopRequests)
            .map(GqlToFrontOrderConverter.fromStopRequestToOrderViewModel)
            .filter((element) => !!element);
    }

    private async requestDepartureStop(departure: Departure): Promise<DepartureStopRequest> {
        const variables: RequestDepartureStopMutationVariables = {
            departure: {
                bookingDeadline: departure.bookingDeadline,
                departureDate: departure.plannedDepartureTime,
                hasBookingRequirements: departure.hasBookingRequirements,
                isBookable: departure.isBookable,
                remainingTimeBeforeBooking: departure.remainingTimeBeforeBooking,
                serviceId: departure.serviceId,
                stop: {
                    id: departure.stop.id,
                    line: {
                        id: departure.line.id,
                    },
                    // The departure.stop.name is not filled by response of departures.cms.json
                    name: departure.stop.physicalStop.associatedCommercialStop.name,
                },
            },
        };

        const result = await firstValueFrom(this.requestDepartureStopGQL.mutate(variables));
        const requestDepartureStop = result.data?.order.requestDepartureStop;
        if (!requestDepartureStop) {
            throw new Error('Error : Gateway response for requestDepartureStop must not be empty.');
        }
        return GqlToFrontStopRequestConverter.toDepartureStopRequest(requestDepartureStop);
    }

    private async requestItineraryStop(itinerary: Itinerary): Promise<ItineraryStopRequest> {
        const variables: RequestItineraryStopMutationVariables = {
            legs: FrontToGqlCartConverter.toLegs(itinerary.legs),
        };

        const result = await firstValueFrom(this.requestItineraryStopGQL.mutate(variables));
        const requestItineraryStop = result.data?.order.requestItineraryStop;
        if (!requestItineraryStop) {
            throw new Error('Error: Gateway response for requestItineraryStop must not be empty.');
        }
        return GqlToFrontStopRequestConverter.toItineraryStopRequest(requestItineraryStop);
    }
}
