import { LOAD_MORE_ITINERARY_COUNT } from '../../../business-rules.utils';
import { ItineraryAction, ItineraryActionTypes } from './itinerary.action';
import { findSameItineraryById } from './itinerary.selector';
import { initialState, ITINERARIES_INITIAL_VALUE, ItineraryState } from './itinerary.state';
import { mergeArrays, subtractOneSecond } from '@traas/boldor/all-helpers';
import { ItineraryAdapter } from '../../../models/itinerary/itinerary';
import { Itinerary } from '@traas/boldor/all-models';

export function itineraryReducer(state: ItineraryState = initialState, action: ItineraryAction): ItineraryState {
    switch (action.type) {
        case ItineraryActionTypes.ResetLoadedItineraries:
            return {
                ...state,
                inScrollViewport: initialState.inScrollViewport,
                refresher: { ...initialState.refresher },
            };

        case ItineraryActionTypes.OpenDetails:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    ...action.payload,
                },
            };

        case ItineraryActionTypes.Loading:
            return {
                ...state,
                loading: true,
            };

        case ItineraryActionTypes.LoadedPrevious:
        case ItineraryActionTypes.LoadedMore: {
            const oldItineraries = getItinerariesFromState(state);
            const itineraries = mergeArrays(action.payload.itineraries, oldItineraries, (itinerary) =>
                new ItineraryAdapter(itinerary).getId(),
            );
            const refresher = getRefresher(itineraries);
            return {
                ...state,
                itineraries,
                loading: false,
                refresher: refresher || state.refresher,
            };
        }

        case ItineraryActionTypes.ReplaceItineraries:
            const currentItineraries = getItinerariesFromState(state);
            const updated = currentItineraries
                .map((itinerary) => new ItineraryAdapter(itinerary))
                .map((itinerary) => {
                    const isSame = findSameItineraryById(itinerary, action.payload.itineraries);
                    return isSame ?? itinerary?.getData();
                });

            return {
                ...state,
                itineraries: updated,
            };

        case ItineraryActionTypes.ClearItineraries:
            return {
                ...state,
                itineraries: ITINERARIES_INITIAL_VALUE,
                loading: false,
            };

        case ItineraryActionTypes.LoadFail:
            return {
                ...state,
                itineraries: state.itineraries ? [...state.itineraries] : [],
                loading: false,
            };

        case ItineraryActionTypes.Reload:
            return {
                ...initialState,
                enabledTransportModes: state.enabledTransportModes,
                queryKind: state.queryKind,
                selected: {
                    ...initialState.selected,
                    datePickerBarSearchOption: state.selected.datePickerBarSearchOption,
                },
            };

        case ItineraryActionTypes.SetDeparturesInScrollViewport:
            return {
                ...state,
                inScrollViewport: action.payload.itineraries,
            };

        case ItineraryActionTypes.ChangeQueryKind:
            return {
                ...state,
                queryKind: action.payload.queryKind,
            };

        case ItineraryActionTypes.ChangeDatePickerSearchOption:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    datePickerBarSearchOption: action.payload,
                },
            };

        case ItineraryActionTypes.SetEnabledTransportModes:
            return {
                ...state,
                enabledTransportModes: action.payload,
            };

        default:
            return state;
    }
}

function getRefresher(itineraries: Itinerary[]): { date: Date; itinerariesCount: number } {
    try {
        let itinerariesCount = LOAD_MORE_ITINERARY_COUNT * 2;
        const now = new Date();
        const futurItineraryIndex = itineraries.findIndex((itinerary) => new Date(itinerary.scheduledDepartureDate) > now);
        const numberOfItineraryInFuture = itineraries.length - futurItineraryIndex - 1;
        if (numberOfItineraryInFuture < itinerariesCount) {
            itinerariesCount = numberOfItineraryInFuture;
        }
        const date = subtractOneSecond(now);
        return {
            date,
            itinerariesCount:
                numberOfItineraryInFuture > itinerariesCount && numberOfItineraryInFuture > 0
                    ? itinerariesCount
                    : numberOfItineraryInFuture,
        };
    } catch (error) {
        console.warn(error);
        return null;
    }
}

function getItinerariesFromState(state: ItineraryState): Itinerary[] {
    return state.itineraries || [];
}
