import { NgModule } from '@angular/core';
import { LoginModule } from '../../login.module';
import { PageTitleModule } from '@traas/boldor/common/components/page-title/page-title.module';
import { BoldorFeatureShellSharedModule } from '@traas/boldor/common/boldor-feature-shell-shared.module';
import { RouterModule } from '@angular/router';
import { CommonLoginComponent } from './common-login.component';

@NgModule({
    declarations: [CommonLoginComponent],
    imports: [BoldorFeatureShellSharedModule, LoginModule, PageTitleModule],
    exports: [RouterModule, CommonLoginComponent],
})
export class CommonLoginComponentModule {}
