import { Injectable } from '@angular/core';
import { environment } from '@traas/boldor/environments';
import { AlertService } from '../../../services/common/alert.service';
import { AlertMetadata, ConfirmationAlertMetadata, Swisspass, UserInfo, UserInfoSwisspass } from '@traas/capacitor/swisspass-plugin';
import { BoldorLocalizationService } from '@traas/common/localization';
import { PlatformUtilsService } from '@traas/common/utils';
import { convertToError, LoggingService, logOptionsFactory } from '@traas/common/logging';
import { SwisspassService } from './SwisspassService';
import { $getAppEventObservable } from '../../../listeners/app-listener-utils';
import { delay, take } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

const errorMessageTitle = 'error-message.title';
const errorMessageUkn = 'error-message.unknown';

@Injectable({
    providedIn: 'root',
})
export class MobileSwisspassService implements SwisspassService {
    private userInfoCache: UserInfo | null = null;
    private lastFetched: number | null = null;

    constructor(
        private alertService: AlertService,
        private platformService: PlatformUtilsService,
        private boldorLocalizationService: BoldorLocalizationService,
        private logger: LoggingService,
    ) {
        this.initSwissPassSDKEnv();
    }

    /**
     * Il est possible que Swisspass SDK nous retourne un accessToken valide, mais que le getUserInfo échoue.
     * Par exemple dans le cas ou nous faisons "Se déconnecter de tous les appareils" depuis l'interface Swisspass web.
     */
    async isAuthenticatedOnSwissPass(): Promise<boolean> {
        try {
            const response = await Swisspass.requestToken();
            return !!response.accessToken;
        } catch (error) {
            this.logger.logLocalError(error, logOptionsFactory('warning'));
            return false;
        }
    }

    async getAccessToken(): Promise<string | null> {
        try {
            const response = await Swisspass.requestToken();
            return response.accessToken ?? null;
        } catch (error) {
            this.logger.logLocalError(error, logOptionsFactory('warning'));
            return null;
        }
    }

    async logout(): Promise<boolean> {
        try {
            const logoutAlertLabels = await this.getLogoutConfirmationAlertMetadata();
            const { success } = await Swisspass.logout(logoutAlertLabels);
            return success;
        } catch (error) {
            this.logger.logError(this.createTechnicalErrorWithNativeMethod('logout', error), 'warning');
            return false;
        }
    }

    async forceLogout(): Promise<void> {
        try {
            await Swisspass.forceLogout();
        } catch (error) {
            this.logger.logError(this.createTechnicalErrorWithNativeMethod('forceLogout', error), 'warning');
        }
    }

    async isSwissPassAvailable(): Promise<boolean> {
        try {
            const isSwissPassMobileAvailable = await Swisspass.isSwissPassMobileAvailable();
            return isSwissPassMobileAvailable.isAvailable;
        } catch (error) {
            this.logger.logError(this.createTechnicalErrorWithNativeMethod('isSwissPassAvailable', error), 'warning');
            return false;
        }
    }

    async openSwissPassMobile(): Promise<void> {
        try {
            await Swisspass.openSwissPassMobile();
        } catch (error) {
            this.logger.logError(this.createTechnicalErrorWithNativeMethod('openSwissPassMobile', error), 'warning');
        }
    }

    async activateSwissPassMobile(): Promise<boolean> {
        try {
            const response = await Swisspass.activateSwissPassMobile();
            return response.isActivated;
        } catch (error: any) {
            this.logger.logError(new TechnicalError('Error while activating Swisspass', ErrorCodes.Swisspass.Activate, error), 'warning');
            const failTitle = await this.boldorLocalizationService.get(errorMessageTitle);
            const cancelFail = await this.boldorLocalizationService.get(errorMessageUkn);
            this.alertService.genericAlert(failTitle, cancelFail);
            return false;
        }
    }

    async canActivateSwissPass(): Promise<{
        canActivate: boolean;
        status: string;
    }> {
        try {
            return await Swisspass.canActivateSwissPass();
        } catch (error) {
            const failTitle = await this.boldorLocalizationService.get(errorMessageTitle);
            let canActivate = false;
            // Depending on platform which throw error
            const errorMessage = (error as any)['status'] ?? (error as any)['message'] ?? error;
            if (errorMessage.includes && (errorMessage.includes('TOO_MANY_ACTIVATION') || errorMessage.includes('tooManyActivations'))) {
                this.logger.logError(
                    new TechnicalError('Too many activations', ErrorCodes.Swisspass.TooManyActivations, convertToError(error)),
                    'warning',
                );
                const cancelFail = await this.boldorLocalizationService.get('swisspass.account-status.too-many-activations');
                canActivate = true;
                await this.alertService.genericAlert(failTitle, cancelFail);
            } else if (errorMessage.includes && (errorMessage.includes('ACCOUNT_BLOCKED') || errorMessage.includes('accountBlocked'))) {
                this.logger.logError(
                    new TechnicalError('Account blocked', ErrorCodes.Swisspass.AccountBlocked, convertToError(error)),
                    'warning',
                );
                const cancelFail = await this.boldorLocalizationService.get('swisspass.account-status.blocked');
                await this.alertService.genericAlert(failTitle, cancelFail);
            }
            return {
                canActivate,
                status: errorMessage,
            };
        }
    }

    async linkSwissPassToAccount(): Promise<void> {
        const errorAlertMetadata = await this.createErrorAlertMetadata();
        try {
            await Swisspass.openCardLinkManagementPage(errorAlertMetadata);
        } catch (error) {
            this.logger.logError(this.createTechnicalErrorWithNativeMethod('linkSwissPassToAccount', error), 'warning');
        }
    }

    async openLoginDataManagementPage(): Promise<void> {
        const errorAlertMetadata = await this.createErrorAlertMetadata();
        try {
            await Swisspass.openLoginDataManagementPage(errorAlertMetadata);
        } catch (error) {
            this.logger.logError(this.createTechnicalErrorWithNativeMethod('openLoginDataManagementPage', error), 'warning');
        }
    }

    async openAccountManagementPage(): Promise<void> {
        const errorAlertMetadata = await this.createErrorAlertMetadata();

        try {
            await Swisspass.openAccountManagementPage(errorAlertMetadata);
        } catch (error) {
            this.logger.logError(this.createTechnicalErrorWithNativeMethod('openAccountManagementPage', error), 'warning');
        }
    }

    /**
     * Cette fonction s'abonne à l'évènement 'appUrlOpen' de Capacitor.
     * L'abonnement est nécessaire pour éviter que les évènements soient accumulés
     * dans une pile interne de Capacitor. Sans cela, un conflit peut survenir avec
     * l'évènement 'appUrlOpen' déclenché par le premier achat après la connexion.
     *
     * @returns {Promise<{ isLoggedIn: boolean }>} Retourne une promesse qui résout en un objet indiquant si l'utilisateur est connecté ou non.
     */
    async openSwisspassLoginPage(): Promise<{
        isLoggedIn: boolean;
    }> {
        const $swisspassPageClosed = new Subject<void>();
        const $appUrlOpen = $getAppEventObservable('appUrlOpen').pipe(take(1));
        // delay() est pour laisser le temps à $appUrlOpen de s'émettre en premier si l'event est déclenché.
        const combined$ = merge($swisspassPageClosed.pipe(delay(100)), $appUrlOpen).pipe(take(1));

        combined$.subscribe();
        let result: Promise<{
            isLoggedIn: boolean;
        }> | null = null;
        if (this.platformService.isAndroid()) {
            result = this.openAndroidSwisspassLoginPage();
        } else {
            result = this.openIosSwisspassLoginPage();
        }
        return result.then((value) => {
            $swisspassPageClosed.next();
            return value;
        });
    }

    async registerSwissPass(): Promise<string | null> {
        if (this.platformService.isAndroid()) {
            return this.registerSwissPassAndroid();
        }
        return this.registerSwissPassIos();
    }

    async getUserInfo(): Promise<UserInfo | null> {
        const now = Date.now();
        const CACHE_DURATION = 1000 * 5;
        const isCacheValid = this.lastFetched && now - this.lastFetched < CACHE_DURATION;

        if (this.userInfoCache && isCacheValid) {
            return this.userInfoCache;
        }

        this.userInfoCache = await this.fetchUserInfo();
        this.lastFetched = now;

        return this.userInfoCache;
    }

    private async fetchUserInfo(): Promise<UserInfo | null> {
        try {
            const result = await Swisspass.getUserInfo();
            if (result?.userInfoJson) {
                const userInfoSwisspass: UserInfoSwisspass = JSON.parse(result.userInfoJson);
                return this.toUserInfo(userInfoSwisspass);
            }
            return null;
        } catch (error) {
            this.logger.logError(this.createTechnicalErrorWithNativeMethod('getUserInfo', error), 'warning');
            return null;
        }
    }

    private async createErrorAlertMetadata(): Promise<AlertMetadata> {
        return {
            errorAlertTitle: await this.boldorLocalizationService.get(errorMessageTitle),
            errorAlertMessage: await this.boldorLocalizationService.get(errorMessageUkn),
        };
    }

    private async registerSwissPassAndroid(): Promise<string | null> {
        try {
            const response = await Swisspass.register();
            return response.accessToken ?? null;
        } catch (error) {
            this.logger.logError(this.createTechnicalErrorWithNativeMethod('registerSwissPassAndroid', error), 'warning');
            return null;
        }
    }

    private async registerSwissPassIos(): Promise<string | null> {
        try {
            const response = await Swisspass.register();
            return response.accessToken ?? null;
        } catch (error) {
            this.logger.logError(this.createTechnicalErrorWithNativeMethod('registerSwissPassIos', error), 'warning');
            return null;
        }
    }

    private async openIosSwisspassLoginPage(): Promise<{
        isLoggedIn: boolean;
    }> {
        try {
            const loginPromise = await Swisspass.openLoginPage();
            if (!loginPromise.accessToken) {
                return { isLoggedIn: false };
            }
            return { isLoggedIn: true };
        } catch (error: any) {
            this.logger.logLocalError(`Fail on openIosSwisspassLoginPage: ${error.message}`, logOptionsFactory('warning'));
            return { isLoggedIn: false };
        }
    }

    private async openAndroidSwisspassLoginPage(): Promise<{
        isLoggedIn: boolean;
    }> {
        try {
            // This promise will be resolved when we go back from the SDK view to the app, or if we complete the login
            const loginPromise = await Swisspass.openLoginPage();
            /* Si l'utilisateur s'inscrit via la page de login, alors après son inscription, la promesse ne sera toujours pas résolu
             * et l'utilisateur sera redirigé automatiquement vers la page swisspass login, et à partir de là, s'il se connecte/quitte/etc
             * la promesse sera résolue */
            if (!loginPromise.accessToken) {
                return { isLoggedIn: false };
            }
            return { isLoggedIn: true };
        } catch (error) {
            this.logger.logLocalError(
                `Fail on openAndroidSwisspassLoginPage: ${convertToError(error).message}`,
                logOptionsFactory('warning'),
            );
            return { isLoggedIn: false };
        }
    }

    private async getLogoutConfirmationAlertMetadata(): Promise<ConfirmationAlertMetadata> {
        return {
            title: await this.boldorLocalizationService.get('swisspass.logout-alert.title'),
            description: await this.boldorLocalizationService.get('swisspass.logout-alert.description'),
            confirm: await this.boldorLocalizationService.get('swisspass.logout-alert.confirm'),
            cancel: await this.boldorLocalizationService.get('swisspass.logout-alert.cancel'),
        };
    }

    private initSwissPassSDKEnv(): void {
        const config = environment.mobileSwisspass;
        if (!config) {
            throw new Error('Swisspass can not be initialized without a valid configuration.');
        }
        Swisspass.initSDK({
            environment: config.environment,
            provider: config.provider,
            clientId: config.clientId,
            deeplink: config.deeplink,
        });
    }

    private toUserInfo(userInfoSwisspass: UserInfoSwisspass): UserInfo {
        return {
            street: userInfoSwisspass.street,
            userId: userInfoSwisspass.sub,
            authenEmail: userInfoSwisspass.authenEmail,
            birthdate: userInfoSwisspass.birthdate,
            careOf: userInfoSwisspass.careOf,
            ckmNumber: userInfoSwisspass.ckmNumber,
            contactEmail: userInfoSwisspass.contactEmail,
            contactLandlinePhoneNumber: userInfoSwisspass.contactLandlinePhoneNumber,
            country: userInfoSwisspass.c,
            contactMobilePhoneNumber: userInfoSwisspass.contactMobilePhoneNumber,
            displayLanguage: userInfoSwisspass.displayLanguage,
            firstName: userInfoSwisspass.givenname,
            gender: userInfoSwisspass.gender,
            identityAssuranceLevel: userInfoSwisspass.ial,
            title: userInfoSwisspass.title,
            lastDataUpdate: userInfoSwisspass.lastDataUpdate,
            lastName: userInfoSwisspass.sn,
            mobile: userInfoSwisspass.mobile,
            zip: userInfoSwisspass.postalCode,
            postbox: userInfoSwisspass.postbox,
            roles: userInfoSwisspass.roles,
            tkid: userInfoSwisspass.tkid,
            city: userInfoSwisspass.l,
            salutation: userInfoSwisspass.salutation,
        };
    }

    private createTechnicalErrorWithNativeMethod(methodName: string, error: any): TechnicalError {
        return new TechnicalError(
            `Error while calling native method ${methodName}`,
            ErrorCodes.Swisspass.NativeMethod,
            convertToError(error),
        );
    }
}
