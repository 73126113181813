import { ChangePlaceEventSourceEnum, InvalidPlaceError, Place } from '@traas/boldor/all-models';
import { BoldorLocalizationService } from '@traas/common/localization';
import * as L from 'leaflet';
import { DivIcon, DivIconOptions } from 'leaflet';
import { Observable } from 'rxjs';
import { CityAdapter } from '../../../../features/place/adapters/city';
import { StopAdapter } from '../../../../features/place/adapters/stop';
import { CompanyService } from '../../company/company.service';
import { DynamicPlaceAdapter } from './dynamic-place.adapter';

export class CityDynamicPlaceAdapter extends DynamicPlaceAdapter<Place> {
    private readonly changePlaceSourceEvent: ChangePlaceEventSourceEnum = ChangePlaceEventSourceEnum.CitySelection;

    constructor(data: Place, boldorLocalizationService: BoldorLocalizationService) {
        super(data);
        if (this.data && this.getMainStops().length === 0) {
            this.throwError(data, boldorLocalizationService).then();
        }
    }

    private async throwError(data: Place, boldorLocalizationService: BoldorLocalizationService): Promise<void> {
        const emptyMainStopsInCity = await boldorLocalizationService.get('error-message.empty-stop-city');
        const emptyMainStopsInCityCode = await boldorLocalizationService.get('error-message.empty-stop-city-code');
        throw new InvalidPlaceError(`[boldorErrorCode - ${emptyMainStopsInCityCode}] Thi mainStops. (${JSON.stringify(data)})`, {
            fr: emptyMainStopsInCity,
        });
    }

    getMainStops(): StopAdapter[] {
        return (this.getData() as CityAdapter).getAdaptedMainStops();
    }

    getChangePlaceEvent(): ChangePlaceEventSourceEnum {
        return this.changePlaceSourceEvent;
    }

    getDivIcon(iconOptions: DivIconOptions = {}): DivIcon {
        const companyClassName = CompanyService.getClassNameForCurrentCompany();
        return L.divIcon({
            html: `
                   <img class="locality-icon" class="${companyClassName}"/>
                `,
            className: `ni-cluster`,
            iconSize: [10, 10],
            popupAnchor: [-10 / 2, -10 / 2],
            ...iconOptions,
        });
    }

    getEndpointIconName(): string {
        return super.getEndpointIconName();
    }

    isDrawOnMapRequired(): boolean {
        return true;
    }

    isDrawOnEndpointRequired(): boolean {
        return true;
    }

    isNeededToFitMap(): boolean {
        return true;
    }

    $getHtmlLeafletIcon(): Observable<string> {
        return super.$getHtmlLeafletIcon();
    }
}
