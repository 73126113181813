/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import {
    ActiveArea,
    createActiveArea,
    Endpoint,
    isArrival,
    isDeparture,
    ItineraryStop,
    PhysicalStopAdapter,
} from '@traas/boldor/all-models';
import { HomeStoreState } from './..';
import { EndpointActions, EndpointSelectors } from '../endpoint';
import { MapActions, MapSelectors } from './index';
import {
    MapActionTypes,
    Moved,
    ShowItinerary,
    TooMuchStopsInBounds,
    UpdateEdgeMarkers,
    UpdateLayers,
    UpdateStopsInBounds,
    UpdateTracksLayer,
} from './map.action';
import { ItineraryStoreSelectors } from '../../../itinerary/store';
import { ClickOnLegArrival, ClickOnLegDeparture, ItineraryActionTypes } from '../../../itinerary/store/itinerary.action';
import { DELAY_BEFORE_EXECUTING_MAP_MOVE_EVENT } from '../../../../business-rules.utils';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LayerBuilderService } from '../../../map/services/layer-builder.service';
import { MapConfigurationService } from '../../../map/services/map-configuration.service';
import { MapService } from '../../../map/services/map.service';
import { PhysicalStopService } from '../../../../services/common/physical-stop/physical-stop.service';
import { ItineraryAdapter } from '../../../../models/itinerary/itinerary';
import { LegAdapter } from '../../../../models/itinerary/leg';
import { StopAdapter } from '../../../../models/itinerary/stop';
import {
    DebugLayerName,
    DYNAMIC_PLACE_LAYER,
    dynamicLayerNameFactory,
    EDGE_LAYER,
    GPS_MARKER_LAYER,
    isNotNullOrUndefined,
    ITINERARY_LAYER,
    ITINERARY_LINE_ICON_LAYER,
    layerName,
    TRACKS_BULLET_LAYER,
    TRACKS_LAYER,
} from '@traas/common/utils';
import { LatLngBounds, LatLngTuple, LayerGroup } from 'leaflet';
import { includes } from 'lodash';
import { firstValueFrom, Observable } from 'rxjs';
import { debounceTime, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getMaxCommercialSopsToFireSearch } from '@traas/boldor/all-helpers';
import { environment } from '@traas/boldor/environments';
import { PhysicalStop } from '@traas/common/models';

enum LegType {
    Departure,
    Arrival,
}

@Injectable()
export class MapEffect {
    $moved = createEffect(() =>
        this.$actions.pipe(
            ofType<Moved>(MapActionTypes.Moved),
            debounceTime(DELAY_BEFORE_EXECUTING_MAP_MOVE_EVENT),
            switchMap(({ payload }) => this.clearLayers(payload.bounds)),
            switchMap(() => this.mapService.getPhysicalStopsVisible()),
            switchMap((physicalStopsVisible) => this.mapService.addFilteredPhysicalStopsInSafeArea(physicalStopsVisible)),
            withLatestFrom(this.store.select(MapSelectors.isEnabled), (context, isEnabled) => ({
                context,
                isEnabled,
            })),
            filter(({ isEnabled }) => isEnabled),
            mergeMap(({ context }) => {
                const commercialStopIds = context.inSafeArea.map((p) => p.getCommercialStopId()).filter(isNotNullOrUndefined);
                const tooManyStops = new Set(commercialStopIds).size > getMaxCommercialSopsToFireSearch(environment.company);
                return [
                    new EndpointActions.SetTooMuchStopsOnActiveEndpoint(tooManyStops),
                    new TooMuchStopsInBounds(tooManyStops),
                    new UpdateStopsInBounds({
                        all: context.all.map((stop: PhysicalStopAdapter) => stop.getData()),
                        inSafeArea: context.inSafeArea.map((stop: PhysicalStopAdapter) => stop.getData()),
                    }),
                ];
            }),
        ),
    );

    $centerOnDeparture = createEffect(
        () =>
            this.$actions.pipe(
                ofType<ClickOnLegDeparture>(ItineraryActionTypes.ClickOnLegDeparture),
                withLatestFrom(this.store.select(ItineraryStoreSelectors.getSelectedItinerary), (action, selectedItinerary) => ({
                    stop: new StopAdapter(action.payload),
                    itinerary: new ItineraryAdapter(selectedItinerary),
                })),
                tap(({ stop, itinerary }) => {
                    this.focusOnMapByLegType(stop, itinerary, LegType.Departure);
                }),
            ),
        { dispatch: false },
    );

    $centerOnArrival = createEffect(
        () =>
            this.$actions.pipe(
                ofType<ClickOnLegArrival>(ItineraryActionTypes.ClickOnLegArrival),
                withLatestFrom(this.store.select(ItineraryStoreSelectors.getSelectedItinerary), (action, selectedItinerary) => ({
                    stop: new StopAdapter(action.payload),
                    itinerary: new ItineraryAdapter(selectedItinerary),
                })),
                tap(({ stop, itinerary }) => {
                    this.focusOnMapByLegType(stop, itinerary, LegType.Arrival);
                }),
            ),
        { dispatch: false },
    );

    $updateStopsInBounds: Observable<UpdateLayers> = createEffect(() =>
        this.$actions.pipe(
            ofType(MapActionTypes.UpdateStopsInBounds),
            map((action: UpdateStopsInBounds) => new UpdateLayers({ ...action.payload })),
        ),
    );

    // this update just the "stops" layer, tracks layer and edge layers are managed by other actions

    $updateLayers = createEffect(
        () =>
            this.$actions.pipe(
                ofType<UpdateLayers>(MapActionTypes.UpdateLayers),
                tap(async ({ payload: { all, inSafeArea } }: UpdateLayers) => {
                    const activeEndpoint: Endpoint = await firstValueFrom(this.store.select(EndpointSelectors.getActiveEndpoint));
                    const stopsAdapter: PhysicalStopAdapter[] = all.map((stop: PhysicalStop) => new PhysicalStopAdapter(stop));
                    const stopsInSafeAreaAdapter: PhysicalStopAdapter[] = inSafeArea.map(
                        (stop: PhysicalStop) => new PhysicalStopAdapter(stop),
                    );
                    this.updateLayersBy(stopsAdapter, activeEndpoint, stopsInSafeAreaAdapter);
                }),
            ),
        { dispatch: false },
    );

    $updateTracksLayers: Observable<UpdateTracksLayer> = createEffect(
        () =>
            this.$actions.pipe(
                ofType<UpdateTracksLayer>(MapActionTypes.UpdateTracksLayer),
                tap(async ({ payload: { stops } }: UpdateTracksLayer) => {
                    const activeEndpoint = await firstValueFrom(this.store.select(EndpointSelectors.getActiveEndpointInformation));
                    const stopsAdapter: PhysicalStopAdapter[] = stops.map((stop: PhysicalStop) => new PhysicalStopAdapter(stop));
                    const activeArea = createActiveArea(activeEndpoint.endpointValue, activeEndpoint.area);

                    // TRAASMOBIL-2153 avoiding track stop to blink between
                    // search because between a search, the component is firing empty stopsAdapter array once
                    if (stopsAdapter.length > 0) {
                        this.updateTracksLayerBy(stopsAdapter, activeArea);
                    }
                    if (isDeparture(activeArea.endpoint)) {
                        this.store.dispatch(new MapActions.UpdateEdgeMarkers());
                    }
                }),
            ),
        { dispatch: false },
    );

    $updateEdgeMarkers: Observable<UpdateEdgeMarkers> = createEffect(
        () =>
            this.$actions.pipe(
                ofType(MapActionTypes.UpdateEdgeMarkers),
                tap(() => {
                    this.mapService.updateEdgeMarkers();
                }),
            ),
        { dispatch: false },
    );

    $drawDetailsElements = createEffect(() =>
        this.$actions.pipe(
            ofType(MapActionTypes.DrawDetailsElements),
            tap(() => {
                const allLayersNames: layerName[] = this.mapService.getAllLayersNames();
                const excludedLayers: layerName[] = [
                    GPS_MARKER_LAYER,
                    ITINERARY_LAYER,
                    ITINERARY_LINE_ICON_LAYER,
                    this.layerBuilder.getDynamicLayerNameByEndpoint(Endpoint.Departure),
                ];
                const layersNamesToDelete: layerName[] = allLayersNames.filter((name: string) => !includes(excludedLayers, name));
                this.mapService.removeLayersFromMap(layersNamesToDelete);
            }),
            map(() => new MapActions.Disable()),
        ),
    );

    $disable = createEffect(() =>
        this.$actions.pipe(
            ofType(MapActionTypes.Disable),
            map(() => new MapActions.DisableDragging()),
        ),
    );

    $enable = createEffect(() =>
        this.$actions.pipe(
            ofType(MapActionTypes.Enable),
            map(({ payload: { enableDragging } }) => {
                if (enableDragging) {
                    return new MapActions.EnableDragging();
                }
            }),
        ),
    );

    $disableDragging = createEffect(
        () =>
            this.$actions.pipe(
                ofType(MapActionTypes.DisableDragging),
                tap(() => {
                    this.mapService.disableDragging();
                }),
            ),
        { dispatch: false },
    );

    $enableDragging = createEffect(
        () =>
            this.$actions.pipe(
                ofType(MapActionTypes.EnableDragging),
                tap(() => {
                    this.mapService.enableDragging();
                }),
            ),
        { dispatch: false },
    );

    $showItinerary = createEffect(
        () =>
            this.$actions.pipe(
                ofType<ShowItinerary>(MapActionTypes.ShowItinerary),
                map((action) => action.payload),
                tap((itinerary: ItineraryAdapter) => {
                    const geoJsonData = itinerary.getGeoJson();
                    const pathLayer = this.layerBuilder.buildItineraryPathLayerFrom(geoJsonData);
                    const lineIconLayer = this.layerBuilder.buildItineraryStopPointsLayerFrom(geoJsonData);
                    const footpathLayer = this.layerBuilder.buildItineraryFootpath(geoJsonData);
                    const footpathMarkersLayer = this.layerBuilder.buildItineraryFootpathMarker(geoJsonData);
                    const legsEndpoints = this.getLegsEndpoints(itinerary);
                    const legTrackMarkersLayer = this.layerBuilder.buildTracksLayerFromPhysicalStops(
                        legsEndpoints.map((stop) => stop.getData()),
                    );
                    const legTrackMarkerBulletsLayer = this.layerBuilder.buildStopsLayerFrom(
                        legsEndpoints.map((adapter) => adapter.getData()),
                    );
                    const arrivalPlaceLayer: layerName = dynamicLayerNameFactory(DYNAMIC_PLACE_LAYER, Endpoint.Arrival);
                    const departurePlaceLayer: layerName = dynamicLayerNameFactory(DYNAMIC_PLACE_LAYER, Endpoint.Departure);
                    if (itinerary.isStartingWithPedestrianLeg()) {
                        this.mapService.removeLayerFromMap(arrivalPlaceLayer);
                    } else {
                        this.mapService.setLayer(departurePlaceLayer, null);
                        this.mapService.removeLayersFromMap([arrivalPlaceLayer, departurePlaceLayer]);
                    }
                    this.mapService.clearEdgeLayer();
                    this.mapService.clearPhysicalStopsLayer();
                    this.mapService.setLayers({
                        ITINERARY_FOOTPATH_LAYER: footpathLayer,
                        ITINERARY_FOOTPATH_MARKERS_LAYER: footpathMarkersLayer,
                        ITINERARY_LAYER: pathLayer,
                        ITINERARY_LINE_ICON_LAYER: lineIconLayer,
                        TRACKS_BULLET_LAYER: legTrackMarkerBulletsLayer,
                        TRACKS_LAYER: legTrackMarkersLayer,
                    });

                    this.mapService.addLayersToMap([TRACKS_BULLET_LAYER, ITINERARY_LAYER]);
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private $actions: Actions,
        private layerBuilder: LayerBuilderService,
        private mapConfigurationService: MapConfigurationService,
        private mapService: MapService,
        private physicalStopService: PhysicalStopService,
        private store: Store<HomeStoreState.HomeState>,
    ) {}

    updateLayersBy(physicalStops: PhysicalStopAdapter[], activeEndpoint: Endpoint, stopsInSafeArea: PhysicalStopAdapter[]): void {
        if (isArrival(activeEndpoint)) {
            this.resetTracksLayerIntoMap();
        }

        const physicalStopsLayer = this.layerBuilder.buildStopsLayerFrom(physicalStops.map((adapter) => adapter.getData()));
        const commercialStopsLayer = this.layerBuilder.buildCommercialStopsLayerFrom(physicalStops);
        const smallCommercialStopsLayer = this.layerBuilder.buildSmallCommercialStopsLayerFrom(physicalStops);

        this.mapService.setPhysicalStopsLayer(physicalStopsLayer);
        this.mapService.setCommercialStopsLayer(commercialStopsLayer);
        this.mapService.setSmallCommercialStopsLayer(smallCommercialStopsLayer);

        if (physicalStops.length <= 0) {
            // This is to clear layers and prevent flashing when there is no stops on map but edge markers are shown 100ms because layer
            // is added then removed 100ms after
            this.mapService.clearEdgeLayer();
            this.mapService.clearTracksLayer();
        }
        const commercialStopIds = stopsInSafeArea.map((p) => p.getCommercialStopId()).filter(isNotNullOrUndefined);
        const commercialStopsCount = new Set(commercialStopIds).size;
        this.mapService.updateLayersOnCurrentZoom(commercialStopsCount > getMaxCommercialSopsToFireSearch(environment.company));
    }

    updateTracksLayerBy(physicalStops: PhysicalStopAdapter[], activeArea: ActiveArea): void {
        const displayLevel = this.mapService.getDisplayLevelConfigurationByCurrentZoom();
        const displayLevelConfig = this.mapConfigurationService.getMapDisplayLevelsConfiguration();
        if (displayLevel.zoomLimit === displayLevelConfig.TOO_HIGH.zoomLimit) {
            this.mapService.removeLayerFromMap(TRACKS_LAYER);
        } else {
            const tracksLayer: LayerGroup = this.layerBuilder.buildTracksLayerFromPhysicalStops(
                physicalStops.map((stop) => stop.getData()),
            );
            this.mapService.setTracksLayer(tracksLayer);
            this.mapService.addLayerToMap(TRACKS_LAYER);
            this.mapService.setDisplayingOfTracksLayerBy(activeArea);
        }
    }

    private getLegsEndpoints(itinerary: ItineraryAdapter): PhysicalStopAdapter[] {
        return itinerary
            .getLegsAdapters()
            .reduce<ItineraryStop[]>((previous, leg) => [...previous, leg.getFirstStop(), leg.getLastStop()], [])
            .filter((stop) => !!stop)
            .map(({ physicalStopAssociated }) => new PhysicalStopAdapter(physicalStopAssociated));
    }

    private focusOnMapByLegType(stop: StopAdapter, itinerary: ItineraryAdapter, legType: LegType): void {
        const currentLeg = itinerary.getLegWithStop(stop);
        if (!currentLeg) {
            return;
        }

        if (legType === LegType.Departure) {
            const prevLeg = itinerary.getPreviousLeg(currentLeg);
            this.focusOnMap(stop, currentLeg, prevLeg, legType);
        } else if (legType === LegType.Arrival) {
            const nextLeg = itinerary.getNextLeg(currentLeg);
            this.focusOnMap(stop, currentLeg, nextLeg, legType);
        }
    }

    private focusOnMap(stop: StopAdapter, currentLeg: LegAdapter, targetLeg: LegAdapter | undefined, legType: LegType): void {
        if (!targetLeg) {
            // No targetLeg means no previous (departure) or no next (arrival)
            // which means it is the first leg or last leg of the itinerary
            // So we focus on the clicked stop
            const stopBounds = this.mapService.createBoundsFromLatLng(stop.getLatLng(), 0);
            this.centerOnBBox(stopBounds);
            return;
        }
        if (this.isLegByFoot(targetLeg)) {
            // If the targetLeg is by foot then we want to include the foot leg in the view
            // So we center on the foot leg BBox
            const bbox = targetLeg.getBbox();
            if (!bbox) {
                console.error(`Can't center on bbox for leg ${targetLeg.getServiceNumber()}`);
                return;
            }
            this.centerOnBBox(targetLeg.getBbox());
            return;
        }
        if (legType === LegType.Departure) {
            // If departure then we center between previous leg last stop and current leg first stop
            this.centerBetweenLegs(targetLeg, currentLeg);
            return;
        }
        if (legType === LegType.Arrival) {
            // If arrival then we center between current leg last stop and next leg first stop
            this.centerBetweenLegs(currentLeg, targetLeg);
            return;
        }
        const stopBounds = this.mapService.createBoundsFromLatLng(stop.getLatLng());
        this.centerOnBBox(stopBounds);
    }

    private centerBetweenLegs(firstLeg: LegAdapter, secondLeg: LegAdapter): void {
        const previousStop = firstLeg.getLastPhyisicalStop();
        const currentStop = secondLeg.getFirstPhyisicalStop();
        if (!previousStop || !currentStop) {
            console.error(`Can't center on leg. One of leg has no physical stop.`);
            return;
        }
        const bbox = this.getLatLngBoundsFromStops([previousStop, currentStop]);
        this.centerOnBBox(bbox);
    }

    private getLatLngBoundsFromStops(stops: PhysicalStop[]): LatLngBounds {
        const latLngs: LatLngTuple[] = stops
            .map((stop) => new PhysicalStopAdapter(stop))
            .map((stop) => [stop.getLatLng(environment.defaultPlace).lat, stop.getLatLng(environment.defaultPlace).lng]);
        return new LatLngBounds(latLngs);
    }

    private isLegByFoot(leg: LegAdapter): boolean {
        return leg?.isByFoot();
    }

    private centerOnBBox(BBox: LatLngBounds): void {
        const visualOffset = 0.25;
        this.mapService.fitBounds(BBox.pad(visualOffset));
    }

    private async clearLayers(bounds: LatLngBounds): Promise<LatLngBounds> {
        const activeEndpoint: Endpoint = await firstValueFrom(this.store.select(EndpointSelectors.getActiveEndpoint));

        this.clearLayersByEndpoint(activeEndpoint);
        this.mapService.redrawDebugLayer(bounds, DebugLayerName.SafeArea);
        return bounds;
    }

    private resetTracksLayerIntoMap(): void {
        const tracksLayer: LayerGroup = this.layerBuilder.buildTracksLayerFromPhysicalStops([]);
        this.mapService.setTracksLayer(tracksLayer);
    }

    private clearLayersByEndpoint(endpoint: Endpoint): void {
        if (isArrival(endpoint)) {
            this.mapService.removeLayerFromMap(TRACKS_LAYER);
            this.mapService.deleteLayerUsingName(EDGE_LAYER);
        }
    }
}
