import { Bookmark } from '@traas/boldor/all-models';

export interface SearchPlaceState {
    bookmarks: Bookmark[];
    isChangingPlace: boolean;
}

export const initialState: SearchPlaceState = {
    bookmarks: [],
    isChangingPlace: false,
};
