import { AbstractAdapter, CategoryTickets, TicketAdapter } from '@traas/boldor/all-models';

export class CategoryTicketsAdapter extends AbstractAdapter<CategoryTickets> {
    constructor(data: CategoryTickets) {
        super(data);
    }

    getCategory(): {
        title: string;
        description: string;
    } {
        return this.getData().category;
    }

    getTickets(): TicketAdapter[] {
        return this.getData().tickets;
    }
}
