import { Injectable } from '@angular/core';
import { NearestStop, TicketAdapter, TicketDuration, TicketState, TravelClass, Traveler, Zone } from '@traas/boldor/all-models';
import { QuickArticlesMutation } from '@traas/boldor/graphql-generated/graphql';
import { GqlToService } from '@traas/boldor/all-helpers';
import { ArticleService } from '../../../services/common/article/article.service';
import { CategoryTicketsAdapter } from '../../../models/ticket/category-tickets';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type GqlQuickArticle = QuickArticlesMutation['article']['generateQuickArticles'][number];

@Injectable({
    providedIn: 'root',
})
export abstract class QuickArticleService {
    protected constructor(protected articleService: ArticleService) {}

    $getQuickArticlesCategories(nearestStop?: NearestStop): Observable<CategoryTicketsAdapter[]> {
        return from(this.getQuickArticles(nearestStop)).pipe(
            // tslint:disable-next-line:max-line-length
            map((quickQuickArticles) => this.adaptQuickArticles(quickQuickArticles)),
            map((tickets) => {
                return this.articleService.castTicketsIntoCategoryTickets(tickets);
            }),
        );
    }

    abstract getQuickArticles(
        nearestStop?: NearestStop,
        passenger?: Traveler,
        durationsFilter?: TicketDuration[],
    ): Promise<GqlQuickArticle[]>;

    adaptQuickArticles(quickQuickArticles: GqlQuickArticle[]): TicketAdapter[] {
        return quickQuickArticles.map((ticket: GqlQuickArticle) => {
            return this.adaptQuickArticle(ticket);
        });
    }

    adaptQuickArticle(ticket: GqlQuickArticle): TicketAdapter {
        return new TicketAdapter({
            article: {
                categoryDescription: ticket.categoryDescription,
                description: ticket.description,
                id: ticket.articleId,
                paymentMeans: ticket.paymentMeans,
                prices: ArticleService.formatPrices(ticket.prices),
                reduction: GqlToService.reduction(ticket.reduction),
                smsCode: ticket.smsCode,
                title: ticket.title,
                travelType: ticket.travelType,
                tripClass: ticket.tripClass,
                vatLabel: ticket.vatLabel,
                conditions: undefined,
                includes: undefined,
                locationsValidity: {
                    label: ticket?.locationsValidity?.label ?? '',
                    values: ticket?.locationsValidity?.values ?? [],
                },
                locationsChoice: {
                    label: ticket?.locationsChoice?.label ?? '',
                    values: ticket?.locationsChoice?.values ?? [],
                },
                roundTrip: undefined,
                tarifOwner: ticket.tarifOwner,
                travelClass: ticket.travelClass as unknown as TravelClass,
                validity: undefined,
                zones: ticket.zones,
                category: {
                    description: ticket.category?.description,
                    id: ticket.category?.id,
                },
            },
            state: TicketState.Unknown,
            id: undefined,
            departureStopName: undefined,
            qrCodeData: undefined,
            purchaseDate: undefined,
            duration: undefined,
            prices: ArticleService.formatPrices(ticket.prices),
            fqCode: undefined,
            passenger: undefined,
            orderNumber: undefined,
            referenceNumber: undefined,
            ticketNumber: undefined,
            type: undefined,
            articleNumber: undefined,
            isCancelled: undefined,
            passengerType: undefined,
        });
    }

    abstract getTicketList(): Promise<GqlQuickArticle[]>;

    abstract generateQuickArticleAdapterByZones(articleId: number, zones: Zone[], traveler: Traveler): Promise<TicketAdapter[]>;

    abstract generateQuickArticleByZone(articleId: number, zones: Zone[], traveler: Traveler): Promise<GqlQuickArticle[]>;
}
