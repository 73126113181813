import { Directive, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { $backButtonPushed } from '../../listeners/app-listener-utils';
import { BackButtonService } from './back-button.service';
import { enterZoneHelper } from '@traas/common/utils';

/**
 * This directive simulate a click on the host element when the user push the back button.
 * Only the top most directive i.e. the last directive to have been "init" will get the events.
 */

@Directive({
    selector: '[oitClickUsingBackButton]',
})
export class BackButtonDirective implements OnInit, OnDestroy {
    // we CANNOT use .takeUntil($destroy), for some weird reason the subscription would never unsubscribe.
    private readonly subscriptions = new Subscription();

    constructor(private el: ElementRef, private backButtonService: BackButtonService, private zone: NgZone) {}

    ngOnInit(): void {
        this.backButtonService.addOnTop(this);
        this.subscriptions.add(this.$backButtonPushedOnTopDirective().subscribe(() => this.onBackButtonPushed()));
    }

    ngOnDestroy(): void {
        this.backButtonService.remove(this);
        this.subscriptions.unsubscribe();
    }

    private $backButtonPushedOnTopDirective(): Observable<void> {
        return $backButtonPushed().pipe(
            enterZoneHelper(this.zone),
            filter(() => this.isTopDirective()),
        );
    }

    private isTopDirective(): boolean {
        return this.backButtonService.isOnTop(this);
    }

    private onBackButtonPushed(): void {
        this.simulateClickOnHost();
    }

    private getHostElement(): HTMLElement {
        return this.el.nativeElement;
    }

    private simulateClickOnHost(): void {
        this.getHostElement().click();
    }
}
