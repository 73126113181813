import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import {
    ArticlesBundle,
    ArticleSummary,
    ArticleViewModel,
    CheckoutTicketMode,
    QuickArticleViewModel,
    SelectedZone,
} from '@traas/boldor/all-models';
import { isDepartureJourney } from '../../../models/departure/departure';
import { isItineraryJourney } from '../../../models/itinerary/itinerary';
import { ArticleType, PassengerType, PaymentMeans } from '@traas/boldor/graphql-generated/graphql';
import { isQuickTicketCart } from '../../../models/cart/cart.utils';
import { CompanyService } from '../../../services/common/company/company.service';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { CartState } from './cart.state';
import { hideTicketTypeFromCart } from '../ui-utils/configure-ticket-ui-utils';

const TICKET_NOT_AVAILABLE = 'billet non disponible';

export const selectCartState = createFeatureSelector<CartState>('cart');

export const getCart = createSelector(selectCartState, ({ cart }) => cart);

export const getCartPrice = createSelector(selectCartState, ({ cart }) => cart?.totalPrice);

export const getArticlesBundles = createSelector(selectCartState, ({ articlesBundles }) => articlesBundles);

// Used only by TPG for multizones picker
export const getAvailableArticlesZones = pipe(
    select(getArticlesBundles),
    map((articlesBundles) => {
        if (!articlesBundles) {
            return [];
        }
        const availableArticles = _.flatten(articlesBundles?.map(({ availableArticles }) => availableArticles));
        let zones = availableArticles.map((article: QuickArticleViewModel) => {
            const zones = article.zones.map(({ id }) => +id);
            const places = article.zones
                .map(({ description }) => description)
                .filter((description) => !!description)
                ?.join(', ');
            return {
                zones,
                places,
            } as SelectedZone;
        });
        zones = _.uniqBy(zones, ({ zones, places }) => `${zones.join(',')}${places}`);
        zones = _.orderBy(zones, ({ zones }) => _.sum(zones), ['asc']);
        /**
         * At this step (NOK):
         * 10, 200
         * 10, 210, 230
         * 10, 200, 230
         * 10, 210, 240
         * 10, 200, 240
         */

        const bizones = zones.filter(({ zones }) => zones.length === 2);
        let trizones = zones.filter(({ zones }) => zones.length === 3);
        trizones = _.orderBy(trizones, ({ zones }) => zones[1], ['asc']);
        /**
         * At this step (OK):
         * 10, 200
         * 10, 200, 230
         * 10, 200, 240
         * 10, 210, 230
         * 10, 210, 240
         */

        zones = [...bizones, ...trizones];
        return zones;
    }),
);

export const getIsMultizones = pipe(
    select(getArticlesBundles),
    filter((articleBundles) => !!articleBundles),
    map((articlesBundles) => isMultizones(articlesBundles)),
);

export const getArticleSelections = createSelector(selectCartState, ({ cart }) => (cart ? cart.articleSelections : []));

export const getSelectedTicketType = createSelector(selectCartState, ({ configureTicket }) => {
    const productId = configureTicket.selectedProductId;
    return configureTicket.ticketTypes.find(({ id }) => id === productId);
});

export const getChooseTicketManually = createSelector(selectCartState, ({ configureTicket }) => configureTicket.chooseTicketManually);

export const getCheckoutStep = createSelector(selectCartState, ({ checkoutStep }) => checkoutStep);

export const getSelectedZones = createSelector(selectCartState, ({ configureTicket }) => configureTicket.selectedZones);

export const getCartIsInitialized = createSelector(selectCartState, ({ isInitialized }) => isInitialized);

export const hasSelectedPaymentMean = createSelector(selectCartState, ({ isPaymentMeanSelected }) => isPaymentMeanSelected);

export const isBuyOperationDisabled = createSelector(selectCartState, (state) => {
    return state?.articlesBundles?.every((ab) => ab?.availableArticles?.length === 0) || (!state?.articlesBundles && state.isInitialized);
});

export const isBuyOperationChecked = createSelector(selectCartState, ({ cart }) => !!cart?.operations.buy.isChecked);

export const isSelectionEligibleForThirdPartyPayement = createSelector(selectCartState, ({ cart }) => {
    return cart?.articleSelections?.every((selection) => selection.article?.thirdPartyPayerInformation?.isEligible);
});

export const getCartMode = createSelector(selectCartState, ({ cart, articlesBundles }) => {
    if (!cart) {
        return CheckoutTicketMode.UNKNOWN;
    }
    if (isMultizones(articlesBundles) && CompanyService.isTPG()) {
        return CheckoutTicketMode.QUICK_TICKET_MULTIZONE;
    }
    if (isItineraryJourney(cart.journeyViewModel)) {
        return CheckoutTicketMode.ITINERARY;
    }
    if (isDepartureJourney(cart.journeyViewModel)) {
        return CheckoutTicketMode.DEPARTURE;
    }
    if (isQuickTicketCart(cart)) {
        return CheckoutTicketMode.QUICK_TICKET;
    }
    return CheckoutTicketMode.UNKNOWN;
});

export const getIsDefaultArticlesBundleLoading = createSelector(
    selectCartState,
    ({ isDefaultArticlesBundleLoading }) => isDefaultArticlesBundleLoading,
);

export const getIsCartUpdating = createSelector(selectCartState, ({ isCartUpdating }) => isCartUpdating);

export const getPassengerIdsByPassengerType = (passengerType: PassengerType) =>
    createSelector(selectCartState, ({ articlesBundles }: CartState) =>
        articlesBundles
            ?.filter((articlesBundle: ArticlesBundle) => articlesBundle?.passenger?.type === passengerType)
            .map((articlesBundle: ArticlesBundle) => articlesBundle.passenger.id),
    );

export const getConfiguredTicket = createSelector(selectCartState, ({ configureTicket }) => configureTicket);

export const getConnectedUserArticlesBundle = createSelector(
    selectCartState,
    ({ connectedUserArticlesBundle }) => connectedUserArticlesBundle,
);

export const getDurationsFilter = createSelector(selectCartState, ({ durationsFilter }) => durationsFilter);

export const hideTicketType = createSelector(selectCartState, ({ cart }): boolean => {
    return hideTicketTypeFromCart(cart);
});

export const hasPaymentBySms = createSelector(selectCartState, ({ cart }) =>
    cart?.articleSelections.every(
        (selection) => cart?.articleSelections?.length > 0 && selection.article?.paymentMeans?.includes(PaymentMeans.Sms),
    ),
);

export const getIsMarketingConsentsLoading = createSelector(selectCartState, (cartState) => cartState.isMarketingConsentsLoading);

export const getArticlesSummary = createSelector(selectCartState, ({ cart }): ArticleSummary[] => {
    return !cart
        ? []
        : cart.articleSelections.reduce((arr: ArticleSummary[], item) => {
              let found = false;
              const description = getArticleDescription(item.article);

              // increment item
              for (let i = 0; i < arr.length; i++) {
                  if (arr[i].title === TICKET_NOT_AVAILABLE && !item.article) {
                      found = true;
                      arr[i].count++;
                  } else if (arr[i].title === `${item.article?.title}${description}`) {
                      found = true;
                      arr[i].count++;
                  }
              }

              // initialize item
              if (!found && !item.article) {
                  arr.push({ title: TICKET_NOT_AVAILABLE, count: 1 });
              } else if (!found && !!item.article) {
                  arr.push({ title: `${item.article.title}${description}`, count: 1 });
              }

              return arr;
          }, []);
});

function isMultizones(articlesBundles: ArticlesBundle[]): boolean {
    const isMultizones =
        articlesBundles?.length &&
        articlesBundles?.every((articlesBundle) => {
            return articlesBundle.availableArticles.every((article) => {
                const isQuickArticle = article.type === ArticleType.QuickArticle;
                const hasMultizones = (article as QuickArticleViewModel).zones?.length > 1;
                return isQuickArticle && hasMultizones;
            });
        });
    return isMultizones ?? false;
}

function getArticleDescription(article: ArticleViewModel): string {
    const SEPARATOR = ':';
    const description = article?.description ?? '';
    return description.includes(SEPARATOR) ? description.split(SEPARATOR)[1] : description;
}
