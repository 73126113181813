import { NgModule } from '@angular/core';
import { CartModule } from '@traas/boldor/common/features/cart/cart.module';
import { TicketModule } from '@traas/boldor/common/features/ticket/ticket.module';
import { IonicModule } from '@ionic/angular';
import { PageTitleModule } from '@traas/boldor/common/components/page-title/page-title.module';
import { TicketPage } from './pages/ticket.page';
import { TicketListComponent } from './containers/ticket-list/ticket-list.component';
import { FeatureTicketRoutingModule } from './feature-ticket-routing.module';
import { BoldorFeatureShellSharedModule } from '@traas/boldor/common/boldor-feature-shell-shared.module';

@NgModule({
    imports: [BoldorFeatureShellSharedModule, CartModule, TicketModule, IonicModule, PageTitleModule, FeatureTicketRoutingModule],
    declarations: [TicketPage, TicketListComponent],
})
export class FeatureTicketModule {}
