export enum Endpoint {
    Departure = 'DEPARTURE',
    Arrival = 'ARRIVAL',
}

export function isArrival(endpoint: Endpoint): boolean {
    return endpoint === Endpoint.Arrival;
}

export function isDeparture(endpoint: Endpoint | null): boolean {
    return endpoint === Endpoint.Departure;
}
