import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LegLineComponent } from './leg-line/leg-line.component';
import { LegsComponent } from './legs.component';

@NgModule({
    declarations: [LegsComponent, LegLineComponent],
    exports: [LegsComponent],
    imports: [CommonModule],
})
export class LegsModule {}
