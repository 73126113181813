import { Injectable } from '@angular/core';
import { SMS, SmsOptions } from '@awesome-cordova-plugins/sms/ngx';
import { AlertController } from '@ionic/angular';
import { AsyncDismissibleAlert, JourneyMessage } from '@traas/boldor/all-models';
import { PHONE_NUMBER_BUY_TICKET_BY_SMS } from '../../business-rules.utils';
import { BoldorLocalizationService } from '@traas/common/localization';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertService {
    constructor(private alertCtrl: AlertController, private boldorLocalizationService: BoldorLocalizationService, private sms: SMS) {}

    async buildLocationInitAlert(): Promise<AsyncDismissibleAlert> {
        const $dismissed = new Subject<void>();
        const header = await this.boldorLocalizationService.get('modal.localization');
        const cancelButton = await this.boldorLocalizationService.get('modal.cancel-button');
        const alert = await this.alertCtrl.create({
            header,
            message: '<div class="spinner-gps"></div>',
            cssClass: 'gps-localization-alert',
            buttons: [
                {
                    text: cancelButton,
                    handler: (): void => {
                        $dismissed.next();
                        $dismissed.complete();
                    },
                    cssClass: 'e2e-cancel-geoloc',
                },
            ],
        });
        alert.onDidDismiss().then((data) => {
            const wasDismissedByCustomer = data.role === 'backdrop';
            if (wasDismissedByCustomer) {
                $dismissed.next();
                $dismissed.complete();
            }
        });
        return { alert, $dismissed };
    }

    async presentSuccessItineraryBooking(): Promise<void> {
        const header = await this.boldorLocalizationService.get('tpc.request-stop.title-success');
        const content = await this.boldorLocalizationService.get('tpc.request-stop.content-success');
        this.genericAlert(header, content, 'e2e-success-booking');
    }

    async presentFailureItineraryBooking(): Promise<void> {
        const header = await this.boldorLocalizationService.get('tpc.request-stop.title-fail');
        const content = await this.boldorLocalizationService.get('tpc.request-stop.content-fail');
        this.genericAlert(header, content, 'e2e-failure-booking');
    }

    async presentSuccessRefundOrder(): Promise<void> {
        const refundTitle = await this.boldorLocalizationService.get('modal.refund.title');
        const refundContent = await this.boldorLocalizationService.get('modal.refund.content');
        await this.genericAlert(refundTitle, refundContent);
    }

    async presentConfirmRefundOrder(hasStopRequest: boolean): Promise<boolean> {
        const simpleRefundMessage = await this.boldorLocalizationService.get('modal.refund.simple-refund-confirm');
        const refundAndCancelStopRequestMessage = await this.boldorLocalizationService.get(
            'modal.refund.refund-and-cancel-stop-request-confirm',
        );
        const confirmTitle = await this.boldorLocalizationService.get('modal.refund.confirm-title');
        return this.confirmationAlert(confirmTitle, hasStopRequest ? refundAndCancelStopRequestMessage : simpleRefundMessage);
    }

    async confirmationAlert(header: string, message: string, cssClass?: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            const okButton = await this.boldorLocalizationService.get('modal.ok-button');
            const cancelButton = await this.boldorLocalizationService.get('modal.cancel-button');
            const alert = await this.alertCtrl.create({
                cssClass,
                header,
                message,
                backdropDismiss: false,
                buttons: [
                    {
                        text: okButton,
                        handler: () => resolve(true),
                        cssClass: 'e2e-alert-ok',
                    },
                    {
                        text: cancelButton,
                        handler: () => resolve(false),
                        cssClass: 'e2e-alert-cancel',
                    },
                ],
            });
            return await alert.present();
        });
    }

    async presentItineraryInThePastAlert(): Promise<void> {
        const header = await this.boldorLocalizationService.get('common.confirm');
        const message = await this.boldorLocalizationService.get('cart.itinerary-in-the-past');
        return this.genericAlert(header, message, [], { awaitDismiss: true });
    }

    async presentTicketBuyFailure(smsCode?: string): Promise<void> {
        const okButton = await this.boldorLocalizationService.get('modal.ok-button');
        const buttons = [
            {
                text: okButton,
                handler: () => ({}),
            },
        ];

        if (smsCode) {
            const payBySms = await this.boldorLocalizationService.get('modal.e-ticketing.pay-by-sms');

            buttons.push({
                text: payBySms,
                handler: () => {
                    const options: SmsOptions = { android: { intent: 'INTENT' } };
                    this.sms.send(PHONE_NUMBER_BUY_TICKET_BY_SMS, smsCode, options);
                    return {};
                },
            });
        }

        const buyFailTitle = await this.boldorLocalizationService.get('modal.e-ticketing.buy-fail-title');
        const retryMessage = await this.boldorLocalizationService.get('payment.retry-message');

        const alert = await this.alertCtrl.create({
            header: buyFailTitle,
            message: retryMessage,
            buttons,
        });
        return alert.present();
    }

    async accountResetPasswordEmailError(): Promise<void> {
        const failTitle = await this.boldorLocalizationService.get('modal.fail-title');
        const resetPasswordEmailError = await this.boldorLocalizationService.get('tpc.reset-password.email-error');
        return this.genericAlert(failTitle, resetPasswordEmailError);
    }

    async accountResetPasswordFailure(failureMessage: string | undefined): Promise<void> {
        const failTitle = await this.boldorLocalizationService.get('modal.fail-title');
        const resetPasswordFail = failureMessage || (await this.boldorLocalizationService.get('tpc.reset-password.content-fail'));
        return this.genericAlert(failTitle, resetPasswordFail);
    }

    async presentCancelStopRequestFailure(): Promise<void> {
        const failTitle = await this.boldorLocalizationService.get('modal.fail-title');
        const cancelFail = await this.boldorLocalizationService.get('tpc.request-stop.cancel-fail');
        return this.genericAlert(failTitle, cancelFail);
    }

    async presentCancelStopRequestSucceeded(): Promise<void> {
        const infoTitle = await this.getInfoTitle();
        const cancelSuccess = await this.boldorLocalizationService.get('tpc.request-stop.cancel-success');
        return this.genericAlert(infoTitle, cancelSuccess);
    }

    async accountResetPasswordSuccess(email: string): Promise<void> {
        const titleSuccess = await this.boldorLocalizationService.get('tpc.reset-password.title-success');
        const contentSuccess = await this.boldorLocalizationService.get('tpc.reset-password.content-success', { email });
        return this.genericAlert(titleSuccess, contentSuccess);
    }

    async frequentTravelerRemoved(traveler: string): Promise<void> {
        const infoTitle = await this.getInfoTitle();
        const message = await this.boldorLocalizationService.get('modal.frequent-travelers.remove-success', { traveler });
        return this.genericAlert(infoTitle, message, 'e2e-removed-traveler-success');
    }

    async frequentTravelerDeletionFailed(traveler: string): Promise<void> {
        const infoTitle = await this.getInfoTitle();
        const message = await this.boldorLocalizationService.get('modal.frequent-travelers.remove-fail', { traveler });
        return this.genericAlert(infoTitle, message);
    }

    async frequentTravelerCreated(traveler: string): Promise<void> {
        const infoTitle = await this.getInfoTitle();
        const message = await this.boldorLocalizationService.get('modal.frequent-travelers.create-success', { traveler });
        return this.genericAlert(infoTitle, message, 'e2e-added-traveler-success');
    }

    async frequentTravelerCreationFailed(traveler: string): Promise<void> {
        const infoTitle = await this.getInfoTitle();
        const message = await this.boldorLocalizationService.get('modal.frequent-travelers.create-fail', { traveler });
        return this.genericAlert(infoTitle, message);
    }

    async frequentTravelerUpdated(traveler: string): Promise<void> {
        const infoTitle = await this.getInfoTitle();
        const message = await this.boldorLocalizationService.get('modal.frequent-travelers.update-success', { traveler });
        return this.genericAlert(infoTitle, message, 'e2e-updated-traveler-success');
    }

    async frequentTravelerUpdateFailed(traveler: string): Promise<void> {
        const infoTitle = await this.getInfoTitle();
        const message = await this.boldorLocalizationService.get('modal.frequent-travelers.update-fail', { traveler });
        return this.genericAlert(infoTitle, message);
    }

    private async getInfoTitle(): Promise<string> {
        return await this.boldorLocalizationService.get('modal.info-title');
    }

    async creditCardRemoved(creditCardRemoved: string): Promise<void> {
        const infoTitle = await this.getInfoTitle();
        const message = await this.boldorLocalizationService.get('modal.credit-cards.remove-success', { creditCardRemoved });
        return this.genericAlert(infoTitle, message, 'e2e-credit-card-successfully-removed');
    }

    async creditCardDeletionFailed(creditCardRemoved: string): Promise<void> {
        const infoTitle = await this.getInfoTitle();
        const message = await this.boldorLocalizationService.get('modal.credit-cards.remove-fail', { creditCardRemoved });
        return this.genericAlert(infoTitle, message);
    }

    async presentDisruptionMessagesModal(messages: JourneyMessage[]): Promise<void> {
        const disruptionDefaultTitle = await this.boldorLocalizationService.get('modal.disruption-default-title');

        return this.genericAlert(
            ``,
            messages
                .map(({ title, htmlContent }) => {
                    const safeHtml = htmlContent.replace(new RegExp(/\\"/, 'g'), '"');
                    return `
            <div class="warning-modal">
                <div class="icon-container">
                    <ion-icon  name="warning"></ion-icon>
                </div>
                <span class="modal-title">${title ? title : disruptionDefaultTitle}</span>
                <div class="modal-text">
                    ${safeHtml}
                </div>
            </div>
            `;
                })
                .join('\n'),
            'custom-larger',
        );
    }

    async genericAlert(header: string, message: string, cssClass?: string | string[], options?: { awaitDismiss?: boolean }): Promise<void> {
        const okButton = await this.boldorLocalizationService.get('modal.ok-button');
        const alert = await this.alertCtrl.create({
            cssClass,
            header,
            message,
            buttons: [
                {
                    cssClass: 'close e2e-alert-ok',
                    text: okButton,
                    handler: (): void => {},
                },
            ],
        });
        const presentAlertPromise = alert.present();
        if (options?.awaitDismiss) {
            return alert.onDidDismiss().then();
        }
        return presentAlertPromise;
    }

    async presentCancelCartCheckoutDialog(): Promise<boolean> {
        const header = await this.boldorLocalizationService.get('common.confirm');
        const message = await this.boldorLocalizationService.get('cart.cancel-confirm');
        return await this.confirmationAlert(header, message);
    }
}
