import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { JourneyListScrollModeEnum, MapMode } from '@traas/boldor/all-models';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { convertToError, LoggingService } from '@traas/common/logging';
import { Store } from '@ngrx/store';
import { debounceTime, filter, takeUntil, takeWhile } from 'rxjs/operators';
import { MapSelectors } from '../../features/home/store/map';
import * as _ from 'lodash';
import { animate, style, transition, trigger } from '@angular/animations';
import { JourneyAdapter } from '../../features/booking/models/journey';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { isPlaceholder, Placeholder } from '../placeholder-list-item/placeholder.model';

@Component({
    selector: 'traas-infinite-scroll-list',
    templateUrl: './infinite-scroll-list.component.html',
    styleUrls: ['./infinite-scroll-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('items', [
            transition(':enter', [
                style({ transform: 'scale(0.8)', opacity: 0.1 }), // initial
                animate('600ms cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 })), // final
            ]),
        ]),
    ],
})
export class InfiniteScrollListComponent<T extends JourneyAdapter | Placeholder> implements OnDestroy, AfterViewInit {
    @Input() itemTemplate: TemplateRef<any>;
    @Output() scrollManually = new EventEmitter<void>();
    @Input() scrollMode: JourneyListScrollModeEnum;

    @Input() set journeys(journeys: T[]) {
        const oldJourneys = this.journeysValue;
        this.journeysValue = journeys;
        if (this.isPrepend(journeys, oldJourneys)) {
            const currentIdx = journeys.findIndex((journey) => this.getId(journey) === this.topJourneyId);
            if (currentIdx !== undefined) {
                this.cdRef.detectChanges();
                /* This if is to prevent bug on iOS: when user is scrolling if scrollToIndex is called
                at same moment, view is broken and list disappear because of rendering bug */
                if (this.$scrolledIndexChange.getValue() === 0) {
                    this.scrollToIndex(currentIdx);
                }
            }
        }

        this.$newJourneys.next();
    }

    get journeys(): T[] {
        return this.journeysValue;
    }

    @Input() itemHeightInPx = 40;
    @Output() requestMore = new EventEmitter<T>();
    @Output() requestPrevious = new EventEmitter<T>();
    @Output() newItemsInViewport = new EventEmitter<T[]>();
    viewportBufferSize = 14;

    @ViewChild(CdkVirtualScrollViewport) private viewport: CdkVirtualScrollViewport;
    private $scrolledIndexChange = new BehaviorSubject<number>(null);
    private $userIsScrolling = new BehaviorSubject<boolean>(false);
    private firstLoading = true;
    private journeysValue: T[] = [];
    private topJourneyId: string | null;
    private currentIndexInScrollAtScrollStart: number;
    private readonly $unsubscribe = new Subject<void>();
    private readonly $hasOutdatedJourneys = new Subject<void>();
    private readonly $newJourneys = new Subject<void>();

    constructor(private logger: LoggingService, private store: Store, private cdRef: ChangeDetectorRef) {
        this.resizeScrollOnSwitchMapMode();

        this.$hasOutdatedJourneys
            .pipe(
                debounceTime(50), // This is to prevent too many triggers
                filter(() => !this.$userIsScrolling.getValue()),
                takeWhile(() => this.scrollMode !== JourneyListScrollModeEnum.Manual),
            )
            .subscribe(() => {
                this.scrollToFirstFutureJourney();
            });

        this.$newJourneys
            .pipe(
                debounceTime(50), // This is to prevent too many triggers
                filter(() => !this.$userIsScrolling.getValue()),
                takeWhile(() => this.scrollMode !== JourneyListScrollModeEnum.Manual),
            )
            .subscribe(() => {
                this.scrollToFirstFutureJourney();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.$scrolledIndexChange
            .pipe(
                filter((index) => index !== null), //this.$scrolledIndexChange behavior stating with null
                debounceTime(50), //this is a random value to not be event's flooded
                takeUntil(this.$unsubscribe),
            )
            .subscribe(async (scrolledIndex) => {
                const SKELETON_OFFSET = 1;
                const startIndex = scrolledIndex;
                const endIndex = this.viewport.getRenderedRange().end;
                const total = this.viewport.getDataLength();
                const topJourneyIndex = Math.max(0, Math.min(startIndex - SKELETON_OFFSET, this.journeys.length - 1));

                if (!this.journeys?.length) {
                    this.topJourneyId = null;
                } else {
                    this.topJourneyId = this.getId(this.journeys[topJourneyIndex]);
                }

                if (startIndex === 0 && !this.firstLoading) {
                    this.emitRequestPrevious();
                }
                if (total === endIndex) {
                    this.emitRequestMore();
                }

                // when a scroll occurs, the index parameter seems to be false when it's superior or equal to 1.
                // we have to decrement index in these cases to handle when first element is not entirely visible.
                const rangeOfVisibleInViewport = {
                    start: topJourneyIndex,
                    end: topJourneyIndex + this.getNumberOfItemsVisibleInScroll(),
                };
                const journeysInScroll = [...this.journeys.slice(rangeOfVisibleInViewport.start, rangeOfVisibleInViewport.end)];
                this.dispatchJourneysInScroll(journeysInScroll);
                this.firstLoading = false;
            });
    }

    scrollIndexChange(index: number): void {
        this.$scrolledIndexChange.next(index);
    }

    onUserScrollStart(): void {
        this.currentIndexInScrollAtScrollStart = this.$scrolledIndexChange.getValue();
        this.$userIsScrolling.next(true);
    }

    onUserScrollEnd(): void {
        // If user has scroll on new index position in list, so we estimate than he has really scrolled.
        if (this.currentIndexInScrollAtScrollStart !== this.$scrolledIndexChange.getValue()) {
            this.scrollManually.emit();
        }
        this.$userIsScrolling.next(false);
    }

    scrollToFirstFutureJourney(): void {
        try {
            const futureJourneyIndex = this.journeys.findIndex((journey) => !isPlaceholder(journey) && !journey.isOutdated());
            if (futureJourneyIndex < 0) {
                return;
            }
            const SKELETON_OFFSET = 1;
            this.scrollToIndex(futureJourneyIndex + SKELETON_OFFSET);
        } catch (error) {
            this.logger.logError(
                new TechnicalError(
                    'Error while scrolling to first future journey',
                    ErrorCodes.Technical.ScrollToFirstFutureJourney,
                    convertToError(error),
                ),
            );
        }
    }

    identify(index: number, item: T): string {
        return getId(item);
    }

    hasOutdatedJourney(): void {
        this.$hasOutdatedJourneys.next();
    }

    private dispatchJourneysInScroll(journeysInScroll: T[]): void {
        this.newItemsInViewport.emit(journeysInScroll);
    }

    private resizeScrollOnSwitchMapMode(): void {
        this.store
            .select(MapSelectors.getMapMode)
            .pipe(
                filter((mapMode) => mapMode === MapMode.Small),
                takeUntil(this.$unsubscribe),
            )
            .subscribe(() => {
                const randomDelayToRecheckViewportPresence = 200;
                if (this.viewport) {
                    this.viewport?.checkViewportSize();
                } else {
                    // If user resize result list directly after app was started, before than first result was loaded, viewport is
                    // not existing
                    const $stopRecheck = new Subject<void>();
                    const $recheckViewportPresence = timer(randomDelayToRecheckViewportPresence, randomDelayToRecheckViewportPresence).pipe(
                        filter(() => !!this.viewport),
                        takeUntil($stopRecheck),
                    );
                    $recheckViewportPresence.subscribe(() => {
                        $stopRecheck.next();
                        this.viewport?.checkViewportSize();
                    });
                }
            });
    }

    /**
     * We consider a prepend if both array ends with the same item
     */
    private isPrepend(journeysA: T[], journeysB: T[]): boolean {
        const lastJourneyA = _.last(journeysA);
        const lastJourneyB = _.last(journeysB);
        if (!lastJourneyA || !lastJourneyB) {
            return false;
        }

        return journeysA.length !== journeysB.length && this.getId(lastJourneyA) === this.getId(lastJourneyB);
    }

    private emitRequestMore(): void {
        this.requestMore.emit(_.last(this.journeys));
    }

    private emitRequestPrevious(): void {
        this.requestPrevious.emit(this.journeys[0]);
    }

    private unsubscribe(): void {
        this.$unsubscribe.next();
        this.$unsubscribe.complete();
    }

    private scrollToIndex(index: number): void {
        if (this.$scrolledIndexChange.getValue() === index) {
            return;
        }
        this.viewport?.scrollToIndex(index);
    }

    private getNumberOfItemsVisibleInScroll(): number {
        let visibleItemsCount: number;
        const heightOfViewport = this.viewport?.elementRef.nativeElement.clientHeight;
        if (heightOfViewport) {
            visibleItemsCount = Math.ceil(heightOfViewport / this.itemHeightInPx);
        }
        return visibleItemsCount ?? 1;
    }

    private getId(journey: T): string {
        return getId(journey);
    }
}

function getId(journey: JourneyAdapter | Placeholder): string {
    if (isPlaceholder(journey)) {
        return journey.id;
    }
    return journey.getId();
}
