/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
    ArticleViewModel,
    CartOperation,
    CartOperationStatus,
    CheckoutStep,
    PriceType,
    QuickArticleViewModel,
    TravelClassType,
} from '@traas/boldor/all-models';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import {
    addOrUpdateExistingBundle,
    computeTotalPriceOfArticleSelections,
    createArticlesSelections,
    getAllAvailableArticlesToUniqArray,
    getSelectedTravelClassIfAvailable,
    getSelectedTravelTypeIfAvailable,
    hasMoreCriteriaAvailableIn,
    initConfigureTicketFrom,
    removeBundleByPassenger,
    setPriceTypeSelectedReducer,
    updateConfigureTicketPrice,
    updateConfigureTicketSelected,
    updateMissingTicketsTypesFrom,
    updateSelectedStateOfAllArticlesBundle,
    updateSelectedStateOfArticlesBundle,
} from './cart.util';
import { CartAction, CartActionTypes } from './cart.action';
import { CreditCardsAction } from '../../credit-cards/store/credit-cards.action';
import { CartState, initialState } from './cart.state';

export function cartReducer(state: CartState = initialState, action: CartAction | CreditCardsAction): CartState {
    switch (action.type) {
        case CartActionTypes.InitCart: {
            const { cart, travelType, chooseTicketManually } = action.payload;
            return {
                ...state,
                articlesBundles: null,
                cart: {
                    ...cart,
                },
                checkoutStep: CheckoutStep.TicketConfiguration,
                connectedUserArticlesBundle: null,
                isInitialized: false,
                configureTicket: {
                    ticketTypes: [],
                    selectedClass: null,
                    selectedProductId: null,
                    selectedTravelType: travelType ?? null,
                    chooseTicketManually,
                    selectedPriceType: PriceType.Normal,
                },
            };
        }

        case CartActionTypes.SetCheckoutStep:
            return {
                ...state,
                checkoutStep: action.payload,
            };

        case CartActionTypes.UpdateCart:
            const articleSelections = createArticlesSelections(state.articlesBundles ?? []);

            return {
                ...state,
                cart: {
                    ...state.cart,
                    articleSelections,
                    totalPrice: computeTotalPriceOfArticleSelections(articleSelections),
                },
                configureTicket: updateConfigureTicketPrice(state.configureTicket, state.articlesBundles ?? []),
                isCartUpdating: true,
            };

        case CartActionTypes.UpdateSaveOperationOfCart:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    operations: {
                        ...state.cart?.operations,
                        save: {
                            ...state.cart?.operations.save,
                            isChecked: action.payload.isChecked,
                        },
                    },
                },
            };

        case CartActionTypes.UpdateBuyOperationOfCart:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    operations: {
                        ...state.cart?.operations,
                        buy: {
                            ...state.cart?.operations.save,
                            isChecked: action.payload.isChecked,
                        },
                    },
                },
            };

        case CartActionTypes.UpdateStopRequestOperationOfCart:
            const stopRequest = (state.cart?.operations.stopRequest ?? {}) as CartOperation;
            return {
                ...state,
                cart: {
                    ...state.cart,
                    operations: {
                        ...state.cart?.operations,
                        stopRequest: {
                            ...stopRequest,
                            isChecked: action.payload.isChecked,
                        },
                    },
                },
            };

        case CartActionTypes.Save:
            return {
                ...state,
                cartOperations: {
                    ...state.cartOperations,
                    save: CartOperationStatus.PROCESS,
                },
            };

        case CartActionTypes.SaveSuccess:
            return {
                ...state,
                cartOperations: {
                    ...state.cartOperations,
                    save: CartOperationStatus.DONE,
                },
            };

        case CartActionTypes.SaveError:
            return {
                ...state,
                checkoutStep: CheckoutStep.TicketConfiguration,
                cartOperations: {
                    ...state.cartOperations,
                    save: CartOperationStatus.FAIL,
                },
            };

        case CartActionTypes.AskToStop:
            return {
                ...state,
                cartOperations: {
                    ...state.cartOperations,
                    requestStop: CartOperationStatus.PROCESS,
                },
            };

        case CartActionTypes.SetDurationsFilter:
            return {
                ...state,
                durationsFilter: action.payload.durations,
            };

        case CartActionTypes.SetSelectedPaymentMean:
            return {
                ...state,
                isPaymentMeanSelected: action.selected,
            };

        case CartActionTypes.SetNationalMarketingConsent:
            return {
                ...state,
                nationalMarketingConsent: action.nationalMarketingConsent,
            };

        case CartActionTypes.SetMarketingConsentsLoading:
            return {
                ...state,
                isMarketingConsentsLoading: action.isLoading,
            };

        case CartActionTypes.AskToStopSuccess:
            return {
                ...state,
                cartOperations: {
                    ...state.cartOperations,
                    requestStop: CartOperationStatus.DONE,
                },
            };

        case CartActionTypes.AskToStopError:
            return {
                ...state,
                cartOperations: {
                    ...state.cartOperations,
                    requestStop: CartOperationStatus.FAIL,
                },
            };
        case CartActionTypes.UpdateArticlesBundle: {
            const newState = cloneDeep(state) as CartState;
            const existingArticlesBundles = newState.articlesBundles ? newState.articlesBundles : [];
            const hasMoreCriteriaAvailableValue = hasMoreCriteriaAvailableIn([...existingArticlesBundles, action.payload.articlesBundle]);

            const { selectedClass, selectedTravelType, selectedProductId, chooseTicketManually, selectedPriceType, selectedZones } =
                newState.configureTicket;

            // 1. update selected articles
            const newBundle = updateSelectedStateOfArticlesBundle(
                action.payload.articlesBundle,
                chooseTicketManually ? selectedProductId : null, // null will not apply filter on this criteria
                selectedClass as TravelClassType,
                chooseTicketManually ? null : selectedTravelType, // null will not apply filter on this criteria
                selectedPriceType,
                selectedZones,
                hasMoreCriteriaAvailableValue,
            );

            // 2. add or update article bundle
            let articlesBundles = addOrUpdateExistingBundle(newState.articlesBundles ?? [], newBundle);
            // 2.1 re-update all bundle's selected articles (to re-set selected state with potential new criteria due to new bundle)
            articlesBundles = updateSelectedStateOfAllArticlesBundle(
                articlesBundles,
                chooseTicketManually ? selectedProductId : null, // null will not apply filter on this criteria
                selectedClass as TravelClassType,
                chooseTicketManually ? null : selectedTravelType, // null will not apply filter on this criteria
                selectedPriceType,
                selectedZones,
                chooseTicketManually,
            );

            // 3. update configure ticket
            const configureTicket = updateMissingTicketsTypesFrom(newState.configureTicket, newBundle);
            return {
                ...newState,
                articlesBundles,
                configureTicket,
            };
        }

        case CartActionTypes.RemoveArticlesBundle: {
            const newState = cloneDeep(state) as CartState;
            const { selectedClass, selectedTravelType, chooseTicketManually, selectedPriceType, selectedZones } = newState.configureTicket;

            const {
                payload: {
                    articlesBundle: { passenger: passengerToRemove },
                },
            } = action;

            // 1. remove article bundle
            const updatedArticlesBundles = removeBundleByPassenger(newState.articlesBundles ?? [], passengerToRemove);

            // We don't automatically reselect an available article
            if (chooseTicketManually) {
                return {
                    ...newState,
                    articlesBundles: updatedArticlesBundles,
                };
            }

            const allAvailableArticles = getAllAvailableArticlesToUniqArray(updatedArticlesBundles);

            // 2. update configure ticket
            let configureTicket = initConfigureTicketFrom(
                allAvailableArticles,
                selectedClass as TravelClassType,
                selectedTravelType,
                chooseTicketManually,
                null, // null will not apply filter on productId
                selectedPriceType,
            );
            configureTicket = updateConfigureTicketSelected(
                newState.configureTicket,
                allAvailableArticles,
                false, // false will not apply filter on productId
            );

            // 3. update articles bundles
            const articlesBundles = updateSelectedStateOfAllArticlesBundle(
                updatedArticlesBundles,
                null, // null will not apply filter on productId
                selectedClass as TravelClassType,
                selectedTravelType,
                selectedPriceType,
                selectedZones,
                chooseTicketManually,
            );

            return {
                ...newState,
                articlesBundles,
                configureTicket,
            };
        }

        case CartActionTypes.PrepareArticlesBundle: {
            const newState = {
                ...state,
                isCartUpdating: true,
            };
            if (!state.articlesBundles) {
                newState.isDefaultArticlesBundleLoading = true;
            }
            return newState;
        }

        case CartActionTypes.PrepareArticlesBundleSuccess:
        case CartActionTypes.PrepareArticlesBundleError:
            return {
                ...state,
                isDefaultArticlesBundleLoading: false,
                isCartUpdating: false,
                isInitialized: true,
            };

        case CartActionTypes.SetDefaultArticlesBundle: {
            const { articlesBundle, travelClass } = action.payload;
            const { selectedTravelType, chooseTicketManually, selectedPriceType } = state.configureTicket;
            const validTravelClass = getSelectedTravelClassIfAvailable(articlesBundle?.availableArticles, travelClass);
            const validTravelType = getSelectedTravelTypeIfAvailable(articlesBundle?.availableArticles, selectedTravelType);
            const configureTicket = initConfigureTicketFrom(
                articlesBundle?.availableArticles,
                validTravelClass,
                chooseTicketManually ? null : validTravelType, // null will not apply filter on this criteria
                chooseTicketManually,
                null, // null will not apply filter on this criteria
                selectedPriceType,
            );
            return {
                ...state,
                connectedUserArticlesBundle: articlesBundle,
                configureTicket,
            };
        }
        case CartActionTypes.ResetCart:
            return {
                ...state,
                checkoutStep: CheckoutStep.TicketConfiguration,
                cart: null,
                connectedUserArticlesBundle: null,
                isDefaultArticlesBundleLoading: false,
                isCartUpdating: false,
                articlesBundles: null,
                durationsFilter: [],
                cartOperations: {
                    buy: CartOperationStatus.INITIAL,
                    requestStop: CartOperationStatus.INITIAL,
                    save: CartOperationStatus.INITIAL,
                },
                isInitialized: false,
                isPaymentMeanSelected: false,
            };
        case CartActionTypes.UpdateCartSuccess:
        case CartActionTypes.UpdateCartFail:
            return {
                ...state,
                isCartUpdating: false,
            };
        case CartActionTypes.SetTypeSelected: {
            const newState = cloneDeep(state) as CartState;
            const { selectedClass, selectedPriceType, selectedZones } = newState.configureTicket;
            // console.log("CartReducer -> SetTypeSelected", { selectedClass, payload: action.payload})
            // 1. update selected type
            newState.configureTicket.selectedProductId = action.payload.id;
            newState.configureTicket.ticketTypes.forEach((ticketType) => {
                ticketType.isSelected = action.payload.id === ticketType.id;
            });
            // 2. update selected articles
            newState.articlesBundles = updateSelectedStateOfAllArticlesBundle(
                newState.articlesBundles ?? [],
                action.payload.id,
                selectedClass as TravelClassType,
                null,
                selectedPriceType,
                selectedZones,
                newState.configureTicket.chooseTicketManually,
            );
            return newState;
        }

        case CartActionTypes.SetPriceTypeSelected: {
            return setPriceTypeSelectedReducer(cloneDeep(state) as CartState, action.payload);
        }

        case CartActionTypes.SetClassSelected: {
            // 1. update selected travel class
            const newState = cloneDeep(state) as CartState;
            newState.configureTicket.selectedClass = action.payload.travelClassType;
            const { selectedTravelType, selectedProductId, chooseTicketManually, selectedPriceType, selectedZones } =
                newState.configureTicket;

            // 2. update selected articles in each bundles
            newState.articlesBundles = updateSelectedStateOfAllArticlesBundle(
                newState.articlesBundles ?? [],
                chooseTicketManually ? selectedProductId : null, // null will not apply filter on this criteria
                action.payload.travelClassType,
                chooseTicketManually ? null : selectedTravelType, // null will not apply filter on this criteria
                selectedPriceType,
                selectedZones,
                chooseTicketManually,
            );

            // 3. update ticket type selected
            const allAvailableArticles = getAllAvailableArticlesToUniqArray(newState.articlesBundles ?? []);
            newState.configureTicket = updateConfigureTicketSelected(
                newState.configureTicket,
                allAvailableArticles,
                chooseTicketManually, // false will not apply filter on productId
            );

            // 4. update ticket type prices
            newState.configureTicket = updateConfigureTicketPrice(newState.configureTicket, newState.articlesBundles ?? []);
            return newState;
        }

        case CartActionTypes.SetTravelTypeSelected: {
            // 1. update selected travel type
            const newState = cloneDeep(state) as CartState;
            newState.configureTicket.selectedTravelType = action.payload.travelType;
            const { selectedClass, selectedPriceType, selectedZones } = newState.configureTicket;

            // 2. update selected articles in each bundles
            newState.articlesBundles = updateSelectedStateOfAllArticlesBundle(
                newState.articlesBundles ?? [],
                null, // We ignore the productId filter in this action case, we will automatically select article by other criteria
                selectedClass as TravelClassType,
                action.payload.travelType,
                selectedPriceType,
                selectedZones,
                newState.configureTicket.chooseTicketManually,
            );

            // 3. update ticket type selected
            const allAvailableArticles = getAllAvailableArticlesToUniqArray(newState.articlesBundles ?? []);
            newState.configureTicket = updateConfigureTicketSelected(
                newState.configureTicket,
                allAvailableArticles,
                false, // false will not apply filter on productId
            );

            // 4. update ticket type prices
            newState.configureTicket = updateConfigureTicketPrice(newState.configureTicket, newState.articlesBundles ?? []);
            return newState;
        }

        case CartActionTypes.SetSelectedZones: {
            const articlesBundle = state.articlesBundles[0];
            const articles = articlesBundle.availableArticles.filter((article: ArticleViewModel) =>
                _.isEqual(
                    article.zones.map(({ id }) => +id),
                    action.selectedZones.zones,
                ),
            );
            const { selectedTravelType, chooseTicketManually, selectedPriceType, selectedClass } = state.configureTicket;
            const validTravelClass = getSelectedTravelClassIfAvailable(articlesBundle?.availableArticles, selectedClass as TravelClassType);
            const validTravelType = getSelectedTravelTypeIfAvailable(articlesBundle?.availableArticles, selectedTravelType);
            const updatedArticlesBundles = updateSelectedStateOfAllArticlesBundle(
                state.articlesBundles ?? [],
                null, // We ignore the productId filter in this action case, we will automatically select article by other criteria
                selectedClass,
                chooseTicketManually ? null : validTravelType, // null will not apply filter on this criteria
                selectedPriceType,
                action.selectedZones,
                chooseTicketManually,
            );
            const configureTicket = initConfigureTicketFrom(
                articles,
                validTravelClass,
                chooseTicketManually ? null : validTravelType, // null will not apply filter on this criteria
                chooseTicketManually,
                null, // null will not apply filter on this criteria
                selectedPriceType,
            );

            return {
                ...state,
                articlesBundles: updatedArticlesBundles,
                configureTicket: {
                    ...state.configureTicket,
                    ...configureTicket,
                    selectedZones: action.selectedZones,
                },
            };
        }

        default:
            return state;
    }
}
