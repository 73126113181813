<ion-list class="no-padding">
    <ng-container *ngIf="showPageContent(); else loading">
        <!-- MOBILE PAYMENT METHODS -->
        <div>
            <hr />
            <ion-item class="no-bottom-border">
                <ion-label class="ion-no-margin bold-text">{{ 'credit-cards.selector.use-mobile-payment' | translate }}</ion-label>
            </ion-item>
            <div class="flex-center payment-icons">
                <ng-container *ngFor="let mobilePaymentMethod of paymentMethodsConfiguration.mobiles">
                    <div (click)="selectMobilePaymentMethod(mobilePaymentMethod)">
                        <ion-img
                            [class.selected-option]="selectedMobilePaymentMethod === mobilePaymentMethod"
                            [class.selectable-item]="selectedMobilePaymentMethod !== mobilePaymentMethod"
                            src="{{ getMobilePaymentMethodIconPath(mobilePaymentMethod) }}"
                        ></ion-img>
                        <ion-radio hidden class="e2e-use-mobile-payment-{{ mobilePaymentMethod }}" value="{{ mobilePaymentMethod }}">
                        </ion-radio>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- SAVED CARDS -->
        <div *ngIf="!guestCustomer && creditCards.length > 0">
            <hr />
            <ion-item class="no-bottom-border">
                <ion-label class="ion-no-margin bold-text">
                    {{ 'credit-cards.selector.use-saved-card' | translate }}
                </ion-label>
            </ion-item>
            <ng-container *ngFor="let creditCard of creditCards">
                <ion-item
                    (click)="selectSavedCreditCard(creditCard)"
                    class="no-bottom-border e2e-credit-card selectable-saved-card-item"
                    [class.selected-option]="selectedSavedCard === creditCard"
                    [class.selectable-item]="selectedSavedCard !== creditCard"
                >
                    <ion-icon
                        class="payment-method-icon"
                        src="{{ getCreditCardIconPath(creditCard) }}"
                        [class.selected-saved-card-item]="selectedSavedCard === creditCard"
                    ></ion-icon>
                    <ion-text class="credit-card-number" [class.selected-saved-card-item]="selectedSavedCard === creditCard">
                        <p class="ion-no-margin">
                            {{ creditCard.maskedNumber }}
                        </p>
                    </ion-text>
                </ion-item>
            </ng-container>
            <ng-container *ngIf="selectedSavedCard !== null">
                <app-use-3d-secure
                    [showSkip3DSecure]="showSkip3DSecure"
                    [skip3DSecure]="skip3DSecure"
                    (skip3DSecureChange)="onSkip3DSecureChange($event)"
                >
                </app-use-3d-secure>
            </ng-container>
            <hr />
        </div>

        <!-- CARDS PAYMENT METHODS -->
        <div>
            <ion-item class="no-bottom-border">
                <ion-label class="ion-no-margin bold-text">
                    {{ 'credit-cards.selector.use-new' | translate }}
                </ion-label>
            </ion-item>
            <div class="flex-center payment-icons wrap-icons">
                <ng-container *ngFor="let cardPaymentMethod of paymentMethodsConfiguration.cards; let i = index">
                    <div (click)="selectCardPaymentMethod(cardPaymentMethod)" class="e2e-use-card-payment-{{ cardPaymentMethod }}">
                        <ion-img
                            [class.selected-option]="selectedCardPaymentMethod === cardPaymentMethod"
                            [class.selectable-item]="selectedCardPaymentMethod !== cardPaymentMethod"
                            src="{{ getCardPaymentMethodIconPath(cardPaymentMethod) }}"
                        ></ion-img>
                        <ion-radio hidden value="{{ cardPaymentMethod }}"></ion-radio>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="showSaveNewCard()">
                <ion-item lines="none">
                    <ion-checkbox
                        slot="start"
                        [checked]="createCreditCard"
                        (ionChange)="onCreateCreditCardChange($event)"
                        value="save-new"
                        class="e2e-save-new-card"
                        mode="md"
                    ></ion-checkbox>
                    <ion-label class="ion-text-wrap">
                        {{ 'credit-cards.selector.save-new-card' | translate }}
                    </ion-label>
                </ion-item>
                <app-use-3d-secure
                    [showSkip3DSecure]="showSkip3DSecure"
                    [skip3DSecure]="skip3DSecure"
                    (skip3DSecureChange)="onSkip3DSecureChange($event)"
                >
                </app-use-3d-secure>
            </ng-container>
        </div>
    </ng-container>
</ion-list>

<ng-template #loading>
    <app-loader></app-loader>
</ng-template>
