import { RouteUrl } from '@traas/common/routing';
import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { RouterState } from './state';

export const selectRouter = createFeatureSelector<fromRouter.RouterReducerState<any>>('router');
const getRouterState = createSelector(selectRouter, (router: fromRouter.RouterReducerState<any>) => {
    return router;
});

export const {
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouter);

function urlIsOneOf(...urls: string[]): (router: fromRouter.RouterReducerState<any>) => boolean {
    return (router) => urls.some((url) => router?.state?.url.includes(url));
}

export const getIsDepartureUrl = createSelector(getRouterState, urlIsOneOf(RouteUrl.departureResultUrl));

export const getIsItineraryUrl = createSelector(getRouterState, urlIsOneOf(RouteUrl.itineraryResultUrl));

/**
 * Map is the common word to represent itinerary or departure result
 */
export const getIsMapPage = createSelector(getIsDepartureUrl, getIsItineraryUrl, (isDeparture, isItinerary) => isDeparture || isItinerary);

/**
 * Journey is the common word to represent itinerary or departure detail
 */
export const getIsJourneyDetailsPage: MemoizedSelector<RouterState, boolean> = createSelector(
    getRouterState,
    urlIsOneOf(RouteUrl.departureDetailUrl, RouteUrl.itineraryDetailUrl),
);

export const getIsBookingPage: MemoizedSelector<RouterState, boolean> = createSelector(getRouterState, urlIsOneOf(RouteUrl.bookingsTabUrl));
