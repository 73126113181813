import { HalfFareService, HalfFareStorage } from '@traas/common/feature-account';
import { inject, Injectable } from '@angular/core';
import { ObservableTypedStorage } from '@traas/common/utils';

@Injectable()
export class TraasHalfFareService extends HalfFareService {
    private readonly halfFareStorage = inject(ObservableTypedStorage<HalfFareStorage>);

    async fetchAndStoreHasHalfFare(): Promise<void> {
        await this.halfFareStorage.setItem('hasHalfFare', false);
    }

    async getHasHalfFareFromStorage(): Promise<boolean> {
        return await this.halfFareStorage.getItem('hasHalfFare', false);
    }

    updateHalfFare(hasHalfFare: boolean): Promise<boolean> {
        return Promise.resolve(hasHalfFare);
    }
}
