import { capitalize } from '@angular-devkit/core/src/utils/strings';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dayOfWeek',
})
export class DayOfWeekPipe implements PipeTransform {
    transform(datetime: string): string {
        if (datetime) {
            return capitalize(moment(datetime).format('dddd D MMMM'));
        }
        return '-';
    }
}
