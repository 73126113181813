import { Bookmark } from './bookmark.model';
import { LatLngRect } from './lat-lng-rect';
import { ChangePlaceEventSourceEnum } from './places/change-place-event-source.enum';
import { PhysicalStop } from '@traas/common/models';

export interface Area {
    metadata: Metadata;
    boundsRect: LatLngRect | null;
}

export interface StopArea extends Area {
    physicalStops: PhysicalStop[];
}

export interface BookmarkArea extends Area {
    originalBookmark: Bookmark;
}

export function isBookmarkArea(area: Area): area is BookmarkArea {
    return (area as BookmarkArea).originalBookmark !== undefined;
}

export function isStopArea(area: Area): area is StopArea {
    return (area as StopArea).physicalStops !== undefined;
}

export interface Metadata {
    /* Who emited this location, map or db for instance */
    source: ChangePlaceEventSourceEnum;
    locationName: string;
    // Lat/Lng are used to pass it as parameters to search itineraries by XY
    longitude: string;
    latitude: string;
}
