import { NgModule } from '@angular/core';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { NationalMarketingConsentPreferenceComponent } from './national-marketing-consent-preference.component';

@NgModule({
    declarations: [NationalMarketingConsentPreferenceComponent],
    imports: [BoldorFeatureShellSharedModule],
    exports: [NationalMarketingConsentPreferenceComponent],
})
export class NationalMarketingConsentPreferenceModule {}
