import { createSelector } from '@ngrx/store';
import { MapState } from './map.state';
import { HomeState } from '../home.state';
import { getHomeState } from '../home.selectors';

export const getMapState = createSelector(getHomeState, (homeState: HomeState) => homeState.map);

export const getBounds = createSelector(getMapState, (mapState: MapState) => mapState.bounds);

export const getStopsInBounds = createSelector(getMapState, (mapState: MapState) => {
    if (mapState.hasTooManyStopsInBounds) {
        return mapState.stopsInBounds.slice(0, 100);
    }
    return mapState.stopsInBounds;
});

export const hasValidStopsCountInBounds = createSelector(getMapState, (mapState: MapState) => {
    return mapState.stopsInBounds.length > 0 && !mapState.hasTooManyStopsInBounds;
});

export const hasTooManyStopsInBounds = createSelector(getMapState, (mapState: MapState) => mapState.hasTooManyStopsInBounds);

export const getMapUpdating = createSelector(getMapState, (mapState: MapState) => mapState.updating);

export const isEnabled = createSelector(getMapState, (mapState: MapState) => mapState.isEnabled);

export const getMapMode = createSelector(getMapState, (mapState: MapState) => mapState.mode);
