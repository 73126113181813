import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ItineraryFiltersStorage, TransportMode } from '@traas/boldor/all-models';
import * as Gql from '@traas/boldor/graphql-generated/graphql';
import { GetTransportModesGQL } from '@traas/boldor/graphql-generated/graphql';
import { ItineraryStoreActions } from '../store';
import { ItineraryState } from '../store/itinerary.state';
import { ObservableTypedStorage } from '@traas/common/utils';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ItineraryFiltersService {
    constructor(
        private getTransportModesGQL: GetTransportModesGQL,
        private itineraryFiltersStorage: ObservableTypedStorage<ItineraryFiltersStorage>,
        private store: Store<ItineraryState>,
    ) {}

    async fetchAndStoreTransportModes(): Promise<void> {
        const { data } = await firstValueFrom(this.getTransportModesGQL.fetch());
        if (!data.transportModes) {
            data.transportModes = [];
        }
        const transportModes = data?.transportModes.map((gqlTransportModes) => this.toTransportMode(gqlTransportModes));
        await this.itineraryFiltersStorage.setItem('transportModes', transportModes);
        this.store.dispatch(new ItineraryStoreActions.SetEnabledTransportModes(transportModes));
    }

    getTransportModesFromStorage(): Promise<TransportMode[]> {
        return this.itineraryFiltersStorage.getItem('transportModes', []) as Promise<TransportMode[]>;
    }

    areTransportModeArraysIdentical(transportModesArray1, transportModesArray2): boolean {
        if ((transportModesArray1 && !transportModesArray2) || (!transportModesArray1 && transportModesArray2)) {
            return false;
        }
        if (transportModesArray1.length !== transportModesArray2.length) {
            return false;
        }
        for (const transportMode1 of transportModesArray1) {
            if (!transportModesArray2.some((transportMode2) => transportMode2.id === transportMode1.id)) {
                return false;
            }
        }
        return true;
    }

    private toTransportMode(transport: Gql.TransportMode): TransportMode {
        return {
            id: transport.id,
            name: transport.name,
            compatibleTransportModes: transport.compatibleTransportModes,
            singular: transport.singular,
            plural: transport.plural,
        };
    }
}
