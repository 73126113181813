export enum ChangePlaceEventSourceEnum {
    ManualMapMove = 'ManualMapMove',
    StopSelection = 'StopSelection',
    ClickFollowGps = 'ClickFollowGps',
    MyGpsPositionSelection = 'MyGpsPositionSelection',
    AddressSelection = 'AddressSelection',
    PoiSelection = 'PoiSelection',
    CitySelection = 'CitySelection',
    BookmarkSelection = 'BookmarkSelection',
}
