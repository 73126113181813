import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { MapService } from '../../../map/services/map.service';
import { CguStatus, TosService } from '@traas/common/feature-tos';
import { ObservableTypedStorage } from '@traas/common/utils';

@Component({
    selector: 'traas-accept-tos',
    templateUrl: './accept-tos.component.html',
    styleUrls: ['./accept-tos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptTosComponent {
    @ViewChild('checkboxSeparator') checkboxSeparator: ElementRef;

    cguAccepted: boolean;
    hasClickedAccept = false;

    constructor(
        private navCtrl: NavController,
        private modalCtrl: ModalController,
        private mapService: MapService,
        private route: ActivatedRoute,
        private platform: Platform,
        private cguStorage: ObservableTypedStorage<CguStatus>,
        private acceptTosService: TosService,
    ) {}

    // noinspection JSUnusedGlobalSymbols
    /**
     * Used to prevent bug in this scenario :
     * Home -> nav to -> My account -> click to input of login form -> the keyboard is opened, but the map on the homepage
     * is with an height of 0. So from this moment, all moveend (mapMoveEnd event from Asymetrik directive) will have a bad bounds
     * (same northEast lat/lng than southWest point). With this, we prevent this bug.
     */
    ionViewWillEnter(): void {
        if (this.mapService.hasMap()) {
            window.setTimeout(() => {
                this.mapService.getMap().invalidateSize();
            });
        }
    }

    async confirmTosAcceptanceAndLaunchApp(): Promise<void> {
        await this.acceptTosService.acceptedTos();
        await this.navCtrl.navigateRoot('/');
    }

    focusOnCheckbox(): void {
        this.hasClickedAccept = true;
        // we scroll in 50ms to give time to the warning message to appear
        setTimeout(() => {
            this.checkboxSeparator.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }, 50);
    }
}
