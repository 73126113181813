import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'app-expandable',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableComponent implements AfterViewInit {
    @ViewChild('expandWrapper', { read: ElementRef }) expandWrapper: ElementRef;

    expanded = false;
    expandHeight = '500vh';

    constructor(protected renderer: Renderer2) {}

    ngAfterViewInit(): void {
        try {
            this.renderer.setStyle(this.expandWrapper.nativeElement, 'max-height', this.expandHeight);
        } catch (e) {}
    }

    updateExpandedState(): void {
        this.expanded = !this.expanded;
    }
}
