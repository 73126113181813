import { BaseStop } from '@traas/common/models';

export interface HumanReadableStop {
    stopAreaFullName?: string;

    stopAreaName?: string;

    getCityName(): string;

    getName(): string;

    getId(): string;
}

export function createHumanReadableStop(stop: BaseStop): HumanReadableStop {
    return {
        getCityName: () => {
            return stop?.cityName ?? '';
        },
        getName: () => {
            return stop?.name ?? '';
        },
        getId: () => {
            return stop?.id ?? '';
        },
    };
}
