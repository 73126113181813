import { ArticlesBundle, Currency, QuickArticleViewModel } from '@traas/boldor/all-models';

export interface TicketCategory {
    id: string;
    title: string;
}

export abstract class ArticleCategoryService<T> {
    abstract getCategories(): Promise<TicketCategory[]>;

    abstract getTicketsUsingCategory(
        articlesBundle: ArticlesBundle<QuickArticleViewModel>,
        categoryId: string,
        currency?: Currency,
    ): Promise<T[]>;
}
