<ng-container *ngIf="$departureTimeVM | async as departureTimeVM">
    <app-departure-time
        class="flex"
        *ngIf="!($departureTimeInput | async).isCancelled; else isCancelledTrip"
        [departureTimeViewModel]="departureTimeVM"
    >
    </app-departure-time>
    <ng-template #isCancelledTrip>
        <traas-departure-time-cancelled class="flex" [departureTimeViewModel]="departureTimeVM"></traas-departure-time-cancelled>
    </ng-template>
</ng-container>
