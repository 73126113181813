import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BookingModule } from '../booking/booking.module';
import { AddTicketHeaderComponent } from './components/add-ticket-header/add-ticket-header.component';
import { ClassSelectionComponent } from './components/class-selection/class-selection.component';
import { ConfigureTicketErrorsMessageComponent } from './components/configure-ticket-errors-message/configure-ticket-errors-message.component';
import { ConfigureTicketManuallyComponent } from './components/configure-ticket-manually/configure-ticket-manually.component';
import { ConfigureTicketPassengerListComponent } from './components/configure-ticket-passenger-list/configure-ticket-passenger-list.component';
import { ConfigureTicketSummaryComponent } from './components/configure-ticket-summary/configure-ticket-summary.component';
import { ConfigureTicketComponent } from './components/configure-ticket/configure-ticket.component';
import { DurationSelectionComponent } from './components/duration-selection/duration-selection.component';
import { QuickTicketHeaderComponent } from './components/quick-ticket-header/quick-ticket-header.component';
import { RequestStopOperationComponent } from './components/request-stop-operation/request-stop-operation.component';
import { SaveJourneyOperationComponent } from './components/save-journey-operation/save-journey-operation.component';
import { TicketButtonComponent } from './components/ticket-button/ticket-button.component';
import { TicketHeaderComponent } from './components/ticket-header/ticket-header.component';
import { TravellerTypeChooserComponent } from './components/traveller-type-chooser/traveller-type-chooser.component';
import { CartFooterComponent } from './containers/cart-footer/cart-footer.component';
import { OperationChooserComponent } from './containers/operation-chooser/operation-chooser.component';
import { OrderComponent } from './containers/order/order.component';
import { PaymentComponent } from './containers/payment/payment.component';
import { CartStoreModule } from './store/cart.module';
import { CreditCardsModule } from '../credit-cards/credit-cards.module';
import { ItineraryModule } from '../itinerary/itinerary.module';
import { PageTitleModule } from '../../components/page-title/page-title.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { PriceTypeChooserComponent } from './components/price-type-chooser/price-type-chooser.component';
import { ZonesPickerComponent } from './containers/zones-picker/zones-picker.component';
import { ZonesCheckboxListComponent } from './components/zones-checkbox-list/zones-checkbox-list.component';
import { OffersLoaderComponent } from './components/offers-loader/offers-loader.component';
import { ErrorMessageComponent } from './error-message/error-message/error-message.component';
import { NationalMarketingConsentPreferenceModule } from '@traas/boldor/common/features/national-marketing-consent-preference/national-marketing-consent-preference.module';
import { CompanyMarketingConsentPreferenceModule } from '@traas/boldor/common/features/company-marketing-consent-preference/company-marketing-consent-preference.module';

@NgModule({
    imports: [
        BookingModule,
        CartStoreModule,
        CreditCardsModule,
        ItineraryModule,
        NationalMarketingConsentPreferenceModule,
        CompanyMarketingConsentPreferenceModule,
        PageTitleModule,
        BoldorFeatureShellSharedModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    declarations: [
        AddTicketHeaderComponent,
        CartFooterComponent,
        ConfigureTicketComponent,
        OperationChooserComponent,
        OrderComponent,
        DurationSelectionComponent,
        ClassSelectionComponent,
        TravellerTypeChooserComponent,
        ConfigureTicketManuallyComponent,
        PaymentComponent,
        QuickTicketHeaderComponent,
        RequestStopOperationComponent,
        SaveJourneyOperationComponent,
        TicketButtonComponent,
        TicketHeaderComponent,
        TravellerTypeChooserComponent,
        ConfigureTicketErrorsMessageComponent,
        ConfigureTicketPassengerListComponent,
        ConfigureTicketSummaryComponent,
        PriceTypeChooserComponent,
        ZonesPickerComponent,
        ZonesCheckboxListComponent,
        OffersLoaderComponent,
        ErrorMessageComponent,
    ],
    exports: [CartFooterComponent],
})
export class CartModule {}
