/* tslint:disable:typedef */
import { CreditCard } from '@traas/boldor/all-models';
import { Action } from '@ngrx/store';

export enum CreditCardsActionTypes {
    LoadCreditCards = '[CreditCards] Load credit cards...',
    LoadCreditCardsSuccess = '[CreditCards] Load credit cards success',
    LoadCreditCardsFail = '[CreditCards] Load credit cards fail',
    SetCreditCard = '[CreditCards] Set credit card to use',
    SetCreateCreditCard = '[CreditCards] Set create credit card',
    ResetCreditCards = '[CreditCards] Reset credit cards',
    SetSkip3DSecure = '[CreditCards] Set skip 3D Secure option',
}

export class LoadCreditCards implements Action {
    readonly type = CreditCardsActionTypes.LoadCreditCards;
}

export class ResetCreditCards implements Action {
    readonly type = CreditCardsActionTypes.ResetCreditCards;
}

export class LoadCreditCardsSuccess implements Action {
    readonly type = CreditCardsActionTypes.LoadCreditCardsSuccess;

    constructor(public payload: CreditCard[]) {}
}

export class LoadCreditCardsFail implements Action {
    readonly type = CreditCardsActionTypes.LoadCreditCardsFail;

    constructor(public payload: { error: any }) {}
}

export class SetCreditCard implements Action {
    readonly type = CreditCardsActionTypes.SetCreditCard;

    constructor(public payload: CreditCard | null) {}
}

export class SetCreateCreditCard implements Action {
    readonly type = CreditCardsActionTypes.SetCreateCreditCard;

    constructor(public payload: boolean) {}
}

export class SetSkip3DSecure implements Action {
    readonly type = CreditCardsActionTypes.SetSkip3DSecure;

    constructor(public payload: boolean) {}
}

export type CreditCardsAction =
    | LoadCreditCards
    | LoadCreditCardsSuccess
    | LoadCreditCardsFail
    | SetCreditCard
    | SetCreateCreditCard
    | ResetCreditCards
    | SetSkip3DSecure;
