import { ElementRef, inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ItineraryThermometerDrawerService {
    private rendererFactory = inject(RendererFactory2);

    drawTransportLegPathBetween(
        element1: HTMLElement,
        element2: HTMLElement,
        container: ElementRef,
        options: {
            lineBackgroundColor: string;
            fallbackLineStyle: string;
            formattedDelayOfLeg: string;
        },
    ): void {
        const renderer = this.rendererFactory.createRenderer(null, null);
        const positions = this.calculatePositions(element1, element2, container);

        const svg = this.createSVGElement(renderer, positions.lineStart, positions.lineEnd);
        const legPath = this.createLineElement(
            renderer,
            positions.lineStart,
            positions.lineEnd,
            options.lineBackgroundColor,
            options.fallbackLineStyle,
        );
        const legDuration = this.createTextElement(renderer, positions.textPosition, options.formattedDelayOfLeg);

        renderer.appendChild(svg, legPath);
        renderer.appendChild(svg, legDuration);
        renderer.appendChild(container.nativeElement, svg);
    }

    isReadyToDraw(element1: HTMLElement, element2: HTMLElement, container: ElementRef): boolean {
        const positions = this.calculatePositions(element1, element2, container);
        return positions.lineEnd.y > 0;
    }

    private calculatePositions(
        element1: HTMLElement,
        element2: HTMLElement,
        container: ElementRef,
    ): {
        lineStart: { x: number; y: number };
        lineEnd: { x: number; y: number };
        textPosition: { x: number; y: number };
    } {
        const containerRect = container.nativeElement.getBoundingClientRect();

        const calculateElementCenter = (elementRect: ClientRect, containerLeft: number, containerTop: number): { x: number; y: number } => {
            const x = elementRect.left + elementRect.width / 2 - containerLeft;
            const y = elementRect.top + elementRect.height / 2 - containerTop;
            return { x, y };
        };

        const lineStartCenter = calculateElementCenter(element1.getBoundingClientRect(), containerRect.left, containerRect.top);
        const lineEndCenter = calculateElementCenter(element2.getBoundingClientRect(), containerRect.left, containerRect.top);

        // Calculate the text's position considering the offset
        const horizontalOffsetForText = 18;
        const textMidX = (lineStartCenter.x + lineEndCenter.x) / 2 - horizontalOffsetForText;
        const textMidY = (lineStartCenter.y + lineEndCenter.y) / 2;

        return {
            lineStart: lineStartCenter,
            lineEnd: lineEndCenter,
            textPosition: { x: textMidX, y: textMidY },
        };
    }

    private createSVGElement(renderer: Renderer2, lineStart: { x: number; y: number }, lineEnd: { x: number; y: number }): any {
        const svg = renderer.createElement('svg', 'svg');
        renderer.addClass(svg, 'itinerary-transport-leg-thermometer-path');

        // Calculate the width and height of the SVG element
        const MARGIN = 50;
        const width = 200;
        const height = Math.abs(lineEnd.y - lineStart.y) + MARGIN;

        // Set the width, height, and position attributes of the SVG
        svg.setAttribute('width', width.toString());
        svg.setAttribute('height', height.toString());
        svg.setAttribute('style', 'position: absolute; top: 0; left: 0; pointer-events: none;');

        return svg;
    }

    private createLineElement(
        renderer: Renderer2,
        lineStart: { x: number; y: number },
        lineEnd: { x: number; y: number },
        color: string,
        fallbackLineStyle: string,
    ): any {
        const line = renderer.createElement('line', 'svg');
        line.setAttribute('x1', lineStart.x.toString());
        line.setAttribute('y1', lineStart.y.toString());
        line.setAttribute('x2', lineEnd.x.toString());
        line.setAttribute('y2', lineEnd.y.toString());
        if (color.length) {
            line.setAttribute('stroke', color);
        } else {
            line.classList.add(fallbackLineStyle);
        }
        line.setAttribute('stroke-width', '.8rem');
        return line;
    }

    private createTextElement(renderer: Renderer2, textPosition: { x: number; y: number }, content: string): any {
        const text = renderer.createElement('text', 'svg');
        renderer.addClass(text, 'duration');
        text.textContent = content;
        text.setAttribute('x', textPosition.x.toString());
        text.setAttribute('y', textPosition.y.toString());
        text.setAttribute('text-anchor', 'middle');
        text.setAttribute('dominant-baseline', 'middle');
        text.setAttribute('transform', `rotate(270, ${textPosition.x}, ${textPosition.y})`);
        return text;
    }
}
