import { registerPlugin } from '@capacitor/core';

import type { SwisspassPlugin } from './definitions';

const Swisspass = registerPlugin<SwisspassPlugin>('Swisspass', {
  web: () => import('./web').then(m => new m.SwisspassWeb()),
});

export * from './definitions';
export { Swisspass };
export * from './user-info.model';
export * from './user-info-swisspass.model';
