import { Layer, TooltipOptions } from 'leaflet';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

export const GPS_MARKER_LAYER = 'GPS_MARKER_LAYER';
export const PHYSICAL_STOPS_LAYER = 'PHYSICAL_STOPS_LAYER';
export const COMMERCIAL_STOPS_LAYER = 'COMMERCIAL_STOPS_LAYER';
export const SMALL_COMMERCIAL_STOPS_LAYER = 'SMALL_COMMERCIAL_STOPS_LAYER';
export const DYNAMIC_PLACE_LAYER = 'DYNAMIC_PLACE_LAYER';
export const TRACKS_LAYER = 'TRACKS_LAYER';
export const TRACKS_BULLET_LAYER = 'TRACKS_BULLET_LAYER';
export const EDGE_LAYER = 'EDGE_LAYER';
export const CIRCLE_BOUNDS_CALCULATOR = 'CIRCLE_BOUNDS_CALCULATOR';
export const ITINERARY_LAYER = 'ITINERARY_LAYER';
export const ITINERARY_LINE_ICON_LAYER = 'ITINERARY_LINE_ICON_LAYER';
export const ITINERARY_FOOTPATH_LAYER = 'ITINERARY_FOOTPATH_LAYER';
export const ITINERARY_FOOTPATH_MARKERS_LAYER = 'ITINERARY_FOOTPATH_MARKERS_LAYER';

export const RADIUS_AROUND_POINT_IN_METERS_WHEN_NO_STOPS = 300;

export const defaultLocalisationIcon = `<div class="user-marker">
        <div class="user-marker-pin bounce"></div>
        <div class="user-marker-shadow"></div>
        <div class="user-marker-pulse"></div>
    </div>`;

const allLayers = [
    CIRCLE_BOUNDS_CALCULATOR,
    COMMERCIAL_STOPS_LAYER,
    DYNAMIC_PLACE_LAYER,
    EDGE_LAYER,
    GPS_MARKER_LAYER,
    ITINERARY_LAYER,
    ITINERARY_LINE_ICON_LAYER,
    ITINERARY_FOOTPATH_LAYER,
    ITINERARY_FOOTPATH_MARKERS_LAYER,
    PHYSICAL_STOPS_LAYER,
    SMALL_COMMERCIAL_STOPS_LAYER,
    TRACKS_LAYER,
    TRACKS_BULLET_LAYER,
] as const;

type staticLayerName = (typeof allLayers)[number];
export type dynamicLayerName = string;
export type layerName = staticLayerName | dynamicLayerName;

export function dynamicLayerNameFactory(layerName: layerName, dynamicLayerName: string): layerName {
    return `${layerName}_${dynamicLayerName}`;
}

export type LayerSet<T = Layer> = {
    [key in layerName]: T;
};

export interface DebugLayerConfiguration {
    name: DebugLayerName;
    color: string;
    tooltipOptions: TooltipOptions;
}

export enum DebugLayerName {
    SafeArea = 'safeArea',
    PlaceArea = 'placeArea',
    EndpointArea = 'endpointArea',
    GpsArea = 'gpsArea',
}

export function getAllDebugLayersConfiguration(): DebugLayerConfiguration[] {
    return DEBUG_LAYER_CONFIGURATION;
}

export function getDebugLayerConfigurationBy(name: DebugLayerName): DebugLayerConfiguration {
    const config = DEBUG_LAYER_CONFIGURATION.find((conf) => conf.name === name);
    if (!config) {
        throw new TechnicalError(`No debug layer configuration for ${name}`, ErrorCodes.Map.MissingLayer);
    }
    return config;
}

const tooltipOptions: TooltipOptions = { permanent: true, opacity: 0.6, direction: 'center' };

const DEBUG_LAYER_CONFIGURATION: DebugLayerConfiguration[] = [
    {
        color: 'green',
        name: DebugLayerName.SafeArea,
        tooltipOptions: { ...tooltipOptions, offset: [100, 0] },
    },
    {
        color: 'orange',
        name: DebugLayerName.PlaceArea,
        tooltipOptions: { ...tooltipOptions, offset: [100, 40] },
    },
    {
        color: 'red',
        name: DebugLayerName.EndpointArea,
        tooltipOptions: { ...tooltipOptions, offset: [100, 80] },
    },
    {
        color: 'blue',
        name: DebugLayerName.GpsArea,
        tooltipOptions: { ...tooltipOptions },
    },
];
