/**
 * Synthese RGB colors are given following this format:
 * (red, green, blue)
 * where colors are in decimal format.
 *
 * This method transforms this format to a standard hex format: #AABBCC
 * @param rgbColor from Synthese or Hafas
 * @param lineId
 */
export function rgbToHexColor(rgbColor: string | undefined, lineId: string): { value?: string; error?: Error } {
    if (!rgbColor) return {};

    const regex = /\((?<r>\d+), *(?<g>\d+), *(?<b>\d+)\)/;
    const rgb = rgbColor.match(regex)?.groups;

    if (!rgb) return { error: new Error(`Invalid RGB Color format, expected: (r,g,b), got: ${rgbColor} for line ${lineId}`) };

    return {
        value: `#${Object.values(rgb)
            .map((color) => toHex(Number(color)))
            .join('')}`,
    };
}

function toHex(color: number): string {
    const HEXA_RADIX = 16;
    return color.toString(HEXA_RADIX).padStart(2, '0');
}
