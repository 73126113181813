import { NgModule } from '@angular/core';
import { CreditCardsComponent } from './components/credit-cards/credit-cards.component';
import { CreditCardSelectorComponent } from './containers/credit-card-selector/credit-card-selector.component';
import { CreditCardsStoreModule } from './store/credit-cards-store.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { Use3DSecureComponent } from './components/use-3d-secure/use3-d-secure.component';

@NgModule({
    declarations: [CreditCardSelectorComponent, CreditCardsComponent, Use3DSecureComponent],
    imports: [BoldorFeatureShellSharedModule, CreditCardsStoreModule],
    exports: [CreditCardSelectorComponent],
})
export class CreditCardsModule {}
