import { Currency, Price, Ticket } from '@traas/boldor/all-models';

export function getFormattedPriceFromNumber(currency: Currency, price: number, omitChf = false, omitCts = false): string {
    const fractionDigits = 2;
    switch (currency) {
        case Currency.CHF:
            return formatSwissPrice(price, omitChf, omitCts);
        case Currency.EUR:
        default:
            return `${price.toFixed(fractionDigits)} ${currency}`;
    }
}

export function getFormattedPriceFromPrice({ currency, value }: Price, omitChf = false, omitCts = false): string {
    const radix = 10;
    const priceUnit = 100;
    const valueAsNumber = Number.parseInt(value, radix) / priceUnit;
    return getFormattedPriceFromNumber(currency, valueAsNumber, omitChf, omitCts);
}

export function getFormattedTotalPrice(tickets: Ticket[]): string {
    try {
        const [firstTicket] = tickets;
        const [firstPrice] = firstTicket.article.prices;
        const { currency } = firstPrice;
        const totalPrice = getTotalPrice(tickets);

        return getFormattedPriceFromPrice(
            {
                currency,
                value: `${totalPrice}`,
            },
            false,
            false,
        );
    } catch (error) {
        return '';
    }
}

export function getTotalPrice(tickets: Ticket[], includeInTotalPrice: (ticket: Ticket) => boolean = (): boolean => true): number {
    return tickets
        .filter(includeInTotalPrice)
        .map(({ article }) => article.prices[0].value)
        .map(Number)
        .reduce((sum, price) => sum + price, 0);
}

function formatSwissPrice(price: number, omitChf = false, omitCents = true): string {
    let swissPrice = omitChf ? '' : `${Currency.CHF} `;
    if (omitCents && price % 1 === 0) {
        swissPrice += `${price}.-`;
    } else {
        swissPrice += `${price.toFixed(2)}`;
    }
    return swissPrice;
}
