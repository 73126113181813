import { ObservableTypedStorage } from '@traas/common/utils';
import { Injectable } from '@angular/core';
import { TosService } from './tos.service';
import { CGU_STATUS_STORAGE_KEY, CguStatus } from '../models/cgu-status.model';

@Injectable()
export class UnversionedTosService extends TosService {
    constructor(cguStorage: ObservableTypedStorage<CguStatus>) {
        super(cguStorage);
    }

    async hasAcceptedTos(): Promise<boolean> {
        const cguStatus = await this.cguStorage.getItem(CGU_STATUS_STORAGE_KEY);
        return !!cguStatus;
    }

    async acceptedTos(): Promise<void> {
        await this.cguStorage.setItem(CGU_STATUS_STORAGE_KEY, true);
    }
}
