<ng-container *ngFor="let stop of stopsAdapter; let first = first; let last = last; let even = even">
    <app-itinerary-leg-stop-list-item
        [lineStyle]="lineStyle"
        [networkId]="networkId"
        [isTrain]="isTrain(legAdapter.getTransportName())"
        [stop]="stop"
        [isLast]="last"
        [isFirst]="first"
        [ngClass]="even ? 'odd' : 'even'"
        (click)="onItemClicked(stop)"
    >
    </app-itinerary-leg-stop-list-item>
</ng-container>
