import { AbstractAdapter, DepartureDetails, TransitStop } from '@traas/boldor/all-models';

export class DepartureDetailsAdapter<T extends DepartureDetails = DepartureDetails> extends AbstractAdapter<T> {
    constructor(data: T) {
        super(data);
    }

    getTransitStops(): TransitStop[] {
        return this.getData().stops;
    }
}
