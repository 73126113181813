import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@traas/boldor/environments';
import { PlatformUtilsService, StorageService } from '@traas/common/utils';

@Component({
    selector: 'debug-info-display',
    templateUrl: './debug-info-display.component.html',
    styleUrls: ['./debug-info-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugInfoDisplayComponent {
    env = environment;
    storageDriver: string | null;
    deviceType: string;
    storageTypeIsWrong: boolean;

    constructor(private readonly storage: StorageService, private readonly platformUtilsService: PlatformUtilsService) {
        const isAndroid = this.platformUtilsService.isAndroid();
        const isIos = this.platformUtilsService.isIos();
        this.storageDriver = this.storage.driver;
        this.storageTypeIsWrong = (isAndroid || isIos) && this.storageDriver !== 'cordovaSQLiteDriver';

        if (isAndroid) {
            this.deviceType = 'android';
        } else if (isIos) {
            this.deviceType = 'ios';
        } else {
            this.deviceType = 'other';
        }
        this.deviceType += this.platformUtilsService.isApp() ? ' app' : ' web';
    }
}
