export abstract class AuthenticationApi {
    abstract isAuthenticated(): Promise<boolean>;

    // WARNING: do not use this method directly, use AccountManagementService.authenticateAndRedirectTo instead.
    abstract authenticate(): Promise<boolean>;

    abstract getAccessToken(): Promise<string | null>;

    // WARNING: do not use this method directly, use AccountManagementService.logout instead.
    abstract logout(): Promise<boolean>;

    // WARNING: The method should not be used, use AccountManagementService.forceLogout instead.
    abstract forceLogout(): Promise<void>;

    abstract deleteAccount(): Promise<void>;
}
