import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LegAdapter } from '../../../../models/itinerary/leg';
import * as moment from 'moment';

@Component({
    selector: 'app-itinerary-leg-walk',
    templateUrl: './itinerary-leg-walk.component.html',
    styleUrls: ['./itinerary-leg-walk.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryLegWalkComponent implements OnInit {
    @Input() isFirstLeg = false;
    @Input() isLastLeg = false;
    @Input() leg: LegAdapter;

    delayOfLeg: string;
    distance = '';

    ngOnInit(): void {
        const meters = this.leg.getDistanceInMeters();
        if (isNaN(meters)) {
            return;
        }
        this.distance = `${meters}m`;
        const delayOfLeg: moment.Duration = moment.duration(this.leg.getWalkDuration(), 'minutes');
        this.delayOfLeg = `${delayOfLeg.seconds() ? delayOfLeg.minutes() + 1 : delayOfLeg.minutes()} MIN`;
    }
}
