import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchPlacesResponseAdapter } from '../../adapters/search-places-response';
import { PlaceService } from '../../services/place.service';
import { BehaviorSubject } from 'rxjs';
import { Place } from '@traas/boldor/all-models';

@Component({
    selector: 'app-places-list',
    templateUrl: './places-list.component.html',
    styleUrls: ['./places-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesListComponent implements OnInit {
    @Input()
    set searchPlacesResponse(searchPlacesResponse: SearchPlacesResponseAdapter) {
        this.searchPlacesResponseValue = searchPlacesResponse;
        this.places = this.placeSearchService.getOrderedPlacesByTermScoring(this.term, this.searchPlacesResponseValue);
    }

    @Input() term: string;

    @Output() selectPlace = new EventEmitter<Place>();

    $itemsAreDisabled = new BehaviorSubject<boolean>(true);
    places: Place[] = [];
    searchPlacesResponseValue: SearchPlacesResponseAdapter;

    constructor(private placeSearchService: PlaceService) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.$itemsAreDisabled.next(false);
        }, 250);
    }

    emitSelectPlace(place: Place): void {
        this.selectPlace.emit(place);
    }

    trackByPlace(index: number, place: Place): string {
        return place.getId();
    }
}
