/* eslint-disable no-case-declarations */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AreaHelper, isFollowGps, setTimeoutPromiseHelper } from '@traas/boldor/all-helpers';
import {
    ChangePlaceEventSourceEnum,
    DataNearPoint,
    Endpoint,
    GeopositionAdapter,
    isDeparture,
    MapMode,
    NewEndpointPayload,
    PhysicalStopAdapter,
    StopArea,
    toBounds,
    toLatLngRect,
    YOUR_LOCATION_I18N_KEY,
} from '@traas/boldor/all-models';
import { EndpointService } from '../../../endpoint/services/endpoint.service';
import { HomeLayoutPercentage } from '../../models/home-layout-percentage.interface';
import { EndpointActions, EndpointSelectors } from '../../store/endpoint';
import { EndpointInformation } from '../../store/endpoint/endpoint.selector';
import { HomeState } from '../../store';
import { MapActions, MapSelectors } from '../../store/map';
import { SearchPlaceActions } from '../../store/searchPlace';
import { LayerBuilderService } from '../../../map/services/layer-builder.service';
import { MapService } from '../../../map/services/map.service';
import { MyGpsPositionPlaceAdapter } from '../../../place/adapters/my-gps-position-place';
import { BookmarkService } from '../../../../services/common/bookmark/bookmark.service';
import { ToasterService } from '../../../../services/common/toaster/toaster.service';
import { getIconNameByChangePlaceEvent, isMyLocation } from '../../../../business-rules.utils';
import { createBookmark } from '../../../../models/bookmark/bookmark-adapter.model';
import { convertToError, LoggingService } from '@traas/common/logging';
import { RouteUrl } from '@traas/common/routing';
import {
    DebugLayerName,
    DYNAMIC_PLACE_LAYER,
    dynamicLayerNameFactory,
    ITINERARY_FOOTPATH_LAYER,
    ITINERARY_FOOTPATH_MARKERS_LAYER,
    ITINERARY_LINE_ICON_LAYER,
    PHYSICAL_STOPS_LAYER,
    TRACKS_LAYER,
} from '@traas/common/utils';
import { LatLng } from 'leaflet';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapEffect } from '../../store/map/map.effect';
import { RouterSelectors } from '../../../../router-store';
import { RouterState } from '../../../../router-store/state';
import { environment } from '@traas/boldor/environments';
import { ErrorCodes, isSuccess, PhysicalStop, TechnicalError } from '@traas/common/models';

const MAXIMUM_DISTANCE_BETWEEN_DEPARTURE_AND_FIRST_STOP_IN_METERS = 500;
const PERCENTAGE_40 = 40;
const PERCENTAGE_50 = 50;
const PERCENTAGE_60 = 60;
const PERCENTAGE_100 = 100;

@Injectable({ providedIn: 'root' })
export class HomeMapContainerService {
    constructor(
        private store: Store<HomeState>,
        private routerStore: Store<RouterState>,
        private mapService: MapService,
        private toasterService: ToasterService,
        private endpointService: EndpointService,
        private bookmarkService: BookmarkService,
        private layerBuilder: LayerBuilderService,
        private logger: LoggingService,
        private mapEffect: MapEffect,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    enableOrDisableMapBy(mapMode: MapMode): void {
        if (!this.mapService.hasMap()) {
            return;
        }
        if (mapMode === MapMode.Full || mapMode === MapMode.Half) {
            this.store.dispatch(
                new MapActions.Enable({
                    enableDragging: true,
                    enableAllListeners: true,
                }),
            );
        } else {
            this.store.dispatch(new MapActions.Disable({ disableAllListeners: true }));
        }
        this.store.dispatch(new EndpointActions.Enable());
    }

    async manageEndpointAndMapBoundsBy(route: string): Promise<void> {
        // Specific to departure or arrival result ONLY
        // SetActiveEndpoint trigger a redraw/cleaning of the map
        if (route.includes(RouteUrl.departureResultUrl)) {
            await this.manageMapAndEndpointForDeparturePage();
        } else if (route.includes(RouteUrl.itineraryResultUrl)) {
            await this.manageMapAndEndpointForItineraryPage();
        }
    }

    async addToBookmark(activeEndpointInformation: EndpointInformation): Promise<void> {
        const isEndpointOnMyLocation = isMyLocation(activeEndpointInformation.area.metadata);
        if (isEndpointOnMyLocation) {
            return;
        }

        if (!(await this.bookmarkService.hasFreeCell())) {
            await this.toasterService.presentBookmarkLimitReached();
        }

        const alreadyHasBookmarkInStorage = await this.bookmarkService.hasBookmarkInStorage(activeEndpointInformation.area as StopArea);
        if (alreadyHasBookmarkInStorage) {
            this.store.dispatch(
                new SearchPlaceActions.RemoveBookmark({ bookmark: createBookmark(activeEndpointInformation.area as StopArea) }),
            );
            return;
        }

        const newBookmark = createBookmark(activeEndpointInformation.area as StopArea);
        this.store.dispatch(new SearchPlaceActions.AddBookmark({ newBookmark }));
    }

    $showFollowGPSButton(): Observable<boolean> {
        return this.store.select(EndpointSelectors.getActiveEndpointInformation).pipe(
            map((activeEndpoint) => {
                try {
                    return activeEndpoint && !isFollowGps(activeEndpoint.area?.metadata?.source);
                } catch (error) {
                    console.warn(error);
                    return false;
                }
            }),
        );
    }

    async unfollowGps(): Promise<void> {
        const activeEndpoint = await firstValueFrom(this.store.select(EndpointSelectors.getActiveEndpoint));
        this.store.dispatch(new EndpointActions.UnfollowGps({ endpoint: activeEndpoint }));
    }

    /**
     * @param newArea
     * @param isByDeparture
     */
    setEndpointLocation(newArea: StopArea, isByDeparture: boolean): void {
        const bounds = toBounds(newArea.boundsRect);
        if (!bounds) {
            console.warn('No bounds to set endpoint location');
        } else {
            this.mapService.redrawDebugLayer(bounds, DebugLayerName.EndpointArea);
        }
        if (isByDeparture) {
            this.endpointService.setDeparture(newArea);
        } else if (newArea.metadata.source) {
            this.endpointService.setArrival(newArea);
        }
    }

    calcPercentageLayout(mapMode: MapMode, isJourneyDetailsPage: boolean): HomeLayoutPercentage {
        // MapMode.Half is 50/50 in journey page
        if (isJourneyDetailsPage && mapMode === MapMode.Half) {
            return {
                map: PERCENTAGE_50,
                result: PERCENTAGE_50,
            };
        }
        switch (mapMode) {
            // MapMode.Small and MapMode.Half keep the same %
            // The difference is managed by css only. Like that
            // We don't reframe the map and don't reload the data
            case MapMode.Small:
            case MapMode.Half:
                return {
                    map: PERCENTAGE_60,
                    result: PERCENTAGE_40,
                };
            // MapMode.Full is very tricky because we have to
            // calculate the right percentage for one line of result
            case MapMode.Full:
            default:
                return this.calcPercentUsingDocument();
        }
    }

    async getBiggerMapMode(): Promise<MapMode | null> {
        const mapMode = await firstValueFrom(this.store.select(MapSelectors.getMapMode));
        switch (mapMode) {
            case MapMode.Small:
                return MapMode.Half;
            case MapMode.Half:
                return MapMode.Full;
            case MapMode.Full:
            default:
                return null;
        }
    }

    async getSmallerMapMode(): Promise<MapMode | null> {
        const mapMode = await firstValueFrom(this.store.select(MapSelectors.getMapMode));
        switch (mapMode) {
            case MapMode.Full:
                return MapMode.Half;
            case MapMode.Half:
                return MapMode.Small;
            case MapMode.Small:
            default:
                return null;
        }
    }

    drawDetailsOfJourneyOnMap(physicalStop: PhysicalStop): void {
        const stopsLayer = this.layerBuilder.buildStopsLayerFrom([physicalStop]);
        const tracksLayer = this.layerBuilder.buildTracksLayerFromPhysicalStops([physicalStop]);

        this.mapService.setPhysicalStopsLayer(stopsLayer);
        this.mapService.setTracksLayer(tracksLayer);
        this.mapService.addLayerToMap(PHYSICAL_STOPS_LAYER);
        this.mapService.addLayerToMap(TRACKS_LAYER);
    }

    /**
     * Detailed layers are things than we want see only when we are enough zoomed.
     */
    updateItineraryDetailedLayersByZoomLevel(): void {
        if (this.mapService.isValidZoomToShowItineraryPathDetailedLayers()) {
            this.showItineraryPathDetailedLayers();
        } else {
            this.removeItineraryPathDetailedLayers();
        }
    }

    getMarkersToShowOnMapByTypeOfDepartureEndpoint(departure: EndpointInformation, departureStop: PhysicalStopAdapter): LatLng[] {
        try {
            return this.getLatLngOfMarkersToShow(departure, departureStop);
        } catch (error) {
            this.logger.logError(new TechnicalError('Error while getting markers to show', ErrorCodes.Map.Marker, convertToError(error)));
        }

        return []; // Will fallback to departureStop.latLng
    }

    private removeItineraryPathDetailedLayers(): void {
        this.mapService.removeLayersFromMap([
            ITINERARY_LINE_ICON_LAYER,
            ITINERARY_FOOTPATH_LAYER,
            ITINERARY_FOOTPATH_MARKERS_LAYER,
            TRACKS_LAYER,
            dynamicLayerNameFactory(DYNAMIC_PLACE_LAYER, Endpoint.Departure),
        ]);
    }

    private showItineraryPathDetailedLayers(): void {
        this.mapService.addLayersToMap([
            ITINERARY_LINE_ICON_LAYER,
            ITINERARY_FOOTPATH_LAYER,
            ITINERARY_FOOTPATH_MARKERS_LAYER,
            TRACKS_LAYER,
            dynamicLayerNameFactory(DYNAMIC_PLACE_LAYER, Endpoint.Departure),
        ]);
    }

    /**
     * This function will update the endpoint and/or the bounds of map. So it will
     * automatically fires a new SearchEvent.
     *
     * @param newPosition
     * @param activeEndpoint
     * @param endpointFollowingGps
     */
    async updateEndpointOnNewGpsPosition(
        newPosition: GeopositionAdapter,
        activeEndpoint: Endpoint,
        endpointFollowingGps: Endpoint | null,
    ): Promise<void> {
        const isItineraryMode = await this.isItineraryMode();

        // We fitBounds on new GPS position if the active endpoint is following GPS ...
        if (endpointFollowingGps === activeEndpoint) {
            const fitBoundsOnGpsNearStops = this.mapService.fitBoundsOnGpsNearStops();
            const waitForMapFinishedUpdating = firstValueFrom(this.mapEffect.$moved);
            await Promise.all([fitBoundsOnGpsNearStops, waitForMapFinishedUpdating]);
        } else if (isItineraryMode) {
            const bounds = newPosition.getBounds();
            const dataNearGps = await this.mapService.fetchDataNear(bounds.getCenter());
            this.setEndpointWithFollowGpsPlace(endpointFollowingGps, dataNearGps);
        }
    }

    private async isItineraryMode(): Promise<boolean> {
        return await firstValueFrom(this.routerStore.select(RouterSelectors.getIsItineraryUrl));
    }

    /**
     * Call of this method will set departure or arrival and so update searchEvent then refresh
     * journeys.
     * @param endpointFollowingGps
     * @param dataNearGps
     * @private
     */
    private setEndpointWithFollowGpsPlace(endpointFollowingGps: Endpoint | null, dataNearGps: DataNearPoint): void {
        const action = isDeparture(endpointFollowingGps) ? new EndpointActions.NewDeparture(null) : new EndpointActions.NewArrival(null);

        const myGpsPosPlaceAdapter = new MyGpsPositionPlaceAdapter({
            name: YOUR_LOCATION_I18N_KEY,
            bounds: dataNearGps.bounds,
        });
        action.payload = this.createNewEndpointPayloadFromFollowGps(myGpsPosPlaceAdapter, dataNearGps.stops);
        this.store.dispatch(action);
    }

    private createNewEndpointPayloadFromFollowGps(place: MyGpsPositionPlaceAdapter, stops: PhysicalStop[]): NewEndpointPayload {
        return {
            area: {
                physicalStops: stops,
                boundsRect: toLatLngRect(place.getData().bounds),
                metadata: {
                    source: ChangePlaceEventSourceEnum.ClickFollowGps,
                    locationName: place.getName(),
                    latitude: place.transformToCoordinates().latitude.toString(),
                    longitude: place.transformToCoordinates().longitude.toString(),
                },
            } as StopArea,
            endpoint: {
                source: ChangePlaceEventSourceEnum.ClickFollowGps,
                hasTooMuchStops: false,
                iconName: getIconNameByChangePlaceEvent(ChangePlaceEventSourceEnum.ClickFollowGps),
                locations: place.getName(),
            },
        };
    }

    private getLatLngOfMarkersToShow(departure: EndpointInformation, departureStop: PhysicalStopAdapter): LatLng[] {
        switch (departure.endpoint.source) {
            case ChangePlaceEventSourceEnum.AddressSelection:
            case ChangePlaceEventSourceEnum.PoiSelection:
                if (!AreaHelper.hasLatLng(departure.area)) {
                    console.warn('No latlng on area to get markers to show');
                    return [];
                }
                const addressLatLng = AreaHelper.getLatLng(departure.area);
                const distanceBetweenDepartureAndFirstStop = addressLatLng.distanceTo(departureStop.getLatLng(environment.defaultPlace));
                if (distanceBetweenDepartureAndFirstStop < MAXIMUM_DISTANCE_BETWEEN_DEPARTURE_AND_FIRST_STOP_IN_METERS) {
                    return [addressLatLng, departureStop.getLatLng(environment.defaultPlace)];
                }
                break;
            case ChangePlaceEventSourceEnum.ClickFollowGps:
            case ChangePlaceEventSourceEnum.MyGpsPositionSelection:
                const gpsMarkerResult = this.mapService.getGpsMarker();
                if (!isSuccess(gpsMarkerResult)) {
                    this.logger.logLocalError(gpsMarkerResult.error, { level: 'warning' });
                    break;
                }
                const gpsLatLng = gpsMarkerResult.value.getLatLng();
                const distanceBetweenDepartureAndMyPosition = gpsLatLng.distanceTo(departureStop.getLatLng(environment.defaultPlace));
                if (distanceBetweenDepartureAndMyPosition < MAXIMUM_DISTANCE_BETWEEN_DEPARTURE_AND_FIRST_STOP_IN_METERS) {
                    return [gpsLatLng, departureStop.getLatLng(environment.defaultPlace)];
                }
                break;
            default:
                return [];
        }
        return [];
    }

    private async manageMapAndEndpointForDeparturePage(): Promise<void> {
        this.store.dispatch(new EndpointActions.HideArrival());
        // Sans ce timeout, quand on spam tab itinéraire tab départ, la map dezoom a chaque itération
        await setTimeoutPromiseHelper(() => {
            this.store.dispatch(new EndpointActions.SetActiveEndpoint(Endpoint.Departure));
        }, 0);
    }

    private async manageMapAndEndpointForItineraryPage(): Promise<void> {
        this.store.dispatch(new EndpointActions.ShowArrival());

        const departureArea = await firstValueFrom(this.endpointService.$getDepartureArea());
        const isDepartureSuitableForSearch = AreaHelper.isSuitableForSearch(departureArea, environment.company);

        if (isDepartureSuitableForSearch) {
            const arrivalArea = await firstValueFrom(this.endpointService.$getArrivalArea());
            const isArrivalSuitableForSearch = AreaHelper.isSuitableForSearch(arrivalArea, environment.company);

            this.store.dispatch(new EndpointActions.SetActiveEndpoint(isArrivalSuitableForSearch ? Endpoint.Departure : Endpoint.Arrival));
        }
    }

    private calcPercentUsingDocument(): HomeLayoutPercentage {
        const homeAreaOneResultHeight = this.document.getElementById('home-area-one-result').getBoundingClientRect().height;
        const homeContainerHeight = this.document.getElementById('home-container').getBoundingClientRect().height;
        const percentage = Math.floor((homeAreaOneResultHeight / homeContainerHeight) * PERCENTAGE_100);
        return {
            map: PERCENTAGE_100 - percentage,
            result: percentage,
        };
    }
}
