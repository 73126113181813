import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestPage } from './test';

const routes: Routes = [
    {
        path: 'test',
        component: TestPage,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TestRoutingModule {}
