import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { BookingRequirementComponent } from './booking-requirement/booking-requirement.component';

@NgModule({
    imports: [CommonModule, IonicModule],
    declarations: [BookingRequirementComponent],
    exports: [BookingRequirementComponent],
})
export class JourneyStatusModule {}
