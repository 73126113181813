import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BookingEffect } from './booking.effect';
import { bookingReducer } from './booking.reducer';

@NgModule({
    imports: [StoreModule.forFeature('booking', bookingReducer), EffectsModule.forFeature([BookingEffect])],
    providers: [BookingEffect],
})
export class BookingStoreModule {}
