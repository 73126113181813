import { CanActivateFn } from '@angular/router';
import { firstValueFrom, isObservable } from 'rxjs';

/**
 * Returns a canActivateFn which calls all the input guards, and returns the first non-true result or true if all canActivateFn functions return true.
 * @param canActivateFnFactories factories are called synchronously to inject dependencies
 * @returns the resulting CanActivateFn
 */
export const canActivateAllFn: (...canActivateFnFactories: (() => CanActivateFn)[]) => CanActivateFn = (...canActivateFnFactories) => {
    return async (next, state) => {
        // injection is allowed before the first `await`
        const canActivateFns = canActivateFnFactories.map((canActivateFnFactory) => canActivateFnFactory());

        for (const canActivateFn of canActivateFns) {
            const canActivateResult = canActivateFn(next, state);
            const canActivateValue = await (isObservable(canActivateResult)
                ? firstValueFrom(canActivateResult)
                : Promise.resolve(canActivateResult));
            if (canActivateValue !== true) {
                return canActivateValue;
            }
        }
        return true;
    };
};
