import { LatLngBounds, LatLngExpression } from 'leaflet';

export interface LatLngRect {
    southWest: LatLngExpression;
    northEast: LatLngExpression;
}

export const toBounds = (rect: LatLngRect | null): LatLngBounds | undefined =>
    rect ? new LatLngBounds(rect.southWest, rect.northEast) : undefined;

export const toLatLngRect = (latLngBounds: LatLngBounds): LatLngRect | undefined => {
    if (latLngBounds) {
        return { southWest: latLngBounds.getSouthWest(), northEast: latLngBounds.getNorthEast() };
    }
    return undefined;
};
