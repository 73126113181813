import { Point } from './grid.model';
import { SearchModeOptions } from '@traas/boldor/all-models';

export interface GridState {
    dragStartPoint?: Point;
    dragEndPoint?: Point;
    isInEdition: boolean;
    editAll: boolean;
    searchMode: SearchModeOptions;
}

export const initialState: GridState = {
    isInEdition: false,
    editAll: false,
    searchMode: SearchModeOptions.MAP,
};
