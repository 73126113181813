import { LatLng } from 'leaflet';
import { HumanReadableStop } from './human-readable-stop';
import { AbstractAdapter } from '../abstract.adapter';
import { AssociatedCommercialStop } from '@traas/common/models';

/**
 * This interface describe data received from the backend. See his usages.
 */

export class AssociatedCommercialStopAdapter extends AbstractAdapter<AssociatedCommercialStop> implements HumanReadableStop {
    constructor(data: AssociatedCommercialStop) {
        super(data);
    }

    getId(): string {
        return this.getData().id;
    }

    getDidok(): number | undefined | null {
        return this.getData().didok;
    }

    getName(): string {
        return this.getData() ? this.getData().name : '';
    }

    getCityName(): string {
        return this.getData()?.cityName ?? '';
    }

    getLatLng(): LatLng {
        if (!this.data.coordinates) {
            throw new Error('No coordinates to parse.');
        }
        return new LatLng(this.data.coordinates.latitude, this.data.coordinates.longitude);
    }
}
