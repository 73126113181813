import { inject, Injectable } from '@angular/core';
import { ReferenceStorage, Title } from '@traas/boldor/all-models';
import { sortAlphabeticallyAsc } from '../../../business-rules.utils';
import { firstValueFrom } from 'rxjs';
import { ObservableTypedStorage } from '@traas/common/utils';
import { GetFrequentTravelerTitlesGQL } from '@traas/boldor/graphql-generated/graphql';
import { convertToError, LoggingService } from '@traas/common/logging';
import { BoldorLocalizationService } from '@traas/common/localization';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

export const FREQUENT_TRAVELER_TITLES_KEY = 'frequentTravelerTitles';

@Injectable()
export class ReferencesService {
    private referenceStorage = inject(ObservableTypedStorage<ReferenceStorage>);
    private getFrequentTravelerTitlesGQL = inject(GetFrequentTravelerTitlesGQL);
    private loggingService = inject(LoggingService);
    private boldorLocalizationService = inject(BoldorLocalizationService);

    private static sortTitlesByLabelAsc(titleA: Pick<Title, 'label'>, titleB: Pick<Title, 'label'>): number {
        return sortAlphabeticallyAsc(titleA.label, titleB.label);
    }

    private static areTitlesDifferent(localTitles: Title[], titles: Title[]): boolean {
        return (
            !localTitles ||
            (titles?.length > 0 && titles.length !== localTitles.length) ||
            titles.some(({ label, value }) => {
                return !localTitles.find((localTitle) => {
                    return localTitle.label === label && localTitle.value === value;
                });
            })
        );
    }

    async initReferencesIfNeeded(): Promise<void> {
        await this.initFrequentTravelerTitles();
    }

    private async initFrequentTravelerTitles(): Promise<void> {
        try {
            const localFrequentTravelerTitles = await this.referenceStorage.getItem(FREQUENT_TRAVELER_TITLES_KEY);
            const frequentTravelerTitles = await this.getFrequentTravelerTitles();
            if (ReferencesService.areTitlesDifferent(localFrequentTravelerTitles, frequentTravelerTitles)) {
                await this.referenceStorage.setItem(FREQUENT_TRAVELER_TITLES_KEY, frequentTravelerTitles);
            }
        } catch (error) {
            this.loggingService.logError(
                new TechnicalError('Error while initializing frequent traveler titles', ErrorCodes.References.Init, convertToError(error)),
            );
        }
    }

    private async getFrequentTravelerTitles(): Promise<Title[]> {
        try {
            const currentLanguage = this.boldorLocalizationService.languageCode;
            const { data } = await firstValueFrom(this.getFrequentTravelerTitlesGQL.fetch({ language: currentLanguage }));
            return data.references.frequentTravelerTitles?.sort(ReferencesService.sortTitlesByLabelAsc);
        } catch (error) {
            throw new TechnicalError(
                'Error while fetching frequent traveler titles',
                ErrorCodes.References.FetchTitles,
                convertToError(error),
            );
        }
    }
}
