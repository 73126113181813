import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PageTitleComponent } from './page-title.component';
import { BackButtonModule } from '../../directives/back-button/back-button.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [PageTitleComponent],
    imports: [CommonModule, RouterModule, IonicModule, BackButtonModule],
    exports: [PageTitleComponent],
})
export class PageTitleModule {}
