<traas-scroll-behavior-badge [mode]="scrollMode"></traas-scroll-behavior-badge>
<ion-content [scrollY]="false">
    <cdk-virtual-scroll-viewport
        traasUserScroll
        (userScrollStart)="onUserScrollStart()"
        (userScrollEnd)="onUserScrollEnd()"
        [maxBufferPx]="viewportBufferSize * itemHeightInPx"
        [minBufferPx]="viewportBufferSize * itemHeightInPx"
        [itemSize]="itemHeightInPx"
        (scrolledIndexChange)="scrollIndexChange($event)"
    >
        <ion-list class="journeys-list e2e-journeys-list">
            <ng-content select="[skeleton-start]"></ng-content>
            <div [@items]="journeys.length" *cdkVirtualFor="let journey of journeys; trackBy: identify" tappable>
                <ng-container
                    *ngTemplateOutlet="itemTemplate; context: { $implicit: journey.getData ? journey.getData() : journey }"
                ></ng-container>
            </div>
            <ng-content select="[skeleton-end]"></ng-content>
        </ion-list>
    </cdk-virtual-scroll-viewport>
</ion-content>
