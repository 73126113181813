import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { fromEvent, fromEventPattern, merge, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { PluginListenerHandle } from '@capacitor/core';
import { BackButtonListenerEvent } from '@capacitor/app/dist/esm/definitions';

export function $getBrowserFinishedEvent(): Observable<void> {
    return fromEventPattern<void>(
        async (handler) => {
            return Browser.addListener('browserFinished', handler);
        },
        async (_, pluginListener: Promise<PluginListenerHandle>) => {
            await (await pluginListener).remove();
        },
    ).pipe();
}

type CapacitorAppEvent = 'appStateChange' | 'appUrlOpen' | 'appRestoredResult' | 'backButton';

/**
 * Returns an observable that emits events from a specified Capacitor App event.
 *
 * @template T - The type of data expected from the event.
 * @param {CapacitorAppEvent} event - The Capacitor App event to listen to.
 * @returns {Observable<T>} An observable that emits event data of type T.
 *
 * @example
 * const urlOpenEvent$ = getAppEventObservable<AppLaunchUrl>('appUrlOpen');
 * urlOpenEvent$.subscribe(data => console.log(data));
 */
export function $getAppEventObservable<T>(event: CapacitorAppEvent): Observable<T> {
    return fromEventPattern(
        async (handler) => {
            return App.addListener(event as any, handler);
        },
        async (_, pluginListener: Promise<PluginListenerHandle>) => {
            await (await pluginListener).remove();
        },
    ).pipe(map((info) => info as unknown as T));
}

export function $backButtonPushed(): Observable<void> {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return merge(
        $debugBackButton(),
        $getAppEventObservable<BackButtonListenerEvent>('backButton').pipe(
            take(1),
            map(() => {}),
        ),
    );
}

export function $debugBackButton(): Observable<void> {
    return fromEvent<KeyboardEvent>(document, 'keydown').pipe(
        // IntlBackslash = option + < on MacOS swiss-fr keyboard.
        filter(({ code }) => code === 'IntlBackslash'),
        tap(() => console.log('Android back button pressed')),
        map(() => undefined),
    );
}
