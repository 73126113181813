import { Endpoint } from './endpoint.enum';
import { Area } from '../area.model';

export interface ActiveArea {
    endpoint: Endpoint;
    area: Area;
}

export function createActiveArea(activeEndpoint: Endpoint, areaAssociated: Area): ActiveArea {
    return {
        endpoint: activeEndpoint,
        area: areaAssociated,
    };
}
