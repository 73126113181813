import { ChangePlaceEventSourceEnum, Endpoint, isDeparture, toBounds, YOUR_LOCATION_I18N_KEY } from '@traas/boldor/all-models';
import { getIconNameByChangePlaceEvent } from '../../../../business-rules.utils';
import { EndpointActions, EndpointActionTypes } from './endpoint.action';
import { EndpointState, initialState } from './endpoint.state';

export function endpointReducer(state: EndpointState = initialState, action: EndpointActions): EndpointState {
    switch (action.type) {
        case EndpointActionTypes.NewDeparture:
            if (state.departure.ignoreNextChange) {
                return {
                    ...state,
                    departure: {
                        ...state.departure,
                        endpointValue: Endpoint.Departure,
                        ignoreNextChange: false,
                    },
                };
            }
            return {
                ...state,
                departure: {
                    ...state.departure,
                    area: action.payload.area,
                    endpoint: action.payload.endpoint,
                },
            };

        case EndpointActionTypes.NewArrival:
            if (!!state.arrival && state.arrival.ignoreNextChange) {
                return {
                    ...state,
                    arrival: {
                        ...state.arrival,
                        ignoreNextChange: false,
                    },
                };
            }
            return {
                ...state,
                arrival: {
                    ...state.arrival,
                    area: action.payload.area,
                    endpoint: action.payload.endpoint,
                },
            };

        case EndpointActionTypes.SetBounds: {
            const centerOfBounds = toBounds(action.payload.bounds)?.getCenter();
            if (!centerOfBounds) {
                console.warn('[SetBounds reducer] No bounds to set.');
            }
            if (action.payload.endpoint === Endpoint.Departure) {
                return {
                    ...state,
                    departure: {
                        ...state.departure,
                        area: {
                            ...state.departure.area,
                            boundsRect: action.payload.bounds,
                            metadata: {
                                ...state.departure.area.metadata,
                                latitude: `${centerOfBounds?.lat}`,
                                longitude: `${centerOfBounds?.lng}`,
                            },
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    arrival: {
                        ...state.arrival,
                        area: {
                            ...state.arrival.area,
                            boundsRect: action.payload.bounds,
                            metadata: {
                                ...state.arrival.area.metadata,
                                latitude: `${centerOfBounds?.lat}`,
                                longitude: `${centerOfBounds?.lng}`,
                            },
                        },
                    },
                };
            }
        }

        case EndpointActionTypes.IgnoreNextChange:
            if (isDeparture(action.payload.endpoint)) {
                return {
                    ...state,
                    departure: {
                        ...state.departure,
                        ignoreNextChange: action.payload.isIgnored,
                    },
                };
            }
            return {
                ...state,
                arrival: {
                    ...state.arrival,
                    ignoreNextChange: action.payload.isIgnored,
                },
            };

        case EndpointActionTypes.SetActiveEndpoint:
            return {
                ...state,
                activeEndpoint: action.endpoint,
            };

        case EndpointActionTypes.FollowGps:
            let endpointInFollowingGps: EndpointState;
            if (isDeparture(action.payload.endpoint)) {
                endpointInFollowingGps = buildSliceOfEndpointReplacingSourceOfChangeLocation(
                    state,
                    'departure',
                    ChangePlaceEventSourceEnum.ClickFollowGps,
                );
                endpointInFollowingGps.departure.endpoint.locations = YOUR_LOCATION_I18N_KEY;
                endpointInFollowingGps.departure.area.metadata.locationName = YOUR_LOCATION_I18N_KEY;
            } else {
                endpointInFollowingGps = buildSliceOfEndpointReplacingSourceOfChangeLocation(
                    state,
                    'arrival',
                    ChangePlaceEventSourceEnum.ClickFollowGps,
                );
                endpointInFollowingGps.arrival.endpoint.locations = YOUR_LOCATION_I18N_KEY;
                endpointInFollowingGps.arrival.area.metadata.locationName = YOUR_LOCATION_I18N_KEY;
            }
            return {
                ...state,
                ...endpointInFollowingGps,
            };

        case EndpointActionTypes.UnfollowGps:
            let endpointUnfollowingGps;
            if (isDeparture(action.payload.endpoint)) {
                endpointUnfollowingGps = buildSliceOfEndpointReplacingSourceOfChangeLocation(
                    state,
                    'departure',
                    ChangePlaceEventSourceEnum.ManualMapMove,
                );
            } else {
                endpointUnfollowingGps = buildSliceOfEndpointReplacingSourceOfChangeLocation(
                    state,
                    'arrival',
                    ChangePlaceEventSourceEnum.ManualMapMove,
                );
            }
            return {
                ...state,
                ...endpointUnfollowingGps,
            };

        case EndpointActionTypes.SwapEndpoint:
            return {
                ...state,
                departure: {
                    ...state.arrival,
                    endpointValue: Endpoint.Departure,
                },
                arrival: {
                    ...state.departure,
                    endpointValue: Endpoint.Arrival,
                },
            };

        /**
         * It set Departure or Arrival metadata slice of state (Arrival by default) by theactiveEndpoint.
         */
        case EndpointActionTypes.SetChangePlaceEventSource:
            if (isDeparture(state.activeEndpoint)) {
                return {
                    ...state,
                    departure: {
                        ...state.departure,
                        area: {
                            ...state.departure.area,
                            metadata: {
                                ...state.departure.area.metadata,
                                ...action.payload.metadata,
                                source: action.payload.source,
                            },
                        },
                        endpoint: {
                            ...state.departure.endpoint,
                            source: action.payload.source,
                            iconName: getIconNameByChangePlaceEvent(action.payload.source),
                        },
                    },
                };
            }
            return {
                ...state,
                arrival: {
                    ...state.arrival,
                    area: {
                        ...state.arrival.area,
                        metadata: {
                            ...state.arrival.area.metadata,
                            ...action.payload.metadata,
                            source: action.payload.source,
                        },
                    },
                    endpoint: {
                        ...state.arrival.endpoint,
                        source: action.payload.source,
                        iconName: getIconNameByChangePlaceEvent(action.payload.source),
                    },
                },
            };

        case EndpointActionTypes.HideArrival:
            return {
                ...state,
                arrival: {
                    ...state.arrival,
                    hidden: true,
                },
            };

        case EndpointActionTypes.ShowArrival:
            return {
                ...state,
                arrival: {
                    ...state.arrival,
                    hidden: false,
                },
            };

        case EndpointActionTypes.Disable:
            return {
                ...state,
                isEnabled: false,
            };

        case EndpointActionTypes.Enable:
            return {
                ...state,
                isEnabled: true,
            };

        case EndpointActionTypes.ArrivalChanged:
            return {
                ...state,
                arrival: {
                    ...state.arrival,
                    isChanging: false,
                },
            };

        case EndpointActionTypes.DepartureChanged:
            return {
                ...state,
                departure: {
                    ...state.departure,
                    isChanging: false,
                },
            };

        case EndpointActionTypes.StartChangingDeparture:
            return {
                ...state,
                departure: {
                    ...state.departure,
                    isChanging: true,
                },
            };

        case EndpointActionTypes.StartChangingArrival:
            return {
                ...state,
                arrival: {
                    ...state.arrival,
                    isChanging: true,
                },
            };

        case EndpointActionTypes.SetTooMuchStopsOnActiveEndpoint: {
            const activeEndpoint = isDeparture(state.activeEndpoint) ? 'departure' : 'arrival';
            return {
                ...state,
                [activeEndpoint]: {
                    ...state[activeEndpoint],
                    endpoint: {
                        ...state[activeEndpoint].endpoint,
                        hasTooMuchStops: action.payload,
                    },
                },
            };
        }

        default:
            return state;
    }
}

function buildSliceOfEndpointReplacingSourceOfChangeLocation(
    state: EndpointState,
    endpointPropertyKey: 'departure' | 'arrival',
    source: ChangePlaceEventSourceEnum,
): any {
    const iconName: string = getIconNameByChangePlaceEvent(source);
    return {
        [endpointPropertyKey]: {
            ...state[endpointPropertyKey],
            area: {
                ...state[endpointPropertyKey].area,
                metadata: {
                    ...state[endpointPropertyKey].area.metadata,
                    source,
                },
            },
            endpoint: {
                ...state[endpointPropertyKey].endpoint,
                source,
                iconName,
            },
        },
    };
}
