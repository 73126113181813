<ion-row>
    <ion-col size="12" class="ion-text-center progress-bar-container">
        <div class="margin-top" *ngIf="!showError; else showErrorTemplate">
            <ion-progress-bar [value]="progressionPercent / 100"></ion-progress-bar>
        </div>
        <ng-template #showErrorTemplate>
            <ion-icon name="alert-circle-outline" color="danger" class="error-icon"></ion-icon>
        </ng-template>
    </ion-col>
    <ion-col size="12" class="ion-text-center">
        <ion-text class="title">
            <p>{{ statusTitle }}</p>
        </ion-text>
    </ion-col>
    <ion-col size="12" class="ion-text-center">
        <ion-text color="danger" *ngIf="isOffline && !showError">
            <p>{{ 'payment.offline' | translate }}</p>
        </ion-text>
        <ion-text *ngIf="!isOffline">
            <p>{{ statusDescription }}</p>
        </ion-text>
    </ion-col>
    <ion-col size="12" class="ion-text-center" *ngIf="isRunningTooLong && !showError">
        <ion-text color="danger">
            <p>{{ 'payment.is-running-too-long' | translate }}</p>
        </ion-text>
    </ion-col>
</ion-row>
