import { AbstractAdapter, Article, Currency } from '@traas/boldor/all-models';
import { getFormattedPriceFromNumber } from '@traas/boldor/all-helpers';
import { PaymentMeans } from '@traas/boldor/graphql-generated/graphql';
import { CompanyService } from '../services/common/company/company.service';

export class ArticleAdapter extends AbstractAdapter<Article> {
    constructor(data: Article) {
        super(data);
    }

    getProductId(): string {
        return this.getData().id;
    }

    getTitle(): string {
        return this.getData().title;
    }

    getDescription(): string {
        return this.getData().description;
    }

    getPrices(): string[] {
        return this.getData().prices.map(({ currency, value }) => {
            return getFormattedPriceFromNumber(currency, +value, false, !CompanyService.isTPG());
        });
    }

    hasPriceInCurrency(currency: Currency): boolean {
        return this.getData().prices.findIndex((price) => price.currency === currency) !== -1;
    }

    isPaymentBySmsAllowed(): boolean {
        return this.hasPaymentMeans() && this.getPaymentMeans().findIndex((paymentMean) => paymentMean === PaymentMeans.Sms) !== -1;
    }

    isPaymentByCreditCardAllowed(): boolean {
        return this.hasPaymentMeans() && this.getPaymentMeans().findIndex((paymentMean) => paymentMean === PaymentMeans.CreditCard) !== -1;
    }

    isOnlyPaymentBySmsAllowed(): boolean {
        return this.isPaymentBySmsAllowed() && !this.isPaymentByCreditCardAllowed();
    }

    getDefaultCurrency(preferredCurrency: Currency): Currency {
        const { prices } = this.getData();
        return prices.length === 1 ? prices[0].currency : preferredCurrency;
    }

    getRawPriceInCurrency(currency: Currency): number {
        // do not multiply by 100 directly !!!!!!!!!!
        return this.getPriceInCurrency(currency) * 10 * 10;
    }

    getPriceInCurrency(currency: Currency): number {
        const priceFound = this.getData().prices.find((price) => {
            return price.currency === currency;
        });
        return +priceFound.value;
    }

    private getPaymentMeans(): PaymentMeans[] {
        return this.getData().paymentMeans;
    }

    private hasPaymentMeans(): boolean {
        const paymentMeans = this.getPaymentMeans();
        return paymentMeans && paymentMeans.length > 0;
    }
}
