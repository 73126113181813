import { Injectable } from '@angular/core';
import { AppLaunchUrl } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { CancellationType, Cart, PaymentTransaction, PaymentTransactionStatus } from '@traas/boldor/all-models';
import { environment } from '@traas/boldor/environments';
import {
    GetOrderStatusGQL,
    OrderStatus,
    OrderStatusResult,
    PayGQL,
    PaymentResult,
    PayMutationVariables,
    TryCancelOrderGQL,
} from '@traas/boldor/graphql-generated/graphql';
import { ClientPlatformConverter } from '../converters/client-platform.converter';
import { TRY_CANCEL_ORDER_RETRY_INTERVAL_MS } from '../../../business-rules.utils';
import { $getAppEventObservable, $getBrowserFinishedEvent } from '../../../listeners/app-listener-utils';
import { isDepartureCart, isItineraryCart, isQuickTicketCart } from '../../../models/cart/cart.utils';
import { AnalyticsService } from '@traas/common/analytics';
import { BoldorLocalizationService } from '@traas/common/localization';
import { PlatformUtilsService } from '@traas/common/utils';
import { EMPTY, firstValueFrom, merge, Observable } from 'rxjs';
import { delay, filter, map, retry, take } from 'rxjs/operators';
import { DepartureArticlePurchaseService } from './departure-article-purchase.service';
import { ItineraryArticlePurchaseService } from './itinerary-article-purchase.service';
import { QuickArticlePurchaseService } from './quick-article-purchase.service';
import { AppState } from '@capacitor/app/dist/esm/definitions';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

@Injectable({
    providedIn: 'root',
})
export class PurchaseArticleService {
    constructor(
        private itineraryPurchaseService: ItineraryArticlePurchaseService,
        private quickArticlePurchaseService: QuickArticlePurchaseService,
        private departureArticlePurchaseService: DepartureArticlePurchaseService,
        private platformService: PlatformUtilsService,
        private getOrderStatusGQL: GetOrderStatusGQL,
        private tryCancelOrderGql: TryCancelOrderGQL,
        private payGQL: PayGQL,
        private analyticsService: AnalyticsService,
        private boldorLocalizationService: BoldorLocalizationService,
        private platformUtilsService: PlatformUtilsService,
    ) {}

    async pay(
        orderId: string,
        createCreditCard: boolean,
        cardAliasId: string | undefined,
        use3DSecure: boolean | null,
        paymentMethodName: string | null,
    ): Promise<PaymentResult> {
        const clientPlatform = ClientPlatformConverter.toGql(this.platformUtilsService.isIos());
        const variable: PayMutationVariables = {
            language: {
                id: this.boldorLocalizationService.languageCode,
            },
            orderId,
            paymentMethod: {
                cardAlias: {
                    createNewOne: createCreditCard,
                    id: cardAliasId,
                    use3DSecure,
                },
                clientPlatform,
                paymentMethodName,
            },
            successRedirectUrl: environment.appSchemeUrl,
        };
        const paymentResult = await firstValueFrom(this.payGQL.mutate(variable));
        if (!paymentResult.data?.purchasePublic.pay) {
            throw new Error('PaymentResult is empty');
        }
        return paymentResult.data?.purchasePublic.pay;
    }

    async waitForSaferpayPayment(paymentPageUrl: string | null | undefined): Promise<PaymentTransaction> {
        if (!paymentPageUrl) {
            return {
                status: PaymentTransactionStatus.Succeed,
                url: '',
            };
        }
        const paymentTransactionStatusFromBrowser = this.getPaymentTransactionStatusFromBrowser();
        const window = await this.openBrowserInApp(paymentPageUrl);

        if (this.platformService.isWeb()) {
            return {
                // Force status because we can't determine whether success|fail|abort in web.
                status: await PurchaseArticleService.getPaymentPageClosedEventForWebPlatform(
                    window as Window,
                    PaymentTransactionStatus.Succeed,
                ),
                url: paymentPageUrl,
            };
        }
        const paymentTransactionStatus = await paymentTransactionStatusFromBrowser;

        if (this.platformService.isIos()) {
            Browser.close().then(
                () => {},
                (err) => {
                    console.warn(err);
                },
            );
        }
        return {
            status: paymentTransactionStatus,
            url: paymentPageUrl,
        };
    }

    async buyArticles(cart: Cart): Promise<string> {
        if (isItineraryCart(cart)) {
            this.reportAnalyticsEventBuyArticleWithCreditCard(cart.totalPrice, 'ri');
            return this.itineraryPurchaseService.buyItineraryArticles(cart);
        }
        if (isDepartureCart(cart)) {
            this.reportAnalyticsEventBuyArticleWithCreditCard(cart.totalPrice, 'departure');
            return this.departureArticlePurchaseService.buyDepartureArticles(cart);
        }
        if (isQuickTicketCart(cart)) {
            this.reportAnalyticsEventBuyArticleWithCreditCard(cart.totalPrice, 'quick');
            return this.quickArticlePurchaseService.buyQuickArticles(cart);
        }
        throw new TechnicalError(`Cart type not supported`, ErrorCodes.Purchase.CartTypeNotSupported, undefined, {
            'cart.journeyViewModel.__type__': cart.journeyViewModel.__type__,
        });
    }

    async getOrderStatus(orderId: string): Promise<OrderStatus> {
        const variables = {
            orderId,
        };
        const { data } = await firstValueFrom(this.getOrderStatusGQL.fetch(variables));
        return data.orderPublic.getOrderStatus;
    }

    async tryCancelOrder(orderId: string, cancellationType = CancellationType.PaymentTimedOut): Promise<OrderStatusResult> {
        const variables = {
            orderId,
            cancellationType,
        };

        const { data } = await firstValueFrom(
            this.tryCancelOrderGql.mutate(variables).pipe(retry({ delay: TRY_CANCEL_ORDER_RETRY_INTERVAL_MS })),
        );
        return data?.orderPublic.tryCancelOrder;
    }

    async openBrowserInApp(url: string): Promise<Window | undefined | null | void> {
        if (this.platformService.isWeb()) {
            return window.open(url, 'Saferpay', 'menubar=no, status=no, scrollbars=no, width=600, height=1200');
        }
        await Browser.open({
            url,
            presentationStyle: 'popover',
        });
    }

    private static getPaymentStatusFrom(appState: { url: string }): PaymentTransactionStatus {
        if (appState.url.includes('success')) {
            return PaymentTransactionStatus.Succeed;
        }
        if (appState.url.includes('abort')) {
            return PaymentTransactionStatus.Aborted;
        }
        if (appState.url.includes('fail')) {
            return PaymentTransactionStatus.Failed;
        }
        return PaymentTransactionStatus.Aborted;
    }

    private static getPaymentPageClosedEventForWebPlatform(
        popupWindow: Window,
        status: PaymentTransactionStatus,
    ): Promise<PaymentTransactionStatus> {
        return new Promise((resolve) => {
            let timer: number;
            const checkBrowserClosedState = (): void => {
                if (popupWindow.closed) {
                    clearInterval(timer);
                    // hotfix because I couldn't listen for page url to determine whether success|fail|abort.
                    resolve(status);
                }
            };
            timer = window.setInterval(checkBrowserClosedState, 500);
        });
    }

    private async getPaymentTransactionStatusFromBrowser(): Promise<PaymentTransactionStatus> {
        const appUrlOpenEvent = this.extractPaymentStatusFromAppUrlOpenEvent();
        const appStateActive = this.listenAppStateActive();
        const browserFinishedEvent = this.listenBrowserFinishedEvent();

        const allEvents = merge(appUrlOpenEvent, appStateActive, browserFinishedEvent).pipe(take(1));

        return firstValueFrom(allEvents);
    }

    /**
     * extractPaymentStatusFromAppUrlOpenEvent: returns a promise (that will be resolved on the first
     * occurence of the event) and an handler to remove the listener.
     */
    private extractPaymentStatusFromAppUrlOpenEvent(): Observable<PaymentTransactionStatus> {
        return $getAppEventObservable<AppLaunchUrl>('appUrlOpen').pipe(
            take(1),
            map((data) => PurchaseArticleService.getPaymentStatusFrom(data)),
        );
    }

    // todo : duplicated code from app-listener-utils.ts, convert to a real observable then clean this

    /**
     * listenAppStateActive: returns a promise that will be resolved on the first occurrence of app becoming active.
     * The listener is automatically discarded after the first event occurrence.
     *
     * This method is mainly used to solve problem about some Android devices which not detects the 'browserFinished' event. Even
     * when every listeners are correctly subscribed. Probably because when Browser is opened, the app state change to "isActive = false",
     * then if the user manually close the browser, the app state change to "isActive = true" AFTER the event 'browserFinished'. So
     * no listeners on 'browserFinished' are handled.
     *
     * The delay is here to prevent a potential bug : When the user had bought his ticket or cancel the process, the browser will be
     * closed, is same time of a potential 'appUrlOpen'. But the event 'appUrlOpen' will retrieve the correct  payment transaction status.
     * So we want let the possibility to the application to intercept this event before the 'appStateChange'. To do that, we add a small
     * delay to this 'appStateChange' edge case to be sure to not have any conflicts in the order of events firing.
     *
     * There is a bug with FaceID, which fire 'isActive = false' then 'isActive = true' when the face recognition process is done.
     */
    private listenAppStateActive(): Observable<PaymentTransactionStatus> {
        if (this.platformService.isIos()) {
            return EMPTY;
        }
        return $getAppEventObservable<AppState>('appStateChange').pipe(
            filter(({ isActive }) => isActive),
            take(1),
            delay(100),
            map(() => PaymentTransactionStatus.Aborted),
        );
    }

    /**
     * listenBrowserOn: returns a promise that will resolved on event and a handle to discard the listener.
     */
    private listenBrowserFinishedEvent(): Observable<PaymentTransactionStatus> {
        return $getBrowserFinishedEvent().pipe(
            take(1),
            map(() => PaymentTransactionStatus.Aborted),
        );
    }

    private reportAnalyticsEventBuyArticleWithCreditCard(totalPrice: number, ticketType: string): void {
        this.analyticsService.reportEvent('buy_ticket_online', {
            ticket_price: totalPrice.toString(),
            ticket_type: ticketType,
        });
    }
}
