import { createReducer, on } from '@ngrx/store';
import * as ScrollActions from './scroll.actions';
import { JourneyListScrollModeEnum } from '@traas/boldor/all-models';

export interface ScrollState {
    mode: JourneyListScrollModeEnum;
}

export const initialState: ScrollState = {
    mode: JourneyListScrollModeEnum.Auto,
};

export const scrollReducer = createReducer(
    initialState,

    on(ScrollActions.setScrollMode, (state, { mode }) => ({ ...state, mode })),
);
