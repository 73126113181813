export enum DatepickerMode {
    FIXED = 'FIXED',
    AUTO = 'AUTO',
}

export interface DatepickerState {
    value: Date;
    mode: DatepickerMode;
}

export const initialState: DatepickerState = {
    value: new Date(),
    mode: DatepickerMode.AUTO,
};
