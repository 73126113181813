import { inject, Injectable } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { environment } from '@traas/boldor/environments';
import { HttpLink } from 'apollo-angular/http';

@Injectable()
export class ApolloClientOptionsBuilderService {
    private httpLink = inject(HttpLink);

    build(): ApolloClientOptions<any> {
        return {
            cache: new InMemoryCache(),
            defaultOptions: {
                mutate: { errorPolicy: 'none' },
                query: { errorPolicy: 'none', fetchPolicy: 'no-cache' },
            },
            link: ApolloLink.from([this.httpLink.create({ uri: `${environment.gatewayUrl}/graphql` })]),
        };
    }
}
