import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { getRealErrorData } from '@traas/common/logging';
import { ErrorHandler as CustomErrorHandler } from '@traas/boldor/all-models';
import { AuthenticationRequiredErrorHandler } from './handlers/authentication-required.error-handler';
import { FatalErrorHandler } from './handlers/fatal-error-handler.service';
import { NetworkErrorHandler } from './handlers/network.error-handler';

/*
Take care to understand the difference between:
- ErrorHandler from angular/core
- ErrorHandler built by openit.
*/
@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    private errorHandlers: CustomErrorHandler[];

    constructor(private injector: Injector) {
        super();
    }

    override async handleError(error: Error): Promise<void> {
        // inject dependencies
        this.errorHandlers = [
            this.injector.get(AuthenticationRequiredErrorHandler),
            this.injector.get(NetworkErrorHandler),
            this.injector.get(FatalErrorHandler),
        ];
        // initialize error handler
        const actualError = getRealErrorData(error);
        let errorWasHandled = false;
        // loop through different kind of handlers
        for (const handler of this.errorHandlers) {
            if (handler) {
                errorWasHandled = await handler.handle(actualError);
                if (errorWasHandled) {
                    break;
                }
            }
        }
    }
}
