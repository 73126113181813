export enum TicketState {
    AuthorizeError = 'AUTHORIZE_ERROR',
    AuthorizeSuccess = 'AUTHORIZE_SUCCESS',
    BoldorCreateSuccess = 'BOLDOR_CREATE_SUCCESS',
    CanceledInApp = 'CANCELED_IN_APP',
    ConfirmError = 'CONFIRM_ERROR',
    ConfirmRefundError = 'CONFIRM_REFUND_ERROR',
    ConfirmRefundSuccess = 'CONFIRM_REFUND_SUCCESS',
    ConfirmSuccess = 'CONFIRM_SUCCESS',
    DocumentUpdateError = 'DOCUMENT_UPDATE_ERROR',
    DocumentUpdateSuccess = 'DOCUMENT_UPDATE_SUCCESS',
    Initial = 'INITIAL',
    RefundAuthorizeError = 'REFUND_AUTHORIZE_ERROR',
    RefundAuthorizeSuccess = 'REFUND_AUTHORIZE_SUCCESS',
    RefundBoldorCreateSuccess = 'REFUND_BOLDOR_CREATE_SUCCESS',
    RefundCreateError = 'REFUND_CREATE_ERROR',
    RefundCreateSuccess = 'REFUND_CREATE_SUCCESS',
    RefundDocumentUpdateError = 'REFUND_DOCUMENT_UPDATE_ERROR',
    RefundDocumentUpdateSuccess = 'REFUND_DOCUMENT_UPDATE_SUCCESS',
    RefundError = 'REFUND_ERROR',
    RefundRecordError = 'REFUND_RECORD_ERROR',
    RefundRecordSuccess = 'REFUND_RECORD_SUCCESS',
    RefundSuccess = 'REFUND_SUCCESS',
    SdsCreateError = 'SDS_CREATE_ERROR',
    SdsCreateSuccess = 'SDS_CREATE_SUCCESS',
    SdsRecordError = 'SDS_RECORD_ERROR',
    SdsRecordSuccess = 'SDS_RECORD_SUCCESS',
    SeenByClient = 'SEEN_BY_CLIENT',
    Unknown = 'UNKNOWN',
}
