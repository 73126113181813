import { addYears, endOfDay, subMonths, subSeconds } from 'date-fns';

export const ONE_SECOND_IN_MS = 1000;
export const ONE_MINUTE_IN_SECONDS = 60;
export const NINETY_SECONDS = 90;
export const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * ONE_MINUTE_IN_SECONDS;
export const SIMPLE_DATE_FORMAT = 'yyyy-MM-dd';

export function subtractOneSecond(aDate: Date): Date {
    return subSeconds(aDate, 1);
}

export function isXYearsOld(date: string, xYears: number): boolean {
    return addYears(endOfDay(new Date(date)), xYears).getTime() < Date.now();
}

export function getOneMonthAgo(now: Date): Date {
    return subMonths(now, 1);
}
