<ion-list class="ticket">
    <ion-progress-bar type="indeterminate" *ngIf="$isLoading | async"></ion-progress-bar>
    <ng-container *ngIf="$orders | async as orders">
        <div class="ticket-group">
            <div
                class="ticket-divider"
                [class.open]="selectionHelper.isExpanded(accordionIndex.current)"
                (click)="selectionHelper.toggle(accordionIndex.current)"
                tappable
            >
                <ng-container *ngIf="orders.current.length as currentTicketsOrderCount; else noCurrentTicketOrder">
                    <div class="ticket-type">
                        <ion-icon class="ticket-icon" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
                        <ion-label class="ticket-label" *ngIf="currentTicketMapping">
                            {{ currentTicketsOrderCount | i18nPlural : currentTicketMapping }}
                        </ion-label>
                    </div>
                    <app-collapse-arrow [expanded]="selectionHelper.isExpanded(accordionIndex.current)"></app-collapse-arrow>
                </ng-container>
                <ng-template #noCurrentTicketOrder>
                    <div class="ticket-type">
                        <ion-icon class="ticket-icon" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
                        <ion-label class="ticket-label"> {{ 'my-journeys.no-current-journey' | translate }}</ion-label>
                    </div>
                </ng-template>
            </div>

            <div class="e2e-list-current-tickets" [class.hidden]="!selectionHelper.isExpanded(accordionIndex.current)">
                <div
                    (click)="onOrderClicked(order)"
                    *ngFor="let order of orders.current; trackBy: trackById"
                    class="tickets-order-item item"
                    detail="false"
                    lines="none"
                    tappable
                >
                    <app-itinerary-ticket-summary-item *ngIf="isItinerary(order)" [itineraryOrder]="order" [order]="order">
                    </app-itinerary-ticket-summary-item>
                    <app-departure-ticket-summary
                        *ngIf="isDeparture(order)"
                        [departureOrder]="order"
                        [order]="order"
                    ></app-departure-ticket-summary>
                    <app-quick-ticket-summary-item *ngIf="isQuick(order)" [order]="order" [quickTicket]="order">
                    </app-quick-ticket-summary-item>
                </div>
            </div>
        </div>

        <div class="ticket-group">
            <div
                class="ticket-divider"
                [class.open]="selectionHelper.isExpanded(accordionIndex.future)"
                (click)="selectionHelper.toggle(accordionIndex.future)"
                tappable
            >
                <ng-container *ngIf="orders.future.length as futureTicketsOrderCount; else noFutureTicketOrder">
                    <div class="ticket-type">
                        <ion-icon class="ticket-icon" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
                        <ion-label class="ticket-label" *ngIf="futureTicketMapping">
                            {{ futureTicketsOrderCount | i18nPlural : futureTicketMapping }}
                        </ion-label>
                    </div>
                    <app-collapse-arrow [expanded]="selectionHelper.isExpanded(accordionIndex.future)"></app-collapse-arrow>
                </ng-container>
                <ng-template #noFutureTicketOrder>
                    <div class="ticket-type">
                        <ion-icon class="ticket-icon" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
                        <ion-label class="ticket-label">{{ 'my-journeys.no-future-journey' | translate }}</ion-label>
                    </div>
                </ng-template>
            </div>
            <div [class.hidden]="!selectionHelper.isExpanded(accordionIndex.future)">
                <div
                    (click)="onOrderClicked(futureOrder)"
                    *ngFor="let futureOrder of orders.future; trackBy: trackById"
                    class="tickets-order-item item"
                    detail="false"
                    lines="none"
                    tappable
                >
                    <app-itinerary-ticket-summary-item
                        *ngIf="isItinerary(futureOrder)"
                        [itineraryOrder]="futureOrder"
                        [order]="futureOrder"
                    >
                    </app-itinerary-ticket-summary-item>
                    <app-departure-ticket-summary
                        *ngIf="isDeparture(futureOrder)"
                        [departureOrder]="futureOrder"
                        [order]="futureOrder"
                    ></app-departure-ticket-summary>
                    <app-quick-ticket-summary-item *ngIf="isQuick(futureOrder)" [order]="futureOrder" [quickTicket]="futureOrder">
                    </app-quick-ticket-summary-item>
                </div>
            </div>
        </div>

        <div class="ticket-group">
            <div
                class="ticket-divider e2e-list-old-travels"
                [class.open]="selectionHelper.isExpanded(accordionIndex.past)"
                (click)="selectionHelper.toggle(accordionIndex.past)"
                tappable
            >
                <ng-container *ngIf="orders.past.length as pastTicketsOrderCount; else noPastTicketOrder">
                    <div class="ticket-type">
                        <ion-icon class="ticket-icon" src="assets/icons/history.svg"></ion-icon>
                        <ion-label class="ticket-label" *ngIf="pastTicketMapping">
                            {{ pastTicketsOrderCount | i18nPlural : pastTicketMapping }}
                        </ion-label>
                    </div>
                    <app-collapse-arrow [expanded]="selectionHelper.isExpanded(accordionIndex.past)"></app-collapse-arrow>
                </ng-container>
                <ng-template #noPastTicketOrder>
                    <div class="ticket-type">
                        <ion-icon class="ticket-icon" src="assets/icons/history.svg"></ion-icon>
                        <ion-label class="ticket-label">{{ 'my-journeys.no-passed-journey' | translate }}</ion-label>
                    </div>
                </ng-template>
            </div>
            <div class="e2e-list-old-tickets" [class.hidden]="!selectionHelper.isExpanded(accordionIndex.past)">
                <div
                    *ngFor="let pastOrder of orders.past; trackBy: trackById"
                    (click)="onOrderClicked(pastOrder)"
                    class="tickets-order-item item"
                    detail="false"
                    lines="none"
                    tappable
                >
                    <app-itinerary-ticket-summary-item *ngIf="isItinerary(pastOrder)" [itineraryOrder]="pastOrder" [order]="pastOrder">
                    </app-itinerary-ticket-summary-item>
                    <app-departure-ticket-summary
                        *ngIf="isDeparture(pastOrder)"
                        [departureOrder]="pastOrder"
                        [order]="pastOrder"
                    ></app-departure-ticket-summary>
                    <app-quick-ticket-summary-item *ngIf="isQuick(pastOrder)" [order]="pastOrder" [quickTicket]="pastOrder">
                    </app-quick-ticket-summary-item>
                </div>
            </div>
        </div>

        <div class="ticket-group" *ngIf="isTraas">
            <div
                class="ticket-divider"
                [class.open]="selectionHelper.isExpanded(accordionIndex.cancelled)"
                (click)="selectionHelper.toggle(accordionIndex.cancelled)"
                tappable
            >
                <ng-container *ngIf="orders.cancelled.length as cancelledTicketsOrderCount; else noCancelledTicketOrder">
                    <div class="ticket-type">
                        <ion-icon class="ticket-icon" src="assets/icons/cancelled_journey.svg"></ion-icon>
                        <ion-label class="ticket-label" *ngIf="cancelledTicketMapping">
                            {{ cancelledTicketsOrderCount | i18nPlural : cancelledTicketMapping }}
                        </ion-label>
                    </div>
                    <app-collapse-arrow [expanded]="selectionHelper.isExpanded(accordionIndex.cancelled)"></app-collapse-arrow>
                </ng-container>
                <ng-template #noCancelledTicketOrder>
                    <div class="ticket-type">
                        <ion-icon class="ticket-icon" src="assets/icons/cancelled_journey.svg"></ion-icon>
                        <ion-label class="ticket-label">{{ 'my-journeys.no-canceled-journey' | translate }}</ion-label>
                    </div>
                </ng-template>
            </div>
            <div class="e2e-list-old-tickets" [class.hidden]="!selectionHelper.isExpanded(accordionIndex.cancelled)">
                <div
                    *ngFor="let cancelledOrder of orders.cancelled; trackBy: trackById"
                    (click)="onOrderClicked(cancelledOrder)"
                    class="tickets-order-item item"
                    detail="false"
                    lines="none"
                    tappable
                >
                    <app-itinerary-ticket-summary-item
                        *ngIf="isItinerary(cancelledOrder)"
                        [itineraryOrder]="cancelledOrder"
                        [order]="cancelledOrder"
                    >
                    </app-itinerary-ticket-summary-item>
                    <app-departure-ticket-summary
                        *ngIf="isDeparture(cancelledOrder)"
                        [departureOrder]="cancelledOrder"
                        [order]="cancelledOrder"
                    ></app-departure-ticket-summary>
                    <app-quick-ticket-summary-item *ngIf="isQuick(cancelledOrder)" [order]="cancelledOrder" [quickTicket]="cancelledOrder">
                    </app-quick-ticket-summary-item>
                </div>
            </div>
        </div>
    </ng-container>
</ion-list>
