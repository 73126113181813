import { BrowserTracing, configureScope, Integrations, makeBrowserOfflineTransport, makeFetchTransport } from '@sentry/angular-ivy';
import * as Sentry from '@sentry/capacitor';
import { Env } from '@traas/boldor/environments';
import { SentryBreadcrumbsUtils } from './sentry-breadcrumbs.utils';
import { Device } from '@capacitor/device';
import { CurrentUserSession } from './current-user-session.service';

export const sentryInit = (environment: Env): void => {
    const tracesSampleRate = !environment.traceSentry ? undefined : environment.isDebugMode ? 1 : 1 / 100;
    const dropBreadcrumb = (): null => null;

    Sentry.init({
        maxValueLength: 1000,
        dsn: environment.sentryDsn,
        release: environment.sentryRelease,
        environment: environment.environment,
        dist: environment.currentCommitHash,
        /*
        Explanation about integrations
        XHR request errors are sent by sentry by defaut with TryCatch integration.
        Since boldor-error-dispatcher service manage sending errors to sentry (catch exceptions caught outside of angular), and
        we want to filter out network errors (normal cases for mobile), we deactivate TryCatch integration.

        More info here: https://moviplus.atlassian.net/wiki/spaces/BDOR/pages/278593709/Sentry
         */
        integrations: (integrations) =>
            integrations
                .filter((i) => i.name !== 'TryCatch')
                .map((i) => (i.name === 'Breadcrumbs' ? new Integrations.Breadcrumbs({ console: false, sentry: false }) : i))
                .concat(environment.traceSentry ? [new BrowserTracing()] : []), // https://docs.sentry.io/platforms/javascript/configuration/integrations/browsertracing/
        tracesSampleRate,
        transport: makeBrowserOfflineTransport(makeFetchTransport),
        beforeBreadcrumb(breadcrumb, hint) {
            if (SentryBreadcrumbsUtils.isSvgAssetsLoadingBreadcrumb(breadcrumb)) {
                return dropBreadcrumb();
            }

            if (SentryBreadcrumbsUtils.isGraphqlBreadcrumb(breadcrumb) && hint) {
                SentryBreadcrumbsUtils.setGraphQLDataOnBreadcrumb(breadcrumb, hint);
            }

            return breadcrumb;
        },
    });

    Sentry.addGlobalEventProcessor(function (event, _) {
        if (!event.exception || !event.exception.values?.length) {
            return event;
        }
        event.exception.values.forEach((exception) => {
            if (exception.mechanism && exception.type === 'FatalError') {
                exception.mechanism.handled = false;
            }
        });
        return event;
    });

    configureScope((scope) => {
        scope.setTags({
            gatewayUrl: environment.gatewayUrl,
            clientVersion: environment.clientVersion,
            company: environment.company,
        });
        Device.getId().then((deviceId) => {
            scope.setTag('device.id', deviceId.uuid);
        });
        CurrentUserSession.onActiveUserChanged.subscribe((user) => {
            console.log('[Sentry] user changed:', user);
            if (!user) {
                scope.setUser({
                    id: undefined,
                });
                return;
            }
            scope.setUser({
                id: user.id,
            });
        });
    });
};
