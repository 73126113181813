<!--https://git.moviplus.ch/bol-dor/app_client/merge_requests/349#note_49862-->
<ion-header>
    <app-page-title
        iconUrl="assets/icons/tabs/monochrome/mainmenu.svg"
        [title]="'Menu'"
        (click)="clickMenuToShowDebugInfo()"
    ></app-page-title>
</ion-header>
<ion-content>
    <div class="menu items">
        <traas-travys-menu></traas-travys-menu>
        <ion-row>
            <ion-col class="ion-text-center">
                <app-version-number></app-version-number>
            </ion-col>
        </ion-row>
    </div>
</ion-content>
<ion-footer>
    <ng-container *ngIf="showDebugInfo()">
        <debug-info-display></debug-info-display>
    </ng-container>
</ion-footer>
