import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CollapseArrowComponent } from './collapse-arrow.component';

@NgModule({
    declarations: [CollapseArrowComponent],
    imports: [CommonModule, IonicModule],
    exports: [CollapseArrowComponent],
})
export class CollapseArrowModule {}
