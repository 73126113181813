import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SymbolSvgComponent } from './symbol-svg.component';

@NgModule({
    imports: [CommonModule],
    declarations: [SymbolSvgComponent],
    exports: [SymbolSvgComponent],
})
export class SymbolSvgModule {}
