import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TicketEffect } from './ticket.effect';
import { ticketReducer } from './ticket.reducer';

@NgModule({
    declarations: [],
    imports: [StoreModule.forFeature('ticket-articles', ticketReducer), EffectsModule.forFeature([TicketEffect])],
    providers: [TicketEffect],
})
export class TicketStoreModule {}
