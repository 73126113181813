<ng-container *ngIf="$hasValidDepartures | async; else hasInvalidDepartures">
    <ng-container *ngIf="departures && departures.length > 0; else noDepartures">
        <traas-placeholder-list-item
            class="top-placeholder"
            *ngIf="currentDepartureDatePlaceholder"
            (click)="$event.preventDefault()"
            [placehoder]="currentDepartureDatePlaceholder"
        ></traas-placeholder-list-item>
        <traas-infinite-scroll-list
            (scrollManually)="scrollManually.emit()"
            [scrollMode]="scrollMode"
            [itemHeightInPx]="departureItemHeightInPx"
            [itemTemplate]="itemTmpl"
            (requestMore)="onRequestMoreDepartures($event)"
            (requestPrevious)="onRequestPreviousDepartures($event)"
            [journeys]="departuresAdapter"
            (newItemsInViewport)="onNewItemsInViewport($event)"
        >
            <traas-departure-list-item-skeleton
                class="top-skeleton"
                [class.has-arrow]="isTPC"
                skeleton-start
                *ngIf="departures.length > nbOfDepartureToShow"
                [hasArrow]="isTPC"
                [noMargin]="isTPG"
            ></traas-departure-list-item-skeleton>
            <traas-departure-list-item-skeleton
                class="bottom-skeleton"
                [class.has-arrow]="isTPC"
                skeleton-end
                *ngIf="departures.length > nbOfDepartureToShow"
                [hasArrow]="isTPC"
                [noMargin]="isTPG"
            ></traas-departure-list-item-skeleton>
        </traas-infinite-scroll-list>
    </ng-container>
</ng-container>

<ng-template #itemTmpl let-departure>
    <ng-container *ngIf="!departure.isPlaceholder; else isPlaceholder">
        <departure-list-item
            *ngIf="$timeDisplayMode | async as timeDisplayMode"
            [thresholds]="thresholds"
            [timeDisplayMode]="timeDisplayMode"
            (click)="onDepartureItemClicked(departure)"
            (outdated)="onOutdatedData()"
            [departure]="departure"
            tappable
        >
        </departure-list-item>
    </ng-container>
    <ng-template #isPlaceholder>
        <traas-placeholder-list-item (click)="$event.preventDefault()" [placehoder]="departure"></traas-placeholder-list-item>
    </ng-template>
</ng-template>

<ng-template #hasInvalidDepartures>
    <ion-row>
        <ion-col class="ion-text-center">
            <h3 class="no-departure">{{ 'departures.invalid-response' | translate }}</h3>
        </ion-col>
    </ion-row>
</ng-template>

<ng-template #noDepartures>
    <ion-row>
        <ion-col class="ion-text-center">
            <h3 class="no-departure">{{ 'departures.empty-result' | translate }}</h3>
        </ion-col>
    </ion-row>
</ng-template>
