import { Injectable } from '@angular/core';
import { Endpoint, SearchModeOptions } from '@traas/boldor/all-models';
import { EndpointActions } from '../../home/store/endpoint';
import { EndpointState } from '../../home/store/endpoint/endpoint.state';
import { HomeState } from '../../home/store/home.state';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../services/common/modal.service';
import { RouteUrl, RoutingService } from '@traas/common/routing';
import { GridStoreActions } from '../../../components/grid/store';
import { Browser } from '@capacitor/browser';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    constructor(
        protected modalService: ModalService,
        protected routingService: RoutingService,
        protected homeStateStore: Store<HomeState>,
        protected endpointStore: Store<EndpointState>,
    ) {}

    async openWebPageInBrowser(path: string, target: '_blank' | '_self' = '_blank'): Promise<void> {
        await Browser.open({ url: path, windowName: target });
    }

    async navigateToHome(): Promise<boolean> {
        this.switchSearchModeOption(SearchModeOptions.MAP);
        return this.routingService.navigateToHome();
    }

    async navigateToItinerary(): Promise<boolean> {
        this.switchSearchModeOption(SearchModeOptions.MAP);
        this.endpointStore.dispatch(new EndpointActions.ShowArrival());
        this.endpointStore.dispatch(new EndpointActions.StartChangingDeparture());
        this.endpointStore.dispatch(new EndpointActions.SetActiveEndpoint(Endpoint.Departure));
        return this.routingService.navigateTo(RouteUrl.itineraryResultUrl);
    }

    async navigateToFavorites(): Promise<boolean> {
        this.switchSearchModeOption(SearchModeOptions.GRID);
        return this.routingService.navigateToHome();
    }

    protected switchSearchModeOption(mode: SearchModeOptions): void {
        this.homeStateStore.dispatch(new GridStoreActions.ChangeSearchMode({ mode }));
    }
}
