<ion-row>
    <div class="itinerary-leg">
        <div class="leg-container">
            <div class="leg-duration">
                {{ delayOfLeg }}
            </div>
            <div class="walking-line" [ngClass]="isFirstLeg ? 'first-leg' : isLastLeg ? 'last-leg' : 'intermediate-leg'"></div>
        </div>
        <div class="walk-container">
            <div class="walk-icon">
                <ion-icon src="assets/icons/walk.svg"></ion-icon>
            </div>
            <div class="walk-message">
                {{ 'home.walk-leg-distance' | translate : { distance: distance } }}
            </div>
        </div>
    </div>
</ion-row>
