import { Injectable } from '@angular/core';
import { ArticlesBundle, Currency, QuickArticleViewModel } from '@traas/boldor/all-models';
import { ArticleCategoryService, TicketCategory } from './article-category.service';

@Injectable({
    providedIn: 'root',
})
export class ArticleCategoryTpcService extends ArticleCategoryService<any> {
    async getCategories(): Promise<TicketCategory[]> {
        return [];
    }

    async getTicketsUsingCategory(
        articlesBundle: ArticlesBundle<QuickArticleViewModel>,
        categoryId: string,
        currency?: Currency,
    ): Promise<any[]> {
        return [];
    }
}
