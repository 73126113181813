/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { Departure, LeaveOrArriveEnum, LineFilter } from '@traas/boldor/all-models';
import { Placeholder } from '../../../components/placeholder-list-item/placeholder.model';

export enum DepartureActionTypes {
    Load = '[Departure] Load data...', // endless scroll call Load directly
    LoadedMore = '[Departure] Loaded more',
    LoadedPrevious = '[Departure] Loaded previous',
    LoadFail = '[Departure] Load fail ...',
    Loading = '[Departure] Loading...', // Loading http request is triggered
    Reload = '[Departure] Reload', // when departuree is changed

    OpenDetails = '[Departure] Open details of departure',
    ClearDepartures = '[Departure] Clear departure state',
    SetDeparturesInScrollViewport = '[Departure] Set departures in scroll viewport',
    SetActiveLineFilters = '[Departure] Set active lines filter',
    SetLineFilters = '[Departure] Set lines filter',
    SetSelectedDeparture = '[Departure] Select a selected departure',
    StartRefreshLinesOfDeparture = '[Departure] Start refreshing lines of departure',
    StopRefreshLinesOfDeparture = '[Departure] Stop refreshing lines of departure',

    SetCommercialStopIdsTarget = '[Departure] Set physical stops array used to get departures',
}

export interface LoadPayload {
    commercialStopIds: string[];
    date: Date;
    departuresCount: number;
    way: LeaveOrArriveEnum;
    lineFilters?: LineFilter[];
}

export class Load implements Action {
    readonly type = DepartureActionTypes.Load;

    constructor(public payload: LoadPayload) {}
}

export class Loading implements Action {
    readonly type = DepartureActionTypes.Loading;

    constructor(public payload: LeaveOrArriveEnum) {}
}

export class Reload implements Action {
    readonly type = DepartureActionTypes.Reload;

    constructor(public payload: LoadPayload) {}
}

export class LoadedMore implements Action {
    readonly type = DepartureActionTypes.LoadedMore;

    constructor(public payload: { departures: Departure[]; nbExpected: number }) {}
}

export class LoadedPrevious implements Action {
    readonly type = DepartureActionTypes.LoadedPrevious;

    constructor(public payload: { departures: Departure[]; nbExpected: number }) {}
}

export class LoadFail implements Action {
    readonly type = DepartureActionTypes.LoadFail;

    constructor(public payload?: { message: string; showMessage: boolean }) {}
}

export class SetDeparturesInScrollViewport implements Action {
    readonly type = DepartureActionTypes.SetDeparturesInScrollViewport;

    constructor(public payload: { departures: (Departure | Placeholder)[] }) {}
}

export class OpenDetails implements Action {
    readonly type = DepartureActionTypes.OpenDetails;

    constructor(public payload: Departure) {}
}

export class SetSelectedDeparture implements Action {
    readonly type = DepartureActionTypes.SetSelectedDeparture;

    constructor(public payload: Departure) {}
}

export class SetLineFilters implements Action {
    readonly type = DepartureActionTypes.SetLineFilters;

    constructor(public payload: LineFilter[]) {}
}

export class SetActiveLineFilters implements Action {
    readonly type = DepartureActionTypes.SetActiveLineFilters;

    constructor(public payload: LineFilter[]) {}
}

export class StartRefreshLinesOfDeparture implements Action {
    readonly type = DepartureActionTypes.StartRefreshLinesOfDeparture;
}

export class StopRefreshLinesOfDeparture implements Action {
    readonly type = DepartureActionTypes.StopRefreshLinesOfDeparture;
}

export class SetCommercialStopIdsTarget implements Action {
    readonly type = DepartureActionTypes.SetCommercialStopIdsTarget;

    constructor(public commercialStopIds: string[]) {}
}

export class ClearDepartures implements Action {
    readonly type = DepartureActionTypes.ClearDepartures;

    constructor() {}
}

export type DepartureActions =
    | Load
    | Loading
    | LoadedMore
    | LoadedPrevious
    | Reload
    | SetDeparturesInScrollViewport
    | LoadFail
    | OpenDetails
    | SetSelectedDeparture
    | StartRefreshLinesOfDeparture
    | StopRefreshLinesOfDeparture
    | SetLineFilters
    | SetActiveLineFilters
    | SetCommercialStopIdsTarget
    | ClearDepartures;
