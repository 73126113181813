import { ComponentRef, Injectable } from '@angular/core';
import { AssociatedCommercialStopAdapter, CssClass, Endpoint, FeatureProperties, PhysicalStopAdapter } from '@traas/boldor/all-models';
import {
    buildGenericLayer,
    buildPhysicalStopsLayerFrom,
    buildTracksLayerFromPhysicalStops,
    CustomMarker,
    defaultLocalisationIcon,
    DYNAMIC_PLACE_LAYER,
    dynamicLayerName,
    dynamicLayerNameFactory,
    TrackHelper,
} from '@traas/common/utils';
import { EdgeMarker } from '../helper/edge-marker/edge-marker';
import { getEdgeMarkerPoint } from '../helper/edge-marker/edge-marker-point';
import { ComponentFactoryService } from './component-factory.service';
import { CompanyService } from '../../../services/common/company/company.service';
import { LineIconComponent } from '../../../components/line-icon/line-icon.component';
import { StopNamePipe } from '../../../pipes/stop-name.pipe';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import * as L from 'leaflet';
import {
    Bounds,
    DivIcon,
    divIcon,
    DivIconOptions,
    FeatureGroup,
    featureGroup,
    GeoJSON,
    latLng,
    LatLng,
    Layer,
    LayerGroup,
    Marker,
    Point,
} from 'leaflet';
import 'leaflet.markercluster';
import { uniqBy } from 'lodash';
import { GREY, ICON_BASE_PATH } from '../../../business-rules.utils';
import { PhysicalStop } from '@traas/common/models';

@Injectable({ providedIn: 'root' })
export class LayerBuilderService {
    private readonly markerClusterGroupOptions: L.MarkerClusterGroupOptions = {
        animate: false,
        removeOutsideVisibleBounds: true,
        disableClusteringAtZoom: 13,
    };

    private readonly markerClusterGroupItineraryOptions: L.MarkerClusterGroupOptions = {
        ...this.markerClusterGroupOptions,
        disableClusteringAtZoom: 1,
    };

    constructor(readonly componentFactoryService: ComponentFactoryService) {}

    getDynamicLayerNameByEndpoint(endpoint: Endpoint): dynamicLayerName {
        return dynamicLayerNameFactory(DYNAMIC_PLACE_LAYER, endpoint);
    }

    buildStopsLayerFrom(stops: PhysicalStop[]): LayerGroup {
        const ICON_SIZE_PX = 10;
        return buildPhysicalStopsLayerFrom(
            stops.map((stop) => stop),
            [ICON_SIZE_PX, ICON_SIZE_PX],
            [-ICON_SIZE_PX / 2, -ICON_SIZE_PX / 2],
            this.markerClusterGroupItineraryOptions,
            CompanyService.getClassNameForCurrentCompany(),
        );
    }

    buildCommercialStopsLayerFrom(stops: PhysicalStopAdapter[]): FeatureGroup {
        const markers = this.getStopsWithValidAssociatedCommercialStop(stops)
            .map(this.getCommercialStopMarker, this)
            .filter((marker) => !!marker);
        return buildGenericLayer<L.MarkerClusterGroup>(L.markerClusterGroup(this.markerClusterGroupOptions), markers);
    }

    buildSmallCommercialStopsLayerFrom(stops: PhysicalStopAdapter[]): FeatureGroup {
        const markers = this.getStopsWithValidAssociatedCommercialStop(stops)
            .map(this.getSmallCommercialStopMarker, this)
            .filter((marker) => !!marker);
        return buildGenericLayer<L.MarkerClusterGroup>(L.markerClusterGroup(this.markerClusterGroupOptions), markers);
    }

    getLocalisationMarkerFrom(latLng: LatLng, iconOptions: DivIconOptions = {}, iconHtml?: string): L.Marker {
        return L.marker(latLng, {
            title: '',
            icon: this.getLocalisationDivIcon(iconOptions, iconHtml),
            riseOnHover: false,
        });
    }

    buildTracksLayerFromPhysicalStops(stops: PhysicalStop[]): LayerGroup {
        const iconSize: [number, number] = [12, 10],
            iconAnchor: [number, number] = [1.5, 45],
            popupAnchor: [number, number] = [-10 / 2, -10 / 2];
        const defaultPlaceByCompany = CompanyService.getDefaultPlaceByCompany();
        const defaultLatLng = latLng(defaultPlaceByCompany.latitude, defaultPlaceByCompany.longitude);
        return buildTracksLayerFromPhysicalStops(stops, {
            iconSize, // instead of 10 in order to overstep the dot layer
            iconAnchor, // to fit with the actual stop that is also drawn behind this layer DivIcon
            popupAnchor,
            markerClusterGroupOptions: { ...this.markerClusterGroupOptions },
            classByCompany: CompanyService.getClassNameForCurrentCompany(),
            defaultPlaceForStop: defaultLatLng,
            currentCompany: CompanyService.getCurrentCompany(),
            useSvgIcon: false,
        });
    }

    buildLayerFromMarkers(markers: Marker[]): FeatureGroup {
        return buildGenericLayer<FeatureGroup>(featureGroup(), markers);
    }

    buildEdgeMarkerLayer(layers: Layer[], map: L.Map, mapSafeZone: Bounds): FeatureGroup {
        const markers = this.buildEdgeMarkers(layers as CustomMarker[], map, mapSafeZone);
        return this.buildLayerFromMarkers(markers);
    }

    buildItineraryPathLayerFrom(path: FeatureCollection): GeoJSON {
        return new GeoJSON(path, {
            filter: (feature): boolean => {
                return !this.isMidpoint(feature) && !this.isFootpath(feature) && !this.isBreadCrumb(feature);
            },
            style: (feature) => ({
                color: feature?.properties.line.color || GREY,
            }),
        });
    }

    buildItineraryStopPointsLayerFrom(path: FeatureCollection): GeoJSON {
        return new GeoJSON(path, {
            filter: (feature): boolean => {
                return this.isMidpoint(feature) && !this.isFootpath(feature) && !this.isBreadCrumb(feature);
            },
            pointToLayer: (feature, latlng): Marker<unknown> => {
                return this.buildMidpointMarker(feature, latlng);
            },
        });
    }

    buildItineraryFootpath(path: FeatureCollection): GeoJSON {
        return new GeoJSON(path, {
            filter: (feature): boolean => {
                return this.isFootpath(feature) && !this.isBreadCrumb(feature) && !this.isMidpoint(feature);
            },
            style: (feature) => ({
                color: feature?.properties.line.color || GREY,
                dashArray: [15, 15],
            }),
        });
    }

    buildItineraryFootpathMarker(path: FeatureCollection): GeoJSON {
        return new GeoJSON(path, {
            filter: (feature): boolean => {
                return this.isMidpoint(feature) && this.isFootpath(feature) && !this.isBreadCrumb(feature);
            },
            pointToLayer: (feature, latlng): Marker<unknown> => {
                return this.buildFootpathMarker(latlng);
            },
        });
    }

    private buildFootpathMarker(latlng: LatLng): Marker<unknown> {
        const icon = new L.Icon({
            iconUrl: `/${ICON_BASE_PATH}/walk.svg`,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
        });

        return new L.Marker(latlng, { icon });
    }

    private isMidpoint(feature: Feature<Geometry, FeatureProperties>): boolean {
        return this.containsCssClass(feature, 'midpoint');
    }

    private isFootpath(feature: Feature<Geometry, FeatureProperties>): boolean {
        return this.containsCssClass(feature, 'footpath');
    }

    private isBreadCrumb(feature: Feature<Geometry, FeatureProperties>): boolean {
        return this.containsCssClass(feature, 'breadcrumb');
    }

    private containsCssClass(feature: Feature<Geometry, FeatureProperties>, className: CssClass): boolean {
        if (feature.properties.cssClasses) {
            return feature.properties.cssClasses.includes(className);
        }
        return false;
    }

    private getSmallCommercialStopMarker(stop: PhysicalStopAdapter): Marker | undefined {
        const commercialStop = stop.getAssociatedCommercialStop();
        if (!commercialStop) {
            return undefined;
        }
        const latLng = commercialStop.getLatLng();
        return new Marker(latLng, { icon: this.getSmallStopDivIcon() });
    }

    private getCommercialStopMarker(stop: PhysicalStopAdapter): Marker {
        const commercialStop = stop.getAssociatedCommercialStop();
        if (!commercialStop) {
            return undefined;
        }
        const icon = this.getStopDivIconFromCommercialStop(commercialStop);
        const latLng = commercialStop.getLatLng();
        return new Marker(latLng, { icon });
    }

    private getStopsWithValidAssociatedCommercialStop(stops: PhysicalStopAdapter[]): PhysicalStopAdapter[] {
        const physicalStopsWithAssociatedCommercialStop = stops.filter((stop) => stop.getAssociatedCommercialStop());
        return uniqBy(physicalStopsWithAssociatedCommercialStop, (stop) => {
            return stop.getAssociatedCommercialStop()?.getId() ?? '';
        });
    }

    private buildEdgeMarkers(markers: CustomMarker[], map: L.Map, mapSafeZone: Bounds): Marker[] {
        return markers.map((marker) => {
            const currentMarkerPosition = map.latLngToContainerPoint(marker.getLatLng());
            const {
                side,
                angle,
                point: [x, y],
            } = getEdgeMarkerPoint(mapSafeZone, currentMarkerPosition);
            const pointAsLatLng: LatLng = map.containerPointToLatLng(new Point(x, y));
            return this.getEdgeMarkerFrom(marker, pointAsLatLng, angle, side);
        });
    }

    private getStopDivIconFromCommercialStop(stop: AssociatedCommercialStopAdapter): DivIcon {
        const companyClassName = CompanyService.getClassNameForCurrentCompany();
        const ICON_SIZE_PX = 10;
        return divIcon({
            html: `
                <div class="stop-marker-circle ${companyClassName}">
                    <div class="stop-name" data-after-content="${StopNamePipe.transformValue(stop)}"></div>
                </div>
            `,
            className: `ni-cluster`,
            iconSize: [ICON_SIZE_PX, ICON_SIZE_PX],
            popupAnchor: [-ICON_SIZE_PX / 2, -ICON_SIZE_PX / 2],
        });
    }

    private getLocalisationDivIcon(iconOptions: DivIconOptions = {}, iconHtml: string = defaultLocalisationIcon): L.DivIcon {
        return L.divIcon({
            className: 'user-pos-marker',
            html: iconHtml,
            iconSize: [24, 27],
            iconAnchor: [-1.5, 22],
            ...iconOptions,
        });
    }

    private getSmallStopDivIcon(): DivIcon {
        const companyClassName = CompanyService.getClassNameForCurrentCompany();
        const ICON_SIZE_PX = 10;
        return divIcon({
            html: `
                <div class="stop-marker-circle ${companyClassName}" style="height: 5px; width: 5px;"></div>
            `,
            className: `ni-cluster`,
            iconSize: [ICON_SIZE_PX, ICON_SIZE_PX],
            popupAnchor: [-ICON_SIZE_PX / 2, -ICON_SIZE_PX / 2],
        });
    }

    // Adjustment in order to make the edgeMarkers look good for each square side
    private getCssFromSide(side: string): string {
        switch (side) {
            case 'top':
                return 'top: -23px; left: -8px;';
            case 'bottom':
                return 'top: 21px; left: -8px;';
            case 'right':
                return 'top: -4px; left: 15px;';
            case 'left':
                return 'top: -2px; right: 17px;';
            default:
                return '';
        }
    }

    private getEdgeDivIconFromPhysicalStops({ options: { letter } }: CustomMarker, angle: number, side: string): DivIcon {
        const isDoubleDigitTrack = TrackHelper.isDoubleDigitTrack(letter);
        const trackType = TrackHelper.getTrackType(letter);

        // Specific for warning type of EdgeDivIcon
        const isDoubleDigitClass = isDoubleDigitTrack ? 'is-double-digit-track' : '';

        const html = ` <div class="stop-marker-square sbb-track ${isDoubleDigitClass}">
                            <span class="track">${trackType}</span>
                            <span class="number">${letter ?? ''}</span>
                       </div>
                       <div style="
                                   position: fixed;
                                   ${this.getCssFromSide(side)}
                                   transform: rotate(${`${+angle + 180}deg`});
                                  "
                            class="edge-marker-arrow">
                       </div>
                      `;

        return divIcon({
            html,
            className: `ni-cluster`,
            iconSize: [12, 10], // 12 instead of 10 in order to overstep the dot layer
            popupAnchor: [-10 / 2, -10 / 2],
        });
    }

    private getEdgeMarkerFrom(marker: CustomMarker, latLng: LatLng, angle: number, side: string): EdgeMarker {
        const icon = this.getEdgeDivIconFromPhysicalStops(marker, angle, side);
        const zIndexOffset = 500;
        const edgeMarker = new EdgeMarker(latLng, { icon, zIndexOffset });
        edgeMarker.setOriginalMarker(marker);
        return edgeMarker;
    }

    private buildMidpointMarker(feature: Feature<Geometry, FeatureProperties>, latlng: LatLng): Marker<unknown> {
        const component = this.componentFactoryService.createComponent(LineIconComponent);
        if (feature.properties.line) {
            component.instance.line = feature.properties.line;
        }
        component.instance.themeCssClass = 'tpg'; // TODO: ask for this weird hard coded value here
        this.addCenterStyle(component);

        component.changeDetectorRef.detectChanges();

        const icon = new L.DivIcon({
            html: component.location.nativeElement,
            className: feature.properties.cssClasses?.join(' '),
        });

        return new L.Marker(latlng, { icon });
    }

    private addCenterStyle(component: ComponentRef<LineIconComponent>): void {
        const centerCssStyle = {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
        };

        // Apply the center style on the component
        Object.assign(component.location.nativeElement.style, centerCssStyle);
    }
}
