import { Bookmark, ChangePlaceEventSourceEnum } from '@traas/boldor/all-models';

export const TRAVYS_DEFAULT_BOOKMARKS: Bookmark[] = [
    {
        boundsRect: {
            southWest: {
                lat: 46.780441689634564,
                lng: 6.639254898308031,
            },
            northEast: {
                lat: 46.782083523945,
                lng: 6.641490488345802,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Yverdon-les-bains',
            longitude: null,
            latitude: null,
        },
        stops: {
            didok: [8504774, 8504200],
            id: ['1970505225607136'],
        },
    },
    {
        boundsRect: {
            southWest: {
                lat: 46.779928966080114,
                lng: 6.636018678103973,
            },
            northEast: {
                lat: 46.781374081919566,
                lng: 6.637986385737845,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Yverdon-les-Bains, Bel-Air',
            longitude: null,
            latitude: null,
        },
        stops: {
            didok: [8579294],
            id: ['1970518110562202'],
        },
    },
    {
        boundsRect: {
            southWest: {
                lat: 46.77968995594859,
                lng: 6.667055800708007,
            },
            northEast: {
                lat: 46.78113507184204,
                lng: 6.6690234996803675,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Cheseaux-N., gymnase d’Yverdon',
            longitude: null,
            latitude: null,
        },
        stops: {
            didok: [8504458],
            id: ['1970518110562196'],
        },
    },
    {
        boundsRect: {
            southWest: {
                lat: 46.81870353578746,
                lng: 6.499655435662836,
            },
            northEast: {
                lat: 46.82023713430837,
                lng: 6.501745130772387,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Ste-Croix',
            longitude: null,
            latitude: null,
        },
        stops: {
            didok: [8571788, 8504298],
            id: ['1970509520572027'],
        },
    },
    {
        boundsRect: {
            southWest: {
                lat: 46.72349673106408,
                lng: 6.529023771106291,
            },
            northEast: {
                lat: 46.72514543683565,
                lng: 6.531266346939526,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Orbe',
            longitude: null,
            latitude: null,
        },
        stops: {
            didok: [8501267, 8581116, 8501191],
            id: ['1970518110562315', '1970518110562277'],
        },
    },
    {
        boundsRect: {
            southWest: {
                lat: 46.60497758325749,
                lng: 6.230292543179768,
            },
            northEast: {
                lat: 46.60661980745351,
                lng: 6.232521410657471,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Le Sentier',
            longitude: null,
            latitude: null,
        },
        stops: {
            didok: [8501157, 8579146],
            id: ['1970509520570619'],
        },
    },
    {
        boundsRect: {
            southWest: {
                lat: 46.71152813971745,
                lng: 6.369177527091826,
            },
            northEast: {
                lat: 46.71306625704422,
                lng: 6.371269213946867,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Vallorbe',
            longitude: null,
            latitude: null,
        },
        stops: {
            didok: [8501103, 8579293],
            id: ['1970505225606802'],
        },
    },
    {
        boundsRect: {
            southWest: {
                lat: 46.71409647855934,
                lng: 6.395992583523743,
            },
            northEast: {
                lat: 46.71984820948874,
                lng: 6.403815044197755,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Le Day',
            longitude: null,
            latitude: null,
        },
        stops: {
            didok: [8501104],
            id: ['1970505225606803'],
        },
    },
];
