import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DepartureService } from '../../services/departure.service';
import { DepartureStoreActions, DepartureStoreState } from '../../store';
import { Store } from '@ngrx/store';
import { AnalyticsService } from '@traas/common/analytics';
import { PreferencesService } from '@traas/common/feature-account';
import { Departure, JourneyListScrollModeEnum, LeaveOrArriveEnum, ThresholdConfiguration, TimeDisplayMode } from '@traas/boldor/all-models';
import { DepartureAdapter } from '../../../../models/departure/departure';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CompanyService } from '../../../../services/common/company/company.service';
import { LoggingService } from '@traas/common/logging';
import { InfiniteScrollListComponent } from '../../../../components/infinite-scroll-list/infinite-scroll-list.component';
import { AndroidBackButtonLockService } from '../../../../services/common/home/android-back-button-lock.service';
import { isPlaceholder, Placeholder } from '../../../../components/placeholder-list-item/placeholder.model';
import { DepartureDatePlaceholderService } from './departure-date-placeholder.service';
import { ConfigurationService } from '../../../../services/common/configuration/configuration.service';

const DEFAULT_DEPARTURE_ITEM_HEIGHT = 40;
const NB_OF_DEPARTURE_TO_SHOW = 8;

const sortDeparturesById = (departure1: Departure, departure2: Departure): number => {
    return departure1.id.localeCompare(departure2.id);
};

export const compareDeparturesByDepartureDate = (departure1: Departure | Placeholder, departure2: Departure | Placeholder): number => {
    if (isPlaceholder(departure1) || isPlaceholder(departure2)) {
        return 0;
    }
    const diff =
        new DepartureAdapter(departure1).getRealTimeDepartureDate().getTime() -
        new DepartureAdapter(departure2).getRealTimeDepartureDate().getTime();
    return diff || sortDeparturesById(departure1, departure2);
};

@Component({
    providers: [DepartureDatePlaceholderService],
    selector: 'app-departures-list-container',
    templateUrl: './departures-list-container.component.html',
    styleUrls: ['./departures-list-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeparturesListContainerComponent implements OnInit, OnDestroy {
    @Input() set departures(departures: (Departure | Placeholder)[]) {
        const sortedDepartures = departures.sort(compareDeparturesByDepartureDate);
        if (!sortedDepartures || sortedDepartures.length === 0) {
            this.departuresValue = [];
            return;
        }
        this.departuresValue = this.departureDatePlaceholderService.insertDatePlaceholdersBetweenDays(sortedDepartures);
    }

    get departures(): (Departure | Placeholder)[] {
        return this.departuresValue;
    }

    get departuresAdapter(): (DepartureAdapter | Placeholder)[] {
        return this.departuresValue.map((departure) => {
            if (isPlaceholder(departure)) {
                return departure;
            }
            return new DepartureAdapter(departure);
        });
    }

    @Input() thresholds: ThresholdConfiguration;
    @Input() scrollMode: JourneyListScrollModeEnum;

    @Output() requestMore = new EventEmitter<Date>();
    @Output() requestPrevious = new EventEmitter<Date>();
    @Output() scrollManually = new EventEmitter<void>();

    @HostBinding('class.has-placeholder')
    get hasPlaceholder(): boolean {
        return !!this.currentDepartureDatePlaceholder;
    }

    $hasValidDepartures: Observable<boolean>;
    $timeDisplayMode: Observable<TimeDisplayMode>;
    departureItemHeightInPx = DEFAULT_DEPARTURE_ITEM_HEIGHT;
    nbOfDepartureToShow = NB_OF_DEPARTURE_TO_SHOW;

    readonly isTPC = CompanyService.isTPC();
    readonly isTPG = CompanyService.isTPG();

    @ViewChild(InfiniteScrollListComponent) private infiniteScrollListComponent: InfiniteScrollListComponent<DepartureAdapter>;
    private departuresValue: (Departure | Placeholder)[] = [];
    private readonly $unsubscribe = new Subject<void>();
    currentDepartureDatePlaceholder: Placeholder | undefined;

    constructor(
        private analyticsService: AnalyticsService,
        private androidBackButtonLockService: AndroidBackButtonLockService,
        private departureService: DepartureService,
        private logger: LoggingService,
        private preferenceService: PreferencesService,
        private store: Store<DepartureStoreState.DepartureState>,
        private departureDatePlaceholderService: DepartureDatePlaceholderService,
        private configService: ConfigurationService,
    ) {
        this.$timeDisplayMode = this.$buildTimeDisplayMode();
    }

    ngOnInit(): void {
        // We have to keep this call to the service because its overridden by eiv in eiv-departure.service.ts
        this.$hasValidDepartures = this.departureService.$buildIsValidResponse();
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    onDepartureItemClicked(departure: Departure): void {
        this.androidBackButtonLockService.lock('onDepartureItemClicked');
        void this.reportAnalyticsEventOnClickDepartureItem(departure);
        this.store.dispatch(new DepartureStoreActions.OpenDetails(departure));
    }

    onOutdatedData(): void {
        this.infiniteScrollListComponent.hasOutdatedJourney();
    }

    onRequestMoreDepartures(departure: DepartureAdapter | Placeholder): void {
        if (isPlaceholder(departure)) {
            return;
        }
        try {
            this.requestMore.emit(departure.getRealTimeDepartureDate());
        } catch (error) {
            this.logger.logLocalError(error);
        }
    }

    onRequestPreviousDepartures(departure: DepartureAdapter | Placeholder): void {
        if (isPlaceholder(departure)) {
            return;
        }
        try {
            this.store.dispatch(new DepartureStoreActions.Loading(LeaveOrArriveEnum.ArriveBy));
            this.requestPrevious.emit(departure.getRealTimeDepartureDate());
        } catch (error) {
            this.logger.logLocalError(error);
        }
    }

    onNewItemsInViewport(departures: (DepartureAdapter | Placeholder)[]): void {
        this.store.dispatch(
            new DepartureStoreActions.SetDeparturesInScrollViewport({
                departures: departures.map((adapter) => {
                    if (isPlaceholder(adapter)) {
                        return adapter;
                    }
                    return adapter.getData();
                }),
            }),
        );
        if (!this.configService.shouldShowDateItemInJourneysList()) {
            return;
        }
        const placeholder = this.departureDatePlaceholderService.createDatePlaceholder(departures[0]);
        if (placeholder) {
            this.currentDepartureDatePlaceholder = placeholder;
            return;
        }
    }

    private async reportAnalyticsEventOnClickDepartureItem(departure: Departure): Promise<void> {
        const adapter = new DepartureAdapter(departure);
        const lineStop = `${adapter.getLineAdapter().getNumber()}-${adapter.getDirectionStopName()}-${adapter
            .getDepartureStop()
            .getName()}`;
        this.analyticsService.reportEvent('departures__line_details', {
            stop_name: adapter.getDepartureStop().getName(),
            line_name: adapter.getLineAdapter().getNumber(),
            direction: adapter.getDirectionStopName(),
            line_stop: lineStop,
        });
    }

    private $buildTimeDisplayMode(): Observable<TimeDisplayMode> {
        return this.preferenceService.$getTimeDisplayMode().pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    }

    private unsubscribe(): void {
        this.$unsubscribe.next();
        this.$unsubscribe.complete();
    }
}
