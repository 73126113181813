import { Component, NgZone } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
    selector: 'app-error',
    templateUrl: './error.page.html',
    styleUrls: ['./error.page.scss'],
})
export class ErrorPage {
    constructor(private zone: NgZone) {}

    goToApp(): void {
        /* We run this code outside angular zone because if the initial error was an template error for example, the template will
         be parsed again after this code, and there is a glitch because another error modal will be opening
         */
        this.zone.runOutsideAngular(() => {
            // Empty the navigation stack to ensure we reset the whole application
            SplashScreen.show();
            window.location.replace('/');
            // We run the reload instruction in a timeout because if we run it directly after the window.location.replace, the url doesn't
            // be already replaced and we reload the current url where there is the potential error
            setTimeout(() => {
                // We reset the URL to the home page
                window.location.reload();
            }, 300);
        });
    }
}
