<ion-grid>
    <ng-container *ngIf="!ticket.isProcessing; else isProcessing">
        <ion-row class="ticket">
            <ion-col *ngIf="!!ticket; else noTicket" class="ion-text-center">
                <ng-container *ngIf="ticket.isCancelled; else notCancelled">
                    <p class="cancelled-ticket">{{ 'my-journeys.canceled-ticket' | translate }}</p>
                    <p class="cancelled-ticket">
                        {{ 'my-journeys.refunded-on' | translate }}
                        {{ ticket.refundDate | date : 'dd.MM.yyyy' }}
                        {{ 'my-journeys.at' | translate }}
                        {{ ticket.refundDate | date : 'HH:mm' }}
                    </p>
                    <p class="cancelled-ticket">
                        {{ ticket.referenceNumber.label || ('my-journeys.ticket-detail.reference-number' | translate) }}
                        {{ getReferenceNumber() }}
                    </p>
                </ng-container>

                <ng-template #notCancelled>
                    <img *ngIf="ticket.qrCodeData; else noQrCodeData" [src]="ticket.qrCodeData" alt="QrCode" />
                    <ng-template #noQrCodeData>
                        <p class="expired-ticket">{{ 'my-journeys.expired-ticket' | translate }}</p>
                    </ng-template>
                </ng-template>
            </ion-col>
        </ion-row>
        <div class="details" *ngIf="ticket.article as article; else noArticle">
            <div class="passenger-info" *ngIf="ticket.passenger as passenger">
                <ion-row>
                    <ion-col size="12">{{ passenger.firstName }} {{ passenger.lastName }}</ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">{{ passenger.birthDate | date : 'dd.MM.yyyy' }}</ion-col>
                </ion-row>
            </div>

            <ion-row class="travel-type title">
                <ion-col size="12">{{ getBilletTypeLabel(article) }}</ion-col>
            </ion-row>

            <div class="travel-type-validity" *ngIf="thereIsLocationsValidityToShow(article)">
                <ion-row *ngFor="let locationValidity of article.locationsValidity.values">
                    <ion-col
                        *ngIf="isDifferentThanZonesText(article, locationValidity)"
                        [ngClass]="isViaLocation(locationValidity) ? 'via' : 'main-location'"
                        size="12"
                    >
                        {{ locationValidity }}
                    </ion-col>
                </ion-row>
            </div>

            <div class="travel-type-choices" *ngIf="thereIsLocationsChoiceToShow(article)">
                <ion-row class="label">{{ article.locationsChoice.label }}</ion-row>

                <ion-row *ngFor="let locationChoice of article.locationsChoice.values">
                    <ion-col
                        *ngIf="isDifferentThanZonesText(article, locationChoice)"
                        [ngClass]="isViaLocation(locationChoice) ? 'via' : 'main-location'"
                        size="12"
                    >
                        {{ locationChoice }}
                    </ion-col>
                </ion-row>
            </div>

            <ion-row *ngFor="let include of article.includes">
                <ion-col size="12">{{ include }}</ion-col>
            </ion-row>

            <ion-row>
                <ion-col size="12">{{ getZonesLabel(article.zones) }}</ion-col>
            </ion-row>

            <ion-row class="zones" *ngIf="getZonesText(article.zones) as zones" [class.tpc]="isTpc">
                <ion-col size="12">{{ zones }}</ion-col>
            </ion-row>

            <ion-row class="travel-options">
                <ion-col size="12" class="travel-class"
                    >{{ tripLabel }} <span class="reduction">{{ article.reduction?.description }}</span>
                </ion-col>
                <ion-col size="12" class="price">
                    {{ getFormattedPrice(ticket.article.prices[0]) }}
                    {{ ticket.article.vatLabel }}
                </ion-col>
            </ion-row>

            <div class="availability-date">
                <ion-row class="label">
                    {{ article.validity.label || ('my-journeys.ticket-availability' | translate) }}
                </ion-row>
                <ion-row class="from"> {{ article.validity.from | date : dateTimeFormat }}-</ion-row>
                <ion-row class="to">
                    {{ article.validity.to | date : dateTimeFormat }}
                </ion-row>
            </div>

            <div class="conditions">
                <ion-row *ngFor="let condition of article.conditions">
                    <ion-col class="condition-text" [innerHTML]="condition"></ion-col>
                </ion-row>
            </div>

            <ion-row class="purchase-date">
                <ion-col size="12"
                    >{{ 'my-journeys.ticket-purchase.bought-on' | translate }}
                    {{ ticket.purchaseDate | date : dateFormat }}
                    {{ 'my-journeys.at' | translate }}
                    {{ ticket.purchaseDate | date : 'HH:mm:ss' }}</ion-col
                >
            </ion-row>

            <div class="references">
                <ion-row class="ticket-id">
                    <ion-col class="label">
                        {{ ticket.ticketNumber.label || ('my-journeys.ticket-detail.ticket-id' | translate) }}
                    </ion-col>
                    <ion-col class="value ion-text-end">{{ ticket.ticketNumber.value }}</ion-col>
                </ion-row>
                <ion-row class="article-number">
                    <ion-col class="label" size="auto">
                        {{ ticket.articleNumber.label || ('my-journeys.ticket-detail.article-number' | translate) }}
                    </ion-col>
                    <ion-col class="value ion-text-end">{{ ticket.articleNumber.value }}</ion-col>
                </ion-row>
                <ion-row class="reference-number">
                    <ion-col class="label" size="auto">
                        {{ ticket.referenceNumber.label || ('my-journeys.ticket-detail.reference-number' | translate) }}
                    </ion-col>
                    <ion-col class="value ion-text-end">{{ getReferenceNumber() }}</ion-col>
                </ion-row>
                <ion-row *ngIf="isTpc" class="order-number">
                    <ion-col class="label" size="auto">
                        {{ 'my-journeys.ticket-detail.order-number' | translate }}
                    </ion-col>
                    <ion-col class="value ion-text-end">{{ getOrderNumber() }}</ion-col>
                </ion-row>
                <ion-row class="fq-code">
                    <ion-col size="12">{{ ticket.fqCode }}</ion-col>
                </ion-row>
            </div>
        </div>
        <ng-template #noTicket>
            <p>{{ 'my-journeys.ticket-not-found' | translate }}</p>
        </ng-template>
        <ng-template #noArticle>
            <p>{{ 'my-journeys.article-not-found' | translate }}</p>
        </ng-template>
    </ng-container>
    <ng-template #isProcessing>
        <div>
            <p>{{ 'my-journeys.ticket-detail.is-processing' | translate }}</p>
        </div>
    </ng-template>
</ion-grid>
