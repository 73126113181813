export interface Point {
    readonly x: number;
    readonly y: number;
}

export function point(x: number, y: number): Point {
    return { x, y };
}

export interface Line {
    readonly from: Point;
    readonly to: Point;
}

export function line(from: Point, to: Point): Line {
    return { from, to };
}
