import { inject } from '@angular/core';
import { NavController } from '@ionic/angular';
import { PreflightService } from '@traas/boldor/common/services/common/preflight/preflight.service';
import { OnlineService } from '@traas/common/utils';
import { CanActivateFn } from '@angular/router';
import { filter, firstValueFrom } from 'rxjs';

let called = false;

export const updateGuardFactory: () => CanActivateFn = (
    onlineService = inject(OnlineService),
    preflightService = inject(PreflightService),
    navCtrl = inject(NavController),
) => {
    const redirectIfOutdated = async (): Promise<boolean> => {
        if (await preflightService.fetchIsOutdated()) {
            await navCtrl.navigateRoot('/update');
            return false;
        }
        return true;
    };

    const whenBackOnline = (): Promise<boolean> => firstValueFrom(onlineService.$getIsOnline().pipe(filter((isOnline) => isOnline)));

    const canActivateFn: CanActivateFn = async (): Promise<boolean> => {
        if (called) {
            return true;
        }
        called = true;

        if (await onlineService.isOffline()) {
            whenBackOnline().then(() => redirectIfOutdated());
            return true;
        }

        return await redirectIfOutdated();
    };

    return canActivateFn;
};
