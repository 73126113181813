import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CancelledTripIconComponent } from '../../cancelled-trip-icon/cancelled-trip-icon.component';
import { DepartureTimeViewModel } from '../../../features/departure/services/departure-time/departure-time.service';
import { DepartureTimeComponent } from '../departure-time/departure-time.component';

@Component({
    selector: 'traas-departure-time-cancelled',
    standalone: true,
    imports: [CommonModule, IonicModule, DepartureTimeComponent, CancelledTripIconComponent],
    templateUrl: './departure-time-cancelled.component.html',
    styleUrls: ['./departure-time-cancelled.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartureTimeCancelledComponent {
    @Input() departureTimeViewModel: DepartureTimeViewModel;
}
