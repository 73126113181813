import { NgModule } from '@angular/core';
import { StartupNotificationStoreModule } from './store/startup-notification-store.module';
import { PageTitleModule } from '../../components/page-title/page-title.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { StartupNotificationContainerComponent } from './containers/startup-notification/startup-notification-container.component';

@NgModule({
    declarations: [StartupNotificationContainerComponent],
    imports: [BoldorFeatureShellSharedModule, StartupNotificationStoreModule, PageTitleModule],
    exports: [StartupNotificationContainerComponent],
})
export class StartupNotificationModule {}
