import { ChangePlaceEventSourceEnum } from '@traas/boldor/all-models';

export function isAddress(changePlaceEventSource: ChangePlaceEventSourceEnum): boolean {
    return changePlaceEventSource === ChangePlaceEventSourceEnum.AddressSelection;
}

export function isPoi(changePlaceEventSource: ChangePlaceEventSourceEnum): boolean {
    return changePlaceEventSource === ChangePlaceEventSourceEnum.PoiSelection;
}

export function isManualMapMove(changePlaceEventSource: ChangePlaceEventSourceEnum): boolean {
    return changePlaceEventSource === ChangePlaceEventSourceEnum.ManualMapMove;
}

export function isMyGpsPosition(changePlaceEventSource: ChangePlaceEventSourceEnum): boolean {
    return changePlaceEventSource === ChangePlaceEventSourceEnum.MyGpsPositionSelection;
}

export function isCitySelection(changePlaceEventSource: ChangePlaceEventSourceEnum): boolean {
    return changePlaceEventSource === ChangePlaceEventSourceEnum.CitySelection;
}

export function isFollowGps(changePlaceEventSource: ChangePlaceEventSourceEnum): boolean {
    return changePlaceEventSource === ChangePlaceEventSourceEnum.ClickFollowGps;
}
