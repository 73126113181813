import { AfterViewInit, ChangeDetectionStrategy, Component, EnvironmentInjector, inject, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouteUrl } from '@traas/common/routing';
import { IonTabButton, IonTabs, NavController } from '@ionic/angular';
import { TabsService } from '../../services/common/tabs/tabs.service';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { environment } from '@traas/boldor/environments';

@Component({
    selector: 'page-tabs',
    templateUrl: 'tabs.html',
    styleUrls: ['tabs.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsPage implements OnDestroy, AfterViewInit {
    @ViewChild('bookingsTab') bookingsTab: IonTabButton;
    @ViewChild('departureTab') departureTab: IonTabButton;
    @ViewChild('itineraryTab') itineraryTab: IonTabButton;
    @ViewChild('mainTabs') mainTabs: IonTabs;
    @ViewChild('menuTab') menuTab: IonTabButton;
    @ViewChild('ticketsTab') ticketsTab: IonTabButton;

    public isDebugMode = environment.isDebugMode;

    private readonly $unsubscribe = new Subject<void>();
    private readonly navCtrl = inject(NavController);

    constructor(public environmentInjector: EnvironmentInjector, private tabsService: TabsService, private router: Router) {}

    ngOnDestroy(): void {
        this.$unsubscribe.next();
        this.$unsubscribe.complete();
    }

    ngAfterViewInit(): void {
        this.selectTab(this.departureTab);
        this.updateSelectedStateOfCurrentTabByNavigationListening();
    }

    /**
     * MDS BDORAPP-248
     *
     * We must manage manually the "selected" state because of sub-route for both of mapTab and gridTab. The active tab when we select
     * one of them is "home" ... not "home/map" or "home/grid", so ionTabsDidChange event and ionTabsWillChange event are not fired
     * between gridTab and mapTab ... So to set the "selected" state of them, we have to listen the url change
     */
    private updateSelectedStateOfCurrentTabByNavigationListening(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                debounceTime(100),
                takeUntil(this.$unsubscribe),
            )
            .subscribe(({ url, urlAfterRedirects }: NavigationEnd) => {
                this.selectCurrentTabFromUrl(url, urlAfterRedirects);
            });
    }

    private selectCurrentTabFromUrl(url: string, urlAfterRedirects: string): void {
        if (
            url.includes(RouteUrl.departureResultUrl) ||
            url.includes(RouteUrl.departureDetailUrl) ||
            urlAfterRedirects.includes(RouteUrl.departureDetailUrl) // urlAfterRedirects is set at the starting of the app
        ) {
            this.selectTab(this.departureTab);
        } else if (url.includes(RouteUrl.itineraryResultUrl) || url.includes(RouteUrl.itineraryDetailUrl)) {
            this.selectTab(this.itineraryTab);
        } else if (url.includes('menu')) {
            this.selectTab(this.menuTab);
        } else if (url.includes('bookings')) {
            this.selectTab(this.bookingsTab);
        } else if (url.includes('tickets')) {
            this.selectTab(this.ticketsTab);
        } else if (url.includes('home')) {
            // fallback for home
            this.selectTab(this.departureTab);
        }
    }

    /**
     * MDS BDORAPP-248
     *
     * The setTimeout is used to force view to refresh because in some case and SOMETIMES (often), the selected
     * property of tab is not refreshed in view. To reproduce : Switch between menu and Map or Grid more times.
     * this.mainTabs.select("home/map"); -> Doesn't work because of the specific "home/map" tab and "home/grid" tab, the
     * mainTabs.getSelected will return "home" and not "home/map" or "home/grid" even if we call this.mainTabs.select("home/map").
     */
    private selectTab(tab: IonTabButton): void {
        if (!tab.selected) {
            setTimeout(() => {
                this.tabsService.setFirstTabSelectionAfterAppRunnig(true);
                this.unselectAllTab();
                tab.selected = true;
            }, this.tabsService.getDelayToWaitBeforeSetActiveTab());
        }
    }

    private unselectAllTab(): void {
        this.departureTab.selected = false;
        this.itineraryTab.selected = false;
        this.menuTab.selected = false;
        this.bookingsTab.selected = false;
        this.ticketsTab.selected = false;
    }

    // these click handlers are here to navigate at tab root.
    async onMenuTabClick(): Promise<void> {
        await this.navCtrl.navigateRoot(RouteUrl.menuTabUrl);
    }

    async onBookingsTabClick(): Promise<void> {
        await this.navCtrl.navigateRoot(RouteUrl.bookingsTabUrl);
    }
}
