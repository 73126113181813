import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'traas-back-link',
    templateUrl: './back-link.component.html',
    styleUrls: ['./back-link.component.scss'],
})
export class BackLinkComponent {
    @Output() backLinkClicked = new EventEmitter<void>();

    onClick(): void {
        this.backLinkClicked.emit();
    }
}
