import { CanDeactivateFn } from '@angular/router';
import { Deactivable } from './deactivable';
import { inject } from '@angular/core';
import { RouterStateService } from '@traas/common/routing';

export const confirmLeavingFn: CanDeactivateFn<Deactivable> = async (component, currentRoute, currentState, nextState) => {
    if (!nextState) {
        return true;
    }

    const routerStateService = inject(RouterStateService);
    const origin = routerStateService.getOrigin();
    const canLeave = component.isAllowedUrl(nextState.url) || (await component.canLeave(nextState.url, origin));
    if (canLeave) {
        component.deactivate(nextState.url);
        return true;
    }
    return false;
};
