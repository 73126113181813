import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LineIconModule } from '../line-icon/line-icon.module';
import { LinesIconHorizontalListComponent } from './lines-icon-horizontal-list/lines-icon-horizontal-list.component';

@NgModule({
    imports: [CommonModule, IonicModule, LineIconModule],
    declarations: [LinesIconHorizontalListComponent],
    exports: [LinesIconHorizontalListComponent],
})
export class LinesIconHorizontalListModule {}
