import { NgModule } from '@angular/core';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import { LinkModule } from '../../components/link/link.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { TicketListItemComponent } from './components/ticket-list-item/ticket-list-item.component';
import { TicketListTpgComponent } from './containers/ticket-list-tpg/ticket-list-tpg.component';
import { CreditCardsModule } from '../credit-cards/credit-cards.module';
import { TicketStoreModule } from './store/ticket-store.module';
import { TicketItineraryButtonComponent } from './components/ticket-itinerary-button/ticket-itinerary-button.component';
import { TicketListComponent } from './containers/ticket-list/ticket-list.component';
import { TicketDetailComponent } from './components/ticket-detail/ticket-detail.component';

@NgModule({
    imports: [BoldorFeatureShellSharedModule, CreditCardsModule, LinkModule, TicketStoreModule],
    declarations: [
        TicketItineraryButtonComponent,
        TicketListItemComponent,
        TicketListTpgComponent,
        TicketListComponent,
        TicketDetailComponent,
    ],
    providers: [SMS],
    exports: [TicketItineraryButtonComponent, TicketListTpgComponent, TicketDetailComponent, TicketListComponent, TicketListItemComponent],
})
export class TicketModule {}
