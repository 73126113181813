import { BaseError } from './base-error';
import { TechnicalError } from './technical-error';
import { ApolloError } from '@apollo/client/core';
import { ErrorCodes } from './error-codes';

const name = 'FatalError';

export class FatalError extends BaseError {
    readonly errorCode?: string;
    readonly serverErrorCode?: string;
    override readonly innerError!: Error;

    constructor(innerError: Error) {
        if (innerError instanceof TechnicalError) {
            super(innerError.message, name, innerError, innerError.context);
            this.errorCode = innerError.errorCode;
        } else if (innerError instanceof ApolloError) {
            super(innerError.message, name, innerError);
            this.errorCode = ErrorCodes.Graphql.Request;
        } else {
            super(innerError.message, name, innerError);
            this.errorCode = undefined;
        }

        this.serverErrorCode = this.determineServerErrorCode(innerError);
        this.name = name;
        this.innerError = innerError;
    }
}
