<ion-header>
    <app-page-title
        iconUrl="assets/icons/tabs/monochrome/tickets.svg"
        title="{{ 'tickets.title' | translate }}"
        [canGoBack]="false"
    ></app-page-title>
</ion-header>

<ion-content>
    <ng-container *ngIf="$isOnline | async; else offline">
        <ng-container *ngIf="!($isLoading | async); else isLoading">
            <ng-container *ngIf="$errorMessage | async as errorMessage; else showTickets">
                <div class="offline-message">
                    <app-message message="{{ errorMessage }}"></app-message>
                </div>
            </ng-container>
            <ng-template #showTickets>
                <travys-ticket-list
                    [$isOnline]="$isOnline"
                    [nearestStop]="{ value: $nearestStop | async, isLoading: $isLoadingNearestStop | async }"
                    [locationTicketsCategory]="$locationTicketsCategory | async"
                    [otherTicketsCategory]="$otherTicketsCategories | async"
                    [smsTicketsCategories]="smsTicketsCategories"
                    [preferredTravelClass]="$preferredTravelClass | async"
                    (buyTicket)="openCartUsingTicketByPosition($event)"
                    (buyTicketByZone)="openCartUsingTicketByZone($event)"
                    (navigateToItinerary)="navigateToItinerary()"
                ></travys-ticket-list>
            </ng-template>
        </ng-container>
    </ng-container>

    <ng-template #isLoading>
        <div class="spinner-container">
            <app-message message="{{ 'tickets.loading-message' | translate }}"></app-message>
            <ion-spinner class="loading-spinner e2e-gathering-tickets"></ion-spinner>
        </div>
    </ng-template>
    <ng-template #offline>
        <div class="offline-message">
            <app-offline-page message="{{ 'common.offline-message' | translate }}"></app-offline-page>
        </div>
    </ng-template>
</ion-content>
