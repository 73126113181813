import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SymbolSvgModule } from '../symbol-svg/symbol-svg.module';
import { VehicleIconComponent } from './vehicle-icon.component';

@NgModule({
    imports: [CommonModule, IonicModule, SymbolSvgModule],
    declarations: [VehicleIconComponent],
    exports: [VehicleIconComponent],
})
export class VehicleIconModule {}
