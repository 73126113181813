import { Injectable } from '@angular/core';
import { DEPARTURE_IS_DIRTY, DepartureDirtiness } from '../models/departure-dirtiness';
import { EndpointActions, EndpointSelectors } from '../../home/store/endpoint';
import { HomeState } from '../../home/store';
import { MapSelectors } from '../../home/store/map';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, skip, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Area, ChangePlaceEventSourceEnum, createEmptyArea, EndpointData } from '@traas/boldor/all-models';
import { createLocationOf, getIconNameByChangePlaceEvent } from '../../../business-rules.utils';
import { AreaHelper } from '@traas/boldor/all-helpers';
import { environment } from '@traas/boldor/environments';

@Injectable({ providedIn: 'root' })
export class EndpointService {
    constructor(private store: Store<HomeState>) {
        // We skip three times, because there is the first value fired by the behavior subject default value, then the first detection of
        // $bounds fired by the map configuration center then eventually the value fired by the GPS position.
        this.store
            .select(EndpointSelectors.getDeparture)
            .pipe(
                skip(2),
                take(1),
                tap(() => DepartureDirtiness.setDepartureDirtiness(true)),
            )
            .subscribe();
    }

    setDeparture(area: Area, options?: { forceLocationName: string; ignoreNextChange?: boolean }): void {
        const endpoint = this.transformAreaToEndpointData(area, options?.forceLocationName);
        this.store.dispatch(
            new EndpointActions.NewDeparture({
                area,
                endpoint: endpoint,
                ignoreNextChange: options?.ignoreNextChange,
            }),
        );
    }

    setArrival(area: Area, options?: { forceLocationName: string; ignoreNextChange?: boolean }): void {
        const endpoint = this.transformAreaToEndpointData(area, options?.forceLocationName);
        this.store.dispatch(
            new EndpointActions.NewArrival({
                area,
                endpoint,
                ignoreNextChange: options?.ignoreNextChange,
            }),
        );
    }

    $getDepartureArea(): Observable<Area> {
        return this.store.select(EndpointSelectors.getDepartureArea);
    }

    $getArrivalArea(): Observable<Area> {
        return this.store.select(EndpointSelectors.getArrivalArea);
    }

    $hasValidActiveEndpoint(): Observable<boolean> {
        return this.store.select(MapSelectors.getMapUpdating).pipe(
            withLatestFrom(this.store.select(MapSelectors.isEnabled)),
            filter(([isMapUpdating, isEnabled]) => !isMapUpdating && isEnabled),
            switchMap(() => {
                try {
                    return this.store.select(MapSelectors.hasValidStopsCountInBounds);
                } catch (error) {
                    console.warn(error);
                    return of(false);
                }
            }),
        );
    }

    transformAreaToEndpointData(area: Area, endpointLocations?: string): EndpointData {
        try {
            if (!area) {
                return this.createEndpointData();
            }
            if (AreaHelper.containsTooManyCommercialStops(area, environment.company)) {
                return this.createEndpointData('', area.metadata.source, true);
            }
            return this.createEndpointData(endpointLocations ?? createLocationOf(area), area.metadata.source, false);
        } catch (error) {
            console.warn('Error during transformAreaToEndpointData :', error);
            return this.createEndpointData();
        }
    }

    private createEndpointData(
        locations: string = '',
        source: ChangePlaceEventSourceEnum = ChangePlaceEventSourceEnum.ManualMapMove,
        hasTooMuchStops = false,
    ): EndpointData {
        const endpointData = { locations, source, hasTooMuchStops, iconName: '' };
        if (!DEPARTURE_IS_DIRTY) {
            return endpointData;
        }
        if (!endpointData.iconName) {
            endpointData.iconName = getIconNameByChangePlaceEvent(source);
        }
        return endpointData;
    }

    clearEndpoints(): void {
        const emptyArea = createEmptyArea(ChangePlaceEventSourceEnum.ManualMapMove);
        const emptyEndpointData = this.transformAreaToEndpointData(emptyArea);

        this.store.dispatch(
            new EndpointActions.NewDeparture({
                endpoint: emptyEndpointData,
                area: emptyArea,
            }),
        );

        this.store.dispatch(
            new EndpointActions.NewArrival({
                endpoint: emptyEndpointData,
                area: emptyArea,
            }),
        );
    }
}
