<ion-list>
    <ion-item (click)="emitSelectPlace(undefined)" lines="none">
        <div class="item-container">
            <ion-icon src="assets/icons/endpoint/map.svg" color="primary"></ion-icon>
            <h2>{{ 'search-place.map-search' | translate }}</h2>
        </div>
    </ion-item>
    <app-places-list-item
        (selected)="emitSelectPlace(place)"
        *ngFor="let place of places; trackBy: trackByPlace"
        [disabled]="$itemsAreDisabled | async"
        [nameOrPath]="place.getNameOrPath()"
        [name]="place.getName()"
        tappable
    >
    </app-places-list-item>
</ion-list>
