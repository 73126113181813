<ion-header>
    <div class="offset-status-bar"></div>
    <ion-row class="endpoints">
        <div oitClickUsingBackButton (click)="closeModal()" class="back-button">
            <ion-icon class="back-arrow" src="assets/icons/back-arrow.svg"></ion-icon>
        </div>
        <app-search-bar *ngIf="$isOnline | async" (clearTerm)="onClearTerm()" (termChange)="onTermChange($event)"></app-search-bar>
    </ion-row>
</ion-header>
<ion-content>
    <ng-container *ngIf="$isOnline | async; else offline">
        <ion-row class="loader">
            <ion-col size="12" class="ion-text-center">
                <ion-spinner
                    *ngIf="$isLoading | async; else e2eLoadingFinished"
                    class="ion-justify-content-center e2e-network-search-spinner"
                ></ion-spinner>
                <ng-template #e2eLoadingFinished>
                    <span class="e2e-loading-finished"></span>
                </ng-template>
            </ion-col>
        </ion-row>
        <app-places-list
            (selectPlace)="onSelectPlace($event)"
            *ngIf="$searchPlacesResponse | async as placesResponse"
            [searchPlacesResponse]="placesResponse"
            [term]="$term | async"
        ></app-places-list>
    </ng-container>

    <ng-template #offline>
        <div class="offline-message">
            <app-offline-page message="{{ 'common.offline-message' | translate }}"></app-offline-page>
        </div>
    </ng-template>
</ion-content>
