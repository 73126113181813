/* eslint-disable max-classes-per-file */
/* tslint:disable:max-classes-per-file */
import { Action } from '@ngrx/store';
import { ItineraryAdapter } from '../../../../models/itinerary/itinerary';
import { LatLng, LatLngBounds } from 'leaflet';
import { MapMode, MapMoveSource } from '@traas/boldor/all-models';
import { PhysicalStop } from '@traas/common/models';

const defaultEnableParameters = { enableDragging: true, enableAllListeners: true };
const defaultDisableParameters = { disableAllListeners: true };

export enum MapActionTypes {
    Moved = '[Map] Moved',
    UpdateLayers = '[Map] MaJ of layers',
    UpdateEdgeMarkers = '[Map] MaJ of edge markers',
    UpdateTracksLayer = '[Map] MaJ of tracks layers',
    UpdateStopsInBounds = '[Map] Update stops in bounds',
    TooMuchStopsInBounds = '[Map] Set too much stops in bounds',
    Disable = '[Map] Disable map',
    Enable = '[Map] Enable map',
    DrawDetailsElements = '[Map] Draw details elements',
    SetMapMode = '[Map] Set the map mode',
    EnableDragging = '[Map] Enable Dragging',
    DisableDragging = '[Map] Disable Dragging',
    ShowItinerary = '[Map] Show itinerary',
}

export class Moved implements Action {
    readonly type: MapActionTypes.Moved = MapActionTypes.Moved;

    constructor(
        public payload: {
            source: MapMoveSource;
            bounds: LatLngBounds;
            centerPoint: LatLng;
            zoomLevel: number;
        },
    ) {}
}

export class UpdateLayers implements Action {
    readonly type: MapActionTypes.UpdateLayers = MapActionTypes.UpdateLayers;

    constructor(public payload: { all: PhysicalStop[]; inSafeArea: PhysicalStop[] }) {}
}

export class TooMuchStopsInBounds implements Action {
    readonly type: MapActionTypes.TooMuchStopsInBounds = MapActionTypes.TooMuchStopsInBounds;

    constructor(public payload: boolean) {}
}

export class UpdateStopsInBounds implements Action {
    readonly type: MapActionTypes.UpdateStopsInBounds = MapActionTypes.UpdateStopsInBounds;

    constructor(public payload: { all: PhysicalStop[]; inSafeArea: PhysicalStop[] }) {}
}

export class UpdateEdgeMarkers implements Action {
    readonly type: MapActionTypes.UpdateEdgeMarkers = MapActionTypes.UpdateEdgeMarkers;
}

export class UpdateTracksLayer implements Action {
    readonly type: MapActionTypes.UpdateTracksLayer = MapActionTypes.UpdateTracksLayer;

    constructor(public payload: { stops: PhysicalStop[] }) {}
}

export class Disable implements Action {
    readonly type = MapActionTypes.Disable;

    constructor(public payload: { disableAllListeners: boolean } = defaultDisableParameters) {}
}

export class Enable implements Action {
    readonly type = MapActionTypes.Enable;

    constructor(
        public payload: {
            enableDragging: boolean;
            enableAllListeners: boolean;
        } = defaultEnableParameters,
    ) {}
}

export class DrawDetailsElements implements Action {
    readonly type = MapActionTypes.DrawDetailsElements;
}

export class SetMapMode implements Action {
    readonly type = MapActionTypes.SetMapMode;

    constructor(public payload: MapMode) {}
}

export class EnableDragging implements Action {
    readonly type = MapActionTypes.EnableDragging;
}

export class DisableDragging implements Action {
    readonly type = MapActionTypes.DisableDragging;
}

export class ShowItinerary {
    readonly type = MapActionTypes.ShowItinerary;

    constructor(public payload: ItineraryAdapter) {}
}

export type MapAction =
    | Moved
    | UpdateLayers
    | TooMuchStopsInBounds
    | UpdateStopsInBounds
    | UpdateTracksLayer
    | UpdateEdgeMarkers
    | Enable
    | Disable
    | DrawDetailsElements
    | SetMapMode
    | EnableDragging
    | DisableDragging
    | ShowItinerary;
