import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DynamicIonIconComponent } from './dynamic-ion-icon.component';

@NgModule({
    imports: [CommonModule, IonicModule],
    declarations: [DynamicIonIconComponent],
    exports: [DynamicIonIconComponent],
})
export class DynamicIonIconModule {}
