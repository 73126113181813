import { Injectable } from '@angular/core';
import {
    Area,
    ChangePlaceEventSourceEnum,
    createActiveArea,
    createEmptyArea,
    Endpoint,
    GeopositionAdapter,
    isArrival,
    isDeparture,
    SearchModeOptions,
    toBounds,
    toLatLngRect,
} from '@traas/boldor/all-models';
import { RE_ENABLE_ENDPOINT_DELAY } from '../../../../business-rules.utils';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { convertToError, LoggingService } from '@traas/common/logging';
import { ModalService } from '../../../../services/common/modal.service';
import { GridStoreActions } from '../../../../components/grid/store';
import { ChangeSearchMode } from '../../../../components/grid/store/grid.actions';
import { EMPTY, Observable } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AndroidBackButtonService } from '../../../../services/common/home/android-back-button.service';
import { Position } from '@capacitor/geolocation';
import { AreaHelper, isManualMapMove } from '@traas/boldor/all-helpers';
import {
    ArrivalChanged,
    DepartureChanged,
    EndpointActionTypes,
    NewArrival,
    NewDeparture,
    SetActiveEndpoint,
    SetBounds,
} from './endpoint.action';
import { EndpointActions, EndpointActionsUnion, EndpointSelectors } from './index';
import { getActiveEndpoint, getEndpointState } from './endpoint.selector';
import { dynamicLayerName, EDGE_LAYER, TRACKS_LAYER } from '@traas/common/utils';
import { EndpointService } from '../../../endpoint/services/endpoint.service';
import { DepartureService } from '../../../departure/services/departure.service';
import { HomeMapContainerService } from '../../containers/home-map-container/home-map-container.service';
import { MapService } from '../../../map/services/map.service';
import { ItineraryService } from '../../../itinerary/services/itinerary.service';
import { LayerBuilderService } from '../../../map/services/layer-builder.service';
import { getIsDepartureUrl, getIsItineraryUrl, getIsJourneyDetailsPage } from '../../../../router-store/selectors';
import { RouterState } from '../../../../router-store/state';
import { environment } from '@traas/boldor/environments';
import { ErrorCodes, isSuccess, TechnicalError } from '@traas/common/models';

@Injectable()
export class EndpointEffect {
    $linkableClickSearch: Observable<ChangeSearchMode> = createEffect(() =>
        this.$actions.pipe(
            ofType(EndpointActionTypes.LinkableClickSearch),
            mergeMap(() => {
                return [new GridStoreActions.ChangeSearchMode({ mode: SearchModeOptions.MAP })];
            }),
        ),
    );

    $newDeparture: Observable<DepartureChanged> = createEffect(() =>
        this.$actions.pipe(
            ofType<NewDeparture>(EndpointActionTypes.NewDeparture),
            tap(({ payload: { area, ignoreNextChange } }) => {
                this.clearDynamicLayerAndDispatchIgnoreNextChange(area, ignoreNextChange, Endpoint.Departure);
            }),
            map(() => new EndpointActions.DepartureChanged()),
        ),
    );

    $newArrival: Observable<ArrivalChanged> = createEffect(() =>
        this.$actions.pipe(
            ofType<NewArrival>(EndpointActionTypes.NewArrival),
            tap(({ payload: { area, ignoreNextChange } }) => {
                this.clearDynamicLayerAndDispatchIgnoreNextChange(area, ignoreNextChange, Endpoint.Arrival);
            }),
            map(() => new EndpointActions.ArrivalChanged()),
        ),
    );

    $clearItinerariesOnUnsuitableEndpoints = createEffect(
        () =>
            this.$actions.pipe(
                ofType<NewDeparture | NewArrival>(EndpointActionTypes.NewDeparture, EndpointActionTypes.NewArrival),
                withLatestFrom(this.store.select(getEndpointState), (action, state) => state),
                withLatestFrom(this.store.select(getIsItineraryUrl), (state, isItineraryUrl) => ({ state, isItineraryUrl })),
                filter(({ isItineraryUrl }) => isItineraryUrl),
                map(({ state }) => [state.departure.area, state.arrival.area]),
                filter(
                    ([departure, arrival]) =>
                        !AreaHelper.isSuitableForSearch(departure, environment.company) ||
                        !AreaHelper.isSuitableForSearch(arrival, environment.company) ||
                        AreaHelper.equals(departure, arrival),
                ),
                tap(() => this.itinerariesService.clearItineraries()),
            ),
        { dispatch: false },
    );

    $clearDeparturesOnUnsuitableEndpoint = createEffect(
        () =>
            this.$actions.pipe(
                ofType<NewDeparture>(EndpointActionTypes.NewDeparture),
                withLatestFrom(this.store.select(getEndpointState), (action, state) => state),
                withLatestFrom(this.store.select(getIsDepartureUrl), (state, isDepartureUrl) => ({ state, isDepartureUrl })),
                filter(({ isDepartureUrl }) => isDepartureUrl),
                map(({ state }) => state.departure.area),
                filter((departure) => !AreaHelper.isSuitableForSearch(departure, environment.company)),
                tap(() => this.departureService.clearDepartures()),
            ),
        { dispatch: false },
    );

    $departureChanged: Observable<unknown> = createEffect(
        () =>
            this.$actions.pipe(
                ofType(EndpointActionTypes.DepartureChanged),
                tap(() => this.modalService.hideLoading()),
                tap(() => this.androidBackButtonService.onDepartureChanged()),
                withLatestFrom(this.routerStore.select(getIsJourneyDetailsPage), (action, isJourneyDetailsPage) => isJourneyDetailsPage),
                filter((isJourneyDetailsPage) => !isJourneyDetailsPage),
                tap(() => this.store.dispatch(new EndpointActions.Enable())),
            ),
        { dispatch: false },
    );

    $arrivalChanged: Observable<unknown> = createEffect(
        () =>
            this.$actions.pipe(
                ofType(EndpointActionTypes.ArrivalChanged),
                tap(() => this.modalService.hideLoading()),
                withLatestFrom(this.routerStore.select(getIsJourneyDetailsPage), (action, isJourneyDetailsPage) => isJourneyDetailsPage),
                filter((isJourneyDetailsPage) => !isJourneyDetailsPage),
                tap(() => this.store.dispatch(new EndpointActions.Enable())),
            ),
        { dispatch: false },
    );

    /**
     * This effect use the GPS marker lat/lng, but if position is falsy or in error because of bad
     * network or accuracy, the GPS marker can be not drawn. So we check it to be sure.
     */
    $followGps = createEffect(
        () =>
            this.$actions.pipe(
                ofType(EndpointActionTypes.FollowGps),
                filter(() => isSuccess(this.mapService.getGpsMarker())),
                withLatestFrom(this.store.select(EndpointSelectors.getActiveEndpoint), ({ payload }, activeEndpoint) => ({
                    endpointFollowingGps: payload.endpoint,
                    activeEndpoint,
                })),
                switchMap(({ endpointFollowingGps, activeEndpoint }) => {
                    try {
                        const boundsResult = this.mapService.createBoundsFromGpsMarker();
                        if (!isSuccess(boundsResult)) {
                            this.logger.logError(
                                new TechnicalError(`Can't follow GPS because of bad bounds`, ErrorCodes.Map.FollowGps, boundsResult.error),
                            );
                            return EMPTY;
                        }
                        const geoposition = new GeopositionAdapter({
                            coords: { latitude: boundsResult.value.getCenter().lat, longitude: boundsResult.value.getCenter().lng },
                        } as Position);
                        return this.homeMapContainerService.updateEndpointOnNewGpsPosition(
                            geoposition,
                            activeEndpoint,
                            endpointFollowingGps,
                        );
                    } catch (error) {
                        this.logger.logLocalError(new TechnicalError(`Can't follow GPS`, ErrorCodes.Map.FollowGps, convertToError(error)));
                        return EMPTY;
                    }
                }),
            ),
        { dispatch: false },
    );

    $unfollowGps: Observable<unknown> = createEffect(() =>
        this.$actions.pipe(
            ofType(EndpointActionTypes.UnfollowGps),
            map(() => {
                return new EndpointActions.SetChangePlaceEventSource({
                    source: ChangePlaceEventSourceEnum.ManualMapMove,
                });
            }),
        ),
    );

    $swapEndpoint: Observable<SetActiveEndpoint> = createEffect(() =>
        this.$actions.pipe(
            ofType(EndpointActionTypes.SwapEndpoint),
            withLatestFrom(this.store.select(getActiveEndpoint), (action, endpoint) => endpoint),
            map((endpoint) => {
                this.mapService.deleteLayerUsingName(TRACKS_LAYER);
                this.reverseMapDynamicLayersByEndpoint();
                this.itinerariesService.clearItineraries();
                if (isDeparture(endpoint)) {
                    this.store.dispatch(new EndpointActions.StartChangingDeparture({ presentLoading: false, disabledEndpoint: true }));
                } else {
                    this.store.dispatch(new EndpointActions.StartChangingArrival({ presentLoading: false, disabledEndpoint: true }));
                }
                return new EndpointActions.SetActiveEndpoint(endpoint);
            }),
        ),
    );

    $setActiveEndpoint: Observable<any> = createEffect(
        () =>
            this.$actions.pipe(
                ofType<SetActiveEndpoint>(EndpointActionTypes.SetActiveEndpoint),
                filter(() => this.mapService.hasMap()),
                withLatestFrom(this.store.select(getEndpointState), ({ endpoint }, endpointState) => ({
                    endpoint,
                    endpointState,
                })),
                tap(({ endpoint, endpointState }) => {
                    let area: Area;
                    if (isDeparture(endpoint)) {
                        area = endpointState.departure.area;
                    } else {
                        area = endpointState.arrival.area;
                        this.mapService.deleteLayerUsingName(EDGE_LAYER);
                    }

                    this.mapService.fitBounds(toBounds(area.boundsRect));
                    if (isManualMapMove(area.metadata.source)) {
                        const dynamicLayerName = `DYNAMIC_PLACE_LAYER_${endpointState.activeEndpoint}`;
                        this.mapService.deleteLayerUsingName(dynamicLayerName);
                    }
                    this.mapService.setDisplayingOfTracksLayerBy(createActiveArea(endpoint, area));

                    /**
                     * Ceci est pour lancer manuellement ArrivalChanged ou DepartureChanged
                     * malgré le fait que le fitBounds ne déclenche pas de NewDeparture ou NewArrival car les bounds
                     * sont vide, donc aucun mapMoveEnd -> MapEffect.Moved -> ... -> DepartureChanged.
                     */
                    const isEmptyArea =
                        !AreaHelper.hasPhysicalStops(area) || AreaHelper.containsTooManyCommercialStops(area, environment.company);
                    if (isArrival(endpoint) && isEmptyArea) {
                        this.store.dispatch(new EndpointActions.ArrivalChanged());
                    } else if (isDeparture(endpoint) && isEmptyArea) {
                        this.store.dispatch(new EndpointActions.DepartureChanged());
                    }
                }),
            ),
        { dispatch: false },
    );

    $startChangingArrival = createEffect(
        () =>
            this.$actions.pipe(
                ofType(EndpointActionTypes.StartChangingArrival),
                tap(({ payload: { presentLoading, disabledEndpoint } }) => {
                    if (presentLoading) {
                        this.modalService.presentLoading(RE_ENABLE_ENDPOINT_DELAY);
                    }
                    if (disabledEndpoint) {
                        this.store.dispatch(new EndpointActions.Disable());
                    }
                }),
            ),
        { dispatch: false },
    );

    $startChangingDeparture = createEffect(
        () =>
            this.$actions.pipe(
                ofType(EndpointActionTypes.StartChangingDeparture),
                tap(({ payload: { presentLoading, disabledEndpoint } }) => {
                    if (presentLoading) {
                        this.modalService.presentLoading(RE_ENABLE_ENDPOINT_DELAY);
                    }
                    if (disabledEndpoint) {
                        this.store.dispatch(new EndpointActions.Disable());
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private modalService: ModalService,
        readonly $actions: Actions<EndpointActionsUnion>,
        // typed as never to avoid the temptation of reading directly from the store
        private store: Store<never>,
        private routerStore: Store<RouterState>,
        private mapService: MapService,
        private logger: LoggingService,
        private endpointService: EndpointService,
        private layerBuilder: LayerBuilderService,
        private androidBackButtonService: AndroidBackButtonService,
        private homeMapContainerService: HomeMapContainerService,
        private departureService: DepartureService,
        private itinerariesService: ItineraryService,
    ) {}

    private clearDynamicLayerAndDispatchIgnoreNextChange(area: Area, ignoreNextChange: boolean, endpoint: Endpoint): void {
        try {
            const { source: areaSource } = area.metadata;

            if (isManualMapMove(areaSource)) {
                const endpointLayer = this.layerBuilder.getDynamicLayerNameByEndpoint(endpoint);
                this.mapService.deleteLayerUsingName(endpointLayer);
            }

            this.store.dispatch(
                new EndpointActions.IgnoreNextChange({
                    isIgnored: ignoreNextChange,
                    endpoint,
                }),
            );
        } catch (error) {
            this.logger.logError(
                new TechnicalError('Error while clearing dynamic layer', ErrorCodes.Map.DynamicLayer, convertToError(error)),
            );
        }
    }

    private reverseMapDynamicLayersByEndpoint(): void {
        const departureLayerName: dynamicLayerName = this.layerBuilder.getDynamicLayerNameByEndpoint(Endpoint.Departure);
        const arrivalLayerName: dynamicLayerName = this.layerBuilder.getDynamicLayerNameByEndpoint(Endpoint.Arrival);
        const departureLayer = this.mapService.getLayerUsingName(departureLayerName);
        const arrivalLayer = this.mapService.getLayerUsingName(arrivalLayerName);
        if (departureLayer) {
            this.mapService.setLayer(arrivalLayerName, departureLayer);
        }
        if (arrivalLayer) {
            this.mapService.setLayer(departureLayerName, arrivalLayer);
        }
    }

    private async setBoundsOnStopsNearGps(endpoint: Endpoint): Promise<void> {
        const dataNearGpsResult = await this.mapService.fetchDataNearBoundsOfGpsMarker();
        if (!isSuccess(dataNearGpsResult)) {
            this.logger.logError(
                new TechnicalError(
                    `Can't set endpoint ${endpoint} on stops near gps position`,
                    ErrorCodes.Map.MoveOnGpsPosition,
                    dataNearGpsResult.error,
                ),
            );
            return;
        }
        this.store.dispatch(
            new SetBounds({
                endpoint,
                bounds: toLatLngRect(dataNearGpsResult.value.bounds),
            }),
        );
        this.mapService.fitBounds(dataNearGpsResult.value.bounds);
    }
}
