import { Area, isBookmarkArea, isStopArea, PhysicalStopAdapter, StopArea } from '@traas/boldor/all-models';
import { isAddress, isFollowGps, isMyGpsPosition, isPoi } from './change-place-event-source.helper';
import { LatLng } from 'leaflet';
import * as _ from 'lodash';
import { isNotNullOrUndefined } from '@traas/common/utils';
import { getMaxCommercialSopsToFireSearch } from './journey-search-conditions';

export class AreaHelper {
    /**
     * use company from environment.company
     * @param area
     * @param company
     */
    static containsTooManyCommercialStops(area: Area, company: string): boolean {
        return AreaHelper.getUniqCommercialStopIds(area).length > getMaxCommercialSopsToFireSearch(company);
    }

    static getPhysicalStopsDistinctByName(area: Area): PhysicalStopAdapter[] {
        if (isStopArea(area)) {
            const physicalStopAdapters = area.physicalStops.map((stop) => new PhysicalStopAdapter(stop));
            return _.uniqBy(physicalStopAdapters, (adapter) => adapter.getCommercialStopName());
        }
        console.log(`Can't getPhysicalStopsDistinctByName on area type different than StopArea`);
        return [];
    }

    /**
     * use company from environment.company
     * @param area
     * @param company
     */
    static isSuitableForSearch(area: Area | null, company: string): boolean {
        if (!area || AreaHelper.containsTooManyCommercialStops(area, company)) {
            return false;
        }

        const canSearchByStops = this.hasPhysicalStops(area);
        const canSearchByLatLng = this.isLatLngPlace(area) && !!area.metadata.latitude && !!area.metadata.longitude;
        return canSearchByLatLng || canSearchByStops;
    }

    static hasLatLng(area: Area): boolean {
        const { latitude, longitude } = area.metadata;
        return !!(latitude && longitude);
    }

    static getLatLng(area: Area): LatLng {
        const { latitude, longitude } = area.metadata;
        return new LatLng(Number.parseFloat(latitude), Number.parseFloat(longitude));
    }

    static hasPhysicalStops(area: Area): boolean {
        if (isBookmarkArea(area)) {
            return _.uniq(area.originalBookmark.stops.id).length > 0;
        }
        if (isStopArea(area)) {
            return (area as StopArea).physicalStops.length > 0;
        }
        return false;
    }

    static equals(a: Area, b: Area): boolean {
        const commercialStopsA = this.getUniqCommercialStopIds(a);
        const commercialStopsB = this.getUniqCommercialStopIds(b);
        const atLeastOneOfThemHaveCommercialStops = commercialStopsA.length > 0 || commercialStopsB.length > 0;
        if (atLeastOneOfThemHaveCommercialStops) {
            return _.isEqual(commercialStopsA, commercialStopsB);
        }

        const didokA = this.getUniqStopsDidok(a);
        const didokB = this.getUniqStopsDidok(b);
        const atLeastOneOfThemHaveDidok = didokA.length > 0 || didokB.length > 0;
        if (atLeastOneOfThemHaveDidok) {
            return _.isEqual(didokA, didokB);
        }

        const bothAreLatLngPlaceType = this.isLatLngPlace(a) && this.isLatLngPlace(b);
        if (bothAreLatLngPlaceType) {
            return this.isSameLatLngPlace(a, b);
        }

        return a.metadata.source === b.metadata.source;
    }

    /**
     * Return uniq commercial stops id for StopArea, and uniq StopId for BookmarkArea.
     * Else empty array.
     */
    static getUniqCommercialStopIds(area: Area): string[] {
        if (isBookmarkArea(area)) {
            return _.uniq(area.originalBookmark.stops.id);
        } else if (isStopArea(area)) {
            return _.uniq(area.physicalStops.map((stop) => stop.associatedCommercialStop?.id).filter(isNotNullOrUndefined));
        } else return [];
    }

    static getUniqStopsDidok(area: Area): number[] {
        if (isBookmarkArea(area)) {
            return _.uniq(area.originalBookmark.stops.didok);
        } else if (isStopArea(area)) {
            return _.uniq(area.physicalStops.map((stop) => stop.associatedCommercialStop?.didok).filter(isNotNullOrUndefined));
        } else return [];
    }

    static isSameLatLngPlace(a: Area, b: Area): boolean {
        return AreaHelper.hasLatLng(a) && AreaHelper.hasLatLng(b) && AreaHelper.getLatLng(a).equals(AreaHelper.getLatLng(b));
    }

    static isLatLngPlace({ metadata }: Area): boolean {
        const source = metadata.source;
        return isPoi(source) || isAddress(source) || isMyGpsPosition(source) || isFollowGps(source);
    }
}
