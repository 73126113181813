import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'payment-status',
    templateUrl: './payment-status.component.html',
    styleUrls: ['./payment-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentStatusComponent {
    @Input() showError!: boolean;
    @Input() isRunningTooLong!: boolean;
    @Input() statusTitle!: string;
    @Input() statusDescription!: string;

    @Input() progressionPercent!: number;
    @Input() isOffline!: boolean;
}
