import { NgModule } from '@angular/core';
import { EndpointContainerComponent } from './containers/endpoint-container/endpoint-container.component';
import { MapModule } from '../map/map.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';

@NgModule({
    imports: [BoldorFeatureShellSharedModule, MapModule],
    declarations: [EndpointContainerComponent],
    exports: [EndpointContainerComponent],
})
export class EndpointModule {}
