/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import { Itinerary, LeaveOrArriveEnum } from '@traas/boldor/all-models';
import { ItineraryArticleService } from '../services/itinerary-article.service';
import { ItineraryService } from '../services/itinerary.service';
import {
    ClearItineraries,
    ItineraryActionTypes,
    Load,
    LoadedMore,
    LoadedPrevious,
    LoadFail,
    Loading,
    OpenDetails,
    Refresh,
    Reload,
    ReplaceItineraries,
    ResetEnabledTransportModes,
    SetEnabledTransportModes,
} from './itinerary.action';
import { ItineraryState } from './itinerary.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyService } from '../../../services/common/company/company.service';
import { convertToError, getErrorMessageToDisplay, LoggingService, logOptionsFactory } from '@traas/common/logging';
import { RoutingService } from '@traas/common/routing';
import { ToasterService } from '../../../services/common/toaster/toaster.service';
import { AreaHelper, SyntheseDateHelper } from '@traas/boldor/all-helpers';
import { ItineraryAdapter } from '../../../models/itinerary/itinerary';
import * as moment from 'moment';
import { from, Observable, of, throwError, zip } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BoldorLocalizationService } from '@traas/common/localization';
import { createGenericAdultTraveler } from '../../../models/traveler';
import { DepartureActionTypes } from '../../departure/store/departure.action';
import { ItineraryFiltersService } from '../services/itinerary-filters.service';
import { EndpointEffect } from '../../home/store/endpoint/endpoint.effect';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

const UNKNOWN_ERROR = 0;

@Injectable()
export class ItineraryEffect {
    $load = createEffect(() =>
        this.$actions.pipe(
            ofType<Load>(ItineraryActionTypes.Load),
            exhaustMap((action: Load) => {
                return this.loadMore(action).pipe(
                    takeUntil(this.endpointEffect.$clearItinerariesOnUnsuitableEndpoints),
                    catchError((error) => {
                        this.logger.logLocalError(error, logOptionsFactory('warning'));
                        return of(new LoadFail());
                    }),
                );
            }),
        ),
    );

    $reload: Observable<Load> = createEffect(() =>
        this.$actions.pipe(
            ofType<Reload>(ItineraryActionTypes.Reload),
            map((action: Reload) => new Load(action.payload)),
        ),
    );

    $refresh: Observable<Load> = createEffect(() =>
        this.$actions.pipe(
            ofType<Refresh>(ItineraryActionTypes.Refresh),
            map((action: Refresh) => new Load({ ...action.payload, isRefresh: true })),
        ),
    );

    $openDetails = createEffect(
        () =>
            this.$actions.pipe(
                ofType<OpenDetails>(ItineraryActionTypes.OpenDetails),
                tap(() => {
                    this.routingService.navigateToItineraryDetails().catch(() => ({}));
                }),
            ),
        { dispatch: false },
    );

    $loadFail = createEffect(
        () =>
            this.$actions.pipe(
                ofType<LoadFail>(DepartureActionTypes.LoadFail),
                filter(({ payload }) => !!payload && payload.showMessage),
                switchMap(({ payload }) => this.toasterService.presentGenericFailure(payload.message)),
            ),
        { dispatch: false },
    );

    $resetEnabledTransportModes = createEffect(() =>
        this.$actions.pipe(
            ofType<ResetEnabledTransportModes>(ItineraryActionTypes.ResetEnabledTransportModes),
            switchMap(() => this.itineraryFiltersService.getTransportModesFromStorage()),
            map((transportModes) => new SetEnabledTransportModes(transportModes)),
        ),
    );

    constructor(
        private $actions: Actions,
        private store: Store<ItineraryState>,
        private logger: LoggingService,
        private routingService: RoutingService,
        private toasterService: ToasterService,
        private itineraryService: ItineraryService,
        private itineraryArticleService: ItineraryArticleService,
        private boldorLocalizationService: BoldorLocalizationService,
        private itineraryFiltersService: ItineraryFiltersService,
        private endpointEffect: EndpointEffect,
    ) {}

    private loadMore({ payload }: Load): Observable<LoadedMore | LoadedPrevious | LoadFail | ClearItineraries> {
        const endpoints = payload.itineraryAreas;

        if (!endpoints?.departure || !endpoints?.arrival) {
            return throwError('Endpoints must be filled to search itineraries.');
        }

        if (AreaHelper.equals(endpoints.departure, endpoints.arrival)) {
            return throwError('Endpoints must be different to search itineraries.');
        }

        const departure = endpoints.departure ? endpoints.departure : null;
        const arrival = endpoints.arrival ? endpoints.arrival : null;
        const dateMinus1Minute = moment(payload.date).subtract(1, 'minutes').toDate();

        const { transportModes, itinerariesCount, way } = payload;

        const ha = payload.highestDepartureTime ? SyntheseDateHelper.getSyntheseDatePlusOneSecond(payload.highestDepartureTime) : null;

        const itinerariesFetchingParameters = this.itineraryService.createItinerariesFetchingParameters(
            departure,
            arrival,
            SyntheseDateHelper.getSyntheseDatePlusOneSecond(dateMinus1Minute),
            itinerariesCount,
            way,
            transportModes,
            ha,
        );
        const loadingWay = itinerariesFetchingParameters.way;
        this.store.dispatch(new Loading());

        const $gqlItineraries = from(this.itineraryService.getItinerariesBy(itinerariesFetchingParameters));
        return $gqlItineraries.pipe(
            map((itineraries) => {
                const rawData = itineraries.map((itinerary) => itinerary.getData());
                // Returning actions
                switch (loadingWay) {
                    case LeaveOrArriveEnum.LeaveAt:
                        return new LoadedMore({ itineraries: rawData });
                    case LeaveOrArriveEnum.ArriveBy:
                        return new LoadedPrevious({ itineraries: rawData });
                    default:
                        throw new Error('Unknown loading way, should be QueryKing.Past or QueryKind.Future');
                }
            }),
            tap((action) => {
                if (action.type === ItineraryActionTypes.LoadedMore || action.type === ItineraryActionTypes.LoadedPrevious) {
                    if (!payload?.isRefresh && CompanyService.isTraas() && !!action.payload?.itineraries) {
                        void this.setHasSupersaverOffersOnItineraries(action.payload?.itineraries);
                    }
                }
            }),
            takeUntil(this.$actions.pipe(ofType<Reload>(ItineraryActionTypes.Reload))),
            catchError((error) => {
                this.logger.logError(
                    new TechnicalError('Error on loading more itineraries', ErrorCodes.Itinerary.LoadMore, error, {
                        itinerariesFetchingParameters,
                    }),
                );

                /**
                 * This case will happens when user lock his screen during a pending request and unlock it.
                 * There is no straightforward way to detect the user query cancellation.
                 */
                const isRequestCancelled = error?.networkError?.status === UNKNOWN_ERROR;
                if (isRequestCancelled) {
                    return of(new LoadFail());
                }
                return zip(
                    this.boldorLocalizationService.get('itineraries.load-fail'),
                    this.boldorLocalizationService.get('error-message.unknown'),
                ).pipe(
                    map(
                        ([loadFail, retryMessage]) =>
                            new LoadFail({ message: getErrorMessageToDisplay(retryMessage, error, loadFail), showMessage: true }),
                    ),
                );
            }),
        );
    }

    private async setHasSupersaverOffersOnItineraries(itineraries: Itinerary[]): Promise<void> {
        const traveler = createGenericAdultTraveler();
        const itinerariesUpdatedPromise = itineraries
            .filter((itinerary) => !new ItineraryAdapter(itinerary).isOutdated())
            .map(async (itinerary) => {
                try {
                    const offers = await this.itineraryArticleService.generateSupersaverItineraryArticles(itinerary, traveler, []);
                    return {
                        ...itinerary,
                        hasSupersaverOffers: offers.some(({ isSupersaver }) => isSupersaver),
                    };
                } catch (error) {
                    this.logger.logError(
                        new TechnicalError(
                            'Error while generating supersaver itinerary articles',
                            ErrorCodes.Itinerary.FetchSupersaverArticles,
                            convertToError(error),
                        ),
                    );
                    return Promise.resolve(null);
                }
            });
        const itinerariesUpdated = (await Promise.all(itinerariesUpdatedPromise)).filter((item) => !!item) as Itinerary[];
        this.store.dispatch(new ReplaceItineraries({ itineraries: itinerariesUpdated }));
    }
}
