import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TransitStopItemComponent } from './transit-stop-item.component';
import { WarningIconModule } from '../warning-icon/warning-icon.module';
import { SharedPipeModule } from '../../pipes/shared-pipe.module';
import { TrackIconModule } from '../track-icon/track-icon.module';
import { CancelledTripIconComponent } from '../cancelled-trip-icon/cancelled-trip-icon.component';

@NgModule({
    imports: [CommonModule, IonicModule, TrackIconModule, SharedPipeModule, WarningIconModule, CancelledTripIconComponent],
    declarations: [TransitStopItemComponent],
    exports: [TransitStopItemComponent],
})
export class TransitStopItemModule {}
