import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CreditCardsEffect } from './credit-cards-effect.service';
import { creditCardsReducer } from './credit-cards.reducer';

@NgModule({
    imports: [StoreModule.forFeature('credit-cards', creditCardsReducer), EffectsModule.forFeature([CreditCardsEffect])],
    providers: [CreditCardsEffect],
})
export class CreditCardsStoreModule {}
