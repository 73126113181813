import { Traveler } from '@traas/boldor/all-models';
import { PassengerInput } from '@traas/boldor/graphql-generated/graphql';

export function createPassengerInputFrom(traveler: Traveler): PassengerInput {
    return {
        firstName: traveler.firstName,
        birthDate: traveler.birthDate,
        lastName: traveler.lastName,
        hasHalfFare: traveler.hasHalfFare,
        title: traveler.title,
        id: traveler.id,
        email: traveler.email,
    };
}
