import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HomeStoreEffects } from './home.effects';
import { HomeStoreReducers } from './home.reducer';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';

@NgModule({
    declarations: [],
    imports: [StoreModule.forFeature('home', HomeStoreReducers), EffectsModule.forFeature(HomeStoreEffects)],
    providers: [HomeStoreEffects, SMS],
})
export class HomeStoreModule {}
