import { Departure as GqlDeparture } from '@traas/boldor/graphql-generated/graphql';
import { Departure, JourneyEnum } from '@traas/boldor/all-models';

export class GqlToFrontDepartureConverter {
    static toDeparture(departure: GqlDeparture): Departure {
        const {
            stop,
            stop: { physicalStop },
            stop: {
                physicalStop: { associatedCommercialStop },
            },
        } = departure;
        return {
            departureTimestamp: departure.departureTimestamp,
            plannedDepartureTime: departure.plannedDepartureTime,
            direction: departure.direction,
            directionCity: null,
            directionStopName: null,
            line: {
                number: departure.line.number,
                networkId: departure.line.networkId,
                lineOfficialCode: departure.line.lineOfficialCode,
                id: departure.line.id,
                fontColor: departure.line.fontColor,
                destination: departure.line.destination,
                color: departure.line.color,
                style: departure.line.style,
            },
            messages: [],
            reservation: false, // @deprecated, to remove
            serviceId: departure.serviceId,
            stop: {
                messages: [],
                cityName: stop.cityName,
                name: stop.name,
                id: stop.id,
                rank: stop.rank,
                isOptional: stop.isOptional,
                physicalStop: {
                    letter: physicalStop.letter,
                    geometry: physicalStop.geometry,
                    id: physicalStop.id,
                    lines: [],
                    transportMode: physicalStop.transportMode,
                    associatedCommercialStop: {
                        id: departure.stop.id,
                        name: associatedCommercialStop.name,
                        cityName: associatedCommercialStop.cityName,
                        coordinates: {
                            accuracy: null,
                            latitude: null,
                            longitude: null,
                            ...associatedCommercialStop.coordinates,
                        },
                        lines: [],
                        didok: associatedCommercialStop.didok,
                    },
                },
            },
            track: departure.track,
            transport: departure.transport,
            networkId: departure.networkId,
            hasStopRequest: departure.hasStopRequest,
            id: departure.id,
            bookingDeadline: departure.bookingDeadline,
            remainingTimeBeforeBooking: departure.remainingTimeBeforeBooking,
            hasBookingRequirements: departure.hasBookingRequirements,
            isBookable: departure.isBookable,
            isCancelled: departure.isCancelled,
            outdatedDate: departure.outdatedDate ? new Date(departure.outdatedDate) : undefined,
            __type__: JourneyEnum.Departure,
        };
    }
}
