import { AbstractAdapter, createHumanReadableStop, Place, PlaceStop, PlacesTypeInterface } from '@traas/boldor/all-models';
import { StopNamePipe } from '../../../pipes/stop-name.pipe';
import { LatLng } from 'leaflet';
import { ICON_BASE_PATH } from '../../../business-rules.utils';

export class StopAdapter extends AbstractAdapter<PlaceStop> implements Place<PlaceStop> {
    isFromHistory: boolean;

    constructor(data: PlaceStop, cityName?: string) {
        super(data);
        if (cityName) {
            this.data.cityName = cityName;
        }
    }

    createStopPlacesHistory(): this {
        this.isFromHistory = true;
        this.getNameOrPath = (): string => 'time';
        return this;
    }

    getName(): string {
        const { cityName, name, id } = this.getData();
        const humanReadableStop = createHumanReadableStop({
            cityName,
            name,
            id,
        });
        return StopNamePipe.transformValue(humanReadableStop);
    }

    transformToCoordinates(): GeolocationCoordinates {
        return {
            accuracy: null,
            latitude: this.getLatitude(),
            longitude: this.getLongitude(),
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            speed: null,
        };
    }

    getLatLng(): LatLng {
        return new LatLng(this.getLatitude(), this.getLongitude());
    }

    getLatitude(): number {
        return this.getData().latLon[0];
    }

    getLongitude(): number {
        return this.getData().latLon[1];
    }

    getNameOrPath(): string {
        return `${ICON_BASE_PATH}/endpoint/stop.svg`;
    }

    isGpsPosition(): boolean {
        return false;
    }

    getPlaceType(): keyof PlacesTypeInterface {
        return 'stop';
    }

    getId(): string {
        return this.getData().id;
    }
}
