<ion-row>
    <div class="departure">
        <div class="departure-first-col">
            <div *ngIf="stopIconDetailName === null" class="departure-cross"></div>
            <ion-col class="booking-icon">
                <div class="previous-line-path" [ngClass]="linePathStyle" [style.background-color]="linePathBackgroundColor"></div>

                <ion-icon [class.tpg]="isTPG" [src]="thermoStopIconName"></ion-icon>

                <div
                    class="next-line-path"
                    *ngIf="!isLast"
                    [ngClass]="linePathStyle"
                    [style.background-color]="linePathBackgroundColor"
                ></div>
            </ion-col>
        </div>

        <app-warning-icon
            class="departure-warning"
            (click)="openWarningModal($event)"
            *ngIf="transitStopViewModel.hasDisruption"
        ></app-warning-icon>

        <div class="departure-col" [class.no-margin]="isTPG">
            <div class="stop-name-container">
                <div class="stop-name">
                    {{ transitStopViewModel.stop | stopName }}
                </div>
                <ng-container *ngIf="transitStopViewModel.isCancelled">
                    <div class="cancelled-message-container" *ngFor="let message of transitStopViewModel.cancellationMessages">
                        <traas-cancelled-trip-icon [isBus]="false"></traas-cancelled-trip-icon>
                        <span class="cancelled-message" [innerHTML]="message.htmlContent"></span>
                    </div>
                </ng-container>
            </div>
            <div class="departure-right">
                <div class="departure-time" [class.cancelled]="transitStopViewModel.isCancelled">
                    <span>{{ transitStopViewModel.arrivalTime }}</span>
                    <span class="delay" *ngIf="!transitStopViewModel.isCancelled">{{ transitStopViewModel.arrivalDelay }}</span>
                </div>

                <div class="departure-options">
                    <ion-icon
                        *ngIf="!transitStopViewModel.isCancelled && isTPC && stopIconDetailName"
                        (click)="stopInformationClicked()"
                        class="on-demand e2e-stop-information-clickable"
                        [src]="stopIconDetailName"
                    ></ion-icon>
                </div>

                <div
                    class="departure-track"
                    (click)="arrowClicked()"
                    [class.hide]="!activateForwardArrow"
                    [class.canceled]="transitStopViewModel.isCancelled"
                >
                    <ion-icon name="arrow-forward"></ion-icon>
                </div>
            </div>
        </div>
    </div>
</ion-row>
