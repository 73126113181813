import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createBookmarkArea, Endpoint, InvalidPlaceError, isArrival, SearchModeOptions } from '@traas/boldor/all-models';
import { HomeService } from '../../services/home.service';
import { HomeStoreState } from './..';
import { EndpointActions, EndpointSelectors } from '../endpoint';
import { MapActions } from '../map';
import { SearchPlaceActions } from './index';
import {
    AddBookmark,
    BookmarkModification,
    BookmarkRemoved,
    PickPlace,
    PickPlaceFail,
    PickPlaceSuccess,
    RemoveBookmark,
    ResetBookmarks,
    SearchPlaceActionTypes,
} from './searchPlace.action';
import { AlertService } from '../../../../services/common/alert.service';
import { BookmarkService } from '../../../../services/common/bookmark/bookmark.service';
import { ModalService } from '../../../../services/common/modal.service';
import { ToasterService } from '../../../../services/common/toaster/toaster.service';
import { GridStoreActions, GridStoreSelectors } from '../../../../components/grid/store';
import { ChangeSearchMode } from '../../../../components/grid/store/grid.actions';
import { AnalyticsService } from '@traas/common/analytics';
import { convertToError, getErrorMessageToDisplay, LoggingService } from '@traas/common/logging';
import { BoldorLocalizationService } from '@traas/common/localization';
import { firstValueFrom, Observable } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

@Injectable()
export class SearchPlaceEffect {
    $removeBookmark: Observable<BookmarkRemoved> = createEffect(() =>
        this.$actions.pipe(
            ofType<RemoveBookmark>(SearchPlaceActionTypes.RemoveBookmark),
            switchMap(async ({ payload }: RemoveBookmark) => {
                const confirmationHeader = await this.boldorLocalizationService.get('home-grid.confirm-title');
                const removeFavoriteConfirm = await this.boldorLocalizationService.get('home-grid.remove-favorite-confirm');
                const remove = await this.alertService.confirmationAlert(confirmationHeader, removeFavoriteConfirm);
                if (remove) {
                    await this.bookmarkService.removeBookmark(payload.bookmark);
                    this.analyticsService.reportEvent('map__remove_favorite', {
                        stop_name: payload.bookmark.metadata.locationName.trim(),
                    });
                    return new BookmarkRemoved(payload);
                }
                return new BookmarkRemoved(null);
            }),
        ),
    );

    $bookmarkRemoved: Observable<{
        readonly isInEditionOfAll?: any;
        readonly action?: any;
    }> = createEffect(
        () =>
            this.$actions.pipe(
                ofType<BookmarkRemoved>(SearchPlaceActionTypes.BookmarkRemoved),
                switchMap((action: BookmarkRemoved) => {
                    return this.store.select(GridStoreSelectors.getIsInEditionOfAll).pipe(
                        map((isInEditionOfAll: boolean) => {
                            return {
                                action,
                                isInEditionOfAll,
                            };
                        }),
                    );
                }),
                tap(({ action, isInEditionOfAll }) => {
                    if (action.payload && !isInEditionOfAll) {
                        this.store.dispatch(new GridStoreActions.EditionOut());
                    }
                }),
            ),
        { dispatch: false },
    );

    $resetBookmark: Observable<unknown> = createEffect(
        () =>
            this.$actions.pipe(
                ofType<ResetBookmarks>(SearchPlaceActionTypes.ResetBookmarks),
                tap(async () => {
                    await this.bookmarkService.clearBookmarks();
                }),
            ),
        { dispatch: false },
    );

    $addBookmark: Observable<RemoveBookmark | BookmarkModification> = createEffect(() =>
        this.$actions.pipe(
            ofType<AddBookmark>(SearchPlaceActionTypes.AddBookmark),
            switchMap(async ({ payload: { newBookmark } }: AddBookmark) => {
                const area = createBookmarkArea(newBookmark, newBookmark.metadata, newBookmark.boundsRect);
                if (await this.bookmarkService.hasBookmarkInStorage(area)) {
                    return new RemoveBookmark({ bookmark: newBookmark });
                }
                await this.bookmarkService.addBookmarkToStorage(newBookmark);
                this.analyticsService.reportEvent('map__add_favorite', {
                    stop_name: newBookmark.metadata.locationName.trim(),
                });
                return new BookmarkModification();
            }),
        ),
    );

    $pickPlace: Observable<PickPlaceSuccess | PickPlaceFail> = createEffect(() =>
        this.$actions.pipe(
            ofType<PickPlace>(SearchPlaceActionTypes.PickPlace),
            switchMap(async (action) => {
                try {
                    const activeEndpoint = await firstValueFrom(this.store.select(EndpointSelectors.getActiveEndpointInformation));

                    this.store.dispatch(new MapActions.Disable());

                    await this.homeService.propagatePlace(action.payload.place, activeEndpoint.endpointValue);

                    return new SearchPlaceActions.PickPlaceSuccess(activeEndpoint.endpointValue);
                } catch (error) {
                    if (error instanceof InvalidPlaceError) {
                        this.logger.logError(new TechnicalError('Invalid place', ErrorCodes.SearchPlace.PickPlace, error));
                        return new SearchPlaceActions.PickPlaceFail(error.messages?.fr ?? '');
                    }
                    this.logger.logError(
                        new TechnicalError('Error while picking place', ErrorCodes.SearchPlace.PickPlace, convertToError(error)),
                    );
                    const serverUnreachable = await this.boldorLocalizationService.get('error-message.server-unreachable');
                    const retryMessage = await this.boldorLocalizationService.get('error-message.unknown');
                    const errorMessage = getErrorMessageToDisplay(retryMessage, error, serverUnreachable);
                    return new SearchPlaceActions.PickPlaceFail(errorMessage ?? '');
                }
            }),
        ),
    );

    $pickPlaceSuccess: Observable<ChangeSearchMode> = createEffect(() =>
        this.$actions.pipe(
            ofType<PickPlaceSuccess>(SearchPlaceActionTypes.PickPlaceSuccess),
            map(({ endpoint }) => {
                if (isArrival(endpoint)) {
                    this.store.dispatch(new EndpointActions.StartChangingDeparture());
                    this.store.dispatch(new EndpointActions.SetActiveEndpoint(Endpoint.Departure));
                }
                return new GridStoreActions.ChangeSearchMode({ mode: SearchModeOptions.MAP });
            }),
        ),
    );

    $pickPlaceFail = createEffect(
        () =>
            this.$actions.pipe(
                ofType<PickPlaceFail>(SearchPlaceActionTypes.PickPlaceFail),
                tap(async ({ payload }) => {
                    this.store.dispatch(new EndpointActions.Enable());
                    await this.modalService.hideLoading();
                    await this.toastrService.presentGenericWarning(payload);
                }),
            ),
        { dispatch: false },
    );

    $pickPlaceCanceled = createEffect(
        () =>
            this.$actions.pipe(
                ofType(SearchPlaceActionTypes.PickPlaceCanceled),
                withLatestFrom(
                    this.store.select(EndpointSelectors.getActiveEndpointInformation),
                    (action, activeEndpoint) => activeEndpoint,
                ),
                tap((activeEndpoint) => {
                    if (isArrival(activeEndpoint.endpointValue) && activeEndpoint.area.metadata.locationName) {
                        this.store.dispatch(new EndpointActions.StartChangingDeparture());
                        this.store.dispatch(new EndpointActions.SetActiveEndpoint(Endpoint.Departure));
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private $actions: Actions,
        private store: Store<HomeStoreState.HomeState>,
        private homeService: HomeService,
        private bookmarkService: BookmarkService,
        private alertService: AlertService,
        private modalService: ModalService,
        private logger: LoggingService,
        private toastrService: ToasterService,
        private analyticsService: AnalyticsService,
        private boldorLocalizationService: BoldorLocalizationService,
    ) {}
}
