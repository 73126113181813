import { Injectable } from '@angular/core';
import { rgbToHexColor } from '@traas/boldor/all-helpers';
import { LineStyle, LineStyleStorage } from '@traas/boldor/all-models';
import { Wayback } from '@traas/boldor/graphql-generated/graphql';
import { GetLinesStylesGQL } from '@traas/boldor/graphql/generated/tpc/graphql';
import { LoggingService } from '@traas/common/logging';
import { ObservableTypedStorage } from '@traas/common/utils';
import { firstValueFrom } from 'rxjs';
import { ErrorCodes, Line, TechnicalError } from '@traas/common/models';

@Injectable({
    providedIn: 'root',
})
export class LineStylesService {
    constructor(
        private lineStylesStorage: ObservableTypedStorage<LineStyleStorage>,
        private getLinesStylesGQL: GetLinesStylesGQL,
        private logger: LoggingService,
    ) {}

    async initLineStyles(): Promise<void> {
        try {
            const lineStyles = await firstValueFrom(this.getLinesStylesGQL.fetch());
            await this.setLineStyles(lineStyles?.data?.linesStyles ?? []);
        } catch (err) {
            this.logger.logLocalError(err, { level: 'warning' });
        }
    }

    async getLineStyleById(searchId: string): Promise<LineStyle | undefined> {
        const linesStyle = await this.getLineStyles();
        return linesStyle.find(({ id }) => id === searchId);
    }

    async getLinesStyleByNetwork(searchNetworkId: string): Promise<LineStyle[]> {
        const linesStyle = await this.getLineStyles();
        return linesStyle.filter(({ networkId }) => networkId === searchNetworkId);
    }

    createLineFrom(lineStyle: LineStyle, lineId: string, destination: string | undefined, wayback?: Wayback | undefined): Line {
        const color = rgbToHexColor(lineStyle.color, lineId);
        const fontColor = rgbToHexColor(lineStyle.textColor, lineId);
        if (color.error) {
            this.logger.logError(new TechnicalError('Error while converting color', ErrorCodes.Line.CreateLineStyle, color.error));
        }
        if (fontColor.error) {
            this.logger.logError(new TechnicalError('Error while converting font color', ErrorCodes.Line.CreateLineStyle, fontColor.error));
        }
        return {
            id: lineId,
            lineOfficialCode: lineStyle.lineOfficialCode ?? '',
            destination,
            number: lineStyle.name ?? '',
            color: color.value,
            fontColor: fontColor.value,
            networkId: lineStyle.networkId,
            style: lineStyle.style ?? '',
            wayback,
        };
    }

    private async setLineStyles(lineStyles: LineStyle[]): Promise<void> {
        await this.lineStylesStorage.setItem('linesStyles', lineStyles);
    }

    private getLineStyles(): Promise<LineStyle[]> {
        return this.lineStylesStorage.getItem('linesStyles', []);
    }
}
