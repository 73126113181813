import { LatLng } from 'leaflet';
import { createLatLngFromStopGeometry } from './leaflet.helper';
import { PhysicalStop } from '@traas/common/models';

/**
 * This helper file will export more pure functions. This pure functions are here to reduce coupling on PhysicalStopAdapter.
 */

export function getLatLng(physicalStop: PhysicalStop, defaultPlace: LatLng): LatLng {
    try {
        return createLatLngFromStopGeometry(physicalStop.geometry);
    } catch (error) {
        return defaultPlace;
    }
}

export function getCityName(physicalStop: PhysicalStop): string {
    if (physicalStop.associatedCommercialStop) {
        return physicalStop.associatedCommercialStop?.cityName ?? '';
    }
    return '';
}

export function getName(physicalStop: PhysicalStop): string {
    if (physicalStop.associatedCommercialStop) {
        return physicalStop.associatedCommercialStop?.name ?? '';
    }
    return '';
}

export function getTrack(physicalStop: PhysicalStop): string {
    return physicalStop.letter ?? '';
}
