<div class="content" [hidden]="hideContent">
    <ng-container *ngIf="$isLoading | async; else list">
        <div class="spinner-container">
            <ion-spinner></ion-spinner>
        </div>
    </ng-container>
    <ng-template #list>
        <ion-list class="content-list" lines="none">
            <ion-item class="content-list-item content-heading">
                <ion-label class="content-label">
                    <ion-toggle
                        (ionChange)="selectAllTransportModesToggleChange($event)"
                        [checked]="allTransportModesAreChecked"
                        mode="ios"
                    ></ion-toggle>
                    <span class="content-text">{{ 'common.select-all' | translate }}</span>
                </ion-label>
            </ion-item>
            <div class="content-container">
                <ion-item
                    *ngFor="let transportMode of transportModes"
                    class="content-list-item content-filter e2e-transport-mode-filter-line"
                >
                    <ion-checkbox
                        (ionChange)="transportModeClicked($event, transportMode)"
                        [checked]="isTransportModeChecked(transportMode)"
                        slot="start"
                        class="e2e-tpf-checkbox"
                    ></ion-checkbox>

                    <ion-label class="content-label">
                        <ion-icon src="{{ getTransportModeIcon(transportMode) }}"></ion-icon>
                        <span class="content-text" class="e2e-tpf-name">{{ transportMode.name }}</span>
                    </ion-label>
                </ion-item>
            </div>
            <div class="content-footer">
                <ion-button (click)="cancelButtonClicked()" class="e2e-transport-mode-filters-cancel"
                    >{{ 'common.cancel' | translate }}
                </ion-button>
                <ion-button
                    (click)="submitFiltersButtonClicked()"
                    class="e2e-transport-mode-filters-submit"
                    [disabled]="areAllTransportModesUnchecked()"
                    >{{ 'transport-mode-filter-popover.filter-button' | translate }}
                </ion-button>
            </div>
        </ion-list>
    </ng-template>
</div>
