<ion-row [class.cancelled]="stop.isCancelled()">
    <div class="itinerary">
        <div
            *ngIf="!isLast"
            [ngClass]="lineStyle.style"
            [style.background-color]="lineStyle.style ? null : lineStyle.backgroundColor"
            class="next-line-path"
        ></div>

        <div class="itinerary-infos">
            <div class="line-track">
                <app-track-icon *ngIf="isFirst || isLast || !!track" [track]="track"></app-track-icon>
            </div>

            <div class="booking-icon">
                <div
                    *ngIf="!isFirst"
                    [class.is-last]="isLast"
                    [class.tpg]="!isTPC"
                    [ngClass]="lineStyle.style"
                    [style.background-color]="lineStyle.style ? null : lineStyle.backgroundColor"
                    class="previous-line-path"
                ></div>

                <ion-icon [class.is-first]="isFirst" [class.is-last]="isLast" [class.tpg]="!isTPC" [src]="thermoStopIconPath"></ion-icon>
            </div>

            <app-warning-icon *ngIf="hasDisruption" (click)="presentWarningMessage($event)"></app-warning-icon>

            <div class="stop-name">
                {{ stop | stopName }}
            </div>
        </div>
        <div class="departure-time">
            <div class="itinerary-options" *ngIf="isTPC">
                <ion-icon [src]="stopIconPathItinerary"></ion-icon>
            </div>

            <ng-container *ngIf="isFirst && departureDatetime; else showArrivalTime">
                <span>
                    {{ departureDatetime | date : 'HH:mm' }}
                </span>
                <span class="delay">{{ stop.getDepartureDelay() }}</span>
            </ng-container>

            <ng-template #showArrivalTime>
                <ng-container *ngIf="arrivalDatetime">
                    <span>
                        {{ arrivalDatetime | date : 'HH:mm' }}
                    </span>
                    <span class="delay">{{ stop.getArrivalDelay() }}</span>
                </ng-container>
            </ng-template>
        </div>
        <ng-container *ngIf="stop.isCancelled()">
            <div class="cancelled-message-container">
                <div class="stop-name cancelled-message">
                    <traas-cancelled-trip-icon [isBus]="false"></traas-cancelled-trip-icon>
                    <span *ngIf="!!cancellationMessage" [innerHTML]="cancellationMessage?.htmlContent"></span>
                </div>
            </div>
        </ng-container>
    </div>
</ion-row>
