import { inject, Injectable } from '@angular/core';
import { Departure, Itinerary, ItineraryStop, JourneyMessage, JourneyMessageType, Leg, TransitStop } from '@traas/boldor/all-models';
import { BoldorLocalizationService } from '@traas/common/localization';
import { first, last, uniqBy } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class JourneyMessageService {
    private localizationService = inject(BoldorLocalizationService);

    async getFormattedDisruptionMessagesOfItinerary(itinerary: Itinerary): Promise<JourneyMessage[]> {
        const promises = itinerary.legs.filter((leg) => !leg.byFoot).map((leg) => this.collectDisruptionMessagesForLeg(leg));
        const results = await Promise.all(promises);
        const messages = results.flat();

        return this.uniqueMessages(messages);
    }

    async containsDisruptionMessagesInItinerary(itinerary: Itinerary): Promise<boolean> {
        const messages = await this.getFormattedDisruptionMessagesOfItinerary(itinerary);
        return messages.length > 0;
    }

    getFormattedCancellationMessageOfLeg(leg: Leg): JourneyMessage | null {
        return this.getCancellationMessagesIn(leg);
    }

    async getFormattedDisruptionMessagesOfDeparture(departure: Departure): Promise<JourneyMessage[]> {
        const lineLocalized = await this.localizationService.get('journey-message.line');
        const messages = [
            ...this.formatDisruptionMessages(departure, `${lineLocalized} ${departure.line.number}`),
            ...this.formatDisruptionMessages(departure.stop, departure.stop.name),
        ];

        return this.uniqueMessages(messages);
    }

    async containsDisruptionMessagesInDeparture(departure: Departure): Promise<boolean> {
        const messages = await this.getFormattedDisruptionMessagesOfDeparture(departure);
        return messages.length > 0;
    }

    getFormattedDisruptionMessagesOfTransitStop(transitStop: TransitStop): JourneyMessage[] {
        const messages = this.formatDisruptionMessages(transitStop, transitStop.stopAreaName);
        return this.uniqueMessages(messages);
    }

    getFormattedCancellationMessagesOfTransitStop(transitStop: TransitStop): JourneyMessage | null {
        return this.getCancellationMessagesIn(transitStop);
    }

    containsDisruptionMessagesInTransitStop(stop: TransitStop): boolean {
        return this.getDisruptionMessagesIn({ messages: stop.messages }).length > 0;
    }

    getFormattedCancellationMessagesOfItineraryStop(itineraryStop: ItineraryStop): JourneyMessage | null {
        return this.getCancellationMessagesIn(itineraryStop);
    }

    getFormattedDisruptionMessagesOfItineraryStop(stop: ItineraryStop): JourneyMessage[] {
        const messages = this.formatDisruptionMessages(stop, stop.name);
        return this.uniqueMessages(messages);
    }

    containsDisruptionMessagesInItineraryStop(stop: ItineraryStop): boolean {
        return this.getFormattedDisruptionMessagesOfItineraryStop(stop).length > 0;
    }

    private async collectDisruptionMessagesForLeg(leg: Leg): Promise<JourneyMessage[]> {
        const lineLocalized = await this.localizationService.get('journey-message.line');

        const departure = first(leg.stops);
        const arrival = last(leg.stops);

        return [
            ...this.formatDisruptionMessages(leg, `${lineLocalized} ${leg.line.number}`),
            ...this.formatDisruptionMessages(departure, departure?.name),
            ...this.formatDisruptionMessages(arrival, arrival?.name),
        ];
    }

    private formatDisruptionMessages(object?: { messages?: JourneyMessage[] }, prefix?: string): JourneyMessage[] {
        if (!object) {
            return [];
        }
        return this.getDisruptionMessagesIn(object).map((message) => this.formatMessage(prefix, message));
    }

    private getDisruptionMessagesIn(object: { messages?: JourneyMessage[] }): JourneyMessage[] {
        return object.messages?.filter((m) => m.type === JourneyMessageType.Disruption) ?? [];
    }

    private getCancellationMessagesIn(object: { messages?: JourneyMessage[] }): JourneyMessage | null {
        return object.messages?.find((m) => m.type === JourneyMessageType.Cancellation) ?? null;
    }

    private formatMessage(prefix: string | undefined | null, message: JourneyMessage): JourneyMessage {
        const prefixFormatted = prefix ? `${prefix} : ` : '';
        return {
            ...message,
            htmlContent: `${prefixFormatted}${message.htmlContent}`,
        };
    }

    private uniqueMessages(messages: JourneyMessage[]): JourneyMessage[] {
        return uniqBy(messages, 'htmlContent');
    }
}
