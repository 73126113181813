/*
Pretty much the same as lodash group by, but returns a Map and not an object
 */
import * as _ from 'lodash';
import { ValueIteratee } from 'lodash';

export function groupBy<T>(array: T[], propertySelector: (value: T) => any): Map<string, T[]> {
    const filtered = new Map<string, T[]>();
    array.forEach((currentItem) => {
        const key = propertySelector(currentItem);
        const defaultValue = filtered.get(key) || [];
        filtered.set(key, [...defaultValue, currentItem]);
    });
    return filtered;
}

/*
Set the length of an array, add extra undefined value if needed.
 */
export function padEnd<T>(items: T[], length: number, fill: T): T[] {
    return items.concat(new Array(length).fill(fill)).slice(0, length);
}

export function mergeArrays<T>(baseArray: T[] = [], overrideWith: T[] = [], iteratee: ValueIteratee<T>): T[] {
    // IMPORTANT: https://lodash.com/docs/4.17.15#unionBy left array items will be kept if they are find in the second one by comparator
    return _.unionBy(overrideWith ?? [], baseArray ?? [], iteratee);
}
