export interface SmsTicketDescription {
    code: string;
    description: string;
    category: string;
    prices: {
        value: string;
        currency: string;
    }[];
}

export const SMSTickets: SmsTicketDescription[] = [
    {
        code: '40',
        description: 'Billet 1 zone Yverdon-les-Bains',
        category: 'Billets plein tarif',
        prices: [
            {
                value: '3.00',
                currency: 'CHF',
            },
        ],
    },
    {
        code: '40R',
        description: 'Billet 1 zone Yverdon-les-Bains',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '2.40',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'J40',
        description: 'Carte journalière 1 zone Yverdon-les-Bains',
        category: 'Billets plein tarif',
        prices: [
            {
                value: '8.60',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'J40R',
        description: 'Carte journalière 1 zone Yverdon-les-Bains',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '6.90',
                currency: 'CHF',
            },
        ],
    },
    {
        code: '48',
        description: 'Billet 1 zone Orbe',
        category: 'Billets plein tarif',
        prices: [
            {
                value: '3.00',
                currency: 'CHF',
            },
        ],
    },
    {
        code: '48R',
        description: 'Billet 1 zone Orbe',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '2.40',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'J48',
        description: 'Carte journalière 1 zone Orbe',
        category: 'Billets plein tarif',
        prices: [
            {
                value: '8.60',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'J48R',
        description: 'Carte journalière 1 zone Orbe',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '6.90',
                currency: 'CHF',
            },
        ],
    },
    {
        code: '4842',
        description: 'Billet 2 zones Orbe - Chavornay',
        category: 'Billets plein tarif',
        prices: [
            {
                value: '3.70',
                currency: 'CHF',
            },
        ],
    },
    {
        code: '4842R',
        description: 'Billet 2 zones Orbe - Chavornay',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '2.40',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'J4842',
        description: 'Carte journalière 2 zones Orbe - Chavornay',
        category: 'Billets plein tarif',
        prices: [
            {
                value: '9.30',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'J4842R',
        description: 'Carte journalière 2 zones Orbe - Chavornay',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '6.90',
                currency: 'CHF',
            },
        ],
    },
    {
        code: '114120',
        description: 'Billet 2 zones Six-Fontaines - Ste-Croix',
        category: 'Billets plein tarif',
        prices: [
            {
                value: '3.70',
                currency: 'CHF',
            },
        ],
    },
    {
        code: '114120R',
        description: 'Billet 2 zones Six-Fontaines - Ste-Croix',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '2.40',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'J114120',
        description: 'Carte journalière 2 zones Six-Fontaines - Ste-Croix',
        category: 'Billets plein tarif',
        prices: [
            {
                value: '9.30',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'J114120R',
        description: 'Carte journalière 2 zones Six-Fontaines - Ste-Croix',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '6.90',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'ALLOBUS',
        description: 'Supplément Allo?Bus Ste-Croix',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '2.00',
                currency: 'CHF',
            },
        ],
    },
    {
        code: '110',
        description: 'Billet 1 zone Vallorbe',
        category: 'Billets plein tarif',
        prices: [
            {
                value: '3.00',
                currency: 'CHF',
            },
        ],
    },
    {
        code: '110R',
        description: 'Billet 1 zone Vallorbe',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '2.40',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'J110',
        description: 'Carte journalière 1 zone Vallorbe',
        category: 'Billets plein tarif',
        prices: [
            {
                value: '8.60',
                currency: 'CHF',
            },
        ],
    },
    {
        code: 'J110R',
        description: 'Carte journalière 1 zone Vallorbe',
        category: 'Billets tarif réduit',
        prices: [
            {
                value: '6.90',
                currency: 'CHF',
            },
        ],
    },
];
