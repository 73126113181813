import { ArticlesBundle, Leg, Traveler } from '@traas/boldor/all-models';
import { v4 as uuid } from 'uuid';

export function createDefaultArticleBundle(legs: Leg[] | undefined, passenger: Traveler, guid = uuid()): ArticlesBundle {
    return {
        passenger,
        guid,
        availableArticles: null,
        legs,
    };
}

export function cloneArticlesBundle(articlesBundle: ArticlesBundle, guid = uuid()): ArticlesBundle {
    // We have to clone it, because the available articles objects will be the same at each defaultArticleBundle added
    return {
        ...articlesBundle,
        passenger: {
            ...articlesBundle.passenger,
        },
        guid,
        availableArticles: articlesBundle.availableArticles.map((defaultArticle) => {
            return { ...defaultArticle };
        }),
    };
}
