import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-itinerary-ticket-stop-request',
    templateUrl: './itinerary-ticket-stop-request.component.html',
    styleUrls: ['./itinerary-ticket-stop-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryTicketStopRequestComponent {
    @Input() set cancelDeadline(value: Date | string) {
        this.$cancelDeadline.next(new Date(value || null));
    }

    @Output() cancelStopRequest: EventEmitter<void> = new EventEmitter<void>();

    private readonly $cancelDeadline: BehaviorSubject<Date | null> = new BehaviorSubject<Date | null>(null);

    onCancelClicked(): void {
        this.cancelStopRequest.emit();
    }
}
