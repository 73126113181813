import { getHomeState } from '../../../features/home/store/home.selectors';
import { createSelector } from '@ngrx/store';
import { line } from './grid.model';

const enum Z_INDEX_VALUE {
    BELOW_ALL = -1,
    ABOVE_ALL = 406,
}

export const getGridState = createSelector(getHomeState, ({ grid }) => grid);

export const getDraggingSvgZIndex = createSelector(getGridState, ({ dragStartPoint }) =>
    dragStartPoint ? Z_INDEX_VALUE.ABOVE_ALL : Z_INDEX_VALUE.BELOW_ALL,
);

export const getIsInEditionMode = createSelector(getGridState, ({ isInEdition }) => isInEdition);

export const getDragLine = createSelector(getGridState, ({ dragStartPoint, dragEndPoint }) =>
    dragStartPoint && dragEndPoint ? line(dragStartPoint, dragEndPoint) : null,
);

export const getIsDragging = createSelector(getGridState, ({ dragStartPoint }) => !!dragStartPoint);

export const getIsInEditionOfAll = createSelector(getGridState, ({ editAll }) => editAll);

export const getSearchMode = createSelector(getGridState, ({ searchMode }) => searchMode);
