import { CreditCard } from '@traas/boldor/all-models';

export interface CreditCardsState {
    cards: CreditCard[];
    selectedCreditCard: CreditCard;
    createCreditCard: boolean;
    skip3DSecure: boolean;
}

export const initialState: CreditCardsState = {
    cards: null,
    selectedCreditCard: null,
    createCreditCard: false,
    skip3DSecure: null,
};
