import { AbstractAdapter } from './abstract.adapter';
import { latLng, LatLng, LatLngBounds } from 'leaflet';
import { Position } from '@capacitor/geolocation';

export class GeopositionAdapter extends AbstractAdapter<Position> {
    static serialize(data: Position): Position {
        const { timestamp, coords } = data;
        return {
            timestamp,
            coords: {
                latitude: coords.latitude,
                longitude: coords.longitude,
                accuracy: coords.accuracy,
                altitude: coords.altitude,
                altitudeAccuracy: coords.altitudeAccuracy,
                heading: coords.heading,
                speed: coords.speed,
            },
        };
    }

    get latitude(): number {
        return this.getCoords().latitude;
    }

    get longitude(): number {
        return this.getCoords().longitude;
    }

    constructor(data: Position) {
        super(data);
        this.data = GeopositionAdapter.serialize(data);
    }

    hasSameLatAndLng(geopositionAdapter: GeopositionAdapter): boolean {
        return this.latitude === geopositionAdapter.latitude && this.longitude === geopositionAdapter.longitude;
    }

    getLatLng(): LatLng {
        return latLng(this.latitude, this.longitude);
    }

    getBounds(radiusFromCenterInMeters = 150): LatLngBounds {
        return this.getLatLng().toBounds(radiusFromCenterInMeters);
    }

    private getCoords(): GeolocationCoordinates {
        return {
            ...this.getData().coords,
            altitudeAccuracy: this.getData().coords.altitudeAccuracy ?? 0,
        };
    }

    hasTimestamp(): boolean {
        return !!this.getData().timestamp;
    }
}
