import { SWISS_DATE_FORMAT, SWISS_TIME_FORMAT } from '@traas/boldor/all-helpers';
import { AbstractAdapter, Bookable } from '@traas/boldor/all-models';
import * as moment from 'moment';

export abstract class BookableAdapter<T extends Bookable> extends AbstractAdapter<T> {
    protected constructor(data: T) {
        super(data);
    }

    getRemainingTimeBeforeBooking(): number | undefined {
        return this.getData().remainingTimeBeforeBooking;
    }

    hasBookingRequirements(): boolean {
        return this.getData().hasBookingRequirements;
    }

    getBookingDeadline(): Date {
        return moment(this.getData().bookingDeadline).toDate();
    }

    getBookingDeadlineDisplayText(): string {
        if (!this.hasBookingRequirements()) {
            return '';
        }

        const bookingDeadline = moment(this.getData().bookingDeadline);
        if (bookingDeadline.format(SWISS_DATE_FORMAT) === moment().format(SWISS_DATE_FORMAT)) {
            return bookingDeadline.format(SWISS_TIME_FORMAT);
        }
        return `le ${bookingDeadline.format('DD.MM à HH:mm')}`;
    }

    abstract isValidTimeConstraintToBook(): boolean;

    abstract timeLeftToBookInMs(): number;

    isBookable(): boolean {
        if (this.hasBookingRequirements()) {
            return this.isValidTimeConstraintToBook();
        }
        return false;
    }
}
