import { NgModule } from '@angular/core';
import { PageTitleModule } from '../../components/page-title/page-title.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { BookingPage } from './booking';
import { BookingPageRoutingModule } from './booking-page-routing.module';
import { OrderDetailsPage } from './order-details-page/order-details.component';
import { BookingModule } from '../../features/booking/booking.module';
import { TicketModule } from '../../features/ticket/ticket.module';

@NgModule({
    declarations: [BookingPage, OrderDetailsPage],
    imports: [BoldorFeatureShellSharedModule, BookingModule, TicketModule, BookingPageRoutingModule, PageTitleModule],
    exports: [OrderDetailsPage],
})
export class BookingPageModule {}
