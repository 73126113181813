import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CompanyService } from '../../../../services/common/company/company.service';
import { getZonesLabelFromZones, getZonesTextFromZones } from '../../../../services/common/quick-article/quick-article.utils';
import { getFormattedPriceFromPrice } from '@traas/boldor/all-helpers';
import { Article, Price, Ticket, Zone } from '@traas/boldor/all-models';

@Component({
    selector: 'ticket-detail',
    templateUrl: './ticket-detail.component.html',
    styleUrls: ['./ticket-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketDetailComponent implements OnInit {
    @Input() ticket: Ticket;

    readonly isTpc = CompanyService.isTPC();
    dateFormat = 'dd.MM.yyyy';
    dateTimeFormat = 'dd.MM.yyyy HH:mm:ss';
    tripLabel: string | null;

    getFormattedPrice(price: Price): string {
        return getFormattedPriceFromPrice(price, false, false);
    }

    getBilletTypeLabel(article: Article): string {
        let billetTypeLabel = '';
        if (article.tarifOwner) {
            billetTypeLabel += `${article.tarifOwner}`;
        }
        if (article.title) {
            billetTypeLabel += ` ${article.title}`;
        }
        return billetTypeLabel;
    }

    getZonesLabel(zones: Zone[]): string {
        return getZonesLabelFromZones(zones);
    }

    getZonesText(zones: Zone[]): string {
        return getZonesTextFromZones(zones);
    }

    getTripLabel(): string {
        let tripLabel = this.ticket.article.travelClass ? this.ticket.article.travelClass.description : null;
        const { roundTrip } = this.ticket.article;
        if (roundTrip) {
            tripLabel += ` ${roundTrip},`;
        }
        return tripLabel;
    }

    ngOnInit(): void {
        this.tripLabel = this.ticket.isProcessing ? null : this.getTripLabel();
    }

    isDifferentThanZonesText(article: Article, locationValidity: string): boolean {
        const zonesText = this.getZonesText(article.zones);
        return zonesText !== locationValidity;
    }

    thereIsLocationsValidityToShow(article: Article): boolean {
        const someValuesArePresent = article.locationsValidity?.values?.length > 0;
        const someValuesMustBeShown = article.locationsValidity?.values?.some((location) =>
            this.isDifferentThanZonesText(article, location),
        );
        return someValuesArePresent && someValuesMustBeShown;
    }

    thereIsLocationsChoiceToShow(article: Article): boolean {
        const someValuesArePresent = article.locationsChoice?.values?.length > 0;
        const someValuesMustBeShown = article.locationsChoice?.values?.some((location) => this.isDifferentThanZonesText(article, location));
        return someValuesArePresent && someValuesMustBeShown;
    }

    isViaLocation(locationValidity: string): boolean {
        return locationValidity?.startsWith('via ');
    }

    getReferenceNumber(): string {
        const { refundReferenceNumber, referenceNumber } = this.ticket;
        return refundReferenceNumber || referenceNumber.value;
    }

    getOrderNumber(): string {
        const { refundOrderNumber, orderNumber } = this.ticket;
        return refundOrderNumber || orderNumber;
    }
}
