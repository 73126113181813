import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@traas/boldor/environments';
import { DatepickerMode } from '../../features/home/store/datepicker/datepicker.state';

@Component({
    selector: 'app-date-picker-behavior-badge',
    templateUrl: './date-picker-behavior-badge.component.html',
    styleUrls: ['./date-picker-behavior-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerBehaviorBadgeComponent {
    @Input() behavior: DatepickerMode;

    visible = environment.isDebugMode;

    getEmoji(): string {
        switch (this.behavior) {
            case DatepickerMode.FIXED:
                return 'FIXED';
            case DatepickerMode.AUTO:
                return 'AUTO';
            default:
                return 'ERROR';
        }
    }

    getColor(): string {
        switch (this.behavior) {
            case DatepickerMode.FIXED:
                return 'danger';
            case DatepickerMode.AUTO:
                return 'success';
            default:
                return 'warning';
        }
    }
}
