import { Area, ChangePlaceEventSourceEnum, Endpoint, EndpointData, isDeparture, ItineraryAreas } from '@traas/boldor/all-models';
import { EndpointState } from './endpoint.state';
import { getHomeState } from '../home.selectors';
import { HomeState } from '../home.state';
import { createSelector } from '@ngrx/store';

export interface EndpointInformation {
    area: Area;
    endpoint: EndpointData;
    ignoreNextChange: boolean;
    endpointValue: Endpoint;
}

export const getEndpointState = createSelector(getHomeState, (homeState: HomeState) => homeState.endpoint);
export const getDeparture = createSelector(getEndpointState, (endpointState: EndpointState) => endpointState.departure);
export const getDepartureArea = createSelector(getDeparture, (departure: EndpointInformation & { isChanging: boolean }) => departure.area);
export const getArrival = createSelector(getEndpointState, (endpointState: EndpointState) => endpointState.arrival);
export const getArrivalArea = createSelector(getArrival, (arrival: EndpointInformation & { isChanging: boolean }) => arrival.area);

export const getAreas = createSelector(
    getDeparture,
    getArrival,
    (departure, arrival) =>
        ({
            departure: departure.area,
            arrival: arrival.area,
        } as ItineraryAreas),
);

export const getActiveEndpoint = createSelector(getEndpointState, (endpointState: EndpointState) => endpointState.activeEndpoint);

export const getActiveEndpointInformation = createSelector(getEndpointState, (endpointState: EndpointState) => {
    if (isDeparture(endpointState.activeEndpoint)) {
        return endpointState.departure;
    }
    return endpointState.arrival;
});

export const getEndpointFollowingGps = createSelector(getEndpointState, (endpointState: EndpointState) => {
    if (isDepartureFollowingGPS(endpointState)) {
        return Endpoint.Departure;
    }
    if (isArrivalFollowingGPS(endpointState)) {
        return Endpoint.Arrival;
    }
    return null;
});

export const isFollowingGps = createSelector(getEndpointState, (endpointState: EndpointState) => {
    if (isDepartureFollowingGPS(endpointState)) {
        return true;
    }
    const isArrivalShown = !endpointState.arrival.hidden;
    return isArrivalShown && isArrivalFollowingGPS(endpointState);
});

export const getDepartureLocations = createSelector(getEndpointState, (endpointState: EndpointState) => {
    return endpointState.departure.endpoint.locations;
});

export const getCanResetArrivalEndpoint = createSelector(getEndpointState, (endpointState: EndpointState) => {
    const validDepartureLocation = !!endpointState.departure.endpoint.locations;
    const validArrivalLocation = !!endpointState.arrival.endpoint.locations;
    return validArrivalLocation && validDepartureLocation;
});

export const getIsArrivalHidden = createSelector(getEndpointState, (endpointState: EndpointState) => {
    return endpointState.arrival.hidden;
});

export const getIsDepartureChanging = createSelector(getEndpointState, (endpointState: EndpointState) => {
    return endpointState.departure.isChanging;
});

export const getIsArrivalChanging = createSelector(getEndpointState, (endpointState: EndpointState) => {
    return endpointState.arrival.isChanging;
});

export const isEnabled = createSelector(getEndpointState, (endpointState: EndpointState) => {
    return endpointState.isEnabled;
});

function isArrivalFollowingGPS(endpointState: EndpointState): boolean {
    return endpointState.arrival?.area.metadata.source === ChangePlaceEventSourceEnum.ClickFollowGps;
}

function isDepartureFollowingGPS(endpointState: EndpointState): boolean {
    return endpointState.departure?.area.metadata.source === ChangePlaceEventSourceEnum.ClickFollowGps;
}
