import { CartOperations, Itinerary, LeaveOrArriveEnum, TransportMode } from '@traas/boldor/all-models';
import { DatePickerBarSearchOption } from '../../../components/date-picker-bar/date-picker-bar.component';
import { Placeholder } from '../../../components/placeholder-list-item/placeholder.model';

export const ITINERARIES_INITIAL_VALUE: any = null;

export interface SelectedItineraryContext {
    itinerary: Itinerary | null;
    operations: CartOperations | null;
    conditionsMessages: string[];
    datePickerBarSearchOption?: DatePickerBarSearchOption;
}

export interface ItineraryState {
    itineraries: Itinerary[] | null;
    loading: boolean;
    refresher: ItineraryRefreshConfig;
    inScrollViewport: (Itinerary | Placeholder)[];
    queryKind: LeaveOrArriveEnum;
    selected: SelectedItineraryContext;

    enabledTransportModes: TransportMode[];
    enableOnlyFastestRoutes: boolean;
}

export const initialState: ItineraryState = {
    itineraries: ITINERARIES_INITIAL_VALUE,
    loading: false,
    refresher: {
        itinerariesCount: 0,
        date: new Date(),
    },
    inScrollViewport: [],
    queryKind: LeaveOrArriveEnum.LeaveAt,
    selected: {
        itinerary: null,
        conditionsMessages: [],
        operations: null,
        datePickerBarSearchOption: DatePickerBarSearchOption.DEPARTURE,
    },
    enabledTransportModes: [],
    enableOnlyFastestRoutes: false,
};

export interface ItineraryRefreshConfig {
    itinerariesCount: number;
    date: Date;
}
