import { Inject, Injectable } from '@angular/core';
import { AuthenticationApi } from '@traas/common/feature-account';
import { SWISSPASS_SERVICE_TOKEN, SwisspassService } from '../../../features/swisspass/services/SwisspassService';

@Injectable()
export class SwisspassAuthenticationApiService extends AuthenticationApi {
    constructor(
        @Inject(SWISSPASS_SERVICE_TOKEN)
        private swisspassService: SwisspassService,
    ) {
        super();
    }

    override async isAuthenticated(): Promise<boolean> {
        return this.swisspassService.isAuthenticatedOnSwissPass();
    }

    override getAccessToken(): Promise<string | null> {
        return this.swisspassService.getAccessToken();
    }

    override async authenticate(): Promise<boolean> {
        const { isLoggedIn } = await this.swisspassService.openSwisspassLoginPage();
        return isLoggedIn;
    }

    override async logout(): Promise<boolean> {
        return await this.swisspassService.logout();
    }

    override async deleteAccount(): Promise<void> {
        await this.swisspassService.openLoginDataManagementPage();
    }

    override async forceLogout(): Promise<void> {
        await this.swisspassService.forceLogout();
    }
}
