import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { RouteUrl } from '@traas/common/routing';

export const stripUrl = (url: string, noTabs: boolean): string => {
    return noTabs ? getPathFromUrl(url).replace('/tabs/', '') : getPathFromUrl(url);
};

const getPathFromUrl = (url: string): string => {
    return url.split(/[?#]/)[0];
};

export function observeRouterUrlEvents(router: Router, onDestroy: Observable<void>): Observable<string> {
    return router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        debounceTime(100),
        takeUntil(onDestroy),
        map(({ urlAfterRedirects }) => {
            const fixedUrl = urlAfterRedirects === '/' ? RouteUrl.departureResultUrl : urlAfterRedirects;
            return stripUrl(fixedUrl, true);
        }),
        distinctUntilChanged(),
    );
}
