import { Pipe, PipeTransform } from '@angular/core';
import { convertToError, LoggingService } from '@traas/common/logging';
import { HumanReadableStop } from '@traas/boldor/all-models';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

@Pipe({
    name: 'stopName',
})
export class StopNamePipe implements PipeTransform {
    static transformValue(value: HumanReadableStop | string = '', logger?: LoggingService): string {
        try {
            if (typeof value === 'string') {
                return value;
            }
            return value.getName();
        } catch (error) {
            const technicalError = new TechnicalError(
                'Error while trying to transform stop name. Falling back to default value.',
                ErrorCodes.Technical.PipeTransform,
                convertToError(error),
                { value: JSON.stringify(value) },
            );
            if (logger) {
                logger.logError(technicalError);
            } else {
                console.error(technicalError);
            }
            return 'Inconnu';
        }
    }

    constructor(private logger: LoggingService) {}

    transform(value: HumanReadableStop | string): any {
        return StopNamePipe.transformValue(value, this.logger);
    }
}
