import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements AfterViewInit {
    @Output() clearTerm = new EventEmitter<void>();
    @Output() termChange = new EventEmitter<string>();

    @ViewChild('searchbar') searchbar: IonSearchbar;

    ngAfterViewInit(): void {
        setTimeout(async () => {
            await this.searchbar.setFocus();
        }, 200);
    }

    onTermChange(event: Event): void {
        this.termChange.emit((event as CustomEvent).detail.value);
    }

    onClearTerm(): void {
        this.clearTerm.emit();
    }
}
