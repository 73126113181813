import { Injectable } from '@angular/core';
import { ApolloError } from '@apollo/client/core';
import { ErrorHandler } from '@traas/boldor/all-models';
import { isApolloNetworkError } from '@traas/common/logging';

/**
 * If this is an network error (device has no network), we don't want to pollute Sentry with that
 * and we mark the error as handled without doing nothing
 */
@Injectable({ providedIn: 'root' })
export class NetworkErrorHandler implements ErrorHandler {
    async handle(error: ApolloError): Promise<boolean> {
        return isApolloNetworkError(error);
    }
}
