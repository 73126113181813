import { StartupNotificationAction, StartupNotificationActionTypes } from './startup-notification-action';
import { initialState, StartupNotificationState } from './startup-notification-state';

export function startupNotificationReducer(
    state: StartupNotificationState = initialState,
    action: StartupNotificationAction,
): StartupNotificationState {
    switch (action.type) {
        case StartupNotificationActionTypes.Load:
            return {
                ...state,
                isLoading: true,
            };

        case StartupNotificationActionTypes.LoadFail:
            return {
                ...state,
                isLoading: false,
                isAllClear: true,
            };

        case StartupNotificationActionTypes.LoadSuccess:
            return {
                ...state,
                isLoading: false,
                pendingMessages: action.payload,
                isAllClear: action.payload.length <= 0,
            };

        case StartupNotificationActionTypes.Done:
            return {
                ...state,
                isAllClear: true,
            };

        case StartupNotificationActionTypes.PresentMessage:
            return {
                ...state,
                currentMessage: action.payload,
                pendingMessages: state.pendingMessages.filter((message) => message.id !== action.payload.id),
            };

        default:
            return state;
    }
}
