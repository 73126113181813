/* tslint:disable */
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
    GeoJson: any;
};

export type Address = {
    __typename?: 'Address';
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Country>;
    /** The number is not typed 'Int' as it could be 14b for instance */
    number?: Maybe<Scalars['String']>;
    /** same as zip code */
    postalCode?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    street2?: Maybe<Scalars['String']>;
};

export type AddressPlace = {
    __typename?: 'AddressPlace';
    address: Scalars['String'];
    city: Scalars['String'];
    latLon: Scalars['Float'][];
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
    scoring: Scalars['Float'];
    zip: Scalars['String'];
};

export type Article = {
    articleNumber?: Maybe<LocalizedOptionItem>;
    category?: Maybe<Category>;
    categoryDescription?: Maybe<Scalars['String']>;
    /** added */
    description?: Maybe<Scalars['String']>;
    /** on bought ticket */
    locationsChoice?: Maybe<LocalizedOptionItems>;
    /** on bought ticket */
    locationsValidity?: Maybe<LocalizedOptionItems>;
    paymentMeans?: Maybe<PaymentMeans[]>;
    prices?: Maybe<Price[]>;
    reduction?: Maybe<Reduction>;
    smsCode?: Maybe<Scalars['String']>;
    /** on bought ticket */
    tarifOwner?: Maybe<Scalars['String']>;
    /** TPC Specific */
    thirdPartyPayerInformation?: Maybe<ThirdPartyPayerInformation>;
    title?: Maybe<Scalars['String']>;
    travelClass?: Maybe<TravelClass>;
    travelType?: Maybe<TravelType>;
    type: ArticleType;
    /** SDS Specific */
    unavailabilityReason?: Maybe<OptionItem>;
    /** on bought ticket */
    validity?: Maybe<Validity>;
    validityDurationLabel?: Maybe<Scalars['String']>;
    /** on bought ticket */
    vatLabel?: Maybe<Scalars['String']>;
    /** optional */
    zones?: Maybe<Zone[]>;
};

export type ArticleMutation = {
    __typename?: 'ArticleMutation';
    generateDepartureArticles?: Maybe<DepartureArticle[]>;
    generateItineraryArticles?: Maybe<ItineraryArticle[]>;
    generateQuickArticles?: Maybe<QuickArticle[]>;
    generateQuickArticlesByZone?: Maybe<QuickArticle[]>;
};

export type ArticleMutationGenerateDepartureArticlesArgs = {
    generateDepartureArticlesInput: GenerateDepartureArticlesInput;
};

export type ArticleMutationGenerateItineraryArticlesArgs = {
    generateItineraryArticlesInput: GenerateItineraryArticlesInput;
};

export type ArticleMutationGenerateQuickArticlesArgs = {
    generateQuickArticlesInput: GenerateQuickArticlesInput;
};

export type ArticleMutationGenerateQuickArticlesByZoneArgs = {
    generateQuickArticlesByZoneInput: GenerateQuickArticlesByZoneInput;
};

export type ArticlePublicMutation = {
    __typename?: 'ArticlePublicMutation';
    generateItineraryArticles: ItineraryArticle[];
};

export type ArticlePublicMutationGenerateItineraryArticlesArgs = {
    generateItineraryArticlesInput: GenerateItineraryArticlesInput;
};

export enum ArticleType {
    DepartureArticle = 'DepartureArticle',
    ItineraryArticle = 'ItineraryArticle',
    QuickArticle = 'QuickArticle',
}

export type AssociatedCommercialStop = {
    __typename?: 'AssociatedCommercialStop';
    cityName?: Maybe<Scalars['String']>;
    coordinates?: Maybe<Coordinates>;
    didok?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    lines: Line[];
    name: Scalars['String'];
};

export type AssociatedCommercialStopInput = {
    cityName?: InputMaybe<Scalars['String']>;
    coordinates?: InputMaybe<CoordinatesInput>;
    didok?: InputMaybe<Scalars['Int']>;
    id: Scalars['ID'];
    lines: LineInput[];
    name: Scalars['String'];
};

export type AuthMutation = {
    __typename?: 'AuthMutation';
    _?: Maybe<Scalars['Boolean']>;
    basicAuth: BasicAuthMutation;
};

export type AuthQuery = {
    __typename?: 'AuthQuery';
    _?: Maybe<Scalars['Boolean']>;
};

export type AuthenticationResult = {
    __typename?: 'AuthenticationResult';
    accessToken: Scalars['String'];
    errors?: Maybe<Scalars['String']>;
};

export type BasicAuthMutation = {
    __typename?: 'BasicAuthMutation';
    authenticate: AuthenticationResult;
    authenticateController: AuthenticationResult;
};

export type BasicAuthMutationAuthenticateArgs = {
    password: Scalars['String'];
    username: Scalars['String'];
};

export type BasicAuthMutationAuthenticateControllerArgs = {
    password: Scalars['String'];
    username: Scalars['String'];
};

export type BoundingBox = {
    __typename?: 'BoundingBox';
    northWest: Coordinates;
    physicalStops?: Maybe<PhysicalStop[]>;
    southEast: Coordinates;
};

export type BoundingBoxInput = {
    northEast: CoordinatesInput;
    southWest: CoordinatesInput;
};

export enum CancellationType {
    PaymentTimedOut = 'PAYMENT_TIMED_OUT',
}

export type CardAliasInput = {
    createNewOne: Scalars['Boolean'];
    id?: InputMaybe<Scalars['String']>;
    use3DSecure?: InputMaybe<Scalars['Boolean']>;
};

export type Category = {
    __typename?: 'Category';
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
};

export type CategoryInput = {
    description?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
};

export enum CheckoutError {
    GenerationOfPaymentFailed = 'GENERATION_OF_PAYMENT_FAILED',
    GenerationOfTicketFailed = 'GENERATION_OF_TICKET_FAILED',
}

export type CityPlace = {
    __typename?: 'CityPlace';
    id: Scalars['String'];
    mainStops: MainStopPlace[];
    name: Scalars['String'];
    scoring: Scalars['Float'];
};

export enum ClientPlatform {
    Android = 'ANDROID',
    Ios = 'IOS',
}

export type CommercialStop = {
    __typename?: 'CommercialStop';
    arrivalTime: Scalars['String'];
    cityName?: Maybe<Scalars['String']>;
    departureTime: Scalars['String'];
    didok?: Maybe<Scalars['Int']>;
    hasBookingRequirements: Scalars['Boolean'];
    id: Scalars['String'];
    isArrivalPlace: Scalars['Boolean'];
    isCancelled: Scalars['Boolean'];
    isDeparturePlace: Scalars['Boolean'];
    isOptional: Scalars['Boolean'];
    latLon: Scalars['Float'][];
    messages: JourneyMessage[];
    name: Scalars['String'];
    physicalStopAssociated?: Maybe<PhysicalStop>;
    rank?: Maybe<Scalars['Int']>;
    scheduledArrivalTime?: Maybe<Scalars['String']>;
    scheduledDepartureTime?: Maybe<Scalars['String']>;
};

export type CommercialStopInput = {
    arrivalTime: Scalars['String'];
    cityName?: InputMaybe<Scalars['String']>;
    departureTime: Scalars['String'];
    didok: Scalars['Int'];
    hasBookingRequirements: Scalars['Boolean'];
    id: Scalars['String'];
    isArrivalPlace: Scalars['Boolean'];
    isCancelled: Scalars['Boolean'];
    isDeparturePlace: Scalars['Boolean'];
    isOptional: Scalars['Boolean'];
    latLon: Scalars['Float'][];
    messages: JourneyMessageInput[];
    name: Scalars['String'];
    physicalStopAssociated?: InputMaybe<PhysicalStopInput>;
    scheduledArrivalTime?: InputMaybe<Scalars['String']>;
    scheduledDepartureTime?: InputMaybe<Scalars['String']>;
};

export type ConfigurationQuery = {
    __typename?: 'ConfigurationQuery';
    paymentMethods: PaymentMethodQuery;
    refreshIntervals: RefreshIntervalQuery;
    thresholds: ThresholdQuery;
};

export type ConfigurationQueryPaymentMethodsArgs = {
    clientPlatform: ClientPlatform;
    currency: Currency;
};

export type Contact = {
    __typename?: 'Contact';
    primary?: Maybe<Scalars['Boolean']>;
    type?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
    verifiable?: Maybe<Scalars['Boolean']>;
    verified?: Maybe<Scalars['Boolean']>;
};

export type Coordinates = {
    __typename?: 'Coordinates';
    accuracy?: Maybe<Scalars['Float']>;
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
};

export type CoordinatesInput = {
    accuracy?: InputMaybe<Scalars['Float']>;
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
};

export type CoordinatesOrStopIdsInput = {
    coordinates?: InputMaybe<CoordinatesInput>;
    stopIds?: InputMaybe<Scalars['ID'][]>;
};

export type Country = {
    __typename?: 'Country';
    /** should be code ISO 3166-1 (alpha-2) */
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
};

export type CountryInput = {
    /** should be code ISO 3166-1 (alpha-2) */
    id: Scalars['ID'];
};

export type CreateCustomerFrequentTravelerInput = {
    birthDate: Scalars['Date'];
    email?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    hasHalfFare: Scalars['Boolean'];
    id?: InputMaybe<Scalars['String']>;
    lastName: Scalars['String'];
    phone?: InputMaybe<Scalars['String']>;
    title: Scalars['String'];
    type: PassengerType;
};

export type CreditCard = PartialCreditCard & {
    __typename?: 'CreditCard';
    brand: Scalars['String'];
    countryCode?: Maybe<Scalars['String']>;
    expirationMonth: Scalars['Int'];
    expirationYear: Scalars['Int'];
    gatewayReference: GatewayReference;
    holderName?: Maybe<Scalars['String']>;
    /** card alias id */
    id: Scalars['ID'];
    maskedNumber: Scalars['String'];
    primary?: Maybe<Scalars['Boolean']>;
};

export type CreditCardInput = PartialCreditCard & {
    __typename?: 'CreditCardInput';
    brand: Scalars['String'];
    countryCode?: Maybe<Scalars['String']>;
    expirationMonth: Scalars['Int'];
    expirationYear: Scalars['Int'];
    gatewayReference: GatewayReference;
    holderName?: Maybe<Scalars['String']>;
    maskedNumber: Scalars['String'];
    primary?: Maybe<Scalars['Boolean']>;
};

export enum Currency {
    Chf = 'CHF',
    Eur = 'EUR',
}

export type CustomerFrequentTraveler = {
    __typename?: 'CustomerFrequentTraveler';
    birthDate: Scalars['Date'];
    email?: Maybe<Scalars['String']>;
    firstName: Scalars['String'];
    hasHalfFare: Scalars['Boolean'];
    id: Scalars['ID'];
    lastName: Scalars['String'];
    phone?: Maybe<Scalars['String']>;
    title: Scalars['String'];
    type: PassengerType;
};

export type CustomerFrequentTravelerInput = {
    birthDate: Scalars['Date'];
    email?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    hasHalfFare: Scalars['Boolean'];
    id: Scalars['String'];
    lastName: Scalars['String'];
    phone?: InputMaybe<Scalars['String']>;
    title: Scalars['String'];
    type: PassengerType;
};

export type CustomerMutation = {
    __typename?: 'CustomerMutation';
    createFrequentTraveler: Result;
    removeCreditCard: Result;
    removeFrequentTraveler: Result;
    updateFrequentTraveler: Result;
};

export type CustomerMutationCreateFrequentTravelerArgs = {
    frequentTraveler: CreateCustomerFrequentTravelerInput;
};

export type CustomerMutationRemoveCreditCardArgs = {
    creditCardId: Scalars['String'];
    currency: Currency;
};

export type CustomerMutationRemoveFrequentTravelerArgs = {
    frequentTravelerId: Scalars['String'];
};

export type CustomerMutationUpdateFrequentTravelerArgs = {
    frequentTraveler: CustomerFrequentTravelerInput;
};

export type CustomerProfile = {
    __typename?: 'CustomerProfile';
    address?: Maybe<Address>;
    birthDate?: Maybe<Scalars['Date']>;
    email: Scalars['String'];
    firstName?: Maybe<Scalars['String']>;
    /**  true if the Customer has the half fare */
    hasHalfFare?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    language?: Maybe<Language>;
    lastName?: Maybe<Scalars['String']>;
    /**  When buying a ticket, this passengerId must be used if the ticket's passenger is the customer  */
    passengerId: Scalars['String'];
    phone?: Maybe<Scalars['String']>;
    subscriptionNumber?: Maybe<Scalars['String']>;
    title: Title;
};

export type CustomerProfileReturn = CustomerProfile | ResponseError;

export type CustomerQuery = {
    __typename?: 'CustomerQuery';
    creditCards: CreditCard[];
    frequentTravelers: CustomerFrequentTraveler[];
    profileByCardId: CustomerProfileReturn;
};

export type CustomerQueryCreditCardsArgs = {
    currency: Currency;
    onlyValid: Scalars['Boolean'];
};

export type CustomerQueryProfileByCardIdArgs = {
    cardId: Scalars['String'];
};

export type DebugOps = {
    __typename?: 'DebugOps';
    error401?: Maybe<Scalars['Boolean']>;
};

export type Departure = {
    __typename?: 'Departure';
    additionalInformation?: Maybe<Scalars['String'][]>;
    bookingDeadline: Scalars['String'];
    departureTimestamp: Scalars['String'];
    direction: Scalars['String'];
    hasBookingRequirements: Scalars['Boolean'];
    hasStopRequest: Scalars['Boolean'];
    id: Scalars['ID'];
    isBookable: Scalars['Boolean'];
    isCancelled: Scalars['Boolean'];
    line: Line;
    messages: JourneyMessage[];
    networkId?: Maybe<Scalars['String']>;
    /** outdatedDate format: YYYY-MM-DD HH:mm:ss on CET timezone */
    outdatedDate?: Maybe<Scalars['String']>;
    plannedDepartureTime: Scalars['String'];
    remainingTimeBeforeBooking: Scalars['Int'];
    serviceId: Scalars['String'];
    stop: DepartureStop;
    track: Scalars['String'];
    transport: Scalars['String'];
};

export type DepartureArticle = Article & {
    __typename?: 'DepartureArticle';
    articleId: Scalars['ID'];
    articleNumber?: Maybe<LocalizedOptionItem>;
    category?: Maybe<Category>;
    categoryDescription?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    /** on bought ticket */
    locationsChoice?: Maybe<LocalizedOptionItems>;
    /** on bought ticket */
    locationsValidity?: Maybe<LocalizedOptionItems>;
    paymentMeans?: Maybe<PaymentMeans[]>;
    prices?: Maybe<Price[]>;
    reduction?: Maybe<Reduction>;
    smsCode?: Maybe<Scalars['String']>;
    /** on bought ticket */
    tarifOwner?: Maybe<Scalars['String']>;
    /** TPC Specific */
    thirdPartyPayerInformation?: Maybe<ThirdPartyPayerInformation>;
    title?: Maybe<Scalars['String']>;
    travelClass?: Maybe<TravelClass>;
    travelType?: Maybe<TravelType>;
    type: ArticleType;
    /** SDS Specific */
    unavailabilityReason?: Maybe<OptionItem>;
    /** On bought ticket */
    validity?: Maybe<Validity>;
    validityDurationLabel?: Maybe<Scalars['String']>;
    /** on bought ticket */
    vatLabel?: Maybe<Scalars['String']>;
    /** optional */
    zones?: Maybe<Zone[]>;
};

export type DepartureArticleBasketInput = {
    articleSelections: DepartureArticleSelectionInput[];
    departure: DepartureBasketInput;
    requestStop: Scalars['Boolean'];
};

export type DepartureArticleInput = {
    amount?: InputMaybe<PriceInput>;
    articleId: Scalars['ID'];
    description?: InputMaybe<Scalars['String']>;
    prices?: InputMaybe<PriceInput[]>;
    reduction?: InputMaybe<ReductionInput>;
    title?: InputMaybe<Scalars['String']>;
    travelClass?: InputMaybe<TravelClassInput>;
    travelType?: InputMaybe<TravelType>;
    zones?: InputMaybe<ZoneInput[]>;
};

export type DepartureArticleSelectionInput = {
    article: DepartureArticleInput;
    isAuthenticatedCustomer: Scalars['Boolean'];
    passenger: PassengerInput;
    passengerType: PassengerType;
};

export type DepartureBasketInput = {
    bookingDeadline: Scalars['String'];
    departureDate: Scalars['String'];
    hasBookingRequirements: Scalars['Boolean'];
    id?: InputMaybe<Scalars['ID']>;
    isBookable: Scalars['Boolean'];
    remainingTimeBeforeBooking?: InputMaybe<Scalars['Int']>;
    serviceId: Scalars['ID'];
    stop: DepartureBasketStopInput;
};

export type DepartureBasketLineInput = {
    id: Scalars['ID'];
};

export type DepartureBasketStopInput = {
    id: Scalars['ID'];
    line: DepartureBasketLineInput;
    name: Scalars['String'];
};

export type DepartureCommercialStop = {
    __typename?: 'DepartureCommercialStop';
    id: Scalars['ID'];
    line: DepartureCommercialStopLine;
    name: Scalars['String'];
};

export type DepartureCommercialStopLine = {
    __typename?: 'DepartureCommercialStopLine';
    id: Scalars['ID'];
};

export type DepartureOrder = Order & {
    __typename?: 'DepartureOrder';
    customerId?: Maybe<Scalars['ID']>;
    departure?: Maybe<Departure>;
    id?: Maybe<Scalars['ID']>;
    orderType: OrderType;
    ticketsTransaction?: Maybe<TicketsTransaction>;
};

export type DepartureQuery = {
    __typename?: 'DepartureQuery';
    getDirectionsFromStop: Direction[];
    search: Departure[];
};

export type DepartureQueryGetDirectionsFromStopArgs = {
    directionsFromStopInput: DirectionsFromStopInput;
};

export type DepartureQuerySearchArgs = {
    count: Scalars['Int'];
    filter?: InputMaybe<DepatureFilterInput>;
    lang: Scalars['String'];
    timing: DepartureTimingInput;
    using: DepartureSearchCriteria;
};

export type DepartureSearchCriteria = {
    stopIds: Scalars['String'][];
};

export type DepartureStop = {
    __typename?: 'DepartureStop';
    cityName?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isOptional: Scalars['Boolean'];
    messages: JourneyMessage[];
    name: Scalars['String'];
    physicalStop: PhysicalStop;
    rank: Scalars['Int'];
};

export type DepartureStopRequest = {
    __typename?: 'DepartureStopRequest';
    customerId?: Maybe<Scalars['ID']>;
    departure?: Maybe<Departure>;
    id?: Maybe<Scalars['ID']>;
};

export type DepartureTimingInput = {
    kind?: InputMaybe<SearchDirection>;
    timestamp?: InputMaybe<Scalars['Date']>;
};

export type DeparturesInput = {
    count: Scalars['Int'];
    filter?: InputMaybe<DepatureFilterInput>;
    timing: DepartureTimingInput;
    using: DepartureSearchCriteria;
};

export type DepatureFilterInput = {
    directions?: InputMaybe<DirectionInput[]>;
};

export type Direction = {
    __typename?: 'Direction';
    lineId: Scalars['ID'];
    lineName?: Maybe<Scalars['String']>;
    lineStyleId: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    wayback?: Maybe<Wayback>;
};

export type DirectionInput = {
    lineId: Scalars['ID'];
    wayback?: InputMaybe<Wayback>;
};

export type DirectionsFromStopInput = {
    boundingBox: BoundingBoxInput;
    dateTime?: InputMaybe<Scalars['Date']>;
    stopAreaIds: Scalars['ID'][];
    timeDuration?: InputMaybe<Scalars['String']>;
};

export type Duration = {
    __typename?: 'Duration';
    description?: Maybe<Scalars['String']>;
    id: DurationType;
};

export enum DurationType {
    Day = 'DAY',
    Travel = 'TRAVEL',
}

export enum Gateway {
    Ccv = 'ccv',
    Saferpay = 'saferpay',
}

export type GatewayReference = {
    __typename?: 'GatewayReference';
    /** date-time for example: 2019-08-16T11:07:19Z */
    expiresAt?: Maybe<Scalars['Date']>;
    gateway: Gateway;
    reference?: Maybe<Scalars['String']>;
};

export type GenerateDepartureArticlesInput = {
    departureDateTime?: InputMaybe<Scalars['String']>;
    departureStopDidok?: InputMaybe<Scalars['Int']>;
    isAuthenticatedCustomer: Scalars['Boolean'];
    language: LanguageInput;
    passenger: PassengerInput;
    passengerType: PassengerType;
    travelTypes: TravelType[];
};

export type GenerateItineraryArticlesInput = {
    isAuthenticatedCustomer: Scalars['Boolean'];
    language: LanguageInput;
    legs: ItineraryLegInput[];
    passenger: PassengerInput;
    passengerType: PassengerType;
    travelTypes: TravelType[];
};

export type GenerateQuickArticlesByZoneInput = {
    articleId: Scalars['Int'];
    isAuthenticatedCustomer: Scalars['Boolean'];
    language: LanguageInput;
    passenger: PassengerInput;
    passengerType: PassengerType;
    zones: ZoneInput[];
};

export type GenerateQuickArticlesInput = {
    _?: InputMaybe<Scalars['Boolean']>;
    departureStopDidok: Scalars['Int'];
    isAuthenticatedCustomer: Scalars['Boolean'];
    language: LanguageInput;
    passenger: PassengerInput;
    passengerType: PassengerType;
    travelTypes: TravelType[];
};

export type Itinerary = {
    __typename?: 'Itinerary';
    arrivalDate: Scalars['String'];
    arrivalTime: Scalars['String'];
    /** format: YYYY-MM-DD HH:mm:ss */
    bookingDeadline: Scalars['String'];
    departureDate: Scalars['String'];
    departureTime: Scalars['String'];
    hasBookingRequirements: Scalars['Boolean'];
    hasStopRequest: Scalars['Boolean'];
    id: Scalars['String'];
    isBookable: Scalars['Boolean'];
    isCancelled: Scalars['Boolean'];
    legs: Leg[];
    remainingTimeBeforeBooking: Scalars['Int'];
    scheduledArrivalDate?: Maybe<Scalars['String']>;
    scheduledArrivalTime?: Maybe<Scalars['String']>;
    scheduledDepartureDate?: Maybe<Scalars['String']>;
    scheduledDepartureTime?: Maybe<Scalars['String']>;
};

export type ItineraryArticle = Article & {
    __typename?: 'ItineraryArticle';
    articleNumber?: Maybe<LocalizedOptionItem>;
    category?: Maybe<Category>;
    categoryDescription?: Maybe<Scalars['String']>;
    conditions?: Maybe<Scalars['String'][]>;
    /** added */
    description?: Maybe<Scalars['String']>;
    includes?: Maybe<Scalars['String'][]>;
    isSupersaver?: Maybe<Scalars['Boolean']>;
    /** on bought ticket */
    locationsChoice?: Maybe<LocalizedOptionItems>;
    locationsValidity?: Maybe<LocalizedOptionItems>;
    novaId?: Maybe<Scalars['ID']>;
    offerNumber?: Maybe<Scalars['String']>;
    paymentMeans?: Maybe<PaymentMeans[]>;
    prices?: Maybe<Price[]>;
    reduction?: Maybe<Reduction>;
    roundTrip?: Maybe<Scalars['String']>;
    routeQualifiers?: Maybe<Scalars['String']>;
    smsCode?: Maybe<Scalars['String']>;
    supplementQualifiers?: Maybe<Scalars['String']>;
    tarifOwner?: Maybe<Scalars['String']>;
    /** TPC Specific */
    thirdPartyPayerInformation?: Maybe<ThirdPartyPayerInformation>;
    title?: Maybe<Scalars['String']>;
    travelClass?: Maybe<TravelClass>;
    travelType?: Maybe<TravelType>;
    type: ArticleType;
    unavailabilityReason?: Maybe<OptionItem>;
    validity?: Maybe<Validity>;
    validityDurationLabel?: Maybe<Scalars['String']>;
    /** on bought ticket */
    vatLabel?: Maybe<Scalars['String']>;
    zones?: Maybe<Zone[]>;
};

export type ItineraryArticleBasketInput = {
    articleSelections: ItineraryArticleSelectionInput[];
    itinerary: ItineraryBasketInput;
    requestStop: Scalars['Boolean'];
};

export type ItineraryArticleInput = {
    amount?: InputMaybe<PriceInput>;
    category?: InputMaybe<CategoryInput>;
    description?: InputMaybe<Scalars['String']>;
    isSupersaver?: InputMaybe<Scalars['Boolean']>;
    novaId?: InputMaybe<Scalars['ID']>;
    offerNumber?: InputMaybe<Scalars['ID']>;
    prices?: InputMaybe<PriceInput[]>;
    reduction?: InputMaybe<ReductionInput>;
    smsCode?: InputMaybe<Scalars['String']>;
    thirdPartyPayerInformation?: InputMaybe<ThirdPartyPayerInformationInput>;
    title?: InputMaybe<Scalars['String']>;
    travelClass?: InputMaybe<TravelClassInput>;
    travelType?: InputMaybe<TravelType>;
    unavailabilityReason?: InputMaybe<OptionItemInput>;
    validFrom?: InputMaybe<Scalars['String']>;
    validTo?: InputMaybe<Scalars['String']>;
    zones?: InputMaybe<ZoneInput[]>;
};

export type ItineraryArticleSelectionInput = {
    article: ItineraryArticleInput;
    isAuthenticatedCustomer: Scalars['Boolean'];
    passenger: PassengerInput;
    passengerType: PassengerType;
};

export type ItineraryBasketInput = {
    arrivalDate: Scalars['String'];
    arrivalTime: Scalars['String'];
    bookingDeadline: Scalars['String'];
    departureDate: Scalars['String'];
    departureTime: Scalars['String'];
    hasBookingRequirements: Scalars['Boolean'];
    hasStopRequest?: Scalars['Boolean'];
    id: Scalars['String'];
    isBookable: Scalars['Boolean'];
    isCancelled: Scalars['Boolean'];
    legs: LegInput[];
    remainingTimeBeforeBooking: Scalars['Int'];
    scheduledArrivalDate: Scalars['String'];
    scheduledArrivalTime: Scalars['String'];
    scheduledDepartureDate: Scalars['String'];
    scheduledDepartureTime: Scalars['String'];
};

export type ItineraryLegInput = {
    arrivalDateTime?: InputMaybe<Scalars['Date']>;
    arrivalStopDidok: Scalars['String'];
    departureDateTime: Scalars['Date'];
    departureStopDidok: Scalars['String'];
    isFirstClassAuthorized: Scalars['Boolean'];
    novaCompanyId: Scalars['String'];
    serviceNumber?: InputMaybe<Scalars['String']>;
    transitStopsDidok: Scalars['String'][];
    transportCode: Scalars['String'];
};

export type ItineraryOrder = Order & {
    __typename?: 'ItineraryOrder';
    customerId?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    itinerary?: Maybe<Itinerary>;
    orderType: OrderType;
    ticketsTransaction?: Maybe<TicketsTransaction>;
};

export type ItineraryStopRequest = {
    __typename?: 'ItineraryStopRequest';
    customerId?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    itinerary?: Maybe<Itinerary>;
};

export type JourneyMessage = {
    __typename?: 'JourneyMessage';
    htmlContent: Scalars['String'];
    id: Scalars['ID'];
    title: Scalars['String'];
    type: JourneyMessageType;
};

export type JourneyMessageInput = {
    htmlContent: Scalars['String'];
    id: Scalars['ID'];
    title: Scalars['String'];
    type: JourneyMessageType;
};

export enum JourneyMessageType {
    Cancellation = 'CANCELLATION',
    Disruption = 'DISRUPTION',
}

export type Language = {
    __typename?: 'Language';
    id: Scalars['ID'];
    /**  human readable name  */
    name?: Maybe<Scalars['String']>;
};

export type LanguageInput = {
    id: Scalars['ID'];
    /**  deprecated remove me on next breaking change  */
    value?: InputMaybe<Scalars['String']>;
};

export type Leg = {
    __typename?: 'Leg';
    additionalInformation?: Maybe<Scalars['String'][]>;
    bookingDeadline: Scalars['String'];
    byFoot: Scalars['Boolean'];
    duration: Scalars['String'];
    geoJson?: Maybe<Scalars['GeoJson']>;
    isBookable: Scalars['Boolean'];
    isFirstClassAuthorized: Scalars['Boolean'];
    length: Scalars['String'];
    line: Line;
    messages: JourneyMessage[];
    serviceId: Scalars['String'];
    serviceNumber?: Maybe<Scalars['String']>;
    stops: CommercialStop[];
    transportCompanyId: Scalars['String'];
    vehicle: Vehicle;
    wkt?: Maybe<Scalars['String']>;
};

export type LegInput = {
    bookingDeadline: Scalars['String'];
    byFoot: Scalars['Boolean'];
    duration: Scalars['String'];
    isBookable: Scalars['Boolean'];
    isFirstClassAuthorized: Scalars['Boolean'];
    length: Scalars['String'];
    line: LineInput;
    messages: JourneyMessageInput[];
    serviceId: Scalars['String'];
    stops: CommercialStopInput[];
    transportCompanyId: Scalars['String'];
    vehicle: VehicleInput;
    wkt?: InputMaybe<Scalars['String']>;
};

export type Line = {
    __typename?: 'Line';
    color?: Maybe<Scalars['String']>;
    destination: Scalars['String'];
    fontColor?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lineOfficialCode: Scalars['String'];
    networkId?: Maybe<Scalars['String']>;
    number: Scalars['String'];
    style: Scalars['String'];
};

export type LineInput = {
    color?: InputMaybe<Scalars['String']>;
    destination: Scalars['String'];
    fontColor?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    lineOfficialCode: Scalars['String'];
    networkId?: InputMaybe<Scalars['String']>;
    number: Scalars['String'];
    style: Scalars['String'];
};

export type LineStyle = {
    __typename?: 'LineStyle';
    color?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    image?: Maybe<Scalars['String']>;
    lineOfficialCode?: Maybe<Scalars['String']>;
    longName?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    networkId?: Maybe<Scalars['String']>;
    style?: Maybe<Scalars['String']>;
    textColor?: Maybe<Scalars['String']>;
};

export type LocalizedOptionItem = {
    __typename?: 'LocalizedOptionItem';
    label?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type LocalizedOptionItems = {
    __typename?: 'LocalizedOptionItems';
    label?: Maybe<Scalars['String']>;
    values?: Maybe<Scalars['String'][]>;
};

export type MainStopPlace = {
    __typename?: 'MainStopPlace';
    cityName?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    latLon: Scalars['Float'][];
    name: Scalars['String'];
    scoring?: Maybe<Scalars['Float']>;
};

export type MarketingConsents = {
    __typename?: 'MarketingConsents';
    company?: Maybe<Scalars['Boolean']>;
    national?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    article: ArticleMutation;
    articlePublic: ArticlePublicMutation;
    auth: AuthMutation;
    customer: CustomerMutation;
    onAuthentication: Scalars['Boolean'];
    order: OrderMutation;
    orderPublic: OrderPublicMutation;
    preferencesMutation: PreferencesMutation;
    purchase: PurchaseMutation;
    purchasePublic: PurchasePublicMutation;
};

export type OptionItem = {
    __typename?: 'OptionItem';
    label?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type OptionItemInput = {
    label?: InputMaybe<Scalars['String']>;
    value?: InputMaybe<Scalars['String']>;
};

export type Order = {
    customerId?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    orderType: OrderType;
    ticketsTransaction?: Maybe<TicketsTransaction>;
};

export type OrderGroup = {
    __typename?: 'OrderGroup';
    departureOrders: DepartureOrder[];
    departureStopRequests: DepartureStopRequest[];
    itineraryOrders: ItineraryOrder[];
    itineraryStopRequests: ItineraryStopRequest[];
    processingOrders: ProcessingOrder[];
    quickTicketOrders: QuickTicketsOrder[];
};

export type OrderInput = {
    orderId: Scalars['ID'];
};

export type OrderMutation = {
    __typename?: 'OrderMutation';
    /** Note: we should return the object which has been mutated  ItineraryOrder | QuickTicketsOrder */
    cancelStopRequest: Result;
    /** Note: we should return the object which has been mutated  ItineraryOrder | QuickTicketsOrder */
    requestDepartureStop: DepartureStopRequest;
    /** Note: we should return the object which has been mutated  ItineraryOrder | QuickTicketsOrder */
    requestItineraryStop: ItineraryStopRequest;
};

export type OrderMutationCancelStopRequestArgs = {
    orderId: Scalars['ID'];
};

export type OrderMutationRequestDepartureStopArgs = {
    departure: RequestDepartureStopInput;
};

export type OrderMutationRequestItineraryStopArgs = {
    legs: RequestItineraryLegInput[];
};

export type OrderPublicMutation = {
    __typename?: 'OrderPublicMutation';
    setTicketsToSeen: Result;
    tryCancelOrder: OrderStatusResult;
};

export type OrderPublicMutationSetTicketsToSeenArgs = {
    orders: OrderInput[];
};

export type OrderPublicMutationTryCancelOrderArgs = {
    cancellationType: CancellationType;
    orderId: Scalars['String'];
};

export type OrderPublicQuery = {
    __typename?: 'OrderPublicQuery';
    getItineraryOrder?: Maybe<ItineraryOrder>;
    getOrderStatus: OrderStatus;
    getQuickTicketsOrder?: Maybe<QuickTicketsOrder>;
};

export type OrderPublicQueryGetItineraryOrderArgs = {
    order: OrderInput;
};

export type OrderPublicQueryGetOrderStatusArgs = {
    orderId: Scalars['String'];
};

export type OrderPublicQueryGetQuickTicketsOrderArgs = {
    order: OrderInput;
};

export type OrderQuery = {
    __typename?: 'OrderQuery';
    getAllOrders: OrderGroup;
    getConfirmedDepartureOrders?: Maybe<DepartureOrder[]>;
    getConfirmedItineraryOrders?: Maybe<ItineraryOrder[]>;
    getConfirmedQuickTicketsOrders?: Maybe<QuickTicketsOrder[]>;
    getDepartureOrder?: Maybe<DepartureOrder>;
    getDepartureStopRequest?: Maybe<DepartureStopRequest>;
    getDepartureStopRequests?: Maybe<DepartureStopRequest[]>;
    getItineraryStopRequest?: Maybe<ItineraryStopRequest>;
    getItineraryStopRequests?: Maybe<ItineraryStopRequest[]>;
};

export type OrderQueryGetAllOrdersArgs = {
    afterBookingDate?: InputMaybe<Scalars['String']>;
    cardId?: InputMaybe<Scalars['String']>;
};

export type OrderQueryGetDepartureOrderArgs = {
    order: OrderInput;
};

export type OrderQueryGetDepartureStopRequestArgs = {
    id: Scalars['String'];
};

export type OrderQueryGetItineraryStopRequestArgs = {
    id: Scalars['String'];
};

export enum OrderStatus {
    Canceled = 'CANCELED',
    Error = 'ERROR',
    Generating = 'GENERATING',
    Ready = 'READY',
    WaitingPaymentNotify = 'WAITING_PAYMENT_NOTIFY',
}

export type OrderStatusResult = {
    __typename?: 'OrderStatusResult';
    orderStatus?: Maybe<OrderStatus>;
    success: Scalars['Boolean'];
};

export enum OrderType {
    BuyDepartureTickets = 'BUY_DEPARTURE_TICKETS',
    BuyItineraryTickets = 'BUY_ITINERARY_TICKETS',
    BuyQuickTicket = 'BUY_QUICK_TICKET',
    DepartureStopRequest = 'DEPARTURE_STOP_REQUEST',
    ItineraryStopRequest = 'ITINERARY_STOP_REQUEST',
    SaveItinerary = 'SAVE_ITINERARY',
}

export type PartialCreditCard = {
    brand: Scalars['String'];
    countryCode?: Maybe<Scalars['String']>;
    expirationMonth: Scalars['Int'];
    expirationYear: Scalars['Int'];
    gatewayReference: GatewayReference;
    holderName?: Maybe<Scalars['String']>;
    maskedNumber: Scalars['String'];
    primary?: Maybe<Scalars['Boolean']>;
};

export type Passenger = {
    __typename?: 'Passenger';
    birthDate: Scalars['Date'];
    firstName: Scalars['String'];
    hasHalfFare: Scalars['Boolean'];
    /**  Only Synthese require to store passenger id in tickets  */
    id?: Maybe<Scalars['ID']>;
    lastName: Scalars['String'];
    type?: Maybe<PassengerType>;
};

export type PassengerInput = {
    birthDate: Scalars['Date'];
    email?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    hasHalfFare: Scalars['Boolean'];
    id?: InputMaybe<Scalars['ID']>;
    lastName: Scalars['String'];
    title: Scalars['String'];
};

export enum PassengerType {
    Bike = 'bike',
    Dog = 'dog',
    Person = 'person',
}

export enum PaymentMeans {
    CreditCard = 'CreditCard',
    Sms = 'Sms',
}

export type PaymentMethodInput = {
    cardAlias: CardAliasInput;
    clientPlatform: ClientPlatform;
    paymentMethodName?: InputMaybe<Scalars['String']>;
};

export type PaymentMethodQuery = {
    __typename?: 'PaymentMethodQuery';
    cards: Scalars['String'][];
    mobiles: Scalars['String'][];
};

export type PaymentPage = {
    __typename?: 'PaymentPage';
    paymentPageUrl?: Maybe<Scalars['String']>;
};

export type PaymentResult = {
    __typename?: 'PaymentResult';
    done: Scalars['Boolean'];
    error?: Maybe<CheckoutError>;
    externalPaymentUrl?: Maybe<Scalars['String']>;
};

export enum PaymentState {
    AssertError = 'ASSERT_ERROR',
    AssertSuccess = 'ASSERT_SUCCESS',
    CaptureError = 'CAPTURE_ERROR',
    CaptureSuccess = 'CAPTURE_SUCCESS',
    GenerateProcessing = 'GENERATE_PROCESSING',
    Initial = 'INITIAL',
    InitializeError = 'INITIALIZE_ERROR',
    InitializeSuccess = 'INITIALIZE_SUCCESS',
    PaymentNotifyTimedOut = 'PAYMENT_NOTIFY_TIMED_OUT',
    Prepaid = 'PREPAID',
    PrepaidByThirdPartyApp = 'PREPAID_BY_THIRD_PARTY_APP',
    PrepaidByThirdPartyBatch = 'PREPAID_BY_THIRD_PARTY_BATCH',
    RefundError = 'REFUND_ERROR',
    RefundSuccess = 'REFUND_SUCCESS',
}

export type PhysicalStop = {
    __typename?: 'PhysicalStop';
    associatedCommercialStop?: Maybe<AssociatedCommercialStop>;
    geometry: Scalars['String'];
    id: Scalars['ID'];
    letter?: Maybe<Scalars['String']>;
    lines: Line[];
    transportMode?: Maybe<Scalars['String']>;
};

export type PhysicalStopInput = {
    associatedCommercialStop?: InputMaybe<AssociatedCommercialStopInput>;
    geometry: Scalars['String'];
    id: Scalars['ID'];
    letter?: InputMaybe<Scalars['String']>;
    lines: LineInput[];
    transportMode?: InputMaybe<Scalars['String']>;
};

export type PhysicalStopQuery = {
    __typename?: 'PhysicalStopQuery';
    byBoundingBox: PhysicalStop[];
    byCoordinates: PhysicalStop[];
};

export type PhysicalStopQueryByBoundingBoxArgs = {
    boundingBox: BoundingBoxInput;
};

export type PhysicalStopQueryByCoordinatesArgs = {
    coordinates: CoordinatesInput;
};

export type PlaceQuery = {
    __typename?: 'PlaceQuery';
    search: Places;
};

export type PlaceQuerySearchArgs = {
    searchQueryInput: SearchQueryInput;
};

export type Places = {
    __typename?: 'Places';
    address: AddressPlace[];
    cities: CityPlace[];
    poi: PoiPlace[];
    stops: MainStopPlace[];
};

export type PoiPlace = {
    __typename?: 'PoiPlace';
    cityName: Scalars['String'];
    id: Scalars['String'];
    latLon: Scalars['Float'][];
    name: Scalars['String'];
    scoring: Scalars['Float'];
};

export type PreferencesMutation = {
    __typename?: 'PreferencesMutation';
    updateNationalMarketingConsent: Result;
};

export type PreferencesMutationUpdateNationalMarketingConsentArgs = {
    nationalMarketingConsent: Scalars['Boolean'];
};

export type PreferencesQuery = {
    __typename?: 'PreferencesQuery';
    marketingConsents: MarketingConsents;
};

export type Preflight = {
    __typename?: 'Preflight';
    isClientOutdated: Scalars['Boolean'];
};

export type Price = {
    __typename?: 'Price';
    amountInCents: Scalars['Int'];
    currency: Currency;
};

export type PriceInput = {
    amountInCents: Scalars['Int'];
    currency: Currency;
};

export type ProcessingOrder = {
    __typename?: 'ProcessingOrder';
    id: Scalars['ID'];
    orderType: OrderType;
    passenger: Passenger;
    purchaseDate: Scalars['String'];
};

export type PublicReferencesQuery = {
    __typename?: 'PublicReferencesQuery';
    frequentTravelerTitles: Title[];
};

export type PublicReferencesQueryFrequentTravelerTitlesArgs = {
    language?: InputMaybe<Scalars['String']>;
};

export type PurchaseMutation = {
    __typename?: 'PurchaseMutation';
    buyDepartureTicketArticles: TicketsTransactionResult;
    saveItinerary: ItineraryOrder;
};

export type PurchaseMutationBuyDepartureTicketArticlesArgs = {
    basket: DepartureArticleBasketInput;
};

export type PurchaseMutationSaveItineraryArgs = {
    itineraryBasket: ItineraryBasketInput;
};

export type PurchasePublicMutation = {
    __typename?: 'PurchasePublicMutation';
    buyItineraryTicketArticles: TicketsTransactionResult;
    buyQuickTicketArticles: TicketsTransactionResult;
    pay: PaymentResult;
    refundDepartureTicketOrder: RefundDepartureTicketResult;
    refundItineraryTicketOrder: RefundItineraryTicketResult;
    refundQuickTicketOrder: RefundQuickTicketResult;
};

export type PurchasePublicMutationBuyItineraryTicketArticlesArgs = {
    basket: ItineraryArticleBasketInput;
};

export type PurchasePublicMutationBuyQuickTicketArticlesArgs = {
    basket: QuickArticlesBasketInput;
};

export type PurchasePublicMutationPayArgs = {
    language: LanguageInput;
    orderId: Scalars['String'];
    paymentMethod: PaymentMethodInput;
    successRedirectUrl: Scalars['String'];
};

export type PurchasePublicMutationRefundDepartureTicketOrderArgs = {
    language: LanguageInput;
    order: OrderInput;
};

export type PurchasePublicMutationRefundItineraryTicketOrderArgs = {
    language: LanguageInput;
    order: OrderInput;
};

export type PurchasePublicMutationRefundQuickTicketOrderArgs = {
    language: LanguageInput;
    order: OrderInput;
};

export type Query = {
    __typename?: 'Query';
    auth: AuthQuery;
    configuration: ConfigurationQuery;
    customer: CustomerQuery;
    debug?: Maybe<DebugOps>;
    departure: DepartureQuery;
    getQuickArticles: QuickArticle[];
    itineraries: Itinerary[];
    linesStyles: LineStyle[];
    order: OrderQuery;
    orderPublic: OrderPublicQuery;
    physicalStops: PhysicalStopQuery;
    places: PlaceQuery;
    preferences: PreferencesQuery;
    preflight: Preflight;
    references: PublicReferencesQuery;
    route: Route;
    smsTickets: SmsTicketCategory[];
    startupNotifications: StartupNotificationMessage[];
    transportModes: TransportMode[];
    update: Update;
};

export type QueryItinerariesArgs = {
    arrival: CoordinatesOrStopIdsInput;
    count: Scalars['Int'];
    dateTime: Scalars['Date'];
    departure: CoordinatesOrStopIdsInput;
    lang: Scalars['String'];
    searchDirection: SearchDirection;
    transportModes: Scalars['String'][];
    withDirectItineraries: Scalars['Boolean'];
};

export type QueryRouteArgs = {
    routeInput: RouteInput;
};

export type QuickArticle = Article & {
    __typename?: 'QuickArticle';
    articleId: Scalars['ID'];
    articleNumber?: Maybe<LocalizedOptionItem>;
    category?: Maybe<Category>;
    categoryDescription?: Maybe<Scalars['String']>;
    /** added */
    description?: Maybe<Scalars['String']>;
    /** on bought ticket */
    locationsChoice?: Maybe<LocalizedOptionItems>;
    locationsValidity?: Maybe<LocalizedOptionItems>;
    paymentMeans?: Maybe<PaymentMeans[]>;
    prices?: Maybe<Price[]>;
    reduction?: Maybe<Reduction>;
    smsCode?: Maybe<Scalars['String']>;
    tarifOwner?: Maybe<Scalars['String']>;
    /** TPC Specific */
    thirdPartyPayerInformation?: Maybe<ThirdPartyPayerInformation>;
    title?: Maybe<Scalars['String']>;
    travelClass?: Maybe<TravelClass>;
    travelType?: Maybe<TravelType>;
    tripClass?: Maybe<Scalars['String']>;
    type: ArticleType;
    unavailabilityReason?: Maybe<OptionItem>;
    validity?: Maybe<Validity>;
    validityDurationLabel?: Maybe<Scalars['String']>;
    /** on bought ticket */
    vatLabel?: Maybe<Scalars['String']>;
    zones?: Maybe<Zone[]>;
};

export type QuickArticleSelectionInput = {
    articleId: Scalars['ID'];
    articlePrice: PriceInput;
    isAuthenticatedCustomer: Scalars['Boolean'];
    passenger: PassengerInput;
    passengerType: PassengerType;
    thirdPartyPayerInformation?: InputMaybe<ThirdPartyPayerInformationInput>;
    zones?: InputMaybe<ZoneInput[]>;
};

export type QuickArticlesBasketInput = {
    articleSelections: QuickArticleSelectionInput[];
};

export type QuickTicketsOrder = Order & {
    __typename?: 'QuickTicketsOrder';
    customerId?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    orderType: OrderType;
    ticketsTransaction?: Maybe<TicketsTransaction>;
    zones?: Maybe<Zone[]>;
};

export type Reduction = {
    __typename?: 'Reduction';
    description: Scalars['String'];
    id?: Maybe<ReductionType>;
};

export type ReductionInput = {
    description: Scalars['String'];
    id?: InputMaybe<ReductionType>;
};

export enum ReductionType {
    Bike = 'BIKE',
    Dog = 'DOG',
    HalfFare = 'HALF_FARE',
    NoReduction = 'NO_REDUCTION',
    Unknown = 'UNKNOWN',
}

export type RefreshIntervalQuery = {
    __typename?: 'RefreshIntervalQuery';
    autoScrollRefreshIntervalInSeconds: Scalars['Int'];
    departureRefreshIntervalInSeconds: Scalars['Int'];
    itineraryRefreshIntervalInSeconds: Scalars['Int'];
};

export type RefundDepartureTicketResult = RefundResult & {
    __typename?: 'RefundDepartureTicketResult';
    error?: Maybe<Scalars['String']>;
    order?: Maybe<DepartureOrder>;
    orderType?: Maybe<OrderType>;
    success: Scalars['Boolean'];
};

export type RefundItineraryTicketResult = RefundResult & {
    __typename?: 'RefundItineraryTicketResult';
    error?: Maybe<Scalars['String']>;
    order?: Maybe<ItineraryOrder>;
    orderType?: Maybe<OrderType>;
    success: Scalars['Boolean'];
};

export type RefundQuickTicketResult = RefundResult & {
    __typename?: 'RefundQuickTicketResult';
    error?: Maybe<Scalars['String']>;
    order?: Maybe<QuickTicketsOrder>;
    orderType?: Maybe<OrderType>;
    success: Scalars['Boolean'];
};

export type RefundResult = {
    error?: Maybe<Scalars['String']>;
    orderType?: Maybe<OrderType>;
    success: Scalars['Boolean'];
};

export type RequestDepartureStopInput = {
    bookingDeadline: Scalars['String'];
    departureDate: Scalars['String'];
    hasBookingRequirements: Scalars['Boolean'];
    isBookable: Scalars['Boolean'];
    remainingTimeBeforeBooking?: InputMaybe<Scalars['Int']>;
    serviceId: Scalars['ID'];
    stop: DepartureBasketStopInput;
};

export type RequestItineraryCommercialStopInput = {
    arrivalTime: Scalars['String'];
    cityName?: InputMaybe<Scalars['String']>;
    departureTime: Scalars['String'];
    didok?: InputMaybe<Scalars['Int']>;
    hasBookingRequirements: Scalars['Boolean'];
    id: Scalars['String'];
    isArrivalPlace: Scalars['Boolean'];
    isCancelled: Scalars['Boolean'];
    isDeparturePlace: Scalars['Boolean'];
    isOptional: Scalars['Boolean'];
    latLon: Scalars['Float'][];
    messages: JourneyMessageInput[];
    name: Scalars['String'];
    physicalStopAssociated?: InputMaybe<PhysicalStopInput>;
    scheduledArrivalTime?: InputMaybe<Scalars['String']>;
    scheduledDepartureTime?: InputMaybe<Scalars['String']>;
};

export type RequestItineraryLegInput = {
    bookingDeadline: Scalars['String'];
    byFoot: Scalars['Boolean'];
    duration: Scalars['String'];
    isBookable: Scalars['Boolean'];
    isFirstClassAuthorized: Scalars['Boolean'];
    length: Scalars['String'];
    line: LineInput;
    messages: JourneyMessageInput[];
    serviceId: Scalars['String'];
    stops: RequestItineraryCommercialStopInput[];
    transportCompanyId: Scalars['String'];
    vehicle: VehicleInput;
    wkt?: InputMaybe<Scalars['String']>;
};

export type ResponseError = {
    __typename?: 'ResponseError';
    errorCode?: Maybe<Scalars['String']>;
    httpCode?: Maybe<Scalars['Int']>;
    originUrl?: Maybe<Scalars['String']>;
    redirectUrl?: Maybe<Scalars['String']>;
};

export type Result = {
    __typename?: 'Result';
    error?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export type Route = {
    __typename?: 'Route';
    id: Scalars['ID'];
    line: Line;
    stops: TransitStop[];
};

export type RouteInput = {
    departureDatetime: Scalars['Date'];
    lang: Scalars['String'];
    serviceId: Scalars['ID'];
    startRank: Scalars['Int'];
    stopId: Scalars['ID'];
};

export enum SaferpayInterface {
    Page = 'PAGE',
    Transaction = 'TRANSACTION',
}

export enum SearchDirection {
    ArriveBeforeDate = 'ArriveBeforeDate',
    LeaveAfterDate = 'LeaveAfterDate',
}

export type SearchQueryInput = {
    currentLatLng: CoordinatesInput;
    term: Scalars['String'];
};

export type SmsCurrencyPrice = {
    __typename?: 'SmsCurrencyPrice';
    amountInCents: Scalars['Int'];
    currency: Scalars['String'];
};

export type SmsLanguageLabel = {
    __typename?: 'SmsLanguageLabel';
    label: Scalars['String'];
    languageCode: Scalars['String'];
};

export type SmsTicket = {
    __typename?: 'SmsTicket';
    code: Scalars['String'];
    label: SmsLanguageLabel[];
    order: Scalars['Int'];
    price: SmsCurrencyPrice[];
};

export type SmsTicketCategory = {
    __typename?: 'SmsTicketCategory';
    id?: Maybe<Scalars['ID']>;
    label: SmsLanguageLabel[];
    order: Scalars['Int'];
    subCategories?: Maybe<SmsTicketSubCategory[]>;
    tickets?: Maybe<SmsTicket[]>;
};

export type SmsTicketSubCategory = {
    __typename?: 'SmsTicketSubCategory';
    label: SmsLanguageLabel[];
    order: Scalars['Int'];
    tickets: SmsTicket[];
};

export type StartupNotificationMessage = {
    __typename?: 'StartupNotificationMessage';
    htmlContent: Scalars['String'];
    id: Scalars['ID'];
    title: Scalars['String'];
};

export type ThirdPartyPayerInformation = {
    __typename?: 'ThirdPartyPayerInformation';
    fare: Scalars['Float'];
    isEligible: Scalars['Boolean'];
    participationLevelInPercent?: Maybe<Scalars['Int']>;
    thirdPartyName: Scalars['String'];
};

export type ThirdPartyPayerInformationInput = {
    fare: Scalars['Float'];
    isEligible: Scalars['Boolean'];
    participationLevelInPercent?: InputMaybe<Scalars['Int']>;
    thirdPartyName: Scalars['String'];
};

export type ThresholdQuery = {
    __typename?: 'ThresholdQuery';
    minimumThresholdToShowIconInSeconds: Scalars['Int'];
    minutesThresholdToDisplayWaitingMinutes: Scalars['Int'];
};

export type Ticket = {
    __typename?: 'Ticket';
    article: Article;
    articleNumber?: Maybe<LocalizedOptionItem>;
    bookingTransactionId?: Maybe<Scalars['String']>;
    departureStopName?: Maybe<Scalars['String']>;
    duration?: Maybe<Duration>;
    fqCode?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    orderNumber?: Maybe<Scalars['String']>;
    passenger: Passenger;
    passengerType?: Maybe<PassengerType>;
    price: Price;
    purchaseDate?: Maybe<Scalars['String']>;
    qrCodeData?: Maybe<Scalars['String']>;
    referenceNumber?: Maybe<LocalizedOptionItem>;
    refundDate?: Maybe<Scalars['String']>;
    refundOrderNumber?: Maybe<Scalars['String']>;
    refundReferenceNumber?: Maybe<Scalars['String']>;
    ticketNumber?: Maybe<LocalizedOptionItem>;
};

export enum TicketState {
    AuthorizeError = 'AUTHORIZE_ERROR',
    AuthorizeSuccess = 'AUTHORIZE_SUCCESS',
    BoldorCreateSuccess = 'BOLDOR_CREATE_SUCCESS',
    CanceledInApp = 'CANCELED_IN_APP',
    ConfirmError = 'CONFIRM_ERROR',
    ConfirmRefundError = 'CONFIRM_REFUND_ERROR',
    ConfirmRefundSuccess = 'CONFIRM_REFUND_SUCCESS',
    ConfirmSuccess = 'CONFIRM_SUCCESS',
    DocumentUpdateError = 'DOCUMENT_UPDATE_ERROR',
    DocumentUpdateSuccess = 'DOCUMENT_UPDATE_SUCCESS',
    Initial = 'INITIAL',
    RefundAuthorizeError = 'REFUND_AUTHORIZE_ERROR',
    RefundAuthorizeSuccess = 'REFUND_AUTHORIZE_SUCCESS',
    RefundBoldorCreateSuccess = 'REFUND_BOLDOR_CREATE_SUCCESS',
    RefundCreateError = 'REFUND_CREATE_ERROR',
    RefundCreateSuccess = 'REFUND_CREATE_SUCCESS',
    RefundDocumentUpdateError = 'REFUND_DOCUMENT_UPDATE_ERROR',
    RefundDocumentUpdateSuccess = 'REFUND_DOCUMENT_UPDATE_SUCCESS',
    RefundError = 'REFUND_ERROR',
    RefundRecordError = 'REFUND_RECORD_ERROR',
    RefundRecordSuccess = 'REFUND_RECORD_SUCCESS',
    RefundSuccess = 'REFUND_SUCCESS',
    SdsCreateError = 'SDS_CREATE_ERROR',
    SdsCreateSuccess = 'SDS_CREATE_SUCCESS',
    SdsRecordError = 'SDS_RECORD_ERROR',
    SdsRecordSuccess = 'SDS_RECORD_SUCCESS',
    SeenByClient = 'SEEN_BY_CLIENT',
}

export type TicketsTransaction = {
    __typename?: 'TicketsTransaction';
    cardAliasId?: Maybe<Scalars['String']>;
    currency?: Maybe<Currency>;
    customerId?: Maybe<Scalars['String']>;
    externalTransactionId?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    paymentState: PaymentState;
    saferpayInterface?: Maybe<SaferpayInterface>;
    saferpayToken?: Maybe<Scalars['String']>;
    saferpayTransactionId?: Maybe<Scalars['String']>;
    ticketState: TicketState;
    tickets?: Maybe<Ticket[]>;
};

export type TicketsTransactionResult = {
    __typename?: 'TicketsTransactionResult';
    orderId?: Maybe<Scalars['ID']>;
};

export type Title = {
    __typename?: 'Title';
    label: Scalars['String'];
    value: Scalars['String'];
};

export type TransitStop = {
    __typename?: 'TransitStop';
    arrivalDate?: Maybe<Scalars['String']>;
    arrivalRealTime?: Maybe<Scalars['String']>;
    arrivalRealTimeDate?: Maybe<Scalars['String']>;
    arrivalTime?: Maybe<Scalars['String']>;
    cityName?: Maybe<Scalars['String']>;
    coordinates: Coordinates;
    departureDate?: Maybe<Scalars['String']>;
    departureRealTime?: Maybe<Scalars['String']>;
    departureRealTimeDate?: Maybe<Scalars['String']>;
    departureTime?: Maybe<Scalars['String']>;
    hasBookingRequirements?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['String']>;
    isCancelled?: Maybe<Scalars['Boolean']>;
    isOptional?: Maybe<Scalars['Boolean']>;
    letter?: Maybe<Scalars['String']>;
    messages: JourneyMessage[];
    rank?: Maybe<Scalars['String']>;
    stopAreaName?: Maybe<Scalars['String']>;
    stopPointName?: Maybe<Scalars['String']>;
    tadId?: Maybe<Scalars['String']>;
};

export type TransportMode = {
    __typename?: 'TransportMode';
    compatibleTransportModes: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    plural: Scalars['String'];
    singular: Scalars['String'];
};

export type TravelClass = {
    __typename?: 'TravelClass';
    description?: Maybe<Scalars['String']>;
    id: TravelClassType;
};

export type TravelClassInput = {
    description?: InputMaybe<Scalars['String']>;
    id: TravelClassType;
};

export enum TravelClassType {
    Class_1 = 'CLASS_1',
    Class_2 = 'CLASS_2',
    ClassChange = 'CLASS_CHANGE',
}

export enum TravelType {
    BikeDailyTicket = 'BikeDailyTicket',
    CityTicket = 'CityTicket',
    ClassUpgrade = 'ClassUpgrade',
    Connection = 'Connection',
    Daily = 'Daily',
    DogDailyTicket = 'DogDailyTicket',
    ShortJourney = 'ShortJourney',
    Simple = 'Simple',
    Simple2H = 'Simple2H',
}

export type Update = {
    __typename?: 'Update';
    forceUpdate: Scalars['Boolean'];
    messageHtml: Scalars['String'];
};

export type Validity = {
    __typename?: 'Validity';
    from?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    to?: Maybe<Scalars['String']>;
};

export type Vehicle = {
    __typename?: 'Vehicle';
    code?: Maybe<Scalars['String']>;
    transport: Scalars['String'];
    transportModeId?: Maybe<Scalars['String']>;
};

export type VehicleInput = {
    code?: InputMaybe<Scalars['String']>;
    transport: Scalars['String'];
};

export enum Wayback {
    Go = 'Go',
    Return = 'Return',
}

export type Zone = {
    __typename?: 'Zone';
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
};

export type ZoneInput = {
    id: Scalars['ID'];
};

export type AssociatedCommercialStopFragment = {
    __typename?: 'AssociatedCommercialStop';
    cityName?: string | null;
    id: string;
    name: string;
    didok?: number | null;
    coordinates?: { __typename?: 'Coordinates'; accuracy?: number | null; latitude: number; longitude: number } | null;
    lines: {
        __typename?: 'Line';
        color?: string | null;
        destination: string;
        fontColor?: string | null;
        id: string;
        lineOfficialCode: string;
        networkId?: string | null;
        number: string;
        style: string;
    }[];
};

export type AddressFragment = {
    __typename?: 'Address';
    city?: string | null;
    postalCode?: string | null;
    street?: string | null;
    number?: string | null;
};

export type ProfileFragment = {
    __typename?: 'CustomerProfile';
    id: string;
    birthDate?: any | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    hasHalfFare?: boolean | null;
    phone?: string | null;
    passengerId: string;
    subscriptionNumber?: string | null;
    address?: {
        __typename?: 'Address';
        city?: string | null;
        postalCode?: string | null;
        street?: string | null;
        number?: string | null;
    } | null;
    title: { __typename?: 'Title'; label: string; value: string };
    language?: { __typename?: 'Language'; id: string } | null;
};

export type ErrorFragment = {
    __typename?: 'ResponseError';
    errorCode?: string | null;
    httpCode?: number | null;
    originUrl?: string | null;
    redirectUrl?: string | null;
};

export type DepartureArticleFragment = {
    __typename?: 'DepartureArticle';
    smsCode?: string | null;
    description?: string | null;
    title?: string | null;
    travelType?: TravelType | null;
    vatLabel?: string | null;
    tarifOwner?: string | null;
    paymentMeans?: PaymentMeans[] | null;
    articleId: string;
    category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
    reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
    travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
    zones?: { __typename?: 'Zone'; id: string }[] | null;
    validity?: { __typename?: 'Validity'; from?: string | null; to?: string | null; label?: string | null } | null;
    locationsValidity?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
    locationsChoice?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
};

export type DepartureTicketFragment = {
    __typename?: 'Ticket';
    id?: string | null;
    departureStopName?: string | null;
    purchaseDate?: string | null;
    qrCodeData?: string | null;
    fqCode?: string | null;
    orderNumber?: string | null;
    refundDate?: string | null;
    passengerType?: PassengerType | null;
    refundOrderNumber?: string | null;
    refundReferenceNumber?: string | null;
    article:
        | {
              __typename?: 'DepartureArticle';
              smsCode?: string | null;
              description?: string | null;
              title?: string | null;
              travelType?: TravelType | null;
              vatLabel?: string | null;
              tarifOwner?: string | null;
              paymentMeans?: PaymentMeans[] | null;
              articleId: string;
              category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
              reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
              travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
              zones?: { __typename?: 'Zone'; id: string }[] | null;
              validity?: { __typename?: 'Validity'; from?: string | null; to?: string | null; label?: string | null } | null;
              locationsValidity?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
              locationsChoice?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
          }
        | { __typename?: 'ItineraryArticle' }
        | { __typename?: 'QuickArticle' };
    price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
    passenger: {
        __typename?: 'Passenger';
        id?: string | null;
        firstName: string;
        lastName: string;
        birthDate: any;
        type?: PassengerType | null;
    };
    duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
    referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
    ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
    articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
};

export type DepartureFragment = {
    __typename?: 'Departure';
    additionalInformation?: string[] | null;
    hasStopRequest: boolean;
    departureTimestamp: string;
    plannedDepartureTime: string;
    direction: string;
    outdatedDate?: string | null;
    serviceId: string;
    track: string;
    transport: string;
    networkId?: string | null;
    id: string;
    isBookable: boolean;
    hasBookingRequirements: boolean;
    remainingTimeBeforeBooking: number;
    bookingDeadline: string;
    isCancelled: boolean;
    line: {
        __typename?: 'Line';
        color?: string | null;
        destination: string;
        fontColor?: string | null;
        id: string;
        lineOfficialCode: string;
        networkId?: string | null;
        number: string;
        style: string;
    };
    stop: {
        __typename?: 'DepartureStop';
        cityName?: string | null;
        id: string;
        name: string;
        rank: number;
        isOptional: boolean;
        physicalStop: {
            __typename?: 'PhysicalStop';
            geometry: string;
            id: string;
            letter?: string | null;
            transportMode?: string | null;
            lines: {
                __typename?: 'Line';
                color?: string | null;
                destination: string;
                fontColor?: string | null;
                id: string;
                lineOfficialCode: string;
                networkId?: string | null;
                number: string;
                style: string;
            }[];
            associatedCommercialStop?: {
                __typename?: 'AssociatedCommercialStop';
                cityName?: string | null;
                id: string;
                name: string;
                didok?: number | null;
                coordinates?: { __typename?: 'Coordinates'; accuracy?: number | null; latitude: number; longitude: number } | null;
                lines: {
                    __typename?: 'Line';
                    color?: string | null;
                    destination: string;
                    fontColor?: string | null;
                    id: string;
                    lineOfficialCode: string;
                    networkId?: string | null;
                    number: string;
                    style: string;
                }[];
            } | null;
        };
        messages: { __typename?: 'JourneyMessage'; id: string; htmlContent: string; title: string; type: JourneyMessageType }[];
    };
    messages: { __typename?: 'JourneyMessage'; id: string; htmlContent: string; title: string; type: JourneyMessageType }[];
};

export type ItineraryArticleFragment = {
    __typename?: 'ItineraryArticle';
    smsCode?: string | null;
    description?: string | null;
    title?: string | null;
    travelType?: TravelType | null;
    vatLabel?: string | null;
    tarifOwner?: string | null;
    paymentMeans?: PaymentMeans[] | null;
    conditions?: string[] | null;
    includes?: string[] | null;
    roundTrip?: string | null;
    isSupersaver?: boolean | null;
    category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
    reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
    travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
    zones?: { __typename?: 'Zone'; id: string }[] | null;
    validity?: { __typename?: 'Validity'; from?: string | null; to?: string | null; label?: string | null } | null;
    locationsValidity?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
    locationsChoice?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
    thirdPartyPayerInformation?: { __typename?: 'ThirdPartyPayerInformation'; isEligible: boolean; thirdPartyName: string } | null;
};

export type ItineraryTicketFragment = {
    __typename?: 'Ticket';
    id?: string | null;
    departureStopName?: string | null;
    purchaseDate?: string | null;
    qrCodeData?: string | null;
    fqCode?: string | null;
    orderNumber?: string | null;
    refundDate?: string | null;
    passengerType?: PassengerType | null;
    refundOrderNumber?: string | null;
    refundReferenceNumber?: string | null;
    article:
        | { __typename?: 'DepartureArticle' }
        | {
              __typename?: 'ItineraryArticle';
              smsCode?: string | null;
              description?: string | null;
              title?: string | null;
              travelType?: TravelType | null;
              vatLabel?: string | null;
              tarifOwner?: string | null;
              paymentMeans?: PaymentMeans[] | null;
              conditions?: string[] | null;
              includes?: string[] | null;
              roundTrip?: string | null;
              isSupersaver?: boolean | null;
              category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
              reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
              travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
              zones?: { __typename?: 'Zone'; id: string }[] | null;
              validity?: { __typename?: 'Validity'; from?: string | null; to?: string | null; label?: string | null } | null;
              locationsValidity?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
              locationsChoice?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
              thirdPartyPayerInformation?: {
                  __typename?: 'ThirdPartyPayerInformation';
                  isEligible: boolean;
                  thirdPartyName: string;
              } | null;
          }
        | { __typename?: 'QuickArticle' };
    price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
    passenger: {
        __typename?: 'Passenger';
        id?: string | null;
        firstName: string;
        lastName: string;
        birthDate: any;
        type?: PassengerType | null;
    };
    duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
    referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
    ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
    articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
};

export type ItineraryFragmentFragment = {
    __typename?: 'Itinerary';
    arrivalDate: string;
    arrivalTime: string;
    departureDate: string;
    departureTime: string;
    scheduledArrivalDate?: string | null;
    scheduledArrivalTime?: string | null;
    scheduledDepartureDate?: string | null;
    scheduledDepartureTime?: string | null;
    isBookable: boolean;
    isCancelled: boolean;
    hasBookingRequirements: boolean;
    remainingTimeBeforeBooking: number;
    bookingDeadline: string;
    hasStopRequest: boolean;
    id: string;
    legs: {
        __typename?: 'Leg';
        additionalInformation?: string[] | null;
        byFoot: boolean;
        serviceNumber?: string | null;
        serviceId: string;
        length: string;
        duration: string;
        isBookable: boolean;
        geoJson?: any | null;
        bookingDeadline: string;
        isFirstClassAuthorized: boolean;
        transportCompanyId: string;
        wkt?: string | null;
        line: {
            __typename?: 'Line';
            color?: string | null;
            destination: string;
            fontColor?: string | null;
            id: string;
            lineOfficialCode: string;
            networkId?: string | null;
            number: string;
            style: string;
        };
        vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
        stops: {
            __typename?: 'CommercialStop';
            scheduledArrivalTime?: string | null;
            scheduledDepartureTime?: string | null;
            arrivalTime: string;
            cityName?: string | null;
            departureTime: string;
            hasBookingRequirements: boolean;
            id: string;
            isArrivalPlace: boolean;
            isDeparturePlace: boolean;
            isOptional: boolean;
            isCancelled: boolean;
            latLon: number[];
            name: string;
            didok?: number | null;
            messages: { __typename?: 'JourneyMessage'; id: string; htmlContent: string; title: string; type: JourneyMessageType }[];
            physicalStopAssociated?: {
                __typename?: 'PhysicalStop';
                geometry: string;
                id: string;
                letter?: string | null;
                transportMode?: string | null;
                lines: {
                    __typename?: 'Line';
                    color?: string | null;
                    destination: string;
                    fontColor?: string | null;
                    id: string;
                    lineOfficialCode: string;
                    networkId?: string | null;
                    number: string;
                    style: string;
                }[];
                associatedCommercialStop?: {
                    __typename?: 'AssociatedCommercialStop';
                    cityName?: string | null;
                    id: string;
                    name: string;
                    didok?: number | null;
                    coordinates?: { __typename?: 'Coordinates'; accuracy?: number | null; latitude: number; longitude: number } | null;
                    lines: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    }[];
                } | null;
            } | null;
        }[];
        messages: { __typename?: 'JourneyMessage'; id: string; htmlContent: string; title: string; type: JourneyMessageType }[];
    }[];
};

export type LineFragment = {
    __typename?: 'Line';
    color?: string | null;
    destination: string;
    fontColor?: string | null;
    id: string;
    lineOfficialCode: string;
    networkId?: string | null;
    number: string;
    style: string;
};

export type MessageFragment = { __typename?: 'JourneyMessage'; id: string; htmlContent: string; title: string; type: JourneyMessageType };

export type PhysicalStopFragment = {
    __typename?: 'PhysicalStop';
    geometry: string;
    id: string;
    letter?: string | null;
    transportMode?: string | null;
    lines: {
        __typename?: 'Line';
        color?: string | null;
        destination: string;
        fontColor?: string | null;
        id: string;
        lineOfficialCode: string;
        networkId?: string | null;
        number: string;
        style: string;
    }[];
    associatedCommercialStop?: {
        __typename?: 'AssociatedCommercialStop';
        cityName?: string | null;
        id: string;
        name: string;
        didok?: number | null;
        coordinates?: { __typename?: 'Coordinates'; accuracy?: number | null; latitude: number; longitude: number } | null;
        lines: {
            __typename?: 'Line';
            color?: string | null;
            destination: string;
            fontColor?: string | null;
            id: string;
            lineOfficialCode: string;
            networkId?: string | null;
            number: string;
            style: string;
        }[];
    } | null;
};

export type QuickArticleFragment = {
    __typename?: 'QuickArticle';
    smsCode?: string | null;
    description?: string | null;
    title?: string | null;
    travelType?: TravelType | null;
    vatLabel?: string | null;
    tarifOwner?: string | null;
    paymentMeans?: PaymentMeans[] | null;
    category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
    reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
    travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
    validity?: { __typename?: 'Validity'; from?: string | null; to?: string | null; label?: string | null } | null;
    zones?: { __typename?: 'Zone'; id: string }[] | null;
    locationsValidity?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
    locationsChoice?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
    thirdPartyPayerInformation?: { __typename?: 'ThirdPartyPayerInformation'; isEligible: boolean; thirdPartyName: string } | null;
};

export type QuickTicketFragment = {
    __typename?: 'Ticket';
    id?: string | null;
    departureStopName?: string | null;
    purchaseDate?: string | null;
    qrCodeData?: string | null;
    fqCode?: string | null;
    orderNumber?: string | null;
    refundDate?: string | null;
    passengerType?: PassengerType | null;
    refundOrderNumber?: string | null;
    refundReferenceNumber?: string | null;
    article:
        | { __typename?: 'DepartureArticle' }
        | { __typename?: 'ItineraryArticle' }
        | {
              __typename?: 'QuickArticle';
              smsCode?: string | null;
              description?: string | null;
              title?: string | null;
              travelType?: TravelType | null;
              vatLabel?: string | null;
              tarifOwner?: string | null;
              paymentMeans?: PaymentMeans[] | null;
              category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
              reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
              travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
              validity?: { __typename?: 'Validity'; from?: string | null; to?: string | null; label?: string | null } | null;
              zones?: { __typename?: 'Zone'; id: string }[] | null;
              locationsValidity?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
              locationsChoice?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
              thirdPartyPayerInformation?: {
                  __typename?: 'ThirdPartyPayerInformation';
                  isEligible: boolean;
                  thirdPartyName: string;
              } | null;
          };
    price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
    passenger: {
        __typename?: 'Passenger';
        id?: string | null;
        firstName: string;
        lastName: string;
        birthDate: any;
        type?: PassengerType | null;
    };
    duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
    referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
    ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
    articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
};

export type GenerateSupersaverItineraryArticlesMutationVariables = Exact<{
    generateItineraryArticlesInput: GenerateItineraryArticlesInput;
}>;

export type GenerateSupersaverItineraryArticlesMutation = {
    __typename?: 'Mutation';
    articlePublic: {
        __typename?: 'ArticlePublicMutation';
        generateItineraryArticles: { __typename?: 'ItineraryArticle'; isSupersaver?: boolean | null }[];
    };
};

export type GenerateDepartureArticlesMutationVariables = Exact<{
    generateDepartureArticlesInput: GenerateDepartureArticlesInput;
}>;

export type GenerateDepartureArticlesMutation = {
    __typename?: 'Mutation';
    article: {
        __typename?: 'ArticleMutation';
        generateDepartureArticles?:
            | {
                  __typename?: 'DepartureArticle';
                  validityDurationLabel?: string | null;
                  articleId: string;
                  smsCode?: string | null;
                  description?: string | null;
                  title?: string | null;
                  vatLabel?: string | null;
                  paymentMeans?: PaymentMeans[] | null;
                  travelType?: TravelType | null;
                  type: ArticleType;
                  category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                  prices?: { __typename?: 'Price'; amountInCents: number; currency: Currency }[] | null;
                  locationsValidity?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
                  locationsChoice?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
                  travelClass?: { __typename?: 'TravelClass'; description?: string | null; id: TravelClassType } | null;
                  reduction?: { __typename?: 'Reduction'; description: string; id?: ReductionType | null } | null;
                  zones?: { __typename?: 'Zone'; id: string; description?: string | null }[] | null;
                  thirdPartyPayerInformation?: {
                      __typename?: 'ThirdPartyPayerInformation';
                      isEligible: boolean;
                      fare: number;
                      thirdPartyName: string;
                  } | null;
              }[]
            | null;
    };
};

export type GenerateItineraryArticlesMutationVariables = Exact<{
    generateItineraryArticlesInput: GenerateItineraryArticlesInput;
}>;

export type GenerateItineraryArticlesMutation = {
    __typename?: 'Mutation';
    article: {
        __typename?: 'ArticleMutation';
        generateItineraryArticles?:
            | {
                  __typename?: 'ItineraryArticle';
                  validityDurationLabel?: string | null;
                  description?: string | null;
                  categoryDescription?: string | null;
                  title?: string | null;
                  offerNumber?: string | null;
                  travelType?: TravelType | null;
                  isSupersaver?: boolean | null;
                  novaId?: string | null;
                  paymentMeans?: PaymentMeans[] | null;
                  supplementQualifiers?: string | null;
                  routeQualifiers?: string | null;
                  roundTrip?: string | null;
                  type: ArticleType;
                  reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                  travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
                  prices?: { __typename?: 'Price'; amountInCents: number; currency: Currency }[] | null;
                  unavailabilityReason?: { __typename?: 'OptionItem'; label?: string | null; value?: string | null } | null;
                  zones?: { __typename?: 'Zone'; id: string; description?: string | null }[] | null;
                  thirdPartyPayerInformation?: {
                      __typename?: 'ThirdPartyPayerInformation';
                      isEligible: boolean;
                      fare: number;
                      thirdPartyName: string;
                  } | null;
              }[]
            | null;
    };
};

export type QuickArticlesMutationVariables = Exact<{
    generateQuickArticlesInput: GenerateQuickArticlesInput;
}>;

export type QuickArticlesMutation = {
    __typename?: 'Mutation';
    article: {
        __typename?: 'ArticleMutation';
        generateQuickArticles?:
            | {
                  __typename?: 'QuickArticle';
                  articleId: string;
                  description?: string | null;
                  categoryDescription?: string | null;
                  smsCode?: string | null;
                  title?: string | null;
                  vatLabel?: string | null;
                  paymentMeans?: PaymentMeans[] | null;
                  tripClass?: string | null;
                  travelType?: TravelType | null;
                  type: ArticleType;
                  validityDurationLabel?: string | null;
                  tarifOwner?: string | null;
                  category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                  prices?: { __typename?: 'Price'; amountInCents: number; currency: Currency }[] | null;
                  travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
                  locationsValidity?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
                  locationsChoice?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
                  reduction?: { __typename?: 'Reduction'; description: string; id?: ReductionType | null } | null;
                  zones?: { __typename?: 'Zone'; id: string; description?: string | null }[] | null;
                  thirdPartyPayerInformation?: {
                      __typename?: 'ThirdPartyPayerInformation';
                      isEligible: boolean;
                      fare: number;
                      thirdPartyName: string;
                  } | null;
              }[]
            | null;
    };
};

export type CreateFrequentTravelerMutationVariables = Exact<{
    frequentTraveler: CreateCustomerFrequentTravelerInput;
}>;

export type CreateFrequentTravelerMutation = {
    __typename?: 'Mutation';
    customer: { __typename?: 'CustomerMutation'; createFrequentTraveler: { __typename?: 'Result'; success: boolean } };
};

export type RemoveCreditCardMutationVariables = Exact<{
    creditCardId: Scalars['String'];
    currency: Currency;
}>;

export type RemoveCreditCardMutation = {
    __typename?: 'Mutation';
    customer: { __typename?: 'CustomerMutation'; removeCreditCard: { __typename?: 'Result'; success: boolean } };
};

export type RemoveFrequentTravelerMutationVariables = Exact<{
    frequentTravelerId: Scalars['String'];
}>;

export type RemoveFrequentTravelerMutation = {
    __typename?: 'Mutation';
    customer: { __typename?: 'CustomerMutation'; removeFrequentTraveler: { __typename?: 'Result'; success: boolean } };
};

export type UpdateFrequentTravelerMutationVariables = Exact<{
    frequentTraveler: CustomerFrequentTravelerInput;
}>;

export type UpdateFrequentTravelerMutation = {
    __typename?: 'Mutation';
    customer: { __typename?: 'CustomerMutation'; updateFrequentTraveler: { __typename?: 'Result'; success: boolean } };
};

export type RequestDepartureStopMutationVariables = Exact<{
    departure: RequestDepartureStopInput;
}>;

export type RequestDepartureStopMutation = {
    __typename?: 'Mutation';
    order: {
        __typename?: 'OrderMutation';
        requestDepartureStop: {
            __typename?: 'DepartureStopRequest';
            id?: string | null;
            customerId?: string | null;
            departure?: {
                __typename?: 'Departure';
                additionalInformation?: string[] | null;
                hasStopRequest: boolean;
                departureTimestamp: string;
                plannedDepartureTime: string;
                direction: string;
                outdatedDate?: string | null;
                serviceId: string;
                track: string;
                transport: string;
                networkId?: string | null;
                id: string;
                isBookable: boolean;
                hasBookingRequirements: boolean;
                remainingTimeBeforeBooking: number;
                bookingDeadline: string;
                isCancelled: boolean;
                line: {
                    __typename?: 'Line';
                    color?: string | null;
                    destination: string;
                    fontColor?: string | null;
                    id: string;
                    lineOfficialCode: string;
                    networkId?: string | null;
                    number: string;
                    style: string;
                };
                stop: {
                    __typename?: 'DepartureStop';
                    cityName?: string | null;
                    id: string;
                    name: string;
                    rank: number;
                    isOptional: boolean;
                    physicalStop: {
                        __typename?: 'PhysicalStop';
                        geometry: string;
                        id: string;
                        letter?: string | null;
                        transportMode?: string | null;
                        lines: {
                            __typename?: 'Line';
                            color?: string | null;
                            destination: string;
                            fontColor?: string | null;
                            id: string;
                            lineOfficialCode: string;
                            networkId?: string | null;
                            number: string;
                            style: string;
                        }[];
                        associatedCommercialStop?: {
                            __typename?: 'AssociatedCommercialStop';
                            cityName?: string | null;
                            id: string;
                            name: string;
                            didok?: number | null;
                            coordinates?: {
                                __typename?: 'Coordinates';
                                accuracy?: number | null;
                                latitude: number;
                                longitude: number;
                            } | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                        } | null;
                    };
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                };
                messages: {
                    __typename?: 'JourneyMessage';
                    id: string;
                    htmlContent: string;
                    title: string;
                    type: JourneyMessageType;
                }[];
            } | null;
        };
    };
};

export type RequestItineraryStopMutationVariables = Exact<{
    legs: RequestItineraryLegInput[] | RequestItineraryLegInput;
}>;

export type RequestItineraryStopMutation = {
    __typename?: 'Mutation';
    order: {
        __typename?: 'OrderMutation';
        requestItineraryStop: {
            __typename?: 'ItineraryStopRequest';
            id?: string | null;
            customerId?: string | null;
            itinerary?: {
                __typename?: 'Itinerary';
                arrivalDate: string;
                arrivalTime: string;
                departureDate: string;
                departureTime: string;
                scheduledArrivalDate?: string | null;
                scheduledArrivalTime?: string | null;
                scheduledDepartureDate?: string | null;
                scheduledDepartureTime?: string | null;
                isBookable: boolean;
                isCancelled: boolean;
                hasBookingRequirements: boolean;
                remainingTimeBeforeBooking: number;
                bookingDeadline: string;
                hasStopRequest: boolean;
                id: string;
                legs: {
                    __typename?: 'Leg';
                    additionalInformation?: string[] | null;
                    byFoot: boolean;
                    serviceNumber?: string | null;
                    serviceId: string;
                    length: string;
                    duration: string;
                    isBookable: boolean;
                    geoJson?: any | null;
                    bookingDeadline: string;
                    isFirstClassAuthorized: boolean;
                    transportCompanyId: string;
                    wkt?: string | null;
                    line: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    };
                    vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
                    stops: {
                        __typename?: 'CommercialStop';
                        scheduledArrivalTime?: string | null;
                        scheduledDepartureTime?: string | null;
                        arrivalTime: string;
                        cityName?: string | null;
                        departureTime: string;
                        hasBookingRequirements: boolean;
                        id: string;
                        isArrivalPlace: boolean;
                        isDeparturePlace: boolean;
                        isOptional: boolean;
                        isCancelled: boolean;
                        latLon: number[];
                        name: string;
                        didok?: number | null;
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                        physicalStopAssociated?: {
                            __typename?: 'PhysicalStop';
                            geometry: string;
                            id: string;
                            letter?: string | null;
                            transportMode?: string | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                            associatedCommercialStop?: {
                                __typename?: 'AssociatedCommercialStop';
                                cityName?: string | null;
                                id: string;
                                name: string;
                                didok?: number | null;
                                coordinates?: {
                                    __typename?: 'Coordinates';
                                    accuracy?: number | null;
                                    latitude: number;
                                    longitude: number;
                                } | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                            } | null;
                        } | null;
                    }[];
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                }[];
            } | null;
        };
    };
};

export type SetTicketsToSeenMutationVariables = Exact<{
    orders: OrderInput[] | OrderInput;
}>;

export type SetTicketsToSeenMutation = {
    __typename?: 'Mutation';
    orderPublic: { __typename?: 'OrderPublicMutation'; setTicketsToSeen: { __typename?: 'Result'; success: boolean } };
};

export type TryCancelOrderMutationVariables = Exact<{
    orderId: Scalars['String'];
    cancellationType: CancellationType;
}>;

export type TryCancelOrderMutation = {
    __typename?: 'Mutation';
    orderPublic: {
        __typename?: 'OrderPublicMutation';
        tryCancelOrder: { __typename?: 'OrderStatusResult'; success: boolean; orderStatus?: OrderStatus | null };
    };
};

export type UpdateNationalMarketingConsentMutationVariables = Exact<{
    nationalMarketingConsent: Scalars['Boolean'];
}>;

export type UpdateNationalMarketingConsentMutation = {
    __typename?: 'Mutation';
    preferencesMutation: {
        __typename?: 'PreferencesMutation';
        updateNationalMarketingConsent: { __typename?: 'Result'; error?: string | null; success: boolean };
    };
};

export type BuyMutationVariables = Exact<{
    basket: QuickArticlesBasketInput;
}>;

export type BuyMutation = {
    __typename?: 'Mutation';
    purchasePublic: {
        __typename?: 'PurchasePublicMutation';
        buyQuickTicketArticles: { __typename?: 'TicketsTransactionResult'; orderId?: string | null };
    };
};

export type BuyDepartureTicketArticlesMutationVariables = Exact<{
    basket: DepartureArticleBasketInput;
}>;

export type BuyDepartureTicketArticlesMutation = {
    __typename?: 'Mutation';
    purchase: {
        __typename?: 'PurchaseMutation';
        buyDepartureTicketArticles: { __typename?: 'TicketsTransactionResult'; orderId?: string | null };
    };
};

export type BuyItineraryTicketArticlesMutationVariables = Exact<{
    basket: ItineraryArticleBasketInput;
}>;

export type BuyItineraryTicketArticlesMutation = {
    __typename?: 'Mutation';
    purchasePublic: {
        __typename?: 'PurchasePublicMutation';
        buyItineraryTicketArticles: { __typename?: 'TicketsTransactionResult'; orderId?: string | null };
    };
};

export type PayMutationVariables = Exact<{
    language: LanguageInput;
    orderId: Scalars['String'];
    paymentMethod: PaymentMethodInput;
    successRedirectUrl: Scalars['String'];
}>;

export type PayMutation = {
    __typename?: 'Mutation';
    purchasePublic: {
        __typename?: 'PurchasePublicMutation';
        pay: { __typename?: 'PaymentResult'; done: boolean; externalPaymentUrl?: string | null };
    };
};

export type RefundDepartureTicketMutationVariables = Exact<{
    order: OrderInput;
    language: LanguageInput;
}>;

export type RefundDepartureTicketMutation = {
    __typename?: 'Mutation';
    purchasePublic: {
        __typename?: 'PurchasePublicMutation';
        refundDepartureTicketOrder: {
            __typename?: 'RefundDepartureTicketResult';
            error?: string | null;
            success: boolean;
            order?: {
                __typename?: 'DepartureOrder';
                id?: string | null;
                customerId?: string | null;
                orderType: OrderType;
                ticketsTransaction?: {
                    __typename?: 'TicketsTransaction';
                    id?: string | null;
                    paymentState: PaymentState;
                    ticketState: TicketState;
                    saferpayToken?: string | null;
                    tickets?:
                        | {
                              __typename?: 'Ticket';
                              id?: string | null;
                              departureStopName?: string | null;
                              purchaseDate?: string | null;
                              qrCodeData?: string | null;
                              fqCode?: string | null;
                              orderNumber?: string | null;
                              refundDate?: string | null;
                              passengerType?: PassengerType | null;
                              refundOrderNumber?: string | null;
                              refundReferenceNumber?: string | null;
                              article:
                                  | {
                                        __typename?: 'DepartureArticle';
                                        smsCode?: string | null;
                                        description?: string | null;
                                        title?: string | null;
                                        travelType?: TravelType | null;
                                        vatLabel?: string | null;
                                        tarifOwner?: string | null;
                                        paymentMeans?: PaymentMeans[] | null;
                                        articleId: string;
                                        category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                        reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                        travelClass?: {
                                            __typename?: 'TravelClass';
                                            id: TravelClassType;
                                            description?: string | null;
                                        } | null;
                                        zones?: { __typename?: 'Zone'; id: string }[] | null;
                                        validity?: {
                                            __typename?: 'Validity';
                                            from?: string | null;
                                            to?: string | null;
                                            label?: string | null;
                                        } | null;
                                        locationsValidity?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        locationsChoice?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                    }
                                  | { __typename?: 'ItineraryArticle' }
                                  | { __typename?: 'QuickArticle' };
                              price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                              passenger: {
                                  __typename?: 'Passenger';
                                  id?: string | null;
                                  firstName: string;
                                  lastName: string;
                                  birthDate: any;
                                  type?: PassengerType | null;
                              };
                              duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                              referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          }[]
                        | null;
                } | null;
                departure?: {
                    __typename?: 'Departure';
                    additionalInformation?: string[] | null;
                    hasStopRequest: boolean;
                    departureTimestamp: string;
                    plannedDepartureTime: string;
                    direction: string;
                    outdatedDate?: string | null;
                    serviceId: string;
                    track: string;
                    transport: string;
                    networkId?: string | null;
                    id: string;
                    isBookable: boolean;
                    hasBookingRequirements: boolean;
                    remainingTimeBeforeBooking: number;
                    bookingDeadline: string;
                    isCancelled: boolean;
                    line: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    };
                    stop: {
                        __typename?: 'DepartureStop';
                        cityName?: string | null;
                        id: string;
                        name: string;
                        rank: number;
                        isOptional: boolean;
                        physicalStop: {
                            __typename?: 'PhysicalStop';
                            geometry: string;
                            id: string;
                            letter?: string | null;
                            transportMode?: string | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                            associatedCommercialStop?: {
                                __typename?: 'AssociatedCommercialStop';
                                cityName?: string | null;
                                id: string;
                                name: string;
                                didok?: number | null;
                                coordinates?: {
                                    __typename?: 'Coordinates';
                                    accuracy?: number | null;
                                    latitude: number;
                                    longitude: number;
                                } | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                            } | null;
                        };
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                    };
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                } | null;
            } | null;
        };
    };
};

export type RefundItineraryTicketMutationVariables = Exact<{
    order: OrderInput;
    language: LanguageInput;
}>;

export type RefundItineraryTicketMutation = {
    __typename?: 'Mutation';
    purchasePublic: {
        __typename?: 'PurchasePublicMutation';
        refundItineraryTicketOrder: {
            __typename?: 'RefundItineraryTicketResult';
            error?: string | null;
            success: boolean;
            order?: {
                __typename?: 'ItineraryOrder';
                id?: string | null;
                customerId?: string | null;
                orderType: OrderType;
                ticketsTransaction?: {
                    __typename?: 'TicketsTransaction';
                    id?: string | null;
                    paymentState: PaymentState;
                    ticketState: TicketState;
                    saferpayToken?: string | null;
                    tickets?:
                        | {
                              __typename?: 'Ticket';
                              id?: string | null;
                              departureStopName?: string | null;
                              purchaseDate?: string | null;
                              qrCodeData?: string | null;
                              fqCode?: string | null;
                              orderNumber?: string | null;
                              refundDate?: string | null;
                              passengerType?: PassengerType | null;
                              refundOrderNumber?: string | null;
                              refundReferenceNumber?: string | null;
                              article:
                                  | { __typename?: 'DepartureArticle' }
                                  | {
                                        __typename?: 'ItineraryArticle';
                                        smsCode?: string | null;
                                        description?: string | null;
                                        title?: string | null;
                                        travelType?: TravelType | null;
                                        vatLabel?: string | null;
                                        tarifOwner?: string | null;
                                        paymentMeans?: PaymentMeans[] | null;
                                        conditions?: string[] | null;
                                        includes?: string[] | null;
                                        roundTrip?: string | null;
                                        isSupersaver?: boolean | null;
                                        category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                        reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                        travelClass?: {
                                            __typename?: 'TravelClass';
                                            id: TravelClassType;
                                            description?: string | null;
                                        } | null;
                                        zones?: { __typename?: 'Zone'; id: string }[] | null;
                                        validity?: {
                                            __typename?: 'Validity';
                                            from?: string | null;
                                            to?: string | null;
                                            label?: string | null;
                                        } | null;
                                        locationsValidity?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        locationsChoice?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        thirdPartyPayerInformation?: {
                                            __typename?: 'ThirdPartyPayerInformation';
                                            isEligible: boolean;
                                            thirdPartyName: string;
                                        } | null;
                                    }
                                  | { __typename?: 'QuickArticle' };
                              price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                              passenger: {
                                  __typename?: 'Passenger';
                                  id?: string | null;
                                  firstName: string;
                                  lastName: string;
                                  birthDate: any;
                                  type?: PassengerType | null;
                              };
                              duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                              referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          }[]
                        | null;
                } | null;
                itinerary?: {
                    __typename?: 'Itinerary';
                    arrivalDate: string;
                    arrivalTime: string;
                    departureDate: string;
                    departureTime: string;
                    scheduledArrivalDate?: string | null;
                    scheduledArrivalTime?: string | null;
                    scheduledDepartureDate?: string | null;
                    scheduledDepartureTime?: string | null;
                    isBookable: boolean;
                    isCancelled: boolean;
                    hasBookingRequirements: boolean;
                    remainingTimeBeforeBooking: number;
                    bookingDeadline: string;
                    hasStopRequest: boolean;
                    id: string;
                    legs: {
                        __typename?: 'Leg';
                        additionalInformation?: string[] | null;
                        byFoot: boolean;
                        serviceNumber?: string | null;
                        serviceId: string;
                        length: string;
                        duration: string;
                        isBookable: boolean;
                        geoJson?: any | null;
                        bookingDeadline: string;
                        isFirstClassAuthorized: boolean;
                        transportCompanyId: string;
                        wkt?: string | null;
                        line: {
                            __typename?: 'Line';
                            color?: string | null;
                            destination: string;
                            fontColor?: string | null;
                            id: string;
                            lineOfficialCode: string;
                            networkId?: string | null;
                            number: string;
                            style: string;
                        };
                        vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
                        stops: {
                            __typename?: 'CommercialStop';
                            scheduledArrivalTime?: string | null;
                            scheduledDepartureTime?: string | null;
                            arrivalTime: string;
                            cityName?: string | null;
                            departureTime: string;
                            hasBookingRequirements: boolean;
                            id: string;
                            isArrivalPlace: boolean;
                            isDeparturePlace: boolean;
                            isOptional: boolean;
                            isCancelled: boolean;
                            latLon: number[];
                            name: string;
                            didok?: number | null;
                            messages: {
                                __typename?: 'JourneyMessage';
                                id: string;
                                htmlContent: string;
                                title: string;
                                type: JourneyMessageType;
                            }[];
                            physicalStopAssociated?: {
                                __typename?: 'PhysicalStop';
                                geometry: string;
                                id: string;
                                letter?: string | null;
                                transportMode?: string | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                                associatedCommercialStop?: {
                                    __typename?: 'AssociatedCommercialStop';
                                    cityName?: string | null;
                                    id: string;
                                    name: string;
                                    didok?: number | null;
                                    coordinates?: {
                                        __typename?: 'Coordinates';
                                        accuracy?: number | null;
                                        latitude: number;
                                        longitude: number;
                                    } | null;
                                    lines: {
                                        __typename?: 'Line';
                                        color?: string | null;
                                        destination: string;
                                        fontColor?: string | null;
                                        id: string;
                                        lineOfficialCode: string;
                                        networkId?: string | null;
                                        number: string;
                                        style: string;
                                    }[];
                                } | null;
                            } | null;
                        }[];
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                    }[];
                } | null;
            } | null;
        };
    };
};

export type RefundQuickTicketMutationVariables = Exact<{
    order: OrderInput;
    language: LanguageInput;
}>;

export type RefundQuickTicketMutation = {
    __typename?: 'Mutation';
    purchasePublic: {
        __typename?: 'PurchasePublicMutation';
        refundQuickTicketOrder: {
            __typename?: 'RefundQuickTicketResult';
            error?: string | null;
            success: boolean;
            order?: {
                __typename?: 'QuickTicketsOrder';
                id?: string | null;
                customerId?: string | null;
                orderType: OrderType;
                ticketsTransaction?: {
                    __typename?: 'TicketsTransaction';
                    id?: string | null;
                    paymentState: PaymentState;
                    ticketState: TicketState;
                    saferpayToken?: string | null;
                    tickets?:
                        | {
                              __typename?: 'Ticket';
                              id?: string | null;
                              departureStopName?: string | null;
                              purchaseDate?: string | null;
                              qrCodeData?: string | null;
                              fqCode?: string | null;
                              orderNumber?: string | null;
                              refundDate?: string | null;
                              passengerType?: PassengerType | null;
                              refundOrderNumber?: string | null;
                              refundReferenceNumber?: string | null;
                              article:
                                  | { __typename?: 'DepartureArticle' }
                                  | { __typename?: 'ItineraryArticle' }
                                  | {
                                        __typename?: 'QuickArticle';
                                        smsCode?: string | null;
                                        description?: string | null;
                                        title?: string | null;
                                        travelType?: TravelType | null;
                                        vatLabel?: string | null;
                                        tarifOwner?: string | null;
                                        paymentMeans?: PaymentMeans[] | null;
                                        category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                        reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                        travelClass?: {
                                            __typename?: 'TravelClass';
                                            id: TravelClassType;
                                            description?: string | null;
                                        } | null;
                                        validity?: {
                                            __typename?: 'Validity';
                                            from?: string | null;
                                            to?: string | null;
                                            label?: string | null;
                                        } | null;
                                        zones?: { __typename?: 'Zone'; id: string }[] | null;
                                        locationsValidity?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        locationsChoice?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        thirdPartyPayerInformation?: {
                                            __typename?: 'ThirdPartyPayerInformation';
                                            isEligible: boolean;
                                            thirdPartyName: string;
                                        } | null;
                                    };
                              price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                              passenger: {
                                  __typename?: 'Passenger';
                                  id?: string | null;
                                  firstName: string;
                                  lastName: string;
                                  birthDate: any;
                                  type?: PassengerType | null;
                              };
                              duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                              referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          }[]
                        | null;
                } | null;
                zones?: { __typename?: 'Zone'; id: string }[] | null;
            } | null;
        };
    };
};

export type SaveItineraryMutationVariables = Exact<{
    itineraryBasket: ItineraryBasketInput;
}>;

export type SaveItineraryMutation = {
    __typename?: 'Mutation';
    purchase: {
        __typename?: 'PurchaseMutation';
        saveItinerary: {
            __typename?: 'ItineraryOrder';
            id?: string | null;
            customerId?: string | null;
            orderType: OrderType;
            ticketsTransaction?: { __typename?: 'TicketsTransaction'; id?: string | null } | null;
            itinerary?: {
                __typename?: 'Itinerary';
                arrivalDate: string;
                arrivalTime: string;
                departureDate: string;
                departureTime: string;
                scheduledArrivalDate?: string | null;
                scheduledArrivalTime?: string | null;
                scheduledDepartureDate?: string | null;
                scheduledDepartureTime?: string | null;
                isBookable: boolean;
                isCancelled: boolean;
                hasBookingRequirements: boolean;
                remainingTimeBeforeBooking: number;
                bookingDeadline: string;
                hasStopRequest: boolean;
                id: string;
                legs: {
                    __typename?: 'Leg';
                    additionalInformation?: string[] | null;
                    byFoot: boolean;
                    serviceNumber?: string | null;
                    serviceId: string;
                    length: string;
                    duration: string;
                    isBookable: boolean;
                    geoJson?: any | null;
                    bookingDeadline: string;
                    isFirstClassAuthorized: boolean;
                    transportCompanyId: string;
                    wkt?: string | null;
                    line: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    };
                    vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
                    stops: {
                        __typename?: 'CommercialStop';
                        scheduledArrivalTime?: string | null;
                        scheduledDepartureTime?: string | null;
                        arrivalTime: string;
                        cityName?: string | null;
                        departureTime: string;
                        hasBookingRequirements: boolean;
                        id: string;
                        isArrivalPlace: boolean;
                        isDeparturePlace: boolean;
                        isOptional: boolean;
                        isCancelled: boolean;
                        latLon: number[];
                        name: string;
                        didok?: number | null;
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                        physicalStopAssociated?: {
                            __typename?: 'PhysicalStop';
                            geometry: string;
                            id: string;
                            letter?: string | null;
                            transportMode?: string | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                            associatedCommercialStop?: {
                                __typename?: 'AssociatedCommercialStop';
                                cityName?: string | null;
                                id: string;
                                name: string;
                                didok?: number | null;
                                coordinates?: {
                                    __typename?: 'Coordinates';
                                    accuracy?: number | null;
                                    latitude: number;
                                    longitude: number;
                                } | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                            } | null;
                        } | null;
                    }[];
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                }[];
            } | null;
        };
    };
};

export type GetPaymentMethodsQueryVariables = Exact<{
    currency: Currency;
    clientPlatform: ClientPlatform;
}>;

export type GetPaymentMethodsQuery = {
    __typename?: 'Query';
    configuration: {
        __typename?: 'ConfigurationQuery';
        paymentMethods: { __typename?: 'PaymentMethodQuery'; cards: string[]; mobiles: string[] };
    };
};

export type GetRefreshIntervalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRefreshIntervalsQuery = {
    __typename?: 'Query';
    configuration: {
        __typename?: 'ConfigurationQuery';
        refreshIntervals: {
            __typename?: 'RefreshIntervalQuery';
            departureRefreshIntervalInSeconds: number;
            itineraryRefreshIntervalInSeconds: number;
            autoScrollRefreshIntervalInSeconds: number;
        };
    };
};

export type GetThresholdsQueryVariables = Exact<{ [key: string]: never }>;

export type GetThresholdsQuery = {
    __typename?: 'Query';
    configuration: {
        __typename?: 'ConfigurationQuery';
        thresholds: {
            __typename?: 'ThresholdQuery';
            minimumThresholdToShowIconInSeconds: number;
            minutesThresholdToDisplayWaitingMinutes: number;
        };
    };
};

export type GetCreditCardsQueryVariables = Exact<{
    currency: Currency;
    onlyValid: Scalars['Boolean'];
}>;

export type GetCreditCardsQuery = {
    __typename?: 'Query';
    customer: {
        __typename?: 'CustomerQuery';
        creditCards: {
            __typename?: 'CreditCard';
            id: string;
            brand: string;
            maskedNumber: string;
            holderName?: string | null;
            expirationYear: number;
            expirationMonth: number;
            countryCode?: string | null;
            primary?: boolean | null;
            gatewayReference: { __typename?: 'GatewayReference'; reference?: string | null };
        }[];
    };
};

export type GetFrequentTravelerTitlesQueryVariables = Exact<{
    language: Scalars['String'];
}>;

export type GetFrequentTravelerTitlesQuery = {
    __typename?: 'Query';
    references: {
        __typename?: 'PublicReferencesQuery';
        frequentTravelerTitles: { __typename?: 'Title'; label: string; value: string }[];
    };
};

export type GetFrequentTravelersQueryVariables = Exact<{ [key: string]: never }>;

export type GetFrequentTravelersQuery = {
    __typename?: 'Query';
    customer: {
        __typename?: 'CustomerQuery';
        frequentTravelers: {
            __typename?: 'CustomerFrequentTraveler';
            type: PassengerType;
            firstName: string;
            lastName: string;
            birthDate: any;
            hasHalfFare: boolean;
            phone?: string | null;
            email?: string | null;
            id: string;
            title: string;
        }[];
    };
};

export type DirectionsFromStopQueryVariables = Exact<{
    directionsFromStopInput: DirectionsFromStopInput;
}>;

export type DirectionsFromStopQuery = {
    __typename?: 'Query';
    departure: {
        __typename?: 'DepartureQuery';
        getDirectionsFromStop: {
            __typename?: 'Direction';
            lineId: string;
            lineStyleId: string;
            lineName?: string | null;
            name?: string | null;
            wayback?: Wayback | null;
        }[];
    };
};

export type SearchDeparturesQueryVariables = Exact<{
    using: DepartureSearchCriteria;
    timing: DepartureTimingInput;
    count: Scalars['Int'];
    filter?: InputMaybe<DepatureFilterInput>;
    lang: Scalars['String'];
}>;

export type SearchDeparturesQuery = {
    __typename?: 'Query';
    departure: {
        __typename?: 'DepartureQuery';
        search: {
            __typename?: 'Departure';
            additionalInformation?: string[] | null;
            hasStopRequest: boolean;
            departureTimestamp: string;
            plannedDepartureTime: string;
            direction: string;
            outdatedDate?: string | null;
            serviceId: string;
            track: string;
            transport: string;
            networkId?: string | null;
            id: string;
            isBookable: boolean;
            hasBookingRequirements: boolean;
            remainingTimeBeforeBooking: number;
            bookingDeadline: string;
            isCancelled: boolean;
            line: {
                __typename?: 'Line';
                color?: string | null;
                destination: string;
                fontColor?: string | null;
                id: string;
                lineOfficialCode: string;
                networkId?: string | null;
                number: string;
                style: string;
            };
            stop: {
                __typename?: 'DepartureStop';
                cityName?: string | null;
                id: string;
                name: string;
                rank: number;
                isOptional: boolean;
                physicalStop: {
                    __typename?: 'PhysicalStop';
                    geometry: string;
                    id: string;
                    letter?: string | null;
                    transportMode?: string | null;
                    lines: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    }[];
                    associatedCommercialStop?: {
                        __typename?: 'AssociatedCommercialStop';
                        cityName?: string | null;
                        id: string;
                        name: string;
                        didok?: number | null;
                        coordinates?: { __typename?: 'Coordinates'; accuracy?: number | null; latitude: number; longitude: number } | null;
                        lines: {
                            __typename?: 'Line';
                            color?: string | null;
                            destination: string;
                            fontColor?: string | null;
                            id: string;
                            lineOfficialCode: string;
                            networkId?: string | null;
                            number: string;
                            style: string;
                        }[];
                    } | null;
                };
                messages: {
                    __typename?: 'JourneyMessage';
                    id: string;
                    htmlContent: string;
                    title: string;
                    type: JourneyMessageType;
                }[];
            };
            messages: { __typename?: 'JourneyMessage'; id: string; htmlContent: string; title: string; type: JourneyMessageType }[];
        }[];
    };
};

export type GetItinerariesQueryVariables = Exact<{
    departure: CoordinatesOrStopIdsInput;
    arrival: CoordinatesOrStopIdsInput;
    dateTime: Scalars['Date'];
    searchDirection: SearchDirection;
    count: Scalars['Int'];
    withDirectItineraries: Scalars['Boolean'];
    transportModes: Scalars['String'][] | Scalars['String'];
    lang: Scalars['String'];
}>;

export type GetItinerariesQuery = {
    __typename?: 'Query';
    itineraries: {
        __typename?: 'Itinerary';
        arrivalDate: string;
        arrivalTime: string;
        departureDate: string;
        departureTime: string;
        scheduledArrivalDate?: string | null;
        scheduledArrivalTime?: string | null;
        scheduledDepartureDate?: string | null;
        scheduledDepartureTime?: string | null;
        isBookable: boolean;
        isCancelled: boolean;
        hasBookingRequirements: boolean;
        remainingTimeBeforeBooking: number;
        bookingDeadline: string;
        hasStopRequest: boolean;
        id: string;
        legs: {
            __typename?: 'Leg';
            additionalInformation?: string[] | null;
            byFoot: boolean;
            serviceNumber?: string | null;
            serviceId: string;
            length: string;
            duration: string;
            isBookable: boolean;
            geoJson?: any | null;
            bookingDeadline: string;
            isFirstClassAuthorized: boolean;
            transportCompanyId: string;
            wkt?: string | null;
            line: {
                __typename?: 'Line';
                color?: string | null;
                destination: string;
                fontColor?: string | null;
                id: string;
                lineOfficialCode: string;
                networkId?: string | null;
                number: string;
                style: string;
            };
            vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
            stops: {
                __typename?: 'CommercialStop';
                scheduledArrivalTime?: string | null;
                scheduledDepartureTime?: string | null;
                arrivalTime: string;
                cityName?: string | null;
                departureTime: string;
                hasBookingRequirements: boolean;
                id: string;
                isArrivalPlace: boolean;
                isDeparturePlace: boolean;
                isOptional: boolean;
                isCancelled: boolean;
                latLon: number[];
                name: string;
                didok?: number | null;
                messages: {
                    __typename?: 'JourneyMessage';
                    id: string;
                    htmlContent: string;
                    title: string;
                    type: JourneyMessageType;
                }[];
                physicalStopAssociated?: {
                    __typename?: 'PhysicalStop';
                    geometry: string;
                    id: string;
                    letter?: string | null;
                    transportMode?: string | null;
                    lines: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    }[];
                    associatedCommercialStop?: {
                        __typename?: 'AssociatedCommercialStop';
                        cityName?: string | null;
                        id: string;
                        name: string;
                        didok?: number | null;
                        coordinates?: { __typename?: 'Coordinates'; accuracy?: number | null; latitude: number; longitude: number } | null;
                        lines: {
                            __typename?: 'Line';
                            color?: string | null;
                            destination: string;
                            fontColor?: string | null;
                            id: string;
                            lineOfficialCode: string;
                            networkId?: string | null;
                            number: string;
                            style: string;
                        }[];
                    } | null;
                } | null;
            }[];
            messages: { __typename?: 'JourneyMessage'; id: string; htmlContent: string; title: string; type: JourneyMessageType }[];
        }[];
    }[];
};

export type GetTransportModesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTransportModesQuery = {
    __typename?: 'Query';
    transportModes: {
        __typename?: 'TransportMode';
        id: string;
        name: string;
        compatibleTransportModes: string;
        singular: string;
        plural: string;
    }[];
};

export type GetLinesStylesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLinesStylesQuery = {
    __typename?: 'Query';
    linesStyles: {
        __typename?: 'LineStyle';
        id?: string | null;
        name?: string | null;
        longName?: string | null;
        image?: string | null;
        style?: string | null;
        color?: string | null;
        textColor?: string | null;
        lineOfficialCode?: string | null;
        networkId?: string | null;
    }[];
};

export type CancelStopRequestMutationVariables = Exact<{
    orderId: Scalars['ID'];
}>;

export type CancelStopRequestMutation = {
    __typename?: 'Mutation';
    order: { __typename?: 'OrderMutation'; cancelStopRequest: { __typename?: 'Result'; success: boolean } };
};

export type GetAllOrdersOfCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllOrdersOfCurrentUserQuery = {
    __typename?: 'Query';
    order: {
        __typename?: 'OrderQuery';
        getAllOrders: {
            __typename?: 'OrderGroup';
            departureOrders: {
                __typename?: 'DepartureOrder';
                id?: string | null;
                customerId?: string | null;
                orderType: OrderType;
                ticketsTransaction?: {
                    __typename?: 'TicketsTransaction';
                    id?: string | null;
                    paymentState: PaymentState;
                    ticketState: TicketState;
                    saferpayToken?: string | null;
                    tickets?:
                        | {
                              __typename?: 'Ticket';
                              id?: string | null;
                              departureStopName?: string | null;
                              purchaseDate?: string | null;
                              qrCodeData?: string | null;
                              fqCode?: string | null;
                              orderNumber?: string | null;
                              refundDate?: string | null;
                              passengerType?: PassengerType | null;
                              refundOrderNumber?: string | null;
                              refundReferenceNumber?: string | null;
                              article:
                                  | {
                                        __typename?: 'DepartureArticle';
                                        smsCode?: string | null;
                                        description?: string | null;
                                        title?: string | null;
                                        travelType?: TravelType | null;
                                        vatLabel?: string | null;
                                        tarifOwner?: string | null;
                                        paymentMeans?: PaymentMeans[] | null;
                                        articleId: string;
                                        category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                        reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                        travelClass?: {
                                            __typename?: 'TravelClass';
                                            id: TravelClassType;
                                            description?: string | null;
                                        } | null;
                                        zones?: { __typename?: 'Zone'; id: string }[] | null;
                                        validity?: {
                                            __typename?: 'Validity';
                                            from?: string | null;
                                            to?: string | null;
                                            label?: string | null;
                                        } | null;
                                        locationsValidity?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        locationsChoice?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                    }
                                  | { __typename?: 'ItineraryArticle' }
                                  | { __typename?: 'QuickArticle' };
                              price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                              passenger: {
                                  __typename?: 'Passenger';
                                  id?: string | null;
                                  firstName: string;
                                  lastName: string;
                                  birthDate: any;
                                  type?: PassengerType | null;
                              };
                              duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                              referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          }[]
                        | null;
                } | null;
                departure?: {
                    __typename?: 'Departure';
                    additionalInformation?: string[] | null;
                    hasStopRequest: boolean;
                    departureTimestamp: string;
                    plannedDepartureTime: string;
                    direction: string;
                    outdatedDate?: string | null;
                    serviceId: string;
                    track: string;
                    transport: string;
                    networkId?: string | null;
                    id: string;
                    isBookable: boolean;
                    hasBookingRequirements: boolean;
                    remainingTimeBeforeBooking: number;
                    bookingDeadline: string;
                    isCancelled: boolean;
                    line: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    };
                    stop: {
                        __typename?: 'DepartureStop';
                        cityName?: string | null;
                        id: string;
                        name: string;
                        rank: number;
                        isOptional: boolean;
                        physicalStop: {
                            __typename?: 'PhysicalStop';
                            geometry: string;
                            id: string;
                            letter?: string | null;
                            transportMode?: string | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                            associatedCommercialStop?: {
                                __typename?: 'AssociatedCommercialStop';
                                cityName?: string | null;
                                id: string;
                                name: string;
                                didok?: number | null;
                                coordinates?: {
                                    __typename?: 'Coordinates';
                                    accuracy?: number | null;
                                    latitude: number;
                                    longitude: number;
                                } | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                            } | null;
                        };
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                    };
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                } | null;
            }[];
            departureStopRequests: {
                __typename?: 'DepartureStopRequest';
                id?: string | null;
                customerId?: string | null;
                departure?: {
                    __typename?: 'Departure';
                    additionalInformation?: string[] | null;
                    hasStopRequest: boolean;
                    departureTimestamp: string;
                    plannedDepartureTime: string;
                    direction: string;
                    outdatedDate?: string | null;
                    serviceId: string;
                    track: string;
                    transport: string;
                    networkId?: string | null;
                    id: string;
                    isBookable: boolean;
                    hasBookingRequirements: boolean;
                    remainingTimeBeforeBooking: number;
                    bookingDeadline: string;
                    isCancelled: boolean;
                    line: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    };
                    stop: {
                        __typename?: 'DepartureStop';
                        cityName?: string | null;
                        id: string;
                        name: string;
                        rank: number;
                        isOptional: boolean;
                        physicalStop: {
                            __typename?: 'PhysicalStop';
                            geometry: string;
                            id: string;
                            letter?: string | null;
                            transportMode?: string | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                            associatedCommercialStop?: {
                                __typename?: 'AssociatedCommercialStop';
                                cityName?: string | null;
                                id: string;
                                name: string;
                                didok?: number | null;
                                coordinates?: {
                                    __typename?: 'Coordinates';
                                    accuracy?: number | null;
                                    latitude: number;
                                    longitude: number;
                                } | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                            } | null;
                        };
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                    };
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                } | null;
            }[];
            itineraryOrders: {
                __typename?: 'ItineraryOrder';
                id?: string | null;
                customerId?: string | null;
                orderType: OrderType;
                ticketsTransaction?: {
                    __typename?: 'TicketsTransaction';
                    id?: string | null;
                    paymentState: PaymentState;
                    ticketState: TicketState;
                    saferpayToken?: string | null;
                    tickets?:
                        | {
                              __typename?: 'Ticket';
                              id?: string | null;
                              departureStopName?: string | null;
                              purchaseDate?: string | null;
                              qrCodeData?: string | null;
                              fqCode?: string | null;
                              orderNumber?: string | null;
                              refundDate?: string | null;
                              passengerType?: PassengerType | null;
                              refundOrderNumber?: string | null;
                              refundReferenceNumber?: string | null;
                              article:
                                  | { __typename?: 'DepartureArticle' }
                                  | {
                                        __typename?: 'ItineraryArticle';
                                        smsCode?: string | null;
                                        description?: string | null;
                                        title?: string | null;
                                        travelType?: TravelType | null;
                                        vatLabel?: string | null;
                                        tarifOwner?: string | null;
                                        paymentMeans?: PaymentMeans[] | null;
                                        conditions?: string[] | null;
                                        includes?: string[] | null;
                                        roundTrip?: string | null;
                                        isSupersaver?: boolean | null;
                                        category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                        reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                        travelClass?: {
                                            __typename?: 'TravelClass';
                                            id: TravelClassType;
                                            description?: string | null;
                                        } | null;
                                        zones?: { __typename?: 'Zone'; id: string }[] | null;
                                        validity?: {
                                            __typename?: 'Validity';
                                            from?: string | null;
                                            to?: string | null;
                                            label?: string | null;
                                        } | null;
                                        locationsValidity?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        locationsChoice?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        thirdPartyPayerInformation?: {
                                            __typename?: 'ThirdPartyPayerInformation';
                                            isEligible: boolean;
                                            thirdPartyName: string;
                                        } | null;
                                    }
                                  | { __typename?: 'QuickArticle' };
                              price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                              passenger: {
                                  __typename?: 'Passenger';
                                  id?: string | null;
                                  firstName: string;
                                  lastName: string;
                                  birthDate: any;
                                  type?: PassengerType | null;
                              };
                              duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                              referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          }[]
                        | null;
                } | null;
                itinerary?: {
                    __typename?: 'Itinerary';
                    arrivalDate: string;
                    arrivalTime: string;
                    departureDate: string;
                    departureTime: string;
                    scheduledArrivalDate?: string | null;
                    scheduledArrivalTime?: string | null;
                    scheduledDepartureDate?: string | null;
                    scheduledDepartureTime?: string | null;
                    isBookable: boolean;
                    isCancelled: boolean;
                    hasBookingRequirements: boolean;
                    remainingTimeBeforeBooking: number;
                    bookingDeadline: string;
                    hasStopRequest: boolean;
                    id: string;
                    legs: {
                        __typename?: 'Leg';
                        additionalInformation?: string[] | null;
                        byFoot: boolean;
                        serviceNumber?: string | null;
                        serviceId: string;
                        length: string;
                        duration: string;
                        isBookable: boolean;
                        geoJson?: any | null;
                        bookingDeadline: string;
                        isFirstClassAuthorized: boolean;
                        transportCompanyId: string;
                        wkt?: string | null;
                        line: {
                            __typename?: 'Line';
                            color?: string | null;
                            destination: string;
                            fontColor?: string | null;
                            id: string;
                            lineOfficialCode: string;
                            networkId?: string | null;
                            number: string;
                            style: string;
                        };
                        vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
                        stops: {
                            __typename?: 'CommercialStop';
                            scheduledArrivalTime?: string | null;
                            scheduledDepartureTime?: string | null;
                            arrivalTime: string;
                            cityName?: string | null;
                            departureTime: string;
                            hasBookingRequirements: boolean;
                            id: string;
                            isArrivalPlace: boolean;
                            isDeparturePlace: boolean;
                            isOptional: boolean;
                            isCancelled: boolean;
                            latLon: number[];
                            name: string;
                            didok?: number | null;
                            messages: {
                                __typename?: 'JourneyMessage';
                                id: string;
                                htmlContent: string;
                                title: string;
                                type: JourneyMessageType;
                            }[];
                            physicalStopAssociated?: {
                                __typename?: 'PhysicalStop';
                                geometry: string;
                                id: string;
                                letter?: string | null;
                                transportMode?: string | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                                associatedCommercialStop?: {
                                    __typename?: 'AssociatedCommercialStop';
                                    cityName?: string | null;
                                    id: string;
                                    name: string;
                                    didok?: number | null;
                                    coordinates?: {
                                        __typename?: 'Coordinates';
                                        accuracy?: number | null;
                                        latitude: number;
                                        longitude: number;
                                    } | null;
                                    lines: {
                                        __typename?: 'Line';
                                        color?: string | null;
                                        destination: string;
                                        fontColor?: string | null;
                                        id: string;
                                        lineOfficialCode: string;
                                        networkId?: string | null;
                                        number: string;
                                        style: string;
                                    }[];
                                } | null;
                            } | null;
                        }[];
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                    }[];
                } | null;
            }[];
            itineraryStopRequests: {
                __typename?: 'ItineraryStopRequest';
                id?: string | null;
                customerId?: string | null;
                itinerary?: {
                    __typename?: 'Itinerary';
                    arrivalDate: string;
                    arrivalTime: string;
                    departureDate: string;
                    departureTime: string;
                    scheduledArrivalDate?: string | null;
                    scheduledArrivalTime?: string | null;
                    scheduledDepartureDate?: string | null;
                    scheduledDepartureTime?: string | null;
                    isBookable: boolean;
                    isCancelled: boolean;
                    hasBookingRequirements: boolean;
                    remainingTimeBeforeBooking: number;
                    bookingDeadline: string;
                    hasStopRequest: boolean;
                    id: string;
                    legs: {
                        __typename?: 'Leg';
                        additionalInformation?: string[] | null;
                        byFoot: boolean;
                        serviceNumber?: string | null;
                        serviceId: string;
                        length: string;
                        duration: string;
                        isBookable: boolean;
                        geoJson?: any | null;
                        bookingDeadline: string;
                        isFirstClassAuthorized: boolean;
                        transportCompanyId: string;
                        wkt?: string | null;
                        line: {
                            __typename?: 'Line';
                            color?: string | null;
                            destination: string;
                            fontColor?: string | null;
                            id: string;
                            lineOfficialCode: string;
                            networkId?: string | null;
                            number: string;
                            style: string;
                        };
                        vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
                        stops: {
                            __typename?: 'CommercialStop';
                            scheduledArrivalTime?: string | null;
                            scheduledDepartureTime?: string | null;
                            arrivalTime: string;
                            cityName?: string | null;
                            departureTime: string;
                            hasBookingRequirements: boolean;
                            id: string;
                            isArrivalPlace: boolean;
                            isDeparturePlace: boolean;
                            isOptional: boolean;
                            isCancelled: boolean;
                            latLon: number[];
                            name: string;
                            didok?: number | null;
                            messages: {
                                __typename?: 'JourneyMessage';
                                id: string;
                                htmlContent: string;
                                title: string;
                                type: JourneyMessageType;
                            }[];
                            physicalStopAssociated?: {
                                __typename?: 'PhysicalStop';
                                geometry: string;
                                id: string;
                                letter?: string | null;
                                transportMode?: string | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                                associatedCommercialStop?: {
                                    __typename?: 'AssociatedCommercialStop';
                                    cityName?: string | null;
                                    id: string;
                                    name: string;
                                    didok?: number | null;
                                    coordinates?: {
                                        __typename?: 'Coordinates';
                                        accuracy?: number | null;
                                        latitude: number;
                                        longitude: number;
                                    } | null;
                                    lines: {
                                        __typename?: 'Line';
                                        color?: string | null;
                                        destination: string;
                                        fontColor?: string | null;
                                        id: string;
                                        lineOfficialCode: string;
                                        networkId?: string | null;
                                        number: string;
                                        style: string;
                                    }[];
                                } | null;
                            } | null;
                        }[];
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                    }[];
                } | null;
            }[];
            quickTicketOrders: {
                __typename?: 'QuickTicketsOrder';
                id?: string | null;
                customerId?: string | null;
                orderType: OrderType;
                ticketsTransaction?: {
                    __typename?: 'TicketsTransaction';
                    id?: string | null;
                    paymentState: PaymentState;
                    ticketState: TicketState;
                    saferpayToken?: string | null;
                    tickets?:
                        | {
                              __typename?: 'Ticket';
                              id?: string | null;
                              departureStopName?: string | null;
                              purchaseDate?: string | null;
                              qrCodeData?: string | null;
                              fqCode?: string | null;
                              orderNumber?: string | null;
                              refundDate?: string | null;
                              passengerType?: PassengerType | null;
                              refundOrderNumber?: string | null;
                              refundReferenceNumber?: string | null;
                              article:
                                  | { __typename?: 'DepartureArticle' }
                                  | { __typename?: 'ItineraryArticle' }
                                  | {
                                        __typename?: 'QuickArticle';
                                        smsCode?: string | null;
                                        description?: string | null;
                                        title?: string | null;
                                        travelType?: TravelType | null;
                                        vatLabel?: string | null;
                                        tarifOwner?: string | null;
                                        paymentMeans?: PaymentMeans[] | null;
                                        category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                        reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                        travelClass?: {
                                            __typename?: 'TravelClass';
                                            id: TravelClassType;
                                            description?: string | null;
                                        } | null;
                                        validity?: {
                                            __typename?: 'Validity';
                                            from?: string | null;
                                            to?: string | null;
                                            label?: string | null;
                                        } | null;
                                        zones?: { __typename?: 'Zone'; id: string }[] | null;
                                        locationsValidity?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        locationsChoice?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        thirdPartyPayerInformation?: {
                                            __typename?: 'ThirdPartyPayerInformation';
                                            isEligible: boolean;
                                            thirdPartyName: string;
                                        } | null;
                                    };
                              price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                              passenger: {
                                  __typename?: 'Passenger';
                                  id?: string | null;
                                  firstName: string;
                                  lastName: string;
                                  birthDate: any;
                                  type?: PassengerType | null;
                              };
                              duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                              referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          }[]
                        | null;
                } | null;
                zones?: { __typename?: 'Zone'; id: string }[] | null;
            }[];
            processingOrders: {
                __typename?: 'ProcessingOrder';
                id: string;
                purchaseDate: string;
                orderType: OrderType;
                passenger: { __typename?: 'Passenger'; id?: string | null; firstName: string; lastName: string; birthDate: any };
            }[];
        };
    };
};

export type GetConfirmedDepartureOrderQueryVariables = Exact<{ [key: string]: never }>;

export type GetConfirmedDepartureOrderQuery = {
    __typename?: 'Query';
    order: {
        __typename?: 'OrderQuery';
        getConfirmedDepartureOrders?:
            | {
                  __typename?: 'DepartureOrder';
                  id?: string | null;
                  customerId?: string | null;
                  orderType: OrderType;
                  ticketsTransaction?: {
                      __typename?: 'TicketsTransaction';
                      id?: string | null;
                      paymentState: PaymentState;
                      ticketState: TicketState;
                      saferpayToken?: string | null;
                      tickets?:
                          | {
                                __typename?: 'Ticket';
                                id?: string | null;
                                departureStopName?: string | null;
                                purchaseDate?: string | null;
                                qrCodeData?: string | null;
                                fqCode?: string | null;
                                orderNumber?: string | null;
                                refundDate?: string | null;
                                passengerType?: PassengerType | null;
                                refundOrderNumber?: string | null;
                                refundReferenceNumber?: string | null;
                                article:
                                    | {
                                          __typename?: 'DepartureArticle';
                                          smsCode?: string | null;
                                          description?: string | null;
                                          title?: string | null;
                                          travelType?: TravelType | null;
                                          vatLabel?: string | null;
                                          tarifOwner?: string | null;
                                          paymentMeans?: PaymentMeans[] | null;
                                          articleId: string;
                                          category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                          reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                          travelClass?: {
                                              __typename?: 'TravelClass';
                                              id: TravelClassType;
                                              description?: string | null;
                                          } | null;
                                          zones?: { __typename?: 'Zone'; id: string }[] | null;
                                          validity?: {
                                              __typename?: 'Validity';
                                              from?: string | null;
                                              to?: string | null;
                                              label?: string | null;
                                          } | null;
                                          locationsValidity?: {
                                              __typename?: 'LocalizedOptionItems';
                                              label?: string | null;
                                              values?: string[] | null;
                                          } | null;
                                          locationsChoice?: {
                                              __typename?: 'LocalizedOptionItems';
                                              label?: string | null;
                                              values?: string[] | null;
                                          } | null;
                                      }
                                    | { __typename?: 'ItineraryArticle' }
                                    | { __typename?: 'QuickArticle' };
                                price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                                passenger: {
                                    __typename?: 'Passenger';
                                    id?: string | null;
                                    firstName: string;
                                    lastName: string;
                                    birthDate: any;
                                    type?: PassengerType | null;
                                };
                                duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                                referenceNumber?: {
                                    __typename?: 'LocalizedOptionItem';
                                    label?: string | null;
                                    value?: string | null;
                                } | null;
                                ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                                articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                            }[]
                          | null;
                  } | null;
                  departure?: {
                      __typename?: 'Departure';
                      additionalInformation?: string[] | null;
                      hasStopRequest: boolean;
                      departureTimestamp: string;
                      plannedDepartureTime: string;
                      direction: string;
                      outdatedDate?: string | null;
                      serviceId: string;
                      track: string;
                      transport: string;
                      networkId?: string | null;
                      id: string;
                      isBookable: boolean;
                      hasBookingRequirements: boolean;
                      remainingTimeBeforeBooking: number;
                      bookingDeadline: string;
                      isCancelled: boolean;
                      line: {
                          __typename?: 'Line';
                          color?: string | null;
                          destination: string;
                          fontColor?: string | null;
                          id: string;
                          lineOfficialCode: string;
                          networkId?: string | null;
                          number: string;
                          style: string;
                      };
                      stop: {
                          __typename?: 'DepartureStop';
                          cityName?: string | null;
                          id: string;
                          name: string;
                          rank: number;
                          isOptional: boolean;
                          physicalStop: {
                              __typename?: 'PhysicalStop';
                              geometry: string;
                              id: string;
                              letter?: string | null;
                              transportMode?: string | null;
                              lines: {
                                  __typename?: 'Line';
                                  color?: string | null;
                                  destination: string;
                                  fontColor?: string | null;
                                  id: string;
                                  lineOfficialCode: string;
                                  networkId?: string | null;
                                  number: string;
                                  style: string;
                              }[];
                              associatedCommercialStop?: {
                                  __typename?: 'AssociatedCommercialStop';
                                  cityName?: string | null;
                                  id: string;
                                  name: string;
                                  didok?: number | null;
                                  coordinates?: {
                                      __typename?: 'Coordinates';
                                      accuracy?: number | null;
                                      latitude: number;
                                      longitude: number;
                                  } | null;
                                  lines: {
                                      __typename?: 'Line';
                                      color?: string | null;
                                      destination: string;
                                      fontColor?: string | null;
                                      id: string;
                                      lineOfficialCode: string;
                                      networkId?: string | null;
                                      number: string;
                                      style: string;
                                  }[];
                              } | null;
                          };
                          messages: {
                              __typename?: 'JourneyMessage';
                              id: string;
                              htmlContent: string;
                              title: string;
                              type: JourneyMessageType;
                          }[];
                      };
                      messages: {
                          __typename?: 'JourneyMessage';
                          id: string;
                          htmlContent: string;
                          title: string;
                          type: JourneyMessageType;
                      }[];
                  } | null;
              }[]
            | null;
    };
};

export type GetConfirmedItineraryOrderQueryVariables = Exact<{ [key: string]: never }>;

export type GetConfirmedItineraryOrderQuery = {
    __typename?: 'Query';
    order: {
        __typename?: 'OrderQuery';
        getConfirmedItineraryOrders?:
            | {
                  __typename?: 'ItineraryOrder';
                  id?: string | null;
                  customerId?: string | null;
                  orderType: OrderType;
                  ticketsTransaction?: {
                      __typename?: 'TicketsTransaction';
                      id?: string | null;
                      paymentState: PaymentState;
                      ticketState: TicketState;
                      saferpayToken?: string | null;
                      tickets?:
                          | {
                                __typename?: 'Ticket';
                                id?: string | null;
                                departureStopName?: string | null;
                                purchaseDate?: string | null;
                                qrCodeData?: string | null;
                                fqCode?: string | null;
                                orderNumber?: string | null;
                                refundDate?: string | null;
                                passengerType?: PassengerType | null;
                                refundOrderNumber?: string | null;
                                refundReferenceNumber?: string | null;
                                article:
                                    | { __typename?: 'DepartureArticle' }
                                    | {
                                          __typename?: 'ItineraryArticle';
                                          smsCode?: string | null;
                                          description?: string | null;
                                          title?: string | null;
                                          travelType?: TravelType | null;
                                          vatLabel?: string | null;
                                          tarifOwner?: string | null;
                                          paymentMeans?: PaymentMeans[] | null;
                                          conditions?: string[] | null;
                                          includes?: string[] | null;
                                          roundTrip?: string | null;
                                          isSupersaver?: boolean | null;
                                          category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                          reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                          travelClass?: {
                                              __typename?: 'TravelClass';
                                              id: TravelClassType;
                                              description?: string | null;
                                          } | null;
                                          zones?: { __typename?: 'Zone'; id: string }[] | null;
                                          validity?: {
                                              __typename?: 'Validity';
                                              from?: string | null;
                                              to?: string | null;
                                              label?: string | null;
                                          } | null;
                                          locationsValidity?: {
                                              __typename?: 'LocalizedOptionItems';
                                              label?: string | null;
                                              values?: string[] | null;
                                          } | null;
                                          locationsChoice?: {
                                              __typename?: 'LocalizedOptionItems';
                                              label?: string | null;
                                              values?: string[] | null;
                                          } | null;
                                          thirdPartyPayerInformation?: {
                                              __typename?: 'ThirdPartyPayerInformation';
                                              isEligible: boolean;
                                              thirdPartyName: string;
                                          } | null;
                                      }
                                    | { __typename?: 'QuickArticle' };
                                price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                                passenger: {
                                    __typename?: 'Passenger';
                                    id?: string | null;
                                    firstName: string;
                                    lastName: string;
                                    birthDate: any;
                                    type?: PassengerType | null;
                                };
                                duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                                referenceNumber?: {
                                    __typename?: 'LocalizedOptionItem';
                                    label?: string | null;
                                    value?: string | null;
                                } | null;
                                ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                                articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                            }[]
                          | null;
                  } | null;
                  itinerary?: {
                      __typename?: 'Itinerary';
                      arrivalDate: string;
                      arrivalTime: string;
                      departureDate: string;
                      departureTime: string;
                      scheduledArrivalDate?: string | null;
                      scheduledArrivalTime?: string | null;
                      scheduledDepartureDate?: string | null;
                      scheduledDepartureTime?: string | null;
                      isBookable: boolean;
                      isCancelled: boolean;
                      hasBookingRequirements: boolean;
                      remainingTimeBeforeBooking: number;
                      bookingDeadline: string;
                      hasStopRequest: boolean;
                      id: string;
                      legs: {
                          __typename?: 'Leg';
                          additionalInformation?: string[] | null;
                          byFoot: boolean;
                          serviceNumber?: string | null;
                          serviceId: string;
                          length: string;
                          duration: string;
                          isBookable: boolean;
                          geoJson?: any | null;
                          bookingDeadline: string;
                          isFirstClassAuthorized: boolean;
                          transportCompanyId: string;
                          wkt?: string | null;
                          line: {
                              __typename?: 'Line';
                              color?: string | null;
                              destination: string;
                              fontColor?: string | null;
                              id: string;
                              lineOfficialCode: string;
                              networkId?: string | null;
                              number: string;
                              style: string;
                          };
                          vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
                          stops: {
                              __typename?: 'CommercialStop';
                              scheduledArrivalTime?: string | null;
                              scheduledDepartureTime?: string | null;
                              arrivalTime: string;
                              cityName?: string | null;
                              departureTime: string;
                              hasBookingRequirements: boolean;
                              id: string;
                              isArrivalPlace: boolean;
                              isDeparturePlace: boolean;
                              isOptional: boolean;
                              isCancelled: boolean;
                              latLon: number[];
                              name: string;
                              didok?: number | null;
                              messages: {
                                  __typename?: 'JourneyMessage';
                                  id: string;
                                  htmlContent: string;
                                  title: string;
                                  type: JourneyMessageType;
                              }[];
                              physicalStopAssociated?: {
                                  __typename?: 'PhysicalStop';
                                  geometry: string;
                                  id: string;
                                  letter?: string | null;
                                  transportMode?: string | null;
                                  lines: {
                                      __typename?: 'Line';
                                      color?: string | null;
                                      destination: string;
                                      fontColor?: string | null;
                                      id: string;
                                      lineOfficialCode: string;
                                      networkId?: string | null;
                                      number: string;
                                      style: string;
                                  }[];
                                  associatedCommercialStop?: {
                                      __typename?: 'AssociatedCommercialStop';
                                      cityName?: string | null;
                                      id: string;
                                      name: string;
                                      didok?: number | null;
                                      coordinates?: {
                                          __typename?: 'Coordinates';
                                          accuracy?: number | null;
                                          latitude: number;
                                          longitude: number;
                                      } | null;
                                      lines: {
                                          __typename?: 'Line';
                                          color?: string | null;
                                          destination: string;
                                          fontColor?: string | null;
                                          id: string;
                                          lineOfficialCode: string;
                                          networkId?: string | null;
                                          number: string;
                                          style: string;
                                      }[];
                                  } | null;
                              } | null;
                          }[];
                          messages: {
                              __typename?: 'JourneyMessage';
                              id: string;
                              htmlContent: string;
                              title: string;
                              type: JourneyMessageType;
                          }[];
                      }[];
                  } | null;
              }[]
            | null;
    };
};

export type GetConfirmedQuickTicketsOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetConfirmedQuickTicketsOrdersQuery = {
    __typename?: 'Query';
    order: {
        __typename?: 'OrderQuery';
        getConfirmedQuickTicketsOrders?:
            | {
                  __typename?: 'QuickTicketsOrder';
                  id?: string | null;
                  customerId?: string | null;
                  orderType: OrderType;
                  ticketsTransaction?: {
                      __typename?: 'TicketsTransaction';
                      id?: string | null;
                      paymentState: PaymentState;
                      ticketState: TicketState;
                      saferpayToken?: string | null;
                      tickets?:
                          | {
                                __typename?: 'Ticket';
                                id?: string | null;
                                departureStopName?: string | null;
                                purchaseDate?: string | null;
                                qrCodeData?: string | null;
                                fqCode?: string | null;
                                orderNumber?: string | null;
                                refundDate?: string | null;
                                passengerType?: PassengerType | null;
                                refundOrderNumber?: string | null;
                                refundReferenceNumber?: string | null;
                                article:
                                    | { __typename?: 'DepartureArticle' }
                                    | { __typename?: 'ItineraryArticle' }
                                    | {
                                          __typename?: 'QuickArticle';
                                          smsCode?: string | null;
                                          description?: string | null;
                                          title?: string | null;
                                          travelType?: TravelType | null;
                                          vatLabel?: string | null;
                                          tarifOwner?: string | null;
                                          paymentMeans?: PaymentMeans[] | null;
                                          category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                          reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                          travelClass?: {
                                              __typename?: 'TravelClass';
                                              id: TravelClassType;
                                              description?: string | null;
                                          } | null;
                                          validity?: {
                                              __typename?: 'Validity';
                                              from?: string | null;
                                              to?: string | null;
                                              label?: string | null;
                                          } | null;
                                          zones?: { __typename?: 'Zone'; id: string }[] | null;
                                          locationsValidity?: {
                                              __typename?: 'LocalizedOptionItems';
                                              label?: string | null;
                                              values?: string[] | null;
                                          } | null;
                                          locationsChoice?: {
                                              __typename?: 'LocalizedOptionItems';
                                              label?: string | null;
                                              values?: string[] | null;
                                          } | null;
                                          thirdPartyPayerInformation?: {
                                              __typename?: 'ThirdPartyPayerInformation';
                                              isEligible: boolean;
                                              thirdPartyName: string;
                                          } | null;
                                      };
                                price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                                passenger: {
                                    __typename?: 'Passenger';
                                    id?: string | null;
                                    firstName: string;
                                    lastName: string;
                                    birthDate: any;
                                    type?: PassengerType | null;
                                };
                                duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                                referenceNumber?: {
                                    __typename?: 'LocalizedOptionItem';
                                    label?: string | null;
                                    value?: string | null;
                                } | null;
                                ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                                articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                            }[]
                          | null;
                  } | null;
                  zones?: { __typename?: 'Zone'; id: string }[] | null;
              }[]
            | null;
    };
};

export type GetDepartureOrderQueryVariables = Exact<{
    order: OrderInput;
}>;

export type GetDepartureOrderQuery = {
    __typename?: 'Query';
    order: {
        __typename?: 'OrderQuery';
        getDepartureOrder?: {
            __typename?: 'DepartureOrder';
            id?: string | null;
            customerId?: string | null;
            orderType: OrderType;
            ticketsTransaction?: {
                __typename?: 'TicketsTransaction';
                id?: string | null;
                paymentState: PaymentState;
                ticketState: TicketState;
                saferpayToken?: string | null;
                tickets?:
                    | {
                          __typename?: 'Ticket';
                          id?: string | null;
                          departureStopName?: string | null;
                          purchaseDate?: string | null;
                          qrCodeData?: string | null;
                          fqCode?: string | null;
                          orderNumber?: string | null;
                          refundDate?: string | null;
                          passengerType?: PassengerType | null;
                          refundOrderNumber?: string | null;
                          refundReferenceNumber?: string | null;
                          article:
                              | {
                                    __typename?: 'DepartureArticle';
                                    smsCode?: string | null;
                                    description?: string | null;
                                    title?: string | null;
                                    travelType?: TravelType | null;
                                    vatLabel?: string | null;
                                    tarifOwner?: string | null;
                                    paymentMeans?: PaymentMeans[] | null;
                                    articleId: string;
                                    category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                    reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                    travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
                                    zones?: { __typename?: 'Zone'; id: string }[] | null;
                                    validity?: {
                                        __typename?: 'Validity';
                                        from?: string | null;
                                        to?: string | null;
                                        label?: string | null;
                                    } | null;
                                    locationsValidity?: {
                                        __typename?: 'LocalizedOptionItems';
                                        label?: string | null;
                                        values?: string[] | null;
                                    } | null;
                                    locationsChoice?: {
                                        __typename?: 'LocalizedOptionItems';
                                        label?: string | null;
                                        values?: string[] | null;
                                    } | null;
                                }
                              | { __typename?: 'ItineraryArticle' }
                              | { __typename?: 'QuickArticle' };
                          price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                          passenger: {
                              __typename?: 'Passenger';
                              id?: string | null;
                              firstName: string;
                              lastName: string;
                              birthDate: any;
                              type?: PassengerType | null;
                          };
                          duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                          referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                      }[]
                    | null;
            } | null;
            departure?: {
                __typename?: 'Departure';
                additionalInformation?: string[] | null;
                hasStopRequest: boolean;
                departureTimestamp: string;
                plannedDepartureTime: string;
                direction: string;
                outdatedDate?: string | null;
                serviceId: string;
                track: string;
                transport: string;
                networkId?: string | null;
                id: string;
                isBookable: boolean;
                hasBookingRequirements: boolean;
                remainingTimeBeforeBooking: number;
                bookingDeadline: string;
                isCancelled: boolean;
                line: {
                    __typename?: 'Line';
                    color?: string | null;
                    destination: string;
                    fontColor?: string | null;
                    id: string;
                    lineOfficialCode: string;
                    networkId?: string | null;
                    number: string;
                    style: string;
                };
                stop: {
                    __typename?: 'DepartureStop';
                    cityName?: string | null;
                    id: string;
                    name: string;
                    rank: number;
                    isOptional: boolean;
                    physicalStop: {
                        __typename?: 'PhysicalStop';
                        geometry: string;
                        id: string;
                        letter?: string | null;
                        transportMode?: string | null;
                        lines: {
                            __typename?: 'Line';
                            color?: string | null;
                            destination: string;
                            fontColor?: string | null;
                            id: string;
                            lineOfficialCode: string;
                            networkId?: string | null;
                            number: string;
                            style: string;
                        }[];
                        associatedCommercialStop?: {
                            __typename?: 'AssociatedCommercialStop';
                            cityName?: string | null;
                            id: string;
                            name: string;
                            didok?: number | null;
                            coordinates?: {
                                __typename?: 'Coordinates';
                                accuracy?: number | null;
                                latitude: number;
                                longitude: number;
                            } | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                        } | null;
                    };
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                };
                messages: {
                    __typename?: 'JourneyMessage';
                    id: string;
                    htmlContent: string;
                    title: string;
                    type: JourneyMessageType;
                }[];
            } | null;
        } | null;
    };
};

export type GetDepartureStopRequestQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetDepartureStopRequestQuery = {
    __typename?: 'Query';
    order: {
        __typename?: 'OrderQuery';
        getDepartureStopRequest?: {
            __typename?: 'DepartureStopRequest';
            id?: string | null;
            customerId?: string | null;
            departure?: {
                __typename?: 'Departure';
                additionalInformation?: string[] | null;
                hasStopRequest: boolean;
                departureTimestamp: string;
                plannedDepartureTime: string;
                direction: string;
                outdatedDate?: string | null;
                serviceId: string;
                track: string;
                transport: string;
                networkId?: string | null;
                id: string;
                isBookable: boolean;
                hasBookingRequirements: boolean;
                remainingTimeBeforeBooking: number;
                bookingDeadline: string;
                isCancelled: boolean;
                line: {
                    __typename?: 'Line';
                    color?: string | null;
                    destination: string;
                    fontColor?: string | null;
                    id: string;
                    lineOfficialCode: string;
                    networkId?: string | null;
                    number: string;
                    style: string;
                };
                stop: {
                    __typename?: 'DepartureStop';
                    cityName?: string | null;
                    id: string;
                    name: string;
                    rank: number;
                    isOptional: boolean;
                    physicalStop: {
                        __typename?: 'PhysicalStop';
                        geometry: string;
                        id: string;
                        letter?: string | null;
                        transportMode?: string | null;
                        lines: {
                            __typename?: 'Line';
                            color?: string | null;
                            destination: string;
                            fontColor?: string | null;
                            id: string;
                            lineOfficialCode: string;
                            networkId?: string | null;
                            number: string;
                            style: string;
                        }[];
                        associatedCommercialStop?: {
                            __typename?: 'AssociatedCommercialStop';
                            cityName?: string | null;
                            id: string;
                            name: string;
                            didok?: number | null;
                            coordinates?: {
                                __typename?: 'Coordinates';
                                accuracy?: number | null;
                                latitude: number;
                                longitude: number;
                            } | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                        } | null;
                    };
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                };
                messages: {
                    __typename?: 'JourneyMessage';
                    id: string;
                    htmlContent: string;
                    title: string;
                    type: JourneyMessageType;
                }[];
            } | null;
        } | null;
    };
};

export type GetDepartureStopRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepartureStopRequestsQuery = {
    __typename?: 'Query';
    order: {
        __typename?: 'OrderQuery';
        getDepartureStopRequests?:
            | {
                  __typename?: 'DepartureStopRequest';
                  id?: string | null;
                  customerId?: string | null;
                  departure?: {
                      __typename?: 'Departure';
                      additionalInformation?: string[] | null;
                      hasStopRequest: boolean;
                      departureTimestamp: string;
                      plannedDepartureTime: string;
                      direction: string;
                      outdatedDate?: string | null;
                      serviceId: string;
                      track: string;
                      transport: string;
                      networkId?: string | null;
                      id: string;
                      isBookable: boolean;
                      hasBookingRequirements: boolean;
                      remainingTimeBeforeBooking: number;
                      bookingDeadline: string;
                      isCancelled: boolean;
                      line: {
                          __typename?: 'Line';
                          color?: string | null;
                          destination: string;
                          fontColor?: string | null;
                          id: string;
                          lineOfficialCode: string;
                          networkId?: string | null;
                          number: string;
                          style: string;
                      };
                      stop: {
                          __typename?: 'DepartureStop';
                          cityName?: string | null;
                          id: string;
                          name: string;
                          rank: number;
                          isOptional: boolean;
                          physicalStop: {
                              __typename?: 'PhysicalStop';
                              geometry: string;
                              id: string;
                              letter?: string | null;
                              transportMode?: string | null;
                              lines: {
                                  __typename?: 'Line';
                                  color?: string | null;
                                  destination: string;
                                  fontColor?: string | null;
                                  id: string;
                                  lineOfficialCode: string;
                                  networkId?: string | null;
                                  number: string;
                                  style: string;
                              }[];
                              associatedCommercialStop?: {
                                  __typename?: 'AssociatedCommercialStop';
                                  cityName?: string | null;
                                  id: string;
                                  name: string;
                                  didok?: number | null;
                                  coordinates?: {
                                      __typename?: 'Coordinates';
                                      accuracy?: number | null;
                                      latitude: number;
                                      longitude: number;
                                  } | null;
                                  lines: {
                                      __typename?: 'Line';
                                      color?: string | null;
                                      destination: string;
                                      fontColor?: string | null;
                                      id: string;
                                      lineOfficialCode: string;
                                      networkId?: string | null;
                                      number: string;
                                      style: string;
                                  }[];
                              } | null;
                          };
                          messages: {
                              __typename?: 'JourneyMessage';
                              id: string;
                              htmlContent: string;
                              title: string;
                              type: JourneyMessageType;
                          }[];
                      };
                      messages: {
                          __typename?: 'JourneyMessage';
                          id: string;
                          htmlContent: string;
                          title: string;
                          type: JourneyMessageType;
                      }[];
                  } | null;
              }[]
            | null;
    };
};

export type ItineraryOrderQueryVariables = Exact<{
    order: OrderInput;
}>;

export type ItineraryOrderQuery = {
    __typename?: 'Query';
    orderPublic: {
        __typename?: 'OrderPublicQuery';
        getItineraryOrder?: {
            __typename?: 'ItineraryOrder';
            id?: string | null;
            customerId?: string | null;
            orderType: OrderType;
            ticketsTransaction?: {
                __typename?: 'TicketsTransaction';
                id?: string | null;
                paymentState: PaymentState;
                ticketState: TicketState;
                saferpayToken?: string | null;
                tickets?:
                    | {
                          __typename?: 'Ticket';
                          id?: string | null;
                          departureStopName?: string | null;
                          purchaseDate?: string | null;
                          qrCodeData?: string | null;
                          fqCode?: string | null;
                          orderNumber?: string | null;
                          refundDate?: string | null;
                          passengerType?: PassengerType | null;
                          refundOrderNumber?: string | null;
                          refundReferenceNumber?: string | null;
                          article:
                              | { __typename?: 'DepartureArticle' }
                              | {
                                    __typename?: 'ItineraryArticle';
                                    smsCode?: string | null;
                                    description?: string | null;
                                    title?: string | null;
                                    travelType?: TravelType | null;
                                    vatLabel?: string | null;
                                    tarifOwner?: string | null;
                                    paymentMeans?: PaymentMeans[] | null;
                                    conditions?: string[] | null;
                                    includes?: string[] | null;
                                    roundTrip?: string | null;
                                    isSupersaver?: boolean | null;
                                    category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                    reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                    travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
                                    zones?: { __typename?: 'Zone'; id: string }[] | null;
                                    validity?: {
                                        __typename?: 'Validity';
                                        from?: string | null;
                                        to?: string | null;
                                        label?: string | null;
                                    } | null;
                                    locationsValidity?: {
                                        __typename?: 'LocalizedOptionItems';
                                        label?: string | null;
                                        values?: string[] | null;
                                    } | null;
                                    locationsChoice?: {
                                        __typename?: 'LocalizedOptionItems';
                                        label?: string | null;
                                        values?: string[] | null;
                                    } | null;
                                    thirdPartyPayerInformation?: {
                                        __typename?: 'ThirdPartyPayerInformation';
                                        isEligible: boolean;
                                        thirdPartyName: string;
                                    } | null;
                                }
                              | { __typename?: 'QuickArticle' };
                          price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                          passenger: {
                              __typename?: 'Passenger';
                              id?: string | null;
                              firstName: string;
                              lastName: string;
                              birthDate: any;
                              type?: PassengerType | null;
                          };
                          duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                          referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                      }[]
                    | null;
            } | null;
            itinerary?: {
                __typename?: 'Itinerary';
                arrivalDate: string;
                arrivalTime: string;
                departureDate: string;
                departureTime: string;
                scheduledArrivalDate?: string | null;
                scheduledArrivalTime?: string | null;
                scheduledDepartureDate?: string | null;
                scheduledDepartureTime?: string | null;
                isBookable: boolean;
                isCancelled: boolean;
                hasBookingRequirements: boolean;
                remainingTimeBeforeBooking: number;
                bookingDeadline: string;
                hasStopRequest: boolean;
                id: string;
                legs: {
                    __typename?: 'Leg';
                    additionalInformation?: string[] | null;
                    byFoot: boolean;
                    serviceNumber?: string | null;
                    serviceId: string;
                    length: string;
                    duration: string;
                    isBookable: boolean;
                    geoJson?: any | null;
                    bookingDeadline: string;
                    isFirstClassAuthorized: boolean;
                    transportCompanyId: string;
                    wkt?: string | null;
                    line: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    };
                    vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
                    stops: {
                        __typename?: 'CommercialStop';
                        scheduledArrivalTime?: string | null;
                        scheduledDepartureTime?: string | null;
                        arrivalTime: string;
                        cityName?: string | null;
                        departureTime: string;
                        hasBookingRequirements: boolean;
                        id: string;
                        isArrivalPlace: boolean;
                        isDeparturePlace: boolean;
                        isOptional: boolean;
                        isCancelled: boolean;
                        latLon: number[];
                        name: string;
                        didok?: number | null;
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                        physicalStopAssociated?: {
                            __typename?: 'PhysicalStop';
                            geometry: string;
                            id: string;
                            letter?: string | null;
                            transportMode?: string | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                            associatedCommercialStop?: {
                                __typename?: 'AssociatedCommercialStop';
                                cityName?: string | null;
                                id: string;
                                name: string;
                                didok?: number | null;
                                coordinates?: {
                                    __typename?: 'Coordinates';
                                    accuracy?: number | null;
                                    latitude: number;
                                    longitude: number;
                                } | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                            } | null;
                        } | null;
                    }[];
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                }[];
            } | null;
        } | null;
    };
};

export type GetItineraryStopRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetItineraryStopRequestsQuery = {
    __typename?: 'Query';
    order: {
        __typename?: 'OrderQuery';
        getItineraryStopRequests?:
            | {
                  __typename?: 'ItineraryStopRequest';
                  id?: string | null;
                  customerId?: string | null;
                  itinerary?: {
                      __typename?: 'Itinerary';
                      arrivalDate: string;
                      arrivalTime: string;
                      departureDate: string;
                      departureTime: string;
                      scheduledArrivalDate?: string | null;
                      scheduledArrivalTime?: string | null;
                      scheduledDepartureDate?: string | null;
                      scheduledDepartureTime?: string | null;
                      isBookable: boolean;
                      isCancelled: boolean;
                      hasBookingRequirements: boolean;
                      remainingTimeBeforeBooking: number;
                      bookingDeadline: string;
                      hasStopRequest: boolean;
                      id: string;
                      legs: {
                          __typename?: 'Leg';
                          additionalInformation?: string[] | null;
                          byFoot: boolean;
                          serviceNumber?: string | null;
                          serviceId: string;
                          length: string;
                          duration: string;
                          isBookable: boolean;
                          geoJson?: any | null;
                          bookingDeadline: string;
                          isFirstClassAuthorized: boolean;
                          transportCompanyId: string;
                          wkt?: string | null;
                          line: {
                              __typename?: 'Line';
                              color?: string | null;
                              destination: string;
                              fontColor?: string | null;
                              id: string;
                              lineOfficialCode: string;
                              networkId?: string | null;
                              number: string;
                              style: string;
                          };
                          vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
                          stops: {
                              __typename?: 'CommercialStop';
                              scheduledArrivalTime?: string | null;
                              scheduledDepartureTime?: string | null;
                              arrivalTime: string;
                              cityName?: string | null;
                              departureTime: string;
                              hasBookingRequirements: boolean;
                              id: string;
                              isArrivalPlace: boolean;
                              isDeparturePlace: boolean;
                              isOptional: boolean;
                              isCancelled: boolean;
                              latLon: number[];
                              name: string;
                              didok?: number | null;
                              messages: {
                                  __typename?: 'JourneyMessage';
                                  id: string;
                                  htmlContent: string;
                                  title: string;
                                  type: JourneyMessageType;
                              }[];
                              physicalStopAssociated?: {
                                  __typename?: 'PhysicalStop';
                                  geometry: string;
                                  id: string;
                                  letter?: string | null;
                                  transportMode?: string | null;
                                  lines: {
                                      __typename?: 'Line';
                                      color?: string | null;
                                      destination: string;
                                      fontColor?: string | null;
                                      id: string;
                                      lineOfficialCode: string;
                                      networkId?: string | null;
                                      number: string;
                                      style: string;
                                  }[];
                                  associatedCommercialStop?: {
                                      __typename?: 'AssociatedCommercialStop';
                                      cityName?: string | null;
                                      id: string;
                                      name: string;
                                      didok?: number | null;
                                      coordinates?: {
                                          __typename?: 'Coordinates';
                                          accuracy?: number | null;
                                          latitude: number;
                                          longitude: number;
                                      } | null;
                                      lines: {
                                          __typename?: 'Line';
                                          color?: string | null;
                                          destination: string;
                                          fontColor?: string | null;
                                          id: string;
                                          lineOfficialCode: string;
                                          networkId?: string | null;
                                          number: string;
                                          style: string;
                                      }[];
                                  } | null;
                              } | null;
                          }[];
                          messages: {
                              __typename?: 'JourneyMessage';
                              id: string;
                              htmlContent: string;
                              title: string;
                              type: JourneyMessageType;
                          }[];
                      }[];
                  } | null;
              }[]
            | null;
    };
};

export type GetOrderStatusQueryVariables = Exact<{
    orderId: Scalars['String'];
}>;

export type GetOrderStatusQuery = { __typename?: 'Query'; orderPublic: { __typename?: 'OrderPublicQuery'; getOrderStatus: OrderStatus } };

export type QuickTicketOrderQueryVariables = Exact<{
    order: OrderInput;
}>;

export type QuickTicketOrderQuery = {
    __typename?: 'Query';
    orderPublic: {
        __typename?: 'OrderPublicQuery';
        getQuickTicketsOrder?: {
            __typename?: 'QuickTicketsOrder';
            id?: string | null;
            customerId?: string | null;
            orderType: OrderType;
            ticketsTransaction?: {
                __typename?: 'TicketsTransaction';
                id?: string | null;
                paymentState: PaymentState;
                ticketState: TicketState;
                saferpayToken?: string | null;
                tickets?:
                    | {
                          __typename?: 'Ticket';
                          id?: string | null;
                          departureStopName?: string | null;
                          purchaseDate?: string | null;
                          qrCodeData?: string | null;
                          fqCode?: string | null;
                          orderNumber?: string | null;
                          refundDate?: string | null;
                          passengerType?: PassengerType | null;
                          refundOrderNumber?: string | null;
                          refundReferenceNumber?: string | null;
                          article:
                              | { __typename?: 'DepartureArticle' }
                              | { __typename?: 'ItineraryArticle' }
                              | {
                                    __typename?: 'QuickArticle';
                                    smsCode?: string | null;
                                    description?: string | null;
                                    title?: string | null;
                                    travelType?: TravelType | null;
                                    vatLabel?: string | null;
                                    tarifOwner?: string | null;
                                    paymentMeans?: PaymentMeans[] | null;
                                    category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                    reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                    travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
                                    validity?: {
                                        __typename?: 'Validity';
                                        from?: string | null;
                                        to?: string | null;
                                        label?: string | null;
                                    } | null;
                                    zones?: { __typename?: 'Zone'; id: string }[] | null;
                                    locationsValidity?: {
                                        __typename?: 'LocalizedOptionItems';
                                        label?: string | null;
                                        values?: string[] | null;
                                    } | null;
                                    locationsChoice?: {
                                        __typename?: 'LocalizedOptionItems';
                                        label?: string | null;
                                        values?: string[] | null;
                                    } | null;
                                    thirdPartyPayerInformation?: {
                                        __typename?: 'ThirdPartyPayerInformation';
                                        isEligible: boolean;
                                        thirdPartyName: string;
                                    } | null;
                                };
                          price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                          passenger: {
                              __typename?: 'Passenger';
                              id?: string | null;
                              firstName: string;
                              lastName: string;
                              birthDate: any;
                              type?: PassengerType | null;
                          };
                          duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                          referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                      }[]
                    | null;
            } | null;
            zones?: { __typename?: 'Zone'; id: string }[] | null;
        } | null;
    };
};

export type GetByBoundingBoxQueryVariables = Exact<{
    boundingBox: BoundingBoxInput;
}>;

export type GetByBoundingBoxQuery = {
    __typename?: 'Query';
    physicalStops: {
        __typename?: 'PhysicalStopQuery';
        byBoundingBox: {
            __typename?: 'PhysicalStop';
            geometry: string;
            id: string;
            letter?: string | null;
            transportMode?: string | null;
            lines: {
                __typename?: 'Line';
                color?: string | null;
                destination: string;
                fontColor?: string | null;
                id: string;
                lineOfficialCode: string;
                networkId?: string | null;
                number: string;
                style: string;
            }[];
            associatedCommercialStop?: {
                __typename?: 'AssociatedCommercialStop';
                cityName?: string | null;
                id: string;
                name: string;
                didok?: number | null;
                coordinates?: { __typename?: 'Coordinates'; accuracy?: number | null; latitude: number; longitude: number } | null;
                lines: {
                    __typename?: 'Line';
                    color?: string | null;
                    destination: string;
                    fontColor?: string | null;
                    id: string;
                    lineOfficialCode: string;
                    networkId?: string | null;
                    number: string;
                    style: string;
                }[];
            } | null;
        }[];
    };
};

export type GetByCoordinatesQueryVariables = Exact<{
    coordinates: CoordinatesInput;
}>;

export type GetByCoordinatesQuery = {
    __typename?: 'Query';
    physicalStops: {
        __typename?: 'PhysicalStopQuery';
        byCoordinates: {
            __typename?: 'PhysicalStop';
            geometry: string;
            id: string;
            letter?: string | null;
            transportMode?: string | null;
            lines: {
                __typename?: 'Line';
                color?: string | null;
                destination: string;
                fontColor?: string | null;
                id: string;
                lineOfficialCode: string;
                networkId?: string | null;
                number: string;
                style: string;
            }[];
            associatedCommercialStop?: {
                __typename?: 'AssociatedCommercialStop';
                cityName?: string | null;
                id: string;
                name: string;
                didok?: number | null;
                coordinates?: { __typename?: 'Coordinates'; accuracy?: number | null; latitude: number; longitude: number } | null;
                lines: {
                    __typename?: 'Line';
                    color?: string | null;
                    destination: string;
                    fontColor?: string | null;
                    id: string;
                    lineOfficialCode: string;
                    networkId?: string | null;
                    number: string;
                    style: string;
                }[];
            } | null;
        }[];
    };
};

export type GetPlacesQueryVariables = Exact<{
    searchQueryInput: SearchQueryInput;
}>;

export type GetPlacesQuery = {
    __typename?: 'Query';
    places: {
        __typename?: 'PlaceQuery';
        search: {
            __typename?: 'Places';
            cities: {
                __typename?: 'CityPlace';
                id: string;
                scoring: number;
                name: string;
                mainStops: {
                    __typename?: 'MainStopPlace';
                    id: string;
                    cityName?: string | null;
                    name: string;
                    latLon: number[];
                    scoring?: number | null;
                }[];
            }[];
            poi: { __typename?: 'PoiPlace'; id: string; cityName: string; name: string; latLon: number[]; scoring: number }[];
            address: {
                __typename?: 'AddressPlace';
                address: string;
                city: string;
                latLon: number[];
                latitude: number;
                longitude: number;
                scoring: number;
                zip: string;
            }[];
            stops: {
                __typename?: 'MainStopPlace';
                id: string;
                cityName?: string | null;
                name: string;
                latLon: number[];
                scoring?: number | null;
            }[];
        };
    };
};

export type GetMarketingConsentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMarketingConsentsQuery = {
    __typename?: 'Query';
    preferences: {
        __typename?: 'PreferencesQuery';
        marketingConsents: { __typename?: 'MarketingConsents'; national?: boolean | null; company?: boolean | null };
    };
};

export type PreflightQueryVariables = Exact<{ [key: string]: never }>;

export type PreflightQuery = { __typename?: 'Query'; preflight: { __typename?: 'Preflight'; isClientOutdated: boolean } };

export type GetRouteQueryVariables = Exact<{
    routeInput: RouteInput;
}>;

export type GetRouteQuery = {
    __typename?: 'Query';
    route: {
        __typename?: 'Route';
        id: string;
        line: {
            __typename?: 'Line';
            color?: string | null;
            destination: string;
            fontColor?: string | null;
            id: string;
            lineOfficialCode: string;
            networkId?: string | null;
            number: string;
            style: string;
        };
        stops: {
            __typename?: 'TransitStop';
            arrivalDate?: string | null;
            arrivalRealTime?: string | null;
            arrivalRealTimeDate?: string | null;
            arrivalTime?: string | null;
            cityName?: string | null;
            departureDate?: string | null;
            departureRealTime?: string | null;
            departureRealTimeDate?: string | null;
            departureTime?: string | null;
            hasBookingRequirements?: boolean | null;
            id?: string | null;
            isCancelled?: boolean | null;
            isOptional?: boolean | null;
            rank?: string | null;
            stopAreaName?: string | null;
            stopPointName?: string | null;
            tadId?: string | null;
            letter?: string | null;
            messages: { __typename?: 'JourneyMessage'; id: string; htmlContent: string; title: string; type: JourneyMessageType }[];
            coordinates: { __typename?: 'Coordinates'; latitude: number; longitude: number };
        }[];
    };
};

export type GetSmsTicketsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSmsTicketsQuery = {
    __typename?: 'Query';
    smsTickets: {
        __typename?: 'SmsTicketCategory';
        order: number;
        label: { __typename?: 'SmsLanguageLabel'; languageCode: string; label: string }[];
        subCategories?:
            | {
                  __typename?: 'SmsTicketSubCategory';
                  order: number;
                  label: { __typename?: 'SmsLanguageLabel'; languageCode: string; label: string }[];
                  tickets: {
                      __typename?: 'SmsTicket';
                      code: string;
                      order: number;
                      label: { __typename?: 'SmsLanguageLabel'; languageCode: string; label: string }[];
                      price: { __typename?: 'SmsCurrencyPrice'; amountInCents: number; currency: string }[];
                  }[];
              }[]
            | null;
        tickets?:
            | {
                  __typename?: 'SmsTicket';
                  code: string;
                  order: number;
                  label: { __typename?: 'SmsLanguageLabel'; languageCode: string; label: string }[];
                  price: { __typename?: 'SmsCurrencyPrice'; amountInCents: number; currency: string }[];
              }[]
            | null;
    }[];
};

export type StartupNotificationQueryVariables = Exact<{ [key: string]: never }>;

export type StartupNotificationQuery = {
    __typename?: 'Query';
    startupNotifications: { __typename?: 'StartupNotificationMessage'; id: string; htmlContent: string; title: string }[];
};

export type UpdateQueryVariables = Exact<{ [key: string]: never }>;

export type UpdateQuery = { __typename?: 'Query'; update: { __typename?: 'Update'; messageHtml: string; forceUpdate: boolean } };

export type LoginControllerMutationVariables = Exact<{
    username: Scalars['String'];
    password: Scalars['String'];
}>;

export type LoginControllerMutation = {
    __typename?: 'Mutation';
    auth: {
        __typename?: 'AuthMutation';
        basicAuth: {
            __typename?: 'BasicAuthMutation';
            authenticateController: { __typename?: 'AuthenticationResult'; accessToken: string; errors?: string | null };
        };
    };
};

export type BasicAuthLoginMutationVariables = Exact<{
    username: Scalars['String'];
    password: Scalars['String'];
}>;

export type BasicAuthLoginMutation = {
    __typename?: 'Mutation';
    auth: {
        __typename?: 'AuthMutation';
        basicAuth: { __typename?: 'BasicAuthMutation'; authenticate: { __typename?: 'AuthenticationResult'; accessToken: string } };
    };
};

export type GenerateQuickArticlesByZonesMutationVariables = Exact<{
    generateQuickArticlesByZonesInput: GenerateQuickArticlesByZoneInput;
}>;

export type GenerateQuickArticlesByZonesMutation = {
    __typename?: 'Mutation';
    article: {
        __typename?: 'ArticleMutation';
        generateQuickArticlesByZone?:
            | {
                  __typename?: 'QuickArticle';
                  articleId: string;
                  description?: string | null;
                  categoryDescription?: string | null;
                  smsCode?: string | null;
                  title?: string | null;
                  vatLabel?: string | null;
                  paymentMeans?: PaymentMeans[] | null;
                  tripClass?: string | null;
                  travelType?: TravelType | null;
                  type: ArticleType;
                  validityDurationLabel?: string | null;
                  tarifOwner?: string | null;
                  category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                  prices?: { __typename?: 'Price'; amountInCents: number; currency: Currency }[] | null;
                  travelClass?: { __typename?: 'TravelClass'; id: TravelClassType; description?: string | null } | null;
                  locationsValidity?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
                  locationsChoice?: { __typename?: 'LocalizedOptionItems'; label?: string | null; values?: string[] | null } | null;
                  reduction?: { __typename?: 'Reduction'; description: string; id?: ReductionType | null } | null;
                  zones?: { __typename?: 'Zone'; id: string; description?: string | null }[] | null;
                  thirdPartyPayerInformation?: {
                      __typename?: 'ThirdPartyPayerInformation';
                      isEligible: boolean;
                      fare: number;
                      thirdPartyName: string;
                  } | null;
              }[]
            | null;
    };
};

export type OnAuthenticationMutationVariables = Exact<{ [key: string]: never }>;

export type OnAuthenticationMutation = { __typename?: 'Mutation'; onAuthentication: boolean };

export type GetAllOrdersByCardQueryVariables = Exact<{
    cardId: Scalars['String'];
    afterBookingDate: Scalars['String'];
}>;

export type GetAllOrdersByCardQuery = {
    __typename?: 'Query';
    order: {
        __typename?: 'OrderQuery';
        getAllOrders: {
            __typename?: 'OrderGroup';
            departureOrders: {
                __typename?: 'DepartureOrder';
                id?: string | null;
                customerId?: string | null;
                orderType: OrderType;
                ticketsTransaction?: {
                    __typename?: 'TicketsTransaction';
                    id?: string | null;
                    paymentState: PaymentState;
                    ticketState: TicketState;
                    saferpayToken?: string | null;
                    tickets?:
                        | {
                              __typename?: 'Ticket';
                              id?: string | null;
                              departureStopName?: string | null;
                              purchaseDate?: string | null;
                              qrCodeData?: string | null;
                              fqCode?: string | null;
                              orderNumber?: string | null;
                              refundDate?: string | null;
                              passengerType?: PassengerType | null;
                              refundOrderNumber?: string | null;
                              refundReferenceNumber?: string | null;
                              article:
                                  | {
                                        __typename?: 'DepartureArticle';
                                        smsCode?: string | null;
                                        description?: string | null;
                                        title?: string | null;
                                        travelType?: TravelType | null;
                                        vatLabel?: string | null;
                                        tarifOwner?: string | null;
                                        paymentMeans?: PaymentMeans[] | null;
                                        articleId: string;
                                        category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                        reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                        travelClass?: {
                                            __typename?: 'TravelClass';
                                            id: TravelClassType;
                                            description?: string | null;
                                        } | null;
                                        zones?: { __typename?: 'Zone'; id: string }[] | null;
                                        validity?: {
                                            __typename?: 'Validity';
                                            from?: string | null;
                                            to?: string | null;
                                            label?: string | null;
                                        } | null;
                                        locationsValidity?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        locationsChoice?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                    }
                                  | { __typename?: 'ItineraryArticle' }
                                  | { __typename?: 'QuickArticle' };
                              price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                              passenger: {
                                  __typename?: 'Passenger';
                                  id?: string | null;
                                  firstName: string;
                                  lastName: string;
                                  birthDate: any;
                                  type?: PassengerType | null;
                              };
                              duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                              referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          }[]
                        | null;
                } | null;
                departure?: {
                    __typename?: 'Departure';
                    additionalInformation?: string[] | null;
                    hasStopRequest: boolean;
                    departureTimestamp: string;
                    plannedDepartureTime: string;
                    direction: string;
                    outdatedDate?: string | null;
                    serviceId: string;
                    track: string;
                    transport: string;
                    networkId?: string | null;
                    id: string;
                    isBookable: boolean;
                    hasBookingRequirements: boolean;
                    remainingTimeBeforeBooking: number;
                    bookingDeadline: string;
                    isCancelled: boolean;
                    line: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    };
                    stop: {
                        __typename?: 'DepartureStop';
                        cityName?: string | null;
                        id: string;
                        name: string;
                        rank: number;
                        isOptional: boolean;
                        physicalStop: {
                            __typename?: 'PhysicalStop';
                            geometry: string;
                            id: string;
                            letter?: string | null;
                            transportMode?: string | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                            associatedCommercialStop?: {
                                __typename?: 'AssociatedCommercialStop';
                                cityName?: string | null;
                                id: string;
                                name: string;
                                didok?: number | null;
                                coordinates?: {
                                    __typename?: 'Coordinates';
                                    accuracy?: number | null;
                                    latitude: number;
                                    longitude: number;
                                } | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                            } | null;
                        };
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                    };
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                } | null;
            }[];
            departureStopRequests: {
                __typename?: 'DepartureStopRequest';
                id?: string | null;
                customerId?: string | null;
                departure?: {
                    __typename?: 'Departure';
                    additionalInformation?: string[] | null;
                    hasStopRequest: boolean;
                    departureTimestamp: string;
                    plannedDepartureTime: string;
                    direction: string;
                    outdatedDate?: string | null;
                    serviceId: string;
                    track: string;
                    transport: string;
                    networkId?: string | null;
                    id: string;
                    isBookable: boolean;
                    hasBookingRequirements: boolean;
                    remainingTimeBeforeBooking: number;
                    bookingDeadline: string;
                    isCancelled: boolean;
                    line: {
                        __typename?: 'Line';
                        color?: string | null;
                        destination: string;
                        fontColor?: string | null;
                        id: string;
                        lineOfficialCode: string;
                        networkId?: string | null;
                        number: string;
                        style: string;
                    };
                    stop: {
                        __typename?: 'DepartureStop';
                        cityName?: string | null;
                        id: string;
                        name: string;
                        rank: number;
                        isOptional: boolean;
                        physicalStop: {
                            __typename?: 'PhysicalStop';
                            geometry: string;
                            id: string;
                            letter?: string | null;
                            transportMode?: string | null;
                            lines: {
                                __typename?: 'Line';
                                color?: string | null;
                                destination: string;
                                fontColor?: string | null;
                                id: string;
                                lineOfficialCode: string;
                                networkId?: string | null;
                                number: string;
                                style: string;
                            }[];
                            associatedCommercialStop?: {
                                __typename?: 'AssociatedCommercialStop';
                                cityName?: string | null;
                                id: string;
                                name: string;
                                didok?: number | null;
                                coordinates?: {
                                    __typename?: 'Coordinates';
                                    accuracy?: number | null;
                                    latitude: number;
                                    longitude: number;
                                } | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                            } | null;
                        };
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                    };
                    messages: {
                        __typename?: 'JourneyMessage';
                        id: string;
                        htmlContent: string;
                        title: string;
                        type: JourneyMessageType;
                    }[];
                } | null;
            }[];
            itineraryOrders: {
                __typename?: 'ItineraryOrder';
                id?: string | null;
                customerId?: string | null;
                orderType: OrderType;
                ticketsTransaction?: {
                    __typename?: 'TicketsTransaction';
                    id?: string | null;
                    paymentState: PaymentState;
                    ticketState: TicketState;
                    saferpayToken?: string | null;
                    tickets?:
                        | {
                              __typename?: 'Ticket';
                              id?: string | null;
                              departureStopName?: string | null;
                              purchaseDate?: string | null;
                              qrCodeData?: string | null;
                              fqCode?: string | null;
                              orderNumber?: string | null;
                              refundDate?: string | null;
                              passengerType?: PassengerType | null;
                              refundOrderNumber?: string | null;
                              refundReferenceNumber?: string | null;
                              article:
                                  | { __typename?: 'DepartureArticle' }
                                  | {
                                        __typename?: 'ItineraryArticle';
                                        smsCode?: string | null;
                                        description?: string | null;
                                        title?: string | null;
                                        travelType?: TravelType | null;
                                        vatLabel?: string | null;
                                        tarifOwner?: string | null;
                                        paymentMeans?: PaymentMeans[] | null;
                                        conditions?: string[] | null;
                                        includes?: string[] | null;
                                        roundTrip?: string | null;
                                        isSupersaver?: boolean | null;
                                        category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                        reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                        travelClass?: {
                                            __typename?: 'TravelClass';
                                            id: TravelClassType;
                                            description?: string | null;
                                        } | null;
                                        zones?: { __typename?: 'Zone'; id: string }[] | null;
                                        validity?: {
                                            __typename?: 'Validity';
                                            from?: string | null;
                                            to?: string | null;
                                            label?: string | null;
                                        } | null;
                                        locationsValidity?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        locationsChoice?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        thirdPartyPayerInformation?: {
                                            __typename?: 'ThirdPartyPayerInformation';
                                            isEligible: boolean;
                                            thirdPartyName: string;
                                        } | null;
                                    }
                                  | { __typename?: 'QuickArticle' };
                              price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                              passenger: {
                                  __typename?: 'Passenger';
                                  id?: string | null;
                                  firstName: string;
                                  lastName: string;
                                  birthDate: any;
                                  type?: PassengerType | null;
                              };
                              duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                              referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          }[]
                        | null;
                } | null;
                itinerary?: {
                    __typename?: 'Itinerary';
                    arrivalDate: string;
                    arrivalTime: string;
                    departureDate: string;
                    departureTime: string;
                    scheduledArrivalDate?: string | null;
                    scheduledArrivalTime?: string | null;
                    scheduledDepartureDate?: string | null;
                    scheduledDepartureTime?: string | null;
                    isBookable: boolean;
                    isCancelled: boolean;
                    hasBookingRequirements: boolean;
                    remainingTimeBeforeBooking: number;
                    bookingDeadline: string;
                    hasStopRequest: boolean;
                    id: string;
                    legs: {
                        __typename?: 'Leg';
                        additionalInformation?: string[] | null;
                        byFoot: boolean;
                        serviceNumber?: string | null;
                        serviceId: string;
                        length: string;
                        duration: string;
                        isBookable: boolean;
                        geoJson?: any | null;
                        bookingDeadline: string;
                        isFirstClassAuthorized: boolean;
                        transportCompanyId: string;
                        wkt?: string | null;
                        line: {
                            __typename?: 'Line';
                            color?: string | null;
                            destination: string;
                            fontColor?: string | null;
                            id: string;
                            lineOfficialCode: string;
                            networkId?: string | null;
                            number: string;
                            style: string;
                        };
                        vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
                        stops: {
                            __typename?: 'CommercialStop';
                            scheduledArrivalTime?: string | null;
                            scheduledDepartureTime?: string | null;
                            arrivalTime: string;
                            cityName?: string | null;
                            departureTime: string;
                            hasBookingRequirements: boolean;
                            id: string;
                            isArrivalPlace: boolean;
                            isDeparturePlace: boolean;
                            isOptional: boolean;
                            isCancelled: boolean;
                            latLon: number[];
                            name: string;
                            didok?: number | null;
                            messages: {
                                __typename?: 'JourneyMessage';
                                id: string;
                                htmlContent: string;
                                title: string;
                                type: JourneyMessageType;
                            }[];
                            physicalStopAssociated?: {
                                __typename?: 'PhysicalStop';
                                geometry: string;
                                id: string;
                                letter?: string | null;
                                transportMode?: string | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                                associatedCommercialStop?: {
                                    __typename?: 'AssociatedCommercialStop';
                                    cityName?: string | null;
                                    id: string;
                                    name: string;
                                    didok?: number | null;
                                    coordinates?: {
                                        __typename?: 'Coordinates';
                                        accuracy?: number | null;
                                        latitude: number;
                                        longitude: number;
                                    } | null;
                                    lines: {
                                        __typename?: 'Line';
                                        color?: string | null;
                                        destination: string;
                                        fontColor?: string | null;
                                        id: string;
                                        lineOfficialCode: string;
                                        networkId?: string | null;
                                        number: string;
                                        style: string;
                                    }[];
                                } | null;
                            } | null;
                        }[];
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                    }[];
                } | null;
            }[];
            itineraryStopRequests: {
                __typename?: 'ItineraryStopRequest';
                id?: string | null;
                customerId?: string | null;
                itinerary?: {
                    __typename?: 'Itinerary';
                    arrivalDate: string;
                    arrivalTime: string;
                    departureDate: string;
                    departureTime: string;
                    scheduledArrivalDate?: string | null;
                    scheduledArrivalTime?: string | null;
                    scheduledDepartureDate?: string | null;
                    scheduledDepartureTime?: string | null;
                    isBookable: boolean;
                    isCancelled: boolean;
                    hasBookingRequirements: boolean;
                    remainingTimeBeforeBooking: number;
                    bookingDeadline: string;
                    hasStopRequest: boolean;
                    id: string;
                    legs: {
                        __typename?: 'Leg';
                        additionalInformation?: string[] | null;
                        byFoot: boolean;
                        serviceNumber?: string | null;
                        serviceId: string;
                        length: string;
                        duration: string;
                        isBookable: boolean;
                        geoJson?: any | null;
                        bookingDeadline: string;
                        isFirstClassAuthorized: boolean;
                        transportCompanyId: string;
                        wkt?: string | null;
                        line: {
                            __typename?: 'Line';
                            color?: string | null;
                            destination: string;
                            fontColor?: string | null;
                            id: string;
                            lineOfficialCode: string;
                            networkId?: string | null;
                            number: string;
                            style: string;
                        };
                        vehicle: { __typename?: 'Vehicle'; transport: string; code?: string | null; transportModeId?: string | null };
                        stops: {
                            __typename?: 'CommercialStop';
                            scheduledArrivalTime?: string | null;
                            scheduledDepartureTime?: string | null;
                            arrivalTime: string;
                            cityName?: string | null;
                            departureTime: string;
                            hasBookingRequirements: boolean;
                            id: string;
                            isArrivalPlace: boolean;
                            isDeparturePlace: boolean;
                            isOptional: boolean;
                            isCancelled: boolean;
                            latLon: number[];
                            name: string;
                            didok?: number | null;
                            messages: {
                                __typename?: 'JourneyMessage';
                                id: string;
                                htmlContent: string;
                                title: string;
                                type: JourneyMessageType;
                            }[];
                            physicalStopAssociated?: {
                                __typename?: 'PhysicalStop';
                                geometry: string;
                                id: string;
                                letter?: string | null;
                                transportMode?: string | null;
                                lines: {
                                    __typename?: 'Line';
                                    color?: string | null;
                                    destination: string;
                                    fontColor?: string | null;
                                    id: string;
                                    lineOfficialCode: string;
                                    networkId?: string | null;
                                    number: string;
                                    style: string;
                                }[];
                                associatedCommercialStop?: {
                                    __typename?: 'AssociatedCommercialStop';
                                    cityName?: string | null;
                                    id: string;
                                    name: string;
                                    didok?: number | null;
                                    coordinates?: {
                                        __typename?: 'Coordinates';
                                        accuracy?: number | null;
                                        latitude: number;
                                        longitude: number;
                                    } | null;
                                    lines: {
                                        __typename?: 'Line';
                                        color?: string | null;
                                        destination: string;
                                        fontColor?: string | null;
                                        id: string;
                                        lineOfficialCode: string;
                                        networkId?: string | null;
                                        number: string;
                                        style: string;
                                    }[];
                                } | null;
                            } | null;
                        }[];
                        messages: {
                            __typename?: 'JourneyMessage';
                            id: string;
                            htmlContent: string;
                            title: string;
                            type: JourneyMessageType;
                        }[];
                    }[];
                } | null;
            }[];
            quickTicketOrders: {
                __typename?: 'QuickTicketsOrder';
                id?: string | null;
                customerId?: string | null;
                orderType: OrderType;
                ticketsTransaction?: {
                    __typename?: 'TicketsTransaction';
                    id?: string | null;
                    paymentState: PaymentState;
                    ticketState: TicketState;
                    saferpayToken?: string | null;
                    tickets?:
                        | {
                              __typename?: 'Ticket';
                              id?: string | null;
                              departureStopName?: string | null;
                              purchaseDate?: string | null;
                              qrCodeData?: string | null;
                              fqCode?: string | null;
                              orderNumber?: string | null;
                              refundDate?: string | null;
                              passengerType?: PassengerType | null;
                              refundOrderNumber?: string | null;
                              refundReferenceNumber?: string | null;
                              article:
                                  | { __typename?: 'DepartureArticle' }
                                  | { __typename?: 'ItineraryArticle' }
                                  | {
                                        __typename?: 'QuickArticle';
                                        smsCode?: string | null;
                                        description?: string | null;
                                        title?: string | null;
                                        travelType?: TravelType | null;
                                        vatLabel?: string | null;
                                        tarifOwner?: string | null;
                                        paymentMeans?: PaymentMeans[] | null;
                                        category?: { __typename?: 'Category'; id: string; description?: string | null } | null;
                                        reduction?: { __typename?: 'Reduction'; id?: ReductionType | null; description: string } | null;
                                        travelClass?: {
                                            __typename?: 'TravelClass';
                                            id: TravelClassType;
                                            description?: string | null;
                                        } | null;
                                        validity?: {
                                            __typename?: 'Validity';
                                            from?: string | null;
                                            to?: string | null;
                                            label?: string | null;
                                        } | null;
                                        zones?: { __typename?: 'Zone'; id: string }[] | null;
                                        locationsValidity?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        locationsChoice?: {
                                            __typename?: 'LocalizedOptionItems';
                                            label?: string | null;
                                            values?: string[] | null;
                                        } | null;
                                        thirdPartyPayerInformation?: {
                                            __typename?: 'ThirdPartyPayerInformation';
                                            isEligible: boolean;
                                            thirdPartyName: string;
                                        } | null;
                                    };
                              price: { __typename?: 'Price'; amountInCents: number; currency: Currency };
                              passenger: {
                                  __typename?: 'Passenger';
                                  id?: string | null;
                                  firstName: string;
                                  lastName: string;
                                  birthDate: any;
                                  type?: PassengerType | null;
                              };
                              duration?: { __typename?: 'Duration'; id: DurationType; description?: string | null } | null;
                              referenceNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              ticketNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                              articleNumber?: { __typename?: 'LocalizedOptionItem'; label?: string | null; value?: string | null } | null;
                          }[]
                        | null;
                } | null;
                zones?: { __typename?: 'Zone'; id: string }[] | null;
            }[];
            processingOrders: {
                __typename?: 'ProcessingOrder';
                id: string;
                purchaseDate: string;
                orderType: OrderType;
                passenger: { __typename?: 'Passenger'; id?: string | null; firstName: string; lastName: string; birthDate: any };
            }[];
        };
    };
};

export type GetCustomerProfileByCardIdQueryVariables = Exact<{
    cardId: Scalars['String'];
}>;

export type GetCustomerProfileByCardIdQuery = {
    __typename?: 'Query';
    customer: {
        __typename?: 'CustomerQuery';
        profileByCardId:
            | {
                  __typename?: 'CustomerProfile';
                  id: string;
                  birthDate?: any | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  email: string;
                  hasHalfFare?: boolean | null;
                  phone?: string | null;
                  passengerId: string;
                  subscriptionNumber?: string | null;
                  address?: {
                      __typename?: 'Address';
                      city?: string | null;
                      postalCode?: string | null;
                      street?: string | null;
                      number?: string | null;
                  } | null;
                  title: { __typename?: 'Title'; label: string; value: string };
                  language?: { __typename?: 'Language'; id: string } | null;
              }
            | { __typename?: 'ResponseError' };
    };
};

export type TicketListQueryVariables = Exact<{ [key: string]: never }>;

export type TicketListQuery = {
    __typename?: 'Query';
    getQuickArticles: {
        __typename?: 'QuickArticle';
        title?: string | null;
        articleId: string;
        tarifOwner?: string | null;
        travelType?: TravelType | null;
        articleNumber?: { __typename?: 'LocalizedOptionItem'; value?: string | null } | null;
        zones?: { __typename?: 'Zone'; id: string }[] | null;
    }[];
};

export const AddressFragmentDoc = gql`
    fragment Address on Address {
        city
        postalCode
        street
        number
    }
`;
export const ProfileFragmentDoc = gql`
    fragment Profile on CustomerProfile {
        id
        birthDate
        firstName
        lastName
        email
        birthDate
        hasHalfFare
        address {
            ...Address
        }
        phone
        passengerId
        title {
            label
            value
        }
        language {
            id
        }
        subscriptionNumber
    }
    ${AddressFragmentDoc}
`;
export const ErrorFragmentDoc = gql`
    fragment Error on ResponseError {
        errorCode
        httpCode
        originUrl
        redirectUrl
    }
`;
export const DepartureArticleFragmentDoc = gql`
    fragment DepartureArticle on DepartureArticle {
        category {
            id
            description
        }
        smsCode
        description
        reduction {
            id
            description
        }
        title
        travelClass {
            id
            description
        }
        zones {
            id
        }
        validity {
            from
            to
            label
        }
        travelType
        locationsValidity {
            label
            values
        }
        locationsChoice {
            label
            values
        }
        vatLabel
        tarifOwner
        paymentMeans
        articleId
    }
`;
export const DepartureTicketFragmentDoc = gql`
    fragment DepartureTicket on Ticket {
        id
        article {
            ...DepartureArticle
        }
        departureStopName
        price {
            amountInCents
            currency
        }
        purchaseDate
        qrCodeData
        passenger {
            id
            firstName
            lastName
            birthDate
            type
        }
        duration {
            id
            description
        }
        fqCode
        orderNumber
        referenceNumber {
            label
            value
        }
        ticketNumber {
            label
            value
        }
        articleNumber {
            label
            value
        }
        refundDate
        passengerType
        refundOrderNumber
        refundReferenceNumber
    }
    ${DepartureArticleFragmentDoc}
`;
export const LineFragmentDoc = gql`
    fragment Line on Line {
        color
        destination
        fontColor
        id
        lineOfficialCode
        networkId
        number
        style
    }
`;
export const AssociatedCommercialStopFragmentDoc = gql`
    fragment AssociatedCommercialStop on AssociatedCommercialStop {
        cityName
        coordinates {
            accuracy
            latitude
            longitude
        }
        id
        lines {
            ...Line
        }
        name
        didok
    }
    ${LineFragmentDoc}
`;
export const PhysicalStopFragmentDoc = gql`
    fragment PhysicalStop on PhysicalStop {
        geometry
        id
        letter
        lines {
            ...Line
        }
        transportMode
        associatedCommercialStop {
            ...AssociatedCommercialStop
        }
    }
    ${LineFragmentDoc}
    ${AssociatedCommercialStopFragmentDoc}
`;
export const MessageFragmentDoc = gql`
    fragment Message on JourneyMessage {
        id
        htmlContent
        title
        type
    }
`;
export const DepartureFragmentDoc = gql`
    fragment Departure on Departure {
        additionalInformation
        hasStopRequest
        departureTimestamp
        plannedDepartureTime
        direction
        outdatedDate
        line {
            ...Line
        }
        serviceId
        stop {
            cityName
            id
            name
            rank
            isOptional
            physicalStop {
                ...PhysicalStop
            }
            messages {
                ...Message
            }
        }
        track
        transport
        networkId
        id
        isBookable
        hasBookingRequirements
        remainingTimeBeforeBooking
        bookingDeadline
        messages {
            ...Message
        }
        isCancelled
    }
    ${LineFragmentDoc}
    ${PhysicalStopFragmentDoc}
    ${MessageFragmentDoc}
`;
export const ItineraryArticleFragmentDoc = gql`
    fragment ItineraryArticle on ItineraryArticle {
        category {
            id
            description
        }
        smsCode
        description
        reduction {
            id
            description
        }
        title
        travelClass {
            id
            description
        }
        zones {
            id
        }
        validity {
            from
            to
            label
        }
        travelType
        locationsValidity {
            label
            values
        }
        locationsChoice {
            label
            values
        }
        vatLabel
        tarifOwner
        paymentMeans
        conditions
        includes
        roundTrip
        isSupersaver
        thirdPartyPayerInformation {
            isEligible
            thirdPartyName
        }
    }
`;
export const ItineraryTicketFragmentDoc = gql`
    fragment ItineraryTicket on Ticket {
        id
        article {
            ...ItineraryArticle
        }
        departureStopName
        price {
            amountInCents
            currency
        }
        purchaseDate
        qrCodeData
        passenger {
            id
            firstName
            lastName
            birthDate
            type
        }
        duration {
            id
            description
        }
        fqCode
        orderNumber
        referenceNumber {
            label
            value
        }
        ticketNumber {
            label
            value
        }
        articleNumber {
            label
            value
        }
        refundDate
        passengerType
        refundOrderNumber
        refundReferenceNumber
    }
    ${ItineraryArticleFragmentDoc}
`;
export const ItineraryFragmentFragmentDoc = gql`
    fragment ItineraryFragment on Itinerary {
        legs {
            additionalInformation
            line {
                ...Line
            }
            vehicle {
                transport
                code
                transportModeId
            }
            byFoot
            stops {
                scheduledArrivalTime
                scheduledDepartureTime
                arrivalTime
                cityName
                departureTime
                hasBookingRequirements
                id
                isArrivalPlace
                isDeparturePlace
                isOptional
                isCancelled
                messages {
                    ...Message
                }
                latLon
                name
                didok
                physicalStopAssociated {
                    ...PhysicalStop
                }
            }
            serviceNumber
            serviceId
            messages {
                ...Message
            }
            length
            duration
            isBookable
            geoJson
            bookingDeadline
            isFirstClassAuthorized
            transportCompanyId
            wkt
        }
        arrivalDate
        arrivalTime
        departureDate
        departureTime
        scheduledArrivalDate
        scheduledArrivalTime
        scheduledDepartureDate
        scheduledDepartureTime
        isBookable
        isCancelled
        hasBookingRequirements
        remainingTimeBeforeBooking
        bookingDeadline
        hasStopRequest
        id
    }
    ${LineFragmentDoc}
    ${MessageFragmentDoc}
    ${PhysicalStopFragmentDoc}
`;
export const QuickArticleFragmentDoc = gql`
    fragment QuickArticle on QuickArticle {
        category {
            id
            description
        }
        smsCode
        description
        reduction {
            id
            description
        }
        title
        travelClass {
            id
            description
        }
        validity {
            from
            to
            label
        }
        zones {
            id
        }
        travelType
        locationsValidity {
            label
            values
        }
        locationsChoice {
            label
            values
        }
        vatLabel
        tarifOwner
        paymentMeans
        thirdPartyPayerInformation {
            isEligible
            thirdPartyName
        }
    }
`;
export const QuickTicketFragmentDoc = gql`
    fragment QuickTicket on Ticket {
        id
        article {
            ...QuickArticle
        }
        departureStopName
        price {
            amountInCents
            currency
        }
        purchaseDate
        qrCodeData
        passenger {
            id
            firstName
            lastName
            birthDate
            type
        }
        duration {
            id
            description
        }
        fqCode
        orderNumber
        referenceNumber {
            label
            value
        }
        ticketNumber {
            label
            value
        }
        articleNumber {
            label
            value
        }
        refundDate
        passengerType
        refundOrderNumber
        refundReferenceNumber
    }
    ${QuickArticleFragmentDoc}
`;
export const GenerateSupersaverItineraryArticlesDocument = gql`
    mutation generateSupersaverItineraryArticles($generateItineraryArticlesInput: GenerateItineraryArticlesInput!) {
        articlePublic {
            generateItineraryArticles(generateItineraryArticlesInput: $generateItineraryArticlesInput) {
                isSupersaver
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GenerateSupersaverItineraryArticlesGQL extends Apollo.Mutation<
    GenerateSupersaverItineraryArticlesMutation,
    GenerateSupersaverItineraryArticlesMutationVariables
> {
    document = GenerateSupersaverItineraryArticlesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GenerateDepartureArticlesDocument = gql`
    mutation generateDepartureArticles($generateDepartureArticlesInput: GenerateDepartureArticlesInput!) {
        article {
            generateDepartureArticles(generateDepartureArticlesInput: $generateDepartureArticlesInput) {
                validityDurationLabel
                articleId
                category {
                    id
                    description
                }
                prices {
                    amountInCents
                    currency
                }
                smsCode
                description
                title
                locationsValidity {
                    label
                    values
                }
                locationsChoice {
                    label
                    values
                }
                vatLabel
                paymentMeans
                travelClass {
                    description
                    id
                }
                reduction {
                    description
                    id
                }
                travelType
                type
                zones {
                    id
                    description
                }
                thirdPartyPayerInformation {
                    isEligible
                    fare
                    thirdPartyName
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GenerateDepartureArticlesGQL extends Apollo.Mutation<
    GenerateDepartureArticlesMutation,
    GenerateDepartureArticlesMutationVariables
> {
    document = GenerateDepartureArticlesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GenerateItineraryArticlesDocument = gql`
    mutation generateItineraryArticles($generateItineraryArticlesInput: GenerateItineraryArticlesInput!) {
        article {
            generateItineraryArticles(generateItineraryArticlesInput: $generateItineraryArticlesInput) {
                validityDurationLabel
                description
                categoryDescription
                reduction {
                    id
                    description
                }
                title
                travelClass {
                    id
                    description
                }
                offerNumber
                travelType
                isSupersaver
                prices {
                    amountInCents
                    currency
                }
                novaId
                paymentMeans
                unavailabilityReason {
                    label
                    value
                }
                supplementQualifiers
                routeQualifiers
                roundTrip
                type
                zones {
                    id
                    description
                }
                thirdPartyPayerInformation {
                    isEligible
                    fare
                    thirdPartyName
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GenerateItineraryArticlesGQL extends Apollo.Mutation<
    GenerateItineraryArticlesMutation,
    GenerateItineraryArticlesMutationVariables
> {
    document = GenerateItineraryArticlesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const QuickArticlesDocument = gql`
    mutation QuickArticles($generateQuickArticlesInput: GenerateQuickArticlesInput!) {
        article {
            generateQuickArticles(generateQuickArticlesInput: $generateQuickArticlesInput) {
                articleId
                category {
                    id
                    description
                }
                prices {
                    amountInCents
                    currency
                }
                travelClass {
                    id
                    description
                }
                description
                categoryDescription
                smsCode
                title
                locationsValidity {
                    label
                    values
                }
                locationsChoice {
                    label
                    values
                }
                vatLabel
                paymentMeans
                tripClass
                reduction {
                    description
                    id
                }
                travelType
                type
                validityDurationLabel
                zones {
                    id
                    description
                }
                tarifOwner
                thirdPartyPayerInformation {
                    isEligible
                    fare
                    thirdPartyName
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class QuickArticlesGQL extends Apollo.Mutation<QuickArticlesMutation, QuickArticlesMutationVariables> {
    document = QuickArticlesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateFrequentTravelerDocument = gql`
    mutation CreateFrequentTraveler($frequentTraveler: CreateCustomerFrequentTravelerInput!) {
        customer {
            createFrequentTraveler(frequentTraveler: $frequentTraveler) {
                success
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateFrequentTravelerGQL extends Apollo.Mutation<CreateFrequentTravelerMutation, CreateFrequentTravelerMutationVariables> {
    document = CreateFrequentTravelerDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RemoveCreditCardDocument = gql`
    mutation RemoveCreditCard($creditCardId: String!, $currency: Currency!) {
        customer {
            removeCreditCard(creditCardId: $creditCardId, currency: $currency) {
                success
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class RemoveCreditCardGQL extends Apollo.Mutation<RemoveCreditCardMutation, RemoveCreditCardMutationVariables> {
    document = RemoveCreditCardDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RemoveFrequentTravelerDocument = gql`
    mutation RemoveFrequentTraveler($frequentTravelerId: String!) {
        customer {
            removeFrequentTraveler(frequentTravelerId: $frequentTravelerId) {
                success
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class RemoveFrequentTravelerGQL extends Apollo.Mutation<RemoveFrequentTravelerMutation, RemoveFrequentTravelerMutationVariables> {
    document = RemoveFrequentTravelerDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateFrequentTravelerDocument = gql`
    mutation UpdateFrequentTraveler($frequentTraveler: CustomerFrequentTravelerInput!) {
        customer {
            updateFrequentTraveler(frequentTraveler: $frequentTraveler) {
                success
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateFrequentTravelerGQL extends Apollo.Mutation<UpdateFrequentTravelerMutation, UpdateFrequentTravelerMutationVariables> {
    document = UpdateFrequentTravelerDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RequestDepartureStopDocument = gql`
    mutation RequestDepartureStop($departure: RequestDepartureStopInput!) {
        order {
            requestDepartureStop(departure: $departure) {
                id
                departure {
                    ...Departure
                }
                customerId
            }
        }
    }
    ${DepartureFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class RequestDepartureStopGQL extends Apollo.Mutation<RequestDepartureStopMutation, RequestDepartureStopMutationVariables> {
    document = RequestDepartureStopDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RequestItineraryStopDocument = gql`
    mutation RequestItineraryStop($legs: [RequestItineraryLegInput!]!) {
        order {
            requestItineraryStop(legs: $legs) {
                id
                itinerary {
                    ...ItineraryFragment
                }
                customerId
            }
        }
    }
    ${ItineraryFragmentFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class RequestItineraryStopGQL extends Apollo.Mutation<RequestItineraryStopMutation, RequestItineraryStopMutationVariables> {
    document = RequestItineraryStopDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SetTicketsToSeenDocument = gql`
    mutation SetTicketsToSeen($orders: [OrderInput!]!) {
        orderPublic {
            setTicketsToSeen(orders: $orders) {
                success
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SetTicketsToSeenGQL extends Apollo.Mutation<SetTicketsToSeenMutation, SetTicketsToSeenMutationVariables> {
    document = SetTicketsToSeenDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const TryCancelOrderDocument = gql`
    mutation TryCancelOrder($orderId: String!, $cancellationType: CancellationType!) {
        orderPublic {
            tryCancelOrder(orderId: $orderId, cancellationType: $cancellationType) {
                success
                orderStatus
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class TryCancelOrderGQL extends Apollo.Mutation<TryCancelOrderMutation, TryCancelOrderMutationVariables> {
    document = TryCancelOrderDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateNationalMarketingConsentDocument = gql`
    mutation UpdateNationalMarketingConsent($nationalMarketingConsent: Boolean!) {
        preferencesMutation {
            updateNationalMarketingConsent(nationalMarketingConsent: $nationalMarketingConsent) {
                error
                success
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateNationalMarketingConsentGQL extends Apollo.Mutation<
    UpdateNationalMarketingConsentMutation,
    UpdateNationalMarketingConsentMutationVariables
> {
    document = UpdateNationalMarketingConsentDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const BuyDocument = gql`
    mutation Buy($basket: QuickArticlesBasketInput!) {
        purchasePublic {
            buyQuickTicketArticles(basket: $basket) {
                orderId
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class BuyGQL extends Apollo.Mutation<BuyMutation, BuyMutationVariables> {
    document = BuyDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const BuyDepartureTicketArticlesDocument = gql`
    mutation BuyDepartureTicketArticles($basket: DepartureArticleBasketInput!) {
        purchase {
            buyDepartureTicketArticles(basket: $basket) {
                orderId
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class BuyDepartureTicketArticlesGQL extends Apollo.Mutation<
    BuyDepartureTicketArticlesMutation,
    BuyDepartureTicketArticlesMutationVariables
> {
    document = BuyDepartureTicketArticlesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const BuyItineraryTicketArticlesDocument = gql`
    mutation BuyItineraryTicketArticles($basket: ItineraryArticleBasketInput!) {
        purchasePublic {
            buyItineraryTicketArticles(basket: $basket) {
                orderId
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class BuyItineraryTicketArticlesGQL extends Apollo.Mutation<
    BuyItineraryTicketArticlesMutation,
    BuyItineraryTicketArticlesMutationVariables
> {
    document = BuyItineraryTicketArticlesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PayDocument = gql`
    mutation Pay($language: LanguageInput!, $orderId: String!, $paymentMethod: PaymentMethodInput!, $successRedirectUrl: String!) {
        purchasePublic {
            pay(language: $language, orderId: $orderId, paymentMethod: $paymentMethod, successRedirectUrl: $successRedirectUrl) {
                done
                externalPaymentUrl
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class PayGQL extends Apollo.Mutation<PayMutation, PayMutationVariables> {
    document = PayDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RefundDepartureTicketDocument = gql`
    mutation RefundDepartureTicket($order: OrderInput!, $language: LanguageInput!) {
        purchasePublic {
            refundDepartureTicketOrder(order: $order, language: $language) {
                error
                success
                order {
                    id
                    ticketsTransaction {
                        id
                        tickets {
                            ...DepartureTicket
                        }
                        paymentState
                        ticketState
                        saferpayToken
                    }
                    departure {
                        ...Departure
                    }
                    customerId
                    orderType
                }
            }
        }
    }
    ${DepartureTicketFragmentDoc}
    ${DepartureFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class RefundDepartureTicketGQL extends Apollo.Mutation<RefundDepartureTicketMutation, RefundDepartureTicketMutationVariables> {
    document = RefundDepartureTicketDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RefundItineraryTicketDocument = gql`
    mutation RefundItineraryTicket($order: OrderInput!, $language: LanguageInput!) {
        purchasePublic {
            refundItineraryTicketOrder(order: $order, language: $language) {
                error
                success
                order {
                    id
                    ticketsTransaction {
                        id
                        tickets {
                            ...ItineraryTicket
                        }
                        paymentState
                        ticketState
                        saferpayToken
                    }
                    itinerary {
                        ...ItineraryFragment
                    }
                    customerId
                    orderType
                }
            }
        }
    }
    ${ItineraryTicketFragmentDoc}
    ${ItineraryFragmentFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class RefundItineraryTicketGQL extends Apollo.Mutation<RefundItineraryTicketMutation, RefundItineraryTicketMutationVariables> {
    document = RefundItineraryTicketDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RefundQuickTicketDocument = gql`
    mutation RefundQuickTicket($order: OrderInput!, $language: LanguageInput!) {
        purchasePublic {
            refundQuickTicketOrder(order: $order, language: $language) {
                error
                success
                order {
                    id
                    ticketsTransaction {
                        id
                        tickets {
                            ...QuickTicket
                        }
                        paymentState
                        ticketState
                        saferpayToken
                    }
                    zones {
                        id
                    }
                    customerId
                    orderType
                }
            }
        }
    }
    ${QuickTicketFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class RefundQuickTicketGQL extends Apollo.Mutation<RefundQuickTicketMutation, RefundQuickTicketMutationVariables> {
    document = RefundQuickTicketDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SaveItineraryDocument = gql`
    mutation SaveItinerary($itineraryBasket: ItineraryBasketInput!) {
        purchase {
            saveItinerary(itineraryBasket: $itineraryBasket) {
                id
                ticketsTransaction {
                    id
                }
                itinerary {
                    ...ItineraryFragment
                }
                customerId
                orderType
            }
        }
    }
    ${ItineraryFragmentFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class SaveItineraryGQL extends Apollo.Mutation<SaveItineraryMutation, SaveItineraryMutationVariables> {
    document = SaveItineraryDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetPaymentMethodsDocument = gql`
    query GetPaymentMethods($currency: Currency!, $clientPlatform: ClientPlatform!) {
        configuration {
            paymentMethods(currency: $currency, clientPlatform: $clientPlatform) {
                cards
                mobiles
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetPaymentMethodsGQL extends Apollo.Query<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables> {
    document = GetPaymentMethodsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetRefreshIntervalsDocument = gql`
    query GetRefreshIntervals {
        configuration {
            refreshIntervals {
                departureRefreshIntervalInSeconds
                itineraryRefreshIntervalInSeconds
                autoScrollRefreshIntervalInSeconds
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetRefreshIntervalsGQL extends Apollo.Query<GetRefreshIntervalsQuery, GetRefreshIntervalsQueryVariables> {
    document = GetRefreshIntervalsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetThresholdsDocument = gql`
    query GetThresholds {
        configuration {
            thresholds {
                minimumThresholdToShowIconInSeconds
                minutesThresholdToDisplayWaitingMinutes
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetThresholdsGQL extends Apollo.Query<GetThresholdsQuery, GetThresholdsQueryVariables> {
    document = GetThresholdsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetCreditCardsDocument = gql`
    query GetCreditCards($currency: Currency!, $onlyValid: Boolean!) {
        customer {
            creditCards(currency: $currency, onlyValid: $onlyValid) {
                id
                brand
                maskedNumber
                holderName
                expirationYear
                expirationMonth
                countryCode
                primary
                gatewayReference {
                    reference
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetCreditCardsGQL extends Apollo.Query<GetCreditCardsQuery, GetCreditCardsQueryVariables> {
    document = GetCreditCardsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetFrequentTravelerTitlesDocument = gql`
    query GetFrequentTravelerTitles($language: String!) {
        references {
            frequentTravelerTitles(language: $language) {
                label
                value
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetFrequentTravelerTitlesGQL extends Apollo.Query<GetFrequentTravelerTitlesQuery, GetFrequentTravelerTitlesQueryVariables> {
    document = GetFrequentTravelerTitlesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetFrequentTravelersDocument = gql`
    query GetFrequentTravelers {
        customer {
            frequentTravelers {
                type
                firstName
                lastName
                birthDate
                hasHalfFare
                phone
                email
                id
                title
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetFrequentTravelersGQL extends Apollo.Query<GetFrequentTravelersQuery, GetFrequentTravelersQueryVariables> {
    document = GetFrequentTravelersDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DirectionsFromStopDocument = gql`
    query DirectionsFromStop($directionsFromStopInput: DirectionsFromStopInput!) {
        departure {
            getDirectionsFromStop(directionsFromStopInput: $directionsFromStopInput) {
                lineId
                lineStyleId
                lineName
                name
                wayback
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DirectionsFromStopGQL extends Apollo.Query<DirectionsFromStopQuery, DirectionsFromStopQueryVariables> {
    document = DirectionsFromStopDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SearchDeparturesDocument = gql`
    query SearchDepartures(
        $using: DepartureSearchCriteria!
        $timing: DepartureTimingInput!
        $count: Int!
        $filter: DepatureFilterInput
        $lang: String!
    ) {
        departure {
            search(using: $using, timing: $timing, count: $count, filter: $filter, lang: $lang) {
                ...Departure
            }
        }
    }
    ${DepartureFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class SearchDeparturesGQL extends Apollo.Query<SearchDeparturesQuery, SearchDeparturesQueryVariables> {
    document = SearchDeparturesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetItinerariesDocument = gql`
    query GetItineraries(
        $departure: CoordinatesOrStopIdsInput!
        $arrival: CoordinatesOrStopIdsInput!
        $dateTime: Date!
        $searchDirection: SearchDirection!
        $count: Int!
        $withDirectItineraries: Boolean!
        $transportModes: [String!]!
        $lang: String!
    ) {
        itineraries(
            departure: $departure
            arrival: $arrival
            dateTime: $dateTime
            searchDirection: $searchDirection
            count: $count
            withDirectItineraries: $withDirectItineraries
            transportModes: $transportModes
            lang: $lang
        ) {
            ...ItineraryFragment
        }
    }
    ${ItineraryFragmentFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetItinerariesGQL extends Apollo.Query<GetItinerariesQuery, GetItinerariesQueryVariables> {
    document = GetItinerariesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetTransportModesDocument = gql`
    query GetTransportModes {
        transportModes {
            id
            name
            compatibleTransportModes
            singular
            plural
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetTransportModesGQL extends Apollo.Query<GetTransportModesQuery, GetTransportModesQueryVariables> {
    document = GetTransportModesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetLinesStylesDocument = gql`
    query GetLinesStyles {
        linesStyles {
            id
            name
            longName
            image
            style
            color
            textColor
            lineOfficialCode
            networkId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetLinesStylesGQL extends Apollo.Query<GetLinesStylesQuery, GetLinesStylesQueryVariables> {
    document = GetLinesStylesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CancelStopRequestDocument = gql`
    mutation CancelStopRequest($orderId: ID!) {
        order {
            cancelStopRequest(orderId: $orderId) {
                success
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CancelStopRequestGQL extends Apollo.Mutation<CancelStopRequestMutation, CancelStopRequestMutationVariables> {
    document = CancelStopRequestDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetAllOrdersOfCurrentUserDocument = gql`
    query GetAllOrdersOfCurrentUser {
        order {
            getAllOrders(cardId: null, afterBookingDate: null) {
                departureOrders {
                    id
                    ticketsTransaction {
                        id
                        tickets {
                            ...DepartureTicket
                        }
                        paymentState
                        ticketState
                        saferpayToken
                    }
                    departure {
                        ...Departure
                    }
                    customerId
                    orderType
                }
                departureStopRequests {
                    id
                    departure {
                        ...Departure
                    }
                    customerId
                }
                itineraryOrders {
                    id
                    ticketsTransaction {
                        id
                        tickets {
                            ...ItineraryTicket
                        }
                        paymentState
                        ticketState
                        saferpayToken
                    }
                    itinerary {
                        ...ItineraryFragment
                    }
                    customerId
                    orderType
                }
                itineraryStopRequests {
                    id
                    itinerary {
                        ...ItineraryFragment
                    }
                    customerId
                }
                quickTicketOrders {
                    id
                    ticketsTransaction {
                        id
                        tickets {
                            ...QuickTicket
                        }
                        paymentState
                        ticketState
                        saferpayToken
                    }
                    zones {
                        id
                    }
                    customerId
                    orderType
                }
                processingOrders {
                    passenger {
                        id
                        firstName
                        lastName
                        birthDate
                    }
                    id
                    purchaseDate
                    orderType
                }
            }
        }
    }
    ${DepartureTicketFragmentDoc}
    ${DepartureFragmentDoc}
    ${ItineraryTicketFragmentDoc}
    ${ItineraryFragmentFragmentDoc}
    ${QuickTicketFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetAllOrdersOfCurrentUserGQL extends Apollo.Query<GetAllOrdersOfCurrentUserQuery, GetAllOrdersOfCurrentUserQueryVariables> {
    document = GetAllOrdersOfCurrentUserDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetConfirmedDepartureOrderDocument = gql`
    query GetConfirmedDepartureOrder {
        order {
            getConfirmedDepartureOrders {
                id
                ticketsTransaction {
                    id
                    tickets {
                        ...DepartureTicket
                    }
                    paymentState
                    ticketState
                    saferpayToken
                }
                departure {
                    ...Departure
                }
                customerId
                orderType
            }
        }
    }
    ${DepartureTicketFragmentDoc}
    ${DepartureFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetConfirmedDepartureOrderGQL extends Apollo.Query<GetConfirmedDepartureOrderQuery, GetConfirmedDepartureOrderQueryVariables> {
    document = GetConfirmedDepartureOrderDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetConfirmedItineraryOrderDocument = gql`
    query GetConfirmedItineraryOrder {
        order {
            getConfirmedItineraryOrders {
                id
                ticketsTransaction {
                    id
                    tickets {
                        ...ItineraryTicket
                    }
                    paymentState
                    ticketState
                    saferpayToken
                }
                itinerary {
                    ...ItineraryFragment
                }
                customerId
                orderType
            }
        }
    }
    ${ItineraryTicketFragmentDoc}
    ${ItineraryFragmentFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetConfirmedItineraryOrderGQL extends Apollo.Query<GetConfirmedItineraryOrderQuery, GetConfirmedItineraryOrderQueryVariables> {
    document = GetConfirmedItineraryOrderDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetConfirmedQuickTicketsOrdersDocument = gql`
    query GetConfirmedQuickTicketsOrders {
        order {
            getConfirmedQuickTicketsOrders {
                id
                ticketsTransaction {
                    id
                    tickets {
                        ...QuickTicket
                    }
                    paymentState
                    ticketState
                    saferpayToken
                }
                zones {
                    id
                }
                customerId
                orderType
            }
        }
    }
    ${QuickTicketFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetConfirmedQuickTicketsOrdersGQL extends Apollo.Query<
    GetConfirmedQuickTicketsOrdersQuery,
    GetConfirmedQuickTicketsOrdersQueryVariables
> {
    document = GetConfirmedQuickTicketsOrdersDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetDepartureOrderDocument = gql`
    query GetDepartureOrder($order: OrderInput!) {
        order {
            getDepartureOrder(order: $order) {
                id
                ticketsTransaction {
                    id
                    tickets {
                        ...DepartureTicket
                    }
                    paymentState
                    ticketState
                    saferpayToken
                }
                departure {
                    ...Departure
                }
                customerId
                orderType
            }
        }
    }
    ${DepartureTicketFragmentDoc}
    ${DepartureFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetDepartureOrderGQL extends Apollo.Query<GetDepartureOrderQuery, GetDepartureOrderQueryVariables> {
    document = GetDepartureOrderDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetDepartureStopRequestDocument = gql`
    query GetDepartureStopRequest($id: String!) {
        order {
            getDepartureStopRequest(id: $id) {
                id
                departure {
                    ...Departure
                }
                customerId
            }
        }
    }
    ${DepartureFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetDepartureStopRequestGQL extends Apollo.Query<GetDepartureStopRequestQuery, GetDepartureStopRequestQueryVariables> {
    document = GetDepartureStopRequestDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetDepartureStopRequestsDocument = gql`
    query GetDepartureStopRequests {
        order {
            getDepartureStopRequests {
                id
                departure {
                    ...Departure
                }
                customerId
            }
        }
    }
    ${DepartureFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetDepartureStopRequestsGQL extends Apollo.Query<GetDepartureStopRequestsQuery, GetDepartureStopRequestsQueryVariables> {
    document = GetDepartureStopRequestsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ItineraryOrderDocument = gql`
    query ItineraryOrder($order: OrderInput!) {
        orderPublic {
            getItineraryOrder(order: $order) {
                id
                ticketsTransaction {
                    id
                    tickets {
                        ...ItineraryTicket
                    }
                    paymentState
                    ticketState
                    saferpayToken
                }
                itinerary {
                    ...ItineraryFragment
                }
                customerId
                orderType
            }
        }
    }
    ${ItineraryTicketFragmentDoc}
    ${ItineraryFragmentFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class ItineraryOrderGQL extends Apollo.Query<ItineraryOrderQuery, ItineraryOrderQueryVariables> {
    document = ItineraryOrderDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetItineraryStopRequestsDocument = gql`
    query GetItineraryStopRequests {
        order {
            getItineraryStopRequests {
                id
                itinerary {
                    ...ItineraryFragment
                }
                customerId
            }
        }
    }
    ${ItineraryFragmentFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetItineraryStopRequestsGQL extends Apollo.Query<GetItineraryStopRequestsQuery, GetItineraryStopRequestsQueryVariables> {
    document = GetItineraryStopRequestsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetOrderStatusDocument = gql`
    query GetOrderStatus($orderId: String!) {
        orderPublic {
            getOrderStatus(orderId: $orderId)
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetOrderStatusGQL extends Apollo.Query<GetOrderStatusQuery, GetOrderStatusQueryVariables> {
    document = GetOrderStatusDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const QuickTicketOrderDocument = gql`
    query QuickTicketOrder($order: OrderInput!) {
        orderPublic {
            getQuickTicketsOrder(order: $order) {
                id
                ticketsTransaction {
                    id
                    tickets {
                        ...QuickTicket
                    }
                    paymentState
                    ticketState
                    saferpayToken
                }
                zones {
                    id
                }
                customerId
                orderType
            }
        }
    }
    ${QuickTicketFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class QuickTicketOrderGQL extends Apollo.Query<QuickTicketOrderQuery, QuickTicketOrderQueryVariables> {
    document = QuickTicketOrderDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetByBoundingBoxDocument = gql`
    query GetByBoundingBox($boundingBox: BoundingBoxInput!) {
        physicalStops {
            byBoundingBox(boundingBox: $boundingBox) {
                ...PhysicalStop
            }
        }
    }
    ${PhysicalStopFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetByBoundingBoxGQL extends Apollo.Query<GetByBoundingBoxQuery, GetByBoundingBoxQueryVariables> {
    document = GetByBoundingBoxDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetByCoordinatesDocument = gql`
    query GetByCoordinates($coordinates: CoordinatesInput!) {
        physicalStops {
            byCoordinates(coordinates: $coordinates) {
                ...PhysicalStop
            }
        }
    }
    ${PhysicalStopFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetByCoordinatesGQL extends Apollo.Query<GetByCoordinatesQuery, GetByCoordinatesQueryVariables> {
    document = GetByCoordinatesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetPlacesDocument = gql`
    query getPlaces($searchQueryInput: SearchQueryInput!) {
        places {
            search(searchQueryInput: $searchQueryInput) {
                cities {
                    id
                    scoring
                    name
                    mainStops {
                        id
                        cityName
                        name
                        latLon
                        scoring
                    }
                }
                poi {
                    id
                    cityName
                    name
                    latLon
                    scoring
                }
                address {
                    address
                    city
                    latLon
                    latitude
                    longitude
                    scoring
                    zip
                }
                stops {
                    id
                    cityName
                    name
                    latLon
                    scoring
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetPlacesGQL extends Apollo.Query<GetPlacesQuery, GetPlacesQueryVariables> {
    document = GetPlacesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetMarketingConsentsDocument = gql`
    query GetMarketingConsents {
        preferences {
            marketingConsents {
                national
                company
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetMarketingConsentsGQL extends Apollo.Query<GetMarketingConsentsQuery, GetMarketingConsentsQueryVariables> {
    document = GetMarketingConsentsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PreflightDocument = gql`
    query Preflight {
        preflight {
            isClientOutdated
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class PreflightGQL extends Apollo.Query<PreflightQuery, PreflightQueryVariables> {
    document = PreflightDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetRouteDocument = gql`
    query GetRoute($routeInput: RouteInput!) {
        route(routeInput: $routeInput) {
            id
            line {
                ...Line
            }
            stops {
                arrivalDate
                arrivalRealTime
                arrivalRealTimeDate
                arrivalTime
                cityName
                departureDate
                departureRealTime
                departureRealTimeDate
                departureTime
                hasBookingRequirements
                id
                isCancelled
                isOptional
                messages {
                    ...Message
                }
                rank
                stopAreaName
                stopPointName
                tadId
                letter
                coordinates {
                    latitude
                    longitude
                }
            }
        }
    }
    ${LineFragmentDoc}
    ${MessageFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetRouteGQL extends Apollo.Query<GetRouteQuery, GetRouteQueryVariables> {
    document = GetRouteDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetSmsTicketsDocument = gql`
    query GetSmsTickets {
        smsTickets {
            label {
                languageCode
                label
            }
            order
            subCategories {
                label {
                    languageCode
                    label
                }
                order
                tickets {
                    label {
                        languageCode
                        label
                    }
                    price {
                        amountInCents
                        currency
                    }
                    code
                    order
                }
            }
            tickets {
                label {
                    languageCode
                    label
                }
                price {
                    amountInCents
                    currency
                }
                code
                order
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GetSmsTicketsGQL extends Apollo.Query<GetSmsTicketsQuery, GetSmsTicketsQueryVariables> {
    document = GetSmsTicketsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StartupNotificationDocument = gql`
    query StartupNotification {
        startupNotifications {
            id
            htmlContent
            title
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class StartupNotificationGQL extends Apollo.Query<StartupNotificationQuery, StartupNotificationQueryVariables> {
    document = StartupNotificationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateDocument = gql`
    query Update {
        update {
            messageHtml
            forceUpdate
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpdateGQL extends Apollo.Query<UpdateQuery, UpdateQueryVariables> {
    document = UpdateDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LoginControllerDocument = gql`
    mutation LoginController($username: String!, $password: String!) {
        auth {
            basicAuth {
                authenticateController(username: $username, password: $password) {
                    accessToken
                    errors
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class LoginControllerGQL extends Apollo.Mutation<LoginControllerMutation, LoginControllerMutationVariables> {
    document = LoginControllerDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const BasicAuthLoginDocument = gql`
    mutation BasicAuthLogin($username: String!, $password: String!) {
        auth {
            basicAuth {
                authenticate(username: $username, password: $password) {
                    accessToken
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class BasicAuthLoginGQL extends Apollo.Mutation<BasicAuthLoginMutation, BasicAuthLoginMutationVariables> {
    document = BasicAuthLoginDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GenerateQuickArticlesByZonesDocument = gql`
    mutation generateQuickArticlesByZones($generateQuickArticlesByZonesInput: GenerateQuickArticlesByZoneInput!) {
        article {
            generateQuickArticlesByZone(generateQuickArticlesByZoneInput: $generateQuickArticlesByZonesInput) {
                articleId
                category {
                    id
                    description
                }
                prices {
                    amountInCents
                    currency
                }
                travelClass {
                    id
                    description
                }
                description
                categoryDescription
                smsCode
                title
                locationsValidity {
                    label
                    values
                }
                locationsChoice {
                    label
                    values
                }
                vatLabel
                paymentMeans
                tripClass
                reduction {
                    description
                    id
                }
                travelType
                type
                validityDurationLabel
                zones {
                    id
                    description
                }
                tarifOwner
                thirdPartyPayerInformation {
                    isEligible
                    fare
                    thirdPartyName
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class GenerateQuickArticlesByZonesGQL extends Apollo.Mutation<
    GenerateQuickArticlesByZonesMutation,
    GenerateQuickArticlesByZonesMutationVariables
> {
    document = GenerateQuickArticlesByZonesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const OnAuthenticationDocument = gql`
    mutation OnAuthentication {
        onAuthentication
    }
`;

@Injectable({
    providedIn: 'root',
})
export class OnAuthenticationGQL extends Apollo.Mutation<OnAuthenticationMutation, OnAuthenticationMutationVariables> {
    document = OnAuthenticationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetAllOrdersByCardDocument = gql`
    query GetAllOrdersByCard($cardId: String!, $afterBookingDate: String!) {
        order {
            getAllOrders(cardId: $cardId, afterBookingDate: $afterBookingDate) {
                departureOrders {
                    id
                    ticketsTransaction {
                        id
                        tickets {
                            ...DepartureTicket
                        }
                        paymentState
                        ticketState
                        saferpayToken
                    }
                    departure {
                        ...Departure
                    }
                    customerId
                    orderType
                }
                departureStopRequests {
                    id
                    departure {
                        ...Departure
                    }
                    customerId
                }
                itineraryOrders {
                    id
                    ticketsTransaction {
                        id
                        tickets {
                            ...ItineraryTicket
                        }
                        paymentState
                        ticketState
                        saferpayToken
                    }
                    itinerary {
                        ...ItineraryFragment
                    }
                    customerId
                    orderType
                }
                itineraryStopRequests {
                    id
                    itinerary {
                        ...ItineraryFragment
                    }
                    customerId
                }
                quickTicketOrders {
                    id
                    ticketsTransaction {
                        id
                        tickets {
                            ...QuickTicket
                        }
                        paymentState
                        ticketState
                        saferpayToken
                    }
                    zones {
                        id
                    }
                    customerId
                    orderType
                }
                processingOrders {
                    passenger {
                        id
                        firstName
                        lastName
                        birthDate
                    }
                    id
                    purchaseDate
                    orderType
                }
            }
        }
    }
    ${DepartureTicketFragmentDoc}
    ${DepartureFragmentDoc}
    ${ItineraryTicketFragmentDoc}
    ${ItineraryFragmentFragmentDoc}
    ${QuickTicketFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetAllOrdersByCardGQL extends Apollo.Query<GetAllOrdersByCardQuery, GetAllOrdersByCardQueryVariables> {
    document = GetAllOrdersByCardDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const GetCustomerProfileByCardIdDocument = gql`
    query GetCustomerProfileByCardId($cardId: String!) {
        customer {
            profileByCardId(cardId: $cardId) {
                ...Profile
            }
        }
    }
    ${ProfileFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class GetCustomerProfileByCardIdGQL extends Apollo.Query<GetCustomerProfileByCardIdQuery, GetCustomerProfileByCardIdQueryVariables> {
    document = GetCustomerProfileByCardIdDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const TicketListDocument = gql`
    query ticketList {
        getQuickArticles {
            title
            articleId
            articleNumber {
                value
            }
            zones {
                id
            }
            tarifOwner
            travelType
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class TicketListGQL extends Apollo.Query<TicketListQuery, TicketListQueryVariables> {
    document = TicketListDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
