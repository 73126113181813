<ng-container *ngIf="leg.isByFoot(); else byTransport">
    <app-itinerary-leg-walk [leg]="leg" [isFirstLeg]="isFirstLeg" [isLastLeg]="isLastLeg"></app-itinerary-leg-walk>
</ng-container>
<ng-template #byTransport>
    <traas-additional-information [additionalInformation]="leg.getAdditionalInformation()"></traas-additional-information>

    <app-itinerary-leg-stop-list
        [hidden]="!showLegDetails"
        [legAdapter]="leg"
        tappable
        (transportClicked)="toggleShowLegDetails()"
        (departureClicked)="onDepartureClicked($event)"
        (arrivalClicked)="onArrivalClicked($event)"
    ></app-itinerary-leg-stop-list>
    <app-itinerary-leg-transport
        [hidden]="showLegDetails"
        [class.is-last-leg]="isLastLeg"
        [leg]="leg"
        tappable
        (transportClicked)="toggleShowLegDetails()"
        (warningClicked)="onWarningClicked($event)"
        (departureClicked)="onDepartureClicked($event)"
        (arrivalClicked)="onArrivalClicked($event)"
    ></app-itinerary-leg-transport>
</ng-template>
