import { Injectable } from '@angular/core';
import { StartupNotificationGQL } from '@traas/boldor/graphql-generated/graphql';
import { GqlToFrontMessageConverter } from '../converters/gql-to-front/gql-to-front-message-converter';
import { MessageStorage } from '../models/message-storage';
import { MessageViewModel } from '../models/message-view-model';
import { MESSAGES_ACKNOWLEDGED_LIMIT } from '../../../business-rules.utils';
import { ObservableTypedStorage } from '@traas/common/utils';
import { firstValueFrom } from 'rxjs';
import { ErrorCodes, Result, TechnicalError } from '@traas/common/models';
import { convertToError, LoggingService } from '@traas/common/logging';

const MESSAGE_STORAGE_KEY: keyof MessageStorage = 'acknowledgeMessagesId';

@Injectable({
    providedIn: 'root',
})
export class StartupNotificationService {
    constructor(
        private messageStorage: ObservableTypedStorage<MessageStorage>,
        private startupNotification: StartupNotificationGQL,
        private logger: LoggingService,
    ) {}

    async acknowledgeMessage(messageId: string): Promise<void> {
        const stored = await this.messageStorage.getItem(MESSAGE_STORAGE_KEY, []);
        if (stored.length === MESSAGES_ACKNOWLEDGED_LIMIT) {
            stored.pop();
        }
        stored.push(messageId);
        await this.messageStorage.setItem(MESSAGE_STORAGE_KEY, stored);
    }

    async getPendingMessages(): Promise<Result<MessageViewModel[], TechnicalError>> {
        try {
            const messages = await this.fetchMessages();
            const idsOfAcknowledgeMessages = await this.getIdsOfAcknowledgeMessages();
            const result = messages.filter((message) => {
                const isAcknowledged = idsOfAcknowledgeMessages.some((id) => message.id === id);
                return !isAcknowledged;
            });
            return { success: true, value: result };
        } catch (error) {
            return {
                success: false,
                error: new TechnicalError(
                    'Error while fetching startup notifications',
                    ErrorCodes.StartupNotification.Fetch,
                    convertToError(error),
                ),
            };
        }
    }

    private async fetchMessages(): Promise<MessageViewModel[]> {
        const result = await firstValueFrom(this.startupNotification.fetch());
        return result.data.startupNotifications.map(GqlToFrontMessageConverter.toViewModel);
    }

    private getIdsOfAcknowledgeMessages(): Promise<string[]> {
        return this.messageStorage.getItem(MESSAGE_STORAGE_KEY, []);
    }
}
