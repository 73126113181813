import { Injectable } from '@angular/core';
import { GetMarketingConsentsGQL, UpdateNationalMarketingConsentGQL } from '@traas/boldor/graphql-generated/graphql';
import { firstValueFrom } from 'rxjs';
import { convertToError } from '@traas/common/logging';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

export interface MarketingConsents {
    company?: boolean | null;
    national?: boolean | null;
}

@Injectable({ providedIn: 'root' })
export class MarketingConsentApiService {
    constructor(
        private updateNationalMarketingConsentGQL: UpdateNationalMarketingConsentGQL,
        private getMarketingConsentsGQL: GetMarketingConsentsGQL,
    ) {}

    async getMarketingConsents(): Promise<MarketingConsents> {
        const response = await firstValueFrom(this.getMarketingConsentsGQL.fetch());
        const marketingConsents = response?.data?.preferences.marketingConsents;
        return {
            company: marketingConsents.company,
            national: marketingConsents.national,
        };
    }

    async updateNationalMarketingConsent(value: boolean): Promise<boolean> {
        try {
            const response = await firstValueFrom(this.updateNationalMarketingConsentGQL.mutate({ nationalMarketingConsent: value }));
            const operationResult = response?.data?.preferencesMutation;

            if (!operationResult) {
                return false;
            }
            return operationResult.updateNationalMarketingConsent.success;
        } catch (error) {
            new TechnicalError(
                'Error while updating national marketing consent',
                ErrorCodes.Preferences.UpdateNationalMarketingConsent,
                convertToError(error),
                { nationalMarketingConsent: value },
            );
            return false;
        }
    }
}
