import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-human-readable-date',
    templateUrl: './human-readable-date.component.html',
    styleUrls: ['./human-readable-date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HumanReadableDateComponent {
    @Input()
    set departureDatetime(value: string) {
        this.departureDatetimeISO = moment(value).toISOString();
    }
    get departureDatetime(): string {
        return this.departureDatetimeISO;
    }
    departureDatetimeISO: string;
}
