import { Departure, TicketDuration, Traveler } from '@traas/boldor/all-models';
import { GenerateDepartureArticlesInput } from '@traas/boldor/graphql/generated/tpc/graphql';

export class FrontToGqlDepartureConverter {
    static toGenerateDepartureArticlesInput(
        departure: Departure,
        traveler: Traveler,
        durationsFilter: TicketDuration[],
        langId: string,
    ): GenerateDepartureArticlesInput {
        return {
            departureStopDidok: departure.stop.physicalStop.associatedCommercialStop?.didok,
            passengerType: traveler.type,
            passenger: {
                id: traveler.id,
                title: traveler.title,
                hasHalfFare: traveler.hasHalfFare,
                lastName: traveler.lastName,
                firstName: traveler.firstName,
                birthDate: traveler.birthDate,
            },
            departureDateTime: departure.plannedDepartureTime,
            travelTypes: durationsFilter.map((duration) => duration.value),
            language: {
                id: langId,
            },
            isAuthenticatedCustomer: traveler.isCustomer,
        };
    }
}
