import { ErrorContext } from './error-context.model';
import { ApolloError } from '@apollo/client/core';
import { TechnicalError } from './technical-error';

export abstract class BaseError extends Error {
    readonly innerError?: Error;
    readonly context?: ErrorContext;

    protected constructor(message: string, name: string, innerError?: Error, context?: ErrorContext) {
        super(message);
        this.stack = this.stack?.replace('Error: ', `${name}: `);
        this.stack = innerError ? `${this.stack}\nCaused by:\n${innerError.stack}` : this.stack;
        this.name = name;
        this.innerError = innerError;
        this.context = context;
    }

    getInnerErrorStack(): string {
        return this.innerError?.stack ?? 'No inner stack.';
    }

    protected determineServerErrorCode(error?: Error): string | undefined {
        if (!error) {
            return undefined;
        }
        if (error instanceof TechnicalError) {
            return this.determineServerErrorCode(error.innerError);
        }
        if (error instanceof ApolloError) {
            return (error.graphQLErrors?.[0]?.extensions['businessErrorCode'] as string) ?? undefined;
        }
        return undefined;
    }
}
