import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BoldorLocalizationService } from '@traas/common/localization';
import { convertToError, getErrorMessageToDisplay, LoggingService } from '@traas/common/logging';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LoadFail } from '../../itinerary/store/itinerary.action';
import { GqlQuickArticle, QuickArticleService } from '../services/quick-article.service';
import { TicketListStorageService } from '../services/ticket-list-storage.service';
import { TicketStoreActions } from './index';
import { Load, LoadQuickTicketsByZone, LoadTicketList } from './ticket.action';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

@Injectable()
export class TicketEffect {
    $load = createEffect(() =>
        this.$actions.pipe(
            ofType<Load>(TicketStoreActions.TicketActionTypes.Load),
            switchMap(({ payload }) => {
                const nearest = payload && payload.nearestStop ? payload.nearestStop : null;
                return this.quickArticleService.$getQuickArticlesCategories(nearest).pipe(
                    map((quickArticles) => new TicketStoreActions.LoadSuccess({ quickArticles })),
                    catchError((error) => {
                        this.logger.logError(
                            new TechnicalError('Error while loading quick articles', ErrorCodes.QuickTicket.Fetch, error, {
                                isNearestStop: !!payload?.nearestStop,
                            }),
                        );
                        return from(this.boldorLocalizationService.get('error-message.unknown')).pipe(
                            map(
                                ([defaultRetryMessage]) =>
                                    new LoadFail({
                                        message: getErrorMessageToDisplay(defaultRetryMessage, error, defaultRetryMessage),
                                        showMessage: true,
                                    }),
                            ),
                        );
                    }),
                );
            }),
        ),
    );

    $loadTicketsByZone = createEffect(() =>
        this.$actions.pipe(
            ofType<LoadQuickTicketsByZone>(TicketStoreActions.TicketActionTypes.LoadQuickTicketsByZone),
            switchMap(({ payload }) => {
                const articleId = payload && payload.articleId ? payload.articleId : null;
                const zones = payload && payload.zones ? payload.zones : [];
                const traveler = payload && payload.traveler ? payload.traveler : null;
                return from(this.quickArticleService.generateQuickArticleAdapterByZones(+articleId, zones, traveler)).pipe(
                    map((quickTicketsByZone) => new TicketStoreActions.LoadQuickTicketsByZoneSuccess({ quickTicketsByZone })),
                    catchError((error) => {
                        this.logger.logError(
                            new TechnicalError('Error while loading quick tickets by zone', ErrorCodes.QuickTicket.Fetch, error, {
                                payload: {
                                    articleId,
                                    zones,
                                },
                            }),
                        );
                        return from(this.boldorLocalizationService.get('error-message.unknown')).pipe(
                            map(
                                ([defaultRetryMessage]) =>
                                    new LoadFail({
                                        message: getErrorMessageToDisplay(defaultRetryMessage, error, defaultRetryMessage),
                                        showMessage: true,
                                    }),
                            ),
                        );
                    }),
                );
            }),
        ),
    );

    $loadTicketList = createEffect(() =>
        this.$actions.pipe(
            ofType<LoadTicketList>(TicketStoreActions.TicketActionTypes.LoadTicketList),
            switchMap(() => this.quickArticleService.getTicketList()),
            tap((tickets) => this.updateStorageTicketList(tickets)),
            map((ticketList) => new TicketStoreActions.LoadTicketListSuccess({ ticketList })),
            catchError((error) => {
                this.logger.logError(new TechnicalError('Error while loading ticket list', ErrorCodes.QuickTicket.Fetch, error));
                return of(new TicketStoreActions.LoadTicketListError(error));
            }),
        ),
    );

    constructor(
        private logger: LoggingService,
        private $actions: Actions,
        private quickArticleService: QuickArticleService,
        private boldorLocalizationService: BoldorLocalizationService,
        private ticketListStorageService: TicketListStorageService,
    ) {}

    private async updateStorageTicketList(ticketList: GqlQuickArticle[]): Promise<void> {
        try {
            await this.ticketListStorageService.updateStorageWithTicketListFromMiddleware(ticketList);
        } catch (error) {
            throw new TechnicalError(
                'Error while updating ticket list storage from server',
                ErrorCodes.QuickTicket.Fetch,
                convertToError(error),
            );
        }
    }
}
