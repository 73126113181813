import { Injectable } from '@angular/core';
import { ChangePlaceEventSourceEnum, DynamicPlace, toBounds } from '@traas/boldor/all-models';
import { LayerBuilderService } from '../../../features/map/services/layer-builder.service';
import { MapService } from '../../../features/map/services/map.service';
import { AddressAdapter } from '../../../features/place/adapters/address';
import { StopAdapter } from '../../../features/place/adapters/stop';
import { BookmarkAdapter } from '../../../models/bookmark/bookmark-adapter.model';
import * as LayersKey from '@traas/common/utils';
import { DebugLayerName } from '@traas/common/utils';
import { Circle, LatLng, LatLngBounds, Layer, Marker } from 'leaflet';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { PhysicalStopService } from '../physical-stop/physical-stop.service';
import { AddressDynamicPlaceAdapter } from './models/address-dynamic-place.adapter';
import { CityDynamicPlaceAdapter } from './models/city-dynamic-place.adapter';
import { StopDynamicPlaceAdapter } from './models/stop-dynamic-place.adapter';
import { environment } from '@traas/boldor/environments';
import { ErrorCodes, isSuccess, Result, TechnicalError } from '@traas/common/models';
import { LoggingService } from '@traas/common/logging';

@Injectable()
export class DynamicPlaceService {
    constructor(
        private mapService: MapService,
        private physicalStopService: PhysicalStopService,
        private layerBuilder: LayerBuilderService,
        private logger: LoggingService,
    ) {}

    createLayerFrom(place: DynamicPlace, coordinates: GeolocationCoordinates): Layer {
        switch (place.getChangePlaceEvent()) {
            case ChangePlaceEventSourceEnum.StopSelection:
            case ChangePlaceEventSourceEnum.CitySelection:
            case ChangePlaceEventSourceEnum.AddressSelection:
            case ChangePlaceEventSourceEnum.PoiSelection:
                return this.createLayerFromStopDynamicPlace(coordinates, place);
            default:
                throw new Error(`Behavior unknown to the event ${ChangePlaceEventSourceEnum[place.getChangePlaceEvent()]}`);
        }
    }

    async createBoundsFrom(dynamicPlace: DynamicPlace): Promise<Result<LatLngBounds | null, TechnicalError>> {
        let bounds = null;
        switch (dynamicPlace.getChangePlaceEvent()) {
            case ChangePlaceEventSourceEnum.StopSelection:
                bounds = await this.fetchBoundsFromStopsAroundStopDynamicPlace(dynamicPlace as StopDynamicPlaceAdapter);
                break;
            case ChangePlaceEventSourceEnum.AddressSelection:
            case ChangePlaceEventSourceEnum.PoiSelection:
                bounds = await this.fetchBoundsFromStopsAroundAddressDynamicPlace(dynamicPlace as AddressDynamicPlaceAdapter);
                break;
            case ChangePlaceEventSourceEnum.MyGpsPositionSelection: {
                const gpsBoundsResult = await this.createBoundsFromMyGpsPositionDynamicPlace();
                if (!isSuccess(gpsBoundsResult)) {
                    return { success: false, error: gpsBoundsResult.error };
                }
                bounds = gpsBoundsResult.value;
                break;
            }
            case ChangePlaceEventSourceEnum.CitySelection:
                bounds = await this.fetchBoundsFromStopsAroundCityDynamicPlace(dynamicPlace as CityDynamicPlaceAdapter);
                break;
            case ChangePlaceEventSourceEnum.BookmarkSelection: {
                const bookmarkAdapter = dynamicPlace.getData() as BookmarkAdapter;
                const boundsRect = bookmarkAdapter.getData().boundsRect;
                bounds = toBounds(boundsRect) ?? null;
                break;
            }
            default:
                return {
                    success: false,
                    error: new TechnicalError(
                        `Behavior unknown to the event ${ChangePlaceEventSourceEnum[dynamicPlace.getChangePlaceEvent()]}`,
                        ErrorCodes.SearchPlace.PickPlace,
                    ),
                };
        }
        return { success: true, value: bounds };
    }

    createLatLngFromStopDynamicPlace(dynamicPlace: StopDynamicPlaceAdapter): LatLng {
        const stopPlace = dynamicPlace.getData() as StopAdapter;
        return new LatLng(stopPlace.getLatitude(), stopPlace.getLongitude());
    }

    createCoordinatesFromCity(cityDynamicPlace: CityDynamicPlaceAdapter, includeAllMainStops = false): GeolocationCoordinates {
        const mainStops = cityDynamicPlace.getMainStops();
        const hasMainStops = !!mainStops && mainStops.length >= 1;
        if (!hasMainStops) {
            throw new Error(`No main stops on this city : ${cityDynamicPlace.getData().getName()}`);
        }
        const stopsCoordinates = mainStops.map((stop) => new LatLng(stop.getLatitude(), stop.getLongitude()));
        const coordinatesToCenter = includeAllMainStops ? stopsCoordinates : stopsCoordinates[0];
        const center = this.mapService.getCenterOf(coordinatesToCenter);
        const { lat: latitude, lng: longitude, alt: altitude } = center;
        return {
            accuracy: null,
            latitude,
            longitude,
            altitude,
            altitudeAccuracy: null,
            heading: null,
            speed: null,
        };
    }

    private async fetchBoundsFromStopsAroundStopDynamicPlace(dynamicPlace: StopDynamicPlaceAdapter): Promise<LatLngBounds> {
        const positionOfStop = this.createLatLngFromStopDynamicPlace(dynamicPlace);
        const stops = await firstValueFrom(this.physicalStopService.$getPhysicalStopsByLatLng(positionOfStop));
        return this.mapService.createBoundsFromPhysicalStops(stops);
    }

    private async createBoundsFromMyGpsPositionDynamicPlace(): Promise<Result<LatLngBounds, TechnicalError>> {
        const result = await this.mapService.fetchDataNearBoundsOfGpsMarker();
        if (!isSuccess(result)) {
            return { success: false, error: result.error };
        }
        return { success: true, value: result.value.bounds };
    }

    private async fetchBoundsFromStopsAroundAddressDynamicPlace(dynamicPlace: AddressDynamicPlaceAdapter): Promise<LatLngBounds> {
        const addressPoint = this.createLatLngFromAddressDynamicPlace(dynamicPlace);
        const $bounds = this.physicalStopService.$getPhysicalStopsByLatLng(addressPoint).pipe(
            map((stops) => {
                const circle: Circle = this.mapService.createCircleIncluding(
                    stops.map((stop) => stop.getLatLng(environment.defaultPlace)),
                    addressPoint,
                );
                this.mapService.setLayer(LayersKey.CIRCLE_BOUNDS_CALCULATOR, circle);
                this.mapService.addLayerToMap(LayersKey.CIRCLE_BOUNDS_CALCULATOR);
                this.mapService.redrawDebugLayer(circle.getBounds(), DebugLayerName.GpsArea, circle.getRadius());
                return circle.getBounds();
            }),
        );
        return firstValueFrom($bounds);
    }

    private createLatLngFromAddressDynamicPlace(dynamicPlace: AddressDynamicPlaceAdapter): LatLng {
        const address = dynamicPlace.getData() as AddressAdapter;
        return new LatLng(address.getLatitude(), address.getLongitude());
    }

    private async fetchBoundsFromStopsAroundCityDynamicPlace(dynamicPlace: CityDynamicPlaceAdapter): Promise<LatLngBounds | null> {
        const stops = dynamicPlace.getMainStops();
        const hasMainStops = stops && stops.length > 0;
        if (hasMainStops) {
            const firstStop = stops[0];
            const physicalStops = await firstValueFrom(this.physicalStopService.$getPhysicalStopsByLatLng(firstStop.getLatLng()));
            return this.mapService.createBoundsFromLatLngArray([
                ...physicalStops.map((physicalStopAdapter) => physicalStopAdapter.getLatLng(environment.defaultPlace)),
                firstStop.getLatLng(),
            ]);
        }
        console.warn(`No stops found to build a bounds.`);
        return null;
    }

    private createLayerFromStopDynamicPlace(coordinates: GeolocationCoordinates, place: DynamicPlace): Layer {
        const marker: Marker = this.mapService.createMarker(new LatLng(coordinates.latitude, coordinates.longitude), place.getDivIcon());
        return this.layerBuilder.buildLayerFromMarkers([marker]);
    }
}
