import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PaymentPage } from '../payment/payment.page';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'page-test',
    templateUrl: 'test.html',
    styleUrls: ['test.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestPage implements OnInit {
    constructor(private modalCtrl: ModalController) {}
    async ngOnInit(): Promise<void> {
        const paymentModal = await this.modalCtrl.create({
            component: PaymentPage,
            componentProps: {},
        });
        await paymentModal.present();
    }
}
