/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Injectable } from '@angular/core';
import { PlatformUtilsService } from '@traas/common/utils';
import { PreferencesService } from '@traas/common/feature-account';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ColorModeOptions } from '@traas/boldor/all-models';

@Injectable({
    providedIn: 'root',
})
export class StatusBarService {
    constructor(private platformUtilsService: PlatformUtilsService, private preferencesService: PreferencesService) {}

    async initializeStatusBar(): Promise<void> {
        if (!this.platformUtilsService.isWeb()) {
            const color = await this.getBackgroundColor();
            await this.setBackgroundColor(color);
            const cmo = await this.preferencesService.getColorModeOption();
            const style = this.getStyleByColorMode(cmo);
            await StatusBar.setStyle({ style });

            // NOT IMPLEMENTED YET
            // https://github.com/ionic-team/capacitor/issues/1876
            // StatusBar.overlaysWebView(this.platformUtilsService.isIos());
        }
    }

    async setStyle(colorMode: ColorModeOptions): Promise<void> {
        const backgroundColor = this.getBackgroundByColorMode(colorMode);
        await this.setBackgroundColor(backgroundColor);

        const style = this.getStyleByColorMode(colorMode);
        await StatusBar.setStyle({ style }).catch(() => {});
    }

    getBackgroundByColorMode(colorMode: ColorModeOptions): string {
        switch (colorMode) {
            case ColorModeOptions.Dark:
                return '#000000';
            case ColorModeOptions.Light:
            default:
                return '#ffffff';
        }
    }

    async resetStyle(): Promise<void> {
        const colorMode = await this.preferencesService.getColorModeOption();
        const style = this.getStyleByColorMode(colorMode);
        await StatusBar.setStyle({ style }).catch(() => {});
        const backgroundColor = await this.getBackgroundColor();
        await this.setBackgroundColor(backgroundColor);
    }

    getStyleByColorMode(colorMode: ColorModeOptions): Style {
        return colorMode === ColorModeOptions.Light ? Style.Light : Style.Dark;
    }

    private async setBackgroundColor(color: string): Promise<void> {
        if (this.platformUtilsService.isAndroid()) {
            await StatusBar.setBackgroundColor({ color });
        }
    }

    private async getBackgroundColor(): Promise<string> {
        const colorMode = await this.getColorMode();
        return this.getBackgroundByColorMode(colorMode);
    }

    private async getColorMode(): Promise<ColorModeOptions> {
        const colorMode = await this.preferencesService.getColorModeOption();
        return colorMode || this.preferencesService.getDefaultPreferences().colorMode;
    }
}
