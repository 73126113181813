import { Injectable } from '@angular/core';
import { DepartureService } from '../../../features/departure/services/departure.service';
import { ItineraryArticleService } from '../../../features/itinerary/services/itinerary-article.service';
import {
    ArticlesBundle,
    ArticleSelection,
    ArticleViewModel,
    Cart,
    CartOperations,
    CheckoutStep,
    NearestStop,
    Departure,
    DepartureArticleViewModel,
    GuestCustomer,
    Itinerary,
    OrderViewModel,
    QuickArticleViewModel,
    SelectedZone,
    TicketDuration,
    Zone,
} from '@traas/boldor/all-models';
import { ArticleCategoryService } from '../../../features/ticket/services/article-category.service';
import { QuickArticleService } from '../../../features/ticket/services/quick-article.service';
import { DepartureAdapter, isDepartureJourney } from '../../../models/departure/departure';
import { isItineraryJourney, ItineraryAdapter } from '../../../models/itinerary/itinerary';
import { GqlArticleConverter } from '../../../models/ticket/gql-article-converter';
import { isItineraryCart, isQuickTicketByZoneCart, isQuickTicketCart, isQuickTicketCartByCategory } from '../../../models/cart/cart.utils';
import { CartFactory } from '../../../models/cart/cart.factory';
import { BoldorLocalizationService } from '@traas/common/localization';
import { CompanyService } from '../company/company.service';
import { CartActions, CartState } from '../../../features/cart/store';
import { Store } from '@ngrx/store';
import { PreferencesService } from '@traas/common/feature-account';
import { RoutingService } from '@traas/common/routing';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { convertToError, LoggingService } from '@traas/common/logging';
import { CustomerProviderService } from '../customer/customer-provider.service';
import { ModalController } from '@ionic/angular';
import { PurchaseArticleService } from '../purchase/purchase-article.service';
import { AlertService } from '../alert.service';
import { OrderService } from '../order/order.service';
import { AnalyticsService } from '@traas/common/analytics';
import { PaymentService } from '../purchase/payment.service';
import { endOfMinute } from 'date-fns';

@Injectable()
export class CartService {
    constructor(
        protected itineraryArticleService: ItineraryArticleService,
        protected departureService: DepartureService,
        protected quickArticleService: QuickArticleService,
        protected articleCategoryService: ArticleCategoryService<any>,
        protected localizationService: BoldorLocalizationService,
        protected cartStore: Store<CartState>,
        protected preferenceService: PreferencesService,
        protected routingService: RoutingService,
        protected customerProviderService: CustomerProviderService,
        protected preferencesService: PreferencesService,
        protected modalCtrl: ModalController,
        protected purchaseArticleService: PurchaseArticleService,
        protected logger: LoggingService,
        protected store: Store,
        protected alertService: AlertService,
        protected orderService: OrderService,
        protected analyticsService: AnalyticsService,
        protected paymentService: PaymentService,
    ) {}

    async createAndNavigateToNewCartFromDeparture(departure: Departure, guestCustomer?: GuestCustomer): Promise<void> {
        const cart = await CartFactory.createCartFromDeparture(new DepartureAdapter(departure), this.localizationService);

        this.cartStore.dispatch(
            new CartActions.InitCart({
                cart,
                travelType: this.preferenceService.getTravelType(),
                chooseTicketManually: false,
            }),
        );
        this.cartStore.dispatch(new CartActions.SetCheckoutStep(CheckoutStep.OperationChooser));
        await this.routingService.navigateToCart(CheckoutStep.OperationChooser, guestCustomer);
    }

    async createAndNavigateToNewCartFromItinerary(itinerary: Itinerary, guestCustomer?: GuestCustomer): Promise<void> {
        const cart = await CartFactory.createCartFromItinerary(new ItineraryAdapter(itinerary), this.localizationService);
        const travelType = this.preferenceService.getTravelType();
        const isTpg = CompanyService.isTPG();
        const chooseTicketManually = isTpg;
        const checkoutStep = isTpg ? CheckoutStep.TicketConfiguration : CheckoutStep.OperationChooser;

        this.cartStore.dispatch(
            new CartActions.InitCart({
                cart,
                travelType,
                chooseTicketManually,
            }),
        );
        this.cartStore.dispatch(new CartActions.SetCheckoutStep(checkoutStep));
        await this.routingService.navigateToCart(checkoutStep, guestCustomer);
    }

    async fetchArticles(
        cart: Cart | null,
        articlesBundle: ArticlesBundle,
        durationsFilter: TicketDuration[],
        nearestStop: NearestStop | null,
    ): Promise<ArticlesBundle> {
        if (isItineraryJourney(cart?.journeyViewModel)) {
            return this.prepareItineraryArticleBundle(cart.journeyViewModel, articlesBundle, durationsFilter);
        }
        if (isDepartureJourney(cart?.journeyViewModel)) {
            return this.prepareDepartureArticleBundle(cart.journeyViewModel, articlesBundle, durationsFilter);
        }
        if (isQuickTicketCartByCategory(cart)) {
            return this.prepareQuickArticleBundleByCategory(
                articlesBundle as ArticlesBundle<QuickArticleViewModel>,
                cart.origin.categoryId,
            );
        }
        if (isQuickTicketByZoneCart(cart)) {
            return this.prepareQuickArticleByZoneBundle(
                articlesBundle as ArticlesBundle<QuickArticleViewModel>,
                +cart.article.id,
                cart.article.zones,
            );
        }

        // This must be the last test case
        if (isQuickTicketCart(cart)) {
            return this.prepareQuickArticleBundle(articlesBundle as ArticlesBundle<QuickArticleViewModel>, nearestStop, durationsFilter);
        }
    }

    hasValidArticlesSelections(cart: Cart): boolean {
        const articlesSelections = cart.articleSelections.filter(({ article, passenger }) => {
            return !!article && !!passenger;
        });
        return articlesSelections.length > 0;
    }

    getPreviousCheckoutStepFromPayment(): CheckoutStep {
        throw Error('Must be overriden in child class.');
    }

    canGoNextStep(
        checkoutStep: CheckoutStep,
        hasSelectedPaymentMean: boolean,
        selectedZones: SelectedZone,
        articleSelections: ArticleSelection<ArticleViewModel>[],
        operations: CartOperations,
        isMarketingConsentsLoading: boolean,
        isOnline: boolean,
    ): boolean {
        switch (checkoutStep) {
            case CheckoutStep.OperationChooser:
                return isOnline && this.hasAvailableOperation(articleSelections, operations);
            case CheckoutStep.TicketConfiguration:
                return (
                    isOnline &&
                    this.hasAvailableOperation(articleSelections, operations) &&
                    articleSelections.every((selection) => selection.article?.prices[0].amountInCents > 0)
                );
            case CheckoutStep.Payment:
                return isOnline && hasSelectedPaymentMean && !isMarketingConsentsLoading;
            case CheckoutStep.ZonesPicker:
                return isOnline && !!selectedZones;
            default:
                return false;
        }
    }

    private hasAvailableOperation(articleSelections: ArticleSelection<ArticleViewModel>[], operations: CartOperations): boolean {
        const buyAvailable = articleSelections.length > 0 && articleSelections.some(({ article }) => !!article);
        return buyAvailable || operations?.save.available || operations?.stopRequest.available;
    }

    private async prepareItineraryArticleBundle(
        itinerary: Itinerary,
        articlesBundle: ArticlesBundle,
        durationsFilter: TicketDuration[],
    ): Promise<ArticlesBundle> {
        try {
            const itineraryArticles = await this.itineraryArticleService.generateItineraryTicketArticles(
                itinerary,
                articlesBundle.passenger,
                durationsFilter,
            );

            const availableArticles = itineraryArticles.map((article) => GqlArticleConverter.itineraryArticleToViewModel(article));
            return {
                ...articlesBundle,
                availableArticles,
            };
        } catch (error) {
            const itineraryAdapter = new ItineraryAdapter(itinerary);
            const currency = await this.preferenceService.getCurrency();
            throw new TechnicalError(
                'Error while fetching itinerary articles',
                ErrorCodes.Purchase.PrepareItineraryArticle,
                convertToError(error),
                {
                    currency,
                    'passenger-id': articlesBundle.passenger.id,
                    'language-id': this.localizationService.languageCode,
                    itinerary: {
                        id: itineraryAdapter.getId(),
                        departure: itineraryAdapter.getDepartureStop().getName(),
                        arrival: itineraryAdapter.getArrivalStop().getName(),
                        scheduledAt: itineraryAdapter.getScheduledDepartureDate(),
                        line: itineraryAdapter.getTransportLegs()[0]?.getLine().number ?? '',
                    },
                },
            );
        }
    }

    private async prepareDepartureArticleBundle(
        journey: Departure,
        articlesBundle: ArticlesBundle,
        durationsFilter: TicketDuration[],
    ): Promise<ArticlesBundle<DepartureArticleViewModel>> {
        const departuresArticles = await this.departureService.generateDepartureTicketArticles(
            journey,
            articlesBundle.passenger,
            durationsFilter,
        );

        const availableArticles = departuresArticles.map((article) => GqlArticleConverter.departureArticleToViewModel(article));
        return {
            ...articlesBundle,
            availableArticles,
        };
    }

    private async prepareQuickArticleBundle(
        articlesBundle: ArticlesBundle<QuickArticleViewModel>,
        nearestStop?: NearestStop,
        durationsFilter?: TicketDuration[],
    ): Promise<ArticlesBundle<QuickArticleViewModel>> {
        // used by TPC to get articles by gps position
        const articles = await this.quickArticleService.getQuickArticles(nearestStop, articlesBundle.passenger, durationsFilter);

        const availableArticles = articles.map((article) => GqlArticleConverter.quickArticleToViewModel(article));

        return {
            ...articlesBundle,
            availableArticles,
        };
    }

    private async prepareQuickArticleByZoneBundle(
        articlesBundle: ArticlesBundle<QuickArticleViewModel>,
        articleId: number,
        zones: Zone[],
    ): Promise<ArticlesBundle<QuickArticleViewModel>> {
        const articleZones: Zone[] = zones.map(({ id }) => ({ id }));
        const articles = await this.quickArticleService.generateQuickArticleByZone(articleId, articleZones, articlesBundle.passenger);
        const availableArticles = articles.map((article) => GqlArticleConverter.quickArticleToViewModel(article, zones));

        return {
            ...articlesBundle,
            availableArticles,
        };
    }

    private async prepareQuickArticleBundleByCategory(
        articlesBundle: ArticlesBundle<QuickArticleViewModel>,
        categoryId: string,
    ): Promise<ArticlesBundle<QuickArticleViewModel>> {
        const articles = await this.articleCategoryService.getTicketsUsingCategory(articlesBundle, categoryId);
        const availableArticles = articles.map((article) => GqlArticleConverter.quickArticleToViewModel(article, article.zones));
        return {
            ...articlesBundle,
            availableArticles,
        };
    }

    purchase(cart: Cart, guestCustomer: GuestCustomer | undefined): Promise<OrderViewModel> {
        return this.paymentService.processPurchase(cart, guestCustomer);
    }

    resetState(): void {
        this.cartStore.dispatch(new CartActions.ResetCart());
        this.paymentService.resetState();
    }

    /**
     * Une offre est considérée comme expirée si elle est liée à un itinéraire dont la date de départ est dans le passé.
     * Le billet étant généré à partir de la date de début en temps réél de l'itinéraire, c'est cette date qui est utilisée pour la validation
     * @param cart - Cart
     * @param itinerary - Itinerary
     * @param now - Date
     */
    offerExpired(cart: Cart, itinerary: Itinerary, now = new Date()): boolean {
        if (!isItineraryCart(cart) || !itinerary) {
            return false;
        }

        const rtDepartureDate = new ItineraryAdapter(itinerary).getRealTimeDepartureDate();
        return endOfMinute(rtDepartureDate) < now;
    }
}
