import { JourneyDataType } from './journey.factory';
import { ONE_MINUTE_IN_MS } from '@traas/boldor/all-helpers';
import { HumanReadableDirection, HumanReadableStop, Identifiable, JourneyData, JourneyEnum } from '@traas/boldor/all-models';
import { BookableAdapter } from '../../../models/booking/bookable.adapter';
import * as moment from 'moment';
import { Line } from '@traas/common/models';

/**
 * This class define the behavior of a base journey.
 * She implements all methods which have the same behavior.
 */
export abstract class JourneyAdapter<T extends JourneyData = JourneyDataType>
    extends BookableAdapter<T>
    implements HumanReadableDirection, Identifiable
{
    protected constructor(data: T) {
        super(data);
    }

    abstract getDirection(): string;

    abstract getLines(): Line[];

    abstract getRealTimeDepartureDate(): Date;

    abstract getTrack(): string | undefined;

    abstract getDirectionStopName(): string;

    abstract getDepartureStop(): HumanReadableStop;

    abstract getDirectionCity(): string;

    abstract isOutdated(): boolean;

    abstract getId(): string;

    timeLeftToBookInMs(): number {
        const remainingTimeBeforeBooking = this.data.remainingTimeBeforeBooking ?? 0;
        if (remainingTimeBeforeBooking <= 0) {
            return 0;
        }
        return remainingTimeBeforeBooking * ONE_MINUTE_IN_MS;
    }

    isValidTimeConstraintToBook(): boolean {
        const remainingTimeBeforeBooking = this.getRemainingTimeBeforeBooking();
        if (remainingTimeBeforeBooking === undefined && this.hasBookingRequirements()) {
            console.error('Has booking requirements and remainingTimeBeforeBooking is undefined.');
            return false;
        }
        if (remainingTimeBeforeBooking !== undefined) {
            return remainingTimeBeforeBooking >= 0;
        }
        return true;
    }

    override hasBookingRequirements(): boolean {
        return this.getData().hasBookingRequirements;
    }

    getRealTimeDepartureTimestamp(): number {
        return this.getRealTimeDepartureDate().getTime();
    }

    override isBookable(): boolean {
        const now = moment().local().toDate().getTime();
        return !this.getData().isCancelled && super.isBookable() && now < this.getRealTimeDepartureDate().getTime();
    }

    protected setType(journeyType: JourneyEnum): void {
        this.getData().__type__ = journeyType;
    }
}
