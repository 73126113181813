import * as L from 'leaflet';
import { DivIcon, DivIconOptions } from 'leaflet';
import { Observable } from 'rxjs';
import { ChangePlaceEventSourceEnum, Place, PlaceStop } from '@traas/boldor/all-models';
import { DynamicPlaceAdapter } from './dynamic-place.adapter';

export class StopDynamicPlaceAdapter extends DynamicPlaceAdapter<Place<PlaceStop>> {
    private readonly changePlaceSourceEvent: ChangePlaceEventSourceEnum = ChangePlaceEventSourceEnum.StopSelection;

    constructor(data: Place<PlaceStop>) {
        super(data);
    }

    getChangePlaceEvent(): ChangePlaceEventSourceEnum {
        return this.changePlaceSourceEvent;
    }

    getDivIcon(iconOptions: DivIconOptions = {}): DivIcon {
        return L.divIcon({
            html: `
                <div class="place-focus">
                </div>
            `,
            className: `ni-cluster`,
            iconSize: [10, 10],
            popupAnchor: [-10 / 2, -10 / 2],
            ...iconOptions,
        });
    }

    getEndpointIconName(): string {
        return super.getEndpointIconName();
    }

    isDrawOnMapRequired(): boolean {
        return true;
    }

    isDrawOnEndpointRequired(): boolean {
        return true;
    }

    isNeededToFitMap(): boolean {
        return true;
    }

    $getHtmlLeafletIcon(): Observable<string> {
        return super.$getHtmlLeafletIcon();
    }

    transformToCoordinates(): GeolocationCoordinates {
        return this.getData().transformToCoordinates();
    }
}
