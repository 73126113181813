import * as Gql from '@traas/boldor/graphql-generated/graphql';
import { GetItineraryStopRequestsQuery } from '@traas/boldor/graphql-generated/graphql';
import { GqlItinerary } from '../../../models/order';
import { Itinerary, ItineraryStop, JourneyEnum, Leg } from '@traas/boldor/all-models';
import { AssociatedCommercialStop, Line, PhysicalStop } from '@traas/common/models';

export type GqlLeg = GetItineraryStopRequestsQuery['order']['getItineraryStopRequests'][number]['itinerary']['legs'][number];

export type GqlStop = GqlLeg['stops'][number];

export class GqlToFrontItineraryConverter {
    static toItinerary(itinerary: GqlItinerary): Itinerary {
        return {
            hasStopRequest: itinerary.hasStopRequest,
            remainingTimeBeforeBooking: itinerary.remainingTimeBeforeBooking,
            isBookable: itinerary.isBookable,
            isCancelled: itinerary.isCancelled,
            hasBookingRequirements: itinerary.hasBookingRequirements,
            bookingDeadline: itinerary.bookingDeadline,
            legs: itinerary.legs.map((leg) => GqlToFrontItineraryConverter.toLeg(leg)),
            arrivalDate: itinerary.arrivalDate,
            arrivalTime: itinerary.arrivalTime,
            departureDate: itinerary.departureDate,
            departureTime: itinerary.departureTime,
            scheduledArrivalDate: itinerary.scheduledArrivalDate,
            scheduledArrivalTime: itinerary.scheduledArrivalTime,
            scheduledDepartureDate: itinerary.scheduledDepartureDate,
            scheduledDepartureTime: itinerary.scheduledDepartureTime,
            id: itinerary.id,
            __type__: JourneyEnum.Itinerary,
        };
    }

    private static toLine(line: Gql.Line): Line {
        return {
            color: line.color,
            destination: line.destination,
            fontColor: line.fontColor,
            id: line.id,
            lineOfficialCode: line.lineOfficialCode,
            networkId: line.networkId,
            number: line.number,
            style: line.style,
        };
    }

    private static toAssociatedCommercialStop(stop: GqlStop, didok: number): AssociatedCommercialStop {
        return {
            name: stop.name,
            lines: stop.physicalStopAssociated.lines.map(GqlToFrontItineraryConverter.toLine),
            didok,
            coordinates: {
                latitude: stop.latLon[0],
                longitude: stop.latLon[1],
            },
            cityName: stop.cityName,
            id: stop.id,
        };
    }

    private static toPhysicalStopAssociated(stop: GqlStop, didok: number): PhysicalStop {
        const { physicalStopAssociated } = stop;
        return {
            associatedCommercialStop: GqlToFrontItineraryConverter.toAssociatedCommercialStop(stop, didok),
            transportMode: physicalStopAssociated.transportMode,
            lines: physicalStopAssociated.lines.map((line) => GqlToFrontItineraryConverter.toLine(line)),
            id: physicalStopAssociated.id,
            geometry: physicalStopAssociated.geometry,
            letter: physicalStopAssociated.letter,
        };
    }

    private static toStop(stop: GqlStop): ItineraryStop {
        return {
            isOptional: stop.isOptional,
            hasBookingRequirements: stop.hasBookingRequirements,
            physicalStopAssociated: GqlToFrontItineraryConverter.toPhysicalStopAssociated(stop, stop.didok),
            isCancelled: stop.isCancelled,
            arrivalTime: stop.arrivalTime,
            departureTime: stop.departureTime,
            id: stop.id,
            cityName: stop.cityName,
            didok: stop.didok,
            isArrivalPlace: stop.isArrivalPlace,
            isDeparturePlace: stop.isDeparturePlace,
            latLon: [stop.latLon[0], stop.latLon[1]],
            name: stop.name,
            scheduledArrivalTime: stop.scheduledArrivalTime,
            scheduledDepartureTime: stop.scheduledDepartureTime,
            messages: [],
        };
    }

    private static toLeg(leg: GqlLeg): Leg {
        return {
            serviceId: leg.serviceId,
            duration: leg.duration,
            bookingDeadline: leg.bookingDeadline,
            byFoot: leg.byFoot,
            isBookable: leg.isBookable,
            length: leg.length,
            line: GqlToFrontItineraryConverter.toLine(leg.line),
            stops: leg.stops.map((stop) => GqlToFrontItineraryConverter.toStop(stop)),
            transportCompanyId: leg.transportCompanyId,
            vehicle: {
                code: leg.vehicle.code,
                transport: leg.vehicle.transport,
                transportModeId: leg.vehicle.transportModeId,
            },
            messages: [],
            isFirstClassAuthorized: leg.isFirstClassAuthorized,
            serviceNumber: leg.serviceNumber,
        };
    }
}
