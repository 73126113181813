import { ChangePlaceEventSourceEnum, createEmptyArea, createEmptyGPSLocationArea, Endpoint } from '@traas/boldor/all-models';
import { EndpointInformation } from './endpoint.selector';

export interface EndpointState {
    departure: EndpointInformation & { isChanging: boolean };
    arrival: EndpointInformation & { hidden?: boolean; isChanging: boolean };
    activeEndpoint: Endpoint;
    isEnabled: boolean;
}

export const initialState: EndpointState = {
    departure: {
        area: createEmptyGPSLocationArea(),
        endpoint: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            hasTooMuchStops: false,
            iconName: '',
            locations: '',
        },
        ignoreNextChange: false,
        endpointValue: Endpoint.Departure,
        isChanging: false,
    },
    arrival: {
        area: createEmptyArea(ChangePlaceEventSourceEnum.ManualMapMove),
        endpoint: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            hasTooMuchStops: false,
            iconName: '',
            locations: '',
        },
        ignoreNextChange: false,
        endpointValue: Endpoint.Arrival,
        hidden: true,
        isChanging: false,
    },
    activeEndpoint: Endpoint.Departure,
    isEnabled: true,
};
