import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { stripUrl } from '@traas/boldor/all-helpers';
import { TransportMode } from '@traas/boldor/all-models';
import { ItineraryFiltersService } from '../../features/itinerary/services/itinerary-filters.service';
import { ItineraryStoreActions, ItineraryStoreSelectors } from '../../features/itinerary/store';
import { ItineraryState } from '../../features/itinerary/store/itinerary.state';
import { ICON_BASE_PATH } from '../../business-rules.utils';
import { AnalyticsService } from '@traas/common/analytics';
import { RouteUrl } from '@traas/common/routing';
import { BoldorLocalizationService } from '@traas/common/localization';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Component({
    selector: 'transport-mode-filter-popover',
    templateUrl: './transport-mode-filter-popover.component.html',
    styleUrls: ['./transport-mode-filter-popover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportModeFilterPopoverComponent implements OnInit {
    $isLoading = new BehaviorSubject<boolean>(true);
    allTransportModesAreChecked = false;
    enabledTransportModes: TransportMode[] = [];
    hideContent = false;
    transportModes: TransportMode[] = [];

    constructor(
        private analyticsService: AnalyticsService,
        private boldorLocalizationService: BoldorLocalizationService,
        private cdr: ChangeDetectorRef,
        private itineraryFiltersService: ItineraryFiltersService,
        private popoverController: PopoverController,
        private store: Store<ItineraryState>,
    ) {}

    async ngOnInit(): Promise<void> {
        this.transportModes = await this.itineraryFiltersService.getTransportModesFromStorage();
        // we spread the array elements into a new array. If we don't, any push/pop here would also change the store array
        this.enabledTransportModes = [...(await firstValueFrom(this.store.select(ItineraryStoreSelectors.getEnabledTransportModes)))];
        if (this.transportModes.length === this.enabledTransportModes.length) {
            this.allTransportModesAreChecked = true;
        }
        this.$isLoading.next(false);

        this.analyticsService.reportPageView(stripUrl(RouteUrl.itineraryFilterUrl, true));
    }

    selectAllTransportModesToggleChange(event: Event): void {
        if ((event as CustomEvent).detail.checked && !this.allTransportModesAreChecked) {
            this.enabledTransportModes = this.transportModes;
            this.allTransportModesAreChecked = true;
        } else if (!(event as CustomEvent).detail.checked && this.allTransportModesAreChecked) {
            this.enabledTransportModes = [];
            this.allTransportModesAreChecked = false;
        }
    }

    transportModeClicked(event: Event, transportMode: TransportMode): void {
        if ((event as CustomEvent).detail.checked && !this.isTransportModeChecked(transportMode)) {
            const transportModeToAdd = this.transportModes.filter((item) => item.id === transportMode.id)[0];
            this.enabledTransportModes.push(transportModeToAdd);
        } else if (!(event as CustomEvent).detail.checked && this.isTransportModeChecked(transportMode)) {
            this.enabledTransportModes = this.enabledTransportModes.filter((item) => item.id !== transportMode.id);
        }
        this.allTransportModesAreChecked = this.transportModes.length === this.enabledTransportModes.length;
    }

    isTransportModeChecked(transportMode: TransportMode): boolean {
        return this.enabledTransportModes.filter((enabledTransportMode) => enabledTransportMode.id === transportMode.id).length > 0;
    }

    getTransportModeIcon({ name }: TransportMode): string {
        const path = `${ICON_BASE_PATH}/transport-modes/`;
        switch (name) {
            case 'Funiculaire':
                return `${path}funicular.svg`;
            case 'Metro':
                return `${path}metro.svg`;
            case 'Bateau':
                return `${path}boat.svg`;
            case 'Train':
                return `${path}train.svg`;
            case 'Tram':
                return `${path}tram.svg`;
            case 'Bus':
            default:
                return `${path}bus.svg`;
        }
    }

    async cancelButtonClicked(): Promise<boolean> {
        return this.popoverController.dismiss();
    }

    async submitFiltersButtonClicked(): Promise<boolean> {
        const currentlyEnabledTransportModes = await firstValueFrom(this.store.select(ItineraryStoreSelectors.getEnabledTransportModes));
        if (!this.itineraryFiltersService.areTransportModeArraysIdentical(currentlyEnabledTransportModes, this.enabledTransportModes)) {
            this.store.dispatch(new ItineraryStoreActions.SetEnabledTransportModes(this.enabledTransportModes));
        }
        return this.popoverController.dismiss();
    }

    areAllTransportModesUnchecked(): boolean {
        return this.enabledTransportModes.length === 0;
    }
}
