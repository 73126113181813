/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { DepartureAdapter } from '../../../models/departure/departure';
import { LegAdapter } from '../../../models/itinerary/leg';
import * as _ from 'lodash';
import { Departure, Leg, LineFilter, LineStyle } from '@traas/boldor/all-models';
import { UNKNOWN_LINE_BACKGROUND_COLOR, UNKNOWN_LINE_FONT_COLOR } from '../../../business-rules.utils';

type TransformableType = LegAdapter | DepartureAdapter;

@Injectable()
export class LineService {
    constructor() {}

    static from(adapter: TransformableType): LineStyle {
        if (adapter instanceof DepartureAdapter) {
            return this.fromDeparture(adapter.getData());
        }
        return this.fromLeg(adapter.getData());
    }

    hasDifferentLineFilters(firstArray: LineFilter[], secondArray: LineFilter[]): boolean {
        const hasDifferentLength = firstArray.length !== secondArray.length;
        const hasDifferentLines = firstArray.some((lineFilter) => !this.findLineFilter(lineFilter, secondArray));
        return hasDifferentLength || hasDifferentLines;
    }

    findLineFilter(lineFilter: LineFilter, array: LineFilter[]): LineFilter | undefined {
        return _.find(array, {
            id: lineFilter.id,
            wayback: lineFilter.wayback,
            destination: lineFilter.destination,
        });
    }

    sortLineFilters(lineFilters: LineFilter[]): LineFilter[] {
        const options = { numeric: true };
        return lineFilters.sort((lineA, lineB) => {
            const result = lineA.number.localeCompare(lineB.number, 'en', options);
            if (result === 0 && !!lineA.destination && !!lineB.destination) {
                return lineA.destination.localeCompare(lineB.destination, 'en', options);
            }
            return result;
        });
    }

    getUniqLineFilters(lineFilters: LineFilter[]): LineFilter[] {
        return _.uniqBy(lineFilters, ({ id, wayback, destination }: LineFilter) => {
            return `${id}${wayback}${destination}`;
        });
    }

    private static fromLeg({ line }: Leg): LineStyle {
        return {
            id: line.id,
            name: line?.number,
            style: line?.style,
            backgroundColor: line?.color ?? UNKNOWN_LINE_BACKGROUND_COLOR,
            textColor: line?.fontColor ?? UNKNOWN_LINE_FONT_COLOR,
        };
    }

    private static fromDeparture(departure: Departure): LineStyle {
        try {
            return {
                id: departure.line.id,
                name: departure.line.number,
                style: departure.line.style,
                backgroundColor: departure.line.color ?? UNKNOWN_LINE_BACKGROUND_COLOR,
                textColor: departure.line.fontColor ?? UNKNOWN_LINE_FONT_COLOR,
            };
        } catch (error) {
            console.error(error);
            return {
                id: departure.line.id,
                name: departure.line.id,
                backgroundColor: UNKNOWN_LINE_BACKGROUND_COLOR,
                textColor: UNKNOWN_LINE_FONT_COLOR,
            };
        }
    }
}
