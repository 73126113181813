import { Injectable } from '@angular/core';
import { convertToError, LoggingService } from '@traas/common/logging';
import { StorageService } from './storage.service';
import { PlatformUtilsService } from '../platform-utils.service';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

/**
 * Silly wrapper to be able to type the storage ~~keys~~values
 */
@Injectable()
export class TypedStorage<T> {
    private static getKeyName(key: string | number | symbol): string {
        return String(key);
    }

    constructor(
        protected storage: StorageService,
        protected logger: LoggingService,
        protected platformUtilsService: PlatformUtilsService,
    ) {}

    async removeItem(key: keyof T): Promise<void> {
        await this.storage.remove(TypedStorage.getKeyName(key));
    }

    async getItem<K extends keyof T>(key: K, defaultValue: T[K] | null = null): Promise<T[K] | null> {
        if (!(await this.hasItem(key))) {
            return defaultValue;
        }
        const keyName = TypedStorage.getKeyName(key);
        const data: string | null = await this.storage.get(keyName);
        if (data) {
            try {
                return JSON.parse(data);
            } catch (error) {
                this.logger.logError(
                    new TechnicalError(
                        `[TypedStorage.getItem] Error during parsing of "${keyName}"`,
                        ErrorCodes.Technical.JsonParse,
                        convertToError(error),
                    ),
                );
                return defaultValue;
            }
        }
        return defaultValue;
    }

    async setItem<K extends keyof T>(key: K, value: T[K]): Promise<void> {
        try {
            const data = JSON.stringify(value);
            await this.storage.set(TypedStorage.getKeyName(key), data);
        } catch (error) {
            this.logger.logLocalError(convertToError(error));
        }
    }

    async hasItem(key: keyof T): Promise<boolean> {
        const keyToFind = TypedStorage.getKeyName(key);
        const allKeys = await this.storage.keys();
        const foundIndex = allKeys.findIndex((currentKey) => currentKey === keyToFind);
        return foundIndex !== -1;
    }

    async clear(): Promise<void> {
        await this.storage.clear();
    }
}
