import { Injectable } from '@angular/core';
import { environment } from '@traas/boldor/environments';
import { Company } from '@traas/common/models';

@Injectable()
export class CompanyService {
    static getClassNameByCompany(company: Company): string {
        switch (company) {
            case Company.Tpg:
                return 'tpg';
            case Company.Tpc:
                return 'tpc';
            case Company.Travys:
                return 'travys';
            default:
                return '';
        }
    }

    static getClassNameForCurrentCompany(): string {
        return this.getClassNameByCompany(this.getCurrentCompany());
    }

    static getCurrentCompany(): Company {
        switch (environment.company) {
            case 'tpg':
                return Company.Tpg;
            case 'travys':
                return Company.Travys;
            case 'tpc':
            default:
                return Company.Tpc;
        }
    }

    static isTPG(): boolean {
        return Company.Tpg === environment.company;
    }

    static isTPC(): boolean {
        return Company.Tpc === environment.company;
    }

    static isTRAVYS(): boolean {
        return Company.Travys === environment.company;
    }

    static isTraas(): boolean {
        return CompanyService.isTRAVYS() || CompanyService.isTPC();
    }

    static getDefaultPlaceByCompany(): { latitude: number; longitude: number } {
        return environment.defaultPlace;
    }
}
