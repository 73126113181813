import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Placeholder } from './placeholder.model';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';

@Component({
    selector: 'traas-placeholder-list-item',
    standalone: true,
    imports: [CommonModule, BoldorFeatureShellSharedModule],
    templateUrl: './placeholder-list-item.component.html',
    styleUrls: ['./placeholder-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholderListItemComponent {
    @Input() placehoder: Placeholder;
}
