import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LegAdapter } from '../../../../models/itinerary/leg';

@Component({
    selector: 'app-itinerary-details-content',
    templateUrl: './itinerary-details-content.component.html',
    styleUrls: ['./itinerary-details-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryDetailsContentComponent {
    @Input() legs: LegAdapter[];
}
