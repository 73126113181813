import { MessageViewModel } from '../../models/message-view-model';
import { StartupNotificationQuery } from '@traas/boldor/graphql-generated/graphql';

export type GqlMessage = StartupNotificationQuery['startupNotifications'][number];

export class GqlToFrontMessageConverter {
    static toViewModel(gqlMessage: GqlMessage): MessageViewModel {
        return {
            title: gqlMessage.title,
            htmlContent: gqlMessage.htmlContent,
            id: gqlMessage.id,
        };
    }
}
