<ion-header>
    <app-page-title iconUrl="assets/icons/tabs/monochrome/journey.svg" [title]="'my-journeys.title' | translate"></app-page-title>
</ion-header>

<ion-content>
    <ion-refresher #refresherRef (ionRefresh)="refreshOrders(true)" slot="fixed">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <journey-list-container> </journey-list-container>
</ion-content>
