import { Injectable } from '@angular/core';
import { LoggingService, logOptionsFactory } from '@traas/common/logging';
import { GqlToFrontOrderConverter } from '../../../models/order';
import { GqlToFrontStopRequestConverter } from '../../../models/stop-request/gql-to-front';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Article, LocalizedOptionItem, OrderViewModel, Passenger, Ticket, Validity } from '@traas/boldor/all-models';
import { ObservableTypedStorage } from '@traas/common/utils';

interface TicketOrderStorage {
    orders: OrderViewModel[];
}

const ORDER_STORAGE_KEY = 'orders';

@Injectable({
    providedIn: 'root',
})
export class OrderStorageService {
    constructor(private logger: LoggingService, private storage: ObservableTypedStorage<TicketOrderStorage>) {}

    static convertStringDateToDateWithTimezoneOffset(date: string): Date {
        return new Date(moment(date).toISOString(true));
    }

    private static sortJourneysByPurchaseDate(ticketA: OrderViewModel, ticketB: OrderViewModel): number {
        return OrderStorageService.getPurchaseDate(ticketB) >= OrderStorageService.getPurchaseDate(ticketA) ? 1 : -1;
    }

    private static getPurchaseDate(order: OrderViewModel): string {
        if (GqlToFrontOrderConverter.isViewModelSaveItineraryOrder(order) || GqlToFrontStopRequestConverter.isItineraryStopRequest(order)) {
            return order.itinerary.departureDate;
        }
        if (GqlToFrontStopRequestConverter.isDepartureStopRequest(order)) {
            return order.departure.plannedDepartureTime;
        }
        const [firstTicket] = order.tickets;
        return firstTicket.purchaseDate;
    }

    private static getExpiredDateOfOrder(order: OrderViewModel): Date {
        let dateToUse = '';
        if (GqlToFrontOrderConverter.isViewModelSaveItineraryOrder(order) || GqlToFrontStopRequestConverter.isItineraryStopRequest(order)) {
            dateToUse = `${order.itinerary.arrivalDate}`;
        } else if (GqlToFrontStopRequestConverter.isDepartureStopRequest(order)) {
            dateToUse = `${order.departure.plannedDepartureTime}`;
        } else {
            dateToUse = order.tickets[0].article.validity.to;
        }
        return OrderStorageService.convertStringDateToDateWithTimezoneOffset(dateToUse);
    }

    private static getValidToDateOfOrder(order: OrderViewModel): Date {
        try {
            let dateToUse: string;
            if (
                GqlToFrontOrderConverter.isViewModelSaveItineraryOrder(order) ||
                GqlToFrontStopRequestConverter.isItineraryStopRequest(order)
            ) {
                dateToUse = `${order.itinerary.arrivalDate}`;
            } else if (GqlToFrontStopRequestConverter.isDepartureStopRequest(order)) {
                dateToUse = `${order.departure.plannedDepartureTime}`;
            } else {
                dateToUse = order.tickets[0].article.validity.to;
            }
            return OrderStorageService.convertStringDateToDateWithTimezoneOffset(dateToUse);
        } catch (error) {
            console.error(error);
            return new Date();
        }
    }

    private static getValidFromDateOfOrder(order: OrderViewModel): Date {
        try {
            let dateToUse: string;
            if (
                GqlToFrontOrderConverter.isViewModelSaveItineraryOrder(order) ||
                GqlToFrontStopRequestConverter.isItineraryStopRequest(order)
            ) {
                dateToUse = `${order.itinerary.departureDate}`;
            } else if (GqlToFrontStopRequestConverter.isDepartureStopRequest(order)) {
                dateToUse = `${order.departure.plannedDepartureTime}`;
            } else {
                dateToUse = order.tickets[0].article.validity.from;
            }
            return OrderStorageService.convertStringDateToDateWithTimezoneOffset(dateToUse);
        } catch (error) {
            console.error(error);
            return new Date();
        }
    }

    filterCurrentOrders(orders: OrderViewModel[]): OrderViewModel[] {
        let currentJourneys: OrderViewModel[] = [];
        const processingOrders = orders.filter(({ isProcessing }) => isProcessing);
        if (orders.length) {
            const now = new Date();
            currentJourneys = orders
                .filter((order) => !!order && !order.isProcessing)
                .map((order) => this.groomOrder(order))
                .filter((order) => {
                    if (order.isProcessing) {
                        return true;
                    }
                    const validTo = OrderStorageService.getValidToDateOfOrder(order);
                    const validFrom = OrderStorageService.getValidFromDateOfOrder(order);
                    const hasRefundedTicket = this.hasRefundedTicket(order.tickets);
                    return !hasRefundedTicket && now > validFrom && validTo > now;
                })
                .sort(OrderStorageService.sortJourneysByPurchaseDate);
        }
        return [...currentJourneys, ...processingOrders.sort(OrderStorageService.sortJourneysByPurchaseDate)];
    }

    filterPastOrders(orders: OrderViewModel[]): OrderViewModel[] {
        let pastJourneys: OrderViewModel[] = [];
        if (orders.length <= 0) {
            return [];
        }
        const now: Date = new Date();
        pastJourneys = orders
            .filter((order) => !!order && !order.isProcessing)
            .map((order) => this.groomOrder(order))
            .filter((order: OrderViewModel) => {
                const expiredDate = OrderStorageService.getExpiredDateOfOrder(order);
                const hasRefundedTicket = this.hasRefundedTicket(order.tickets);
                return !hasRefundedTicket && now >= expiredDate;
            })
            .map((order: OrderViewModel) => {
                if (order.tickets[0] && order.tickets[0].qrCodeData) {
                    order.tickets[0].qrCodeData = undefined;
                }
                return order;
            })
            .sort(OrderStorageService.sortJourneysByPurchaseDate);
        return pastJourneys;
    }

    filterFutureOrders(orders: OrderViewModel[]): OrderViewModel[] {
        let futureJourneys: OrderViewModel[] = [];
        if (orders.length) {
            const now = new Date();
            futureJourneys = orders
                .filter((order) => !!order && !order.isProcessing)
                .map((order) => this.groomOrder(order))
                .filter((order: OrderViewModel) => {
                    const validFrom = OrderStorageService.getValidFromDateOfOrder(order);
                    const hasRefundedTicket = this.hasRefundedTicket(order.tickets);
                    return !hasRefundedTicket && now < validFrom;
                })
                .sort(OrderStorageService.sortJourneysByPurchaseDate);
        }
        return futureJourneys;
    }

    filterCancelledOrders(orders: OrderViewModel[]): OrderViewModel[] {
        let cancelledJourneys: OrderViewModel[] = [];
        if (orders.length) {
            cancelledJourneys = orders
                .filter((order) => !!order && !order.isProcessing)
                .map((order) => this.groomOrder(order))
                .filter(({ tickets }: OrderViewModel) => {
                    return this.hasRefundedTicket(tickets);
                })
                .sort(OrderStorageService.sortJourneysByPurchaseDate);
        }
        return cancelledJourneys;
    }

    async clear(): Promise<void> {
        await this.storage.removeItem(ORDER_STORAGE_KEY);
    }

    async getStoredOrders(): Promise<OrderViewModel[]> {
        const storedOrders: OrderViewModel[] = (await this.storage.getItem(ORDER_STORAGE_KEY)) || [];
        storedOrders.forEach((order) => {
            if (order.tickets && order.tickets.length > 0) {
                order.tickets.forEach((ticket) => {
                    if (ticket.passenger) {
                        try {
                            ticket.passenger.birthDate = moment(ticket.passenger.birthDate).toDate();
                        } catch (error) {
                            console.error(error);
                        }
                    }
                });
            }
        });
        return storedOrders;
    }

    $getStoredOrder(): Observable<OrderViewModel[]> {
        return this.storage.$getItem(ORDER_STORAGE_KEY, []).pipe(
            map((storedOrders) =>
                storedOrders.map((order) => {
                    const groomedOrder = this.groomOrder(order);
                    return {
                        ...groomedOrder,
                        tickets: groomedOrder.tickets?.map((ticket) => ({
                            ...ticket,
                            passenger: this.parsePassenger(ticket.passenger),
                        })),
                    };
                }),
            ),
        );
    }

    async addOrderToStorage(order: OrderViewModel): Promise<void> {
        const orderStored = await this.getStoredOrders();
        const updatedTicketStore = [...orderStored, order];
        await this.storeOrders(updatedTicketStore);
    }

    async replaceOrderById(pOrder: OrderViewModel): Promise<void> {
        const stored = await this.getStoredOrders();
        const foundIndex = stored.findIndex((order) => order.id === pOrder.id);
        const updated = [...stored];
        if (foundIndex !== -1) {
            updated[foundIndex] = { ...pOrder };
        } else {
            console.warn(`Element to update was not found ${JSON.stringify(pOrder)}`);
        }
        await this.storeOrders(updated);
    }

    private hasRefundedTicket(tickets: Ticket[]): boolean {
        return tickets.some(({ isCancelled, refundDate }) => isCancelled && !!refundDate);
    }

    private parsePassenger(passenger: Passenger): Passenger {
        try {
            return {
                ...passenger,
                birthDate: moment(passenger.birthDate).toDate(),
            };
        } catch (error) {
            this.logger.logLocalError(error);
            return undefined;
        }
    }

    private groomOrder(order: OrderViewModel): OrderViewModel {
        const orderCopy = _.cloneDeep(order);

        if (order.isProcessing) {
            return orderCopy;
        }

        orderCopy.tickets
            .filter((ticket) => !!ticket.article)
            .forEach((ticket) => {
                if (ticket.passenger) {
                    try {
                        // parse the birthDate
                        ticket.passenger.birthDate = moment(ticket.passenger.birthDate).toDate();
                    } catch (error) {
                        this.logger.logLocalError(error, logOptionsFactory('warning'));
                    }
                }
            });

        orderCopy.tickets = this.patchTicketFromMiddleware(orderCopy);
        return orderCopy;
    }

    async storeOrders(tickets: OrderViewModel[] = []): Promise<void> {
        // having zero order is a valid transaction list from middleware and in this case
        // local storage must reflect that it has been initialized
        await this.storage.setItem(ORDER_STORAGE_KEY, tickets);
    }

    // transform locationValidity: string or locationsValidity: string[] to locationsValidity: {label: string, values: string[]}
    private patchLocationsValidity(ticket: Ticket): Ticket {
        if (ticket.article?.locationsValidity?.values || ticket.article?.locationsValidity?.label) {
            return ticket;
        }
        if (ticket.article && 'locationValidity' in ticket.article) {
            return {
                ...ticket,
                article: {
                    ...ticket.article,
                    locationsValidity: {
                        label: '',
                        values: [ticket.article['locationValidity'] as string],
                    },
                },
            };
        }
        if (Array.isArray(ticket.article?.locationsValidity)) {
            return {
                ...ticket,
                article: {
                    ...ticket.article,
                    locationsValidity: {
                        label: '',
                        values: [...(ticket.article?.locationsValidity as string[])],
                    },
                },
            };
        }
        return ticket;
    }

    private patchLocationsChoice(ticket: Ticket): Ticket {
        if (ticket.article?.locationsChoice?.values || ticket.article?.locationsChoice?.label) {
            return ticket;
        }
        if (ticket.article && !('locationsChoice' in ticket.article)) {
            return {
                ...ticket,
                article: {
                    ...(ticket.article as Article),
                    locationsChoice: {
                        label: '',
                        values: [],
                    },
                },
            };
        }
        return ticket;
    }

    // transform validTo: string, validFrom: string to validity: Validity
    private patchValidity(ticket: Ticket): Ticket {
        let validity: Validity;
        if (ticket.article.validity) {
            validity = ticket.article.validity;
        } else if ('validFrom' in ticket.article && 'validTo' in ticket.article) {
            validity = {
                from: ticket.article['validFrom'] as string,
                to: ticket.article['validTo'] as string,
            };
        }
        const article: Article = {
            ...ticket.article,
            validity,
        };
        return {
            ...ticket,
            article,
        };
    }

    // transform referenceNumber: string to referenceNumber: LocalizedOptionItem
    private patchReferenceNumber(ticket: Ticket): Ticket {
        let referenceNumber: LocalizedOptionItem;
        if (typeof ticket.referenceNumber === 'object') {
            referenceNumber = ticket.referenceNumber;
        } else {
            referenceNumber = {
                value: ticket['referenceNumber'],
            };
        }
        return {
            ...ticket,
            referenceNumber,
        };
    }

    // transform ticketNumber: string to ticketNumber: LocalizedOptionItem
    private patchTicketNumber(ticket: Ticket): Ticket {
        let ticketNumber: LocalizedOptionItem;
        if (typeof ticket.ticketNumber === 'object') {
            ticketNumber = ticket.ticketNumber;
        } else {
            ticketNumber = {
                value: ticket['ticketNumber'],
            };
        }
        return {
            ...ticket,
            ticketNumber,
        };
    }

    // transform articleNumber: string to articleNumber: LocalizedOptionItem
    private patchArticleNumber(ticket: Ticket): Ticket {
        let articleNumber: LocalizedOptionItem;
        if (typeof ticket.articleNumber === 'object') {
            articleNumber = ticket.articleNumber;
        } else {
            articleNumber = {
                value: ticket['articleNumber'],
            };
        }
        return {
            ...ticket,
            articleNumber,
        };
    }

    private patchTicketFromMiddleware(order?: OrderViewModel): Ticket[] {
        return order.tickets
            .map((ticket) => this.patchLocationsValidity(ticket))
            .map((ticket) => this.patchLocationsChoice(ticket))
            .map((ticket) => this.patchValidity(ticket))
            .map((ticket) => this.patchReferenceNumber(ticket))
            .map((ticket) => this.patchTicketNumber(ticket))
            .map((ticket) => this.patchArticleNumber(ticket));
    }
}
