import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { combineLatest, from, Observable } from 'rxjs';
import { environment } from '@traas/boldor/environments';
import { switchMap } from 'rxjs/operators';
import { CurrentUserSession } from '@traas/common/logging';
import { AuthenticationApi } from '@traas/common/feature-account';
import { DeviceIdService } from '@traas/boldor/common/http-interceptors/device-id.service';
import { CorrelationIdService } from '@traas/boldor/common/http-interceptors/correlation-id.service';

@Injectable()
export class RequestHeadersInterceptor implements HttpInterceptor {
    authService = inject(AuthenticationApi);
    deviceIdService = inject(DeviceIdService);
    correlationIdService = inject(CorrelationIdService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return combineLatest([this.authService.getAccessToken(), this.deviceIdService.getDeviceId()]).pipe(
            switchMap(([token, deviceId]) => {
                const customerId = CurrentUserSession.activeUser?.id;

                let headers = req.headers
                    .set('Client-Version', environment.clientVersion)
                    .set('x-correlation-id', this.correlationIdService.createCorrelationId());

                if (token) {
                    headers = headers.set('Authorization', `Bearer ${token}`);
                }
                if (customerId) {
                    headers = headers.set('x-customer-id', customerId);
                }
                if (deviceId) {
                    headers = headers.set('x-device-id', deviceId);
                }

                const modifiedReq = req.clone({ headers });
                return next.handle(modifiedReq);
            }),
        );
    }
}
