import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AreaHelper } from '@traas/boldor/all-helpers';
import {
    ActiveArea,
    DataNearPoint,
    Endpoint,
    GeopositionAdapter,
    isArrival,
    MapConfiguration,
    MapDisplayLevel,
    MapDisplayLevelConfiguration,
    MapMoveSource,
    PhysicalStopAdapter,
} from '@traas/boldor/all-models';
import { environment } from '@traas/boldor/environments';
import { HomeStoreState } from '../../home/store';
import { MapActions, MapSelectors } from '../../home/store/map';
import { EdgeMarker } from '../helper/edge-marker/edge-marker';
import { LayerBuilderService } from './layer-builder.service';
import { MapConfigurationService } from './map-configuration.service';
import { CompanyService } from '../../../services/common/company/company.service';
import { PhysicalStopService } from '../../../services/common/physical-stop/physical-stop.service';
import { convertToError, LoggingService, logOptionsFactory } from '@traas/common/logging';
import {
    CIRCLE_BOUNDS_CALCULATOR,
    COMMERCIAL_STOPS_LAYER,
    DebugLayerConfiguration,
    DebugLayerName,
    dynamicLayerName,
    EDGE_LAYER,
    getDebugLayerConfigurationBy,
    GPS_MARKER_LAYER,
    layerName,
    LeafletService,
    OnlineService,
    PHYSICAL_STOPS_LAYER,
    SMALL_COMMERCIAL_STOPS_LAYER,
    TRACKS_LAYER,
    trblFactory,
    TrblHelper,
} from '@traas/common/utils';
import * as L from 'leaflet';
import {
    bounds,
    Bounds,
    Circle,
    FeatureGroup,
    FitBoundsOptions,
    latLng,
    LatLng,
    LatLngBounds,
    LatLngExpression,
    Layer,
    LayerGroup,
    Marker,
    PanOptions,
    Point,
    PointTuple,
    Polygon,
    tileLayer,
} from 'leaflet';
import { includes } from 'lodash';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorCodes, isSuccess, Result, TechnicalError } from '@traas/common/models';

const PHYSICAL_STOPS_BBOX_FETCHING_BOUNDS_PAD = 0.025;
const SECURITY_OFFSET_FOR_BORDERLIMIT_STOPS = 0.025;
const SWITZERLAND_LAT_LNG_BOUNDS = new LatLngBounds([47.8012, 10.4666], [45.8207, 5.9548]);

const cannotRetrieveBoundsOfLeafletMap = 'Cannot retrieve bounds of Leaflet map';

@Injectable({ providedIn: 'root' })
export class MapService extends LeafletService {
    $gpsMarkerLatLng = new BehaviorSubject<LatLng | null>(null);
    private panOptions: PanOptions = {
        animate: true,
    };

    constructor(
        private store: Store<HomeStoreState.HomeState>,
        private physicalStopsService: PhysicalStopService,
        private logger: LoggingService,
        private mapConfigurationService: MapConfigurationService,
        private layerBuilder: LayerBuilderService,
        private onlineService: OnlineService,
    ) {
        super();
    }

    async getPhysicalStopsVisible(): Promise<PhysicalStopAdapter[]> {
        const boxBounds = await firstValueFrom(this.store.select(MapSelectors.getBounds));
        if (!boxBounds) {
            console.error(cannotRetrieveBoundsOfLeafletMap);
            return [];
        }
        const isOnline = await this.onlineService.isOnline();
        if (!isOnline) {
            return [];
        }
        const paddedBoxBounds = boxBounds.pad(PHYSICAL_STOPS_BBOX_FETCHING_BOUNDS_PAD);
        return await this.physicalStopsService.fetchPhysicalStopsInBBox(paddedBoxBounds);
    }

    /**
     * Depending on zoom level, we will not get the stops by same criteria.
     */
    getStopsInSafeAreaByCurrentZoomLevel(safeMapAreaBounds: LatLngBounds, stops: PhysicalStopAdapter[]): PhysicalStopAdapter[] {
        const mustUseCommercialStopLatLng = this.isLowZoomLevelOrMore(this.getZoom());
        return stops.filter((stop) => {
            if (mustUseCommercialStopLatLng) {
                const commercialStopLatLng = stop.getAssociatedCommercialStop()?.getLatLng();
                if (!commercialStopLatLng) {
                    console.warn('No commercial stop lat lng to check in safe area by zoom level');
                    return false;
                }
                return safeMapAreaBounds.contains(commercialStopLatLng);
            }
            return safeMapAreaBounds.contains(stop.getLatLng(environment.defaultPlace));
        });
    }

    getZoomLevel(): number {
        return this.getMap().getZoom();
    }

    getDisplayLevelConfigurationByCurrentZoom(): MapDisplayLevelConfiguration {
        const zoomLevel = this.getZoomLevel();
        return this.mapConfigurationService.getDisplayLevelConfigurationByZoom(zoomLevel);
    }

    updateLayersOnCurrentZoom(hasTooMuchCommercialStops: boolean): void {
        if (this.hasMap()) {
            const displayLevel = this.getDisplayLevelConfigurationByCurrentZoom();
            this.resetLayers();
            this.updateLayersByDisplayLevel(displayLevel, hasTooMuchCommercialStops);
        }
    }

    getMapConfiguration(center: LatLngExpression): MapConfiguration {
        return this.createDefaultMapConfiguration(center);
    }

    redrawDebugLayer(pBounds: LatLngBounds, name: DebugLayerName, circleRadius?: number): void {
        if (!environment.isDebugMode) {
            return;
        }
        const polygonConfiguration = getDebugLayerConfigurationBy(name);
        const mustDrawCircle = polygonConfiguration.name === DebugLayerName.GpsArea;
        let layer: Layer;
        if (mustDrawCircle) {
            layer = new Circle(pBounds.getCenter(), {
                radius: circleRadius,
                color: polygonConfiguration.color,
            });
        } else {
            layer = this.createPolygonFromBounds(pBounds, this.getMap(), polygonConfiguration.color);
        }
        layer.bindTooltip(polygonConfiguration.name.toString(), polygonConfiguration.tooltipOptions).openTooltip();
        this.setDebugLayer(polygonConfiguration, layer);
    }

    async fetchDataNearBoundsOfGpsMarker(): Promise<Result<DataNearPoint, TechnicalError>> {
        const defaultBoundsOfGpsMarker = this.createBoundsFromGpsMarker();
        if (!isSuccess(defaultBoundsOfGpsMarker)) {
            return {
                success: false,
                error: new TechnicalError(
                    `Can't fetch data near bounds of GPS marker`,
                    ErrorCodes.Map.Marker,
                    defaultBoundsOfGpsMarker.error,
                ),
            };
        }
        const centerOfGpsBounds = defaultBoundsOfGpsMarker.value.getCenter();
        const dataNearGps = await this.fetchDataNear(centerOfGpsBounds);
        return { success: true, value: dataNearGps };
    }

    fetchDataNear(latLng: LatLng): Promise<DataNearPoint> {
        if (!this.hasMap()) {
            throw new TechnicalError(`Can't fetch data near latlng because no map`, ErrorCodes.Map.Marker);
        }
        const $neighborhood = this.physicalStopsService.$getPhysicalStopsByLatLng(latLng).pipe(
            map((stopsAround) => {
                const circle = this.createCircleIncluding(
                    stopsAround.map((stop) => stop.getLatLng(environment.defaultPlace)),
                    latLng,
                );
                this.setLayer(CIRCLE_BOUNDS_CALCULATOR, circle);
                this.addLayerToMap(CIRCLE_BOUNDS_CALCULATOR);
                const latLngBounds = circle.getBounds().pad(SECURITY_OFFSET_FOR_BORDERLIMIT_STOPS);
                this.redrawDebugLayer(latLngBounds, DebugLayerName.GpsArea, circle.getRadius());
                return {
                    stops: stopsAround.map((stop) => stop.getData()),
                    bounds: latLngBounds,
                };
            }),
        );
        return firstValueFrom($neighborhood);
    }

    async fitBoundsOnGpsNearStops(): Promise<void> {
        const dataNearPoint = await this.fetchDataNearBoundsOfGpsMarker();
        if (!isSuccess(dataNearPoint)) {
            this.logger.logError(
                new TechnicalError(`Can't move map on gps near stops`, ErrorCodes.Map.MoveOnGpsPosition, dataNearPoint.error),
            );
            return;
        }
        this.fitBounds(dataNearPoint.value.bounds, { animate: true });
    }

    override fitBounds(
        latLngBounds: LatLngBounds | undefined | null,
        options: FitBoundsOptions = {},
    ): Result<LatLngBounds, TechnicalError> {
        if (latLngBounds && latLngBounds.isValid()) {
            const fitBoundsOptions = { ...this.getDefaultFitBoundsOptions(), ...options };
            super.fitBounds(latLngBounds, fitBoundsOptions);
        }
        const safeMapAreaBoundsResult = this.getSafeMapAreaBounds();
        if (!isSuccess(safeMapAreaBoundsResult)) {
            return {
                success: false,
                error: safeMapAreaBoundsResult.error,
            };
        }
        return {
            success: true,
            value: safeMapAreaBoundsResult.value,
        };
    }

    async emitBounds(source: MapMoveSource): Promise<void> {
        const nextMapBoundsResult = this.getSafeMapAreaBounds();
        if (!isSuccess(nextMapBoundsResult)) {
            this.logger.logError(nextMapBoundsResult.error);
            return;
        }

        const centerPoint = nextMapBoundsResult.value.getCenter();
        const zoomLevel = this.getZoomLevel();
        this.store.dispatch(
            new MapActions.Moved({
                source,
                bounds: nextMapBoundsResult.value,
                centerPoint,
                zoomLevel,
            }),
        );
    }

    /**
     * The bounds of the map without the endpoint fields
     */
    getSafeMapAreaBounds(): Result<LatLngBounds, TechnicalError> {
        try {
            const mapValue = this.getMap();
            const paneValues = this.getPanes();
            const isInvalidPaneValues = Array.isArray(paneValues) && !paneValues.length;
            if (isInvalidPaneValues) {
                throw new TechnicalError(`Invalid panes`, ErrorCodes.Map.GetBounds, undefined, {
                    paneValues,
                });
            }
            const safeArea = this.getSafeMapArea(true);
            const latLngBounds = new LatLngBounds(
                mapValue.layerPointToLatLng(safeArea.getBottomLeft()),
                mapValue.layerPointToLatLng(safeArea.getTopRight()),
            );
            return {
                success: true,
                value: latLngBounds,
            };
        } catch (error) {
            const technicalError = new TechnicalError(`Cannot get safe map area bounds`, ErrorCodes.Map.GetBounds, convertToError(error), {
                hasMap: this.hasMap(),
            });
            return {
                success: false,
                error: technicalError,
            };
        }
    }

    getGpsMarker(): Result<Marker, TechnicalError> {
        try {
            const gpsMarkerLayer = this.getLayerUsingName(GPS_MARKER_LAYER);
            if (!gpsMarkerLayer) {
                throw new Error('No GPS layer found.');
            }
            return {
                success: true,
                value: gpsMarkerLayer as Marker,
            };
        } catch (error) {
            return {
                error: new TechnicalError('No GPS marker found.', ErrorCodes.Map.MissingLayer, convertToError(error)),
                success: false,
            };
        }
    }

    setTracksLayer(layer: Layer): void {
        this.setLayer(TRACKS_LAYER, layer);
    }

    setCommercialStopsLayer(layer: Layer): void {
        this.setLayer(COMMERCIAL_STOPS_LAYER, layer);
    }

    setSmallCommercialStopsLayer(layer: Layer): void {
        this.setLayer(SMALL_COMMERCIAL_STOPS_LAYER, layer);
    }

    setPhysicalStopsLayer(layer: Layer | LayerGroup): void {
        this.setLayer(PHYSICAL_STOPS_LAYER, layer);
    }

    createBoundsFromGpsMarker(): Result<LatLngBounds, TechnicalError> {
        const marker = this.getGpsMarker();
        if (!isSuccess(marker)) {
            return { error: marker.error, success: false };
        }
        const latLngBoundsResult = this.getLatLngBoundsFromMarker(marker.value);
        if (!isSuccess(latLngBoundsResult)) {
            return { error: latLngBoundsResult.error, success: false };
        }
        return { success: true, value: latLngBoundsResult.value };
    }

    setDisplayingOfTracksLayerBy(activeArea: ActiveArea): void {
        try {
            if (isArrival(activeArea.endpoint)) {
                this.removeLayerFromMap(TRACKS_LAYER);
            } else if (AreaHelper.hasPhysicalStops(activeArea.area)) {
                this.addLayerToMap(TRACKS_LAYER);
            } else {
                this.removeLayerFromMap(TRACKS_LAYER);
            }
        } catch (error) {
            this.logger.logLocalError(error, logOptionsFactory('info'));
        }
    }

    updateEdgeMarkers(): void {
        if (!this.hasMap()) {
            console.error('Cannot retrieve instance of Leaflet map');
            return;
        }

        if (!this.getMap().getBounds()) {
            console.error(cannotRetrieveBoundsOfLeafletMap);
            return;
        }

        if (this.isLayerOnMap(TRACKS_LAYER)) {
            this.setTrackMarkersVisibility();
            this.setLayer(EDGE_LAYER, this.buildEdgeLayer());
            this.addLayerToMap(EDGE_LAYER);
        }
    }

    clearEdgeLayer(): void {
        this.clearLayers(EDGE_LAYER);
    }

    clearTracksLayer(): void {
        this.clearLayers(TRACKS_LAYER);
    }

    clearPhysicalStopsLayer(): void {
        this.clearLayers(PHYSICAL_STOPS_LAYER);
    }

    createBoundsFromLatLngArray(latLngArray: LatLng[]): LatLngBounds | null {
        const localBounds = L.latLngBounds(latLngArray);
        if (latLngArray?.length > 0) {
            return localBounds;
        }
        console.warn(`No latlng found to build a bounds.`);
        return null;
    }

    createBoundsFromPhysicalStops(physicalStopAdapters: PhysicalStopAdapter[]): LatLngBounds | null {
        if (physicalStopAdapters?.length === 0) {
            console.warn(`No stops found to build a bounds.`);
            return null;
        }
        const localBounds = this.createBoundsFromLatLngArray(physicalStopAdapters.map((stop) => stop.getLatLng(environment.defaultPlace)));
        if (localBounds) {
            return localBounds.pad(PHYSICAL_STOPS_BBOX_FETCHING_BOUNDS_PAD);
        }
        return null;
    }

    setLatLngOfGpsPositionMarker(geoposition: GeopositionAdapter): void {
        try {
            const gpsMarker = this.getGpsMarker();
            if (!isSuccess(gpsMarker)) {
                return;
            }
            const newLatLng = latLng(geoposition.latitude, geoposition.longitude);
            gpsMarker.value.setLatLng(newLatLng);
            this.$gpsMarkerLatLng.next(newLatLng);
        } catch (error) {
            this.logger.logLocalError(error);
        }
    }

    panSafeAreaTo(target: LatLng | LatLngExpression): void {
        const safeMapAreaBoundsResult = this.getSafeMapAreaBounds();
        if (!isSuccess(safeMapAreaBoundsResult)) {
            this.logger.logError(safeMapAreaBoundsResult.error);
            return;
        }

        const safeZoneCenter = safeMapAreaBoundsResult.value.getCenter();
        const aMap: L.Map = this.getMap();
        const zoom = aMap.getZoom();
        const safeZoneCenterInPx = aMap.project(safeZoneCenter, zoom);
        const targetInPx = aMap.project(target, zoom);
        const offsetInPx = targetInPx.subtract(safeZoneCenterInPx);
        aMap.panBy(offsetInPx, this.panOptions);
    }

    flyTo(latLng: LatLng, zoom: number, panOptions = this.panOptions): void {
        this.getMap().flyTo(latLng, zoom, { ...panOptions, duration: 0.1 });
    }

    override setMap(map: L.Map): void {
        super.setMap(map);
        if (environment.isDebugMode) {
            this.initDebugLayers();
            this.initDebugLayersControl();
        }
    }

    async addFilteredPhysicalStopsInSafeArea(
        physicalStopsVisible: PhysicalStopAdapter[],
    ): Promise<{ all: PhysicalStopAdapter[]; inSafeArea: PhysicalStopAdapter[] }> {
        const safeMapAreaBoundsResult = this.getSafeMapAreaBounds();
        if (!isSuccess(safeMapAreaBoundsResult)) {
            this.logger.logError(safeMapAreaBoundsResult.error);
            return { all: physicalStopsVisible, inSafeArea: [] };
        }
        const inSafeArea = this.getStopsInSafeAreaByCurrentZoomLevel(safeMapAreaBoundsResult.value, physicalStopsVisible);
        return { all: physicalStopsVisible, inSafeArea };
    }

    isValidZoomToShowItineraryPathDetailedLayers(): boolean {
        let zoomLimit;
        if (CompanyService.isTPG()) {
            zoomLimit = this.mapConfigurationService.getZoomLimitDependingOfTPGBy(MapDisplayLevel.TOO_HIGH);
        } else {
            zoomLimit = this.mapConfigurationService.getZoomLimitDependingOfTraasBy(MapDisplayLevel.HIGH);
        }
        return this.getZoom() > zoomLimit;
    }

    getCenterOf(coordinates: LatLng | LatLng[]): LatLng {
        if (Array.isArray(coordinates)) {
            return this.createBoundsFromLatLngArray(coordinates).getCenter();
        }
        return this.createBoundsFromLatLng(coordinates, 0).getCenter();
    }

    clearEndpointsLayers(): void {
        const departureLayerName: dynamicLayerName = this.layerBuilder.getDynamicLayerNameByEndpoint(Endpoint.Departure);
        const arrivalLayerName: dynamicLayerName = this.layerBuilder.getDynamicLayerNameByEndpoint(Endpoint.Arrival);
        this.deleteLayersUsingName([departureLayerName, arrivalLayerName]);
    }

    private clearLayers(name: layerName): void {
        const layer = this.getLayerUsingName(name) as FeatureGroup;
        if (!layer) return;
        layer.clearLayers();
    }

    private createPolygonFromBounds(latLngBounds: LatLngBounds, lmap: L.Map, color = 'red'): Polygon {
        const center = latLngBounds.getCenter();
        const latlngs = [];

        latlngs.push(latLngBounds.getSouthWest()); // bottom left
        latlngs.push(latLngBounds.getSouthEast()); // bottom right
        latlngs.push({ lat: center.lat, lng: latLngBounds.getEast() }); // center right
        latlngs.push(latLngBounds.getNorthEast()); // top right
        latlngs.push(latLngBounds.getNorthWest()); // top left

        return new Polygon(latlngs, { color });
    }

    private isLowZoomLevelOrMore(zoomLevel: number): boolean {
        const lowZoomDisplayLevelConfig = this.mapConfigurationService.getMapDisplayLevelsConfiguration().LOW;
        return zoomLevel <= lowZoomDisplayLevelConfig.zoomLimit;
    }

    private setDebugLayer(polygonConfiguration: DebugLayerConfiguration, layer: Layer): void {
        this.debugLayers[polygonConfiguration.name].clearLayers();
        this.debugLayers[polygonConfiguration.name].addLayer(layer);
    }

    private getDefaultFitBoundsOptions(): FitBoundsOptions {
        // use the safe area to fit the bounds
        const paddingTopLeft: PointTuple = [0, this.getSafeAreaTop()];
        return { paddingTopLeft };
    }

    private setTrackMarkersVisibility(): void {
        this.getTrackMarkers().forEach((aMarker) => {
            aMarker.setOpacity(1);
        });
        this.getTrackMarkersOutsizeArea().forEach((aMarker) => aMarker.setOpacity(0));
    }

    private buildEdgeLayer(): FeatureGroup<any> {
        const trackMarkersOutsideBox: Layer[] = this.getTrackMarkersOutsizeArea();
        const edgeMarkerLayer: FeatureGroup<any> = this.layerBuilder.buildEdgeMarkerLayer(
            trackMarkersOutsideBox,
            this.getMap(),
            this.getSafeMapArea(),
        );
        const markers: Layer[] = edgeMarkerLayer.getLayers();
        markers.forEach((marker) => this.addCenterMapOnClickListener(marker));
        return edgeMarkerLayer;
    }

    private addCenterMapOnClickListener(marker: Layer): void {
        if (marker instanceof EdgeMarker) {
            marker.on('click', () => {
                const originalMarker = marker.getOriginalMarker();
                const originalMarkerLocation: LatLng = originalMarker.getLatLng();
                this.panSafeAreaTo(originalMarkerLocation);
            });
        }
    }

    private resetLayers(): void {
        const departureLayerName: dynamicLayerName = this.layerBuilder.getDynamicLayerNameByEndpoint(Endpoint.Departure);
        const arrivalLayerName: dynamicLayerName = this.layerBuilder.getDynamicLayerNameByEndpoint(Endpoint.Arrival);
        const allLayersNames: layerName[] = this.getAllLayersNames();
        const excludedLayers: layerName[] = [GPS_MARKER_LAYER, CIRCLE_BOUNDS_CALCULATOR, departureLayerName, arrivalLayerName];
        const layersNamesToDelete: layerName[] = allLayersNames.filter((name: string) => !includes(excludedLayers, name));
        this.removeLayersFromMap(layersNamesToDelete);
    }

    private updateLayersByDisplayLevel(displayLevel: MapDisplayLevelConfiguration, hasTooMuchCommercialStops: boolean): void {
        const layers: string[] = displayLevel.getLayersAssociated(hasTooMuchCommercialStops);
        layers.forEach((layer: layerName) => this.addLayerToMap(layer));
    }

    private getTrackMarkers(): Marker[] {
        return this.getTrackLayer().getLayers() as Marker[];
    }

    private getTrackLayer(): LayerGroup {
        return this.getLayerUsingName(TRACKS_LAYER) as LayerGroup;
    }

    private getTrackMarkersOutsizeArea(): Marker[] {
        return this.filterMarkersOutsideBounds(this.getTrackMarkers(), this.getSafeMarkerArea());
    }

    /**
     * The bounds of the map without the endpoint fields
     */
    private getSafeMapArea(fromCurrentMapBbox = false): Bounds {
        const safeAreaPadding: TrblHelper<number> = trblFactory<number>(this.getSafeAreaTop(), 0, 0, 0);
        return this.getMapPaddedBounds(safeAreaPadding, fromCurrentMapBbox);
    }

    /**
     * The bounds of the map where the markers with their flags can be safely drawn
     */
    private getSafeMarkerArea(): Bounds {
        const markerHeightInPx = 50;
        const markerBound: TrblHelper<number> = trblFactory(markerHeightInPx, 0, 0, 0);
        return this.subtractPaddingFromBounds(this.getSafeMapArea(), markerBound);
    }

    /**
     * Pseudo hack: We use the app-endpoints HTMLElement to get the safe area of the map
     * Basically the returned value is the y offset of the bottom of the lowest location input field on the map.
     */
    private getSafeAreaTop(): number {
        const endpoints: Element = document.getElementsByTagName('app-endpoints').item(0);
        if (!endpoints) {
            return 0;
        }
        const endpointsRect: DOMRect = endpoints.getBoundingClientRect();
        const { y, height } = endpointsRect;
        return y + height;
    }

    /**
     * Negative padding values will extend the area, positive values contract
     */
    private subtractPaddingFromBounds(area: Bounds, { top, bottom, right, left }: TrblHelper): Bounds {
        const areaTopLeft = area.min as Point;
        const areaBottomRight = area.max as Point;
        const upperLeftPadding = new Point(left, top);
        const bottomRightPadding = new Point(right, bottom);
        return bounds(areaTopLeft.add(upperLeftPadding), areaBottomRight.subtract(bottomRightPadding));
    }

    private filterMarkersOutsideBounds(markers: Marker[], hiddenMarkerArea: Bounds): Marker[] {
        return markers.filter((aMarker: Marker) => {
            const markerPixelPoint: Point = this.getMap().latLngToContainerPoint(aMarker.getLatLng());
            return !hiddenMarkerArea.contains(markerPixelPoint);
        });
    }

    private getMapPaddedBounds(padding: TrblHelper, fromCurrentMapBbox: boolean): Bounds {
        let topLeft: Point;
        let bottomRight: Point;
        if (fromCurrentMapBbox) {
            const mapBoundsValue = this.getBounds();
            topLeft = this.getMap().latLngToLayerPoint(mapBoundsValue.getNorthWest());
            bottomRight = this.getMap().latLngToLayerPoint(mapBoundsValue.getSouthEast());
        } else {
            topLeft = new Point(0, 0);
            bottomRight = this.getMap().getSize();
        }
        const mapBounds: Bounds = new Bounds(topLeft, bottomRight);
        return this.subtractPaddingFromBounds(mapBounds, padding);
    }

    private createDefaultMapConfiguration(center: LatLngExpression): MapConfiguration {
        return {
            mapOptions: {
                center,
                layers: [
                    tileLayer(environment.mapTilerUrl, {
                        maxZoom: 20,
                        minZoom: 2, // We must set a minZoom because of negative values if the map is too dezoomed.
                        attribution: '...',
                        tileSize: 512,
                        zoomOffset: -1, // This is mandatory for some reason, otherwise, the map is blank
                    }),
                ],
                zoom: 17,
                zoomControl: false,
                attributionControl: false,
                zoomSnap: 0,
            },
        };
    }
}
