import { NgZone } from '@angular/core';
import { Observable } from 'rxjs';

export function enterZoneHelper(zone: NgZone): <T>(source: Observable<T>) => Observable<T> {
    return <T>(source: Observable<T>) =>
        new Observable<T>((observer) =>
            source.subscribe({
                next: (x) => zone.run(() => observer.next(x)),
                error: (error) => observer.error(error),
                complete: () => observer.complete(),
            }),
        );
}
