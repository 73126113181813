import { Currency, Price, PriceInCents, Reduction, ReductionType, TravelClass, TravelClassType } from '@traas/boldor/all-models';
import * as Gql from '@traas/boldor/graphql-generated/graphql';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

/** uncomment this when we will not have webpack anymore
 // export itself
 export * as GqlToService from './gql-to-service.converter';
 */

export function currency(value: Gql.Currency): Currency {
    switch (value) {
        case Gql.Currency.Chf:
            return Currency.CHF;
        case Gql.Currency.Eur:
            return Currency.EUR;
        default:
            console.warn(`Unknown Gql.Currency: ${value}`);
            return Currency.CHF;
    }
}

function travelClassType(value: Gql.TravelClassType): TravelClassType {
    switch (value) {
        case Gql.TravelClassType.Class_1:
            return TravelClassType.CLASS_1;
        case Gql.TravelClassType.Class_2:
            return TravelClassType.CLASS_2;
        case Gql.TravelClassType.ClassChange:
            return TravelClassType.CLASS_CHANGE;
    }
}

export function travelClass(value: Gql.TravelClass): TravelClass {
    return {
        id: travelClassType(value.id),
        description: value.description ?? undefined,
    };
}

export function reductionType(value?: Gql.Maybe<Gql.ReductionType>): ReductionType {
    if (!value) {
        return ReductionType.UNKNOWN;
    }
    switch (value) {
        case Gql.ReductionType.NoReduction:
            return ReductionType.NO_REDUCTION;
        case Gql.ReductionType.HalfFare:
            return ReductionType.HALF_FARE;
        case Gql.ReductionType.Bike:
            return ReductionType.BIKE;
        case Gql.ReductionType.Dog:
            return ReductionType.DOG;
        case Gql.ReductionType.Unknown:
            return ReductionType.UNKNOWN;
        default:
            console.warn(`Unknown Gql.ReductionType: ${value}`);
            return ReductionType.NO_REDUCTION;
    }
}

export function reduction(gqlReduction: Gql.Reduction): Reduction {
    if (gqlReduction) {
        return {
            id: reductionType(gqlReduction.id),
            description: gqlReduction.description,
        };
    }
    console.warn(`falsy reduction GQL (${gqlReduction}) is not a valid value, use fallback.`);
    return {
        id: ReductionType.NO_REDUCTION,
        description: '',
    };
}

export function priceInCents(value: Gql.Price): PriceInCents {
    if (!value?.currency) {
        throw new TechnicalError('No currency to get priceInCents', ErrorCodes.Graphql.Mapping);
    }
    if (value?.amountInCents === undefined || value?.amountInCents === null) {
        throw new TechnicalError('No amountInCents to get priceInCents', ErrorCodes.Graphql.Mapping);
    }
    return {
        currency: currency(value.currency),
        amountInCents: value.amountInCents,
    };
}

export function price(value: Gql.Price): Price {
    const _priceInCents = priceInCents(value);
    return {
        currency: _priceInCents.currency,
        value: `${_priceInCents.amountInCents}`,
    };
}
