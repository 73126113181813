import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyService } from '../../../../services/common/company/company.service';
import { LineService } from '../../../../services/common/line/line.service';
import { LegAdapter } from '../../../../models/itinerary/leg';
import { StopAdapter } from '../../../../models/itinerary/stop';
import { head, last } from 'lodash';
import { LineStyle } from '@traas/boldor/all-models';
import { isTrain } from '../../../../business-rules.utils';

@Component({
    selector: 'app-itinerary-leg-stop-list',
    templateUrl: './itinerary-leg-stop-list-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryLegStopListContainerComponent implements OnInit {
    @Input() legAdapter: LegAdapter;

    @Output() arrivalClicked = new EventEmitter<StopAdapter>();
    @Output() departureClicked = new EventEmitter<StopAdapter>();
    @Output() transportClicked = new EventEmitter<StopAdapter>();

    lineStyle: LineStyle;
    networkId: string;
    stopsAdapter: StopAdapter[];

    ngOnInit(): void {
        this.lineStyle = LineService.from(this.legAdapter);
        this.networkId = this.legAdapter.getLineAdapter().getNetworkId();
        this.stopsAdapter = this.legAdapter
            .getStops()
            .map((stop) => new StopAdapter(stop))
            .filter((stop) => {
                if (CompanyService.isTPC()) {
                    return stop.getArrivalTime() !== '';
                }
                return true;
            });
    }

    onItemClicked(clickedStop: StopAdapter): void {
        if (clickedStop === head(this.stopsAdapter)) {
            return this.departureClicked.emit(clickedStop);
        }
        if (clickedStop === last(this.stopsAdapter)) {
            return this.arrivalClicked.emit(clickedStop);
        }
        return this.transportClicked.emit(clickedStop);
    }

    isTrain(transportName: string): boolean {
        return isTrain(transportName);
    }
}
