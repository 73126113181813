import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TosModule } from '@traas/boldor/common/features/tos/tos.module';
import { PageTitleModule } from '@traas/boldor/common/components/page-title/page-title.module';
import { TravysDisplayConditionsOfSalePageComponent } from './travys-display-conditions-of-sale-page.component';
import { TravysConditionsOfSaleModule } from '../../components/travys-conditions-of-sale.module';
import { BoldorFeatureShellSharedModule } from '@traas/boldor/common/boldor-feature-shell-shared.module';

const routes: Routes = [
    {
        path: '',
        component: TravysDisplayConditionsOfSalePageComponent,
    },
];

@NgModule({
    declarations: [TravysDisplayConditionsOfSalePageComponent],
    imports: [BoldorFeatureShellSharedModule, PageTitleModule, TosModule, TravysConditionsOfSaleModule, RouterModule.forChild(routes)],
})
export class TravysDisplayConditionsOfSalePageModule {}
