import { Injectable } from '@angular/core';
import { ConfigurationStorage, PaymentMethodsConfiguration, RefreshIntervals, ThresholdConfiguration } from '@traas/boldor/all-models';
import {
    GetPaymentMethodsGQL,
    GetPaymentMethodsQueryVariables,
    GetRefreshIntervalsGQL,
    GetThresholdsGQL,
} from '@traas/boldor/graphql-generated/graphql';
import { CreditCardsService } from '../../../features/credit-cards/services/credit-cards.service';
import { ClientPlatformConverter } from '../converters/client-platform.converter';
import { PreferencesService } from '@traas/common/feature-account';
import { ObservableTypedStorage, PlatformUtilsService } from '@traas/common/utils';
import { firstValueFrom } from 'rxjs';
import { environment } from '@traas/boldor/environments';

@Injectable()
export class ConfigurationService {
    constructor(
        private getRefreshIntervalsGQL: GetRefreshIntervalsGQL,
        private getThresholdsGQL: GetThresholdsGQL,
        private getPaymentMethodsGQL: GetPaymentMethodsGQL,
        private configurationStorage: ObservableTypedStorage<ConfigurationStorage>,
        private preferencesService: PreferencesService,
        private platformUtilsService: PlatformUtilsService,
    ) {}

    private static areRefreshIntervalsInitalized(localRefreshIntervals: RefreshIntervals | null): boolean {
        return (
            !!localRefreshIntervals?.itineraryRefreshIntervalInSeconds ||
            !!localRefreshIntervals?.departureRefreshIntervalInSeconds ||
            !!localRefreshIntervals?.autoScrollRefreshIntervalInSeconds
        );
    }

    private static areThesholdsInitalized(localThresholdConfiguration: ThresholdConfiguration | null): boolean {
        return (
            !!localThresholdConfiguration?.minimumThresholdToShowIconInSeconds ||
            !!localThresholdConfiguration?.minutesThresholdToDisplayWaitingMinutes
        );
    }

    private static areRefreshIntervalsDifferent(
        localRefreshIntervals: RefreshIntervals | null,
        refreshIntervals: RefreshIntervals,
    ): boolean {
        return (
            localRefreshIntervals?.itineraryRefreshIntervalInSeconds !== refreshIntervals?.itineraryRefreshIntervalInSeconds ||
            localRefreshIntervals?.departureRefreshIntervalInSeconds !== refreshIntervals?.departureRefreshIntervalInSeconds ||
            localRefreshIntervals?.autoScrollRefreshIntervalInSeconds !== refreshIntervals?.autoScrollRefreshIntervalInSeconds
        );
    }

    private static areThresholdsDifferent(
        localThresholdConfiguration: ThresholdConfiguration | null,
        thresholdConfiguration: ThresholdConfiguration,
    ): boolean {
        return (
            localThresholdConfiguration?.minutesThresholdToDisplayWaitingMinutes !==
                thresholdConfiguration?.minutesThresholdToDisplayWaitingMinutes ||
            localThresholdConfiguration?.minimumThresholdToShowIconInSeconds !== thresholdConfiguration?.minimumThresholdToShowIconInSeconds
        );
    }

    async initConfigurationIfNeeded(): Promise<void> {
        await Promise.all([this.initRefreshIntervalsConfigurationIfNeeded(), this.initThresholdsConfigurationIfNeeded()]);
    }

    async getPaymentMethods(): Promise<PaymentMethodsConfiguration> {
        const currency = await this.preferencesService.getCurrency();
        const variable: GetPaymentMethodsQueryVariables = {
            currency: CreditCardsService.convertCurrency(currency),
            clientPlatform: ClientPlatformConverter.toGql(this.platformUtilsService.isIos()),
        };
        const { data } = await firstValueFrom(this.getPaymentMethodsGQL.fetch(variable));
        const paymentMethods = data.configuration?.paymentMethods;
        if (!paymentMethods) {
            return {
                cards: [],
                mobiles: [],
            };
        }
        return paymentMethods;
    }

    shouldShowDateItemInJourneysList(): boolean {
        return environment.features?.showDateItemInJourneysList ?? false;
    }

    private async initRefreshIntervalsConfigurationIfNeeded(): Promise<void> {
        const localRefreshIntervals = await this.configurationStorage.getItem('refreshIntervals');
        const refreshIntervals = await this.getRefreshIntervals();

        if (
            !ConfigurationService.areRefreshIntervalsInitalized(localRefreshIntervals) ||
            ConfigurationService.areRefreshIntervalsDifferent(localRefreshIntervals, refreshIntervals)
        ) {
            await this.configurationStorage.setItem('refreshIntervals', refreshIntervals);
        }
    }

    private async initThresholdsConfigurationIfNeeded(): Promise<void> {
        const localThresholdsIntervals = await this.configurationStorage.getItem('thresholds');
        const thresholds = await this.getThresholds();

        if (
            !ConfigurationService.areThesholdsInitalized(localThresholdsIntervals) ||
            ConfigurationService.areThresholdsDifferent(localThresholdsIntervals, thresholds)
        ) {
            await this.configurationStorage.setItem('thresholds', thresholds);
        }
    }

    private async getRefreshIntervals(): Promise<RefreshIntervals> {
        const { data } = await firstValueFrom(this.getRefreshIntervalsGQL.fetch());
        const refreshIntervals = data.configuration?.refreshIntervals;
        if (!refreshIntervals) {
            return {
                departureRefreshIntervalInSeconds: 10,
                itineraryRefreshIntervalInSeconds: 20,
                autoScrollRefreshIntervalInSeconds: 30,
            };
        }
        return refreshIntervals;
    }

    private async getThresholds(): Promise<ThresholdConfiguration> {
        const { data } = await firstValueFrom(this.getThresholdsGQL.fetch());
        const thresholds = data.configuration?.thresholds;
        if (!thresholds) {
            return {
                minimumThresholdToShowIconInSeconds: 60,
                minutesThresholdToDisplayWaitingMinutes: 60,
            };
        }
        return thresholds;
    }
}
