import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MvplusDragModule } from '../../directives/mvplus-drag/mvplus-drag.module';
import { MvplusDropModule } from '../../directives/mvplus-drag/mvplus-drop.module';
import { LinkableComponent } from './linkable/linkable.component';

// import { LongPressModule } from 'ionic-long-press';

@NgModule({
    imports: [CommonModule, IonicModule, MvplusDragModule, MvplusDropModule /* LongPressModule */],
    declarations: [LinkableComponent],
    exports: [LinkableComponent],
})
export class GridModule {}
