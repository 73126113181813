import { Injectable } from '@angular/core';
import { AnalyticsService } from '@traas/common/analytics';
import { RouteUrl } from '@traas/common/routing';
import { StartupNotificationContainerComponent } from '../containers/startup-notification/startup-notification-container.component';
import { MessageViewModel } from '../models/message-view-model';
import { StartupNotificationService } from '../services/startup-notification.service';
import { StartupNotificationActions, StartupNotificationSelectors } from './index';
import {
    AcknowledgeMessage,
    LoadSuccess,
    PresentAllMessages,
    PresentMessage,
    StartupNotificationAction,
    StartupNotificationActionTypes,
} from './startup-notification-action';
import { StartupNotificationState } from './startup-notification-state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoggingService } from '@traas/common/logging';
import { ModalService } from '../../../services/common/modal.service';
import { stripUrl } from '@traas/boldor/all-helpers';
import { EMPTY, of } from 'rxjs';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { RouterSelectors } from '../../../router-store';
import { RouterState } from '../../../router-store/state';
import { isSuccess } from '@traas/common/models';

@Injectable()
export class StartupNotificationEffect {
    $load = createEffect(() =>
        this.$actions.pipe(
            ofType(StartupNotificationActionTypes.Load),
            switchMap(async () => {
                const result = await this.messageLauncherService.getPendingMessages();
                if (isSuccess(result)) {
                    return new StartupNotificationActions.LoadSuccess(result.value);
                } else {
                    this.logger.logError(result.error);
                    return new StartupNotificationActions.LoadFail();
                }
            }),
        ),
    );

    $loadSuccess = createEffect(() =>
        this.$actions.pipe(
            ofType<LoadSuccess>(StartupNotificationActionTypes.LoadSuccess),
            mergeMap(({ payload }) => {
                if (payload.length > 0) {
                    return of(new StartupNotificationActions.PresentAllMessages());
                }
                return EMPTY;
            }),
        ),
    );

    $presentAllMessages = createEffect(() =>
        this.$actions.pipe(
            ofType<PresentAllMessages>(StartupNotificationActionTypes.PresentAllMessages),
            withLatestFrom(this.store.select(StartupNotificationSelectors.getPendingMessages), (payload, pending) => pending),
            map((pending) => {
                return new StartupNotificationActions.PresentMessage(pending.shift() as MessageViewModel);
            }),
        ),
    );

    $acknowledgeMessage = createEffect(
        () =>
            this.$actions.pipe(
                ofType<AcknowledgeMessage>(StartupNotificationActionTypes.AcknowledgeMessage),
                map(async ({ payload }) => {
                    await this.messageLauncherService.acknowledgeMessage(payload.id);
                }),
            ),
        { dispatch: false },
    );

    $presentMessage = createEffect(() =>
        this.$actions.pipe(
            ofType<PresentMessage>(StartupNotificationActionTypes.PresentMessage),
            withLatestFrom(
                this.store.select(StartupNotificationSelectors.getPendingMessages),
                this.store.select(RouterSelectors.selectUrl),
                ({ payload }, pending, currentUrl) => ({ payload, pending, currentUrl }),
            ),
            switchMap(async ({ payload, pending, currentUrl }) => {
                this.analyticsService.reportPageView(stripUrl(RouteUrl.startupNotificationUrl, true));
                const isAcknowledged = await this.modalService.presentModal<boolean>(
                    {
                        component: StartupNotificationContainerComponent,
                        keyboardClose: false,
                        backdropDismiss: false,
                    },
                    'startupNotification',
                );
                return { payload, pending, isAcknowledged, currentUrl };
            }),
            switchMap(({ payload, pending, isAcknowledged, currentUrl }) => {
                const actions: StartupNotificationAction[] = [];
                if (isAcknowledged) {
                    actions.push(new StartupNotificationActions.AcknowledgeMessage(payload));
                    this.analyticsService.reportEvent('startup_notification__acknowledge');
                } else {
                    actions.push(new StartupNotificationActions.PostponeMessage(payload));
                    this.analyticsService.reportEvent('startup_notification__postpone');
                }
                if (pending.length > 0) {
                    const nextMessage = pending.shift();
                    if (nextMessage) {
                        actions.push(new StartupNotificationActions.PresentMessage(nextMessage));
                    }
                } else {
                    this.analyticsService.reportPageView(stripUrl(currentUrl, true));
                    actions.push(new StartupNotificationActions.Done());
                }
                return actions;
            }),
        ),
    );

    constructor(
        private $actions: Actions,
        private logger: LoggingService,
        private messageLauncherService: StartupNotificationService,
        private modalService: ModalService,
        private analyticsService: AnalyticsService,
        private store: Store<StartupNotificationState | RouterState>,
    ) {}
}
