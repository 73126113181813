<ng-container *ngIf="linesToShow as lines">
    <ng-container *ngIf="isStartByFoot">
        <div class="walk-icon">
            <ion-icon src="assets/icons/walk.svg"></ion-icon>
        </div>
        <div class="walk-info">
            <div class="walk-duration">{{ delayOfWalkingLegInMinutes }}'</div>
            <div class="dashed-duration">...</div>
        </div>
    </ng-container>
    <span
        *ngFor="let line of lines; trackBy: trackById; let i = index; let isLast = last"
        [ngClass]="getCompanyFromNetworkId(line.networkId)"
        [class.join-right]="hasRightJoin(i, isLast)"
        [class.dashed-join-right]="hasRightDashedJoin(i)"
    >
        <app-line-icon [line]="line"></app-line-icon>
    </span>
</ng-container>
