<div class="container" #container>
    <ion-row class="departure" [class.cancelled]="departureStop.isCancelled()" (click)="onDepartureClicked(departureStop)">
        <div class="departure-row">
            <div class="flex-item grow departure-infos">
                <div class="line-track">
                    <app-track-icon [track]="track"></app-track-icon>
                </div>
                <div class="booking-icon">
                    <ion-icon #legPathStart [class.tpg]="isTpg" [src]="departureThermoStopIcon"></ion-icon>
                </div>

                <app-warning-icon *ngIf="hasDisruptionOnDeparture" (click)="onWarningClicked($event, departureStop)"></app-warning-icon>

                <div class="stop-name e2e-departure-stop-name">
                    {{ departureStop | stopName }}
                </div>
            </div>
            <div class="flex-item no-grow departure-stop">
                <div class="options" *ngIf="isTPC">
                    <ion-icon [src]="departureStopIconPathItinerary"></ion-icon>
                </div>

                <div class="time">{{ leg.getScheduledDepartureDate() | date : 'shortTime' }}</div>
                <div class="delay">{{ leg.getDepartureDelay() }}</div>
            </div>

            <ng-container *ngIf="leg.getFirstStop().isCancelled">
                <div class="flex-item full-width cancelled-message-container">
                    <div class="stop-name cancelled-message">
                        <traas-cancelled-trip-icon [isBus]="false"></traas-cancelled-trip-icon>
                        <span [innerHTML]="cancellationMessageByStops.firstStop?.htmlContent"></span>
                    </div>
                </div>
            </ng-container>
        </div>
    </ion-row>

    <!--Ceci ne s'affiche que en TRAAS-->
    <ion-row class="transport stop-info" *ngIf="$stopInformation | async as stopInformation">
        <div class="transport-row">
            <div class="transport-infos">
                <div class="line"></div>
                <div class="transport-content e2e-stop-information-itinerary">
                    {{ stopInformation }}
                </div>
            </div>
            <div class="dotdotdot" style="opacity: 0">
                <ion-icon class="arrow" src="assets/icons/dotdotdot-idle.svg"></ion-icon>
            </div>
        </div>
    </ion-row>

    <ion-row class="transport" (click)="onTransportClicked()">
        <div class="transport-row">
            <div class="transport-infos">
                <div class="line"></div>
                <div class="transport-content with-arrow">
                    <app-vehicle-icon [transport]="transportIconName"></app-vehicle-icon>
                    <app-line-icon [line]="leg.getLine()"></app-line-icon>
                    <ion-icon class="arrow" src="assets/icons/right-arrow.svg"></ion-icon>
                    <div class="direction ion-text-wrap">
                        {{ leg.getDirection() }}
                    </div>
                </div>
            </div>
            <div class="dotdotdot">
                <ion-icon class="arrow" src="assets/icons/dotdotdot-idle.svg"></ion-icon>
            </div>
        </div>
    </ion-row>

    <ion-row class="line-cancelled-message" *ngIf="!!lineCancellationMessage">
        <div class="flex-item full-width cancelled-message-container">
            <div class="stop-name cancelled-message">
                <traas-cancelled-trip-icon [isBus]="false"></traas-cancelled-trip-icon>
                <span [innerHTML]="lineCancellationMessage.htmlContent"></span>
            </div>
        </div>
    </ion-row>

    <ion-row class="arrival" [class.cancelled]="departureStop.isCancelled()" (click)="onArrivalClicked(arrivalStop)">
        <div class="arrival-row">
            <div class="flex-item grow arrival-infos">
                <div class="line-track">
                    <app-track-icon [track]="arrivalTrack"></app-track-icon>
                </div>
                <div class="booking-icon">
                    <ion-icon #legPathEnd [class.tpg]="isTpg" [src]="arrivalThermoStopIcon"></ion-icon>
                </div>

                <app-warning-icon *ngIf="hasDisruptionOnArrival"></app-warning-icon>

                <div class="stop-name e2e-arrival-stop-name">
                    {{ arrivalStop | stopName }}
                </div>
            </div>
            <div class="flex-item no-grow arrival-stop">
                <div class="options" *ngIf="isTPC">
                    <ion-icon [src]="arrivalStopIconPathItinerary"></ion-icon>
                </div>
                <div class="time">{{ leg.getScheduledArrivalTime() | date : 'shortTime' }}</div>
                <div class="delay">{{ leg.getArrivalDelay() }}</div>
            </div>
            <ng-container *ngIf="leg.getLastStop().isCancelled">
                <div class="flex-item full-width cancelled-message-container">
                    <div class="stop-name cancelled-message">
                        <traas-cancelled-trip-icon [isBus]="false"></traas-cancelled-trip-icon>
                        <span [innerHTML]="cancellationMessageByStops.lastStop?.htmlContent"></span>
                    </div>
                </div>
            </ng-container>
        </div>
    </ion-row>
</div>
