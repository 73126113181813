import { Injectable } from '@angular/core';

@Injectable()
export class TabsService {
    private firstTabSelectionAfterAppRunnig = false;

    setFirstTabSelectionAfterAppRunnig(value: boolean): void {
        this.firstTabSelectionAfterAppRunnig = value;
    }

    /**
     * When app will be ran for the first time, the delay to load tabs can be extremely long...
     * PS: Delay of 100 is important, because sometimes, with just 1 ms, that doesn't works :(
     */
    getDelayToWaitBeforeSetActiveTab(): number {
        if (this.firstTabSelectionAfterAppRunnig) {
            return 100;
        }
        return 2000;
    }
}
