import { Marker } from 'leaflet';

export class EdgeMarker extends Marker {
    private originalMarker: Marker;

    getOriginalMarker(): Marker {
        return this.originalMarker;
    }

    setOriginalMarker(marker: Marker): void {
        this.originalMarker = marker;
    }
}
