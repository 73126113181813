import { Injectable } from '@angular/core';
import { CguStatus } from '../models/cgu-status.model';
import { ObservableTypedStorage } from '@traas/common/utils';

@Injectable()
export abstract class TosService {
    protected constructor(protected cguStorage: ObservableTypedStorage<CguStatus>) {}

    abstract acceptedTos(): Promise<void>;

    abstract hasAcceptedTos(): Promise<boolean>;
}
