import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { ONE_SECOND_IN_MS } from '@traas/boldor/all-helpers';
import { DatePickerActions, DatePickerSelectors } from '../../features/home/store/datepicker';
import { getDatePickerValue } from '../../features/home/store/datepicker/datepicker.selectors';
import { DatepickerMode, DatepickerState } from '../../features/home/store/datepicker/datepicker.state';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { TimerService } from './timer/timer.service';

@Injectable({
    providedIn: 'root',
})
export class DatepickerService {
    constructor(private store: Store<DatepickerState>, private timerService: TimerService, private platform: Platform) {}

    /**
     * @function
     * @param {Date} date - The date to set the datepicker to.
     * @returns {void}
     * @description Sets the datepicker date. If the date is close to the current date (within 60 seconds),
     * the datepicker mode is set to `DatepickerMode.AUTO`. Otherwise, the datepicker mode is set to `DatepickerMode.FIXED`
     * and the date is set to the provided date.
     */
    setDatepickerDate(date: Date): void {
        const isNearNow = Math.abs(new Date().getTime() - date.getTime()) < 60 * 1000;
        if (isNearNow) {
            this.store.dispatch(DatePickerActions.resetToAuto());
        } else {
            this.store.dispatch(DatePickerActions.setFixedDate({ date }));
        }
    }

    async getCurrentDatepickerValue(): Promise<Date> {
        const datepickerMode = await firstValueFrom(this.store.select(DatePickerSelectors.getDatepickerMode));
        return this.getDatepickerValueByMode(datepickerMode);
    }

    /**
     * @function
     * @returns {Observable<Date>} - An observable that emits the current value of the datepicker.
     * @description Creates an observable that emits a value every 30 seconds and listens to the platform resume event.
     * It uses the value emitted by the selector `DatePickerSelectors.getDatepickerMode` to filter the values and only
     * allow those where the mode is equal to `DatepickerMode.FIXED`. Then it returns the value of the datepicker. If the
     * mode is not equal to `DatepickerMode.FIXED` it returns the current date.
     */
    $getAutoRefreshedDatepickerValue(): Observable<Date> {
        const $datepickerValueChange = this.store.select(getDatePickerValue);
        const $each30Secs = this.timerService.$synchronizedTimer(30 * ONE_SECOND_IN_MS, true);
        const $resume = this.platform.resume.asObservable().pipe(startWith(() => undefined));
        return combineLatest([$each30Secs, $resume, $datepickerValueChange]).pipe(
            withLatestFrom(this.store.select(DatePickerSelectors.getDatepickerMode), (_, mode) => mode),
            switchMap((mode) => {
                return this.getDatepickerValueByMode(mode);
            }),
        );
    }

    private getDatepickerValueByMode(mode: DatepickerMode): Promise<Date> {
        if (mode === DatepickerMode.FIXED) {
            return firstValueFrom(this.store.select(getDatePickerValue));
        }
        return Promise.resolve(new Date());
    }
}
