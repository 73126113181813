import { ONE_MINUTE_IN_MS } from '@traas/boldor/all-helpers';

const CACHE_EXPIRATION_IN_MS = 5 * ONE_MINUTE_IN_MS;
// TODO Name is confusing because @apollo/client is also exporting this
export class InMemoryCache<T> {
    private data?: T;
    private timestamp?: number;

    get(): T {
        this.cleanup();
        return this.data;
    }

    set(value: T): void {
        this.data = value;
        this.timestamp = new Date().getTime();
    }

    private cleanup(): void {
        if (this.timestamp && new Date().getTime() > this.timestamp + CACHE_EXPIRATION_IN_MS) {
            this.clear();
        }
    }

    clear(): void {
        this.timestamp = undefined;
        this.data = undefined;
    }
}
