import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BuyTicketEvent, NearestStop, PositionType, TicketViewModel, TravelClassType } from '@traas/boldor/all-models';
import { SelectionHelper } from '@traas/boldor/all-helpers';
import { SMS, SmsOptions } from '@awesome-cordova-plugins/sms/ngx';
import { CategoryTicketsViewModel } from '@traas/boldor/common/features/ticket/models/ticket-list-mapper';
import { Store } from '@ngrx/store';
import { TicketState } from '@traas/boldor/common/features/ticket/store/ticket.state';
import { TicketStoreSelectors } from '@traas/boldor/common/features/ticket/store';

const PHONE_NUMBER_BUY_TICKET_BY_SMS = '456';

@Component({
    selector: 'travys-ticket-list',
    templateUrl: './ticket-list.component.html',
    styleUrls: ['./ticket-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketListComponent {
    @Input() $isOnline: Observable<boolean>;
    @Input() locationTicketsCategory: CategoryTicketsViewModel | null;
    @Input() nearestStop: { value: NearestStop | null; isLoading: boolean } = {
        value: null,
        isLoading: false,
    };
    @Input() preferredTravelClass: TravelClassType;
    @Input() otherTicketsCategory: CategoryTicketsViewModel | null;
    @Input() smsTicketsCategories: CategoryTicketsViewModel[];

    @Output() buyTicket = new EventEmitter<BuyTicketEvent>();
    @Output() buyTicketByZone = new EventEmitter<BuyTicketEvent>();
    @Output() navigateToItinerary = new EventEmitter<void>();

    rootAccordion = new SelectionHelper();
    smsAccordion = new SelectionHelper();

    LOCATION_SECTION_ID = 'LOCATION';
    OTHER_SECTION_ID = 'OTHER';
    SMS_SECTION_ID = 'SMS';
    $isTicketsCategoryLoading: Observable<boolean>;

    private $onDestroy = new Subject<void>();

    constructor(private sms: SMS, private store: Store<TicketState>) {
        this.$isTicketsCategoryLoading = this.store.select(TicketStoreSelectors.getIsQuickArticlesLoading);
    }

    ngOnDestroy(): void {
        this.$onDestroy.next();
        this.$onDestroy.complete();
    }

    isGpsPosition(): boolean {
        return this.nearestStop.value?.positionType === PositionType.Gps;
    }

    isDefaultPosition(): boolean {
        return this.nearestStop.value?.positionType === PositionType.Default;
    }

    async buyTicketArticle(ticket: TicketViewModel, nearestStop?: NearestStop | null): Promise<void> {
        this.buyTicket.emit({
            ticket,
            nearestStop,
        });
    }

    async buyTicketArticleByZones(ticket: TicketViewModel): Promise<void> {
        this.buyTicketByZone.emit({ ticket });
    }

    emitClickOnNavigate(): void {
        this.navigateToItinerary.emit();
    }

    trackByIndex(index: number): number {
        return index;
    }

    noTicketMessage(): string {
        return 'Aucune offre de billet ne correspond à votre géolocalisation';
    }

    async buySmsTicket(ticket: TicketViewModel): Promise<void> {
        const options: SmsOptions = { android: { intent: 'INTENT' } };
        try {
            console.log('Send SMS code', ticket.article.smsCode);
            await this.sms.send(PHONE_NUMBER_BUY_TICKET_BY_SMS, ticket.article.smsCode, options);
        } catch (error) {
            console.log('SMS not sent');
        }
    }
}
