import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { getStopIconDetailName, getThermoStopIconName } from '../../../../business-rules.utils';
import { AlertService } from '../../../../services/common/alert.service';
import { CompanyService } from '../../../../services/common/company/company.service';
import { StopAdapter } from '../../../../models/itinerary/stop';
import { JourneyMessage, LineStyle } from '@traas/boldor/all-models';
import { JourneyMessageService } from '../../../../services/common/journey-message.service';

@Component({
    selector: 'app-itinerary-leg-stop-list-item',
    templateUrl: './itinerary-leg-stop-list-item.component.html',
    styleUrls: ['./itinerary-leg-stop-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryLegStopListItemComponent implements OnInit {
    @Input() isFirst: boolean;
    @Input() isLast: boolean;
    @Input() lineStyle: LineStyle;
    @Input() networkId: string;
    @Input() isTrain: boolean;
    @Input() stop: StopAdapter;

    arrivalDatetime?: Date;
    departureDatetime?: Date;
    hasDisruption: boolean;
    thermoStopIconPath: string;
    stopIconPathItinerary: string;
    track: string;
    cancellationMessage: JourneyMessage | null;
    readonly isTPC = CompanyService.isTPC();

    private alertService = inject(AlertService);
    private journeyMessageService = inject(JourneyMessageService);

    ngOnInit(): void {
        this.hasDisruption = this.journeyMessageService.containsDisruptionMessagesInItineraryStop(this.stop.getData());
        this.departureDatetime = this.stop.getScheduledDepartureTime();
        this.arrivalDatetime = this.stop.getScheduledArrivalTime();
        this.track = this.stop.getTrack();
        const { hasBookingRequirements, isOptional } = this.stop.getData();
        this.thermoStopIconPath = getThermoStopIconName(hasBookingRequirements, isOptional, this.isTrain);
        this.stopIconPathItinerary = getStopIconDetailName(hasBookingRequirements, isOptional, this.isTrain, this.isFirst);
        this.cancellationMessage = this.journeyMessageService.getFormattedCancellationMessagesOfItineraryStop(this.stop.getData());
    }

    async presentWarningMessage(event: Event): Promise<void> {
        event.stopPropagation();
        event.preventDefault();
        return this.alertService.presentDisruptionMessagesModal(
            this.journeyMessageService.getFormattedDisruptionMessagesOfItineraryStop(this.stop.getData()),
        );
    }
}
