import { Injectable } from '@angular/core';
import { OrderViewModel, StopRequest } from '@traas/boldor/all-models';
import {
    GetAllOrdersOfCurrentUserQuery,
    GetDepartureOrderGQL,
    GetDepartureOrderQueryVariables,
    ItineraryOrderGQL,
    ItineraryOrderQueryVariables,
    QuickTicketOrderGQL,
    QuickTicketOrderQueryVariables,
    SetTicketsToSeenGQL,
    SetTicketsToSeenMutationVariables,
} from '@traas/boldor/graphql-generated/graphql';
import { GetAllOrdersOfCurrentUserGQL } from '../../../../../../graphql/generated/tpc/graphql';
import { ArticleType } from '../../../features/payment/store/payment.state';
import {
    GqlDepartureOrder,
    GqlItineraryOrder,
    GqlOrder,
    GqlQuickTicketsOrder,
    GqlTicketsTransaction,
    GqlToFrontOrderConverter,
} from './../../../models/order';
import { firstValueFrom } from 'rxjs';
import { StopRequestService } from '../stop-request/stop-request.service';
import { retry } from 'rxjs/operators';
import { TRY_CANCEL_ORDER_RETRY_INTERVAL_MS } from '../../../business-rules.utils';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { LoggingService } from '@traas/common/logging';

interface ConvertibleGqlOrders {
    departureOrders: GetAllOrdersOfCurrentUserQuery['order']['getAllOrders']['departureOrders'];
    itineraryOrders: GetAllOrdersOfCurrentUserQuery['order']['getAllOrders']['itineraryOrders'];
    quickTicketOrders: GetAllOrdersOfCurrentUserQuery['order']['getAllOrders']['quickTicketOrders'];
    processingOrders: GetAllOrdersOfCurrentUserQuery['order']['getAllOrders']['processingOrders'];
}

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(
        private getDepartureOrderGQL: GetDepartureOrderGQL,
        private itineraryOrderGQL: ItineraryOrderGQL,
        private quickTicketOrderGQL: QuickTicketOrderGQL,
        private getAllOrdersOfCurrentUserGQL: GetAllOrdersOfCurrentUserGQL,
        private stopRequestService: StopRequestService,
        private setTicketsToSeenGQL: SetTicketsToSeenGQL,
        private logger: LoggingService,
    ) {}

    async setTicketsToSeen(orderId: string[]): Promise<void> {
        const variables: SetTicketsToSeenMutationVariables = {
            orders: orderId.map((id) => ({ orderId: id })),
        };
        const { data } = await firstValueFrom(
            this.setTicketsToSeenGQL.mutate(variables).pipe(retry({ delay: TRY_CANCEL_ORDER_RETRY_INTERVAL_MS })),
        );
        const setTicketsToSeen = data?.orderPublic.setTicketsToSeen;
        if (!setTicketsToSeen) {
            this.logger.logError(
                new TechnicalError('setTicketsToSeen response must not be empty.', ErrorCodes.Purchase.SetTicketsToSeen, undefined, {
                    orderId,
                }),
            );
        }
    }

    async getOrder(orderId: string, articleType: ArticleType): Promise<GqlOrder> {
        let order: GqlOrder;
        if (articleType === ArticleType.QUICK) {
            order = await this.getQuickTicketById(orderId);
        } else if (articleType === ArticleType.ITINERARY) {
            order = await this.getItineraryTicketById(orderId);
        } else if (articleType === ArticleType.DEPARTURE) {
            order = await this.getDepartureOrderById(orderId);
        }
        this.assertResponseContainsATicket(order);
        return order;
    }

    gqlOrdersToOrdersViewModel(convertibleGqlOrders: ConvertibleGqlOrders): OrderViewModel[] {
        let orders = [
            ...convertibleGqlOrders.departureOrders,
            ...convertibleGqlOrders.itineraryOrders,
            ...convertibleGqlOrders.quickTicketOrders,
        ].map(GqlToFrontOrderConverter.toOrderViewModel);
        if (convertibleGqlOrders.processingOrders?.length > 0) {
            orders = orders.concat(
                convertibleGqlOrders.processingOrders.map(GqlToFrontOrderConverter.fromProcessingTicketToOrderViewModel),
            );
        }
        return orders.filter((element) => !!element);
    }

    /**
     * This query GQL get all ticket orders and all stop requests
     */
    async getAllOrdersAndAllStopRequestsOfCurrentUser(): Promise<{
        orders: OrderViewModel[];
        stopRequests: StopRequest[];
    }> {
        const result = await firstValueFrom(this.getAllOrdersOfCurrentUserGQL.fetch());
        const data = result.data.order.getAllOrders;
        const orders = this.gqlOrdersToOrdersViewModel({
            departureOrders: data.departureOrders,
            processingOrders: data.processingOrders,
            itineraryOrders: data.itineraryOrders,
            quickTicketOrders: data.quickTicketOrders,
        });
        const stopRequests = this.stopRequestService.gqlStopRequestsToStopRequests({
            departureStopRequests: data.departureStopRequests,
            itineraryStopRequests: data.itineraryStopRequests,
        });
        return { orders, stopRequests };
    }

    private async getDepartureOrderById(orderId: string): Promise<GqlDepartureOrder> {
        // BDORAPP-276 MDS : must search in local storage before call middleware
        const variables: GetDepartureOrderQueryVariables = {
            order: {
                orderId,
            },
        };

        const result = await firstValueFrom(this.getDepartureOrderGQL.fetch(variables));
        return result.data.order.getDepartureOrder;
    }

    private async getItineraryTicketById(orderId: string): Promise<GqlItineraryOrder> {
        // BDORAPP-276 MDS : must search in local storage before call middleware
        const variables: ItineraryOrderQueryVariables = {
            order: {
                orderId,
            },
        };

        const result = await firstValueFrom(this.itineraryOrderGQL.fetch(variables));
        return result.data.orderPublic.getItineraryOrder;
    }

    private async getQuickTicketById(orderId: string): Promise<GqlQuickTicketsOrder> {
        const variables: QuickTicketOrderQueryVariables = {
            order: {
                orderId,
            },
        };

        const result = await firstValueFrom(this.quickTicketOrderGQL.fetch(variables));
        return result.data.orderPublic.getQuickTicketsOrder;
    }

    private assertResponseContainsATicket(order: GqlOrder): void {
        if (!order || !order.ticketsTransaction || !(order.ticketsTransaction as GqlTicketsTransaction).tickets) {
            throw new Error('Order response must contains a ticket.');
        }
    }
}
