import { Injectable } from '@angular/core';
import { createDefaultGeoposition } from '@traas/boldor/all-helpers';
import { AssociatedCommercialStopAdapter, DataNearPoint, NearestStop, PositionType } from '@traas/boldor/all-models';
import { MapService } from './map.service';
import { CompanyService } from '../../../services/common/company/company.service';
import { GeolocationService } from '../../../services/common/geolocation/geolocation.service';
import { StopNamePipe } from '../../../pipes/stop-name.pipe';
import { convertToError, LoggingService } from '@traas/common/logging';
import { LatLng } from 'leaflet';
import { firstValueFrom } from 'rxjs';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

interface StopInfo {
    stopDidok: number | null;
    stopName: string;
}

@Injectable({ providedIn: 'root' })
export class NearestStopService {
    constructor(private loggingService: LoggingService, private mapService: MapService, private geolocationService: GeolocationService) {}

    async getNearestStop(): Promise<NearestStop | null> {
        try {
            const gpsPosition = await this.getCurrentGpsPosition();
            let nearestStop = gpsPosition ? await this.fetchNearestStop(gpsPosition, PositionType.Gps) : null;

            if (!nearestStop) {
                const defaultPosition = this.getDefaultPosition();
                nearestStop = await this.fetchNearestStop(defaultPosition, PositionType.Default);
            }

            return nearestStop;
        } catch (error) {
            this.logError('Error finding nearest stop', error);
            return null;
        }
    }

    private async getCurrentGpsPosition(): Promise<LatLng | null> {
        const isGpsAvailable = await firstValueFrom(this.geolocationService.$isGeolocationServiceAvailable());
        if (!isGpsAvailable) {
            return null;
        }

        return (await this.geolocationService.getHere()).getLatLng();
    }

    private getDefaultPosition(): LatLng {
        return createDefaultGeoposition(CompanyService.getDefaultPlaceByCompany()).getLatLng();
    }

    private async fetchNearestStop(position: LatLng, positionType: PositionType): Promise<NearestStop | null> {
        const stopInfo = await this.fetchStopInfoNear(position);
        if (!stopInfo) {
            this.logInfo(`No stop found near ${position}`);
            return null;
        }
        return { ...stopInfo, positionType };
    }

    private async fetchStopInfoNear(latLng: LatLng): Promise<StopInfo | null> {
        const dataNearPoint = await this.mapService.fetchDataNear(latLng);
        if (!dataNearPoint.stops?.length) {
            return null;
        }
        return this.extractFirstCommercialStopDidok(dataNearPoint);
    }

    private extractFirstCommercialStopDidok(data: DataNearPoint): StopInfo {
        const [firstStop] = data.stops;
        const firstCommercialStop = new AssociatedCommercialStopAdapter(firstStop.associatedCommercialStop);
        const stopDidok = firstCommercialStop.getDidok();
        const stopName = StopNamePipe.transformValue(firstCommercialStop);

        if (!stopDidok) {
            throw new Error(`No DIDOK found for ${stopName}`);
        }

        return {
            stopDidok,
            stopName,
        };
    }

    private logError(message: string, error: unknown): void {
        this.loggingService.logError(new TechnicalError(message, ErrorCodes.NearestStop.Fetch, convertToError(error)));
    }

    private logInfo(message: string): void {
        this.loggingService.logLocalError(new Error(message), { level: 'info' });
    }
}
