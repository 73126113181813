import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AngularSplitModule } from 'angular-split';
import { TestPage } from './test';
import { BookingPageModule } from '../booking/booking.module';
import { TicketModule } from '../../features/ticket/ticket.module';
import { TestRoutingModule } from './test-routing.module';
import { ItineraryDetailsModule } from '../../features/itinerary/components/itinerary-details/itinerary-details.module';

@NgModule({
    declarations: [TestPage],
    imports: [AngularSplitModule, TicketModule, TestRoutingModule, IonicModule, BookingPageModule, ItineraryDetailsModule],
})
export class TestPageModule {}
