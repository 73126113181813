/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { Point } from './grid.model';
import { SearchModeOptions } from '@traas/boldor/all-models';

export enum GridActionTypes {
    DragStart = '[Grid] Drag Start',
    DragEnd = '[Grid] Drag End',
    DragMove = '[Grid] Drag moved',
    EditionIn = '[Grid] Enter in edition',
    EditionOut = '[Grid] Out of edition mode',
    EditionAll = '[Grid] Enter in edition of all',
    ChangeSearchMode = '[Grid] Changing Search mode',
}
export class DragStart implements Action {
    readonly type: GridActionTypes.DragStart = GridActionTypes.DragStart;

    constructor(public payload: { point: Point }) {}
}

export class DragEnd implements Action {
    readonly type: GridActionTypes.DragEnd = GridActionTypes.DragEnd;
}

export class DragMove implements Action {
    readonly type: GridActionTypes.DragMove = GridActionTypes.DragMove;

    constructor(public payload: { point: Point }) {}
}

export class ChangeSearchMode implements Action {
    readonly type: GridActionTypes.ChangeSearchMode = GridActionTypes.ChangeSearchMode;

    constructor(public payload: { mode: SearchModeOptions }) {}
}

export class EditionIn implements Action {
    readonly type: GridActionTypes.EditionIn = GridActionTypes.EditionIn;
}

export class EditionOut implements Action {
    readonly type: GridActionTypes.EditionOut = GridActionTypes.EditionOut;
}

export class EditionAll implements Action {
    readonly type: GridActionTypes.EditionAll = GridActionTypes.EditionAll;
}

export type GridActions = DragStart | DragMove | DragEnd | EditionIn | EditionOut | EditionAll | ChangeSearchMode;
