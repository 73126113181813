import { Injectable } from '@angular/core';
import { GqlQuickArticle } from './quick-article.service';
import { ObservableTypedStorage } from '@traas/common/utils';
import * as moment from 'moment';

interface QuickTicketListStorage {
    ticketList: GqlQuickArticle[];
}

interface dateStorage {
    date: Date;
}

const Ticket_List_STORAGE_KEY = 'ticketList';
const DATE_ARTICLES_STORAGE_KEY = 'date';

@Injectable({
    providedIn: 'root',
})
export class TicketListStorageService {
    constructor(
        private storageDate: ObservableTypedStorage<dateStorage>,
        private storageQuickArticles: ObservableTypedStorage<QuickTicketListStorage>,
    ) {}

    async clearTicketList(): Promise<void> {
        await this.storageQuickArticles.removeItem(Ticket_List_STORAGE_KEY);
    }

    async getStoredTicketsList(): Promise<GqlQuickArticle[]> {
        return (await this.storageQuickArticles.getItem(Ticket_List_STORAGE_KEY)) || [];
    }

    async hasTicketListInLocalStorage(): Promise<boolean> {
        const ticketList = await this.storageQuickArticles.getItem(Ticket_List_STORAGE_KEY);
        const dateStoredTickets = await this.storageDate.getItem(DATE_ARTICLES_STORAGE_KEY);
        const now = moment();
        const duration = moment.duration(now.diff(moment(dateStoredTickets)));
        const hours = duration.asHours();
        return ticketList?.length > 0 && hours < 24;
    }

    async updateStorageWithTicketListFromMiddleware(ticketList: GqlQuickArticle[]): Promise<void> {
        await this.storageQuickArticles.setItem(Ticket_List_STORAGE_KEY, ticketList);
        await this.storageDate.setItem(DATE_ARTICLES_STORAGE_KEY, moment().toDate());
    }
}
