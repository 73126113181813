import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';

@Injectable()
export class DeviceIdService {
    private deviceId: string | undefined;

    async getDeviceId(): Promise<string> {
        if (!this.deviceId) {
            const deviceInfo = await Device.getId();
            this.deviceId = deviceInfo.uuid;
        }
        return this.deviceId;
    }
}
