import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ItineraryEffect } from './itinerary.effect';
import { itineraryReducer } from './itinerary.reducer';

@NgModule({
    declarations: [],
    imports: [StoreModule.forFeature('itinerary', itineraryReducer), EffectsModule.forFeature([ItineraryEffect])],
    providers: [ItineraryEffect],
})
export class ItineraryStoreModule {}
