import * as L from 'leaflet';
import { LatLngExpression } from 'leaflet';

// CustomMarker has been created in order to pass stopName cityName and letter into
// the marker data -> Usecase in getEdgeDivIconFromPhysicalStops for exemple
export class CustomMarker extends L.Marker {
    declare options: CustomOptions;

    constructor(latLng: LatLngExpression, options?: CustomOptions) {
        super(latLng, options);
    }
}

interface CustomOptions extends L.MarkerOptions {
    stopName: string;
    stopCityName: string;
    letter: string;
    networkId: string;
}
