import { TicketState } from './ticket.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectTicket = createFeatureSelector<TicketState>('ticket-articles');

export const getCategoryTicketAdapter = createSelector(selectTicket, (state: TicketState) => state.quickTickets);

export const getCategoryTicketByZoneAdapter = createSelector(selectTicket, (state: TicketState) => state.quickTicketsByZone);

export const getIsQuickArticlesLoading = createSelector(selectTicket, (state: TicketState) => state.isLoading);

export const getIsInitialized = createSelector(selectTicket, (state: TicketState) => state.isInitialized);

export const getTicketList = createSelector(selectTicket, (state: TicketState) => state.ticketList);
