/**
 * Check if an input value is defined, i.e. not null or undefined.
 * See discuss here : https://git.moviplus.ch/traas/app-client/-/merge_requests/549#note_113572
 * @template T - The type of the input value.
 * @param {T | null | undefined} input - The input value to check.
 * @returns {input is T} - True if the input value is defined, false otherwise.
 * @example
 * this.configService
 *     .$getDisplayConfiguration()
 *     .pipe(filter(isNotNullOrUndefined)) // <-- will force type checking, so in subscribe callback, newDisplayConfiguration can't be undefined.
 *     .subscribe((newDisplayConfiguration) => ...
 *
 * Or without RXJS :
 *
 * area.physicalStops
 *     .map((stop) => stop.associatedCommercialStop?.didok)
 *     .filter(isNotNullOrUndefined);
 */
export const isNotNullOrUndefined = <T extends {}>(input: T | null | undefined): input is T => input !== null && input !== undefined;
