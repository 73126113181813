import { BookingState, initialState } from './booking.state';
import { BookingAction, BookingActionTypes } from './booking.action';

export function bookingReducer(state: BookingState = initialState, action: BookingAction): BookingState {
    switch (action.type) {
        case BookingActionTypes.SetIsLoading:
            return {
                ...state,
                isLoading: action.isLoading,
            };

        case BookingActionTypes.LoadOrders:
            return {
                ...state,
                isLoading: !action?.payload?.preventLoading,
            };

        case BookingActionTypes.LoadOrdersFail:
        case BookingActionTypes.LoadOrdersSuccess:
            return {
                ...state,
                isLoading: false,
            };

        case BookingActionTypes.SetOrders:
            return {
                ...state,
                orders: [...action.payload.orders],
            };

        case BookingActionTypes.AddOrder:
            return {
                ...state,
                orders: [...state.orders, action.payload],
            };

        case BookingActionTypes.UpdateOrderById:
            const foundIndex = state.orders.findIndex((order) => order.id === action.payload.id);
            const updated = [...state.orders];

            if (foundIndex !== -1) {
                updated[foundIndex] = { ...action.payload };
            } else {
                console.warn(`Element to update was not found ${JSON.stringify(action.payload)}`);
            }

            return {
                ...state,
                orders: updated,
            };
        default:
            return state;
    }
}
