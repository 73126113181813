import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { ErrorPage } from './error.page';

@NgModule({
    imports: [BoldorFeatureShellSharedModule, IonicModule],
    declarations: [ErrorPage],
    exports: [ErrorPage],
})
export class ErrorPageModule {}
