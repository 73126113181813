import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { getZonesTextFromZones } from '../../../../services/common/quick-article/quick-article.utils';
import { areThirdPartyPaidTickets, getFormattedTotalPrice } from '@traas/boldor/all-helpers';
import { Article, OrderViewModel, QuickTicketsOrderViewModel, Zone } from '@traas/boldor/all-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-quick-ticket-summary-item',
    templateUrl: './quick-ticket-summary.component.html',
    styleUrls: ['./quick-ticket-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickTicketSummaryComponent {
    @Input() set order(value: OrderViewModel) {
        this.$order.next(value);
        this.totalPrice = getFormattedTotalPrice(value.tickets);
    }

    get order(): QuickTicketsOrderViewModel {
        return this.$order.getValue();
    }

    @Input() isRefundable = false;
    @Input() showOperationsIcons = true;
    @Input() quickTicket: QuickTicketsOrderViewModel;

    @Output() refundOrder = new EventEmitter<QuickTicketsOrderViewModel>();

    totalPrice: string;

    private readonly $order = new BehaviorSubject<QuickTicketsOrderViewModel>(null);

    confirmRefundOrder(): void {
        this.refundOrder.emit(this.order);
    }

    $article(): Observable<Article> {
        return this.$order.pipe(
            map((order) => order?.tickets?.[0]?.article),
            filter((article) => !!article),
        );
    }

    getZonesTextFromZones(zones: Zone[]): string {
        return getZonesTextFromZones(zones);
    }

    areThirdPartyPaidTickets(order: QuickTicketsOrderViewModel): boolean {
        return areThirdPartyPaidTickets(order);
    }
}
