import { NgModule } from '@angular/core';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { ModalCgvPage } from './modal-cgv';

@NgModule({
    imports: [BoldorFeatureShellSharedModule],
    declarations: [ModalCgvPage],
    exports: [ModalCgvPage],
})
export class ModalCgvPageModule {}
