import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { MapComponent } from './components/map/map.component';
import { UnavailableGpsIndicatorComponent } from './components/unavailable-gps-indicator/unavailable-gps-indicator.component';
import { MapContainerComponent } from './containers/map-container/map-container.component';

@NgModule({
    imports: [
        LeafletModule, // need to reimport here from doc of vendor
        LeafletMarkerClusterModule,
        BoldorFeatureShellSharedModule,
    ],
    declarations: [BookmarkComponent, MapComponent, MapContainerComponent, UnavailableGpsIndicatorComponent],
    exports: [BookmarkComponent, MapContainerComponent],
})
export class MapModule {}
