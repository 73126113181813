import { Injectable } from '@angular/core';
import { LoggingService } from '@traas/common/logging';
import { TypedStorage } from './typed-storage.service';
import { from, merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { PlatformUtilsService } from '../platform-utils.service';

@Injectable()
export class ObservableTypedStorage<T> extends TypedStorage<T> {
    private readonly $didSetItem = new Subject<keyof T>();

    constructor(storage: StorageService, logger: LoggingService, platformUtilsService: PlatformUtilsService) {
        super(storage, logger, platformUtilsService);
    }

    override async setItem<K extends keyof T>(key: K, value: T[K]): Promise<void> {
        await super.setItem(key, value);
        this.$didSetItem.next(key);
    }

    $getItem<K extends keyof T>(key: K, defaultValue: T[K]): Observable<T[K]> {
        const $changedItem: Observable<T[K]> = this.$didSetItem.pipe(
            filter((updatedKey) => updatedKey === key),
            switchMap(async () => this.getItem(key)),
            map((item) => item ?? defaultValue),
        );

        const $currentItem: Observable<T[K]> = from(this.getItem(key, defaultValue)).pipe(map((item) => item ?? defaultValue));
        return merge($currentItem, $changedItem);
    }
}
