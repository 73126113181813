<div class="ticket">
    <ion-row class="title">
        <ion-col> {{ 'my-journeys.ticket-summary.itinerary' | translate }} </ion-col>
        <ion-col>
            <app-lines-icon-horizontal-list [lines]="lines"></app-lines-icon-horizontal-list>
        </ion-col>
    </ion-row>
    <div class="ticket-container">
        <div class="ticket-infos">
            <app-itinerary-ticket-from-to
                [defaultPaddingOnCol]="false"
                [class.show-operations-icons]="showOperationsIcons"
                [departureStop]="departureStop"
                [customColPadding]="paddingCol"
                [arrivalStop]="arrivalStop"
            >
            </app-itinerary-ticket-from-to>

            <ion-row class="departure-datetime" [class.show-operations-icons]="showOperationsIcons">
                <ion-col size="10">
                    <app-human-readable-date [departureDatetime]="departureDate"></app-human-readable-date>
                </ion-col>
                <ion-col *ngIf="showCollapseButton" size="2" class="collapse-btn-container flex ion-text-end">
                    <app-collapse-arrow (click)="expandDetails()" [expanded]="expanded"></app-collapse-arrow>
                </ion-col>
            </ion-row>

            <div>
                <div *ngIf="showOperationsIcons" class="ticket-icons-price">
                    <ion-icon class="operation-icon separation-right" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
                    <ion-icon
                        *ngIf="itineraryOrder.tickets.length > 0"
                        class="operation-icon separation-right"
                        src="assets/icons/tabs/monochrome/tickets.svg"
                    ></ion-icon>
                    <ion-icon
                        *ngIf="itineraryOrder.itinerary.hasStopRequest"
                        class="operation-icon"
                        src="assets/icons/stop-on-request.svg"
                    ></ion-icon>
                    <ion-icon
                        *ngIf="isRefundable"
                        (click)="confirmRefundOrder()"
                        class="ticket-delete"
                        src="assets/icons/grid/delete.svg"
                    ></ion-icon>
                    <div class="ticket-price" [class.strikethrough]="areThirdPartyPaidTickets(itineraryOrder)">
                        {{ totalPrice }}
                    </div>
                </div>
            </div>
        </div>
        <div class="ticket-arrow">
            <ion-icon src="assets/icons/menu/chevron.svg"></ion-icon>
        </div>
    </div>
</div>

<div class="cancel-parent-padding" *ngIf="expanded">
    <app-itinerary-details-content [legs]="legs"></app-itinerary-details-content>
</div>
