import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClickOnLegArrival, ClickOnLegDeparture } from '../../store/itinerary.action';
import { ItineraryState } from '../../store/itinerary.state';
import { AlertService } from '../../../../services/common/alert.service';
import { LegAdapter } from '../../../../models/itinerary/leg';
import { StopAdapter } from '../../../../models/itinerary/stop';
import { firstValueFrom } from 'rxjs';
import { RouterState } from '../../../../router-store/state';
import { RouterSelectors } from '../../../../router-store';
import { JourneyMessageService } from '../../../../services/common/journey-message.service';

@Component({
    selector: 'app-itinerary-leg',
    templateUrl: './itinerary-leg.component.html',
    styleUrls: ['./itinerary-leg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryLegComponent {
    @Input() isFirstLeg = false;
    @Input() isLastLeg = false;
    @Input() leg: LegAdapter;

    showLegDetails = false;

    private alertService = inject(AlertService);
    private routerStore = inject(Store<RouterState>);
    private store = inject(Store<ItineraryState>);
    private journeyMessageService = inject(JourneyMessageService);

    toggleShowLegDetails(): void {
        this.showLegDetails = !this.showLegDetails;
    }

    async onWarningClicked(stop: StopAdapter): Promise<void> {
        return this.alertService.presentDisruptionMessagesModal(
            this.journeyMessageService.getFormattedDisruptionMessagesOfItineraryStop(stop.getData()),
        );
    }

    async onDepartureClicked(stop: StopAdapter): Promise<void> {
        const isValidPage = await firstValueFrom(this.routerStore.select(RouterSelectors.getIsJourneyDetailsPage));
        if (isValidPage) {
            this.store.dispatch(new ClickOnLegDeparture(stop.getData()));
        }
    }

    async onArrivalClicked(stop: StopAdapter): Promise<void> {
        const isValidPage = await firstValueFrom(this.routerStore.select(RouterSelectors.getIsJourneyDetailsPage));
        if (isValidPage) {
            this.store.dispatch(new ClickOnLegArrival(stop.getData()));
        }
    }
}
