import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Place, SearchPlacesResponse, YOUR_LOCATION_I18N_KEY } from '@traas/boldor/all-models';
import { HomeStoreState } from '../../../home/store';
import { SearchPlaceActions } from '../../../home/store/searchPlace';
import { MapService } from '../../../map/services/map.service';
import { MyGpsPositionPlaceAdapter } from '../../adapters/my-gps-position-place';
import { createEmptySearchPlacesResponse, SearchPlacesResponseAdapter } from '../../adapters/search-places-response';
import { PlaceService } from '../../services/place.service';
import { CompanyService } from '../../../../services/common/company/company.service';
import { GeolocationService } from '../../../../services/common/geolocation/geolocation.service';
import { ICON_BASE_PATH } from '../../../../business-rules.utils';
import { convertToError, LoggingService } from '@traas/common/logging';
import { BoldorLocalizationService } from '@traas/common/localization';
import { OnlineService, PlatformUtilsService } from '@traas/common/utils';
import { combineLatest, EMPTY, from, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AnalyticsService } from '@traas/common/analytics';
import { DatePickerActions } from '../../../home/store/datepicker';
import { ErrorCodes, isSuccess, Result, TechnicalError } from '@traas/common/models';

const SEARCH_PLACE_MINIMUM_LENGTH = 3;

@Component({
    selector: 'app-search-places',
    templateUrl: './search-places.component.html',
    styleUrls: ['./search-places.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPlacesComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly $isLoading = new Subject<boolean>();
    $isOnline: Observable<boolean>;
    $searchPlacesResponse: Observable<SearchPlacesResponseAdapter>;
    $unsubscribe = new Subject<void>();
    placeNameCtrl: FormControl;
    searchForm: FormGroup;

    readonly $term = new Subject<string>();

    private myFavorite: string;

    private static isValidRequirements(searchTerm: string): boolean {
        return searchTerm.length >= SEARCH_PLACE_MINIMUM_LENGTH;
    }

    constructor(
        private analyticsService: AnalyticsService,
        private boldorLocalizationService: BoldorLocalizationService,
        private fb: FormBuilder,
        private geolocationService: GeolocationService,
        private logger: LoggingService,
        private mapService: MapService,
        private modalCtrl: ModalController,
        private onlineService: OnlineService,
        private placeSearchService: PlaceService,
        private platformUtilsService: PlatformUtilsService,
        private store: Store<HomeStoreState.HomeState>,
    ) {
        this.buildForm();
    }

    ngOnInit(): void {
        this.$searchPlacesResponse = this.$buildSearchPlacesResponse().pipe(shareReplay(1));
        this.$isLoading.next(false);
        this.$isOnline = this.onlineService.$getIsOnline();
    }

    async ngOnDestroy(): Promise<void> {
        this.$unsubscribe.next();
        this.$unsubscribe.complete();
        if (this.platformUtilsService.isApp() && this.platformUtilsService.isIos()) {
            try {
                await Keyboard.setResizeMode({ mode: KeyboardResize.Native });
            } catch (error) {
                // On some Android's phone, setResizeMode can crash with "Not implemented" error.
                console.warn(error);
            }
        }
    }

    async ngAfterViewInit(): Promise<void> {
        if (this.platformUtilsService.isApp() && this.platformUtilsService.isIos()) {
            try {
                await Keyboard.setResizeMode({ mode: KeyboardResize.Body });
            } catch (error) {
                // On some Android's phone, setResizeMode can crash with "Not implemented" error.
                console.warn(error);
            }
        }
    }

    $buildSearchPlacesResponse(): Observable<SearchPlacesResponseAdapter> {
        const $places = this.$assembleSearchPlaceResults();
        const $isGeolocationServiceAvailable = this.geolocationService.$isGeolocationServiceAvailable();

        const $placesWithoutGpsPlace = $places.pipe(
            takeUntil(this.$unsubscribe),
            map((searchPlacesResponseAdapter) => {
                return new SearchPlacesResponseAdapter(searchPlacesResponseAdapter.getData(), [
                    ...searchPlacesResponseAdapter.getCustomPlaces(),
                ]);
            }),
        );

        const $hasGpsPosition = $isGeolocationServiceAvailable.pipe(
            startWith(false),
            switchMap((isLocationAvailable) =>
                isLocationAvailable
                    ? this.geolocationService.$watchGeoposition().pipe(
                          takeUntil(this.$unsubscribe),
                          map(() => true),
                      )
                    : of(false),
            ),
            takeUntil(this.$unsubscribe),
        );

        return combineLatest([$placesWithoutGpsPlace, $hasGpsPosition]).pipe(
            switchMap(async ([searchPlacesResponseAdapter, hasGpsPosition]) => {
                try {
                    if (!hasGpsPosition) {
                        return searchPlacesResponseAdapter;
                    }
                    const yourLocationPlaceResult = await this.createCurrentPositionPlace();
                    if (!isSuccess(yourLocationPlaceResult)) {
                        this.logger.logError(
                            new TechnicalError(
                                'Error during creation of gps place',
                                ErrorCodes.SearchPlace.CreateGpsPlace,
                                yourLocationPlaceResult.error,
                            ),
                        );
                        return searchPlacesResponseAdapter;
                    }
                    return new SearchPlacesResponseAdapter(searchPlacesResponseAdapter.getData(), [
                        yourLocationPlaceResult.value,
                        ...searchPlacesResponseAdapter.getCustomPlaces(),
                    ]);
                } catch (error) {
                    this.logger.logError(
                        new TechnicalError(
                            'Error during creation of places response with gps',
                            ErrorCodes.SearchPlace.CreateResponse,
                            convertToError(error),
                        ),
                    );
                    return searchPlacesResponseAdapter;
                }
            }),
            takeUntil(this.$unsubscribe),
        );
    }

    async onSelectPlace(place: Place): Promise<void> {
        this.analyticsService.reportEvent('search-place');
        if (place) {
            if (place.isGpsPosition()) {
                const boundsResult = this.mapService.createBoundsFromGpsMarker();
                if (!isSuccess(boundsResult)) {
                    this.logger.logError(
                        new TechnicalError('Error while to select gps place', ErrorCodes.SearchPlace.PickPlace, boundsResult.error),
                    );
                    return;
                }
                const currentPositionPlace = new MyGpsPositionPlaceAdapter({
                    name: YOUR_LOCATION_I18N_KEY,
                    bounds: boundsResult.value,
                });
                this.store.dispatch(DatePickerActions.resetToAuto());
                await this.closeModal(currentPositionPlace);
                return;
            }
            if (place.isFromHistory !== true) {
                await this.placeSearchService.addPlaceInHistory(place);
            }
        }

        await this.closeModal(place);
    }

    onTermChange(newTerm: string): void {
        this.$term.next(newTerm.trim());
    }

    async closeModal(data?: Place | MyGpsPositionPlaceAdapter): Promise<boolean> {
        try {
            const modal = await this.modalCtrl.getTop();
            if (modal) {
                return this.modalCtrl.dismiss(data);
            }
            return true;
        } catch (error) {
            console.warn(`SearchPlaceComponent.closeModal: Error while trying to close modal : ${error.message}`);
            return true;
        }
    }

    onClearTerm(): void {
        this.$term.next('');
    }

    private $assembleSearchPlaceResults(): Observable<SearchPlacesResponseAdapter> {
        const $searchPlacesFromServer = this.$buildSearchPlacesFromServer();
        const $searchPlacesHistory = this.$buildSearchPlacesFromHistory();
        const $bookmark = this.$buildBookmarkPlaces();

        return combineLatest([$searchPlacesHistory, $searchPlacesFromServer, $bookmark]).pipe(
            // tslint:disable-next-line:max-line-length
            switchMap(([pHistory, pFromServer, pBookmark]) => this.mergeSearchResponseHistory(pFromServer, pHistory, pBookmark)),
        );
    }

    /**
     * Create a place using GPS position but without considering the stops around it.
     */
    private async createCurrentPositionPlace(): Promise<Result<MyGpsPositionPlaceAdapter, TechnicalError>> {
        const yourLocationLabel = await this.boldorLocalizationService.get(YOUR_LOCATION_I18N_KEY);
        const boundsResult = this.mapService.createBoundsFromGpsMarker();
        if (!isSuccess(boundsResult)) {
            return { success: false, error: boundsResult.error };
        }
        const myGpsPositionPlaceAdapter = new MyGpsPositionPlaceAdapter({
            name: yourLocationLabel,
            bounds: boundsResult.value,
        });
        return { success: true, value: myGpsPositionPlaceAdapter };
    }

    private $buildSearchPlacesFromHistory(): Observable<SearchPlacesResponseAdapter> {
        const $reversedPlaces: Observable<Place[]> = from(this.placeSearchService.getReversedPlacesHistory());
        return $reversedPlaces.pipe(
            switchMap((reversedHistoryPlaces: Place[]) => {
                const searchPlacesAdapter = new SearchPlacesResponseAdapter(createEmptySearchPlacesResponse(), reversedHistoryPlaces);
                return of(searchPlacesAdapter);
            }),
        );
    }

    private $buildBookmarkPlaces(): Observable<SearchPlacesResponseAdapter> {
        // New behavior: https://moviplus.atlassian.net/browse/BDORAPP-1400
        const bookMarkActionItem = this.placeSearchService.createActionItem({
            _placeType: 'actionItem',
            name: this.myFavorite,
            type: 'bookmark',
            icon: `${ICON_BASE_PATH}/grid/star-full.svg`,
        });
        const searchPlacesAdapter = new SearchPlacesResponseAdapter(createEmptySearchPlacesResponse(), [bookMarkActionItem]);
        return of(searchPlacesAdapter);
    }

    private $buildSearchPlacesFromServer(): Observable<SearchPlacesResponseAdapter> {
        return this.$term.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap((searchTerm) => this.$handleSearchTerm(searchTerm)),
            startWith(null),
        );
    }

    private $handleSearchTerm(searchTerm: string): Observable<SearchPlacesResponseAdapter> {
        if (SearchPlacesComponent.isValidRequirements(searchTerm)) {
            this.$isLoading.next(true);
            return this.placeSearchService.$searchByTerm(searchTerm, CompanyService.getDefaultPlaceByCompany()).pipe(
                catchError(() => EMPTY),
                tap(() => this.$isLoading.next(false)),
            );
        }
        return of(new SearchPlacesResponseAdapter(createEmptySearchPlacesResponse()));
    }

    private buildForm(): void {
        this.boldorLocalizationService.$get('search-place.my-favorites').subscribe((value) => (this.myFavorite = value));

        this.placeNameCtrl = this.fb.control('');
        this.searchForm = this.fb.group({
            placeName: this.placeNameCtrl,
        });
    }

    private async mergeSearchResponseHistory(
        placesFromServer: SearchPlacesResponseAdapter,
        placesHistory: SearchPlacesResponseAdapter,
        placesBookmark: SearchPlacesResponseAdapter,
    ): Promise<SearchPlacesResponseAdapter> {
        let searchPlacesMerged = new SearchPlacesResponseAdapter(createEmptySearchPlacesResponse());
        if (placesFromServer) {
            const placesFormServerData = placesFromServer.getData();
            const searchPlacesResponse: SearchPlacesResponse = {
                cities: placesFormServerData.cities,
                poi: placesFormServerData.poi,
                stops: placesFormServerData.stops,
                address: placesFormServerData.address,
                bookmarks: [],
            };
            searchPlacesMerged = new SearchPlacesResponseAdapter(searchPlacesResponse);
        }
        if (placesBookmark) {
            try {
                searchPlacesMerged.addCustomPlaces(placesBookmark.getCustomPlaces());
            } catch (error) {
                this.store.dispatch(new SearchPlaceActions.ResetBookmarks());
                this.logger.logLocalError(error);
            }
        }
        if (placesHistory) {
            try {
                searchPlacesMerged.addCustomPlaces(placesHistory.getCustomPlaces());
            } catch (error) {
                await this.placeSearchService.clearHistory();
                this.logger.logLocalError(error);
            }
        }
        return searchPlacesMerged;
    }
}
