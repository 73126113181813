import { ActionReducerMap } from '@ngrx/store';
import { GridStoreReducers } from '../../../components/grid/store';
import { HomeState } from './home.state';
import { SearchPlaceReducers } from './searchPlace';
import { EndpointReducers } from './endpoint';
import { MapReducers } from './map';
import { DatePickerReducers } from './datepicker';
import { scrollReducer } from './scroll/scroll.reducer';

export const HomeStoreReducers: ActionReducerMap<HomeState> = {
    searchPlace: SearchPlaceReducers.searchPlaceReducer,
    grid: GridStoreReducers.gridReducer,
    endpoint: EndpointReducers.endpointReducer,
    map: MapReducers.mapReducer,
    datepicker: DatePickerReducers.datePickerReducer,
    scroll: scrollReducer,
};
