/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Bookmark, BookmarkStorage, ChangePlaceEventSourceEnum } from '@traas/boldor/all-models';
import { getLoaderHtml } from '../../../business-rules.utils';
import { convertToError, LoggingService } from '@traas/common/logging';
import { BoldorLocalizationService } from '@traas/common/localization';
import { ObservableTypedStorage } from '@traas/common/utils';
import * as _ from 'lodash';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { BookmarkService } from '../bookmark/bookmark.service';
import { CompanyService } from '../company/company.service';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { GatewayEndpoints } from '@traas/boldor/common/gateway-endpoints';

const APP_CURRENT_DATA_VERSION_NUMBER = '5';
const STORAGE_KEY = 'dataVersion';

interface DataVersion {
    dataVersion: string;
}

@Injectable({ providedIn: 'root' })
export class DataVersionService {
    private migrationLoadingModal: HTMLIonLoadingElement | null;

    $isMigrating = new BehaviorSubject<boolean>(false);

    constructor(
        private loadingCtrl: LoadingController,
        private storage: ObservableTypedStorage<DataVersion>,
        private bookmarkStorage: ObservableTypedStorage<BookmarkStorage>,
        private anyStorage: ObservableTypedStorage<any>,
        private loggingService: LoggingService,
        private bookmarkService: BookmarkService,
        private boldorLocalizationService: BoldorLocalizationService,
        private http: HttpClient,
    ) {}

    async update(): Promise<void> {
        let currentDataVersion = await this.storage.getItem(STORAGE_KEY);

        /** Workaround to fix a bug introduced in update process */
        if (!currentDataVersion) {
            await this.storage.setItem(STORAGE_KEY, '2');
            currentDataVersion = '2';
        } else if (+currentDataVersion < 2) {
            await this.bookmarkService.clearBookmarks();
        }
        try {
            if (+currentDataVersion < +APP_CURRENT_DATA_VERSION_NUMBER) {
                if (currentDataVersion === '2') {
                    /** Workaround to fix a bug introduced in update process */
                    const hasBookmarks = await this.bookmarkService.hasBookmarks();
                    if (hasBookmarks) {
                        await this.migrateBookmarksFromV2ToV3(); // Complex struct to simple one
                    }
                    currentDataVersion = '3';
                }
                if (currentDataVersion === '3') {
                    await this.migrateSkip3DSecureNaming();

                    currentDataVersion = '4';
                }
                if (currentDataVersion === '4') {
                    if (CompanyService.isTPG()) {
                        const hasBookmarks = await this.bookmarkService.hasBookmarks();
                        if (hasBookmarks) {
                            const bookmarks: any[] = await this.bookmarkStorage.getItem<any>('bookmarks', []);
                            await this.convertDataFromSyntheseToHafas(bookmarks);
                        }
                    }
                    currentDataVersion = '5';
                }

                // if(currentDataVersion === '3') { then do migrate data from v3 to v4 }
            }
            await this.storage.setItem(STORAGE_KEY, APP_CURRENT_DATA_VERSION_NUMBER);
        } catch (error) {
            this.loggingService.logError(
                new TechnicalError(
                    `Error while updating data version, current data version: ${currentDataVersion}`,
                    ErrorCodes.Technical.UpdateDataVersion,
                    convertToError(error),
                ),
            );
        }
    }

    private async migrateBookmarksFromV2ToV3(): Promise<void> {
        const oldBookmarks: any[] = await this.bookmarkStorage.getItem<any>('bookmarks', []);
        const newBookmarks: Bookmark[] = oldBookmarks
            .map((oldBookmark) => {
                let newBookmark: Bookmark = null;
                try {
                    if (oldBookmark.areaAssociated) {
                        newBookmark = {
                            metadata: null, // Filled below
                            boundsRect: oldBookmark.areaAssociated.boundsRect,
                            stops: {
                                didok: _.uniq<number>(
                                    oldBookmark.areaAssociated.physicalStops?.map((stop) => stop.associatedCommercialStop.didok),
                                ).filter((didok) => !!didok),
                                id: _.uniq<string>(
                                    oldBookmark.areaAssociated.physicalStops?.map((stop) => stop.associatedCommercialStop.id),
                                ).filter((id) => !!id),
                            },
                        };
                        if (oldBookmark.areaAssociated.metadata?.address) {
                            newBookmark.metadata = {
                                locationName: oldBookmark.areaAssociated.metadata.address,
                                longitude: oldBookmark.areaAssociated.metadata.longitude,
                                latitude: oldBookmark.areaAssociated.metadata.latitude,
                                source: ChangePlaceEventSourceEnum.AddressSelection,
                            };
                        } else {
                            newBookmark.metadata = {
                                locationName:
                                    oldBookmark.name ?? oldBookmark.areaAssociated.physicalStops[0].associatedCommercialStop.name ?? null,
                                longitude: oldBookmark.areaAssociated.metadata.longitude,
                                latitude: oldBookmark.areaAssociated.metadata.latitude,
                                source: oldBookmark.areaAssociated.metadata.source ?? ChangePlaceEventSourceEnum.ManualMapMove,
                            };
                        }
                    }
                } catch (error) {
                    // It will be removed
                    this.loggingService.logError(
                        new TechnicalError(
                            `Error during migration of bookmark v2 to v3 : ${JSON.stringify(oldBookmark)}`,
                            ErrorCodes.Bookmark.Migration,
                            convertToError(error),
                        ),
                    );
                    return null;
                }
                return newBookmark;
            })
            .filter((bookmark) => !!bookmark);
        await this.bookmarkService.setBookmarks(newBookmarks);
    }

    private async migrateSkip3DSecureNaming(): Promise<void> {
        let newSkip3DSecureValue = null;
        if (await this.anyStorage.hasItem('hasNo3DSecure')) {
            newSkip3DSecureValue = await this.anyStorage.getItem('hasNo3DSecure');
            await this.anyStorage.removeItem('hasNo3DSecure');
            await this.anyStorage.setItem('skip3DSecure', newSkip3DSecureValue);
        }

        let new3dSecureChangedWhileLoggedOutValue = false;
        if (await this.anyStorage.hasItem('hasNo3DSecureMigrated')) {
            new3dSecureChangedWhileLoggedOutValue = await this.anyStorage.getItem('hasNo3DSecureMigrated');
            await this.anyStorage.removeItem('hasNo3DSecureMigrated');
        }
        await this.anyStorage.setItem('skip3DSecureChangedWhileLoggedOut', new3dSecureChangedWhileLoggedOutValue);

        let newCurrencyChangedWhileLoggedOutValue = false;
        if (await this.anyStorage.hasItem('hasCurrencyMigrated')) {
            newCurrencyChangedWhileLoggedOutValue = await this.anyStorage.getItem('hasCurrencyMigrated');
            await this.anyStorage.removeItem('hasCurrencyMigrated');
        }
        await this.anyStorage.setItem('currencyChangedWhileLoggedOut', newCurrencyChangedWhileLoggedOutValue);
    }

    private async convertDataFromSyntheseToHafas(bookmarks: Bookmark[]): Promise<void> {
        try {
            this.$isMigrating.next(true);
            const bookmarkMigrationEndpoint = GatewayEndpoints.bookmarksMigrationUrl;
            if (bookmarkMigrationEndpoint) {
                const converted = await firstValueFrom(this.http.post<Bookmark[]>(bookmarkMigrationEndpoint, bookmarks));
                if (converted) {
                    await this.bookmarkService.setBookmarks(converted);
                }
            }
        } catch (error) {
            throw new TechnicalError(
                'Error while migrating bookmarks from synthese to hafas',
                ErrorCodes.Bookmark.MigrationFromServer,
                convertToError(error),
                { bookmarks },
            );
        } finally {
            if (this.migrationLoadingModal) {
                await this.migrationLoadingModal.dismiss();
                this.migrationLoadingModal = null;
            }
            this.$isMigrating.next(false);
            this.$isMigrating.complete();
        }
    }

    /**
     * Instance of modal will be dismissed after migration, by the migration callback himself
     */
    async presentMigrationLoadingModal(): Promise<void> {
        const migrationText = await this.boldorLocalizationService.get('bookmark.migrating');
        this.migrationLoadingModal = await this.loadingCtrl.create({
            spinner: null,
            message: getLoaderHtml(migrationText),
            backdropDismiss: false,
            cssClass: 'custom-loading',
        });
        await this.migrationLoadingModal.present();
    }
}
