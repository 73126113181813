import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TrackIconComponent } from './track-icon.component';

@NgModule({
    imports: [CommonModule, IonicModule],
    declarations: [TrackIconComponent],
    exports: [TrackIconComponent],
})
export class TrackIconModule {}
