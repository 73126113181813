/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable max-classes-per-file */
/* tslint:disable:max-classes-per-file */
import { Action } from '@ngrx/store';
import { GqlItineraryOrder } from '../../../models/order';
import {
    ArticlesBundle,
    Cart,
    CheckoutStep,
    ConfigureTicketType,
    GuestCustomer,
    JourneyData,
    PriceType,
    SelectedZone,
    StopRequest,
    TicketDuration,
    TravelClassType,
} from '@traas/boldor/all-models';
import { TravelType } from '@traas/boldor/graphql-generated/graphql';

export enum CartActionTypes {
    SetDefaultArticlesBundle = '[Cart] Set default articles bundle for connected user',
    Save = '[Cart] Save itinerary ...',
    SaveSuccess = '[Cart] Save success',
    SaveError = '[Cart] Save failed',
    AskToStop = '[Cart] Ask to stop...',
    AskToStopSuccess = '[Cart] Ask to stop success',
    AskToStopError = '[Cart] Ask to stop failed',
    InitCart = '[Cart] Init new cart...',
    UpdateCart = '[Cart] Update the cart',
    UpdateCartFail = '[Cart] Updating cart has failed',
    UpdateCartSuccess = '[Cart] Updating cart has succeed',
    UpdateSaveOperationOfCart = '[Cart] Set SAVE operation of cart',
    UpdateBuyOperationOfCart = '[Cart] Set BUY operation of cart',
    UpdateStopRequestOperationOfCart = '[Cart] Set STOP_REQUEST operation of cart',
    RunOperationsOfCart = '[Cart] Run all operations of cart...',
    ResetCart = '[Cart] Reset cart...',
    SetCheckoutStep = '[Cart] Set checkout step',
    // Eticketing
    PrepareArticlesBundle = '[Cart - Eticketing] Load articles...',
    PrepareArticlesBundleSuccess = '[Cart - Eticketing] Load articles success',
    PrepareArticlesBundleError = '[Cart - Eticketing] Load articles failed',
    UpdateArticlesBundle = '[Cart - Eticketing] Update one articles bundle',
    RemoveArticlesBundle = '[Cart - Eticketing] Remove one articles bundle',
    SetTypeSelected = '[Cart - Eticketing] Set type selected',
    SetClassSelected = '[Cart - Eticketing] Set class selected',
    SetPriceTypeSelected = '[Cart - Eticketing] Set price type selected',
    SetTravelTypeSelected = '[Cart - Eticketing] Set travel type selected',
    SetDurationsFilter = '[Cart - Eticketing] Set durations filter',
    SetSelectedZones = '[Cart - Eticketing] Set selected zones',
    SetSelectedPaymentMean = '[Cart - Eticketing] Set payment mean',
    SetNationalMarketingConsent = '[Cart - Eticketing] Set national marketing (RUD) consent',
    SetMarketingConsentsLoading = '[Cart - Eticketing] Set marketing consents is loading',
}

export class Save implements Action {
    readonly type = CartActionTypes.Save;

    constructor() {}
}

export class SaveSuccess implements Action {
    readonly type = CartActionTypes.SaveSuccess;

    constructor() {}
}

export class SaveError implements Action {
    readonly type = CartActionTypes.SaveError;
}

export class AskToStop implements Action {
    readonly type: CartActionTypes.AskToStop = CartActionTypes.AskToStop;

    constructor() {}
}

export class AskToStopSuccess implements Action {
    readonly type: CartActionTypes.AskToStopSuccess = CartActionTypes.AskToStopSuccess;

    constructor() {}
}

export class AskToStopError implements Action {
    readonly type: CartActionTypes.AskToStopError = CartActionTypes.AskToStopError;

    constructor() {}
}

export class InitCart implements Action {
    readonly type = CartActionTypes.InitCart;

    constructor(public payload: { cart: Cart; travelType?: TravelType | null; chooseTicketManually: boolean }) {}
}

export class PrepareArticlesBundleSuccess implements Action {
    readonly type: CartActionTypes.PrepareArticlesBundleSuccess = CartActionTypes.PrepareArticlesBundleSuccess;

    constructor(public payload: { articlesBundle: ArticlesBundle }) {}
}

export class UpdateCart implements Action {
    readonly type: CartActionTypes.UpdateCart = CartActionTypes.UpdateCart;
}

export class SetCheckoutStep implements Action {
    readonly type = CartActionTypes.SetCheckoutStep;

    constructor(public payload: CheckoutStep) {}
}

// https://moviplus.atlassian.net/wiki/spaces/BDOR/pages/2461597697/2022-05-11+-+Maxime+-+TRAASMOBIL-2371+Am+lioration+UX+billets+multizones
export class SetSelectedZones implements Action {
    readonly type = CartActionTypes.SetSelectedZones;

    constructor(public selectedZones: SelectedZone) {}
}

export class SetSelectedPaymentMean implements Action {
    readonly type = CartActionTypes.SetSelectedPaymentMean;

    constructor(public selected: boolean) {}
}

export class SetNationalMarketingConsent implements Action {
    readonly type = CartActionTypes.SetNationalMarketingConsent;

    constructor(public nationalMarketingConsent: boolean) {}
}

export class SetMarketingConsentsLoading implements Action {
    readonly type = CartActionTypes.SetMarketingConsentsLoading;

    constructor(public isLoading: boolean) {}
}

export class UpdateCartFail implements Action {
    readonly type: CartActionTypes.UpdateCartFail = CartActionTypes.UpdateCartFail;
}

export class UpdateCartSuccess implements Action {
    readonly type: CartActionTypes.UpdateCartSuccess = CartActionTypes.UpdateCartSuccess;
}

export class UpdateSaveOperationOfCart implements Action {
    readonly type: CartActionTypes.UpdateSaveOperationOfCart = CartActionTypes.UpdateSaveOperationOfCart;

    constructor(public payload: { isChecked: boolean }) {}
}

export class UpdateBuyOperationOfCart implements Action {
    readonly type: CartActionTypes.UpdateBuyOperationOfCart = CartActionTypes.UpdateBuyOperationOfCart;

    constructor(public payload: { isChecked: boolean }) {}
}

export class UpdateStopRequestOperationOfCart implements Action {
    readonly type: CartActionTypes.UpdateStopRequestOperationOfCart = CartActionTypes.UpdateStopRequestOperationOfCart;

    constructor(public payload: { isChecked: boolean }) {}
}

export class RunOperationsOfCart implements Action {
    readonly type: CartActionTypes.RunOperationsOfCart = CartActionTypes.RunOperationsOfCart;

    constructor(public payload: { guestCustomer?: GuestCustomer }) {}
}

export class PrepareArticlesBundle implements Action {
    readonly type: CartActionTypes.PrepareArticlesBundle = CartActionTypes.PrepareArticlesBundle;

    constructor(public payload: { articlesBundle: ArticlesBundle }) {}
}

export class SetDefaultArticlesBundle implements Action {
    readonly type: CartActionTypes.SetDefaultArticlesBundle = CartActionTypes.SetDefaultArticlesBundle;

    constructor(
        public payload: {
            articlesBundle: ArticlesBundle;
            travelClass: TravelClassType;
        },
    ) {}
}

export class ResetCart implements Action {
    readonly type: CartActionTypes.ResetCart = CartActionTypes.ResetCart;
}

// ArticlesBundle operations

export class UpdateArticlesBundle implements Action {
    readonly type: CartActionTypes.UpdateArticlesBundle = CartActionTypes.UpdateArticlesBundle;

    constructor(public payload: { articlesBundle: ArticlesBundle }) {}
}

export class RemoveArticlesBundle implements Action {
    readonly type: CartActionTypes.RemoveArticlesBundle = CartActionTypes.RemoveArticlesBundle;

    constructor(public payload: { articlesBundle: ArticlesBundle }) {}
}

export class PrepareArticlesBundleError implements Action {
    readonly type: CartActionTypes.PrepareArticlesBundleError = CartActionTypes.PrepareArticlesBundleError;

    constructor() {}
}

export class SetTypeSelected implements Action {
    readonly type = CartActionTypes.SetTypeSelected;

    constructor(public payload: ConfigureTicketType) {}
}

export class SetClassSelected implements Action {
    readonly type = CartActionTypes.SetClassSelected;

    constructor(public payload: { travelClassType: TravelClassType }) {}
}

export class SetPriceTypeSelected implements Action {
    readonly type = CartActionTypes.SetPriceTypeSelected;

    constructor(public payload: { priceType: PriceType }) {}
}

export class SetTravelTypeSelected implements Action {
    readonly type = CartActionTypes.SetTravelTypeSelected;

    constructor(public payload: { travelType: TravelType }) {}
}

export class SetDurationsFilter implements Action {
    readonly type = CartActionTypes.SetDurationsFilter;

    constructor(public payload: { durations: TicketDuration[] }) {}
}

export type CartAction =
    | InitCart
    | UpdateCart
    | UpdateCartFail
    | UpdateBuyOperationOfCart
    | UpdateCartSuccess
    | UpdateSaveOperationOfCart
    | UpdateStopRequestOperationOfCart
    | RunOperationsOfCart
    | PrepareArticlesBundle
    | PrepareArticlesBundleSuccess
    | UpdateArticlesBundle
    | RemoveArticlesBundle
    | ResetCart
    | Save
    | SaveSuccess
    | SaveError
    | AskToStop
    | AskToStopSuccess
    | AskToStopError
    | PrepareArticlesBundleError
    | SetDefaultArticlesBundle
    | SetCheckoutStep
    | SetTypeSelected
    | SetClassSelected
    | SetPriceTypeSelected
    | SetTravelTypeSelected
    | SetDurationsFilter
    | SetSelectedZones
    | SetSelectedPaymentMean
    | SetNationalMarketingConsent
    | SetMarketingConsentsLoading;
