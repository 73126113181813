import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { PhysicalStopAdapter, SyntheseInvalidResponseError } from '@traas/boldor/all-models';
import {
    GetByBoundingBoxGQL,
    GetByBoundingBoxQueryVariables,
    GetByCoordinatesGQL,
    GetByCoordinatesQuery,
    GetByCoordinatesQueryVariables,
} from '@traas/boldor/graphql-generated/graphql';
import { BoldorLocalizationService } from '@traas/common/localization';
import { LatLng, LatLngBounds } from 'leaflet';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ItineraryAdapter } from '../../../models/itinerary/itinerary';

@Injectable()
export class PhysicalStopService {
    static getPhysicalStopsFromItineraries(itineraries: ItineraryAdapter[]): PhysicalStopAdapter[] {
        const departureStops = itineraries.map((itinerary) => itinerary.getDepartureStop());
        return departureStops.map((departureStop) => departureStop.getPhysicalStop()).filter((stop) => !!stop);
    }

    constructor(
        private getByCoordinatesGQL: GetByCoordinatesGQL,
        private getByBoundingBoxGQL: GetByBoundingBoxGQL,
        private boldorLocalizationService: BoldorLocalizationService,
    ) {}

    async fetchPhysicalStopsInBBox(bounds: LatLngBounds): Promise<PhysicalStopAdapter[]> {
        if (!bounds) {
            return [];
        }
        const getByBoundingBoxQueryVariables = this.createGetByBoundingBoxQueryVariables(bounds);
        const $getByBoundingBoxGQL = this.getByBoundingBoxGQL.fetch(getByBoundingBoxQueryVariables);
        const $physicalStopAdapters = $getByBoundingBoxGQL.pipe(
            map((response) => {
                return response.data?.physicalStops?.byBoundingBox
                    .filter(({ geometry }) => !!geometry)
                    .map((physicalStop) => new PhysicalStopAdapter(physicalStop));
            }),
            catchError((error) => {
                return of([]);
            }),
        );
        return firstValueFrom($physicalStopAdapters);
    }

    $getPhysicalStopsByLatLng(latLng: LatLng): Observable<PhysicalStopAdapter[]> {
        if (!latLng) {
            return of([]);
        }
        const getByCoordinatesQueryVariables = this.createGetByCoordinatesQueryVariables(latLng);
        const $getByCoordinatesGQL = this.getByCoordinatesGQL.fetch(getByCoordinatesQueryVariables);
        return $getByCoordinatesGQL.pipe(
            map((response) => {
                this.checkGetByCoordinatesResponse(response, getByCoordinatesQueryVariables);
                return response.data.physicalStops.byCoordinates
                    .filter(({ geometry }) => !!geometry)
                    .map((physicalStop) => new PhysicalStopAdapter(physicalStop));
            }),
        );
    }

    private async checkGetByCoordinatesResponse(
        response: ApolloQueryResult<GetByCoordinatesQuery>,
        getByCoordinatesQueryVariables: GetByCoordinatesQueryVariables,
    ): Promise<void> {
        const isValidResponse = !!response && response.data?.physicalStops?.byCoordinates.length >= 0;
        if (!isValidResponse) {
            const stopsLoadFail = await this.boldorLocalizationService.get('error-message.stop-load-fail');
            throw new SyntheseInvalidResponseError(
                'The response is empty and/or not matching with the defined data model.',
                getByCoordinatesQueryVariables,
                response,
                'getByCoordinatesGQL',
                {
                    fr: stopsLoadFail,
                },
            );
        }
    }

    private createGetByBoundingBoxQueryVariables(bounds: LatLngBounds): GetByBoundingBoxQueryVariables {
        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();
        return {
            boundingBox: {
                northEast: {
                    latitude: northEast.lat,
                    longitude: northEast.lng,
                },
                southWest: {
                    latitude: southWest.lat,
                    longitude: southWest.lng,
                },
            },
        };
    }

    private createGetByCoordinatesQueryVariables(latLng: LatLng): GetByCoordinatesQueryVariables {
        return {
            coordinates: {
                longitude: latLng.lng,
                latitude: latLng.lat,
            },
        };
    }
}

export function distinctStopsById(stops: PhysicalStopAdapter[]): PhysicalStopAdapter[] {
    return stops.filter((currentStop, index, self) => {
        return (
            self.findIndex((subCurrentStop) => {
                return subCurrentStop.getId() === currentStop.getId();
            }) === index
        );
    });
}
