import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { LineService } from '../../../../services/common/line/line.service';
import { ExpandableComponent } from '../../../../components/expandable/expandable.component';
import { areThirdPartyPaidTickets, getFormattedTotalPrice } from '@traas/boldor/all-helpers';
import { DepartureAdapter } from '../../../../models/departure/departure';
import {
    DepartureOrderViewModel,
    HumanReadableStop,
    LineStyle,
    OrderViewModel,
    QuickTicketsOrderViewModel,
    TransitStop,
} from '@traas/boldor/all-models';
import { isTrain } from '../../../../business-rules.utils';
import { Line } from '@traas/common/models';

@Component({
    selector: 'app-departure-ticket-summary',
    templateUrl: './departure-ticket-summary.component.html',
    styleUrls: ['./departure-ticket-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartureTicketSummaryComponent extends ExpandableComponent implements OnInit {
    @Input() isRefundable = false;

    @Input() set departureOrder(order: OrderViewModel) {
        this.departureOrderValue = order as DepartureOrderViewModel;
    }

    get departureOrder(): DepartureOrderViewModel {
        return this.departureOrderValue;
    }

    @Input() showCollapseButton = false;
    @Input() showOperationsIcon = true;
    @Input() order: OrderViewModel;
    @Input() transitStops: TransitStop[] = [];

    @Output() refundOrder = new EventEmitter<DepartureOrderViewModel>();

    departureDate: string;
    departureStop: HumanReadableStop;
    lines: Line[];
    lineStyle: LineStyle;
    paddingCol: any = { top: '.3rem', bottom: '.3rem', left: null, right: null };
    totalPrice: string;
    private departureOrderValue: DepartureOrderViewModel;

    constructor(protected renderer: Renderer2) {
        super(renderer);
    }

    ngOnInit(): void {
        const departureAdapter = new DepartureAdapter(this.departureOrder.departure);
        this.departureStop = departureAdapter.getDepartureStop();
        this.departureDate = this.departureOrder.departure.plannedDepartureTime;
        this.lineStyle = LineService.from(departureAdapter);
        this.lines = departureAdapter.getLines();
        if (this.departureOrder.tickets.length > 0) {
            this.totalPrice = this.getTotalAmount();
        }
    }

    expandDetails(): void {
        if (this.showCollapseButton) {
            this.updateExpandedState();
        }
    }

    trackById(index: number, stop: TransitStop): string {
        return stop.id;
    }

    confirmRefundOrder(): void {
        this.refundOrder.emit(this.departureOrder);
    }

    areThirdPartyPaidTickets(order: QuickTicketsOrderViewModel): boolean {
        return areThirdPartyPaidTickets(order);
    }

    isTrain(transport: string): boolean {
        return isTrain(transport);
    }

    private getTotalAmount(): string {
        return getFormattedTotalPrice(this.departureOrder.tickets);
    }
}
