import { wktToGeoJSON } from '@terraformer/wkt';
import { Point } from 'geojson';
import * as L from 'leaflet';
import { DivIcon, divIcon, DivIconOptions, latLng, LatLng, LayerGroup, Marker } from 'leaflet';
import 'leaflet.markercluster';
import { CustomMarker } from './custom-marker';
import { getCityName, getLatLng, getName, getTrack } from './physical-stop.helper';
import { TrackHelper } from './track.helper';
import { Company, PhysicalStop } from '@traas/common/models';

export function createLatLngFromStopGeometry(geometry: string): LatLng {
    const geoJson = wktToGeoJSON(geometry) as Point;
    const [lng, lat] = geoJson.coordinates;
    return latLng(lat, lng);
}

interface TracksLayerBuildOptions {
    iconSize: [number, number];
    iconAnchor: [number, number];
    popupAnchor: [number, number];
    markerClusterGroupOptions: L.MarkerClusterGroupOptions;
    classByCompany: string;
    defaultPlaceForStop: LatLng;
    currentCompany: Company;
    hideTrackType?: boolean;
    useSvgIcon: boolean;
}

export function buildTracksLayerFromPhysicalStops(stops: PhysicalStop[], options: TracksLayerBuildOptions): LayerGroup {
    const markers = stops.map((stop) => {
        return createStopMarkerFrom({
            latLng: getLatLng(stop, options.defaultPlaceForStop),
            stopName: getName(stop),
            stopCityName: getCityName(stop),
            track: getTrack(stop),
            iconSize: options.iconSize,
            iconAnchor: options.iconAnchor,
            popupAnchor: options.popupAnchor,
            classByCompany: options.classByCompany,
            currentCompany: options.currentCompany,
            networkId: stop.lines[0]?.networkId,
            hideTrackType: options.hideTrackType,
            trackIconSvgPath: options.useSvgIcon ? TrackHelper.getTrackIconPath(getTrack(stop)) : undefined,
        });
    });
    return buildGenericLayer<L.MarkerClusterGroup>(
        L.markerClusterGroup({ ...options.markerClusterGroupOptions, disableClusteringAtZoom: 1 }),
        markers,
    );
}

export function buildGenericLayer<T extends LayerGroup>(layer: T, markers: Marker[]): T {
    markers.forEach((marker) => layer.addLayer(marker));
    return layer;
}

export function buildPhysicalStopsLayerFrom(
    stops: PhysicalStop[],
    iconSize: [number, number],
    popupAnchor: [number, number],
    options: L.MarkerClusterGroupOptions,
    companyClassName: string,
): LayerGroup {
    const markers = stops.map((stop) => {
        if (!stop.letter) {
            console.debug('No letter on this stop: ', stop.id);
        }
        const stopIcon = createPhysicalStopDivIconFrom(stop.letter ?? '', iconSize, popupAnchor, {}, companyClassName);
        return new Marker(createLatLngFromStopGeometry(stop.geometry), { icon: stopIcon });
    });
    const layerGroup = L.markerClusterGroup(options);
    return buildGenericLayer<L.MarkerClusterGroup>(layerGroup, markers);
}

function createPhysicalStopDivIconFrom(
    track: string,
    iconSize: [number, number],
    popupAnchor: [number, number],
    iconOptions: DivIconOptions,
    cssClass: string = '',
): DivIcon {
    return divIcon({
        html: `<div class="stop-marker-circle ${cssClass}" data-after-content="${track ?? ''}"></div>`,
        className: `ni-cluster`,
        iconSize, //: [10, 10],
        popupAnchor, //: [-10 / 2, -10 / 2],
        ...iconOptions,
    });
}

interface StopMarkerOptions {
    latLng: LatLng;
    stopName: string;
    stopCityName: string;
    track: string;
    iconSize: [number, number];
    iconAnchor: [number, number];
    popupAnchor: [number, number];
    classByCompany: string;
    currentCompany: Company;
    networkId?: string;
    hideTrackType?: boolean;
    trackIconSvgPath?: string;
}

function createStopMarkerFrom(options: StopMarkerOptions): Marker {
    const stopIcon = createStopDivIconFrom({
        track: options.track,
        classByCompany: options.classByCompany,
        currentCompany: options.currentCompany,
        iconSize: options.iconSize,
        iconAnchor: options.iconAnchor,
        popupAnchor: options.popupAnchor,
        networkId: options.networkId ?? '',
        hideTrackType: options.hideTrackType,
        trackIconSvgPath: options.trackIconSvgPath,
    });
    return new CustomMarker(options.latLng, {
        icon: stopIcon,
        zIndexOffset: 50,
        stopName: options.stopName,
        stopCityName: options.stopCityName,
        letter: options.track,
        networkId: options.networkId ?? '',
    });
}

interface StopDivIconOptions {
    track: string;
    classByCompany: string;
    currentCompany: Company;
    iconSize: [number, number];
    iconAnchor: [number, number];
    popupAnchor: [number, number];
    networkId?: string;
    hideTrackType?: boolean;
    trackIconSvgPath?: string;
}

function createStopDivIconFrom(options: StopDivIconOptions): DivIcon {
    const isDoubleDigitTrack = TrackHelper.isDoubleDigitTrack(options.track);
    const trackType = TrackHelper.getTrackType(options.track);

    // Specific for warning type of DivIcon
    const isDoubleDigitClass = isDoubleDigitTrack ? 'is-double-digit-track' : '';

    let trackIcon = '';
    if (options.trackIconSvgPath) {
        trackIcon = `<img class="stop-marker-square" src="${options.trackIconSvgPath}"/>`;
    } else {
        trackIcon = `
        <div class="stop-marker-square sbb-track ${isDoubleDigitClass}">
            ${options.hideTrackType ? '' : `<span class="track">${trackType}</span>`}
            <span class="number">${options.track ?? ''}</span>
        </div>`;
    }

    const html = `
        ${trackIcon}
        <div class="bar ${options.classByCompany}"> </div>
    `;

    return divIcon({
        html,
        className: `ni-cluster`,
        iconSize: options.iconSize,
        iconAnchor: options.iconAnchor,
        popupAnchor: options.popupAnchor,
    });
}
