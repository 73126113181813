import { StartupNotificationState } from './startup-notification-state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectStartupNotificationState = createFeatureSelector<StartupNotificationState>('startupNotification');

export const getPendingMessages = createSelector(
    selectStartupNotificationState,
    (state: StartupNotificationState) => state.pendingMessages,
);

export const getCurrentMessage = createSelector(selectStartupNotificationState, (state: StartupNotificationState) => state.currentMessage);

export const hasPendingMessage = createSelector(selectStartupNotificationState, (state: StartupNotificationState) => {
    return !state?.isAllClear || false;
});
