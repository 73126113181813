import { calculateBounds } from '@terraformer/spatial';
import {
    feature,
    featureCollection,
    geometryCollection,
    isDrawable,
    isLineString,
    midPathPoint,
    point,
    SyntheseDateHelper,
    toGeoJson,
} from '@traas/boldor/all-helpers';
import {
    AbstractAdapter,
    CssClass,
    FeatureProperties,
    HumanReadableDirection,
    ItineraryStop,
    Leg,
    LineAdapter,
    Vehicle,
} from '@traas/boldor/all-models';
import { getDelayInMinutes } from '../../business-rules.utils';
import { StopAdapter } from './stop';
import { LatLngBounds, LatLngTuple } from 'leaflet';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Duration } from 'moment';
import { GeoJSON } from 'geojson';
import { ErrorCodes, Line, PhysicalStop, TechnicalError } from '@traas/common/models';

export class LegAdapter extends AbstractAdapter<Leg> implements HumanReadableDirection {
    constructor(data: Leg) {
        super(data);
    }

    getTransitStopsDidok(): number[] {
        let transitsStops = [...this.getStops()];
        if (transitsStops.length > 0) {
            transitsStops = _.drop(_.dropRight(transitsStops));
            return transitsStops.map(({ didok }: ItineraryStop) => didok).filter((didok) => !!didok) as number[];
        }
        return [];
    }

    getAdditionalInformation(): string[] {
        return this.getData().additionalInformation ?? [];
    }

    getTransitStops(): ItineraryStop[] {
        const transitsStops = [...this.getStops()];
        if (transitsStops.length > 0) {
            return _.drop(_.dropRight(transitsStops));
        }
        return [];
    }

    isEqual(leg: LegAdapter): boolean {
        const stops = _.zip(this.getStopAdapters(), leg?.getStopAdapters() ?? []) as [StopAdapter, StopAdapter][];

        return this.data.line.id === leg.data.line.id && stops.every(([thisStop, legStop]) => thisStop.isEqual(legStop));
    }

    getFirstStop(): ItineraryStop {
        return this.getStops()[0];
    }

    getFirstPhyisicalStop(): PhysicalStop | undefined {
        return this.getFirstStop().physicalStopAssociated;
    }

    getTransportCode(): string | null {
        const { code } = this.getVehicle();
        if (!code) {
            console.log(`No transport code.`);
            return null;
        }
        return code;
    }

    getTransportName(): string {
        const { transport } = this.getVehicle();
        return transport;
    }

    getTransportCompanyId(): string {
        return this.data.transportCompanyId;
    }

    getScheduledDepartureDate(): Date {
        const firstStop = this.getFirstStop();
        if (!firstStop.scheduledDepartureTime) {
            throw new TechnicalError(`Leg adapter: no scheduled departure time on stop`, ErrorCodes.Itinerary.AdapterError, undefined, {
                stop: firstStop,
            });
        }
        return SyntheseDateHelper.parseSyntheseStringDate(firstStop.scheduledDepartureTime);
    }

    getIsFirstClassAuthorized(): boolean {
        return this.data.isFirstClassAuthorized;
    }

    getDepartureDate(): Date {
        return SyntheseDateHelper.parseSyntheseStringDate(this.getFirstStop().departureTime);
    }

    getDuration(): Duration {
        const start = moment(this.getDepartureDate());
        const roundDownStart = start.seconds(0);
        const end = moment(this.getArrivalDate());

        const scheduledArrivalDateRoundedUpMinute =
            end.second() || end.millisecond() ? end.add(1, 'minute').startOf('minute') : end.startOf('minute');

        return moment.duration(scheduledArrivalDateRoundedUpMinute.diff(roundDownStart));
    }

    getDepartureDelay(): string {
        const { departureTime, scheduledDepartureTime } = this.getFirstStop();
        const roundDownStart = moment(departureTime).seconds(0);
        const departureDateTime = moment(roundDownStart).toDate();
        const scheduledDateTime = moment(scheduledDepartureTime).toDate();
        return getDelayInMinutes(departureDateTime, scheduledDateTime);
    }

    getArrivalDelay(): string {
        const { arrivalTime } = this.getLastStop();
        const arrivalDateTime = moment(arrivalTime).toDate();
        const scheduledArrivalDateTime = moment(this.getScheduledArrivalTime()).toDate();
        return getDelayInMinutes(arrivalDateTime, scheduledArrivalDateTime);
    }

    getArrivalDate(): Date {
        return SyntheseDateHelper.parseSyntheseStringDate(this.getLastStop().arrivalTime);
    }

    getScheduledArrivalTime(): Date {
        let dateToUse: moment.Moment;
        const hasScheduledArrivalTime = !!this.getLastStop().scheduledArrivalTime;
        if (hasScheduledArrivalTime) {
            dateToUse = moment(this.getLastStop().scheduledArrivalTime);
        } else {
            dateToUse = moment(this.getArrivalDate());
        }

        const result =
            dateToUse.second() || dateToUse.millisecond() ? dateToUse.add(1, 'minute').startOf('minute') : dateToUse.startOf('minute');
        return result.toDate();
    }

    getLastStop(): ItineraryStop {
        return this.getStops()[this.getStops().length - 1];
    }

    getLastPhyisicalStop(): PhysicalStop | undefined {
        return this.getLastStop().physicalStopAssociated;
    }

    getStopAdapters(): StopAdapter[] {
        return this.getStops().map((stop) => new StopAdapter(stop));
    }

    getStops(): ItineraryStop[] {
        return this.data.stops;
    }

    hasStop(stop: StopAdapter): boolean {
        return this.getStops().some((aStop) => stop.isEqual(new StopAdapter(aStop)));
    }

    getDirection(): string | null {
        const direction = this.getLine().destination;
        if (!direction) {
            console.log(`No direction on line ${this.data.line.number}`);
            return null;
        }
        return direction;
    }

    getVehicle(): Vehicle {
        return this.data.vehicle;
    }

    isByFoot(): boolean {
        return this.data.byFoot;
    }

    getLineAdapter(): LineAdapter {
        return new LineAdapter(this.data.line);
    }

    getDistanceInMeters(): number {
        if (!this.data?.length) {
            return NaN;
        }
        return +this.data?.length;
    }

    getWalkDuration(): string {
        return this.data.duration;
    }

    getServiceNumber(): string | null {
        const serviceNumber = this.data.serviceNumber;
        if (!serviceNumber) {
            console.log(`No service number on leg ${this.data.serviceId}`);
            return null;
        }
        return serviceNumber;
    }

    getGeoJson(): GeoJSON | null {
        const { wkt, stops, byFoot, line } = this.data;

        if (!wkt) {
            return null;
        }

        const path = toGeoJson(wkt);
        if (!isLineString(path) || !isDrawable(path)) {
            return null;
        }

        const midPoint = midPathPoint(path);
        const breadcrumbs = stops.map(({ latLon: [latitude, longitude] }) => point([longitude, latitude]));

        const cssByFoot: CssClass[] = byFoot ? ['footpath'] : [];

        return featureCollection(
            feature<FeatureProperties>(path, {
                cssClasses: cssByFoot,
                line,
            }),
            feature<FeatureProperties>(midPoint, {
                cssClasses: ['midpoint', ...cssByFoot],
                line,
            }),
            feature<FeatureProperties>(geometryCollection(...breadcrumbs), {
                cssClasses: ['breadcrumb'],
                line,
            }),
        );
    }

    getBbox(): LatLngBounds | null {
        const geoJson = this.getGeoJson();
        if (!geoJson) {
            return null;
        }
        const [west, south, east, north] = calculateBounds(geoJson);
        const southWest: LatLngTuple = [south, west];
        const northEast: LatLngTuple = [north, east];
        return new LatLngBounds(southWest, northEast);
    }

    /**
     * See getLineAdapter to get the LineAdapter
     */
    getLine(): Line {
        return this.data.line;
    }

    private hasLegDisruption(): boolean {
        return this.data.messages.length > 0 ?? false;
    }
}
