import { DatepickerMode, initialState } from './datepicker.state';
import { createReducer, on } from '@ngrx/store';
import { resetToAuto, setFixedDate } from './datepicker.action';

export const datePickerReducer = createReducer(
    initialState,

    on(resetToAuto, () => ({ mode: DatepickerMode.AUTO, value: new Date() })),
    on(setFixedDate, (state, { date }) => ({ ...state, value: date, mode: DatepickerMode.FIXED })),
);
