<ion-header>
    <app-page-title defaultHref="/tabs/bookings" title=""></app-page-title>
</ion-header>
<ion-content fullscreen>
    <ng-container *ngIf="$order | async as order; else isLoading">
        <div class="flex tickets summary">
            <app-itinerary-ticket-summary-item
                *ngIf="isItinerary(order)"
                [itineraryOrder]="order"
                [showCollapseButton]="true"
                [showOperationsIcons]="true"
                [order]="order"
                (refundOrder)="onConfirmRefundOrder($event)"
                [isRefundable]="$isRefundable | async"
            ></app-itinerary-ticket-summary-item>
            <app-quick-ticket-summary-item
                *ngIf="isQuick(order)"
                [order]="order"
                [showOperationsIcons]="false"
                [quickTicket]="order"
                (refundOrder)="onConfirmRefundOrder($event)"
                [isRefundable]="$isRefundable | async"
            ></app-quick-ticket-summary-item>
            <app-departure-ticket-summary
                *ngIf="isDeparture(order)"
                [departureOrder]="order"
                [showCollapseButton]="true"
                [showOperationsIcon]="false"
                [transitStops]="$transitStops | async"
                [order]="order"
                (refundOrder)="onConfirmRefundOrder($event)"
                [isRefundable]="$isRefundable | async"
            ></app-departure-ticket-summary>
        </div>
        <div *ngIf="$hasStopRequest | async" class="flex tickets-stop-request">
            <app-itinerary-ticket-stop-request (cancelStopRequest)="onCancelStopRequest(order.id)"></app-itinerary-ticket-stop-request>
        </div>
        <div *ngFor="let ticket of order.tickets" class="tickets list">
            <app-ticket-order [ticket]="ticket"></app-ticket-order>
        </div>
    </ng-container>
    <ng-template #isLoading>
        <div class="fullscreen">
            <ion-spinner></ion-spinner>
        </div>
    </ng-template>
</ion-content>
