import { CreditCard, OrderViewModel } from '@traas/boldor/all-models';
import { ArticleType } from './payment.state';
import { Action } from '@ngrx/store';

export enum PaymentActionTypes {
    BuyArticles = '[Payment] Buy articles...',
    BuyArticlesSuccess = '[Payment] Buy articles success',
    BuyArticlesFail = '[Payment] Buy articles fail',
    ProcessPayment = '[Payment] Processing of payment...',
    ProcessPaymentSuccess = '[Payment] Processing of payment success',
    ProcessPaymentFail = '[Payment] Processing of payment fail',
    CheckTicketGeneration = '[Payment] Check ticket generation...',
    GenerateTicketSuccess = '[Payment] Ticket generation success',
    GenerateTicketFail = '[Payment] Ticket generation fail',
    TransactionDone = '[Payment] Transaction done',
    ResetPaymentState = '[Payment] Reset state',
    CancelPayment = '[Payment] Cancel payment',
    CancelPaymentSuccess = '[Payment] Cancel payment success',
    PaymentTimedOut = '[Payment] Payment timed out',
    RecoveryFailed = '[Payment] Ticket recovery failed',
    SetCardPaymentMethodName = '[Payment] Set card payment method name',
    SetMobilePaymentMethodName = '[Payment] Set mobile payment method name',
    SetPaymentMethodName = '[Payment] Set payment method name',
}

export class BuyArticles implements Action {
    readonly type: PaymentActionTypes.BuyArticles = PaymentActionTypes.BuyArticles;

    constructor(
        public payload: {
            articleType: ArticleType;
            createCreditCard: boolean;
            selectedCreditCard: CreditCard;
        },
    ) {}
}

export class BuyArticlesSuccess implements Action {
    readonly type: PaymentActionTypes.BuyArticlesSuccess = PaymentActionTypes.BuyArticlesSuccess;

    constructor(public payload: { orderId: string }) {}
}

export class BuyArticlesFail implements Action {
    readonly type: PaymentActionTypes.BuyArticlesFail = PaymentActionTypes.BuyArticlesFail;
}

export class ProcessPayment implements Action {
    readonly type: PaymentActionTypes.ProcessPayment = PaymentActionTypes.ProcessPayment;
}

export class ProcessPaymentSuccess implements Action {
    readonly type: PaymentActionTypes.ProcessPaymentSuccess = PaymentActionTypes.ProcessPaymentSuccess;
}

export class ProcessPaymentFail implements Action {
    readonly type: PaymentActionTypes.ProcessPaymentFail = PaymentActionTypes.ProcessPaymentFail;
}

export class CheckTicketGeneration implements Action {
    readonly type: PaymentActionTypes.CheckTicketGeneration = PaymentActionTypes.CheckTicketGeneration;
}

export class GenerateTicketSuccess implements Action {
    readonly type: PaymentActionTypes.GenerateTicketSuccess = PaymentActionTypes.GenerateTicketSuccess;
}

export class GenerateTicketFail implements Action {
    readonly type: PaymentActionTypes.GenerateTicketFail = PaymentActionTypes.GenerateTicketFail;
}

export class ResetPaymentState implements Action {
    readonly type: PaymentActionTypes.ResetPaymentState = PaymentActionTypes.ResetPaymentState;
}

export class TransactionDone implements Action {
    readonly type: PaymentActionTypes.TransactionDone = PaymentActionTypes.TransactionDone;

    constructor(public payload: { order: OrderViewModel; isGuestCustomer: boolean }) {}
}

export class CancelPayment implements Action {
    readonly type: PaymentActionTypes.CancelPayment = PaymentActionTypes.CancelPayment;
}

export class PaymentTimedOut implements Action {
    readonly type: PaymentActionTypes.PaymentTimedOut = PaymentActionTypes.PaymentTimedOut;
}

export class RecoveryFailed implements Action {
    readonly type: PaymentActionTypes.RecoveryFailed = PaymentActionTypes.RecoveryFailed;
}

export class SetCardPaymentMethodName implements Action {
    readonly type: PaymentActionTypes.SetCardPaymentMethodName = PaymentActionTypes.SetCardPaymentMethodName;

    constructor(public cardPaymentMethodName: string | null) {}
}

export class SetMobilePaymentMethodName implements Action {
    readonly type: PaymentActionTypes.SetMobilePaymentMethodName = PaymentActionTypes.SetMobilePaymentMethodName;

    constructor(public mobilePaymentMethodName: string | null) {}
}

export class SetPaymentMethodName implements Action {
    readonly type: PaymentActionTypes.SetPaymentMethodName = PaymentActionTypes.SetPaymentMethodName;

    constructor(public paymentMethodName: string | null) {}
}

export class CancelPaymentSuccess implements Action {
    readonly type: PaymentActionTypes.CancelPaymentSuccess = PaymentActionTypes.CancelPaymentSuccess;
}

export type PaymentAction =
    | BuyArticles
    | BuyArticlesSuccess
    | BuyArticlesFail
    | ProcessPayment
    | ProcessPaymentSuccess
    | ProcessPaymentFail
    | CheckTicketGeneration
    | GenerateTicketSuccess
    | GenerateTicketFail
    | ResetPaymentState
    | TransactionDone
    | CancelPayment
    | PaymentTimedOut
    | RecoveryFailed
    | SetPaymentMethodName
    | SetCardPaymentMethodName
    | CancelPaymentSuccess
    | SetMobilePaymentMethodName;
