<div class="group-items" [ngClass]="isCollapsed ? 'group-collapsed' : 'group-expanded'" (click)="toggleMenu()">
    <div class="menu-left">
        <ion-icon [src]="iconPath" [name]="ionIconName"></ion-icon>
        <span>{{ label }}</span>
    </div>
    <app-collapse-arrow [expanded]="!isCollapsed"></app-collapse-arrow>
</div>
<span class="sub-items">
    <ng-content></ng-content>
</span>
