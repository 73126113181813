import { MapAction, MapActionTypes } from './map.action';

import { initialState, MapState } from './map.state';

export function mapReducer(state: MapState = initialState, action: MapAction): MapState {
    switch (action.type) {
        case MapActionTypes.Moved:
            return {
                ...state,
                bounds: action.payload.bounds,
                centerPoint: action.payload.centerPoint,
                zoomLevel: action.payload.zoomLevel,
                updating: true,
                lastMoveSource: action.payload.source,
            };

        case MapActionTypes.UpdateLayers:
            return {
                ...state,
                updating: false,
            };

        case MapActionTypes.UpdateStopsInBounds:
            return {
                ...state,
                stopsInBounds: action.payload.inSafeArea,
            };

        case MapActionTypes.TooMuchStopsInBounds:
            return {
                ...state,
                hasTooManyStopsInBounds: action.payload,
                updating: false,
            };

        case MapActionTypes.Disable:
            return {
                ...state,
                isEnabled: !action.payload.disableAllListeners,
            };

        case MapActionTypes.Enable:
            return {
                ...state,
                isEnabled: action.payload.enableAllListeners,
            };

        case MapActionTypes.SetMapMode:
            return {
                ...state,
                mode: action.payload,
            };

        default:
            return state;
    }
}
