import { Directive, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

@Directive({
    selector: '[traasUserScroll]',
})
export class UserScrollDirective implements OnDestroy {
    @Output() userScrollStart = new EventEmitter<void>();
    @Output() userScrollEnd = new EventEmitter<void>();

    private $wheel = new Subject<void>();
    private wheelSubscription: Subscription[] = [];

    constructor() {
        // This is workaround for web mode
        this.wheelSubscription = [
            this.$wheel.pipe(throttleTime(2000)).subscribe(() => {
                this.userScrollStart.emit();
            }),
            this.$wheel.pipe(debounceTime(400)).subscribe(() => {
                this.userScrollEnd.emit();
            }),
        ];
    }

    ngOnDestroy(): void {
        this.wheelSubscription.forEach((sub) => sub.unsubscribe());
    }

    @HostListener('wheel')
    private onWheel(): void {
        this.$wheel.next();
    }

    @HostListener('touchstart')
    private touchStart(): void {
        this.userScrollStart.emit();
    }

    @HostListener('touchend', ['$event'])
    private touchEnd(event: TouchEvent): void {
        if (event.touches.length === 0) {
            this.userScrollEnd.emit();
        }
    }
}
