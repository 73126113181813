import * as L from 'leaflet';
import { DivIcon, DivIconOptions } from 'leaflet';
import { ChangePlaceEventSourceEnum, Place } from '@traas/boldor/all-models';
import { AddressDynamicPlaceAdapter } from './address-dynamic-place.adapter';

export class PoiDynamicPlaceAdapter extends AddressDynamicPlaceAdapter {
    protected readonly changePlaceSourceEvent: ChangePlaceEventSourceEnum = ChangePlaceEventSourceEnum.PoiSelection;

    constructor(data: Place) {
        super(data);
    }

    getDivIcon(iconOptions?: DivIconOptions): DivIcon {
        return L.divIcon({
            html: `
                <div class="place-focus">
                </div>
            `,
            className: `ni-cluster`,
            iconSize: [10, 10],
            popupAnchor: [-10 / 2, -10 / 2],
            ...iconOptions,
        });
    }
}
