<ion-grid>
    <ion-row class="header" [class.header-itinerary]="isItineraryMode">
        <div *ngIf="isDetail" class="back-button" [class.back-button-itinerary]="isItineraryMode">
            <ion-button
                [class.vibrate]="$vibrateBackButton | async"
                [disabled]="$isBackButonLocked | async"
                *ngIf="mode === ResultMode.DEPARTURE"
                size="small"
                (click)="onBackButtonClicked()"
            >
                <ion-icon name="arrow-back"></ion-icon>
            </ion-button>

            <ion-button *ngIf="mode !== ResultMode.DEPARTURE" size="small" oitClickUsingBackButton (click)="onBackButtonClicked()">
                <ion-icon name="arrow-back"></ion-icon>
            </ion-button>
        </div>
        <ion-col
            [class.is-departure-detail]="isDepartureMode && isDetail"
            [class.is-itinerary]="isItineraryMode"
            [class.no-padding-left]="!isDetail && (isTPG || isItineraryMode)"
        >
            <div *ngIf="showFilter && !($isGridMode | async); else notFilter" [class.is-detail]="isDetail" class="subtitle">
                <ion-button
                    [ngClass]="{ active: isFilterActive }"
                    size="small"
                    elevation="1"
                    class="filter-button e2e-filters"
                    (click)="onFilterClicked()"
                >
                    {{ 'home.options-button' | translate }}
                </ion-button>
                <ion-button *ngIf="!isDetail" size="small" elevation="1" class="e2e-time-display-mode" (click)="timeDisplayModeClicked()">
                    <ion-icon src="assets/icons/timer_choice.svg"></ion-icon>
                </ion-button>
            </div>
            <ng-template #notFilter>
                <div [class.is-detail]="isDetail" [class.is-itinerary]="isItineraryMode" class="subtitle">
                    <ion-button
                        size="small"
                        *ngIf="!isDetail"
                        elevation="1"
                        class="e2e-time-display-mode big-margin-left"
                        (click)="timeDisplayModeClicked()"
                    >
                        <ion-icon src="assets/icons/timer_choice.svg"></ion-icon>
                    </ion-button>
                </div>
            </ng-template>
        </ion-col>

        <ion-col class="datepicker" [class.is-itinerary]="isItineraryMode">
            <app-date-picker
                *ngIf="!($isGridMode | async) && ($pickedDatetime | async) as datetime"
                (dateSelected)="emitDateSelected($event)"
                (queryKindChange)="emitQueryKindChange($event)"
                (searchOptionChange)="emitSearchOptionChange($event)"
                [datePickerConfig]="datePickerConfig"
                [pickedDateTime]="datetime"
            ></app-date-picker>
        </ion-col>
    </ion-row>
</ion-grid>
<app-date-picker-behavior-badge [behavior]="$datepickerMode | async"></app-date-picker-behavior-badge>
