import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CartEffect } from './cart.effect';
import { cartReducer } from './cart.reducer';

@NgModule({
    declarations: [],
    imports: [StoreModule.forFeature('cart', cartReducer), EffectsModule.forFeature([CartEffect])],
    providers: [CartEffect],
})
export class CartStoreModule {}
