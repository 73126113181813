import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-places-list-item',
    templateUrl: './places-list-item.component.html',
    styleUrls: ['./places-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesListItemComponent {
    @Input() disabled: boolean;
    @Input() name: string;
    @Input() nameOrPath: string;

    @Output() selected = new EventEmitter<void>();

    emitSelected(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.selected.emit();
    }
}
