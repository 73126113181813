import { Env } from '../env.model';
import { travysAppConfig } from '@traas/boldor/environments/specific/travys/environment.travys.base';
// Ne pas simplifier cet import en ../ car ce fichier est fileReplacé dans un autre contexte où le ../ sera faux au build
import { SwissPassEnvEnum } from '@traas/boldor/environments/specific/SwissPassEnvEnum';

export const environment: Env = {
    ...travysAppConfig,
    appId: 'ch.travys.mobile',
    appSchemeUrl: 'travysmobile://boldor.ch/',
    environment: 'prod',
    gatewayUrl: 'https://gateway.traas.openit.ch',
    isDebugMode: false,
    showEnvironmentInformations: false,
    logSentry: true,
    traceSentry: false,
    analyticsTrackingEnabled: true,
    analyticsLoggingEnabled: false,
    analyticsUserIdHashSalt: 'GjVpGUxm2WnmpHLw',
    mobileSwisspass: {
        // compilera si on enlève cette valeur, mais l'app plantera sur une version mobile
        clientId: 'travys_mobile',
        deeplink: 'travysmobile', // IMPORTANT, when changing this deeplink, you must also change the trapeze configuration (config.*.yml)
        environment: SwissPassEnvEnum.Production,
        provider: 'travys',
    },
    analyticsWebConfig: {
        apiKey: 'AIzaSyC4V51mLn8MdzmI5RN_QVReVQWuIpoemdo',
        authDomain: 'travys-mobile.firebaseapp.com',
        projectId: 'travys-mobile',
        storageBucket: 'travys-mobile.appspot.com',
        messagingSenderId: '274124801740',
        appId: '1:274124801740:web:17b9842cfe8b77dfe4a469',
        measurementId: 'G-ZQLYWHMH2',
    },
    webSwisspassRedirectUri: 'http://l4200.openit.ch/web-auth-success',
};
