import { Injectable } from '@angular/core';
import { COMMERCIAL_STOPS_LAYER, EDGE_LAYER, PHYSICAL_STOPS_LAYER, SMALL_COMMERCIAL_STOPS_LAYER, TRACKS_LAYER } from '@traas/common/utils';
import { CompanyService } from '../../../services/common/company/company.service';
import { MapDisplayLevel, MapDisplayLevelConfiguration, MapDisplayLevelsConfiguration } from '@traas/boldor/all-models';
import { Company } from '@traas/common/models';

@Injectable({
    providedIn: 'root',
})
export class MapConfigurationService {
    private mapDisplayLevelsConfiguration: MapDisplayLevelsConfiguration;

    getMapDisplayLevelsConfiguration(): MapDisplayLevelsConfiguration {
        if (!this.mapDisplayLevelsConfiguration) {
            this.mapDisplayLevelsConfiguration = this.createMapDisplayLevelsConfiguration();
        }
        return this.mapDisplayLevelsConfiguration;
    }

    getDisplayLevelConfigurationByZoom(zoom: number): MapDisplayLevelConfiguration {
        const displayLevelsConfig = this.getMapDisplayLevelsConfiguration();
        for (const key of Object.keys(displayLevelsConfig)) {
            const displayLevelConfig = displayLevelsConfig[key] as MapDisplayLevelConfiguration;
            if (displayLevelConfig.isGoodZoomLevel(zoom)) {
                return displayLevelConfig;
            }
        }
        return displayLevelsConfig.TOO_HIGH;
    }

    createMapDisplayLevelConfigurationBy(displayLevel: MapDisplayLevel): MapDisplayLevelConfiguration {
        switch (displayLevel) {
            case MapDisplayLevel.GROUND:
                return this.createGroundDisplayLevelConfiguration();
            case MapDisplayLevel.LOW:
                return this.createLowDisplayLevelConfiguration();
            case MapDisplayLevel.MID:
                return this.createMidDisplayLevelConfiguration();
            case MapDisplayLevel.HIGH:
                return this.createHighDisplayLevelConfiguration();
            case MapDisplayLevel.TOO_HIGH:
            default:
                return this.createTooHighDisplayLevelConfiguration();
        }
    }

    getZoomLimitDependingOfTraasBy(displayLevel: MapDisplayLevel): number {
        switch (displayLevel) {
            case MapDisplayLevel.GROUND:
                return 20;
            case MapDisplayLevel.LOW:
                return 16;
            case MapDisplayLevel.MID:
                return 15;
            case MapDisplayLevel.HIGH:
                return 14;
            case MapDisplayLevel.TOO_HIGH:
            default:
                return 13;
        }
    }

    getZoomLimitDependingOfTPGBy(displayLevel: MapDisplayLevel): number {
        switch (displayLevel) {
            case MapDisplayLevel.GROUND:
                return 20;
            case MapDisplayLevel.LOW:
                return 17;
            case MapDisplayLevel.MID:
                return 16;
            case MapDisplayLevel.HIGH:
                return 15;
            case MapDisplayLevel.TOO_HIGH:
            default:
                return 14;
        }
    }

    private createMapDisplayLevelsConfiguration(): MapDisplayLevelsConfiguration {
        return {
            GROUND: this.createMapDisplayLevelConfigurationBy(MapDisplayLevel.GROUND),
            LOW: this.createMapDisplayLevelConfigurationBy(MapDisplayLevel.LOW),
            MID: this.createMapDisplayLevelConfigurationBy(MapDisplayLevel.MID),
            HIGH: this.createMapDisplayLevelConfigurationBy(MapDisplayLevel.HIGH),
            TOO_HIGH: this.createMapDisplayLevelConfigurationBy(MapDisplayLevel.TOO_HIGH),
        };
    }

    private createGroundDisplayLevelConfiguration(): MapDisplayLevelConfiguration {
        const groundZoomLimit = this.getZoomLimitDependingOfCurrentCompanyBy(MapDisplayLevel.GROUND);
        return {
            zoomLimit: groundZoomLimit,
            getLayersAssociated: (hasTooMuchCommercialStops: boolean) => {
                return hasTooMuchCommercialStops ? [PHYSICAL_STOPS_LAYER] : [TRACKS_LAYER, EDGE_LAYER, PHYSICAL_STOPS_LAYER];
            },
            isGoodZoomLevel: (zoom: number): boolean => {
                return zoom >= groundZoomLimit;
            },
        };
    }

    private createLowDisplayLevelConfiguration(): MapDisplayLevelConfiguration {
        const groundZoomLimit = this.getZoomLimitDependingOfCurrentCompanyBy(MapDisplayLevel.GROUND);
        const lowZoomLimit = this.getZoomLimitDependingOfCurrentCompanyBy(MapDisplayLevel.LOW);
        return {
            zoomLimit: lowZoomLimit,
            getLayersAssociated: (hasTooMuchCommercialStops: boolean) => {
                return hasTooMuchCommercialStops ? [PHYSICAL_STOPS_LAYER] : [TRACKS_LAYER, EDGE_LAYER, PHYSICAL_STOPS_LAYER];
            },
            isGoodZoomLevel: (zoom: number): boolean => {
                return this.isBetween(lowZoomLimit, groundZoomLimit, zoom);
            },
        };
    }

    private createMidDisplayLevelConfiguration(): MapDisplayLevelConfiguration {
        const lowZoomLimit = this.getZoomLimitDependingOfCurrentCompanyBy(MapDisplayLevel.LOW);
        const midZoomLimit = this.getZoomLimitDependingOfCurrentCompanyBy(MapDisplayLevel.MID);
        return {
            zoomLimit: midZoomLimit,
            getLayersAssociated: (hasTooMuchCommercialStops: boolean) => {
                return hasTooMuchCommercialStops ? [COMMERCIAL_STOPS_LAYER] : [TRACKS_LAYER, EDGE_LAYER, PHYSICAL_STOPS_LAYER];
            },
            isGoodZoomLevel: (zoom: number): boolean => {
                return this.isBetween(midZoomLimit, lowZoomLimit, zoom);
            },
        };
    }

    private createHighDisplayLevelConfiguration(): MapDisplayLevelConfiguration {
        const midZoomLimit = this.getZoomLimitDependingOfCurrentCompanyBy(MapDisplayLevel.MID);
        const highZoomLimit = this.getZoomLimitDependingOfCurrentCompanyBy(MapDisplayLevel.HIGH);
        return {
            zoomLimit: highZoomLimit,
            getLayersAssociated: (hasTooMuchCommercialStops: boolean) => {
                return hasTooMuchCommercialStops ? [COMMERCIAL_STOPS_LAYER] : [TRACKS_LAYER, EDGE_LAYER, PHYSICAL_STOPS_LAYER];
            },
            isGoodZoomLevel: (zoom: number): boolean => {
                return this.isBetween(highZoomLimit, midZoomLimit, zoom);
            },
        };
    }

    private createTooHighDisplayLevelConfiguration(): MapDisplayLevelConfiguration {
        const tooHighZoomLimit = this.getZoomLimitDependingOfCurrentCompanyBy(MapDisplayLevel.TOO_HIGH);
        const highZoomLimit = this.getZoomLimitDependingOfCurrentCompanyBy(MapDisplayLevel.TOO_HIGH);
        return {
            zoomLimit: tooHighZoomLimit,
            getLayersAssociated: () => {
                return [SMALL_COMMERCIAL_STOPS_LAYER];
            },
            isGoodZoomLevel: (zoom: number): boolean => {
                return zoom < highZoomLimit;
            },
        };
    }

    private isBetween(min: number, max: number, zoom: number): boolean {
        return zoom >= min && zoom < max;
    }

    private getZoomLimitDependingOfCurrentCompanyBy(displayLevel: MapDisplayLevel): number {
        const company: Company = CompanyService.getCurrentCompany();
        switch (company) {
            case Company.Tpg:
                return this.getZoomLimitDependingOfTPGBy(displayLevel);
            default:
                return this.getZoomLimitDependingOfTraasBy(displayLevel);
        }
    }
}
