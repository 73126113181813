import { AbstractAdapter } from './abstract.adapter';
import { Line } from '@traas/common/models';

export class LineAdapter extends AbstractAdapter<Line> {
    constructor(data: Line) {
        super(data);
    }

    getColor(fallback: string): string {
        return this.getData().color || fallback;
    }

    getLineId(): string {
        return this.getData().id;
    }
    getNumber(): string {
        return this.getData().number;
    }

    getNetworkId(): string {
        return this.getData().networkId || '';
    }
}
