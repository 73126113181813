import { AbstractAdapter } from '../abstract.adapter';
import { Ticket } from './ticket.model';
import { Currency } from './currency.enum';
import { Article } from './article.model';

export class TicketAdapter extends AbstractAdapter<Ticket> {
    constructor(data: Ticket) {
        super(data);
    }

    getCurrency(): Currency {
        const [{ currency }] = this.getArticle().prices;
        return currency;
    }

    getArticle(): Article {
        return this.getData().article;
    }

    getCategoryId(): string {
        return this.getArticle().category.id;
    }

    getSmsCode(): string {
        return this.getArticle().smsCode;
    }
}
