import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'menu-toggle',
    templateUrl: './menu-toggle.component.html',
    styleUrls: ['./menu-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuToggleComponent {
    @Input() iconPath: string;
    @Input() ionIconName: string;
    @Input() isCollapsed: boolean;
    @Input() label: string;

    toggleMenu(): void {
        this.isCollapsed = !this.isCollapsed;
    }
}
