<menu-toggle
    [label]="'menu.schedules' | translate"
    iconPath="assets/icons/tabs/monochrome/schedule.svg"
    class="e2e-menu-map-link"
    [isCollapsed]="isHoursMenuCollapsed"
    (click)="toggleHoursMenu()"
>
    <menu-entry (click)="navigateToHome()" [label]="'menu.next-departures' | translate" class="e2e-menu-map-link"></menu-entry>
    <menu-entry (click)="navigateToItinerary()" [label]="'menu.itineraries' | translate" class="e2e-menu-map-link"></menu-entry>
    <menu-entry (click)="navigateToFavorites()" [label]="'menu.favorites' | translate" class="e2e-menu-map-link"></menu-entry>
</menu-toggle>

<menu-toggle
    [label]="'menu.buy' | translate"
    class="e2e-menu-buy"
    iconPath="assets/icons/tabs/monochrome/tickets.svg"
    [isCollapsed]="isBuyMenuCollapsed"
    (click)="toggleBuyMenu()"
>
    <menu-entry [label]="'menu.quick-tickets' | translate" routerLink="/tabs/tickets"></menu-entry>
    <menu-entry [label]="'menu.tickets' | translate" routerLink="/tabs/bookings"></menu-entry>
</menu-toggle>

<menu-entry
    [label]="'menu.journeys' | translate"
    class="e2e-menu-travels"
    routerLink="/tabs/bookings"
    iconPath="assets/icons/tabs/monochrome/journey.svg"
>
</menu-entry>

<menu-entry
    (click)="openTrafficInformation()"
    label="{{ 'menu.info-traffic' | translate }}"
    class="e2e-menu-info-trafic"
    iconPath="assets/icons/menu/infotrafic.svg"
>
</menu-entry>

<menu-entry
    (click)="openPlans()"
    [label]="'menu.network-map' | translate"
    class="e2e-menu-road-network"
    iconPath="assets/icons/menu/plans.svg"
>
</menu-entry>

<menu-toggle
    [label]="'menu.my-account' | translate"
    iconPath="assets/icons/menu/account.svg"
    class="e2e-menu-account"
    [isCollapsed]="isAccountMenuCollapsed"
    (click)="toggleAccountMenu()"
>
    <menu-entry [label]="'menu.my-profile' | translate" routerLink="./account" class="e2e-menu-profile"></menu-entry>
    <menu-entry [label]="'menu.my-travelers' | translate" routerLink="/tabs/menu/frequent-travelers/false" class="e2e-menu-travelers">
    </menu-entry>
    <menu-entry [label]="'menu.my-credit-cards' | translate" routerLink="/tabs/menu/credit-cards" class="e2e-menu-credit-cards">
    </menu-entry>
    <menu-entry [label]="'menu.preferences' | translate" routerLink="/tabs/menu/preferences" class="e2e-menu-preferences"> </menu-entry>
</menu-toggle>

<menu-toggle
    [label]="'menu.help' | translate"
    class="e2e-menu-information"
    iconPath="assets/icons/menu/information.svg"
    [isCollapsed]="isHelpMenuCollapsed"
    (click)="toggleHelpMenu()"
>
    <menu-entry class="e2e-menu-help" [label]="'menu.faq' | translate" (click)="openFaq()"></menu-entry>

    <menu-entry [label]="'menu.terms-of-use' | translate" routerLink="./menu-cgu" class="e2e-menu-terms-of-use"></menu-entry>

    <menu-entry [label]="'menu.sales-conditions' | translate" routerLink="./menu-cgv" class="e2e-conditions-of-sale"></menu-entry>

    <menu-entry (click)="openCustomerService()" label="{{ 'menu.customer-service' | translate }}" class="e2e-customer-service"></menu-entry>
</menu-toggle>
