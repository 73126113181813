import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptionsBuilderService } from './apollo-client-options-builder.service';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';

@NgModule({
    imports: [HttpClientModule, ApolloModule],
    providers: [
        ApolloClientOptionsBuilderService,
        {
            provide: APOLLO_OPTIONS,
            useFactory: (optionsBuilder: ApolloClientOptionsBuilderService) => optionsBuilder.build(),
            deps: [ApolloClientOptionsBuilderService],
        },
    ],
})
export class ApolloClientModule {}
