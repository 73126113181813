import { Injectable } from '@angular/core';
import { PreferencesService } from '@traas/common/feature-account';
import {
    ColorModeOptions,
    Currency,
    MapDisplayOptions,
    PreferencesStorage,
    SearchModeOptions,
    TimeDisplayMode,
    TravelClassType,
} from '@traas/boldor/all-models';
import { TravelType } from '@traas/boldor/graphql-generated/graphql';

@Injectable()
export class TravysPreferencesService extends PreferencesService {
    getDefaultPreferences(): PreferencesStorage {
        return {
            accessibilityMode: false,
            colorMode: ColorModeOptions.Dark,
            currency: Currency.CHF,
            currencyChangedWhileLoggedOut: false,
            mapDisplayOption: MapDisplayOptions.Map,
            searchMode: SearchModeOptions.MAP,
            skip3DSecure: false,
            skip3DSecureChangedWhileLoggedOut: false,
            timeDisplayMode: TimeDisplayMode.Absolute,
            travelClass: TravelClassType.CLASS_2,
        };
    }

    showLanguagePicker(): boolean {
        return false;
    }

    showMultipleCurrencies(): boolean {
        return false;
    }

    showSkip3DSecurePreference(): Promise<boolean> {
        return Promise.resolve(false);
    }

    showSkip3DSecureForCart(cartPriceInCts: number): Promise<boolean> {
        return Promise.resolve(false);
    }

    getTravelType(): TravelType {
        return TravelType.Simple;
    }

    fetchAndUpdateCurrency(): Promise<void> {
        return Promise.resolve();
    }

    fetchAndUpdateSkip3DSecureSetting(): Promise<void> {
        return Promise.resolve();
    }

    saveCurrencyToRemote(): Promise<void> {
        return Promise.resolve();
    }

    saveSkip3DSecureSettingToRemote(): Promise<void> {
        return Promise.resolve();
    }

    clearCompanySpecificPreferences(): Promise<void> {
        return Promise.resolve();
    }
}
