import { AppConfig, EnvConfig } from '../env.model';
import { LanguageCode } from '@traas/boldor/all-models';
// Ne pas simplifier ces imports en ../ car ce fichier est fileReplacé dans un autre contexte où le ../ sera faux au build
import { TRAVYS_DEFAULT_BOOKMARKS } from '@traas/boldor/environments/specific/travys/travys-default-bookmarks';
import { SwissPassEnvEnum } from '@traas/boldor/environments/specific/SwissPassEnvEnum';

export const travysAppConfig: AppConfig = {
    appId: 'ch.travys.mobile.DEV',
    clientVersion: '33.0.0',
    company: 'travys',
    currentCommitHash: '7f3d85780',
    languages: [LanguageCode.French],
    iosAppUrl: 'https://apps.apple.com/ch/app/travys-mobile/id1607840717?l=fr',
    mapTilerUrl: 'https://api.maptiler.com/maps/357b4e11-9ae3-4c7b-a841-4c2b3450cd0c/{z}/{x}/{y}.png?key=lDhIN1ZERhEUR6lmeySf',
    sentryDsn: 'https://f77630ca91084ebbb44a3ebb29c19284@o311737.ingest.sentry.io/6195976',
    sentryRelease: 'ch.travys.mobile@33.0.0',
    version: '33.0.0',
    showNationalMarketingConsentInPreferences: true,
    defaultBookmarks: TRAVYS_DEFAULT_BOOKMARKS,
    defaultPlace: {
        latitude: 46.78181,
        longitude: 6.63959,
    },
    guestModeEnabled: false,
};

export const travysDeveloperEnvConfig: Omit<EnvConfig, 'environment' | 'gatewayUrl' | 'appSchemeUrl'> = {
    isDebugMode: true,
    showEnvironmentInformations: true,
    traceSentry: false,
    logSentry: false,
    analyticsLoggingEnabled: false,
    analyticsTrackingEnabled: false,
    analyticsUserIdHashSalt: '------------',

    mobileSwisspass: {
        clientId: 'travys_mobile_inte',
        deeplink: 'travysmobileinte', // IMPORTANT, when changing this deeplink, you must also change the trapeze configuration (config.*.yml)
        environment: SwissPassEnvEnum.Integration,
        provider: 'travys',
    },
    webSwisspassRedirectUri: 'http://l4200.openit.ch/web-auth-success',
};
