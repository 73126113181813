import { Injectable } from '@angular/core';
import { PreflightGQL } from '@traas/boldor/graphql-generated/graphql';
import { LoggingService, logOptionsFactory } from '@traas/common/logging';
import { firstValueFrom } from 'rxjs';

/**
 * Give access to bug reporting gateway endpoints
 */
@Injectable({ providedIn: 'root' })
export class PreflightService {
    constructor(private preflight: PreflightGQL, private logger: LoggingService) {}

    async fetchIsOutdated(): Promise<boolean> {
        try {
            const result = await firstValueFrom(this.preflight.fetch());
            return result.data.preflight.isClientOutdated;
        } catch (error) {
            this.logger.logLocalError(error, logOptionsFactory('info'));
            return false;
        }
    }
}
