<div class="version">
    <div class="version-col">
        <ion-icon src="assets/icons/menu/darth-vader.svg"></ion-icon>
    </div>
    <div class="version-col">
        <div>Middleware: {{ env.gatewayUrl }}</div>
        <div>Version: {{ env.version }} ({{ env.currentCommitHash }})</div>
        <div>Environment: {{ env.environment }}</div>
        <div>Storage type: {{ storageDriver }}</div>
        <div>Device type: {{ deviceType }}</div>
        <div class="wrong-storage-type" *ngIf="storageTypeIsWrong">
            Le type de stockage n'est pas SQLITE, il est probable qu'il y ait un problème de build, merci de prévenir l'équipe de
            développement
        </div>
    </div>
</div>
