import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

@Injectable()
export class PlatformUtilsService {
    static readonly DEVICE_LIVERELOAD_URL = 'http://192.168.1';
    static readonly WEB_DEBUG_URL = 'http://localhost:42';

    static readonly APP_ANDROID_URL = 'http://localhost/';
    static readonly APP_ANDROID_URL_HTTPS = 'https://localhost/';
    static readonly APP_IOS_URL = 'ionic://localhost';

    constructor(private platform: Platform) {}

    isApp(): boolean {
        if (document.URL.startsWith(PlatformUtilsService.WEB_DEBUG_URL)) {
            return false;
        }
        // Because we are on device, but in livereload the URL of the app can be different than an real device build
        if (document.URL.startsWith(PlatformUtilsService.DEVICE_LIVERELOAD_URL)) {
            return true;
        }

        // without that hack, the platform.platforms() gives the same results on smartphone app and smartphone browser
        return (
            document.URL.startsWith(PlatformUtilsService.APP_ANDROID_URL) || // url on Android
            document.URL.startsWith(PlatformUtilsService.APP_ANDROID_URL_HTTPS) || // url on iOS
            document.URL.startsWith(PlatformUtilsService.APP_IOS_URL)
        );
    }

    isWeb(): boolean {
        return !this.isApp();
    }

    isAndroid(): boolean {
        return Capacitor.getPlatform() === 'android';
    }

    isIos(): boolean {
        return this.platform.is('ios');
    }

    /**
     * Returns system language code according to ISO 639-1 (2-letters lowercase)
     * @returns Two letters language code as a lowercase string (e.g. 'fr')
     */
    async getLanguageCode(): Promise<string> {
        const code = await Device.getLanguageCode();
        return code.value.toLowerCase();
    }
}
