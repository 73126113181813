import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { BehaviorSubject, firstValueFrom, fromEvent, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, timeout } from 'rxjs/operators';
import { enterZoneHelper } from '../helpers/enter-zone.helper';
import { App } from '@capacitor/app';

@Injectable()
export class OnlineService {
    private readonly $isOnline: Observable<boolean>;

    private readonly $networkNativeStatus = new BehaviorSubject(false);

    constructor(private zone: NgZone) {
        this.initFirstNetworkStatus().then();
        if (Capacitor.isNativePlatform()) {
            this.$isOnline = this.$buildNativeIsOnline();
        } else {
            this.$isOnline = this.$buildBrowserIsOnline();
        }
    }

    $getIsOnline(): Observable<boolean> {
        return this.$isOnline;
    }

    async isOffline(): Promise<boolean> {
        const isOnline = await this.isOnline();
        return !isOnline;
    }

    async isOnline(): Promise<boolean> {
        return firstValueFrom(this.$isOnline.pipe(timeout({ each: 100, with: () => of(false) })));
    }

    private $buildBrowserIsOnline(): Observable<boolean> {
        const $isOnline = fromEvent<void>(window, 'online');
        const $isOffline = fromEvent<void>(window, 'offline');
        return merge(of(undefined), $isOnline, $isOffline).pipe(
            distinctUntilChanged(),
            enterZoneHelper(this.zone),
            map(() => navigator.onLine),
        );
    }

    private $buildNativeIsOnline(): Observable<boolean> {
        this.createNativeListener();
        this.createOnResumeListener();
        return this.$networkNativeStatus.pipe(distinctUntilChanged(), enterZoneHelper(this.zone));
    }

    private createNativeListener(): void {
        Network.addListener('networkStatusChange', (status) => {
            this.$networkNativeStatus.next(status.connected);
        });
    }

    private createOnResumeListener(): void {
        App.addListener('resume', async () => {
            await this.nextNetworkStatus();
        });
    }

    private async initFirstNetworkStatus(): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            await this.nextNetworkStatus();
        }
    }

    private async nextNetworkStatus(): Promise<void> {
        const status = await Network.getStatus();
        this.$networkNativeStatus.next(status.connected);
    }
}
