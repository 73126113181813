import { Injectable } from '@angular/core';
import { Departure } from '@traas/boldor/all-models';
import { isPlaceholder, Placeholder } from '../../../../components/placeholder-list-item/placeholder.model';
import { DepartureAdapter } from '../../../../models/departure/departure';
import { isSameDay } from 'date-fns';
import { ConfigurationService } from '../../../../services/common/configuration/configuration.service';

@Injectable()
export class DepartureDatePlaceholderService {
    constructor(private configService: ConfigurationService) {}

    insertDatePlaceholdersBetweenDays(departures: (Departure | Placeholder)[]): (Departure | Placeholder)[] {
        if (!this.configService.shouldShowDateItemInJourneysList()) {
            return departures;
        }
        if (!departures || departures.length === 0) {
            return [];
        }
        const processedDepartures: (Departure | Placeholder)[] = [departures[0]];
        for (let index = 1; index < departures.length; index++) {
            const currentItem = departures[index];
            const previousItem = departures[index - 1];
            if (isPlaceholder(currentItem) || isPlaceholder(previousItem)) {
                processedDepartures.push(currentItem);
                continue;
            }
            const previousDate = new DepartureAdapter(previousItem).getRealTimeDepartureDate();
            const currentDate = new DepartureAdapter(currentItem).getRealTimeDepartureDate();
            if (!isSameDay(previousDate, currentDate)) {
                const placeholder: Placeholder = {
                    isPlaceholder: true,
                    date: currentDate,
                    id: `placeholder-${previousItem.id}`,
                };
                processedDepartures.push(placeholder);
            }

            processedDepartures.push(currentItem);
        }

        return processedDepartures;
    }

    createDatePlaceholder(input: DepartureAdapter | Placeholder): Placeholder | undefined {
        if (!this.configService.shouldShowDateItemInJourneysList()) {
            return undefined;
        }
        if (isPlaceholder(input)) {
            return undefined;
        }
        return {
            isPlaceholder: true,
            date: input.getRealTimeDepartureDate(),
            id: `placeholder-${input.getId()}`,
        };
    }
}
