import { ChangePlaceEventSourceEnum, Place } from '@traas/boldor/all-models';
import { Observable } from 'rxjs';
import { DynamicPlaceAdapter } from './dynamic-place.adapter';

export class BookmarkDynamicPlaceAdapter extends DynamicPlaceAdapter<Place> {
    private readonly changePlaceSourceEvent: ChangePlaceEventSourceEnum = ChangePlaceEventSourceEnum.BookmarkSelection;

    constructor(data: Place) {
        super(data);
    }

    getChangePlaceEvent(): ChangePlaceEventSourceEnum {
        return this.changePlaceSourceEvent;
    }

    getEndpointIconName(): string {
        return super.getEndpointIconName();
    }

    isDrawOnMapRequired(): boolean {
        return false;
    }

    isDrawOnEndpointRequired(): boolean {
        return true;
    }

    isNeededToFitMap(): boolean {
        return true;
    }

    $getHtmlLeafletIcon(): Observable<string> {
        return super.$getHtmlLeafletIcon();
    }
}
