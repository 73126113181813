import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PageTitleModule } from '@traas/boldor/common/components/page-title/page-title.module';
import { TravysTosComponent } from './travys-tos/travys-tos.component';

@NgModule({
    declarations: [TravysTosComponent],
    imports: [CommonModule, IonicModule, PageTitleModule],
    exports: [TravysTosComponent],
})
export class TravysFeatureTermsOfServiceModule {}
