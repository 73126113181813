import { JourneyAdapter } from './journey';
import { DepartureAdapter, isDepartureJourney } from '../../../models/departure/departure';
import { isItineraryJourney, ItineraryAdapter } from '../../../models/itinerary/itinerary';
import { Departure, Itinerary, JourneyData } from '@traas/boldor/all-models';

export type JourneyDataType = Departure | Itinerary;

export class JourneyAdapterFactory {
    static create(journey: JourneyData): JourneyAdapter {
        if (isDepartureJourney(journey)) {
            return new DepartureAdapter(journey) as JourneyAdapter<Departure>;
        }
        if (isItineraryJourney(journey)) {
            return new ItineraryAdapter(journey) as JourneyAdapter<Itinerary>;
        }
        throw new Error(`Unknown journey type`);
    }
}
