import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { VersionNumberComponent } from './version-number.component';

@NgModule({
    imports: [CommonModule, IonicModule],
    declarations: [VersionNumberComponent],
    exports: [VersionNumberComponent],
})
export class VersionNumberModule {}
