import { MessageViewModel } from '../models/message-view-model';
import { Action } from '@ngrx/store';

export enum StartupNotificationActionTypes {
    Load = '[Startup notification] Loading messages...',
    LoadSuccess = '[Startup notification] Loading success',
    LoadFail = '[Startup notification] Loading fail',

    PresentAllMessages = '[Startup notification] Present all messages',
    PresentMessage = '[Startup notification] Present message',
    AcknowledgeMessage = '[Startup notification] Acknowledge message',
    PostponeMessage = '[Startup notification] Postpone message',
    Done = '[Startup notification] Done',
}

export class Load implements Action {
    readonly type = StartupNotificationActionTypes.Load;

    constructor() {}
}

export class LoadSuccess implements Action {
    readonly type = StartupNotificationActionTypes.LoadSuccess;

    constructor(public payload: MessageViewModel[]) {}
}

export class LoadFail implements Action {
    readonly type = StartupNotificationActionTypes.LoadFail;

    constructor() {}
}

export class PresentAllMessages implements Action {
    readonly type = StartupNotificationActionTypes.PresentAllMessages;

    constructor() {}
}

export class PresentMessage implements Action {
    readonly type = StartupNotificationActionTypes.PresentMessage;

    constructor(public payload: MessageViewModel) {}
}

export class AcknowledgeMessage implements Action {
    readonly type = StartupNotificationActionTypes.AcknowledgeMessage;

    constructor(public payload: MessageViewModel) {}
}

export class Done implements Action {
    readonly type = StartupNotificationActionTypes.Done;

    constructor() {}
}

export class PostponeMessage implements Action {
    readonly type = StartupNotificationActionTypes.PostponeMessage;

    constructor(public payload: MessageViewModel) {}
}

export type StartupNotificationAction =
    | Load
    | LoadSuccess
    | LoadFail
    | PresentAllMessages
    | PresentMessage
    | AcknowledgeMessage
    | PostponeMessage
    | Done;
