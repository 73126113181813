/* tslint:disable:max-classes-per-file */
import { Area, Endpoint, LatLngRect, NewEndpointPayload } from '@traas/boldor/all-models';
import { ChangePlaceEvent } from './change-place-event.interface';
import { Action } from '@ngrx/store';

export enum EndpointActionTypes {
    ArrivalChanged = '[Endpoint] Arrival has been changed',
    DepartureChanged = '[Endpoint] Departure has been changed',
    FollowGps = '[Endpoint] Follow GPS',
    HideArrival = '[Endpoint] Hide arrival endpoint',
    IgnoreNextChange = '[Endpoint] Ignore next change on specific endpoint',
    LinkableClickSearch = '[Endpoint] Link clicked',
    NewArrival = '[Endpoint] New arrival',
    SetBounds = '[Endpoint] Set bounds (bypass ignoreNextChange)',
    NewDeparture = '[Endpoint] New departure',
    SetActiveEndpoint = '[Endpoint] Set active endpoint',
    SetChangePlaceEventSource = '[Endpoint] Set change place event source',
    ShowArrival = '[Endpoint] Show arrival endpoint',
    SwapEndpoint = '[Endpoint] Swap endpoint',
    UnfollowGps = '[Endpoint] Unfollow GPS',
    Disable = '[Endpoint] Disabled endpoints',
    Enable = '[Endpoint] Enable endpoints',
    StartChangingDeparture = '[Endpoint] Start changing departure...',
    StartChangingArrival = '[Endpoint] Start changing arrival...',
    SetTooMuchStopsOnActiveEndpoint = '[Endpoint] Set hasTooMuchStopsInBounds on active endpoint',
}

export class LinkableClickSearch implements Action {
    readonly type = EndpointActionTypes.LinkableClickSearch;

    constructor(public payload: { area: Area }) {}
}

export class NewDeparture implements Action {
    readonly type = EndpointActionTypes.NewDeparture;

    /**
     *
     * @param payload ignoreNextChange Is used to apply constraint that used to ignore the next departure endpoint modification.
     * We need it because when the GPS position is followed (so at the start of the app), we set the follow gps icon in endpoint,
     * then the map move, so if we not ignore the new departure endpoint modification, it is the MapMove icon.
     */
    constructor(public payload: NewEndpointPayload) {}
}

export class DepartureChanged implements Action {
    readonly type = EndpointActionTypes.DepartureChanged;
}

export class NewArrival implements Action {
    readonly type = EndpointActionTypes.NewArrival;

    /**
     *
     * @param payload ignoreNextChange Is used to apply constraint that used to ignore the next departure endpoint modification.
     * We need it because when the GPS position is followed (so at the start of the app), we set the follow gps icon in endpoint,
     * then the map move, so if we not ignore the new departure endpoint modification, it is the MapMove icon.
     */
    constructor(public payload: NewEndpointPayload) {}
}

export class ArrivalChanged implements Action {
    readonly type = EndpointActionTypes.ArrivalChanged;
}

export class StartChangingArrival implements Action {
    readonly type = EndpointActionTypes.StartChangingArrival;

    constructor(
        public payload: { presentLoading: boolean; disabledEndpoint: boolean } = {
            presentLoading: true,
            disabledEndpoint: true,
        },
    ) {}
}

export class StartChangingDeparture implements Action {
    readonly type = EndpointActionTypes.StartChangingDeparture;

    constructor(
        public payload: { presentLoading: boolean; disabledEndpoint: boolean } = {
            presentLoading: true,
            disabledEndpoint: true,
        },
    ) {}
}

export class IgnoreNextChange implements Action {
    readonly type = EndpointActionTypes.IgnoreNextChange;

    constructor(public payload: { endpoint: Endpoint; isIgnored: boolean }) {}
}

export class SetActiveEndpoint implements Action {
    readonly type = EndpointActionTypes.SetActiveEndpoint;

    constructor(public endpoint: Endpoint) {}
}

export class FollowGps implements Action {
    readonly type = EndpointActionTypes.FollowGps;

    constructor(public payload: { endpoint: Endpoint }) {}
}

export class UnfollowGps implements Action {
    readonly type = EndpointActionTypes.UnfollowGps;

    constructor(public payload: { endpoint: Endpoint }) {}
}

export class SwapEndpoint implements Action {
    readonly type = EndpointActionTypes.SwapEndpoint;
}

export class HideArrival implements Action {
    readonly type = EndpointActionTypes.HideArrival;
}

export class ShowArrival implements Action {
    readonly type = EndpointActionTypes.ShowArrival;
}

export class SetChangePlaceEventSource implements Action {
    readonly type = EndpointActionTypes.SetChangePlaceEventSource;

    constructor(public payload: ChangePlaceEvent) {}
}

export class SetTooMuchStopsOnActiveEndpoint implements Action {
    readonly type = EndpointActionTypes.SetTooMuchStopsOnActiveEndpoint;

    constructor(public payload: boolean) {}
}

export class Disable implements Action {
    readonly type = EndpointActionTypes.Disable;
}

export class Enable implements Action {
    readonly type = EndpointActionTypes.Enable;
}

export class SetBounds implements Action {
    readonly type = EndpointActionTypes.SetBounds;

    constructor(public payload: { endpoint: Endpoint; bounds: LatLngRect }) {}
}

export type EndpointActions =
    | ArrivalChanged
    | DepartureChanged
    | FollowGps
    | HideArrival
    | IgnoreNextChange
    | LinkableClickSearch
    | NewArrival
    | NewDeparture
    | SetActiveEndpoint
    | SetChangePlaceEventSource
    | ShowArrival
    | SwapEndpoint
    | UnfollowGps
    | Disable
    | Enable
    | StartChangingDeparture
    | StartChangingArrival
    | SetBounds
    | SetTooMuchStopsOnActiveEndpoint;
