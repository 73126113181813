import { AbstractAdapter, HumanReadableStop, TransitStop } from '@traas/boldor/all-models';
import { getDelayInMinutes } from '../../business-rules.utils';
import { SyntheseDateHelper } from '@traas/boldor/all-helpers';

export class TransitStopAdapter extends AbstractAdapter<TransitStop> implements HumanReadableStop {
    get stopAreaName(): string {
        return this.data.stopAreaName;
    }

    constructor(data: TransitStop) {
        super(data);
    }

    getCityName(): string {
        return this.data?.cityName ?? '';
    }

    getName(): string {
        return this.data?.stopAreaName ?? '';
    }

    getId(): string {
        return this.data?.id ?? '';
    }

    getArrivalTime(): string {
        return this.data.arrivalTime?.substr(0, 5) ?? '';
    }

    getArrivalRealTime(): string {
        return this.data.arrivalRealTime?.substr(0, 5) ?? '';
    }

    getArrivalRealtimeDate(): Date {
        return SyntheseDateHelper.syntheseDatetimeStringToDate(this.data.arrivalRealTimeDate, this.data.arrivalRealTime);
    }

    getArrivalDate(): Date {
        return SyntheseDateHelper.syntheseDatetimeStringToDate(this.data.arrivalDate, this.data.arrivalTime);
    }

    isCancelled(): boolean {
        return this.data.isCancelled;
    }

    getArrivalDelay(): string {
        const rtArrivalDate = this.getArrivalRealtimeDate();
        const arrivalDate = this.getArrivalDate();
        return getDelayInMinutes(rtArrivalDate, arrivalDate);
    }
}
