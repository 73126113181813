import { SearchPlaceAction, SearchPlaceActionTypes } from './searchPlace.action';
import { initialState, SearchPlaceState } from './searchPlace.state';
import { BookmarkAdapter } from '../../../../models/bookmark/bookmark-adapter.model';

export function searchPlaceReducer(state: SearchPlaceState = initialState, action: SearchPlaceAction): SearchPlaceState {
    switch (action.type) {
        case SearchPlaceActionTypes.AddBookmark:
            return {
                ...state,
                bookmarks: [...state.bookmarks, action.payload.newBookmark],
            };

        case SearchPlaceActionTypes.InitBookmarks:
            return {
                ...state,
                bookmarks: [...action.payload.bookmarks],
            };

        case SearchPlaceActionTypes.PickPlace:
            return {
                ...state,
                isChangingPlace: true,
            };

        case SearchPlaceActionTypes.PickPlaceSuccess:
            return {
                ...state,
                isChangingPlace: false,
            };

        case SearchPlaceActionTypes.PickPlaceFail:
            return {
                ...state,
                isChangingPlace: false,
            };

        case SearchPlaceActionTypes.ResetBookmarks:
            return {
                ...state,
                bookmarks: [],
            };

        case SearchPlaceActionTypes.BookmarkRemoved:
            const wasRemoved = !!action.payload;
            if (wasRemoved) {
                const bookmarkAdapterToDeleted = new BookmarkAdapter(action.payload.bookmark);
                const bookmarks = state.bookmarks
                    .map((bookmark) => new BookmarkAdapter(bookmark))
                    .filter((bookmarkAdapter) => !bookmarkAdapter.isEqual(bookmarkAdapterToDeleted))
                    .map((bookmarkAdapter) => bookmarkAdapter.getData());
                return {
                    ...state,
                    bookmarks,
                };
            }
            return state;

        default:
            return state;
    }
}
