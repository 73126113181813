import { OrderViewModel } from '@traas/boldor/all-models';

export enum BookingStatus {
    INITIAL,
    SUCCESS,
    FAIL,
}

export interface BookingState {
    isLoading: boolean;
    bookingStatus: BookingStatus;
    orders: OrderViewModel[];
}

export const initialState: BookingState = {
    isLoading: false,
    bookingStatus: BookingStatus.INITIAL,
    orders: [],
};
