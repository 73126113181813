import jwt_decode, { JwtPayload } from 'jwt-decode';

const invalidTokenNoExpiryFieldError = new Error('Invalid token: no expiry field');

export const isTokenExpired = (token: string, threshold = 0): boolean => {
    const currentTime = Math.round(Date.now() / 1000); // Convert to seconds
    const expirationTime = getTokenExpirationDateInSeconds(token);
    return expirationTime - threshold < currentTime;
};

export const getTokenExpirationDateInSeconds = (token: string): number => {
    const decoded: JwtPayload = jwt_decode<JwtPayload>(token);
    if (decoded && decoded.exp) {
        return decoded.exp;
    }
    throw invalidTokenNoExpiryFieldError;
};

export const isTokenValid = (token: string): boolean => {
    try {
        const decoded: JwtPayload = jwt_decode(token);
        if (!decoded.exp) {
            console.error(invalidTokenNoExpiryFieldError);
            return false;
        }
        return true;
    } catch (e) {
        console.error('Failed to decode token', e);
        return false;
    }
};

export const getTokenExpirationDurationInSeconds = (accessToken: string): number => {
    const expirationDateInSeconds = getTokenExpirationDateInSeconds(accessToken);
    const currentDateInSeconds: number = Math.floor(Date.now() / 1000); // Convert to seconds
    return expirationDateInSeconds - currentDateInSeconds;
};
