<div class="container">
    <ng-content></ng-content>

    <br #checkboxSeparator />
    <ion-item lines="none">
        <!-- Bugfix https://github.com/ionic-team/ionic-framework/issues/21939 -->
        <div tabindex="0"></div>
        <!-- End bugfix -->
        <ion-checkbox [(ngModel)]="cguAccepted" class="e2e-accept-cgu" color="tertiary" mode="md"></ion-checkbox>
        <ion-label class="ion-text-wrap">{{ 'cgu.consent' | translate }} </ion-label>
    </ion-item>
    <br />
    <ion-text color="danger" *ngIf="hasClickedAccept && !cguAccepted">
        <p class="mandatory">{{ 'cgu.missing-consent' | translate }}</p>
    </ion-text>
</div>
<div class="footer">
    <ion-button
        size="large"
        elevation="1"
        (click)="confirmTosAcceptanceAndLaunchApp()"
        *ngIf="cguAccepted"
        class="cgu-button"
        tappable
        type="button"
    >
        {{ 'cgu.accept-button' | translate }}
    </ion-button>
    <ion-button
        size="large"
        elevation="1"
        (click)="focusOnCheckbox()"
        *ngIf="!cguAccepted"
        class="cgu-button opacity"
        tappable
        type="button"
    >
        {{ 'cgu.accept-button' | translate }}
    </ion-button>
</div>
