import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { RouteUrl, RoutingService } from '@traas/common/routing';
import { firstValueFrom, isObservable } from 'rxjs';
import { environment } from '@traas/boldor/environments';

export const redirectToLoginOnAllReject: (...canActivateFnFactories: (() => CanActivateFn)[]) => CanActivateFn = (
    ...canActivateFnFactories
) => {
    return async (next, state) => {
        // injection is allowed before the first `await`
        const canActivateFns = canActivateFnFactories.map((canActivateFnFactory) => canActivateFnFactory());
        const routingService: RoutingService = inject(RoutingService);
        const document = inject(DOCUMENT);

        for (const canActivateFn of canActivateFns) {
            const canActivateResult = canActivateFn(next, state);
            const canActivateValue = await (isObservable(canActivateResult)
                ? firstValueFrom(canActivateResult)
                : Promise.resolve(canActivateResult));
            if (canActivateValue === true) {
                return true;
            }
        }

        const originUrl = state.url;
        const queryParams = getQueryParams(originUrl, document);
        await routingService.navigateToLogin(originUrl, queryParams);
        return false;
    };
};

function getQueryParams(originUrl: string, document: Document): { showBuyTicketBySmsButton: boolean; showGuestCheckoutButton: boolean } {
    const wellKnownUrls = [RouteUrl.ticketsTabUrl, RouteUrl.cartOrdersUrl];
    const isWellKnownUrl = wellKnownUrls.some((wellKnownUrl) => originUrl.startsWith(wellKnownUrl));

    // for now guest mode is not implemented for traas, when it will be implemented we can remove guestModeEnabled property from env files
    // and the condition in showGuestCheckoutButton on the return.
    const guestModeEnabled = !!environment.guestModeEnabled;
    const forceSmsButton = new URL(originUrl, document.location.href).searchParams.get('showBuyTicketBySmsButton') === 'true';

    return {
        showBuyTicketBySmsButton: isWellKnownUrl || forceSmsButton,
        showGuestCheckoutButton: isWellKnownUrl && guestModeEnabled,
    };
}
