<div class="ticket">
    <ion-row class="title">
        <ion-col> {{ 'my-journeys.ticket-summary.departure' | translate }} </ion-col>
        <ion-col>
            <app-lines-icon-horizontal-list [lines]="lines"></app-lines-icon-horizontal-list>
        </ion-col>
    </ion-row>
    <div class="ticket-container">
        <div class="ticket-infos">
            <app-itinerary-ticket-from-to
                [class.show-operations-icons]="showOperationsIcon"
                [customColPadding]="paddingCol"
                [defaultPaddingOnCol]="false"
                [departureStop]="departureStop"
            >
            </app-itinerary-ticket-from-to>

            <ion-row [class.show-operations-icons]="showOperationsIcon" class="departure-datetime">
                <ion-col size="10">
                    <app-human-readable-date [departureDatetime]="departureDate"> </app-human-readable-date>
                </ion-col>
                <ion-col *ngIf="showCollapseButton" class="collapse-btn-container flex ion-text-end" size="2">
                    <app-collapse-arrow (click)="expandDetails()" [expanded]="expanded"></app-collapse-arrow>
                </ion-col>
            </ion-row>

            <div>
                <div *ngIf="!showOperationsIcon && isRefundable" class="ticket-icons-price">
                    <ion-icon (click)="confirmRefundOrder()" class="ticket-delete" src="assets/icons/grid/delete.svg"></ion-icon>
                    <div class="ticket-price">
                        {{ totalPrice }}
                    </div>
                </div>
                <div *ngIf="showOperationsIcon" class="ticket-icons-price">
                    <ion-icon class="operation-icon separation-right" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
                    <ion-icon
                        *ngIf="departureOrder.tickets.length > 0"
                        class="operation-icon separation-right"
                        src="assets/icons/tabs/monochrome/tickets.svg"
                    ></ion-icon>

                    <ion-icon
                        *ngIf="departureOrder.departure.hasStopRequest"
                        class="operation-icon"
                        src="assets/icons/stop-on-request.svg"
                    ></ion-icon>
                    <div class="ticket-price" [class.strikethrough]="areThirdPartyPaidTickets(departureOrder)">
                        {{ totalPrice }}
                    </div>
                </div>
            </div>
        </div>
        <div class="ticket-arrow">
            <ion-icon src="assets/icons/menu/chevron.svg"></ion-icon>
        </div>
    </div>
</div>

<div *ngIf="expanded" class="cancel-parent-padding">
    <ng-container *ngIf="transitStops as transitStops; else isLoading">
        <app-transit-stop-item
            *ngFor="let transitStop of transitStops; trackBy: trackById; let last = last; let even = even"
            [isLast]="last"
            [lineStyle]="lineStyle"
            [ngClass]="even ? 'odd' : 'even'"
            [isTrain]="isTrain(departureOrder.departure.transport)"
            [transitStop]="transitStop"
            [activateForwardArrow]="false"
        ></app-transit-stop-item>
    </ng-container>
    <ng-template #isLoading>
        <div class="spinner-container">
            <ion-spinner class="loading-spinner"></ion-spinner>
        </div>
    </ng-template>
</div>
