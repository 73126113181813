/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ArticlesBundle, Cart, CheckoutStep, NearestStop, TravelClassType } from '@traas/boldor/all-models';
import { PreferencesService } from '@traas/common/feature-account';
import { convertToError, LoggingService } from '@traas/common/logging';
import { EMPTY, firstValueFrom, Observable } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { cloneArticlesBundle } from '../../../models/cart/article-bundle';
import { isQuickTicketCart } from '../../../models/cart/cart.utils';
import { CartService } from '../../../services/common/cart/cart.service';
import { ItineraryState } from '../../itinerary/store/itinerary.state';
import { CartActionTypes, PrepareArticlesBundle, SetCheckoutStep, UpdateArticlesBundle } from './cart.action';
import { getCart, getConnectedUserArticlesBundle, getDurationsFilter } from './cart.selector';
import { CartState } from './cart.state';
import { CartActions, CartSelectors } from './index';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

@Injectable()
export class CartEffect {
    // ARTICLES BUNDLE ACTIONS

    $prepareArticlesBundle = createEffect(() =>
        this.$actions.pipe(
            ofType<PrepareArticlesBundle>(CartActionTypes.PrepareArticlesBundle),
            withLatestFrom(
                this.itineraryStore.select(getCart),
                this.itineraryStore.select(getConnectedUserArticlesBundle),
                this.itineraryStore.select(getDurationsFilter),
                ({ payload }, cart, defaultBundle, durationsFilter) => ({
                    payload,
                    cart,
                    defaultBundle,
                    durationsFilter,
                }),
            ),
            mergeMap(async ({ payload, cart, defaultBundle: userArticlesBundle, durationsFilter }) => {
                // Load articles using articlesBundle (passenger) and add it
                let articlesBundle: ArticlesBundle;
                try {
                    const nearestStop = this.getNearestStop(cart);
                    articlesBundle = await this.cartService.fetchArticles(
                        cart,
                        payload.articlesBundle,
                        durationsFilter.filter((duration) => !!duration),
                        nearestStop,
                    );

                    if (!articlesBundle) {
                        return new CartActions.PrepareArticlesBundleError();
                    }
                    if (!userArticlesBundle) {
                        await this.addDefaultArticlesBundle(cloneArticlesBundle(articlesBundle));
                    }
                    return new CartActions.PrepareArticlesBundleSuccess({
                        articlesBundle,
                    });
                } catch (error) {
                    this.logger.logError(
                        new TechnicalError(
                            'Error while preparing articles bundle',
                            ErrorCodes.Purchase.PrepareArticlesBundle,
                            convertToError(error),
                        ),
                    );
                    return new CartActions.PrepareArticlesBundleError();
                }
            }),
        ),
    );

    $prepareArticlesBundleSuccess: Observable<UpdateArticlesBundle> = createEffect(() =>
        this.$actions.pipe(
            ofType(CartActionTypes.PrepareArticlesBundleSuccess),
            map(({ payload: { articlesBundle } }) => {
                return new CartActions.UpdateArticlesBundle({
                    articlesBundle,
                });
            }),
        ),
    );

    // UPDATE CART ACTIONS

    $prepareArticlesBundleError = createEffect(() =>
        this.$actions.pipe(
            ofType(CartActionTypes.PrepareArticlesBundleError),
            map(() => new CartActions.UpdateCartFail()),
        ),
    );

    $updateCartSuccess = createEffect(() =>
        this.$actions.pipe(
            ofType(CartActionTypes.UpdateCart),
            map(() => new CartActions.UpdateCartSuccess()),
        ),
    );

    $updateClassSelected: Observable<CartActions.UpdateCart> = createEffect(() =>
        this.$actions.pipe(
            ofType(
                CartActionTypes.UpdateArticlesBundle,
                CartActionTypes.RemoveArticlesBundle,
                CartActionTypes.SetTypeSelected,
                CartActionTypes.SetTravelTypeSelected,
                CartActionTypes.SetClassSelected,
                CartActionTypes.SetSelectedZones,
                CartActionTypes.SetPriceTypeSelected,
            ),
            map(() => new CartActions.UpdateCart()),
        ),
    );

    $SetCheckoutStep = createEffect(
        () =>
            this.$actions.pipe(
                ofType(CartActionTypes.SetCheckoutStep),
                filter(({ payload }: SetCheckoutStep) => payload === CheckoutStep.Processing),
                concatLatestFrom(() => this.cartStore.select(CartSelectors.selectCartState)),
                filter(([, cart]) => cart.nationalMarketingConsent !== undefined),
                switchMap(([, cart]) => {
                    if (cart.nationalMarketingConsent) {
                        return this.preferencesService.saveNationalMarketingConsentToRemote(true);
                    }
                    return EMPTY;
                }),
                catchError((err) => {
                    this.logger.logError(
                        new TechnicalError('Error during effect SetCheckoutStep', ErrorCodes.Purchase.SetCheckoutStep, err),
                    );
                    return EMPTY;
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private $actions: Actions,
        private cartService: CartService,
        private cartStore: Store<CartState>,
        private itineraryStore: Store<ItineraryState>,
        private logger: LoggingService,
        private preferencesService: PreferencesService,
    ) {}

    private getNearestStop(cart: Cart | null): NearestStop | null {
        if (isQuickTicketCart(cart)) {
            return cart.nearestStop;
        }
        return null;
    }

    private async addDefaultArticlesBundle(articlesBundle: ArticlesBundle): Promise<void> {
        const isValidArticlesBundle = articlesBundle.availableArticles.length > 0;
        const travelClass = await this.getPreferredClassFilter();
        if (isValidArticlesBundle) {
            this.cartStore.dispatch(
                new CartActions.SetDefaultArticlesBundle({
                    articlesBundle,
                    travelClass,
                }),
            );
        } else {
            console.error('CartEffect -> addDefaultArticlesBundle -> availableArticles is empty');
        }
    }

    private getPreferredClassFilter(): Promise<TravelClassType> {
        return firstValueFrom(this.preferencesService.$getTravelClass());
    }
}
