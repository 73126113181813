import { UserInfo } from '@traas/capacitor/swisspass-plugin';

export const SWISSPASS_SERVICE_TOKEN = 'SWISSPASS_SERVICE_TOKEN';

export interface SwisspassService {
    isAuthenticatedOnSwissPass(): Promise<boolean>;

    getAccessToken(): Promise<string | null>;

    logout(): Promise<boolean>;

    forceLogout(): Promise<void>;

    isSwissPassAvailable(): Promise<boolean>;

    openSwissPassMobile(): Promise<void>;

    activateSwissPassMobile(): Promise<boolean>;

    canActivateSwissPass(): Promise<{ canActivate: boolean; status: string }>;

    linkSwissPassToAccount(): Promise<void>;

    openLoginDataManagementPage(): Promise<void>;

    openAccountManagementPage(): Promise<void>;

    openSwisspassLoginPage(): Promise<{ isLoggedIn: boolean }>;

    registerSwissPass(): Promise<string | null>;

    getUserInfo(): Promise<UserInfo | null>;
}
