import { NgModule } from '@angular/core';
import { EndpointModule } from '../endpoint/endpoint.module';
import { HomeMapContainerService } from '../home/containers/home-map-container/home-map-container.service';
import { PlaceService } from './services/place.service';
import { DynamicIonIconModule } from '../../components/dynamic-ion-icon/dynamic-ion-icon.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { PlacesListItemComponent } from './components/places-list-item/places-list-item.component';
import { PlacesListComponent } from './containers/places-list/places-list.component';
import { SearchBarComponent } from './containers/search-bar/search-bar.component';
import { SearchPlacesComponent } from './containers/search-places/search-places.component';
import { AnalyticsService } from '@traas/common/analytics';

@NgModule({
    imports: [BoldorFeatureShellSharedModule, EndpointModule, DynamicIonIconModule],
    declarations: [SearchPlacesComponent, SearchBarComponent, PlacesListComponent, PlacesListItemComponent],
    providers: [PlaceService, HomeMapContainerService, AnalyticsService],
    exports: [SearchPlacesComponent],
})
export class PlaceModule {}
