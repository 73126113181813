import { inject, Injectable } from '@angular/core';
import {
    Area,
    ChangePlaceEventSourceEnum,
    ItinerariesFetchingParameters,
    Itinerary,
    JourneyEnum,
    JourneyMessageType,
    LeaveOrArriveEnum,
    MapMode,
    toBounds,
} from '@traas/boldor/all-models';
import { EndpointState } from '../../home/store/endpoint';
import { LOAD_MORE_ITINERARY_COUNT } from '../../../business-rules.utils';

import { PopoverOptions } from '@ionic/core';
import { Store } from '@ngrx/store';
import { AreaHelper, isAddress, isFollowGps, isMyGpsPosition, isPoi, ServiceToGql } from '@traas/boldor/all-helpers';
import {
    CoordinatesOrStopIdsInput,
    GenerateItineraryArticlesMutation,
    GetItinerariesGQL,
    GetTransportModesGQL,
    ItineraryBasketInput,
    JourneyMessageType as JourneyMessageTypeGQL,
    QueryItinerariesArgs,
    SaveItineraryGQL,
    SaveItineraryMutationVariables,
    SearchDirection,
} from '@traas/boldor/graphql-generated/graphql';
import { InMemoryCache } from '../../cache/memoryCache.service';
import { AlertService } from '../../../services/common/alert.service';
import { CompanyService } from '../../../services/common/company/company.service';
import { ToasterService } from '../../../services/common/toaster/toaster.service';
import { TransportModeFilterPopoverComponent } from '../../../components/transport-mode-filter-popover/transport-mode-filter-popover.component';
import { ItineraryAdapter } from '../../../models/itinerary/itinerary';
import { GqlItineraryOrder } from '../../../models/order';
import { LatLng } from 'leaflet';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { setScrollMode } from '../../home/store/scroll/scroll.actions';
import { initialState } from '../../home/store/scroll/scroll.reducer';
import { ItineraryStoreActions } from '../store';
import { BoldorLocalizationService } from '@traas/common/localization';
import { convertToError, LoggingService } from '@traas/common/logging';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

export type GqlItineraryArticle = GenerateItineraryArticlesMutation['article']['generateItineraryArticles'][number];

@Injectable({
    providedIn: 'root',
})
export class ItineraryService {
    private itineraryCache = new InMemoryCache<Itinerary[]>();
    protected boldorLocalizationService = inject(BoldorLocalizationService);

    constructor(
        protected alertService: AlertService,
        protected store: Store<EndpointState>,
        protected toasterService: ToasterService,
        protected saveItineraryGQL: SaveItineraryGQL,
        protected getTransportModesGQL: GetTransportModesGQL,
        protected getItinerariesGQL: GetItinerariesGQL,
        protected loggingService: LoggingService,
    ) {}

    getLoadMoreDeparturesCountBy(mapMode: MapMode): number {
        switch (mapMode) {
            case MapMode.Small:
                return LOAD_MORE_ITINERARY_COUNT * 2;
            case MapMode.Full:
            case MapMode.Half:
            default:
                return LOAD_MORE_ITINERARY_COUNT;
        }
    }

    presentFailureBookingOutdated(): void {
        void this.toasterService.presentFailureBookingOutdated();
    }

    async saveItinerary(itinerary: ItineraryBasketInput): Promise<GqlItineraryOrder> {
        const variables: SaveItineraryMutationVariables = {
            itineraryBasket: ServiceToGql.itineraryBasket(itinerary),
        };
        const result = await firstValueFrom(this.saveItineraryGQL.mutate(variables));
        const saveItinerary = result.data?.purchase.saveItinerary;
        if (!saveItinerary) {
            throw new Error('Error: Gateway response for saveItinerary must not be empty.');
        }
        return saveItinerary;
    }

    async getItinerariesBy(itinerariesFetchingParameters: ItinerariesFetchingParameters): Promise<ItineraryAdapter[]> {
        try {
            const variables = this.createGetItinerariesVariables(itinerariesFetchingParameters);
            const result = await firstValueFrom(this.getItinerariesGQL.fetch(variables));
            const itineraries = result.data.itineraries;

            return itineraries.map((itinerary) => {
                const schedule: Itinerary = {
                    scheduledDepartureTime: itinerary.scheduledDepartureTime ?? undefined,
                    scheduledDepartureDate: itinerary.scheduledDepartureDate ?? undefined,
                    scheduledArrivalTime: itinerary.scheduledArrivalTime ?? undefined,
                    scheduledArrivalDate: itinerary.scheduledArrivalDate ?? undefined,
                    arrivalTime: itinerary.arrivalTime,
                    arrivalDate: itinerary.arrivalDate,
                    departureTime: itinerary.departureTime,
                    departureDate: itinerary.departureDate,
                    bookingDeadline: itinerary.bookingDeadline,
                    hasBookingRequirements: itinerary.hasBookingRequirements,
                    hasStopRequest: itinerary.hasStopRequest,
                    remainingTimeBeforeBooking: itinerary.remainingTimeBeforeBooking,
                    isBookable: itinerary.isBookable,
                    isCancelled: itinerary.isCancelled,
                    legs: itinerary.legs.map((leg) => {
                        return {
                            ...leg,
                            stops: leg.stops.map((stop) => {
                                return {
                                    ...stop,
                                    messages: stop.messages.map((message) => {
                                        return {
                                            ...message,
                                            type: this.convertJourneyMessageType(message.type),
                                        };
                                    }),
                                };
                            }),
                            messages: leg.messages.map((message) => {
                                return {
                                    ...message,
                                    type: this.convertJourneyMessageType(message.type),
                                };
                            }),
                        };
                    }),
                    id: itinerary.id,
                    __type__: JourneyEnum.Itinerary,
                };
                return new ItineraryAdapter(schedule);
            });
        } catch (error) {
            throw new TechnicalError('Error on fetching itineraries', ErrorCodes.Itinerary.Fetch, convertToError(error), {
                itinerariesFetchingParameters,
            });
        }
    }

    private convertJourneyMessageType(type: JourneyMessageTypeGQL): JourneyMessageType {
        switch (type) {
            case JourneyMessageTypeGQL.Cancellation:
                return JourneyMessageType.Cancellation;
            case JourneyMessageTypeGQL.Disruption:
                return JourneyMessageType.Disruption;
            default:
                this.loggingService.logError(
                    new TechnicalError('Unexpected JourneyMessageType', ErrorCodes.Graphql.Mapping, undefined, { type }),
                );
                return JourneyMessageType.Disruption;
        }
    }

    // Do not simplify, this is overridden and used by Travys
    getTransportModeFilterPopoverParams(): PopoverOptions {
        return {
            component: TransportModeFilterPopoverComponent,
            cssClass: 'transport-mode-filter-popover',
            backdropDismiss: false,
            translucent: false,
        };
    }

    // Do not simplify, this is overridden and used by Travys
    showTransportModeFilters(): boolean {
        return CompanyService.isTPG();
    }

    private createDepartureInput(itinerariesParameters: ItinerariesFetchingParameters): CoordinatesOrStopIdsInput {
        let departure: CoordinatesOrStopIdsInput;
        const xyDeparture = itinerariesParameters.XYDeparture;
        if (xyDeparture) {
            departure = {
                coordinates: {
                    longitude: xyDeparture.lng,
                    latitude: xyDeparture.lat,
                },
            };
        } else {
            departure = {
                stopIds: itinerariesParameters.departure_stops ? itinerariesParameters.departure_stops : [],
            };
        }
        return departure;
    }

    private createArrivalInput(itinerariesParameters: ItinerariesFetchingParameters): CoordinatesOrStopIdsInput {
        let arrival: CoordinatesOrStopIdsInput;
        const xyArrival = itinerariesParameters.XYArrival;
        if (xyArrival) {
            arrival = {
                coordinates: {
                    longitude: xyArrival.lng,
                    latitude: xyArrival.lat,
                },
            };
        } else {
            arrival = {
                stopIds: itinerariesParameters.arrival_stops ? itinerariesParameters.arrival_stops : [],
            };
        }
        return arrival;
    }

    private createGetItinerariesVariables(itinerariesParameters: ItinerariesFetchingParameters): QueryItinerariesArgs {
        const departure = this.createDepartureInput(itinerariesParameters);
        const arrival = this.createArrivalInput(itinerariesParameters);
        const count = itinerariesParameters.count;
        let searchDirection: SearchDirection;
        let dateTime: string | undefined;
        if (itinerariesParameters.way === LeaveOrArriveEnum.LeaveAt) {
            searchDirection = SearchDirection.LeaveAfterDate;
            dateTime = itinerariesParameters.date || itinerariesParameters.ia;
        } else {
            searchDirection = SearchDirection.ArriveBeforeDate;
            dateTime = itinerariesParameters.ha || itinerariesParameters.ia;
        }

        const transportModes = itinerariesParameters.transportModes || [];
        const lang = this.boldorLocalizationService.languageCode;
        return {
            departure,
            arrival,
            searchDirection,
            count,
            dateTime,
            withDirectItineraries: true,
            transportModes,
            lang,
        };
    }

    getItinerariesFromCache(): Itinerary[] {
        return this.itineraryCache.get();
    }

    storeItineraries(itineraries: Itinerary[]): void {
        this.itineraryCache.set(itineraries.map((itinerary) => ({ ...itinerary, isFromCache: true })));
    }

    /**
     * 1: Clear cache
     * 2 and [...] : Clear state
     *
     * It is important to call clear cache in first. Because all selectors will be triggered when we modify
     * state, if we modify state firstly -> selector will be fired but cache is still filled, then clear cache -> selectors are
     * not fired again because cache is not observed by Ngrx selectors.
     */
    clearItineraries(): void {
        this.itineraryCache.clear();
        this.store.dispatch(new ItineraryStoreActions.ResetLoadedItineraries());
        this.store.dispatch(new ItineraryStoreActions.ClearItineraries());
        this.store.dispatch(setScrollMode({ mode: initialState.mode }));
    }

    createItinerariesFetchingParameters(
        departure: Area,
        arrival: Area,
        date: string,
        count: number,
        way: LeaveOrArriveEnum,
        transportModesFilter: string[] = [],
        highestDepartureDate: string,
    ): ItinerariesFetchingParameters {
        const validDate = date || moment().format('YYYY-MM-DD HH:m');
        const arriveByObj = highestDepartureDate ? { ia: validDate, ha: highestDepartureDate } : { ia: validDate };
        const leaveAtObj = { date: validDate };
        const _date = way === LeaveOrArriveEnum.LeaveAt ? leaveAtObj : arriveByObj;
        const isLatLngArrival =
            isAddress(arrival.metadata.source) ||
            isPoi(arrival.metadata.source) ||
            isFollowGps(arrival.metadata.source) ||
            isMyGpsPosition(arrival.metadata.source);
        const isLatLngDeparture =
            isAddress(departure.metadata.source) ||
            isPoi(departure.metadata.source) ||
            isFollowGps(departure.metadata.source) ||
            isMyGpsPosition(departure.metadata.source);

        let arrivalStops: string[] | null;
        let departureStops: string[] | null;

        if (isLatLngArrival) {
            arrivalStops = null;
        } else {
            arrivalStops = AreaHelper.getUniqCommercialStopIds(arrival);
        }

        if (isLatLngDeparture) {
            departureStops = null;
        } else {
            departureStops = AreaHelper.getUniqCommercialStopIds(departure);
        }

        let xyDeparture: LatLng | undefined = undefined;
        if (isLatLngDeparture) {
            if (AreaHelper.hasLatLng(departure)) {
                xyDeparture = AreaHelper.getLatLng(departure);
            }
            if (departure.metadata.source === ChangePlaceEventSourceEnum.ClickFollowGps) {
                xyDeparture = toBounds(departure.boundsRect)?.getCenter();
            }
        }

        let xyArrival: LatLng | undefined = undefined;
        if (isLatLngArrival) {
            if (AreaHelper.hasLatLng(arrival)) {
                xyArrival = AreaHelper.getLatLng(arrival);
            }
            if (arrival.metadata.source === ChangePlaceEventSourceEnum.ClickFollowGps) {
                xyArrival = toBounds(arrival.boundsRect)?.getCenter();
            }
        }

        return {
            ..._date,
            arrival_stops: arrivalStops ?? [],
            departure_stops: departureStops ?? [],
            count,
            way,
            transportModes: transportModesFilter,
            XYDeparture: xyDeparture,
            XYArrival: xyArrival,
        };
    }
}
