import { TravelClassType, TravelType } from '@traas/boldor/graphql-generated/graphql';
import { TicketAdapter, TicketViewModel } from '@traas/boldor/all-models';
import { CategoryTicketsAdapter } from '../../../models/ticket/category-tickets';

export interface CategoryTicketsViewModel {
    category: {
        title: string;
        description: string;
    };
    tickets: TicketViewModel[];
    displayed: boolean;
}

export class TicketListMapper {
    static mapIntoFilteredTicketsViewModel(
        categoryTicketsAdapter: CategoryTicketsAdapter,
        travelClass: string = TravelClassType.Class_2,
    ): CategoryTicketsViewModel {
        const ticketsAdapters: TicketAdapter[] = categoryTicketsAdapter.getTickets();
        const tickets: TicketViewModel[] = ticketsAdapters.map((ticketAdapter: TicketAdapter) => {
            const { travelType, tripClass } = ticketAdapter.getArticle();
            const hastoBeDisplayed: boolean = travelType === TravelType.Simple && tripClass === travelClass;
            return {
                ...ticketAdapter.getData(),
                isDisplayed: hastoBeDisplayed,
            };
        });
        const category = categoryTicketsAdapter.getCategory();
        return {
            displayed: false,
            tickets,
            category,
        };
    }
}
