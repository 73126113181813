import { Injectable } from '@angular/core';
import { CheckoutStep } from '@traas/boldor/all-models';
import { Store } from '@ngrx/store';
import { PreferencesService } from '@traas/common/feature-account';
import { RoutingService } from '@traas/common/routing';
import { CartService } from '@traas/boldor/common/services/common/cart/cart.service';
import { ItineraryArticleService } from '@traas/boldor/common/features/itinerary/services/itinerary-article.service';
import { DepartureService } from '@traas/boldor/common/features/departure/services/departure.service';
import { QuickArticleService } from '@traas/boldor/common/features/ticket/services/quick-article.service';
import { ArticleCategoryService } from '@traas/boldor/common/features/ticket/services/article-category.service';
import { CartState } from '@traas/boldor/common/features/cart/store';
import { BoldorLocalizationService } from '@traas/common/localization';
import { CustomerProviderService } from '@traas/boldor/common/services/common/customer/customer-provider.service';
import { ModalController } from '@ionic/angular';
import { PurchaseArticleService } from '@traas/boldor/common/services/common/purchase/purchase-article.service';
import { LoggingService } from '@traas/common/logging';
import { AlertService } from '@traas/boldor/common/services/common/alert.service';
import { OrderService } from '@traas/boldor/common/services/common/order/order.service';
import { AnalyticsService } from '@traas/common/analytics';
import { PaymentService } from '@traas/boldor/common/services/common/purchase/payment.service';

@Injectable()
export class TravysCartService extends CartService {
    constructor(
        itineraryArticleService: ItineraryArticleService,
        departureService: DepartureService,
        quickArticleService: QuickArticleService,
        articleCategoryService: ArticleCategoryService<any>,
        localizationService: BoldorLocalizationService,
        cartStore: Store<CartState>,
        preferenceService: PreferencesService,
        routingService: RoutingService,
        customerProviderService: CustomerProviderService,
        preferencesService: PreferencesService,
        modalCtrl: ModalController,
        purchaseArticleService: PurchaseArticleService,
        logger: LoggingService,
        store: Store,
        alertService: AlertService,
        orderService: OrderService,
        analyticsService: AnalyticsService,
        paymentService: PaymentService,
    ) {
        super(
            itineraryArticleService,
            departureService,
            quickArticleService,
            articleCategoryService,
            localizationService,
            cartStore,
            preferenceService,
            routingService,
            customerProviderService,
            preferencesService,
            modalCtrl,
            purchaseArticleService,
            logger,
            store,
            alertService,
            orderService,
            analyticsService,
            paymentService,
        );
    }

    override getPreviousCheckoutStepFromPayment(): CheckoutStep {
        return CheckoutStep.TicketConfiguration;
    }
}
