import { AbstractAdapter, MyGpsPositionPlace, Place, PlacesTypeInterface } from '@traas/boldor/all-models';

export class MyGpsPositionPlaceAdapter extends AbstractAdapter<MyGpsPositionPlace> implements Place<MyGpsPositionPlace> {
    isFromHistory: boolean;

    constructor(data: MyGpsPositionPlace) {
        super(data);
    }

    getNameOrPath(): string {
        return 'locate';
    }

    getName(): string {
        return this.getData().name;
    }

    transformToCoordinates(): GeolocationCoordinates {
        const { lat: latitude, lng: longitude } = this.getData().bounds.getCenter();
        return {
            accuracy: null,
            latitude,
            longitude,
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            speed: null,
        };
    }

    isGpsPosition(): boolean {
        return true;
    }

    getPlaceType(): keyof PlacesTypeInterface {
        return 'myGpsPosition';
    }

    getId(): string {
        return 'gps-place';
    }
}
