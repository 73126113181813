import { Injectable } from '@angular/core';
import { QuickTicketCart } from '@traas/boldor/all-models';
import { BuyGQL, BuyMutationVariables } from '@traas/boldor/graphql-generated/graphql';
import { ServiceToGql } from '@traas/boldor/all-helpers';
import { createPassengerInputFrom } from '../../../models/customer';
import { firstValueFrom } from 'rxjs';
import { PurchaseEmailHelperService } from './purchase-email-helper.service';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

@Injectable({
    providedIn: 'root',
})
export class QuickArticlePurchaseService {
    constructor(private buyGQL: BuyGQL, private purchaseEmailHelperService: PurchaseEmailHelperService) {}

    async buyQuickArticles(cart: QuickTicketCart): Promise<string> {
        const variables = await this.createBuyVariables(cart);
        const result = await firstValueFrom(this.buyGQL.mutate(variables));
        const orderId = result.data?.purchasePublic.buyQuickTicketArticles?.orderId;
        if (!orderId) {
            throw new TechnicalError(
                'buy quick ticket article response must contains an orderId.',
                ErrorCodes.Purchase.BuyArticles,
                undefined,
                { userId: variables.basket.articleSelections?.[0]?.passenger.id },
            );
        }
        return orderId;
    }

    private async createBuyVariables(cart: QuickTicketCart): Promise<BuyMutationVariables> {
        const articleSelections = cart.articleSelections;
        let defaultEmail: string | undefined = undefined;
        if (this.purchaseEmailHelperService.shouldFetchDefaultEmail(cart)) {
            defaultEmail = await this.purchaseEmailHelperService.fetchDefaultCustomerEmail();
        }
        return {
            basket: {
                articleSelections: articleSelections
                    .filter((selection) => !!selection.article)
                    .map(({ article, passenger }) => {
                        const [price] = article.prices;
                        return {
                            zones:
                                article.zones?.map(({ id }) => ({
                                    id,
                                })) ?? [],
                            articleId: article.articleId,
                            articlePrice: ServiceToGql.priceInCents(price),
                            isAuthenticatedCustomer: passenger.isCustomer,
                            passenger: createPassengerInputFrom({ ...passenger, email: passenger.email ?? defaultEmail }),
                            passengerType: passenger.type,
                            thirdPartyPayerInformation: article.thirdPartyPayerInformation
                                ? {
                                      fare: article?.thirdPartyPayerInformation?.fare,
                                      isEligible: article?.thirdPartyPayerInformation?.isEligible,
                                      thirdPartyName: article?.thirdPartyPayerInformation?.thirdPartyName,
                                  }
                                : null,
                        };
                    }),
            },
        };
    }
}
