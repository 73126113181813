// noinspection NestedFunctionJS

import { AnyFn } from '@ngrx/store/src/selector';
import { MemoizedProjection } from '@ngrx/store';
import { isEqual } from 'lodash';

export function isEqualMemoized(t: AnyFn): MemoizedProjection {
    let lastResult: any = null;

    function memoized(): any {
        const result = t.apply(null, arguments);

        if (lastResult == null || !isEqual(result, lastResult)) {
            lastResult = result;
            return result;
        }
        return lastResult;
    }

    function reset() {
        lastResult = null;
    }

    function clearResult() {
        lastResult = null;
    }

    function setResult(value) {
        lastResult = value;
    }

    return { memoized, reset, setResult, clearResult };
}
