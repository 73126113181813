import { CreditCardsState } from './credit-cards.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectCreditCardState = createFeatureSelector<CreditCardsState>('credit-cards');

export const getCreditCards = createSelector(selectCreditCardState, ({ cards }: CreditCardsState) => cards);

export const isCreateCreditCard = createSelector(selectCreditCardState, ({ createCreditCard }: CreditCardsState) => createCreditCard);

export const getSelectedCreditCard = createSelector(
    selectCreditCardState,
    ({ selectedCreditCard }: CreditCardsState) => selectedCreditCard,
);

export const getState = createSelector(selectCreditCardState, (state: CreditCardsState) => state);
