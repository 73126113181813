import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ICON_BASE_PATH } from '../../business-rules.utils';

@Component({
    selector: 'traas-cancelled-trip-icon',
    standalone: true,
    imports: [CommonModule, IonicModule],
    templateUrl: './cancelled-trip-icon.component.html',
    styleUrls: ['./cancelled-trip-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelledTripIconComponent {
    @Input() isBus = true;
    ICON_BASE_PATH = ICON_BASE_PATH;
}
