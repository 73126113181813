import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class StorageService {
    private storage!: Promise<Storage>;
    private _driver: string | null = null;

    constructor(storageParam: Storage) {
        let init = Promise.resolve();
        if (Capacitor.isNativePlatform()) {
            init = storageParam.defineDriver(CordovaSQLiteDriver);
        }
        this.storage = init.then(() => storageParam.create());
        this.storage.then((storage) => (this._driver = storage.driver));
    }

    /**
     * Get the name of the driver being used.
     * @returns Name of the driver
     */
    get driver(): string | null {
        return this._driver;
    }

    /**
     * Get the value associated with the given key.
     * @param key the key to identify this value
     * @returns Returns a promise with the value of the given key
     */
    async get(key: string): Promise<any> {
        const storage = await this.storage;
        return storage.get(key);
    }

    /**
     * Set the value for the given key.
     * @param key the key to identify this value
     * @param value the value for this key
     * @returns Returns a promise that resolves when the key and value are set
     */
    async set(key: string, value: any): Promise<any> {
        const storage = await this.storage;
        return storage.set(key, value);
    }

    /**
     * Remove any value associated with this key.
     * @param key the key to identify this value
     * @returns Returns a promise that resolves when the value is removed
     */
    async remove(key: string): Promise<any> {
        const storage = await this.storage;
        return storage.remove(key);
    }

    /**
     * Clear the entire key value store. WARNING: HOT!
     * @returns Returns a promise that resolves when the store is cleared
     */
    async clear(): Promise<void> {
        const storage = await this.storage;
        return storage.clear();
    }

    /**
     * @returns Returns a promise that resolves with the number of keys stored.
     */
    async length(): Promise<number> {
        const storage = await this.storage;
        return storage.length();
    }

    /**
     * @returns Returns a promise that resolves with the keys in the store.
     */
    async keys(): Promise<string[]> {
        const storage = await this.storage;
        return storage.keys();
    }

    /**
     * Iterate through each key,value pair.
     * @param iteratorCallback a callback of the form (value, key, iterationNumber)
     * @returns Returns a promise that resolves when the iteration has finished.
     */
    async forEach(iteratorCallback: (value: any, key: string, iterationNumber: number) => any): Promise<void> {
        const storage = await this.storage;
        // @ts-ignore
        return storage.forEach(iteratorCallback);
    }
}
