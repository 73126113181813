// CartOperationType is used as string because sometimes we use || comparison
// and with 0 value, (SAVE default value without overriding string), the test is broken.
// Example : { type : myParams.type || CartOperationType.STOP }
export enum CartOperationType {
    SAVE = 'SAVE',
    BUY = 'BUY',
    STOP = 'STOP',
    NOTIFY = 'NOTIFY',
}

export enum CartOperationStatus {
    INITIAL,
    PROCESS,
    DONE,
    FAIL,
}
