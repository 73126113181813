// Dans votre librairie "common"
import { UserSession } from './user-session.model';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Classe statique permettant de stocker la session utilisateur courante.
 * Cette classe est utilisée par Sentry pour envoyer les erreurs avec l'identifiant de l'utilisateur courant.
 * @see https://moviplus.atlassian.net/wiki/spaces/BDOR/pages/2811461652/Error+handling#CustomerId
 */
export class CurrentUserSession {
    private static _activeUser = new BehaviorSubject<UserSession | null>(null);

    static readonly onActiveUserChanged: Observable<UserSession | null> = this._activeUser.asObservable();

    static set activeUser(user: UserSession | null) {
        console.log('[Session] Active user:', user?.id);
        this._activeUser.next(user);
    }

    static get activeUser(): UserSession | null {
        return this._activeUser.getValue();
    }

    static clear(): void {
        this.activeUser = null;
    }
}
