import { Departure, LeaveOrArriveEnum } from '@traas/boldor/all-models';
import { DepartureActions, DepartureActionTypes } from './departure.action';
import { DepartureState, initialState } from './departure.state';
import { mergeArrays, subtractOneSecond } from '@traas/boldor/all-helpers';
import { DepartureAdapter } from '../../../models/departure/departure';
import { compareDeparturesByDepartureDate } from '../containers/departures-list-container/departures-list-container.component';

export function departureReducer(state = initialState, action: DepartureActions): DepartureState {
    switch (action.type) {
        case DepartureActionTypes.Loading:
            if (action.payload === LeaveOrArriveEnum.LeaveAt) {
                return {
                    ...state,
                    future: {
                        ...state.future,
                        loading: true,
                    },
                };
            } else {
                return {
                    ...state,
                    past: {
                        ...state.past,
                        loading: true,
                    },
                };
            }

        case DepartureActionTypes.LoadFail:
            return {
                ...state,
                past: {
                    ...state.past,
                    departures: state.past.departures ? [...state.past.departures] : [],
                    loading: false,
                },
                future: {
                    ...state.future,
                    departures: state.future.departures ? [...state.future.departures] : [],
                    loading: false,
                },
                error: 'erreur',
            };

        case DepartureActionTypes.LoadedMore: {
            const rawFutureDepartures = mergeArrays(state.future.departures, action.payload.departures, (departure) => departure.id).sort(
                compareDeparturesByDepartureDate,
            );
            const futureDepartures = removeDuplicatesFromA(rawFutureDepartures, state.past.departures).sort(
                compareDeparturesByDepartureDate,
            );
            const allDepartures = mergeArrays(state.past.departures, futureDepartures, (departure) => departure.id);
            const dateOfFirstDeparture = getUpcomingDepartureDate(allDepartures);
            if (futureDepartures.length === 0) {
                return {
                    ...initialState,
                    future: {
                        ...initialState.future,
                        departures: [],
                    },
                    error: state.error,
                    lineFilters: state.lineFilters,
                    activeLineFilters: state.activeLineFilters,
                    lineFiltersLoading: state.lineFiltersLoading,
                    commercialStopIdsTarget: state.commercialStopIdsTarget,
                };
            }

            return {
                ...state,
                future: {
                    ...state.future,
                    departures: futureDepartures,
                    nbExpected: action.payload.nbExpected,
                    loading: false,
                },
                error: null,
                refresher: {
                    date: dateOfFirstDeparture,
                    departuresCount: undefined,
                },
            };
        }

        case DepartureActionTypes.LoadedPrevious: {
            const rawPastDepartures = mergeArrays(state.past.departures, action.payload.departures, (departure) => departure.id) || [];
            const futureDepartures = state.future.departures || [];
            const pastDepartures = removeDuplicatesFromA(rawPastDepartures, futureDepartures).sort(compareDeparturesByDepartureDate);

            const allDepartures = mergeArrays(pastDepartures, futureDepartures, (departure) => departure.id);
            const dateOfFirstDeparture = getUpcomingDepartureDate(allDepartures);
            return {
                ...state,
                past: {
                    ...state.past,
                    departures: pastDepartures,
                    nbExpected: action.payload.nbExpected,
                    loading: false,
                },
                refresher: {
                    date: dateOfFirstDeparture,
                    departuresCount: undefined,
                },
            };
        }

        case DepartureActionTypes.Reload: {
            return {
                ...initialState,
                error: state.error,
                lineFilters: state.lineFilters,
                activeLineFilters: state.activeLineFilters,
                lineFiltersLoading: state.lineFiltersLoading,
                commercialStopIdsTarget: state.commercialStopIdsTarget,
            };
        }

        case DepartureActionTypes.ClearDepartures:
            return {
                ...state,
                future: { ...initialState.future },
                past: { ...initialState.past },
                inScrollViewport: initialState.inScrollViewport,
                refresher: { ...initialState.refresher },
            };

        case DepartureActionTypes.SetDeparturesInScrollViewport:
            return {
                ...state,
                inScrollViewport: action.payload.departures,
            };

        case DepartureActionTypes.OpenDetails:
            return {
                ...state,
                selected: {
                    departure: { ...action.payload },
                },
            };

        case DepartureActionTypes.SetLineFilters:
            return {
                ...state,
                lineFilters: [...action.payload],
            };

        case DepartureActionTypes.SetActiveLineFilters:
            return {
                ...state,
                activeLineFilters: [...action.payload],
            };

        case DepartureActionTypes.StartRefreshLinesOfDeparture:
            return {
                ...state,
                lineFiltersLoading: true,
            };

        case DepartureActionTypes.StopRefreshLinesOfDeparture:
            return {
                ...state,
                lineFiltersLoading: false,
            };

        case DepartureActionTypes.SetCommercialStopIdsTarget:
            return {
                ...state,
                commercialStopIdsTarget: action.commercialStopIds,
            };

        case DepartureActionTypes.SetSelectedDeparture:
            return {
                ...state,
                selected: {
                    departure: { ...action.payload },
                },
            };

        default:
            return state;
    }
}

function getUpcomingDepartureDate(allDepartures: Departure[]): Date {
    const now: Date = new Date();
    const firstDeparture = allDepartures.find((departure) => new Date(new DepartureAdapter(departure).getRealTimeDepartureDate()) > now);
    const refresherDate: Date = firstDeparture ? new Date(new DepartureAdapter(firstDeparture).getRealTimeDepartureDate()) : now;
    return subtractOneSecond(refresherDate);
}

// If a departure is in pastDepartures and also in futureDepartures, we remove it from pastDepartures.
function removeDuplicatesFromA(a: Departure[], b: Departure[]): Departure[] {
    const futureDeparturesIds = b.map((d) => d.id);
    return a.filter((departure) => !futureDeparturesIds.includes(departure.id));
}
