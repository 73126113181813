import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartureTimeCancelledComponent } from './departure-time-cancelled/departure-time-cancelled.component';
import { ThresholdConfiguration, TimeDisplayMode } from '@traas/boldor/all-models';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';
import { DEPARTURE_TIME_ICON_REFRESH_INTERVAL_IN_SECONDS } from '../../business-rules.utils';
import { ONE_SECOND_IN_MS } from '@traas/boldor/all-helpers';
import { TimerService } from '../../services/common/timer/timer.service';
import { DepartureTimeService, DepartureTimeViewModel } from '../../features/departure/services/departure-time/departure-time.service';
import { DepartureTimeComponent } from './departure-time/departure-time.component';

interface DepartureTimeInput {
    thresholds: ThresholdConfiguration;
    isCancelled: boolean;
    delayInSec: number;
    timestampInMS: number;
    outdatedDate: Date | undefined;
    timeDisplayMode: TimeDisplayMode;
}

@Component({
    selector: 'traas-trip-departure-time',
    standalone: true,
    imports: [CommonModule, DepartureTimeComponent, DepartureTimeCancelledComponent],
    templateUrl: './trip-departure-time.component.html',
    styleUrls: ['./trip-departure-time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripDepartureTimeComponent implements OnInit, OnDestroy {
    $departureTimeVM: Observable<DepartureTimeViewModel>;
    readonly $departureTimeInput = new BehaviorSubject<DepartureTimeInput>({
        outdatedDate: undefined,
        timestampInMS: undefined,
        timeDisplayMode: undefined,
        delayInSec: undefined,
        thresholds: undefined,
        isCancelled: false,
    });
    private timerService = inject(TimerService);
    private departureTimeService = inject(DepartureTimeService);

    @Output() update = new EventEmitter<void>();

    @Input() set thresholds(value: ThresholdConfiguration) {
        this.updateInput({ thresholds: value });
    }

    @Input() set isCancelled(value: boolean) {
        this.updateInput({ isCancelled: value });
    }

    @Input() set delay(value: number) {
        this.updateInput({ delayInSec: value });
    }

    @Input() set timestamp(timestampInMS: number) {
        this.updateInput({ timestampInMS });
    }

    @Input() set outdatedDate(value: Date | undefined) {
        this.updateInput({ outdatedDate: value });
    }

    @Input() set timeDisplayMode(timeDisplay: TimeDisplayMode) {
        this.updateInput({ timeDisplayMode: timeDisplay });
    }

    ngOnInit(): void {
        this.$departureTimeVM = this.$buildDepartureTime();
    }

    ngOnDestroy(): void {
        this.$departureTimeInput.complete();
    }

    private updateInput(partialInput: Partial<DepartureTimeInput>): void {
        this.$departureTimeInput.next({ ...this.$departureTimeInput.getValue(), ...partialInput });
    }

    private $buildTimer(): Observable<number> {
        return this.timerService
            .$synchronizedTimer(DEPARTURE_TIME_ICON_REFRESH_INTERVAL_IN_SECONDS * ONE_SECOND_IN_MS, true)
            .pipe(startWith(0));
    }

    private $buildDepartureTime(): Observable<DepartureTimeViewModel> {
        const $timer = this.$buildTimer();

        return combineLatest([$timer, this.$departureTimeInput]).pipe(
            filter(([_, input]) => !!input.timestampInMS && !!input.timeDisplayMode),
            map(([_, input]) => this.departureTimeService.createDepartureTimeViewModel(input)),
            tap(() => this.update.emit()),
            distinctUntilChanged(),
        );
    }
}
