import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NearestStop, TicketViewModel } from '@traas/boldor/all-models';
import { CompanyService } from '../../../../services/common/company/company.service';
import { ArticleAdapter } from '../../../../models/article';

@Component({
    selector: 'app-ticket-list-item',
    templateUrl: './ticket-list-item.component.html',
    styleUrls: ['./ticket-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketListItemComponent implements OnInit {
    @Input() nearestStop: NearestStop | null;
    @Input() customNgClass: string;
    @Input() isLastChild: boolean;
    @Input() ticket: TicketViewModel;

    @Output() ticketBuy = new EventEmitter<string>();
    @Output() buyTicketByZone = new EventEmitter<TicketViewModel>();

    readonly isTpc = CompanyService.isTPC();
    hasOnlyPaymentBySmsAllowed: boolean;
    code: string;
    description: string;
    prices: string[];
    title: string;

    ngOnInit(): void {
        const article = this.ticket.article;
        const articleAdapter = new ArticleAdapter(article);
        this.code = this.ticket.article.smsCode;
        this.description = articleAdapter.getDescription();
        this.prices = articleAdapter.getPrices();
        this.title = this.getTitle(articleAdapter);
        this.hasOnlyPaymentBySmsAllowed = articleAdapter.isPaymentBySmsAllowed() && !articleAdapter.isPaymentByCreditCardAllowed();
    }

    buy(): void {
        this.ticketBuy.emit(this.code);
        this.buyTicketByZone.emit(this.ticket);
    }

    private getTitle(articleAdapter: ArticleAdapter): string {
        return this.isTpc && this.nearestStop ? `De: ${this.nearestStop.stopName}` : articleAdapter.getTitle();
    }
}
