import { Component } from '@angular/core';
import { environment } from '@traas/boldor/environments';
import { PlatformUtilsService } from '@traas/common/utils';
import { from, Observable, of } from 'rxjs';
import { App } from '@capacitor/app';

@Component({
    selector: 'app-version-number',
    templateUrl: './version-number.component.html',
    styleUrls: ['./version-number.component.scss'],
})
export class VersionNumberComponent {
    $version: Observable<string | number>;

    constructor(private platformUtilsService: PlatformUtilsService) {
        if (this.platformUtilsService.isWeb()) {
            this.$version = of(environment.version);
        } else {
            this.$version = from(this.getVersionNumber());
        }
    }

    private async getVersionNumber(): Promise<string> {
        const { version } = await App.getInfo();
        return version;
    }
}
