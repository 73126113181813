import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MessageViewModel } from '../../models/message-view-model';
import { StartupNotificationSelectors } from '../../store';
import { StartupNotificationState } from '../../store/startup-notification-state';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
    selector: 'startup-notification-container',
    templateUrl: './startup-notification-container.component.html',
    styleUrls: ['./startup-notification-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartupNotificationContainerComponent {
    $message: Observable<MessageViewModel>;

    constructor(private modalCtrl: ModalController, private store: Store<StartupNotificationState>) {
        this.$message = this.store.select(StartupNotificationSelectors.getCurrentMessage);
    }

    acknowledge(): Promise<boolean> {
        return this.modalCtrl.dismiss(true);
    }

    postpone(): Promise<boolean> {
        return this.modalCtrl.dismiss(false);
    }
}
