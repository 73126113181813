<ion-item lines="none" class="ticket" [class.isLastChild]="isLastChild" [ngClass]="customNgClass" (click)="buy()">
    <div class="ticket-row">
        <div *ngIf="isTpc" class="ticket-icon"></div>
        <div [class.tpg]="!isTpc" class="ticket-container">
            <div class="ticket-code">{{ title }}<span *ngIf="hasOnlyPaymentBySmsAllowed">&nbsp;(SMS)</span></div>
            <div class="ticket-description">{{ description }}</div>
        </div>
        <div *ngIf="prices[0]" class="ticket-price">{{ prices[0] }}</div>
    </div>
</ion-item>
