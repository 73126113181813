<div class="departure">
    <ng-container *ngIf="departureTimeViewModel.isTransportApproaching; else showDepartureTime">
        <ion-icon src="assets/icons/bus_timer.svg" class="icon e2e-departure-bus-icon"></ion-icon>
        <div class="placeholder"></div>
    </ng-container>
    <ng-template #showDepartureTime>
        <span class="departure-time e2e-departure-time">{{ departureTimeViewModel.formattedTime }}</span>
        <span class="departure-delay">{{ departureTimeViewModel.formattedDelay }}</span>
    </ng-template>
</div>
