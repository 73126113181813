import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticatedGuardFnFactory } from '@traas/boldor/common/guards/is-authenticated.guard';
import { redirectToLoginOnAllReject } from '@traas/common/utils';
import { MenuPageComponent } from './pages/menu/menu';

const routes: Routes = [
    {
        path: '',
        component: MenuPageComponent,
    },
    {
        path: 'customer-service',
        loadChildren: () =>
            import('@traas/boldor/common/pages/customer-service/customer-service.module').then(
                (module) => module.CustomerServicePageModule,
            ),
    },
    {
        path: 'menu-cgu',
        loadChildren: () => import('@traas/boldor/travys').then((module) => module.TravysDisplayTosPageModule),
    },
    {
        path: 'menu-cgv',
        loadChildren: () => import('@traas/boldor/travys').then((module) => module.TravysDisplayConditionsOfSalePageModule),
    },
    {
        path: 'accessibility',
        loadChildren: () =>
            import('@traas/boldor/common/pages/accessibility/accessibility-page.module').then((module) => module.AccessibilityPageModule),
    },
    {
        path: 'account',
        canActivate: [redirectToLoginOnAllReject(isAuthenticatedGuardFnFactory)],
        loadChildren: () => import('@traas/boldor/common/pages/account/account.module').then((module) => module.AccountPageModule),
    },
    {
        path: 'login',
        loadChildren: () => import('@traas/boldor/travys').then((module) => module.TravysLoginPageModule),
    },
    {
        path: 'preferences',
        loadChildren: () =>
            import('@traas/boldor/common/pages/preferences/preferences-page.module').then((module) => module.PreferencesPageModule),
    },
    {
        path: 'frequent-travelers',
        canActivate: [redirectToLoginOnAllReject(isAuthenticatedGuardFnFactory)],
        loadChildren: () =>
            import('@traas/boldor/common/pages/frequent-travelers/frequent-travelers-page.module').then(
                (module) => module.FrequentTravelersPageModule,
            ),
    },
    {
        path: 'credit-cards',
        canActivate: [redirectToLoginOnAllReject(isAuthenticatedGuardFnFactory)],
        loadChildren: () =>
            import('@traas/boldor/common/pages/credit-cards/credit-cards-page.module').then((module) => module.CreditCardsPageModule),
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FeatureMenuRoutingModule {}
