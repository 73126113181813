import { NgModule } from '@angular/core';
import { MenuPageComponent } from './pages/menu/menu';
import { TicketModule } from '@traas/boldor/common/features/ticket/ticket.module';
import { PageTitleModule } from '@traas/boldor/common/components/page-title/page-title.module';
import { MenuComponent } from './components/menu/menu.component';
import { FeatureMenuRoutingModule } from './feature-menu-routing.module';
import { BoldorFeatureShellSharedModule } from '@traas/boldor/common/boldor-feature-shell-shared.module';
import { MenuModule } from '@traas/boldor/common/features/menu/menu.module';

@NgModule({
    declarations: [MenuPageComponent, MenuComponent],
    imports: [BoldorFeatureShellSharedModule, TicketModule, PageTitleModule, FeatureMenuRoutingModule, MenuModule],
})
export class FeatureMenuModule {}
