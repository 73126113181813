import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/* I wish we could know and type this properly, but the transport ids are coming from the server itself.
 A solution would be to get the symbol url from the server itself */
export type Transport = string;

@Component({
    selector: 'app-vehicle-icon',
    templateUrl: './vehicle-icon.component.html',
    styleUrls: ['./vehicle-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleIconComponent {
    @Input() transport!: Transport;

    getVehicleSymbolURL(): string {
        return `${this.getAtlasURL()}#${this.getVehicleSymbolName()}`;
    }

    // noinspection JSMethodCanBeStatic
    private getAtlasURL(): string {
        return 'assets/imgs/transports.min.svg';
    }

    private getVehicleSymbolName(): string {
        return this.transport.replace('|', '');
    }
}
