import { BaseError } from './base-error';
import { ErrorContext } from './error-context.model';

const name = 'TechnicalError';

export class TechnicalError extends BaseError {
    readonly errorCode: string; // stands for client error code.
    readonly serverErrorCode?: string;

    /**
     * @param message
     * @param errorCode - The error code must come from the error-codes.ts file.
     * @param innerError
     * @param context - If the error context is too deep (>2) we need to flatten/stringify it.
     */
    constructor(message: string, errorCode: string, innerError?: Error, context?: ErrorContext) {
        super(message, name, innerError, context);
        this.errorCode = errorCode;
        this.name = name;
        this.serverErrorCode = this.determineServerErrorCode(innerError);
    }
}
