import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationBehaviorOptions, NavigationExtras, Params, Router, UrlCreationOptions } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ArticlesBundle, CheckoutStep, GuestCustomer, OrderViewModel, Traveler } from '@traas/boldor/all-models';
import { LatLng } from 'leaflet';
import { FROM_TRANSIT_STOP_PARAM, RETURN_URL_PARAM_NAME, RouteUrl } from './url-constants';

/**
 * To see bug about more router-outlet :
 * https://github.com/ionic-team/ionic/issues/15798
 * https://github.com/ionic-team/ionic/issues/16411
 */
@Injectable()
export class RoutingService {
    private readonly routingTable: { [k in CheckoutStep]?: RouteUrl } = {
        [CheckoutStep.OffersLoader]: RouteUrl.cartOffersLoader,
        [CheckoutStep.OperationChooser]: RouteUrl.cartOperationChooser,
        [CheckoutStep.ZonesPicker]: RouteUrl.cartZonesPicker,
        [CheckoutStep.TicketConfiguration]: RouteUrl.cartOrdersUrl,
        [CheckoutStep.Payment]: RouteUrl.cartPaymentUrl,
    };

    constructor(
        protected readonly location: Location,
        protected readonly router: Router,
        protected readonly navCtrl: NavController,
        protected readonly activatedRoute: ActivatedRoute,
    ) {}

    goBack(): void {
        this.location.back();
    }

    getRouteUrl(): string {
        return this.router.url;
    }

    navigateTo(target: string, extra: NavigationExtras = {}): Promise<boolean> {
        return this.router.navigate([target], extra);
    }

    // SPECIFIC

    async navigateToGuestForm(returnUrl: string | null): Promise<void> {
        await this.router.navigate([RouteUrl.signAsGuestUrl], {
            queryParams: {
                returnUrl,
            },
        });
    }

    async navigateToGuestTickets(extras?: NavigationBehaviorOptions): Promise<void> {
        if (extras) {
            await this.router.navigateByUrl(RouteUrl.guestTicketsPageUrl, extras);
        } else {
            await this.navigateToQuickTicketsTab();
        }
    }

    navigateToHome(fromTransitStop = false): Promise<boolean> {
        if (fromTransitStop) {
            const navigationExtras: NavigationExtras = {
                state: {
                    [FROM_TRANSIT_STOP_PARAM]: fromTransitStop,
                },
                queryParamsHandling: 'preserve',
            };
            return this.router.navigate([RouteUrl.departureResultUrl], navigationExtras);
        }
        return this.router.navigate([RouteUrl.departureResultUrl], {
            replaceUrl: true, // Prevents native back behaviour on iOS
        });
    }

    async navigateToLogin(
        returnUrl: string | null,
        options?: { showBuyTicketBySmsButton?: boolean; showGuestCheckoutButton?: boolean },
    ): Promise<void> {
        await this.router.navigate([RouteUrl.loginUrl], {
            queryParams: {
                returnUrl,
                showBuyTicketBySmsButton: options?.showBuyTicketBySmsButton ?? false,
                showGuestCheckoutButton: options?.showGuestCheckoutButton ?? false,
            },
        });
    }

    navigateToItineraryDetails(navigateBack?: boolean): Promise<boolean> {
        if (navigateBack) {
            return this.router.navigateByUrl(RouteUrl.itineraryDetailUrl);
        }
        return this.router.navigate([RouteUrl.itineraryDetailUrl], {
            queryParamsHandling: 'preserve',
        });
    }

    // tslint:disable-next-line:max-line-length
    async navigateToEditFrequentTravelers(traveler: Traveler, isFromCart: boolean, articlesBundle: ArticlesBundle): Promise<void> {
        const navigationExtras: NavigationExtras = {
            state: {
                isFromCart,
                traveler,
                articlesBundle,
            },
        };
        const url = this.getEditFrequentTravelersUrl(isFromCart);
        await this.router.navigate([url], navigationExtras);
    }

    getEditFrequentTravelersUrl(isFromCart: boolean): string {
        return isFromCart ? RouteUrl.frequentTravelersEditUrlFromCart : RouteUrl.frequentTravelersEditUrlFromMenu;
    }

    getFrequentTravelersUrl(isFromCart: boolean): string {
        return isFromCart ? RouteUrl.frequentTravelersUrlFromCart : RouteUrl.frequentTravelersUrlFromMenu;
    }

    async navigateToFrequentTravelers(isFromCart: boolean, passengerType: string, articlesBundle?: ArticlesBundle): Promise<void> {
        const navigationExtras: NavigationExtras = {
            state: {
                articlesBundle,
                passengerType,
                isFromCart,
            },
        };
        const url = this.getFrequentTravelersUrl(isFromCart);
        await this.router.navigate([url], navigationExtras);
    }

    async navigateToBookings(): Promise<void> {
        await this.router.navigateByUrl(RouteUrl.bookingsTabUrl);
    }

    async navigateToQuickTicketsTab(navigateBack = false): Promise<boolean> {
        if (navigateBack) {
            return this.navCtrl.navigateBack(RouteUrl.ticketsTabUrl);
        }
        return this.router.navigateByUrl(RouteUrl.ticketsTabUrl);
    }

    async navigateToOrder({ id }: OrderViewModel): Promise<boolean> {
        return this.router.navigateByUrl(`${RouteUrl.bookingsTabUrl}/details/${id}`);
    }

    async navigateToAccount(): Promise<void> {
        await this.router.navigateByUrl(`${RouteUrl.menuTabUrl}/account`);
    }

    async navigateToCart(step: CheckoutStep, guestCustomer?: GuestCustomer): Promise<void> {
        const route = this.routingTable[step] ?? RouteUrl.cartError;
        const options: NavigationExtras = { state: { guestCustomer } };
        await this.router.navigateByUrl(route, options);
    }

    async updateUrlFromMap(centerPoint: LatLng, zoomLevel: number, action: string): Promise<void> {
        const queryParams: Params = {
            lat: centerPoint.lat,
            lng: centerPoint.lng,
            z: zoomLevel,
            action,
        };

        await this.router.navigate([], this.getUrlExtras(queryParams));
    }

    // With this function we are overwriting the last history element instead of pushing a second one
    async updateUrlAndReplaceHistory(queryParams: Params): Promise<void> {
        const tree = this.router.createUrlTree([], this.getUrlExtras(queryParams));

        const path = this.router.serializeUrl(tree);

        // We need to keep this value alive in the state
        const fromTransit = window.history.state[FROM_TRANSIT_STOP_PARAM];

        this.location.replaceState(path, '', { from_transit_stop: fromTransit });
    }

    async navigateToSwissPassPage(): Promise<boolean> {
        return this.router.navigateByUrl(`${RouteUrl.menuTabUrl}/swisspass`);
    }

    getCurrentReturnUrl(): string {
        return this.activatedRoute.snapshot.queryParams[RETURN_URL_PARAM_NAME];
    }

    getSwisspassSSORedirectUrl(): string {
        return this.activatedRoute.snapshot.queryParams['swisspassSSORedirectUrl'];
    }

    private getUrlExtras(queryParams: Params): UrlCreationOptions {
        return {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge',
        };
    }
}
