import { NgModule } from '@angular/core';
import { PaymentStatusComponent } from './components/payment-card-status/payment-status.component';
import { PaymentStoreModule } from './store/payment-store.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';

@NgModule({
    imports: [BoldorFeatureShellSharedModule, PaymentStoreModule],
    declarations: [PaymentStatusComponent],
    exports: [PaymentStatusComponent],
})
export class PaymentModule {}
