import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { OfflinePageComponent } from './offline-page.component';

@NgModule({
    declarations: [OfflinePageComponent],
    imports: [CommonModule, IonicModule],
    exports: [OfflinePageComponent],
})
export class OfflinePageModule {}
