import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BoldorFeatureShellSharedModule } from '@traas/boldor/common/boldor-feature-shell-shared.module';
import { PageTitleModule } from '@traas/boldor/common/components/page-title/page-title.module';
import { TravysLoginPageComponent } from './page/travys-login-page.component';
import { CommonLoginComponentModule } from '@traas/boldor/common/features/login/containers/login/common-login-component.module';

const routes: Routes = [
    {
        path: '',
        component: TravysLoginPageComponent,
    },
];

@NgModule({
    declarations: [TravysLoginPageComponent],
    imports: [RouterModule.forChild(routes), BoldorFeatureShellSharedModule, CommonLoginComponentModule, PageTitleModule],
    exports: [RouterModule],
})
export class TravysLoginPageModule {}
