import { Bounds, Point } from 'leaflet';

interface EdgeMarkerPoint {
    side: string;
    angle: number;
    point: number[];
}

export function getEdgeMarkerPoint(mapPixelBounds: Bounds, currentMarkerPosition: Point): EdgeMarkerPoint {
    const markerWidth = 50;
    const markerHeight = 70;

    let { x } = currentMarkerPosition;
    let { y } = currentMarkerPosition;

    // we want to place EdgeMarker on the line from center screen to target,
    // and against the border of the screen
    // we know angle and its x or y coordinate
    // (depending if we want to place it against top/bottom edge or left right edge)
    // from that we can calculate the other coordinate
    const center = mapPixelBounds.getCenter();

    const rad = Math.atan2(center.y - y, center.x - x);

    const angle = (rad / Math.PI) * 180;
    let side = 'top';
    const rad2TopLeftcorner = Math.atan2(center.y - mapPixelBounds.min.y, center.x - mapPixelBounds.min.x);

    // target is in between diagonals window/ hourglass
    // more out in y then in x
    if (Math.abs(rad) > rad2TopLeftcorner && Math.abs(rad) < Math.PI - rad2TopLeftcorner) {
        if (y < center.y) {
            side = 'top';
            // top out
            y = mapPixelBounds.min.y + markerHeight / 2;
            x = center.x - (center.y - y) / Math.tan(Math.abs(rad));
        } else {
            side = 'bottom';
            // bottom out
            y = mapPixelBounds.max.y - markerHeight / 2;
            x = center.x - (y - center.y) / Math.tan(Math.abs(rad));
        }
    } else {
        // left out
        if (x < center.x) {
            side = 'left';
            x = mapPixelBounds.min.x + (markerWidth + 10) / 2;
            y = center.y - (center.x - x) * Math.tan(rad);
            // right out
        } else {
            side = 'right';
            x = mapPixelBounds.max.x - markerWidth / 2;
            y = center.y + (x - center.x) * Math.tan(rad);
        }
    }
    // correction so that is always has same distance to edge

    // top out (top has y=0)
    if (y < mapPixelBounds.min.y + markerHeight / 2) {
        y = mapPixelBounds.min.y + markerHeight / 2;
        // bottom out
    } else if (y > mapPixelBounds.max.y - markerHeight / 2) {
        y = mapPixelBounds.max.y - markerHeight / 2;
    }
    // right out
    if (x > mapPixelBounds.max.x - markerWidth / 2) {
        x = mapPixelBounds.max.x - markerWidth / 2;
        // left out
    } else if (x < markerWidth / 2) {
        x = mapPixelBounds.min.x + markerWidth / 2;
    }

    return { side, angle, point: [x, y] };
}
