import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconMode } from '@traas/boldor/all-models';

@Component({
    selector: 'app-dynamic-ion-icon',
    templateUrl: 'dynamic-ion-icon.component.html',
    styleUrls: ['dynamic-ion-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicIonIconComponent implements OnInit {
    get nameOrPath(): string {
        return this.nameOrPathValue;
    }

    @Input()
    set nameOrPath(value: string) {
        this.iconMode = DynamicIonIconComponent.isIonIconPath(value) ? IconMode.SRC : IconMode.NAME;
        this.nameOrPathValue = value;
    }

    @Input()
    mode = '';

    @Input()
    size = '2.8';

    iconMode?: IconMode;
    iconModeSRC = IconMode.SRC;
    iconModeNAME = IconMode.NAME;
    private nameOrPathValue = '';

    static isIonIconPath(iconPathOrName: string): boolean {
        return iconPathOrName.includes('assets/');
    }

    ngOnInit(): void {
        this.iconMode = DynamicIonIconComponent.isIonIconPath(this.nameOrPath) ? IconMode.SRC : IconMode.NAME;
    }
}
