import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DynamicIonIconModule } from '../dynamic-ion-icon/dynamic-ion-icon.module';
import { EndpointComponent } from './endpoint.component';

@NgModule({
    imports: [CommonModule, IonicModule, DynamicIonIconModule],
    declarations: [EndpointComponent],
    exports: [EndpointComponent],
})
export class EndpointModule {}
