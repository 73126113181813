import { Component, Input, OnDestroy } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { GuestCustomer } from '@traas/boldor/all-models';
import { BoldorLocalizationService } from '@traas/common/localization';
import { RoutingService } from '@traas/common/routing';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, skip, switchMap, withLatestFrom } from 'rxjs/operators';
import { CartActions, CartSelectors, CartState } from '../../features/cart/store';
import { PaymentStoreSelectors } from '../../features/payment/store';
import { PaymentState, PaymentStatus, TicketStatus } from '../../features/payment/store/payment.state';
import { CartService } from '../../services/common/cart/cart.service';
import { ProgressService } from './progress.service';
import { PaymentService } from '../../services/common/purchase/payment.service';
import { convertToError, LoggingService } from '@traas/common/logging';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { OnlineService } from '@traas/common/utils';
import { HardwareBackButtonEventsPriorityIonic } from '../../hardware-back-button-events-priority-ionic';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.page.html',
    styleUrls: ['./payment.page.scss'],
})
export class PaymentPage implements OnDestroy {
    @Input() guestCustomer: GuestCustomer | undefined;

    readonly $paymentStatusDescription!: Observable<string>;
    readonly $paymentStatusTitle!: Observable<string>;
    readonly $progressionPercent = new BehaviorSubject<number>(0);
    readonly $isRunningTooLong: Observable<boolean>;
    readonly $isStillGeneratingTimeout: Observable<boolean>;
    readonly $isPaymentError: Observable<boolean>;
    readonly $isOffline: Observable<boolean>;

    private progressionSubscription?: Subscription;
    private backButtonSubscription: Subscription;

    constructor(
        private boldorLocalizationService: BoldorLocalizationService,
        private cartService: CartService,
        private cartStore: Store<CartState>,
        private modalCtrl: ModalController,
        private paymentStore: Store<PaymentState>,
        private routingService: RoutingService,
        private progressService: ProgressService,
        private paymentService: PaymentService,
        private logger: LoggingService,
        private platform: Platform,
        private onlineService: OnlineService,
    ) {
        this.$isOffline = this.onlineService.$getIsOnline().pipe(map((isOnline) => !isOnline));

        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(
            HardwareBackButtonEventsPriorityIonic.Overlays + 1,
            () => {
                console.log('Back button pressed, but action is disabled on this page.');
            },
        );
        this.$isRunningTooLong = this.progressService.$isRunningTooLong;

        const $paymentAndTicketStatus = this.paymentStore.select(PaymentStoreSelectors.getPaymentAndTicketStatus).pipe(shareReplay(1));

        this.$paymentStatusTitle = $paymentAndTicketStatus.pipe(
            withLatestFrom(
                this.cartStore.select(CartSelectors.isSelectionEligibleForThirdPartyPayement),
                (paymentAndTicketStatus, isEligible) => ({
                    paymentAndTicketStatus,
                    isEligible,
                }),
            ),
            map(({ paymentAndTicketStatus: { paymentStatus, ticketStatus }, isEligible }) => {
                if (isEligible) {
                    return 'third-party-payer.generating';
                }
                return this.getStatusLabel(paymentStatus, ticketStatus);
            }),
            switchMap((statusKey) => this.boldorLocalizationService.get(statusKey)),
        );

        this.$paymentStatusDescription = $paymentAndTicketStatus.pipe(
            map(({ paymentStatus, ticketStatus }) => this.getStatusDescription(paymentStatus, ticketStatus)),
            switchMap((statusKey) => this.boldorLocalizationService.get(statusKey)),
        );

        this.$isStillGeneratingTimeout = $paymentAndTicketStatus.pipe(
            map(({ ticketStatus }) => ticketStatus === TicketStatus.RECOVERY_FAILED),
        );

        this.$isPaymentError = $paymentAndTicketStatus.pipe(map(({ paymentStatus }) => this.paymentService.isPaymentError(paymentStatus)));

        this.setupProgression();
    }

    ngOnDestroy(): void {
        this.progressionSubscription?.unsubscribe();
        this.backButtonSubscription.unsubscribe();
    }

    async onGoBack(): Promise<void> {
        try {
            this.progressionSubscription?.unsubscribe();
            this.paymentService.resetState();
            this.cartStore.dispatch(new CartActions.SetSelectedPaymentMean(false));
            const previousStep = this.cartService.getPreviousCheckoutStepFromPayment();
            await this.routingService.navigateToCart(previousStep, this.guestCustomer);
            this.cartStore.dispatch(new CartActions.SetCheckoutStep(previousStep));
            await this.modalCtrl.dismiss();
        } catch (error: any) {
            this.logger.logError(new TechnicalError('Cannot go back from payment page', ErrorCodes.Purchase.GoBack, convertToError(error)));
        }
    }

    private getStatusLabel(paymentStatus: PaymentStatus, ticketStatus: TicketStatus): string {
        if (ticketStatus === TicketStatus.RECOVERY_FAILED) {
            return 'payment.status.recovery-failed';
        }
        switch (paymentStatus) {
            case PaymentStatus.PREPARATION:
                return 'payment.status.preparation';
            case PaymentStatus.VERIFICATION:
                return 'payment.status.verification';
            case PaymentStatus.VERIFIED:
                return 'payment.status.verified';
            case PaymentStatus.VALIDATION:
                return 'payment.status.validation';
            case PaymentStatus.TIMED_OUT:
            case PaymentStatus.FAIL:
                return 'payment.status.fail';
            case PaymentStatus.BUY_FAIL:
                return 'payment.status.buy-fail';
            case PaymentStatus.ABORT:
                return 'payment.status.abort';
            case PaymentStatus.CAPTURED:
                return 'payment.status.success';
            case PaymentStatus.INITIAL:
            default:
                return 'payment.status.initial';
        }
    }

    private getStatusDescription(paymentStatus: PaymentStatus, ticketStatus: TicketStatus): string {
        if (ticketStatus === TicketStatus.RECOVERY_FAILED) {
            return 'payment.ticket-recovery-failed';
        }
        switch (paymentStatus) {
            case PaymentStatus.BUY_FAIL:
                return 'payment.buy-failed';
            case PaymentStatus.ABORT:
                return 'payment.payment-aborted';
            case PaymentStatus.FAIL:
                return 'payment.payment-failed';
            case PaymentStatus.TIMED_OUT:
                return 'payment.ticket-generation-failed';
            default:
                return 'payment.please-wait';
        }
    }

    private setupProgression(): void {
        const $paymentStatus = this.paymentStore.select(PaymentStoreSelectors.getPaymentAndTicketStatus).pipe(
            map((status) => status.paymentStatus),
            distinctUntilChanged(),
        );

        this.progressionSubscription = this.progressService.startProgression($paymentStatus).subscribe({
            next: (progress) => {
                const newProgressIsBackward = progress < this.$progressionPercent.value;
                if (newProgressIsBackward) {
                    return;
                }
                this.$progressionPercent.next(progress);
            },
            complete: () => this.$progressionPercent.next(100),
        });
    }

    async goToBookingTab(): Promise<void> {
        await this.routingService.navigateToBookings();
        await this.modalCtrl.dismiss();
        this.cartService.resetState();
    }
}
