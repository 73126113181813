<ion-row class="ion-align-items-center" *ngIf="!ticket.isProcessing">
    <ion-col size="8" class="flex title ion-align-items-center">
        <ion-icon class="operation-icon" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
        <p class="ion-no-margin">
            {{ ticket.article.title }}
        </p>
    </ion-col>
    <ion-col size="4" class="price">
        <p class="ion-no-margin" [class.strikethrough]="ticket.article?.thirdPartyPayerInformation?.isEligible">
            {{ getFormattedPrice(ticket.article.prices[0]) }}
        </p>
        <span class="third-party-payer" *ngIf="ticket.article?.thirdPartyPayerInformation?.isEligible">
            Pris en charge par {{ ticket.article?.thirdPartyPayerInformation?.thirdPartyName }}
        </span>
    </ion-col>
</ion-row>
<ion-row class="user-section">
    <ion-col size="10" class="flex content ion-align-items-center">
        <ion-icon name="{{ getPassengerTypeIconName(ticket.passengerType) }}"></ion-icon>

        <p class="ion-no-margin username">{{ ticket.passenger.firstName }} {{ ticket.passenger.lastName }}</p>
    </ion-col>
    <ion-col size="2" class="collapse-btn-container flex ion-text-end">
        <app-collapse-arrow [expanded]="expanded"></app-collapse-arrow>
    </ion-col>
</ion-row>
