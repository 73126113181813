import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentState, PaymentStatus, TicketStatus } from './payment.state';

export const selectPaymentState = createFeatureSelector<PaymentState>('payment');

export const getPaymentState = createSelector(selectPaymentState, (state: PaymentState) => state);

export const getCardPaymentMethodName = createSelector(
    selectPaymentState,
    ({ cardPaymentMethodName }: PaymentState) => cardPaymentMethodName,
);

export const getMobilePaymentMethodName = createSelector(
    selectPaymentState,
    ({ mobilePaymentMethodName }: PaymentState) => mobilePaymentMethodName,
);

export const getPaymentAndTicketStatus = createSelector(selectPaymentState, ({ paymentStatus, ticketStatus }: PaymentState) => ({
    paymentStatus,
    ticketStatus,
}));
