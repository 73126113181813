<ng-container *ngIf="locationTicketsCategory; else noTicketsByLocation">
    <ion-list>
        <ion-item-group>
            <ion-item-divider
                [class.active]="rootAccordion.isExpanded(LOCATION_SECTION_ID)"
                (click)="rootAccordion.toggle(LOCATION_SECTION_ID)"
                class="ticket ion-no-padding"
                tappable
            >
                <div class="ticket-category">
                    <div class="ticket-icon">
                        <ng-container *ngIf="!nearestStop.isLoading && isGpsPosition()">
                            <ion-icon name="locate"></ion-icon>
                        </ng-container>
                        <ng-container *ngIf="!nearestStop.isLoading && isDefaultPosition()">
                            <ion-icon src="assets/icons/no-locate.svg"></ion-icon>
                        </ng-container>
                        <ng-container *ngIf="nearestStop.isLoading">
                            <ion-spinner></ion-spinner>
                        </ng-container>
                    </div>
                    <span> Billets rapides depuis votre localisation </span>
                </div>
                <app-collapse-arrow [expanded]="rootAccordion.isExpanded(LOCATION_SECTION_ID)"></app-collapse-arrow>
            </ion-item-divider>

            <ion-list [class.collapsed]="rootAccordion.isExpanded(LOCATION_SECTION_ID)" class="tickets">
                <ng-container *ngFor="let ticket of locationTicketsCategory.tickets; trackBy: trackByIndex; let last = last">
                    <app-ticket-list-item
                        *ngIf="ticket.isDisplayed"
                        (ticketBuy)="buyTicketArticle(ticket, nearestStop.value)"
                        [isLastChild]="last"
                        [ticket]="ticket"
                        [nearestStop]="nearestStop.value"
                    ></app-ticket-list-item>
                </ng-container>
            </ion-list>
        </ion-item-group>
    </ion-list>
</ng-container>

<ng-template #noTicketsByLocation>
    <ion-list>
        <ion-item-group>
            <ion-item-divider
                class="ticket ion-no-padding"
                *ngIf="nearestStop.isLoading || ($isTicketsCategoryLoading | async); else noTickets"
            >
                <div class="ticket-category">
                    <div class="ticket-icon">
                        <ion-spinner></ion-spinner>
                    </div>
                    <span> {{ 'tickets.is-loading-nearest-stop' | translate }} </span>
                </div>
            </ion-item-divider>

            <ng-template #noTickets>
                <div class="spinner-container">
                    <app-message message="{{ noTicketMessage() }}"></app-message>
                </div>
            </ng-template>
        </ion-item-group>
    </ion-list>
</ng-template>

<ion-item-group *ngIf="otherTicketsCategory">
    <ion-item-divider
        [class.active]="rootAccordion.isExpanded(OTHER_SECTION_ID)"
        (click)="rootAccordion.toggle(OTHER_SECTION_ID)"
        class="ticket ion-no-padding"
        tappable
    >
        <div class="ticket-category">
            <div class="ticket-icon">
                <ion-icon class="other-ticket-icon" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
            </div>
            <span> {{ otherTicketsCategory.category.title }} </span>
        </div>
        <app-collapse-arrow [expanded]="rootAccordion.isExpanded(OTHER_SECTION_ID)"></app-collapse-arrow>
    </ion-item-divider>

    <ion-list [class.collapsed]="rootAccordion.isExpanded(OTHER_SECTION_ID)" class="tickets">
        <ng-container *ngFor="let ticket of otherTicketsCategory.tickets; trackBy: trackByIndex; let last = last">
            <app-ticket-list-item
                *ngIf="ticket.isDisplayed"
                (ticketBuy)="buyTicketArticleByZones(ticket)"
                [isLastChild]="last"
                [ticket]="ticket"
            ></app-ticket-list-item>
        </ng-container>
    </ion-list>
</ion-item-group>

<ion-item-group *ngIf="smsTicketsCategories?.length > 0">
    <ion-item-divider
        [class.active]="rootAccordion.isExpanded(SMS_SECTION_ID)"
        (click)="rootAccordion.toggle(SMS_SECTION_ID)"
        class="ticket ion-no-padding"
        tappable
    >
        <div class="ticket-category">
            <div class="ticket-icon">
                <ion-icon class="other-ticket-icon" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
            </div>
            <span> Billets SMS </span>
        </div>
        <app-collapse-arrow [expanded]="rootAccordion.isExpanded(SMS_SECTION_ID)"></app-collapse-arrow>
    </ion-item-divider>

    <ion-list [class.collapsed]="rootAccordion.isExpanded(SMS_SECTION_ID)" class="tickets">
        <ion-item-group *ngFor="let categoryViewModel of smsTicketsCategories">
            <ion-item-divider
                [class.active]="smsAccordion.isExpanded(categoryViewModel.category.title)"
                (click)="smsAccordion.toggle(categoryViewModel.category.title)"
                class="ticket ion-no-padding"
                tappable
            >
                <div class="ticket-category">
                    <div class="ticket-icon">
                        <ion-icon class="other-ticket-icon" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
                    </div>
                    <span> {{ categoryViewModel.category.title }} </span>
                </div>
                <app-collapse-arrow [expanded]="smsAccordion.isExpanded(categoryViewModel.category.title)"></app-collapse-arrow>
            </ion-item-divider>

            <ion-list [class.collapsed]="smsAccordion.isExpanded(categoryViewModel.category.title)" class="tickets">
                <ng-container *ngFor="let ticket of categoryViewModel.tickets; trackBy: trackByIndex; let last = last">
                    <app-ticket-list-item
                        *ngIf="ticket.isDisplayed"
                        (ticketBuy)="buySmsTicket(ticket)"
                        [isLastChild]="last"
                        [ticket]="ticket"
                    ></app-ticket-list-item>
                </ng-container>
            </ion-list>
        </ion-item-group>
    </ion-list>
</ion-item-group>

<app-ticket-itinerary-button (navigateClicked)="emitClickOnNavigate()"></app-ticket-itinerary-button>
