export interface TrblHelper<T = number> {
    top: T;
    left: T;
    right: T;
    bottom: T;
}

export function trblFactory<T = number>(top: T, left: T, right: T, bottom: T): TrblHelper<T> {
    return {
        top,
        left,
        right,
        bottom,
    };
}
