import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { IonBackButtonDelegate, Platform } from '@ionic/angular';
import { FROM_HEADER_LOGO, RouteUrl } from '@traas/common/routing';
import { Router } from '@angular/router';
import { environment } from '@traas/boldor/environments';
import { Subscription } from 'rxjs';
import { HardwareBackButtonEventsPriorityIonic } from '../../hardware-back-button-events-priority-ionic';

@Component({
    selector: 'app-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(IonBackButtonDelegate, { static: false }) ionBackButtonDelegate: IonBackButtonDelegate;

    @Input() title: string;
    @Input() iconName?: string;
    @Input() iconUrl: string;
    @Input() iconSize: string;
    @Input() homeButtonHref = RouteUrl.departureResultUrl;
    @Input() isHomeButtonDisabled = false;

    @Input() showHomeLogo = environment.showHomeLogoInHeader ?? false;
    @Input() canGoBack = true;
    @Input() defaultHref: string;
    @Input() customBackButtonAction = false;
    @Output() backButtonClicked = new EventEmitter<void>();
    private hardwareBackButtonSubscription: Subscription;

    constructor(private router: Router, private platform: Platform) {}

    ngOnInit(): void {
        /**
         * Like documented https://ionicframework.com/docs/api/back-button#defaulthref
         * this will add the default-href attributes on ion-back-button in the DOM. It is enough to show it, even if navigation stack is empty.
         * In this particular case of customBackButtonAction === true, the default-href '#' will be ignored and the button will trigger
         * his onClick event.
         */
        const MINIMAL_VALUE_TO_SHOW_BACK_BTN = '#';
        if (this.iconName && this.iconUrl) {
            throw new Error('PageTitleComponent error: you provided both an Icon NAME and URL. You can only provide one.');
        }
        if (this.customBackButtonAction && this.defaultHref) {
            throw new Error(
                'PageTitleComponent error: you provided both an customBackButtonAction and defaultHref. You can only provide one.',
            );
        }
        if (this.customBackButtonAction) {
            this.defaultHref = MINIMAL_VALUE_TO_SHOW_BACK_BTN;
        }
    }

    ngOnDestroy(): void {
        if (this.hardwareBackButtonSubscription) {
            this.hardwareBackButtonSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        if (this.ionBackButtonDelegate && this.customBackButtonAction) {
            /**
             * Only custom ion-back-button custom action will manage navigation
             * Hardware back button navigation event is ignored
             * Explanation https://ionicframework.com/docs/developing/hardware-back-button#internal-framework-handlers
             */
            this.ignoreAllEventsOfHardwareBackButtonFromPriority(HardwareBackButtonEventsPriorityIonic.Navigation + 1);
            this.ionBackButtonDelegate.onClick = (): void => {
                this.backButtonClicked.emit();
            };
        }
    }

    homeButtonClicked(): Promise<boolean> {
        return this.router.navigateByUrl(this.homeButtonHref, { replaceUrl: true, state: { origin: FROM_HEADER_LOGO } });
    }

    private ignoreAllEventsOfHardwareBackButtonFromPriority(priority: number): void {
        this.hardwareBackButtonSubscription = this.platform.backButton.subscribeWithPriority(priority, () => {
            console.info('Cancel hardware back-button all events under priority 1');
        });
    }
}
