import { NgModule } from '@angular/core';
import { AcceptTosComponent } from './components/accept-tos/accept-tos.component';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PageTitleModule } from '../../components/page-title/page-title.module';

@NgModule({
    declarations: [AcceptTosComponent],
    imports: [BoldorFeatureShellSharedModule, ReactiveFormsModule, PageTitleModule],
    exports: [AcceptTosComponent],
})
export class TosModule {}
