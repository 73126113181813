import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IonCheckboxBoldComponent } from './ion-checkbox-bold.component';

@NgModule({
    declarations: [IonCheckboxBoldComponent],
    imports: [CommonModule, IonicModule],
    exports: [IonCheckboxBoldComponent],
})
export class IonCheckboxBoldModule {}
