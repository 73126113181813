<ng-container *ngIf="!($isLoading | async); else isLoading">
    <ng-container *ngIf="$isOnline | async; else offline">
        <div class="container">
            <ng-content></ng-content>
            <traas-swisspass-login-button class="e2e-signin-button" (click)="onLoginSwisspassClicked()"> </traas-swisspass-login-button>

            <ion-button
                *ngIf="$showGuestCheckoutButton | async"
                (click)="onContinueAsGuest()"
                elevation="1"
                expand="block"
                fill="outline"
                color="secondary"
                class="e2e-signup-button"
                tappable
                >{{ 'login-dispatcher.guest-button' | translate }}</ion-button
            >

            <!--            // As of 3 Avril, on met en standby la feature achat par sms mais elle reviendra wink wink-->
            <!--            <ng-container *ngIf="($showBuyTicketBySmsButton|async) && ($isEligibleToGuest | async) === false">-->
            <!--                <div class="buy-ticket-by-sms-button-link">-->
            <!--                    <a (click)="buyTicketBySms()">{{ 'login-dispatcher.buy-ticket-by-sms' | translate : { company: 'tpg' } }}</a>-->
            <!--                </div>-->
            <!--            </ng-container>-->
            <traas-back-link class="e2e-cancel-button" (backLinkClicked)="returnToPreviousPage()"></traas-back-link>
        </div>
    </ng-container>
</ng-container>
<ng-template #isLoading>
    <div class="info-message">
        <app-message message="{{ 'login-dispatcher.loading-message' | translate }}"></app-message>
    </div>
</ng-template>
<ng-template #offline>
    <div class="info-message">
        <app-offline-page message="{{ 'common.offline-message' | translate }}"></app-offline-page>
    </div>
</ng-template>
