import { ChangeDetectionStrategy, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ExpandableComponent } from '../../../../components/expandable/expandable.component';
import { Ticket } from '@traas/boldor/all-models';

@Component({
    selector: 'app-ticket-order',
    templateUrl: './ticket-order.component.html',
    styleUrls: ['./ticket-order.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketOrderComponent extends ExpandableComponent implements OnInit {
    @Input() ticket: Ticket;

    constructor(protected renderer: Renderer2) {
        super(renderer);
    }

    ngOnInit(): void {
        this.expanded = true;
    }
}
