import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PageTitleModule } from '../../components/page-title/page-title.module';
import { BoldorFeatureShellSharedModule } from '../../boldor-feature-shell-shared.module';
import { PaymentPage } from './payment.page';
import { PaymentModule } from '../../features/payment/payment.module';

@NgModule({
    imports: [BoldorFeatureShellSharedModule, IonicModule, PageTitleModule, PaymentModule],
    declarations: [PaymentPage],
    exports: [PaymentPage],
})
export class PaymentPageModule {}
