import { ItineraryLegInput } from '@traas/boldor/graphql-generated/graphql';
import { TransportNames } from '@traas/boldor/all-models';
import { LegAdapter } from '../../../models/itinerary/leg';
import * as _ from 'lodash';

export class FrontToGqlItineraryConverter {
    static toItineraryLegInputCollection(legs: LegAdapter[]): ItineraryLegInput[] {
        const legsWithoutWalkOnStart: LegAdapter[] = _.dropWhile(legs, (leg: LegAdapter) => {
            return leg.isByFoot();
        });
        const legsWithoutWalkOnStartAndEnd: LegAdapter[] = _.dropRightWhile(legsWithoutWalkOnStart, (leg: LegAdapter) => {
            return leg.isByFoot();
        });
        return legsWithoutWalkOnStartAndEnd.map((leg, index, all) => this.toItineraryLegInput(leg, index, all));
    }

    private static toItineraryLegInput(current: LegAdapter, index: number, all: LegAdapter[]): ItineraryLegInput {
        if (current.isByFoot()) {
            return this.fromWalkLegToItineraryLegInput(index, all);
        }
        return this.fromTransportLegToItineraryLegInput(current);
    }

    private static fromTransportLegToItineraryLegInput(leg: LegAdapter): ItineraryLegInput {
        return {
            arrivalDateTime: leg.getArrivalDate(),
            arrivalStopDidok: leg.getLastStop().didok?.toString(),
            departureDateTime: leg.getScheduledDepartureDate(),
            departureStopDidok: leg.getFirstStop().didok?.toString(),
            isFirstClassAuthorized: leg.getIsFirstClassAuthorized(),
            novaCompanyId: leg.getTransportCompanyId(),
            transitStopsDidok: leg.getTransitStopsDidok().map((didok) => didok?.toString()),
            transportCode: leg.getTransportCode(),
            serviceNumber: leg.getServiceNumber(),
        };
    }

    private static fromWalkLegToItineraryLegInput(indexOfWalkLeg: number, legs: LegAdapter[]): ItineraryLegInput {
        const currentLeg = legs[indexOfWalkLeg];
        const previousLeg = legs[indexOfWalkLeg - 1];
        const nextLeg = legs[indexOfWalkLeg + 1];
        const WALK_NOVA_COMPANY_ID = '00';
        return {
            arrivalDateTime: nextLeg.getScheduledDepartureDate(),
            arrivalStopDidok: nextLeg.getFirstStop().didok?.toString(),
            departureDateTime: previousLeg.getArrivalDate(),
            departureStopDidok: previousLeg.getLastStop().didok?.toString(),
            isFirstClassAuthorized: currentLeg.getIsFirstClassAuthorized(),
            novaCompanyId: WALK_NOVA_COMPANY_ID,
            transitStopsDidok: [],
            transportCode: TransportNames.WALK,
        };
    }
}
