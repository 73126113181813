import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-collapse-arrow',
    templateUrl: './collapse-arrow.component.html',
    styleUrls: ['./collapse-arrow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapseArrowComponent {
    @Input() expanded = false;
    @Input() iconSize = '2.7rem';

    @Output() expand = new EventEmitter<void>();

    emitExpand(): void {
        this.expand.emit();
    }
}
