import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-ticket-itinerary-button',
    templateUrl: './ticket-itinerary-button.component.html',
    styleUrls: ['./ticket-itinerary-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketItineraryButtonComponent {
    @Output() navigateClicked: EventEmitter<void> = new EventEmitter<void>();

    onNavigateClicked(): void {
        this.navigateClicked.emit();
    }
}
