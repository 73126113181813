import { ItineraryService } from '../../../../libs/boldor/common/src/lib/features/itinerary/services/itinerary.service';
import { Injectable } from '@angular/core';
import { AlertService } from '../../../../libs/boldor/common/src/lib/services/common/alert.service';
import { Store } from '@ngrx/store';
import { EndpointState } from '../../../../libs/boldor/common/src/lib/features/home/store/endpoint/endpoint.state';
import { ToasterService } from '../../../../libs/boldor/common/src/lib/services/common/toaster/toaster.service';
import { GetItinerariesGQL, GetTransportModesGQL, SaveItineraryGQL } from '@traas/boldor/graphql-generated/graphql';
import { PopoverOptions } from '@ionic/core';
import { TransportModeFilterPopoverComponent } from '../../../../libs/boldor/common/src/lib/components/transport-mode-filter-popover/transport-mode-filter-popover.component';
import { LoggingService } from '@traas/common/logging';

@Injectable()
export class TravysItineraryService extends ItineraryService {
    constructor(
        protected alertService: AlertService,
        protected store: Store<EndpointState>,
        protected toasterService: ToasterService,
        protected saveItineraryGQL: SaveItineraryGQL,
        protected getTransportModesGQL: GetTransportModesGQL,
        protected getItinerariesGQL: GetItinerariesGQL,
        protected loggingService: LoggingService,
    ) {
        super(alertService, store, toasterService, saveItineraryGQL, getTransportModesGQL, getItinerariesGQL, loggingService);
    }

    getTransportModeFilterPopoverParams(): PopoverOptions {
        return {
            component: TransportModeFilterPopoverComponent,
            cssClass: 'transport-mode-filter-popover',
            backdropDismiss: false,
            translucent: false,
        };
    }

    showTransportModeFilters() {
        return true;
    }
}
