import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrderType, OrderViewModel } from '@traas/boldor/all-models';
import { CompanyService } from '../../../services/common/company/company.service';
import { OrderStorageService } from '../../../services/common/order/order-storage.service';
import { OrderService } from '../../../services/common/order/order.service';
import { StopRequestStorageService } from '../../../services/common/stop-request/stop-request-storage.service';
import { GqlToFrontOrderConverter } from '../../../models/order';
import { convertToError, LoggingService } from '@traas/common/logging';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AddOrder, BookingActionTypes, LoadOrdersFail, LoadOrdersSuccess, UpdateOrderById } from './booking.action';
import { BookingState } from './booking.state';
import { BookingStoreActions } from './index';
import { ErrorCodes, TechnicalError } from '@traas/common/models';

@Injectable()
export class BookingEffect {
    $loadOrders: Observable<LoadOrdersSuccess | LoadOrdersFail> = createEffect(() =>
        this.$actions.pipe(
            ofType(BookingStoreActions.BookingActionTypes.LoadOrders),
            mergeMap(() => this.updateOrdersStorageFromServer()),
            mergeMap(() => this.getOrdersFromStorage()),
            map((orders) => new BookingStoreActions.LoadOrdersSuccess({ orders })),
            catchError((error) => {
                this.logger.logError(new TechnicalError('Error while loading orders in effect', ErrorCodes.Booking.Fetch, error));
                return of(new BookingStoreActions.LoadOrdersFail(error));
            }),
        ),
    );

    $loadOrdersSuccess = createEffect(() =>
        this.$actions.pipe(
            ofType(BookingActionTypes.LoadOrdersSuccess),
            map(({ payload: { orders } }: BookingStoreActions.LoadOrdersSuccess) => {
                return new BookingStoreActions.SetOrders({ orders });
            }),
        ),
    );

    /**
     * Todo make a better distinction between TicketsOrder and StopRequestOrders
     */

    $addOrder = createEffect(
        () =>
            this.$actions.pipe(
                ofType<AddOrder>(BookingActionTypes.AddOrder),
                tap(async ({ payload }) => {
                    switch (payload.orderType) {
                        case OrderType.ItineraryStopRequest:
                        case OrderType.DepartureStopRequest:
                            await this.stopRequestsStorageService.addStopRequestToStorage(payload);
                            break;
                        case OrderType.SaveItinerary:
                        case OrderType.BuyItineraryTickets:
                        case OrderType.BuyDepartureTickets:
                        case OrderType.BuyQuickTicket:
                            await this.orderStorageService.addOrderToStorage(payload);
                            break;
                        default:
                            console.warn(`Unknown orderType of order : ${JSON.stringify(payload)}`);
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Todo make a better distinction between TicketsOrder and StopRequestOrders
     */

    $updateOrderById = createEffect(
        () =>
            this.$actions.pipe(
                ofType<UpdateOrderById>(BookingActionTypes.UpdateOrderById),
                tap(async ({ payload }) => {
                    switch (payload.orderType) {
                        case OrderType.ItineraryStopRequest:
                        case OrderType.DepartureStopRequest:
                            await this.stopRequestsStorageService.replaceStopRequestById(payload);
                            break;
                        case OrderType.SaveItinerary:
                        case OrderType.BuyItineraryTickets:
                        case OrderType.BuyDepartureTickets:
                        case OrderType.BuyQuickTicket:
                            await this.orderStorageService.replaceOrderById(payload);
                            break;
                        default:
                            console.warn(`Unknown orderType of order : ${JSON.stringify(payload)}`);
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private bookingStore: Store<BookingState>,
        private $actions: Actions,
        private orderService: OrderService,
        private orderStorageService: OrderStorageService,
        private stopRequestsStorageService: StopRequestStorageService,
        private logger: LoggingService,
    ) {}

    private getOrdersFromStorage(): Promise<OrderViewModel[]> {
        if (CompanyService.isTPG()) {
            return firstValueFrom(this.orderStorageService.$getStoredOrder());
        }

        const $orders = combineLatest([
            this.stopRequestsStorageService
                .$getStoredStopRequests()
                .pipe(
                    map((stopRequests) =>
                        stopRequests.map((stopRequest) => GqlToFrontOrderConverter.fromStopRequestToOrderViewModel(stopRequest)),
                    ),
                ),
            this.orderStorageService.$getStoredOrder(),
        ]).pipe(map(([stopRequestsOrderViewModel, orderViewModel]) => [...stopRequestsOrderViewModel, ...orderViewModel]));
        return firstValueFrom($orders);
    }

    private async updateOrdersStorageFromServer(): Promise<void> {
        try {
            const result = await this.orderService.getAllOrdersAndAllStopRequestsOfCurrentUser();
            await Promise.all([
                this.orderStorageService.storeOrders(result.orders),
                this.stopRequestsStorageService.storeStopRequests(result.stopRequests),
            ]);
        } catch (error) {
            throw new TechnicalError('Error while updating orders storage from server', ErrorCodes.Booking.Fetch, convertToError(error));
        }
    }
}
