import { NgModule } from '@angular/core';
import { StartupNotificationEffect } from './startup-notification-effect.service';
import { startupNotificationReducer } from './startup-notification-reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature('startupNotification', startupNotificationReducer),
        EffectsModule.forFeature([StartupNotificationEffect]),
    ],
    providers: [StartupNotificationEffect],
})
export class StartupNotificationStoreModule {}
