import { LatLng, LatLngBounds } from 'leaflet';
import { MapMode, MapMoveSource } from '@traas/boldor/all-models';
import { PhysicalStop } from '@traas/common/models';

export interface MapState {
    bounds: LatLngBounds;
    stopsInBounds: PhysicalStop[];
    hasTooManyStopsInBounds: boolean;
    updating: boolean;
    isEnabled: boolean;
    mode: MapMode;
    lastMoveSource: MapMoveSource;
    centerPoint: LatLng;
    zoomLevel: number;
}

export const initialState: MapState = {
    bounds: null,
    stopsInBounds: [],
    updating: false,
    hasTooManyStopsInBounds: false,
    isEnabled: true,
    mode: MapMode.Half,
    lastMoveSource: MapMoveSource.Other,
    centerPoint: null,
    zoomLevel: 1,
};
